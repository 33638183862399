import { useSubFundPnlInputSuspenseQuery } from '../../../../generated/graphql.tsx';
import { Suspense, useState } from 'react';
import { createDimensionAutocompleteOptions } from '../../fund/SubFundService.tsx';
import { Select } from '../../../technical/inputs';
import Message from '../../../technical/Message.tsx';
import { Stack } from '@mui/joy';
import { defaultRowSpacing } from '../../../StackSpacing.ts';
import DateTimeInput from '../../../technical/inputs/date/DateTimeInput.tsx';
import dayjs, { type Dayjs } from 'dayjs';
import SubFundPnlResult from './SubFundPnlResult.tsx';
import Loader from '../../../technical/Loader/Loader.tsx';
import { UserSettings } from '../../../management/UserSettings.types.ts';
import { getSavedDaysRange } from '../../dashboard/tiles/PortfolioTilesService.ts';
import { range7Days } from '../../../predefinedDateRanges.ts';

const SubFundPnlDashboard = ({ dimensions, since }: { dimensions: string[]; since: Dayjs }) => {
  const [dimension, setDimension] = useState<string>(dimensions[0]);
  const [startDate, setStartDate] = useState<Dayjs | null>(since);

  return (
    <Stack spacing={defaultRowSpacing}>
      <Stack direction={'row'} spacing={defaultRowSpacing} flexWrap={'wrap'}>
        <Select
          {...createDimensionAutocompleteOptions(dimensions)}
          width={'normal'}
          menuWidth={'normal'}
          label={'Dimension'}
          value={dimension}
          onChange={(value) => {
            setDimension(value);
          }}
          showLabelAboveInput
        />
        <DateTimeInput
          value={startDate}
          onChange={(day) => setStartDate(day)}
          width={'normal'}
          label={'Start date'}
          maxDate={dayjs.utc()}
          showClearable
          showLabelAboveInput
        />
      </Stack>
      <Suspense fallback={<Loader />}>
        <SubFundPnlResult dimension={dimension} startDate={startDate} />
      </Suspense>
    </Stack>
  );
};

export const noDimensionsMessage = <Message>At least one sub-fund is required</Message>;

const SubFundPnlDashboardContainer = () => {
  const { data } = useSubFundPnlInputSuspenseQuery({
    variables: {
      field: UserSettings.TilesDateRange,
    },
  });

  const dimensions = data.portfolio.subFunds.list.map((item) => item.dimension);
  if (dimensions.length === 0) {
    return noDimensionsMessage;
  }

  const { savedDateRange } = getSavedDaysRange(data);
  const since = (savedDateRange.value() ?? range7Days.value())[0];

  return <SubFundPnlDashboard dimensions={dimensions} since={since} />;
};

export default SubFundPnlDashboardContainer;

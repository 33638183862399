import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import { Stack, Typography, type TypographyProps } from '@mui/joy';
import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import type { CellContext, SortingState } from '@tanstack/table-core';
import type { Dayjs } from 'dayjs';
import capitalize from 'lodash/fp/capitalize';
import isNil from 'lodash/fp/isNil';
import { bignumber } from 'mathjs';
import { type FunctionComponent, type ReactElement, type ReactNode, useMemo, useState } from 'react';
import { downloadTransactionOrderCsv } from 'components/file.utils.tsx';
import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';
import GTable from 'components/technical/GTable/GTable';
import type { Filter } from 'components/technical/GTable/GTable.props';
import GTableSkeleton from 'components/technical/GTable/GTableSkeleton';
import { useTablePagePaginator } from 'components/technical/GTable/UseTablePaginator.tsx';
import { defaultHeaderActionProps } from 'components/technical/HeaderBar/DefaultHeaderActionProps.ts';
import HeaderBar from 'components/technical/HeaderBar/HeaderBar';
import StaticMultiAutocomplete from 'components/technical/inputs/Autocomplete/StaticMultiAutocomplete';
import type { StaticAutocompleteOption } from 'components/technical/inputs/Autocomplete/StaticSingleAutocomplete.props';
import DateRangeInput, { type DateRangeInputProps } from 'components/technical/inputs/date/DateRangeInput';
import AsyncActionButton from 'components/technical/inputs/GButton/AsyncActionButton';
import SectionColumn from 'components/technical/layout/Column/SectionColumn';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';

import { useUserTimezone } from 'components/technical/UseUserTimezone.tsx';
import {
  type IAsset,
  type IOrderFilters,
  IOrderSide,
  type IOrdersQuery,
  IOrderStatus,
  ISortDirection,
  useDownloadOrdersMutation,
  useOrderFilterInputQuery,
  useOrdersQuery,
} from '../../../generated/graphql';
import { isValidDayjsDateRange } from '../../date.utils';
import { DateTimeFormat, formatDate, formatPercentage } from '../../formatter.utils';
import type { IAssetToAsset } from '../../market/asset/Asset.types';
import AssetLabel from '../../market/asset/AssetLabel';
import AssetValueView from '../../market/asset/AssetValueView';
import {
  createSubAccountIdAutocompleteOptions,
  type CreateSubAccountIdAutocompleteOptionsInputAccount,
} from '../../portfolio/account/AccountService.tsx';
import { SubAccountLabel } from '../../portfolio/account/SubAccountLabel.tsx';
import { convertDateRangeToSinceToDateTime } from 'components/technical/inputs/date/dateRange.utils.ts';
import type { StaticMultiAutocompleteProps } from '../../technical/inputs/Autocomplete/StaticMultiAutocomplete.props.ts';
import type { NotVerifiedAsset } from '../../market/asset/AssetLabelService.ts';
import GMultiAutocomplete from '../../technical/inputs/Autocomplete/GMultiAutocomplete.tsx';
import { createAssetAutocompleteProps, useAssetPaginatedOptions } from '../../market/asset/AssetService.tsx';
import type { GMultiAutocompleteProps } from '../../technical/inputs/Autocomplete/GMultiAutocomplete.props.ts';
import { emptyToUndefined } from 'components/filter.utils.ts';

const orderSides: StaticAutocompleteOption<IOrderSide>[] = [
  ...Object.entries(IOrderSide).map(([name, value]) => ({
    label: name,
    value: value,
    key: name,
    searchText: name,
  })),
];

const orderStatusOptions: StaticAutocompleteOption<IOrderStatus>[] = [
  ...Object.entries(IOrderStatus).map(([name, value]) => ({
    label: name,
    value: value,
    key: name,
    searchText: name,
  })),
];

const headers = [
  'Opened at',
  'Sub-account',
  'Order #',
  'Type',
  'Currency pair',
  'Side',
  'State',
  'Quantity',
  'Avg fill price',
  '% Exec',
  'Total',
  'Updated at',
];

type OrderRawRow = IOrdersQuery['bookkeeping']['orders']['data'][number];
type Order = {
  [key in keyof OrderRawRow]: NonNullable<OrderRawRow[key]> extends IAsset
    ? IAssetToAsset<OrderRawRow[key]>
    : OrderRawRow[key];
};

const statusToColor: Record<IOrderStatus, TypographyProps['color']> = {
  CANCELED: 'neutral',
  CLOSED: 'success',
  EXPIRED: 'neutral',
  OPEN: 'success',
  REJECTED: 'danger',
  UNKNOWN: 'neutral',
};

const OrderList: FunctionComponent<{
  accounts: CreateSubAccountIdAutocompleteOptionsInputAccount[];
}> = ({ accounts }): ReactElement => {
  const createdAtColumnId = 'creationTime';
  const [orderSide, setOrderSide] = useState<IOrderSide[]>([]);
  const [orderStatus, setOrderStatus] = useState<IOrderStatus[]>([]);
  const [assets, setAssets] = useState<(NotVerifiedAsset & { id: string })[]>([]);
  const [subAccount, setSubAccount] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs] | null>(null);
  const timezone = useUserTimezone();

  const subAccountOptions = useMemo(() => createSubAccountIdAutocompleteOptions(accounts), [accounts]);

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: createdAtColumnId,
      desc: true,
    },
  ]);

  const isValidDateRange = isValidDayjsDateRange(dateRange);

  const filters = useMemo(() => {
    return {
      orderStatus: emptyToUndefined(orderStatus),
      orderSide: emptyToUndefined(orderSide) as IOrderSide[],
      subAccountIds: emptyToUndefined(subAccount),
      createdAt: isValidDateRange ? convertDateRangeToSinceToDateTime(dateRange) : { since: null, to: null },
      assetIds: emptyToUndefined(assets.map((asset) => asset?.id ?? null)),
    } satisfies IOrderFilters;
  }, [orderStatus, orderSide, subAccount, dateRange, assets, isValidDateRange]);

  const { tablePaginator, page } = useTablePagePaginator({
    filters,
  });

  const sort = useMemo(
    () => ({
      name: createdAtColumnId,
      direction: sorting.find((col) => col.id === createdAtColumnId)?.desc ? ISortDirection.Desc : ISortDirection.Asc,
    }),
    [sorting]
  );

  const { data, error, loading } = useOrdersQuery({
    variables: {
      filters: filters,
      pageLimit: page,
      sort: sort,
    },
    skip: !isValidDateRange,
  });

  const [downloadOrders] = useDownloadOrdersMutation({
    ignoreResults: true,
  });

  const columnHelper = createColumnHelper<Order>();
  const { showGraphqlError } = useFeedback();
  const actions = (
    <AsyncActionButton
      {...defaultHeaderActionProps}
      onClick={async (): Promise<void> => {
        try {
          const token = await downloadOrders();

          downloadTransactionOrderCsv(token.data!.DownloadOrders.token);
        } catch (e) {
          console.error('Failed to download csv', e);
          showGraphqlError(e);
        }
      }}
      startDecorator={<TableChartOutlinedIcon />}
    >
      Export CSV
    </AsyncActionButton>
  );

  const columns: ColumnDef<Order>[] = [
    {
      id: createdAtColumnId,
      accessorKey: createdAtColumnId,
      cell: (props: CellContext<Order, unknown>): ReactNode => {
        const creationTime = props.row.original.creationTime;
        if (isNil(creationTime)) {
          return '-';
        }

        return formatDate(creationTime, DateTimeFormat.DateTime, timezone);
      },
      header: 'Opened at',
      enableSorting: true,
    },
    columnHelper.display({
      header: 'Sub-account',
      cell: (props: CellContext<Order, unknown>): ReactElement => {
        return <SubAccountLabel subAccount={props.row.original.subAccount} />;
      },
    }),
    {
      accessorKey: 'externalId',
      header: 'Order #',
    },
    {
      accessorFn: (order) => capitalize(order.type),
      header: 'Type',
    },
    columnHelper.display({
      header: 'Currency pair',
      cell: (props: CellContext<Order, unknown>): ReactNode => {
        const order = props.row.original;
        if (isNil(order.baseAsset) || isNil(order.quoteAsset)) {
          return '-';
        }

        return (
          <Stack direction="row" spacing={0}>
            <AssetLabel asset={order.baseAsset} format="short" link={false} plain />
            &nbsp;/&nbsp;
            <AssetLabel asset={order.quoteAsset} format="short" link={false} plain />
          </Stack>
        );
      },
    }),
    {
      accessorFn: (order) => capitalize(order.side),
      header: 'Side',
    },
    columnHelper.display({
      header: 'State',
      cell: (props: CellContext<Order, unknown>): ReactNode => {
        const order = props.row.original;
        if (isNil(order.status)) {
          return '-';
        }

        return <Typography color={statusToColor[order.status]}>{capitalize(order.status)}</Typography>;
      },
    }),
    columnHelper.display({
      header: 'Quantity',
      cell: (props: CellContext<Order, unknown>): ReactNode => {
        const order = props.row.original;
        return isNil(order.amount) ? '-' : <AssetValueView asset={order.baseAsset} value={order.amount} link={false} />;
      },
    }),
    columnHelper.display({
      header: 'Avg fill price',
      cell: (props: CellContext<Order, unknown>): ReactNode => {
        const order = props.row.original;
        return isNil(order.average) ? (
          '-'
        ) : (
          <AssetValueView value={order.average} asset={order.quoteAsset} link={false} />
        );
      },
    }),
    columnHelper.display({
      header: '% exec',
      cell: (props: CellContext<Order, unknown>): ReactNode => {
        const order = props.row.original;
        return isNil(order.amount) || isNil(order.filled)
          ? '-'
          : formatPercentage(bignumber(order.filled).div(order.amount).toNumber());
      },
    }),
    columnHelper.display({
      header: 'Total',
      cell: (props: CellContext<Order, unknown>): ReactNode => {
        const order = props.row.original;
        return isNil(order.average) || isNil(order.amount) ? (
          '-'
        ) : (
          <AssetValueView asset={order.quoteAsset} value={bignumber(order.amount).mul(order.average)} link={false} />
        );
      },
    }),
    {
      header: 'Updated at',
      cell: (props: CellContext<Order, unknown>): ReactNode => {
        const updateTime = props.row.original.lastUpdateTime;
        if (isNil(updateTime)) {
          return '-';
        }

        return formatDate(props.row.original.lastUpdateTime, DateTimeFormat.DateTime, timezone);
      },
    },
  ];

  const width = 'normal' as const;
  const { getOptions } = useAssetPaginatedOptions();
  return (
    <SectionColumn>
      <HeaderBar title="Orders">{actions}</HeaderBar>
      <GTable
        filters={[
          {
            component: DateRangeInput,
            value: dateRange,
            width,
            error: isValidDateRange ? undefined : 'Invalid date',
            onChange: setDateRange,
            label: 'Created at',
            defaultValue: null,
            defaultFilter: true,
          } satisfies Filter<DateRangeInputProps>,
          {
            component: GMultiAutocomplete,
            getOptions,
            ...createAssetAutocompleteProps(),
            value: assets,
            width,
            onChange: setAssets,
            label: 'Asset',
            defaultValue: [],
            defaultFilter: true,
          } satisfies Filter<GMultiAutocompleteProps<NotVerifiedAsset & { id: string }>>,
          {
            component: StaticMultiAutocomplete,
            options: orderSides,
            value: orderSide,
            width,
            limitTags: 2,
            onChange: setOrderSide,
            label: 'Order side',
            defaultValue: [],
          } satisfies Filter<StaticMultiAutocompleteProps<IOrderSide>>,
          {
            component: StaticMultiAutocomplete,
            value: subAccount,
            width,
            ...subAccountOptions,
            onChange: setSubAccount,
            label: 'Sub-account',
            defaultValue: [],
            defaultFilter: true,
          } satisfies Filter<StaticMultiAutocompleteProps<string>>,
          {
            component: StaticMultiAutocomplete,
            options: orderStatusOptions,
            value: orderStatus,
            width,
            limitTags: 2,
            onChange: setOrderStatus,
            label: 'Order status',
            defaultValue: [],
          } satisfies Filter<StaticMultiAutocompleteProps<IOrderStatus>>,
        ]}
        columns={columns}
        data={data?.bookkeeping.orders.data}
        loading={loading}
        error={error}
        pagePaginator={tablePaginator}
        totalResults={data?.bookkeeping.orders.pageInfo.totalResults}
        sortingState={sorting}
        onSortingChange={setSorting}
      />
    </SectionColumn>
  );
};

const OrdersDashboard = (): ReactElement => {
  const { data, Fallback, loaded } = useDefaultErrorHandling(useOrderFilterInputQuery());

  if (!loaded) {
    return <Fallback />;
  }

  if (data.portfolio.accounts.length === 0) {
    return <GTableSkeleton headers={headers} />;
  }

  return <OrderList accounts={data.portfolio.accounts} />;
};

export default OrdersDashboard;

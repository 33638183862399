import { Stack } from '@mui/joy';
import { type FunctionComponent, Suspense, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  createAssetAutocompleteProps,
  type NotVerifiedAssetWithId,
  useAssetPaginatedOptions,
} from 'components/market/asset/AssetService.tsx';
import {
  createAccountIdAutocompleteOptions,
  createSubAccountIdAutocompleteOptions,
} from 'components/portfolio/account/AccountService.tsx';
import { useDrawer } from 'components/technical/Drawer/UseDrawer.tsx';
import FormStaticMultiAutocomplete from 'components/technical/form/FormStaticMultiAutocomplete.tsx';
import GFormProvider from 'components/technical/form/GFormProvider.tsx';
import GButton from 'components/technical/inputs/GButton/GButton.tsx';

import {
  type ISubAccountAssetAssetFiltersInputQuery,
  type ISubAccountAssetFiltersInputQuery,
  useSubAccountAssetAssetFiltersInputSuspenseQuery,
  useSubAccountAssetFiltersInputSuspenseQuery,
} from 'generated/graphql.tsx';
import {
  type Field,
  useShowSubAccountAssetFilter,
  useSubAccountAssetFilterFieldsForPath,
} from './UseShowSubAccountAssetFilters.tsx';
import {
  useSubAccountAssetFilters,
  useSyncSubAccountAssetFilterWithCurrentData,
} from './UseSubAccountAssetFilters.tsx';
import { createSubfundIdAutocompleteOptions } from '../../portfolio/fund/SubFundService.tsx';
import Loader from '../Loader/Loader.tsx';
import FormMultiAutocomplete from '../form/FormMultiAutocomplete.tsx';

interface SubAccountAssetFilterForm {
  assets: NotVerifiedAssetWithId[];
  subFundIds: number[];
  accountIds: string[];
  subAccountIds: string[];
  assetIds: string[];
}

type FilterDrawerProps = {
  open: boolean;
  data: Partial<ISubAccountAssetAssetFiltersInputQuery> & ISubAccountAssetFiltersInputQuery;
};

const SubAccountAssetFilterDrawer: FunctionComponent<FilterDrawerProps> = ({ open, data }) => {
  const idToAsset = new Map((data.assets?.list ?? []).map((a) => [a.id, a]));
  const { subAccountAssetFilters, setSubAccountAssetFilters } = useSubAccountAssetFilters();

  const methods = useForm<SubAccountAssetFilterForm>({
    defaultValues: {
      subFundIds: subAccountAssetFilters.subFundIds ?? [],
      accountIds: subAccountAssetFilters.accountIds ?? [],
      subAccountIds: subAccountAssetFilters.subAccountIds ?? [],
      assets: (subAccountAssetFilters.assetIds ?? []).map((id) => idToAsset.get(id)!),
    },
  });

  const fields = useSubAccountAssetFilterFieldsForPath();
  const shouldShowField = (fieldName: Field): boolean => fields?.includes(fieldName) ?? true;

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (open) {
      const idToAsset = new Map((data.assets?.list ?? []).map((a) => [a.id, a]));
      // reset from dirty values and set applied filters values on drawer reopen
      methods.setValue('subFundIds', subAccountAssetFilters.subFundIds ?? []);
      methods.setValue('subAccountIds', subAccountAssetFilters.subAccountIds ?? []);
      methods.setValue('accountIds', subAccountAssetFilters.accountIds ?? []);
      methods.setValue(
        'assets',
        (subAccountAssetFilters.assetIds ?? []).map((id) => idToAsset.get(id)!)
      );
    }
    // rest only makes sense on open drawer event
  }, [open, data]);

  const subFunds = data.portfolio.subFunds.list;
  const onSubmit = (formData: SubAccountAssetFilterForm): void => {
    setSubAccountAssetFilters({
      subFundIds: formData.subFundIds,
      subAccountIds: formData.subAccountIds,
      accountIds: formData.accountIds,
      assetIds: formData.assets.map((asset) => asset.id),
    });
  };

  const assetOptions = useAssetPaginatedOptions();

  return (
    <GFormProvider {...methods}>
      <Stack spacing={1.5}>
        {shouldShowField('subFundIds') && (
          <FormStaticMultiAutocomplete
            name="subFundIds"
            {...createSubfundIdAutocompleteOptions(subFunds)}
            label="Sub-fund"
            width="fullWidth"
          />
        )}
        {shouldShowField('accountIds') && (
          <FormStaticMultiAutocomplete
            name="accountIds"
            {...createAccountIdAutocompleteOptions(data.portfolio.accounts)}
            label="Account"
            width="fullWidth"
          />
        )}
        {shouldShowField('subAccountIds') && (
          <FormStaticMultiAutocomplete
            name="subAccountIds"
            {...createSubAccountIdAutocompleteOptions(data.portfolio.accounts)}
            label="Sub-account"
            width="fullWidth"
          />
        )}
        {shouldShowField('assets') && (
          <FormMultiAutocomplete
            {...assetOptions}
            {...createAssetAutocompleteProps<NotVerifiedAssetWithId>()}
            width="fullWidth"
            label="Asset"
            name="assets"
            limitTags={3}
          />
        )}
        <GButton
          variant="plain"
          onClick={(): void => {
            methods.reset({
              subFundIds: [],
              accountIds: [],
              subAccountIds: [],
              assets: [],
            });
          }}
        >
          Clear all
        </GButton>
        <GButton onClick={methods.handleSubmit(onSubmit)}>Apply filter</GButton>
      </Stack>
    </GFormProvider>
  );
};

const SubAccountAssetFilterDrawerContainer: FunctionComponent<{ open: boolean }> = ({ open }) => {
  const { subAccountAssetFilters } = useSubAccountAssetFilters();

  // run two queries - when calling endpoint with assetIds empty, the backend returns the list of all assets
  const { data } = useSubAccountAssetFiltersInputSuspenseQuery();

  const { data: assetData } = useSubAccountAssetAssetFiltersInputSuspenseQuery({
    variables: {
      assetIds: subAccountAssetFilters.assetIds ?? [],
    },
    skip: (subAccountAssetFilters.assetIds ?? []).length === 0,
  });

  const fullData = {
    ...data!,
    ...assetData,
  };

  useSyncSubAccountAssetFilterWithCurrentData(fullData);

  return <SubAccountAssetFilterDrawer open={open} data={fullData} />;
};

const SubAccountAssetFilterDrawerWrapper: FunctionComponent<{ open: boolean }> = ({ open }) => {
  const { drawer, closeDrawer } = useDrawer();
  const shouldShowSubAccountAssetFilter = useShowSubAccountAssetFilter();

  useEffect(() => {
    if (drawer && !shouldShowSubAccountAssetFilter) {
      closeDrawer();
    }
  }, [drawer, closeDrawer, shouldShowSubAccountAssetFilter]);

  return (
    <Suspense fallback={<Loader />}>
      <SubAccountAssetFilterDrawerContainer open={open} />
    </Suspense>
  );
};

export default SubAccountAssetFilterDrawerWrapper;

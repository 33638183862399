import { gridWithInputStyles } from 'components/technical/grids/gridStyles';
import GAgGrid from 'components/technical/grids/GAgGrid';
import { Box, useTheme } from '@mui/joy';
import {
  amountColumn,
  SPARKLINE_Y_MIN_OFFSET,
  NAV_PERC_MIN_DECIMAL_THRESHOLD,
  navPercentageColumn,
  priceColumn,
  sideWithBadgeColumn,
  unitCostBasisColumn,
  unrealizedPnlWithColorsColumn,
  assetWithSymbolColumn,
  notionalColumn,
  navColumn,
} from 'components/technical/grids/SharedReportColumns';
import { calculateSubAccountToSubFundsMapper } from './PositionsSummary.util';
import { groupCostBasisByAssetAndSubFund } from '../account/SubAccountPositionsService';
import type { ColDef, ICellRendererParams } from 'ag-grid-community';
import type { IPortfolioDashboardInputQuery } from 'generated/graphql';
import { bignumber } from 'mathjs';
import { VenueIconList } from 'components/venue/VenueIconList';
import { PriceSummaryType, usePriceChanges } from './UsePriceChanges';

interface VisualPosition {
  asset: IPortfolioDashboardInputQuery['portfolio']['positions']['positions'][number]['asset'];
  spot?: IPortfolioDashboardInputQuery['portfolio']['positions']['positions'][number]['spot'];
  derivative?: IPortfolioDashboardInputQuery['portfolio']['positions']['positions'][number]['derivative'];
  subAccount: {
    name: string;
    venue: {
      label: string;
      id: string;
    };
  }[];
}

const PositionSummaryLight = ({
  portfolio,
}: {
  portfolio: IPortfolioDashboardInputQuery['portfolio'];
}) => {
  const costBasisPerAssetAndSubFund = groupCostBasisByAssetAndSubFund(portfolio.journal.costBasis);
  const costBasisDimension = portfolio.journal.preferences?.dimension ?? null;
  const subAccountToCostBasisSubFundsMapper = calculateSubAccountToSubFundsMapper(
    costBasisDimension,
    portfolio.subFunds.list
  );

  const totalNAV = portfolio.positions.positions.reduce((acc, pos) => {
    let partialAcc = acc;
    if (pos.spot) {
      partialAcc = partialAcc.plus(pos.spot.balance);
    } else if (pos.derivative) {
      partialAcc = partialAcc.plus(pos.derivative.balance || 0);
    }
    return partialAcc;
  }, bignumber(0));

  const spotsList = portfolio.positions.positions.filter((pos) => pos.spot);
  const spotAssetIds = new Map<string, { name: string; venue: { label: string; id: string } }[]>();

  for (const pos of spotsList) {
    if (pos.spot) {
      if (spotAssetIds.has(pos.asset.id)) {
        const subAccounts = spotAssetIds.get(pos.asset.id);
        if (subAccounts) {
          const evaluatedAccount = {
            name: pos.subAccount.name,
            venue: {
              label: pos.subAccount.account.venue.label,
              id: pos.subAccount.account.id,
            },
          };
          subAccounts.push(evaluatedAccount);
          spotAssetIds.set(pos.asset.id, subAccounts);
        }
      } else {
        const evaluatedAccount = {
          name: pos.subAccount.name,
          venue: {
            label: pos.subAccount.account.venue.label,
            id: pos.subAccount.account.id,
          },
        };
        spotAssetIds.set(pos.asset.id, [evaluatedAccount]);
      }
    }
  }

  const groupedSpots = spotsList.reduce((acc: Map<string, VisualPosition>, pos) => {
    const existing = acc.get(pos.asset.id);
    if (existing) {
      const evaluated = {
        asset: existing.asset,
        spot: {
          price: bignumber(existing.spot?.price || 0).toString(),
          amount: bignumber(existing.spot?.amount || 0)
            .plus(pos.spot?.amount || 0)
            .toString(),
          balance: bignumber(existing.spot?.balance || 0)
            .plus(pos.spot?.balance || 0)
            .toString(),
        },
        subAccount: spotAssetIds.get(pos.asset.id) ?? [],
      };
      acc.set(pos.asset.id, evaluated);
    } else {
      const evaluated = {
        asset: pos.asset,
        spot: {
          price: bignumber(pos.spot?.price || 0).toString(),
          amount: bignumber(pos.spot?.amount || 0).toString(),
          balance: bignumber(pos.spot?.balance || 0).toString(),
        },
        subAccount: spotAssetIds.get(pos.asset.id) ?? [],
      };
      acc.set(pos.asset.id, evaluated);
    }
    return acc;
  }, new Map<string, VisualPosition>());

  const derivativesList = portfolio.positions.positions.filter((pos) => pos.derivative);
  const derivativeAssetIds = new Map<string, { name: string; venue: { label: string; id: string } }[]>();

  for (const pos of derivativesList) {
    if (pos.derivative) {
      if (derivativeAssetIds.has(pos.asset.id)) {
        const subAccounts = derivativeAssetIds.get(pos.asset.id);
        if (subAccounts) {
          const evaluatedAccount = {
            name: pos.subAccount.name,
            venue: {
              label: pos.subAccount.account.venue.label,
              id: pos.subAccount.account.id,
            },
          };
          subAccounts.push(evaluatedAccount);
          derivativeAssetIds.set(pos.asset.id, subAccounts);
        }
      } else {
        const evaluatedAccount = {
          name: pos.subAccount.name,
          venue: {
            label: pos.subAccount.account.venue.label,
            id: pos.subAccount.account.id,
          },
        };
        derivativeAssetIds.set(pos.asset.id, [evaluatedAccount]);
      }
    }
  }

  const groupedDerivatives = derivativesList.reduce((acc: Map<string, VisualPosition>, pos) => {
    const existing = acc.get(pos.asset.id);
    if (existing) {
      const evaluated = {
        asset: existing.asset,
        derivative: existing.derivative,
        subAccount: derivativeAssetIds.get(pos.asset.id) ?? [],
      };
      acc.set(pos.asset.id, evaluated);
    } else {
      const evaluated = {
        asset: pos.asset,
        derivative: pos.derivative,
        subAccount: derivativeAssetIds.get(pos.asset.id) ?? [],
      };
      acc.set(pos.asset.id, evaluated);
    }
    return acc;
  }, new Map<string, VisualPosition>());

  const theme = useTheme();

  const arraySpots: VisualPosition[] = Array.from(groupedSpots.values());
  const arrayDerivatives: VisualPosition[] = Array.from(groupedDerivatives.values());
  const arrayPositions = [...arraySpots, ...arrayDerivatives];
  const assetIds = arrayPositions.map((pos) => pos.asset.id);
  const pricesChangesResult = usePriceChanges(assetIds);

  if (!pricesChangesResult.loaded) {
    return <pricesChangesResult.Fallback />;
  }

  const navValues = arrayPositions
    .map((pos) => {
      if (!pos) return 0;

      let value: number;
      if (pos.spot) {
        value = bignumber(pos.spot.balance).div(totalNAV).toNumber();
      } else if (pos.derivative) {
        value = bignumber(pos.derivative.balance || 0)
          .div(totalNAV)
          .toNumber();
      } else {
        return 0;
      }

      return Math.abs(value) < NAV_PERC_MIN_DECIMAL_THRESHOLD ? 0 : value;
    })
    .filter((val: number) => !Number.isNaN(val));

  const minNavValue = navValues.some((val) => val < 0) ? SPARKLINE_Y_MIN_OFFSET : undefined;

  const columns: ColDef[] = [
    assetWithSymbolColumn({
      pinned: 'left',
      suppressHeaderMenuButton: true,
    }),
    {
      colId: 'account',
      suppressHeaderMenuButton: true,
      headerName: 'Account',
      type: 'textColumn',
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        innerRenderer: (params: ICellRendererParams) => {
          const accounts = params.data?.subAccount.map(
            (account: { name: string; venue: { label: string; id: string } }) => ({
              name: account.name,
              venue: { label: account.venue.label, id: account.venue.id },
            })
          );
          return <VenueIconList accounts={accounts} maxItems={3} />;
        },
      },
      filter: false,
      sortable: false,
    },
    navColumn({
      suppressHeaderMenuButton: true,
    }),
    notionalColumn({
      suppressHeaderMenuButton: true,
    }),
    navPercentageColumn({
      showBar: true,
      totalNAV: totalNAV.toNumber(),
      theme,
      minNavValue,
    }),
    amountColumn({ sideAware: true, initialHide: false, suppressHeaderMenuButton: true }),
    amountColumn({ sideAware: false, initialHide: true, suppressHeaderMenuButton: true }),
    unitCostBasisColumn(costBasisPerAssetAndSubFund, subAccountToCostBasisSubFundsMapper, {
      suppressHeaderMenuButton: true,
    }),
    // TODO: add back the price column once we have the price changes for the last 24h
    // priceWithLast24hPriceChangeColumn(
    //   pricesChangesResult.priceSummary,
    //   PriceSummaryType.latestToday,
    //   'Latest',
    //   pricesChangesResult.priceChanges24hByAssetId,
    //   { suppressHeaderMenuButton: true }
    // ),
    priceColumn(pricesChangesResult.priceSummary, PriceSummaryType.latestToday, 'Latest', {
      suppressHeaderMenuButton: true,
    }),
    unrealizedPnlWithColorsColumn(costBasisPerAssetAndSubFund, subAccountToCostBasisSubFundsMapper, {
      suppressHeaderMenuButton: true,
    }),
    sideWithBadgeColumn({ suppressHeaderMenuButton: true }),
  ];

  return (
    <Box height="500px">
      <GAgGrid
        rowData={arrayPositions}
        columnDefs={columns}
        autoSizeStrategy={{ type: 'fitCellContents' }}
        defaultColDef={{
          resizable: true,
          sortable: true,
          filter: false,
          pivot: true,
        }}
        rowHeight={gridWithInputStyles.rowHeight}
      />
    </Box>
  );
};

export default PositionSummaryLight;

import { Stack } from '@mui/joy';
import type { ReactElement } from 'react';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs.tsx';

import AppbarActions from './AppbarActions/AppbarActions';
import AppBarProfile from './AppbarProfile/AppBarProfile';
import AppbarSearch from './AppbarSearch/AppbarSearch';
import Navbar from './Navbar';

const Appbar = (): ReactElement => (
  <Navbar>
    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} rowGap={0.5} flexWrap="wrap">
      <Stack spacing={1}>
        <Breadcrumbs />
      </Stack>
      <Stack direction="row" spacing={1} alignItems="center" flexWrap="wrap">
        <AppbarSearch />
        <AppbarActions />
        <AppBarProfile />
      </Stack>
    </Stack>
  </Navbar>
);

export default Appbar;

import * as yup from 'yup';
import type { Schema } from 'yup';
import type { NotVerifiedAssetWithId } from '../../../../../market/asset/AssetService.tsx';

export interface AssetUniverseStepOutput {
  universe: (NotVerifiedAssetWithId & { label: string })[];
}

export interface AssetUniverseStepInput {
  universe: (NotVerifiedAssetWithId & { label: string })[];
}

export const schema: Schema<unknown> = yup.object({
  universe: yup
    .array(yup.object().required())
    .required('At least one asset is required')
    .min(1, 'At least one asset is required'),
});

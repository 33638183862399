import { Stack, Typography } from '@mui/joy';
import Card from '@mui/joy/Card';
import dayjs, { type Dayjs } from 'dayjs';
import type Highcharts from 'highcharts';
import uniq from 'lodash/fp/uniq';
import { bignumber } from 'mathjs';
import type { ReactElement, RefObject } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { UTC } from 'components/date.utils';
import { DateTimeFormat, formatDate, formatPercentage } from 'components/formatter.utils';
import { TwoThirdsLayout } from 'components/TwoThirdsLayout.tsx';
import {
  addHoverHighlight,
  dateTimeAxisFormat,
  dateTimeExportFormat,
  getChartClickEvent,
  getPointClickEvent,
  type HighChartRef,
  type HighchartSeries,
  removeHoverHighlight,
  type SelectedChartElement,
  tooltipFormat,
} from 'components/technical/charts/HighChartsWrapper/Highchart.utils.ts';
import HighChartsWrapper from 'components/technical/charts/HighChartsWrapper/HighChartsWrapper.tsx';
import SunburstChart from 'components/technical/charts/SunburstChart/SunburstChart.tsx';
import { calculateChartInput, type ElementChild } from 'components/technical/charts/SunburstChart/SunburstChart.utils';
import HeaderBar from 'components/technical/HeaderBar/HeaderBar';
import SectionColumn from 'components/technical/layout/Column/SectionColumn';
import Message from 'components/technical/Message.tsx';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';
import { type IStrategyAllocationQuery, useStrategyAllocationQuery } from 'generated/graphql';
import { getRegularChartColor } from '../../../theme/colors.ts';
import { useFinalColorScheme } from '../../../useFinalColorScheme.ts';
import bigNumMath from '../../../bigNumMath.ts';

const calculateChartData = (
  colorScheme: 'dark' | 'light',
  data: IStrategyAllocationQuery,
  ref: HighChartRef | null,
  onElementSelected: (element: SelectedChartElement) => void
): HighchartSeries[] => {
  const assetToData: Record<
    string,
    Extract<
      HighchartSeries,
      {
        type: 'column';
      }
    >
  > = {};

  for (const { assetAllocations, time } of data.strategy.assetAllocations) {
    for (const { asset, value } of assetAllocations) {
      const assetId = asset.id;
      if (!assetToData[assetId]) {
        assetToData[assetId] = {
          data: [],
          name: asset.symbol,
          type: 'column' as const,
          allowPointSelect: true,
          point: {
            events: {
              select: (event: Highcharts.PointInteractionEventObject): void =>
                getPointClickEvent(colorScheme, event, ref, onElementSelected),
              mouseOver: (): void => addHoverHighlight(colorScheme, ref?.chart),
              mouseOut: (): void => removeHoverHighlight(ref?.chart),
            },
          },
        };
      }
      assetToData[assetId].data.push({
        x: dayjs.utc(time).valueOf(),
        y: value,
        textValue: formatPercentage(value),
      });
    }
  }

  return Object.values(assetToData);
};

const calculateOptions = (
  colorScheme: 'dark' | 'light',
  chartRef: RefObject<null | HighChartRef>,
  onElementSelected: (element: SelectedChartElement) => void,
  strategy: string
): Highcharts.Options => {
  return {
    ...dateTimeAxisFormat,
    ...dateTimeExportFormat(`${strategy}-performance`),
    ...tooltipFormat,
    chart: {
      events: {
        click: (): void => {
          if (chartRef.current?.chart) {
            getChartClickEvent(colorScheme, chartRef.current.chart, onElementSelected, true);
          }
        },
      },
    },
    yAxis: {
      title: {
        text: undefined,
      },
      labels: {
        formatter: ({ value }) => formatPercentage(bignumber(value).toNumber()),
      },
    },
    plotOptions: {
      series: {
        stacking: 'normal',
      },
      column: {
        states: {
          select: {
            // Don't change appearance for selected bar
            color: undefined,
            borderColor: undefined,
          },
        },
      },
    },
  };
};

const StrategyAllocationSunburstContainer = ({
  elementXValue,
  data,
}: {
  elementXValue: Dayjs | undefined;
  data: IStrategyAllocationQuery['strategy']['assetAllocations'];
}): ReactElement => {
  const row = data.find((row) => dayjs(row.time).isSame(elementXValue));
  const colorScheme = useFinalColorScheme();
  if (!row) {
    return <Message>No data</Message>;
  }

  const orderedAssets = uniq(data.flatMap((snapshot) => snapshot.assetAllocations.map(({ asset }) => asset.id)));

  const sunburstData = calculateChartInput<{ value: number } & ElementChild>({
    root: {
      label: 'Net exposure',
      color: 'transparent',
      elements: row.assetAllocations.map(({ asset, value }) => ({
        color: getRegularChartColor(colorScheme, orderedAssets.indexOf(asset.id)),
        label: asset.name ?? asset.symbol,
        value,
        elements: [],
      })),
    },
    valueProvider: (val) => bignumber(val.value),
    textProvider: (val) => formatPercentage(val),
    rootValue: bigNumMath.sum(row.assetAllocations.map((al) => bignumber(al.value))),
  });

  return <SunburstChart data={{ ...sunburstData, hoverinfo: 'label+text', textinfo: 'label+text' }} />;
};

const StrategyAllocationSection = ({ strategy }: { strategy: string }): ReactElement => {
  const query = useStrategyAllocationQuery({ variables: { label: strategy } });
  const queryWithErrorHandling = useDefaultErrorHandling(query);
  const [elementXValue, setElementXValue] = useState<Dayjs | undefined>(undefined);
  const chartRef = useRef<null | HighChartRef>(null);
  const colorScheme = useFinalColorScheme();

  useEffect(() => {
    if (!queryWithErrorHandling.data) {
      return;
    }

    const latestTime = queryWithErrorHandling.data.strategy.assetAllocations.at(-1)?.time;
    if (latestTime) {
      setElementXValue(dayjs.utc(latestTime));
    }
  }, [queryWithErrorHandling.data]);

  const onElementSelected = useCallback((element: SelectedChartElement): void => setElementXValue(element.xValue), []);

  return (
    <TwoThirdsLayout
      left={
        <SectionColumn
          sx={{
            height: '100%',
          }}
        >
          <HeaderBar title="Historical allocation" />
          <Card
            sx={{
              height: '100%',
            }}
          >
            <HighChartsWrapper<IStrategyAllocationQuery>
              {...query}
              ref={chartRef}
              calculateChartData={(data): HighchartSeries[] =>
                calculateChartData(colorScheme, data, chartRef.current, onElementSelected)
              }
              calculateOptions={(): Highcharts.Options => {
                return calculateOptions(colorScheme, chartRef, onElementSelected, strategy);
              }}
            />
          </Card>
        </SectionColumn>
      }
      right={
        <SectionColumn>
          <HeaderBar title="Assets allocation" />
          <Card>
            {elementXValue && (
              <Stack direction="row" justifyContent="center" spacing={1.5}>
                <Typography>{formatDate(elementXValue, DateTimeFormat.Date, UTC)}</Typography>
              </Stack>
            )}
            {queryWithErrorHandling.loaded ? (
              <StrategyAllocationSunburstContainer
                data={queryWithErrorHandling.data.strategy.assetAllocations}
                elementXValue={elementXValue}
              />
            ) : (
              <queryWithErrorHandling.Fallback />
            )}
          </Card>
        </SectionColumn>
      }
    />
  );
};

export default StrategyAllocationSection;

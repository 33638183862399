import * as yup from 'yup';
import type { Schema, ValidationError } from 'yup';
import { yupWhen } from '../../../../../../validation.ts';
import type { InitialAssetsStepInput } from '../initialAssets/InitialAssetsStep.validation.ts';
import type { IAsset, IPoolInfo } from '../../../../../../generated/graphql.tsx';
import { bignumber } from 'mathjs';
import { isNil } from 'lodash/fp';
import { isValidNumber } from 'components/number.utils.ts';

type PoolAssetInfo = Pick<IPoolInfo, 'id' | 'poolName' | 'protocol'> & {
  collateralAsset: Pick<IAsset, 'symbol' | 'id'>;
  underlyingAsset: Pick<IAsset, 'symbol' | 'id'>;
};
export interface PoolUniverseStepOutput {
  universe: PoolAssetInfo[];
}

export interface PoolUniverseStepInput {
  universe: PoolAssetInfo[];
}

export const schema: Schema<unknown> = yup.object({
  universe: yupWhen(['givenPortfolio'], ([givenPortfolio]: [InitialAssetsStepInput['givenPortfolio']]) => {
    return yup
      .array(yup.mixed().required())
      .required('At least one pool is required')
      .min(1, 'At least one pool is required')
      .test('pool-for-asset', 'Each asset needs to have at least one pool', (value, ctx) => {
        if (isNil(givenPortfolio) || Object.keys(givenPortfolio).length === 0) {
          return true;
        }

        const safeBignumber = (value: string | number | undefined | null) =>
          isValidNumber(value) ? bignumber(value) : bignumber(0);

        const assets = Object.entries(givenPortfolio).filter(([, value]) => {
          return safeBignumber(value.amount).isPositive();
        });

        const castedValue = value as PoolAssetInfo[];
        const assetsInPools = new Set(castedValue.map((item) => item.collateralAsset.id));
        const errors: ValidationError[] = [];
        const errorPath = 'universe' as const;
        for (const asset of assets) {
          if (!assetsInPools.has(asset[0])) {
            errors.push(
              ctx.createError({
                message: `Asset ${asset[1]?.asset?.symbol ?? 'Unknown Asset'} doesn't have a pool`,
                path: errorPath,
              })
            );
          }
        }

        if (errors.length === 0) {
          return true;
        }

        return errors as unknown as ValidationError;
      });
  }),
});

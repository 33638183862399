import type { Theme } from '@mui/joy/styles/types';
import dayjs, { type Dayjs } from 'dayjs';
import { isNil } from 'lodash/fp';
import { bignumber, isZero } from 'mathjs';
import {
  type PredefinedRange,
  type PredefinedRangeLabel,
  getRangeByLabel,
  range7Days,
} from 'components/predefinedDateRanges';
import {
  type IPortfolioTwrEquityTileInputQuery,
  type IUserSettingsQuery,
  usePortfolioTwrEquityTileInputQuery,
} from 'generated/graphql';
import { DateTimeFormat, formatDate } from '../../../formatter.utils.ts';
import { useRef } from 'react';
import { useSubAccountAssetFilters } from '../../../technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters.tsx';
import { convertDateInUtcToUTCISODate } from '../../../date.utils.ts';
import { logErrorOnce } from '../../../log.utils.ts';

export type TileValues =
  | {
      change: number | undefined;
      minValue: number | undefined;
      maxValue: number | undefined;
      progressValue: number | undefined;
      latestValue: number | undefined;
    }
  | undefined;

const RETURNS_COLORING_THRESHOLD = 0.005;

export const useTwrEquityData = ({
  savedTimeFrameInDays,
}: { savedTimeFrameInDays: number }): {
  twr?: IPortfolioTwrEquityTileInputQuery['portfolio']['portfolioTimeWeightedReturns']['values'];
  loading: boolean;
  twrSince: Dayjs;
} => {
  const currentDate = useRef(dayjs.utc());
  const { subAccountAssetFilters } = useSubAccountAssetFilters();
  // we show end of day returns, so when someone wants to see returns since start of day we need to take snapshots one day earlier
  const twrSince = currentDate.current.subtract(savedTimeFrameInDays, 'day');
  const twrEquityInput = usePortfolioTwrEquityTileInputQuery({
    variables: {
      twrSince: convertDateInUtcToUTCISODate(twrSince ?? dayjs.utc()),
      twrTo: convertDateInUtcToUTCISODate(dayjs.utc()),
      subAccountAssetFilters,
    },
  });

  if (twrEquityInput.error) {
    logErrorOnce('Failed to fetch portfolio twr equity tiles data', twrEquityInput.error);
  }

  const twr = twrEquityInput.data?.portfolio.portfolioTimeWeightedReturns.values;
  return {
    twr,
    twrSince,
    loading: twrEquityInput.loading,
  };
};

export const getReturnsColor = (value: string | number, theme: Theme): string => {
  const numValue = bignumber(value).toNumber();
  if (numValue >= RETURNS_COLORING_THRESHOLD) {
    return theme.palette.success.plainColor;
  }

  if (numValue <= -RETURNS_COLORING_THRESHOLD) {
    return theme.palette.danger.plainColor;
  }

  return theme.palette.primary.softHoverBg;
};

export const calculateTooltipText = (date: Dayjs): string => {
  return `Time frame: ${formatDate(date, DateTimeFormat.ShortDate)} - today. Edit in Control center.`;
};

export const getTileValues = (data: { value: number | string }[] | undefined): TileValues => {
  if (!data || data.length === 0) {
    return;
  }

  const latestValue = bignumber(data.at(-1)?.value).toNumber()!;

  const yesterdayValue = data.length > 1 ? bignumber(data.at(-2)?.value).toNumber() : undefined;

  const change =
    yesterdayValue && !isZero(yesterdayValue)
      ? bignumber(latestValue).minus(yesterdayValue).div(yesterdayValue).toNumber()
      : undefined;

  const values = data.map((row) => bignumber(row.value).toNumber());

  const minValue = Math.min(...values);
  const maxValue = Math.max(...values);

  // how far is the latest value from minimum, latest = minimum -> 0%, latest = maximum -> 100%
  let progressValue: number | undefined = undefined;
  if (!isNil(latestValue) && minValue !== maxValue) {
    progressValue = (100 * (latestValue - minValue)) / (maxValue - minValue);
  }

  return {
    change,
    minValue,
    maxValue,
    progressValue,
    latestValue,
  };
};

export function getSavedDaysRange(userSettingsQueryData: IUserSettingsQuery): {
  savedTimeFrameInDays: number;
  savedDateRange: PredefinedRange;
} {
  const dateRangeLabel: PredefinedRangeLabel | undefined = userSettingsQueryData.management.userSettings;
  let savedDateRange = dateRangeLabel ? getRangeByLabel(dateRangeLabel) : undefined;
  if (!savedDateRange || !savedDateRange.value()) {
    savedDateRange = range7Days;
  }
  const since = savedDateRange.value()![0]; // default range value() is always defined
  const savedTimeFrameInDays = dayjs.utc().diff(since, 'day') + 1;
  return {
    savedTimeFrameInDays,
    savedDateRange,
  };
}

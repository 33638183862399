import {
  type IAsset,
  type ISubmitYieldOptimizationMutation,
  type ISubmitYieldOptimizationMutationVariables,
  type IYieldOptimizerWizardInputQuery,
  useSubmitYieldOptimizationMutation,
  useYieldOptimizerWizardInputSuspenseQuery,
} from '../../../../../generated/graphql.tsx';
import { type ReactElement, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import gYupResolver from '../../../../technical/form/gYupResolver.ts';
import { type YieldOptimizerInputFields, schema } from './YieldOptimizer.validation.ts';
import { useSteps } from '../../../../technical/wizard/UseSteps.ts';
import { config as descriptionStepConfig } from './description/DescriptionStepConfig.tsx';
import { config as submitConfig } from './submit/SubmitStepConfig.tsx';
import { useGraphQLApiError } from '../../../../technical/form/UseGraphQLApiError.tsx';
import { OptimizationType } from '../../optimization.utils.ts';
import OptimizerWizard from '../portfolio/OptimizerWizard.tsx';
import { config as initialAssetsStepConfig } from './initialAssets/InitialAssetsStepConfig.tsx';
import { config as poolUniverseStepConfig } from './poolUniverse/PoolUniverseStepConfig.tsx';
import { config as allocationConstraintConfig } from './allocationConstraints/AllocationConstraintsStepConfig.tsx';
import { bignumber, type BigNumber } from 'mathjs';
import { isNil, uniqBy } from 'lodash/fp';
import { createRequestInput } from './YieldOptimizerRequestFactory.ts';
import type { YieldOptimizerOutputFields } from './YieldOptimizer.validation.ts';
import type { NotVerifiedAssetWithId } from 'components/market/asset/AssetService.tsx';

const YieldOptimizerWizardContainer = () => {
  const yieldOptimizerWizardInputQuery = useYieldOptimizerWizardInputSuspenseQuery();

  const { yieldOptimization, portfolio } = yieldOptimizerWizardInputQuery.data;
  return <YieldOptimizerWizard pools={yieldOptimization.listPools} positions={portfolio.positions.positions} />;
};

const YieldOptimizerWizard = ({
  pools,
  positions,
}: {
  pools: IYieldOptimizerWizardInputQuery['yieldOptimization']['listPools'];
  positions: IYieldOptimizerWizardInputQuery['portfolio']['positions']['positions'];
}): ReactElement => {
  const availableUnderlyingAssets = useMemo(
    () =>
      uniqBy(
        (asset) => asset?.id,
        pools.map((pool) => pool.underlyingAsset).filter((asset) => !isNil(asset)) as (NotVerifiedAssetWithId &
          Pick<IAsset, 'symbol' | 'name' | 'type'>)[]
      ) || [],
    [pools]
  );

  const availableCollateral = useMemo(
    () =>
      uniqBy(
        (asset) => asset.id,
        pools.map((pool) => pool.collateralAsset)
      ),
    [pools]
  );

  const methods = useForm<YieldOptimizerInputFields>({
    resolver: gYupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      description: '',
      useUnderlying: false,
      universe: [],
      constraints: [],
      givenPortfolio: {},
    },
  });

  const stepApi = useSteps();
  const { goToStep, validateVisitedSteps } = stepApi;

  const name = useWatch({
    name: 'name',
    control: methods.control,
  });

  const useUnderlying = useWatch({
    name: 'useUnderlying',
    control: methods.control,
  });

  const steps = useMemo(() => {
    let nextIndex = 1;
    const nextHandler = (): (() => void) => {
      const index = nextIndex++;
      return (): void => goToStep(index);
    };

    const availableCollateralIds = new Set(availableCollateral.map((asset) => asset.id));
    const groupedCollateralAmount = new Map<string, BigNumber>();

    for (const pos of positions) {
      const assetId = pos.asset.id;
      if (!availableCollateralIds.has(assetId)) {
        continue;
      }

      if (isNil(pos.spot)) {
        continue;
      }

      const currentValue = groupedCollateralAmount.get(assetId) ?? bignumber(0);
      groupedCollateralAmount.set(assetId, currentValue.add(pos.spot.amount));
    }

    const buildUnderlyingAssetMap = (
      pools: IYieldOptimizerWizardInputQuery['yieldOptimization']['listPools']
    ): Map<string, string> => {
      return pools.reduce((map, pool) => {
        const collateralSymbol = pool.collateralAsset.symbol;
        const underlyingSymbol = pool.underlyingAsset.symbol;

        // Ensure we don't overwrite existing mappings if multiple pools use the same assets
        if (!map.has(collateralSymbol)) {
          map.set(collateralSymbol, underlyingSymbol);
        }
        return map;
      }, new Map<string, string>());
    };

    const underlyingAssetMap = buildUnderlyingAssetMap(pools);

    return [
      descriptionStepConfig(nextHandler()),
      initialAssetsStepConfig(
        availableCollateral,
        groupedCollateralAmount,
        availableUnderlyingAssets,
        underlyingAssetMap,
        nextHandler()
      ),
      poolUniverseStepConfig(pools, nextHandler()),
      allocationConstraintConfig(nextHandler()),
      submitConfig(),
    ];
  }, [goToStep, pools, positions, availableCollateral, availableUnderlyingAssets]);

  validateVisitedSteps({
    trigger: methods.trigger,
    steps,
  });
  const { onErrorAndThrow } = useGraphQLApiError(methods);
  const [submitOptimization] = useSubmitYieldOptimizationMutation();

  return (
    <OptimizerWizard<
      YieldOptimizerInputFields,
      ISubmitYieldOptimizationMutationVariables['input'],
      ISubmitYieldOptimizationMutation
    >
      type={OptimizationType.yield}
      useUnderlying={useUnderlying}
      createRequestInput={(input) => createRequestInput(input as unknown as YieldOptimizerOutputFields)}
      methods={methods}
      name={name}
      onErrorAndThrow={onErrorAndThrow}
      stepApi={stepApi}
      steps={steps}
      submitOptimization={submitOptimization}
      getOptimizationId={(result) => result.optimization.optimizationId}
    />
  );
};

export default YieldOptimizerWizardContainer;

import { Modal, ModalDialog, Stack } from '@mui/joy';
import { type ReactElement, useState } from 'react';
import GDialogHeader from 'components/technical/GDialog/GDialogHeader.tsx';

import { Header3 } from 'components/technical/Header3';
import { Pencil } from 'components/technical/icons';
import GButton from 'components/technical/inputs/GButton/GButton';
import type { Aggregation } from './PositionAggregationsService.ts';
import { type AggItem, AggregationList } from './AggregationList.tsx';

const EditSunburstAggregationsDialog = ({
  onClose,
  onEdited,
  availableAggregations,
  activeAggregations,
}: {
  onClose: () => void;
  onEdited: (aggs: { category: string; active: boolean }[]) => void;
  availableAggregations: Pick<Aggregation<unknown>, 'label' | 'category'>[];
  activeAggregations: string[];
}): ReactElement => {
  const [items, setItems] = useState<AggItem[]>(
    availableAggregations.map((agg) => ({
      id: agg.category,
      label: agg.label,
      category: agg.category,
      checked: activeAggregations.includes(agg.category),
    }))
  );

  return (
    <Modal open={true} onClose={onClose}>
      <ModalDialog>
        <GDialogHeader>Configure portfolio balance chart</GDialogHeader>
        <Stack spacing={1.5} alignItems="center">
          <div>
            <Header3 title="Aggregations" />
            <AggregationList aggs={items} setAggs={setItems} />
          </div>
          <GButton
            color="primary"
            onClick={(): void => {
              onEdited(
                items.map((item) => ({
                  category: item.category,
                  active: item.checked,
                }))
              );
            }}
            startDecorator={<Pencil />}
          >
            Save configuration
          </GButton>
        </Stack>
      </ModalDialog>
    </Modal>
  );
};

export default EditSunburstAggregationsDialog;

import { GridView } from '@mui/icons-material';
import type { CustomWidgetDescription } from 'components/management/customDashboard/CustomWidgetDescription.types.ts';
import PositionsSummary from './PositionsSummary.tsx';
import { AgGridTitleSettings } from '../../management/customDashboard/AgGridWithTitleSettings.tsx';

const createOpenSummaryPresetKey = (id: string) => `customWidget/summaryOpenPositions/${id}`;

export const openPositionWidgets: CustomWidgetDescription[] = [
  {
    name: 'Open positions summary',
    type: 'summaryOpenPositions',
    description: 'List of open positions',
    icon: () => <GridView />,
    viewComponent: ({ id }) => <PositionsSummary presetSettingsKey={createOpenSummaryPresetKey(id)} />,
    settingsComponent: (props) => <AgGridTitleSettings {...props} presetKey={createOpenSummaryPresetKey(props.id)} />,
    initialH: 4,
    initialW: 3,
  },
];

import { Box, Stack } from '@mui/joy';
import { defaultRowSpacing } from 'components/StackSpacing';
import GAgGrid from 'components/technical/grids/GAgGrid';
import GButton from 'components/technical/inputs/GButton/GButton';
import { type ReactElement, type ReactNode, useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import type { ColDef, ValueGetterParams } from 'ag-grid-community';
import { gridWithInputStyles } from 'components/technical/grids/gridStyles';
import { type BigNumber, bignumber } from 'mathjs';
import { defaultCol } from '../../initialPortfolio/InitialPortfolioStep.utils.tsx';
import type { YieldOptimizerInputFields } from '../YieldOptimizer.validation.ts';
import type { NotVerifiedAssetWithId } from '../../../../../market/asset/AssetService.tsx';
import type { ICellRendererParams } from 'ag-grid-enterprise';
import { FormInput } from '../../../../../technical/inputs';
import { syncInitialAssetsWithPoolsAndConstraints } from './InitialAssetStep.utils.ts';
import { isNil } from 'lodash/fp';
import { nameColumn, symbolColumn } from '../../../../../technical/grids/SharedReportColumns.tsx';
import type { IAsset } from '../../../../../../generated/graphql.tsx';
import { isValidNumber } from 'components/number.utils.ts';
import { useUnmount } from 'react-use';

type Row = { asset: NotVerifiedAssetWithId & Pick<IAsset, 'symbol' | 'name' | 'type'> };

export const InitialAssetInputCellRenderer = (props: ICellRendererParams<Row, ReactNode>): ReactElement | null => {
  const id = props.data?.asset.id;

  const {
    formState: { isSubmitting },
    getValues,
    setValue,
  } = useFormContext<YieldOptimizerInputFields>();

  useUnmount(() => {
    handleBlur();
  });

  if (!id) {
    return null;
  }

  const handleBlur = () => {
    setValue(`givenPortfolio.${id}.asset`, props.data!.asset, { shouldValidate: false, shouldDirty: true });
    syncInitialAssetsWithPoolsAndConstraints(getValues, setValue);
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <FormInput<YieldOptimizerInputFields>
        type="number"
        name={`givenPortfolio.${id}.amount` as const}
        width="fullWidth"
        errorInTooltip={true}
        disabled={isSubmitting}
        onBlur={handleBlur}
      />
    </Stack>
  );
};

const InitialAssetsStep = ({
  collateralAssets,
  underlyingAssets,
  goToNextStep,
  aggregatedPortfolioAmountByAsset,
  underlyingAssetMap,
}: {
  collateralAssets: (NotVerifiedAssetWithId & Pick<IAsset, 'symbol' | 'name' | 'type'>)[];
  underlyingAssets: (NotVerifiedAssetWithId & Pick<IAsset, 'symbol' | 'name' | 'type'>)[];
  goToNextStep: () => void;
  aggregatedPortfolioAmountByAsset: Map<string, BigNumber>;
  underlyingAssetMap: Map<string, string>;
}): ReactElement => {
  const { getValues, setValue } = useFormContext<YieldOptimizerInputFields>();

  const [shownAssets, setShownAssets] = useState(collateralAssets);

  const useUnderlying = useWatch<YieldOptimizerInputFields, 'useUnderlying'>({
    name: 'useUnderlying',
  });

  useEffect(() => {
    if (useUnderlying) {
      setShownAssets(underlyingAssets);
      setValue('givenPortfolio', {}); // Clear the portfolio when switching
    } else {
      setShownAssets(collateralAssets);
    }
  }, [useUnderlying, collateralAssets, underlyingAssets, setValue]);

  const columns: ColDef<Row>[] = useMemo(
    () => [
      nameColumn({ initialHide: false, initialSort: 'asc', sortIndex: 1 }),
      symbolColumn({ initialHide: false }),
      {
        colId: 'assetAmount',
        headerName: 'Amount',
        sortIndex: 0,
        initialSort: 'desc',
        cellRenderer: InitialAssetInputCellRenderer,
        cellStyle: gridWithInputStyles.inputCellStyle,
        filter: 'agNumberColumnFilter',
        valueGetter: (params: ValueGetterParams<Row, number>): number | undefined => {
          if (!params.data?.asset) {
            return undefined;
          }
          const assetId = params.data.asset.id;
          const givenPortfolioAssetInfo = getValues(`givenPortfolio.${assetId}`);

          if (isNil(givenPortfolioAssetInfo?.amount)) {
            return 0;
          }

          if (!isValidNumber(givenPortfolioAssetInfo.amount)) {
            return 0;
          }

          return bignumber(givenPortfolioAssetInfo.amount).toNumber();
        },
      },
    ],
    [getValues]
  );

  const importPortfolio = () => {
    // Build a map of asset amounts, merging collateral into underlying when needed
    const amountByAssetId = collateralAssets.reduce((acc, asset) => {
      const amount = aggregatedPortfolioAmountByAsset.get(asset.id) ?? bignumber(0);

      // If `useUnderlying` is enabled, merge collateral into underlying
      const underlyingSymbol = useUnderlying ? underlyingAssetMap.get(asset.symbol) : undefined;
      const underlyingAsset = underlyingSymbol ? shownAssets.find((a) => a.symbol === underlyingSymbol) : undefined;

      const targetAssetId = underlyingAsset?.id ?? asset.id;
      acc.set(targetAssetId, (acc.get(targetAssetId) ?? bignumber(0)).plus(amount));

      return acc;
    }, new Map<string, BigNumber>());

    // Precompute a lookup for both collateral and underlying assets
    const assetLookup = new Map<string, NotVerifiedAssetWithId & Pick<IAsset, 'symbol' | 'name' | 'type'>>([
      ...collateralAssets.map((a): [string, NotVerifiedAssetWithId & Pick<IAsset, 'symbol' | 'name' | 'type'>] => [
        a.id,
        a,
      ]),
      ...underlyingAssets.map((a): [string, NotVerifiedAssetWithId & Pick<IAsset, 'symbol' | 'name' | 'type'>] => [
        a.id,
        a,
      ]),
    ]);

    // Convert stored amounts to the final format
    const importedGivenPortfolioInAmount = Object.fromEntries(
      Array.from(amountByAssetId.entries()).map(([id, amount]) => [
        id,
        {
          asset: assetLookup.get(id) ?? ({} as NotVerifiedAssetWithId & Pick<IAsset, 'symbol' | 'name' | 'type'>),
          amount: amount.isZero() ? null : amount.toDP(4).toString(),
        },
      ])
    );

    // Filter out values with precision < 0.0001 using BigNumber
    const filteredGivenPortfolioInAmount = Object.fromEntries(
      Object.entries(importedGivenPortfolioInAmount).filter(([_, value]) => {
        if (value?.amount) {
          return bignumber(value.amount).gte('0.0001'); // Uses BigNumber for precise comparison
        }
        return false;
      })
    );
    setValue('givenPortfolio', filteredGivenPortfolioInAmount);
    syncInitialAssetsWithPoolsAndConstraints(getValues, setValue);
  };

  return (
    <Stack spacing={defaultRowSpacing}>
      <Stack spacing={defaultRowSpacing} direction="row-reverse">
        <GButton
          variant="plain"
          onClick={(): void => {
            const emptyGivenPortfolio = Object.fromEntries(
              shownAssets.map((asset) => [
                asset.id,
                {
                  asset: asset,
                  amount: null,
                },
              ])
            );
            setValue('givenPortfolio', emptyGivenPortfolio);
            syncInitialAssetsWithPoolsAndConstraints(getValues, setValue);
          }}
        >
          Clear all
        </GButton>
        <GButton onClick={importPortfolio}>Import from portfolio</GButton>
      </Stack>
      <Box height="500px">
        <GAgGrid<Row>
          rowData={shownAssets.map((asset) => ({ asset }))}
          getRowId={(params) => params.data.asset.id}
          columnDefs={columns}
          defaultColDef={defaultCol}
          autoSizeStrategy={{ type: 'fitGridWidth' }}
          rowHeight={gridWithInputStyles.rowHeight}
        />
      </Box>
      <GButton onClick={goToNextStep} sx={{ marginLeft: 'auto' }}>
        Next
      </GButton>
    </Stack>
  );
};

export default InitialAssetsStep;

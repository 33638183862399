// Extend Joy UI Card component with custom styles
import { Card as JoyCard, type CardProps } from '@mui/joy';
import type { FunctionComponent } from 'react';

interface BaseCardProps extends CardProps {
  transparent?: boolean;
}

const BaseCard: FunctionComponent<BaseCardProps> = ({ children, transparent, ...props }) => {
  return (
    <JoyCard
      {...props}
      sx={
        transparent
          ? {
              boxShadow: 'none',
              border: 'none',
              backgroundColor: 'transparent',
            }
          : undefined
      }
    >
      {children}
    </JoyCard>
  );
};

export default BaseCard;

import isNil from 'lodash/fp/isNil';
import type { ReactElement } from 'react';
import { useState } from 'react';
import Message from 'components/technical/Message';

import SelectableTabs from 'components/technical/SelectableTabs/SelectableTabs';
import OptionsStressTestBaseAssetSimulator from './OptionsStressTestBaseAssetSimulator';
import { useOptionsBaseAssets } from '../../market/asset/useOptionsBaseAssetId';
import { Stack } from '@mui/joy';

const OptionsStressTestSimulator = ({
  optionBaseAssets,
}: {
  optionBaseAssets: { id: string; symbol: string }[];
}): ReactElement => {
  const [assetId, setAssetId] = useState<string | undefined>(optionBaseAssets.at(0)?.id);

  if (isNil(assetId)) {
    return <Message>No options base assets</Message>;
  }

  return (
    <Stack spacing={3}>
      <SelectableTabs
        tabs={optionBaseAssets.map((asset) => ({
          label: asset.symbol,
          value: asset.id,
        }))}
        onChange={(id): void => setAssetId(id)}
        value={assetId}
      />
      <OptionsStressTestBaseAssetSimulator assetId={assetId} key={assetId} />
    </Stack>
  );
};

const OptionsStressTestContainer = (): ReactElement => {
  const { loaded, data, Fallback } = useOptionsBaseAssets();
  if (!loaded) {
    return <Fallback />;
  }

  return <OptionsStressTestSimulator optionBaseAssets={data} />;
};
export default OptionsStressTestContainer;

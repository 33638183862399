import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import type { Aggregation } from '../PositionAggregationsService.ts';
import { createAggregations } from '../../../../UseUserAggregations.tsx';
import { calculateAllAggregations, type PortfolioSunburstPosition } from './PortfolioSnapshotSunburstContainer.tsx';
import { useCurrentExposureSettingsInputSuspenseQuery } from '../../../../generated/graphql.tsx';
import WidgetSettings from 'components/management/customDashboard/WidgetSettings.tsx';
import { updateWidgetState, useWidgetState } from 'components/management/customDashboard/CustomWidgetContext.tsx';
import gYupResolver from '../../../technical/form/gYupResolver.ts';
import { type AggItem, AggregationList } from '../AggregationList.tsx';
import { useState } from 'react';

const schema = yup.object({
  title: yup.string().required(),
});

interface FormState {
  title: string;
}

export interface CurrentExposureWidgetState {
  title: string;
  aggs: { active: boolean; category: string }[];
}

const CurrentExposureSettings = ({
  id,
  onFinishedEditing,
  onRemovedWidget,
  title,
  activeAggsOrdered,
  allAggs,
}: {
  id: string;
  onFinishedEditing: () => void;
  onRemovedWidget: () => Promise<void>;
  title: string;
  activeAggsOrdered: string[];
  allAggs: Aggregation<PortfolioSunburstPosition>[];
}) => {
  const updater = updateWidgetState<CurrentExposureWidgetState>();
  // kept outside the state, always valid, so no validation required
  const [items, setItems] = useState<AggItem[]>(
    allAggs.map((agg) => ({
      id: agg.category,
      label: agg.label,
      category: agg.category,
      checked: activeAggsOrdered.includes(agg.category),
    }))
  );

  const methods = useForm<FormState>({
    resolver: gYupResolver(schema),
    defaultValues: {
      title,
    },
  });

  const handleFormSubmit = async (input: FormState): Promise<void> => {
    await updater({
      id,
      state: {
        title: input.title,
        aggs: items.map((item) => ({
          category: item.category,
          active: item.checked,
        })),
      },
      updateBackend: true,
    });

    onFinishedEditing();
  };

  return (
    <WidgetSettings methods={methods} handleFormSubmit={handleFormSubmit} onRemovedWidget={onRemovedWidget}>
      <AggregationList aggs={items} setAggs={setItems} />
    </WidgetSettings>
  );
};

export const CurrentExposureSettingsContainer = ({
  id,
  onFinishedEditing,
  onRemovedWidget,
}: {
  id: string;
  onFinishedEditing: () => void;
  onRemovedWidget: () => Promise<void>;
}) => {
  const initialState = useWidgetState<CurrentExposureWidgetState>(id);
  const { data } = useCurrentExposureSettingsInputSuspenseQuery();
  const { allAggs, activeAggregationsOrdered } = createAggregations({
    savedAggs: initialState.aggs ?? [],
    defaultAggs: [],
    allAvailableAggregations: calculateAllAggregations(data.assets.assetGroups),
  });

  return (
    <CurrentExposureSettings
      id={id}
      title={initialState.title}
      onFinishedEditing={onFinishedEditing}
      onRemovedWidget={onRemovedWidget}
      activeAggsOrdered={activeAggregationsOrdered}
      allAggs={allAggs}
    />
  );
};

import { Grid, Stack, Typography } from '@mui/joy';
import { defaultRowSpacing } from '../../../../StackSpacing.ts';
import { formatCash, formatPercentage } from '../../../../formatter.utils.ts';
import Loader from 'components/technical/Loader/Loader.tsx';
import isNil from 'lodash/fp/isNil';
import YieldResultTile from './YieldResultTile.tsx';
import type { ReactElement } from 'react';
import YieldAllocationGrid from './YieldAllocationGrid.tsx';
import type { Optimization, OptimizationOutput } from './YieldOptimizerResult.types.ts';
import sortBy from 'lodash/fp/sortBy';
import uniq from 'lodash/fp/uniq';
import YieldAllocationChart from './YieldAllocationChart.tsx';
import { bignumber } from 'mathjs';
import TitleWithChip from 'components/technical/Tile/TitleWithChip.tsx';

const gap = 2;
const YieldOptimizerResult = ({ output }: { output: OptimizationOutput }): ReactElement => {
  const apyNaiveRange = sortBy(
    (val) => val,
    uniq(output.naiveAllocationApys.map((all) => output.yieldInUsd - all.yieldUsd))
  );
  const apyNaiveRangeAPY = sortBy(
    (val) => val,
    uniq(output.naiveAllocationApys.map((all) => (output.globalApy - all.impactedApy) * 10000))
  );

  const totalLeverage = formatCash(
    output.allocations.reduce((acc, alloc) => acc.add(alloc.dollarValueOfAllocation), bignumber(0)).toString()
  );
  return (
    <Stack gap={defaultRowSpacing}>
      <Grid container spacing={gap}>
        <YieldResultTile title={'Predicted returns'} value={formatPercentage(output.globalApy, 3)} withSign />
        <YieldResultTile title={'Returns @ current price'} value={formatCash(output.yieldInUsd)} />
        <YieldResultTile title={'Total value'} value={totalLeverage} />
        <YieldResultTile
          title={<TitleWithChip title={'Alpha vs single pool'} change={apyNaiveRangeAPY} type="bps" />}
          value={`${apyNaiveRange.map((val) => formatCash(val, 'short')).join(' — ')}`}
        />
      </Grid>
      <Grid container spacing={gap}>
        <Grid xs={12} sm={12} md={6} lg={3} direction={'row'} gap={gap}>
          <YieldAllocationChart data={output.allocations} type={'pool'} title={'Pool'} />
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} direction={'row'} gap={gap}>
          <YieldAllocationChart data={output.allocations} type={'protocol'} title={'Protocol'} />
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} direction={'row'} gap={gap}>
          <YieldAllocationChart data={output.allocations} type={'chain'} title={'Chain'} />
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} direction={'row'} gap={gap}>
          <YieldAllocationChart data={output.allocations} type={'collateralAsset'} title={'Collateral Asset'} />
        </Grid>
        <Grid xs={12}>
          <YieldAllocationGrid data={output.allocations} useUnderlying={output.groupPoolsByUnderlying} />
        </Grid>
      </Grid>
    </Stack>
  );
};

const YieldOptimizerResultWrapper = ({ optimization }: { optimization: Optimization }) => {
  const output = optimization.output;
  return (
    <Stack gap={2}>
      <Typography level="h2">{optimization.name}</Typography>
      {isNil(output) ? <Loader /> : <YieldOptimizerResult output={output} />}
    </Stack>
  );
};

export default YieldOptimizerResultWrapper;

import HighChartsWrapper from '../../technical/charts/HighChartsWrapper/HighChartsWrapper.tsx';
import type { Options } from 'highcharts';
import {
  dateTimeAxisFormat,
  type HighchartSeries,
  percentageFormatter as highPercentageFormatter,
  calculateMultiChartParams,
  defaultTooltipDateHeader,
  defaultTooltipPointColor,
  highChartsNegativeColorIndex,
  highChartsPositiveColorIndex,
  highChartsNeutralColorIndex,
  getHighchartColorWithOpacity,
  percentageFormatter,
} from '../../technical/charts/HighChartsWrapper/Highchart.utils.ts';
import { parseUtcDate } from '../../date.utils.ts';
import {
  DateTimeFormat,
  formatCash,
  formatDate,
  formatEnum,
  formatISODate,
  formatPercentage,
} from 'components/formatter.utils.ts';
import { TupleKeyMap } from '../../TupleKeyMap.ts';
import { useMarketRegimeBenchmarkPriceSuspenseQuery, useMarketRegimeSuspenseQuery } from 'generated/graphql.tsx';
import sortBy from 'lodash/fp/sortBy';
import groupBy from 'lodash/fp/groupBy';
import dayjs from 'dayjs';
import { useFinalColorScheme } from '../../../useFinalColorScheme.ts';
import { bignumber } from 'mathjs';
import isNil from 'lodash/fp/isNil';
import uniqBy from 'lodash/fp/uniqBy';

interface SerieUserData {
  modelId?: number;
  assetId?: string;
}

interface CreateSeries {
  type: 'line' | 'area';
  id: string;
  name: string;
  yAxis: number;
  dataPoints: { x: number; y?: number; textValue: string }[];
  color: string;
  modelId?: number;
  assetId?: string;
}

const regimeColor: Record<string, number> = {
  bear: highChartsNegativeColorIndex,
  bull: highChartsPositiveColorIndex,
  side: highChartsNeutralColorIndex,
};

const calculateOptions = (
  predictions: { model: { name: string; id: string } }[],
  assets: { symbol: string; id: string }[]
): Options => {
  const { yAxis, chartHeight, chartSpacingTop } = calculateMultiChartParams({
    items: predictions.length,
    singleItemHeight: 300,
    legendMaxHeight: 0,
  });

  return {
    ...dateTimeAxisFormat,
    tooltip: {
      formatter: function (): string {
        const timestamp = this.x;
        const text: string[][] = [];
        const points = (
          this as unknown as {
            points: {
              color: string;
              point: {
                textValue: string;
              };
              series: {
                name: string;
                yAxis: {
                  index: number;
                };
                userOptions: { userOptions: SerieUserData };
              };
            }[];
          }
        ).points;

        // Filter out benchmark points and create a list of unique objects by assetId
        const uniqueBenchmarkPoints = uniqBy(
          'series.userOptions.userOptions.assetId',
          points.filter((p) => !isNil(p.series.userOptions.userOptions.assetId))
        );

        const benchmarkPoints = sortBy((point) => point.series.name.toLowerCase(), uniqueBenchmarkPoints);
        const benchmarkText = [];
        for (const benchmark of benchmarkPoints) {
          benchmarkText.push(
            `${defaultTooltipPointColor(benchmark.color)} ${benchmark.series.name}: <b>${benchmark.point.textValue}</b>`
          );
        }

        text.push(benchmarkText);

        const modelIdToPoints = new Map(
          Object.entries(
            groupBy(
              (p) => p.series.userOptions.userOptions.modelId,
              points.filter((p) => !isNil(p.series.userOptions.userOptions.modelId))
            )
          )
        );

        // Area chart Tooltip for each model
        for (const [modelId, modelPoints] of modelIdToPoints.entries()) {
          const portfolioText: string[] = [];
          portfolioText.push(`<b>${predictions[Number(modelId)].model.name}:</b>`);
          const sortedByNamePoints = sortBy((p) => p.series.name, modelPoints);
          for (const point of sortedByNamePoints) {
            portfolioText.push(
              `${defaultTooltipPointColor(point.color)} ${point.series.name}: <b>${point.point.textValue}</b>`
            );
          }
          text.push(portfolioText);
        }

        const header = defaultTooltipDateHeader(formatDate(dayjs.utc(timestamp), DateTimeFormat.LongDate));
        return [header, text.map((itemText) => itemText.join('<br>')).join('<br><br>')].join('<br>');
      },
    },
    yAxis: predictions.flatMap((pred, i) => [
      {
        title: { text: pred.model.name },
        ...yAxis(i),
        endOnTick: false,
        labels: {
          formatter: (ctx: { value: number | string }): string => {
            const val = typeof ctx.value === 'string' ? Number.parseFloat(ctx.value) : ctx.value;
            return highPercentageFormatter({ value: val / 100 });
          },
        },
      },
      ...(assets.length > 0
        ? [
            {
              title: { text: 'Normalized Price' },
              opposite: true,
              ...yAxis(i),
              labels: {
                formatter: percentageFormatter,
              },
            },
          ]
        : []),
    ]),
    chart: {
      height: chartHeight,
      spacingTop: chartSpacingTop,
    },
    legend: {
      enabled: true,
    },
    plotOptions: {
      area: {
        stacking: 'percent',
      },
    },
  };
};

const calculateChartData = ({
  colorScheme,
  data,
  prices,
  assets,
}: {
  colorScheme: 'dark' | 'light';
  assets: { symbol: string; id: string }[];
  data: {
    model: { name: string; id: string };
    timeseries: {
      date: UtcDate;
      values: { name: string; value: number }[];
    }[];
  }[];
  prices: { [assetId: string]: { date: UtcDate; price: string }[] };
}): HighchartSeries[] => {
  const series: HighchartSeries[] = [];
  const p = data.length;

  const uniqueIds = new Set<string>();

  function createSeries({ type, id, name, yAxis, dataPoints, color, modelId, assetId }: CreateSeries): HighchartSeries {
    const isFirstOccurrence = !uniqueIds.has(id);
    if (isFirstOccurrence) {
      uniqueIds.add(id);
    }

    const baseSeries: HighchartSeries & { userOptions?: SerieUserData } = {
      type,
      name,
      yAxis,
      data: dataPoints,
      color,
      showInLegend: isFirstOccurrence,
      ...(isFirstOccurrence ? { id } : { linkedTo: id }),
      userOptions: {
        modelId,
        assetId,
      },
    };

    return baseSeries;
  }

  for (let i = 0; i < p; i++) {
    const model = data[i];
    const labels = new Set<string>();
    const dateToRegimeToValue = new TupleKeyMap<[UtcDate, string], number>();

    // Process model regimes
    for (const day of model.timeseries) {
      for (const regime of day.values) {
        labels.add(regime.name);
        dateToRegimeToValue.set([day.date, regime.name], regime.value);
      }
    }

    // Create area series for each regime
    for (const regime of labels.values()) {
      const regimeDataPoints = model.timeseries.map((d) => {
        const val = dateToRegimeToValue.get([d.date, regime]);
        return { x: parseUtcDate(d.date).valueOf(), y: val, textValue: formatPercentage(val) };
      });

      regimeDataPoints.sort((a, b) => a.x - b.x);

      const name = formatEnum(regime);
      const areaSeries = createSeries({
        type: 'area',
        id: name,
        name,
        yAxis: 2 * i,
        dataPoints: regimeDataPoints,
        color: getHighchartColorWithOpacity(colorScheme, regimeColor[regime.toLowerCase()], 0.8),
        modelId: i,
      });

      series.push(areaSeries);
    }

    // Asset lines for this model
    assets &&
      assets.length > 0 &&
      assets.forEach((asset, assetIndex) => {
        const priceRows = prices[asset.id] ?? [];
        const priceLineData = priceRows.map((dayValue) => ({
          x: parseUtcDate(dayValue.date).valueOf(),
          y: bignumber(dayValue.price).toNumber(),
          textValue: formatCash(dayValue.price),
        }));

        priceLineData.sort((a, b) => a.x - b.x);

        const initialPrice = priceLineData[0]?.y ?? 1;
        const normalizedPriceData = priceLineData.map((row) => {
          const normalizedPrice = (row.y ?? 0) / initialPrice;
          return {
            x: row.x,
            y: normalizedPrice,
            textValue: formatPercentage(normalizedPrice),
          };
        });

        const lineSeries = createSeries({
          type: 'line',
          id: asset.id,
          name: asset.symbol,
          yAxis: 2 * i + 1,
          dataPoints: normalizedPriceData,
          color: getHighchartColorWithOpacity(colorScheme, 2 + assetIndex, 1),
          assetId: asset.id,
        });
        series.push(lineSeries);
      });
  }

  return series;
};

export interface MarketRegimeResultInput {
  since?: UtcDate | null;
  to?: UtcDate | null;
  assets: {
    id: string;
    symbol: string;
  }[];
  models: {
    id: string;
    name: string;
    benchmark?: { id: string; name?: string | null } | null;
  }[];
  useSingleModelMode: boolean;
}

interface ChartData {
  key: string;
  predictions: {
    model: { name: string; id: string };
    timeseries: {
      date: UtcDate;
      values: { name: string; value: number }[];
    }[];
  }[];
  assets: { symbol: string; id: string }[];
}

const MarketRegimeResult = ({ input }: { input: MarketRegimeResultInput }) => {
  const colorScheme = useFinalColorScheme();
  const query = useMarketRegimeSuspenseQuery({
    variables: {
      modelIds: input.models.map((mod) => mod.id),
      to: input.to,
      since: input.since,
    },
  });

  const predictions: ChartData['predictions'] = query.data!.regime.historicalPredictions;
  const dates = predictions.flatMap((model) => model.timeseries.map((ts) => parseUtcDate(ts.date)));
  const minDate = dayjs.min(...dates);
  const maxDate = dayjs.max(...dates);

  // Determine which assets to fetch prices for
  const selectedAssetIds: string[] = input.useSingleModelMode
    ? input.models
        .map((mod) => mod.benchmark?.id)
        .filter((id): id is string => Boolean(id)) // Ensure filtering removes undefined values
    : input.assets.map((asset) => asset.id);

  // Fetch asset prices once for all selected benchmarks/assets
  const request = useMarketRegimeBenchmarkPriceSuspenseQuery({
    variables: {
      since: isNil(minDate) ? ('' as unknown as UtcDate) : formatISODate(minDate),
      to: isNil(maxDate) ? ('' as unknown as UtcDate) : formatISODate(maxDate),
      assetIds: selectedAssetIds,
    },
    skip: dates.length === 0 || selectedAssetIds.length === 0,
  });

  const prices: { [assetId: string]: { date: UtcDate; price: string }[] } = {};
  if (request.data?.assets) {
    for (const item of request.data.assets.price) {
      prices[item.asset.id] = item.rows;
    }
  }

  // Prepare chart data with the correct types
  const chartData: ChartData[] = input.useSingleModelMode
    ? input.models
        .map((model) => {
          const benchmarkAsset = input.assets.find((asset) => asset.id === model.benchmark?.id);
          if (!benchmarkAsset) return null; // Ensure asset is found

          return {
            key: model.id,
            predictions: predictions.filter((pred) => pred.model.id === model.id),
            assets: [benchmarkAsset], // Single benchmark asset
          };
        })
        .filter((item): item is ChartData => Boolean(item)) // Ensure we remove null values
    : [
        {
          key: 'combined-chart',
          predictions: sortBy((pred) => pred.model.name, predictions),
          assets: sortBy((asset) => asset.symbol, input.assets),
        },
      ];

  return (
    <>
      {chartData.map(({ key, predictions, assets }) => (
        <HighChartsWrapper
          key={key}
          data={predictions}
          loading={false}
          calculateOptions={(data) => calculateOptions(data, assets)}
          calculateChartData={(data) =>
            calculateChartData({
              colorScheme,
              data,
              prices,
              assets,
            })
          }
        />
      ))}
    </>
  );
};

export default MarketRegimeResult;

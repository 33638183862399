import dayjs from 'dayjs';
import type Highcharts from 'highcharts';
import { type BigNumber, bignumber } from 'mathjs';
import { type ReactElement, useRef, useState, memo } from 'react';
import { parseUtcDate } from 'components/date.utils.ts';
import { DateTimeFormat, formatDate, formatPercentage } from 'components/formatter.utils.ts';
import {
  calculateMultiChartParams,
  dateTimeAxisFormat,
  dateTimeExportFormat,
  defaultTooltipDateHeader,
  defaultTooltipPointColor,
  getHighchartColor,
  type HighChartRef,
  type HighchartSeries,
} from 'components/technical/charts/HighChartsWrapper/Highchart.utils.ts';
import HighChartsWrapper from 'components/technical/charts/HighChartsWrapper/HighChartsWrapper.tsx';
import { groupBy, partition, sortBy } from 'lodash/fp';
import { TupleKeyMap } from '../../../../TupleKeyMap.ts';
import bigNumMath from '../../../../../bigNumMath.ts';
import type { GroupWithAssetId } from 'components/portfolio/dashboard/PositionAggregationsService.ts';
import ColorIndexService from 'components/copilot/risk/ColorIndexService.ts';
import { type OptionValue, useAssetExposureOptions } from '../../../risk/RiskAggregationsService.ts';
import { defaultRowSpacing } from '../../../../StackSpacing.ts';
import { Stack } from '@mui/joy';
import { useFinalColorScheme } from '../../../../../useFinalColorScheme.ts';
import { Select } from '../../../../technical/inputs';

interface Analysis {
  portfolioDefinition: {
    id: string;
    name: string;
  };
  cashAssetIds: string[];
  allocations: {
    date: UtcDate;
    assetAllocation: {
      asset: {
        symbol: string;
        id: string;
      };
      value: number;
    }[];
  }[];
}

interface PortfolioAllocationSectionProps {
  analysis: Analysis[];
  genieGroups: GroupWithAssetId[];
  userGroups: GroupWithAssetId[];
}

interface SerieUserData {
  pointLabel: string;
  portfolioId: string;
}

const minAllocationToShow = 0.001; // 0.1%
const calculateChartData = ({
  colorScheme,
  portfolioAllocations,
  portfolioToAxisIndex,
  exposureType,
  colorIndexService,
}: {
  colorScheme: 'dark' | 'light';
  portfolioAllocations: PortfolioAllocationSectionProps['analysis'];
  portfolioToAxisIndex: Map<string, number>;
  exposureType: OptionValue;
  colorIndexService: ColorIndexService;
}): HighchartSeries<number[]>[] => {
  const portfolioCategoryToData = new TupleKeyMap<
    [string, string],
    Extract<HighchartSeries<number[]>, { type: 'column' }> & SerieUserData
  >();

  const addedCategories = new Set<string>();
  const categoryIdToCategoryName = new Map();
  for (const portfolio of portfolioAllocations) {
    for (const { assetAllocation, date } of portfolio.allocations) {
      const categoryIdToAlloc = new Map();
      for (const { asset, value } of assetAllocation) {
        const parsedValue = bignumber(value);
        if (parsedValue.abs().lt(minAllocationToShow)) {
          continue;
        }

        const category = exposureType.getCategory(asset, parsedValue);
        if (!categoryIdToCategoryName.has(category.id)) {
          categoryIdToCategoryName.set(category.id, category.name);
        }

        if (!categoryIdToAlloc.has(category.id)) {
          categoryIdToAlloc.set(category.id, [parsedValue]);
        } else {
          categoryIdToAlloc.get(category.id).push(parsedValue);
        }
      }

      for (const [categoryId, values] of categoryIdToAlloc.entries()) {
        const totalValue = bigNumMath.sum(values);
        const colorIndex = colorIndexService.getOrIncreaseCategoryIndex(exposureType.clusterId, categoryId);
        const portfolioId = portfolio.portfolioDefinition.id;
        const key: [string, string] = [portfolioId, categoryId];
        let portfolioCategoryElements = portfolioCategoryToData.get(key);
        const addedToChart = addedCategories.has(categoryId);
        if (!addedToChart) {
          addedCategories.add(categoryId);
        }

        const categoryName = categoryIdToCategoryName.get(categoryId);
        if (!portfolioCategoryElements) {
          portfolioCategoryElements = {
            data: [],
            id: addedToChart ? `${portfolioId}-${categoryId}` : categoryId, // we cannot add series with the same id twice
            yAxis: portfolioToAxisIndex.get(portfolioId),
            name: categoryName,
            type: 'column' as const,
            portfolioId: portfolioId,
            pointLabel: categoryName,
            linkedTo: addedToChart ? categoryId : undefined,
            allowPointSelect: false,
            color: getHighchartColor(colorScheme, colorIndex ?? 0),
            groupPadding: 0,
          };

          portfolioCategoryToData.set(key, portfolioCategoryElements);
        }

        portfolioCategoryElements.data.push([
          dayjs.utc(parseUtcDate(date)).valueOf(),
          bignumber(totalValue).toNumber(),
        ]);
      }
    }
  }

  return Array.from(portfolioCategoryToData.values());
};

const legendMaxHeight = 100;
const singlePortfolioHeight = 300;

const calculateOptions = (sortedPortfolios: Analysis[]): Highcharts.Options => {
  const { yAxis, chartHeight, chartSpacingTop } = calculateMultiChartParams({
    items: sortedPortfolios.length,
    singleItemHeight: singlePortfolioHeight,
    legendMaxHeight,
  });

  return {
    ...dateTimeAxisFormat,
    ...dateTimeExportFormat('portfolio-backtest'),
    tooltip: {
      formatter: function (): string {
        const timestamp = this.x;
        const text: string[][] = [];
        const points = (
          this as unknown as {
            points: {
              color: string;
              series: {
                userOptions: SerieUserData;
              };
              x: number;
              y: number;
            }[];
          }
        ).points;

        const portfolioIdToPoints = new Map(Object.entries(groupBy((p) => p.series.userOptions.portfolioId, points)));
        for (const portfolio of sortedPortfolios) {
          const portfolioText: string[] = [];
          const points = portfolioIdToPoints.get(portfolio.portfolioDefinition.id) ?? [];
          if (points.length > 0) {
            portfolioText.push(`<b>${portfolio.portfolioDefinition.name}:</b>`);
          }
          const sortedBySymbolPoints = sortBy((p) => p.series.userOptions.pointLabel, points);
          for (const point of sortedBySymbolPoints) {
            portfolioText.push(
              `${defaultTooltipPointColor(point.color)} ${point.series.userOptions.pointLabel}: <b>${formatPercentage(
                point.y
              )}</b>`
            );
          }
          text.push(portfolioText);
        }
        const header = defaultTooltipDateHeader(formatDate(dayjs.utc(timestamp), DateTimeFormat.LongDate));
        return [header, text.map((portfolioText) => portfolioText.join('<br>')).join('<br><br>')].join('<br>');
      },
      followPointer: true,
    },
    yAxis: sortedPortfolios.map((port, i) => {
      const { dataMin, dataMax } = calculatePortfolioMinAndMaxValues(port.allocations);
      return {
        title: {
          text: port.portfolioDefinition.name,
        },
        ...yAxis(i),
        min: dataMin.toNumber(),
        max: dataMax.toNumber(),
        labels: {
          formatter: ({ value }) => formatPercentage(bignumber(value).toNumber()),
        },
        endOnTick: false,
      };
    }),
    chart: {
      height: chartHeight,
      spacingTop: chartSpacingTop,
    },
    legend: {
      maxHeight: legendMaxHeight,
    },
    plotOptions: {
      series: {
        stacking: 'normal',
      },
    },
  };
};

const calculatePortfolioMinAndMaxValues = (
  dailyValues: {
    date: UtcDate;
    assetAllocation: { asset: { symbol: string; id: string }; value: number }[];
  }[]
): {
  dataMin: BigNumber;
  dataMax: BigNumber;
} => {
  const dailyStackedAllocations = dailyValues.map((day) => {
    const allDayAllocations = day.assetAllocation.map((alloc) => bignumber(alloc.value));
    const [positiveValues, negativeValues] = partition((val) => val.isPositive(), allDayAllocations);
    return {
      negative: bigNumMath.sum(negativeValues),
      positive: bigNumMath.sum(positiveValues),
    };
  });

  const zero = bignumber(0);
  dailyStackedAllocations.push({
    negative: zero,
    positive: zero,
  });

  const dataMin = bigNumMath.min(dailyStackedAllocations.map((val) => val.negative));
  const dataMax = bigNumMath.max(dailyStackedAllocations.map((val) => val.positive));
  return { dataMin, dataMax };
};

const PortfolioAllocationSection = ({
  analysis,
  genieGroups,
  userGroups,
}: PortfolioAllocationSectionProps): ReactElement => {
  const colorScheme = useFinalColorScheme();
  const colorIndexServiceRef = useRef(new ColorIndexService());
  const cashAssetIds = new Set(...analysis.flatMap((a) => a.cashAssetIds));

  const exposureOptions = useAssetExposureOptions({
    groups: [...genieGroups, ...userGroups],
    cashAssetIds,
  });

  const [exposureOptionValue, setExposureOptionValue] = useState<OptionValue | null>(exposureOptions.options[0].value);

  const chartRef = useRef<null | HighChartRef>(null);
  const sortedPortfolios = sortBy((port) => port.portfolioDefinition.name, analysis);

  const idToPortfolioIndex = new Map(
    sortedPortfolios.map((portfolio, index) => [portfolio.portfolioDefinition.id, index])
  );

  return (
    <Stack gap={defaultRowSpacing}>
      <Select
        label="Aggregation"
        {...exposureOptions}
        value={exposureOptionValue}
        width="normal"
        onChange={(val: OptionValue | null) => setExposureOptionValue(val)}
      />
      <HighChartsWrapper<PortfolioAllocationSectionProps['analysis'], number[]>
        loading={false}
        data={exposureOptionValue ? analysis : undefined}
        ref={chartRef}
        calculateChartData={(data): HighchartSeries<number[]>[] =>
          calculateChartData({
            colorScheme,
            portfolioAllocations: data,
            portfolioToAxisIndex: idToPortfolioIndex,
            exposureType: exposureOptionValue!,
            colorIndexService: colorIndexServiceRef.current,
          })
        }
        calculateOptions={(): Highcharts.Options => {
          return calculateOptions(sortedPortfolios);
        }}
      />
    </Stack>
  );
};

export default memo(PortfolioAllocationSection);

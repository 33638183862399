import { type ReactElement, type ReactNode, useContext } from 'react';
import type { RenderDialogFunction } from '../inputs/GButton/DialogButton.tsx';
import { DialogDropdownContext } from './DialogDropdownContext.tsx';
import { MenuItem } from '@mui/joy';
import type { MenuItemProps } from '@mui/joy/MenuItem/MenuItemProps';

export interface DialogItemProps {
  children: ReactNode;
  renderDialog: RenderDialogFunction;
  color?: MenuItemProps['color'];
  variant?: MenuItemProps['variant'];
}

export const DialogMenuItem = ({ children, renderDialog, color, variant }: DialogItemProps): ReactElement => {
  const { setDialog } = useContext(DialogDropdownContext);

  return (
    <MenuItem
      color={color}
      variant={variant}
      onClick={(): void => {
        setDialog(
          renderDialog({
            onClose: (): void => setDialog(null),
          })
        );
      }}
    >
      {children}
    </MenuItem>
  );
};

import { bignumber } from 'mathjs';
import { type ReactElement, useRef } from 'react';
import TitleValueTile, { TitleValueContent } from '../../../technical/Tile/TitleValueTile.tsx';
import { Box, Stack, Typography } from '@mui/joy';
import { formatCash, formatPercentage } from '../../../formatter.utils.ts';
import { getReturnsColor } from './PortfolioTilesService.ts';
import drop from 'lodash/fp/drop';
import { usePortfolioReturnsTileInputQuery } from '../../../../generated/graphql.tsx';
import { convertDateInUtcToUTCISODate } from '../../../date.utils.ts';
import dayjs from 'dayjs';
import { useSubAccountAssetFilters } from 'components/technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters.tsx';
import { logErrorOnce } from '../../../log.utils.ts';

const DAILY_RETURN_RANGE_DAYS = 30;
export const DailyReturnsTileContent = ({ hideTitle }: { hideTitle?: boolean }): ReactElement => {
  const currentDate = useRef(dayjs.utc());
  const { subAccountAssetFilters } = useSubAccountAssetFilters();
  const returnsDetails = usePortfolioReturnsTileInputQuery({
    variables: {
      returnsSince: convertDateInUtcToUTCISODate(currentDate.current.subtract(DAILY_RETURN_RANGE_DAYS, 'day')),
      subAccountAssetFilters,
    },
  });

  if (returnsDetails.error) {
    logErrorOnce('Failed to fetch portfolio return tile data', returnsDetails.error);
  }

  const dailyReturnRows = drop(1, returnsDetails.data?.portfolio.portfolioTimeWeightedReturns.values ?? []);
  const totalReturn = dailyReturnRows?.at(-1)?.return.return ?? 0;
  const totalPercentageReturn = bignumber(dailyReturnRows?.at(-1)?.return.weighted_return ?? 0);
  return (
    <TitleValueContent
      loading={returnsDetails.loading}
      title={!hideTitle && <Typography lineHeight={2}>Daily return</Typography>}
      value={formatCash(totalReturn)}
      caption={`(${formatPercentage(totalPercentageReturn)})`}
      footer={
        <Stack>
          <Typography level="body-xs2">Last {DAILY_RETURN_RANGE_DAYS} days</Typography>
          <Stack direction="row" gap={0.25}>
            {dailyReturnRows?.map((dailyReturn) => (
              <Box
                borderRadius={2}
                height={16}
                width="100%"
                maxWidth={12}
                minWidth={2}
                key={dailyReturn.date.toString()}
                sx={{
                  bgcolor: (theme) => getReturnsColor(dailyReturn.return.weighted_return, theme),
                }}
              />
            ))}
          </Stack>
        </Stack>
      }
    />
  );
};

const DailyReturnsTile = (): ReactElement => {
  return (
    <TitleValueTile>
      <DailyReturnsTileContent />
    </TitleValueTile>
  );
};

export default DailyReturnsTile;

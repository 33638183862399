import PageShell from '../components/technical/PageShell/PageShell.tsx';
import PortfolioDashboard from '../components/portfolio/dashboard/PortfolioDashboard.tsx';
import ProfitAndLossDashboard from '../components/portfolio/profitLoss/ProfitAndLossDashboard.tsx';
import ProfitAndLossPerformance from '../components/portfolio/profitLoss/ProfitAndLossPerformance.tsx';
import PerformanceAttributionContainer from '../components/portfolio/profitLoss/attribution/PerformanceAttribution.tsx';
import AssetContribution from '../components/portfolio/profitLoss/assetContribution/AssetContribution.tsx';
import DailyPnl from '../components/portfolio/profitLoss/dailyPnl/DailyPnl.tsx';
import RiskDashboard from '../components/copilot/risk/RiskDashboard.tsx';
import RiskOverview from '../components/copilot/risk/RiskOverview.tsx';
import CounterpartyRiskReport from '../components/copilot/risk/CounterpartyRiskReport.tsx';
import AssetsRiskReport from '../components/copilot/risk/assetRiskMetrics/AssetRiskMetricsReport.tsx';
import PositionsDashboard from '../components/portfolio/openPositions/PositionsDashboard.tsx';
import { createIndexRoute } from './CreateIndexRoute.tsx';
import { Navigate } from 'react-router';
import UnifiedPnl from 'components/portfolio/profitLoss/unifiedPnl/UnifiedPnl.tsx';
import SubFundPnlDashboard from '../components/portfolio/profitLoss/subFund/SubFundPnlDashboard.tsx';
import CustomDashboard from '../components/management/customDashboard/CustomDashboard.tsx';

export const portfolioRoutes = {
  handle: {
    title: 'Portfolio',
    breadcrumb: 'Portfolio',
  },
  path: 'portfolio',
  children: [
    {
      handle: {
        breadcrumb: 'Dashboard',
      },
      path: 'dashboard',
      element: (
        <PageShell title="Overview" hideBottomPadding>
          <PortfolioDashboard />
        </PageShell>
      ),
    },
    {
      handle: {
        breadcrumb: 'Profit and loss',
      },
      path: 'profit-loss',
      element: (
        <PageShell title="Profit and loss">
          <ProfitAndLossDashboard />
        </PageShell>
      ),
      children: [
        { handle: { breadcrumb: 'Performance' }, path: 'performance', element: <ProfitAndLossPerformance /> },
        {
          handle: {
            breadcrumb: 'Attribution',
            docs: 'https://genie-crypto.atlassian.net/wiki/spaces/GENPUB/pages/766869547/Return+Attribution',
          },
          path: 'attribution',
          element: <PerformanceAttributionContainer />,
        },
        {
          handle: {
            breadcrumb: 'Contribution by asset',
            docs: 'https://genie-crypto.atlassian.net/wiki/spaces/GENPUB/pages/770637836/Contribution+to+Performance+by+Asset',
          },
          path: 'asset-contribution',
          element: <AssetContribution />,
        },
        {
          handle: { breadcrumb: 'Daily P&L' },
          path: 'daily-pnl',
          element: <DailyPnl />,
        },
        {
          handle: { breadcrumb: 'Unified P&L' },
          path: 'unified-pnl',
          element: <UnifiedPnl />,
        },
        { index: true, element: <Navigate to="performance" replace /> },
      ],
    },
    {
      handle: {
        title: 'Sub-fund P&L',
        breadcrumb: 'Sub-fund P&L',
      },
      path: 'subfund-profit-loss',
      element: (
        <PageShell title="Sub fund P&L">
          <SubFundPnlDashboard />
        </PageShell>
      ),
    },
    {
      handle: { breadcrumb: 'Risk' },
      path: 'risk',
      element: (
        <PageShell title="Risk">
          <RiskDashboard />
        </PageShell>
      ),
      children: [
        {
          handle: {
            breadcrumb: 'Overview',
            docs: 'https://genie-crypto.atlassian.net/wiki/spaces/GENPUB/pages/764116996/Portfolio+Risk',
          },
          path: 'overview',
          element: <RiskOverview />,
        },
        {
          handle: { breadcrumb: 'Counterparty risk' },
          path: 'counterparty-report',
          element: <CounterpartyRiskReport />,
        },
        {
          handle: { breadcrumb: 'Asset risk metrics' },
          path: 'asset-risk-metrics',
          element: <AssetsRiskReport />,
        },
        { index: true, element: <Navigate to="overview" replace /> },
      ],
    },
    {
      handle: { breadcrumb: 'Positions' },
      path: 'positions',
      element: (
        <PageShell title="Positions">
          <PositionsDashboard />
        </PageShell>
      ),
    },
    {
      handle: { title: 'My dashboard', breadcrumb: 'My dashboard' },
      path: 'custom-dashboard',
      element: (
        <PageShell>
          <CustomDashboard />
        </PageShell>
      ),
    },
    createIndexRoute('dashboard'),
  ],
};

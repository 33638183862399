import { Add } from '@mui/icons-material';
import { Stack } from '@mui/joy';
import type { ReactElement } from 'react';

import ActionsContextProvider from 'components/technical/actions/ActionsContextProvider';
import ActionsHeaderBar from 'components/technical/actions/ActionsHeaderBar';
import GTableSkeleton from 'components/technical/GTable/GTableSkeleton';
import DialogButton from 'components/technical/inputs/GButton/DialogButton';
import SectionColumn from 'components/technical/layout/Column/SectionColumn';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';
import CreateInvestmentDialog from './CreateInvestmentDialog';
import InvestmentList, { type Investment } from './InvestmentList';
import { IAssetType, useInvestmentsInputQuery } from '../../../generated/graphql';
import { isAsset } from '../../market/asset/Asset.types';
import { GENIE_VENUE } from '../../venue/VenueData.tsx';

const headers = [
  'Date time',
  'Investment name',
  'Sub-account',
  'Buy amount',
  'Buy currency',
  'Sell amount',
  'Sell currency',
  'Fee',
  'Vested',
  'Status',
];

const InvestmentDashboard = (): ReactElement => {
  /* jscpd:ignore-start */
  const { data, Fallback, loaded, refetch } = useDefaultErrorHandling(
    useInvestmentsInputQuery({
      variables: {
        filters: {
          assetTypes: [IAssetType.Private, IAssetType.Public],
        },
      },
    })
  );

  if (!loaded) {
    return <Fallback />;
  }

  if (data.portfolio.accounts.length === 0) {
    return <GTableSkeleton headers={headers} />;
  }

  /* jscpd:ignore-end */
  const genieAccounts = data.portfolio.accounts.filter((wal) => wal.venue.label === GENIE_VENUE);
  const cannotSeeData = data.assets.list.length === 0 || genieAccounts.length === 0;
  return (
    <Stack spacing={1.5} p={2}>
      <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
        <span title={cannotSeeData ? 'To add an investment, create a virtual account and a private asset first' : ''}>
          <DialogButton
            disabled={genieAccounts.length === 0}
            renderDialog={({ onClose }): ReactElement => (
              <CreateInvestmentDialog
                onClose={onClose}
                onAdded={() => {
                  refetch();
                  onClose();
                }}
                accounts={genieAccounts}
                assets={data.assets.list}
              />
            )}
            startDecorator={<Add />}
          >
            Add investment
          </DialogButton>
        </span>
      </Stack>
      <SectionColumn>
        <ActionsContextProvider>
          <ActionsHeaderBar title="Investments" level="h2" />
          {cannotSeeData ? (
            <GTableSkeleton headers={headers} />
          ) : (
            <InvestmentList
              investments={data.bookkeeping.investments.list.filter((investment): investment is Investment => {
                if (!isAsset(investment.credit.asset)) {
                  console.error('Investment credit is not an asset', investment);
                  return false;
                }

                if (!isAsset(investment.debit.asset)) {
                  console.error('Investment debit is not an asset', investment);
                  return false;
                }

                if (investment.fee && !isAsset(investment.fee.asset)) {
                  console.error('Investment fee is not an asset', investment);
                  return false;
                }

                return true;
              })}
              refetch={(): Promise<unknown> => refetch()}
            />
          )}
        </ActionsContextProvider>
      </SectionColumn>
    </Stack>
  );
};

export default InvestmentDashboard;

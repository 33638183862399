import type { OptimizationOutput } from './YieldOptimizerResult.types.ts';
import { useFinalColorScheme } from '../../../../../useFinalColorScheme.ts';
import Message from '../../../../technical/Message.tsx';
import { getRegularChartColor } from '../../../../../theme/colors.ts';
import { bignumber } from 'mathjs';
import { formatCash, formatPercentage } from '../../../../formatter.utils.ts';
import bigNumMath from '../../../../../bigNumMath.ts';
import HighChartsWrapper from 'components/technical/charts/HighChartsWrapper/HighChartsWrapper.tsx';
import { Stack, useTheme } from '@mui/joy';
import groupBy from 'lodash/fp/groupBy';
import { sortBy } from 'lodash/fp';

type RowType = OptimizationOutput['allocations'][number];

const YieldAllocationChart = ({
  data,
  type,
  title,
}: {
  data: RowType[];
  type: 'pool' | 'protocol' | 'collateralAsset' | 'chain';
  title: string;
}) => {
  const colorScheme = useFinalColorScheme();
  const theme = useTheme();

  if (data.length === 0) {
    return <Message>No data</Message>;
  }

  // Group and sum allocations based on the selected type
  const groupedData = groupBy((row: RowType) => {
    switch (type) {
      case 'pool':
        return row.pool.poolName;
      case 'protocol':
        return row.pool.protocol;
      case 'chain':
        return row.pool.chain;
      case 'collateralAsset':
        return row.pool.collateralAsset.id;
      default:
        return 'Unknown';
    }
  }, data);

  // Sort grouped data by total allocation in descending order
  const sortedGroups = sortBy(
    ([, rows]) => -bigNumMath.sum(rows.map((row) => row.dollarValueOfAllocation)),
    Object.entries(groupedData)
  );

  const totalAllocation = bigNumMath.sum(data.map((row) => bignumber(row.dollarValueOfAllocation)));

  // Prepare Pie Chart Data with Logarithmic Scaling for Visibility
  const chartData = sortedGroups.map(([groupName, rows], i) => {
    const allocation = bigNumMath.sum(rows.map((row) => row.dollarValueOfAllocation));

    // Apply logarithmic scaling to enhance visibility of small values
    const scaledValue = Math.max(5, Math.log1p(allocation) * 10);

    const color = getRegularChartColor(colorScheme, i);
    const name = type === 'collateralAsset' ? rows[0].pool.collateralAsset.symbol : groupName;
    return {
      name,
      y: scaledValue, // Scaled value for visualization
      color,
      custom: { realValue: allocation },
    };
  });

  const numberOfTypes = Object.keys(groupedData).length > 0 ? `${Object.keys(groupedData).length}` : '';
  const chartTitle = `${numberOfTypes} ${title}${Number.parseFloat(numberOfTypes) > 1 ? 's' : ''}`;
  return (
    <Stack width="100%" spacing={2}>
      <HighChartsWrapper
        height={150}
        data={chartData}
        calculateOptions={() => ({
          chart: {
            plotBorderWidth: 0,
            plotShadow: false,
          },
          title: {
            // show the number of type in the title
            text: `<span style="font-size: ${theme.vars.fontSize.sm}; font-weight: ${theme.vars.fontWeight.lg}">${chartTitle}</span>`,
            align: 'center',
            verticalAlign: 'middle',
            y: 60,
            style: {
              fontSize: theme.vars.fontSize.sm,
              fontWeight: theme.vars.fontWeight.sm.toString(),
            },
          },
          tooltip: {
            headerFormat: '',
            pointFormatter: function () {
              // @ts-expect-error - realValue is a custom property but it is not typed in the options
              return `<b>${this.name}</b> </br> ${formatCash(this.options.realValue)} (${formatPercentage(this.options.realValue / totalAllocation.toNumber(), 3)})`;
            },
          },
          accessibility: {
            point: {
              valueSuffix: '%',
            },
          },
          plotOptions: {
            pie: {
              dataLabels: {
                enabled: false,
              },
              startAngle: -90,
              endAngle: 90,
              center: ['50%', '130%'],
              size: '270%',
              innerSize: '50%',
            },
          },
        })}
        calculateChartData={() => [
          {
            type: 'pie',
            name: `Allocation by ${title} `,
            innerSize: '60%',
            data: chartData.map((d) => ({
              name: d.name,
              y: d.y,
              color: d.color,
              realValue: d.custom.realValue,
            })),
          },
        ]}
        loading={false}
        error={undefined}
      />
    </Stack>
  );
};

export default YieldAllocationChart;

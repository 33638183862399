import { Card, Grid } from '@mui/joy';
import { isNil, sortBy } from 'lodash/fp';
import { bignumber } from 'mathjs';
import type { FunctionComponent } from 'react';
import { formatPercentage } from 'components/formatter.utils';
import {
  type HighchartSeries,
  createMarkers,
  tooltipFormat,
} from 'components/technical/charts/HighChartsWrapper/Highchart.utils';
import HighChartsContainer from 'components/technical/charts/HighChartsWrapper/HighChartsWrapper';
import { type IOptionsQuery, IOptionType } from 'generated/graphql';

import { factorName } from './FactorService.ts';
import type * as Highcharts from 'highcharts';

type VolatilityStrikeChartProps = {
  filename: string;
  date: string;
  data: IOptionsQuery['assets']['options']['pricedOptions'];
};

interface SerieData {
  x: number;
  y: number | null;
  textValue: string;
}

type HighchartVolatilitySerie = Extract<HighchartSeries<SerieData>, { type: 'line' }>;

export const hasVolatilityData = (data: IOptionsQuery['assets']['options']['pricedOptions']): boolean => {
  const result = calculateChartData(data);
  const hasData = result.some((serie) => serie.data?.find((row) => !isNil(row.y)));
  return hasData;
};

const calculateChartData = (data: IOptionsQuery['assets']['options']['pricedOptions']): HighchartVolatilitySerie[] => {
  const factors = ['bidFactors', 'askFactors'] as const;

  const chartData: HighchartVolatilitySerie[] = [];

  for (const factor of factors) {
    for (const optionType in IOptionType) {
      const option = IOptionType[optionType as keyof typeof IOptionType];
      const dataByType = data.filter((row) => row.option.derivativeDetails?.optionType === option);

      const dataPoints = dataByType.map((row) => ({
        x: bignumber(row.option.derivativeDetails?.strikePrice ?? 0).toNumber(),

        y: row[factor]?.iv ? bignumber(row[factor]!.iv).mul(100).toNumber() : null,
        textValue: isNil(row[factor]?.iv) ? '' : formatPercentage(row[factor]?.iv),
      }));

      const sortedData = sortBy((row) => row.x, dataPoints);

      const serie: HighchartVolatilitySerie = {
        name: `${optionType} (${factorName[factor]})`,
        type: 'line',
        data: sortedData,
        ...createMarkers,
      };

      chartData.push(serie);
    }
  }

  return chartData;
};

const calculateOptions = (date: string, filename: string): Highcharts.Options => {
  return {
    title: {
      text: date,
    },
    exporting: {
      filename,
    },
    ...tooltipFormat,
    yAxis: {
      title: {
        text: 'IV',
      },
    },
    xAxis: {
      title: {
        text: 'Strike',
      },
    },
  };
};

const VolatilityStrikeChart: FunctionComponent<VolatilityStrikeChartProps> = ({ data, date, filename }) => {
  return (
    <Grid md={4} xs={12}>
      <Card>
        <HighChartsContainer<IOptionsQuery['assets']['options']['pricedOptions']>
          loading={false}
          data={data}
          calculateChartData={calculateChartData}
          calculateOptions={(): Highcharts.Options => calculateOptions(date, filename)}
        />
      </Card>
    </Grid>
  );
};

export default VolatilityStrikeChart;

import type { CellContext, ColumnDef } from '@tanstack/table-core';
import dayjs, { type Dayjs } from 'dayjs';
import isNil from 'lodash/fp/isNil';
import { bignumber } from 'mathjs';
import type { ReactElement, ReactNode } from 'react';

import GTable from 'components/technical/GTable/GTable.tsx';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';

import { useUserTimezone } from 'components/technical/UseUserTimezone';
import { IJournalError, useJournalErrorsQuery } from '../../../generated/graphql';
import { DateTimeFormat, formatDate, formatNumber } from '../../formatter.utils';

import type { IAsset } from '.../../generated/graphql';

interface Error {
  time: Dayjs;
  type: string;
  amount?: number;
  dimension?: string;
  asset?: Omit<IAsset, 'derivativeDetails' | 'unvestedAsset' | 'priceAsset'>;
}

const JournalList = (): ReactElement => {
  const { loaded, data, Fallback } = useDefaultErrorHandling(useJournalErrorsQuery());
  const timezone = useUserTimezone();
  if (!loaded) {
    return <Fallback />;
  }

  const journal = data.portfolio.journal;
  const rows: Error[] = [];
  rows.push(
    ...journal.oldestJournalAssetErrors.map((error) => ({
      time: dayjs.utc(error.time),
      amount: bignumber(error.remainingAmount).toNumber(),
      asset: error.asset,
      subFund: error.subFund?.name ?? 'Organization',
      type: error.error === IJournalError.MissingAmount ? 'Missing amount' : 'Missing cost basis',
    }))
  );

  const columns: ColumnDef<Error>[] = [
    {
      header: 'Date',
      cell: (context: CellContext<Error, unknown>) =>
        formatDate(context.row.original.time, DateTimeFormat.DateTime, timezone),
      accessorFn: (error) => error.time.toDate(),
    },
    {
      header: 'Sub-fund',
      accessorKey: 'subFund',
    },
    {
      header: 'Error type',
      accessorKey: 'type',
    },
    {
      header: 'Quantity',
      cell: (context: CellContext<Error, unknown>): ReactNode => {
        const { amount } = context.row.original;
        return isNil(amount) ? undefined : formatNumber(amount);
      },
      accessorFn: (error) => (isNil(error.amount) ? '-' : error.amount),
    },
    {
      header: 'Asset',
      accessorFn: (error) => error.asset?.symbol,
    },
  ];

  return <GTable columns={columns} data={rows} disablePagination />;
};

export default JournalList;

// TourActions.tsx
import { Button, Stack, useTheme } from '@mui/joy';
import type React from 'react';
import type { TourGuideKey } from './useTourGuideConfig';
import { Refresh } from '@mui/icons-material';

const TourActions: React.FC<{ featureId: TourGuideKey }> = ({ featureId }) => {
  const theme = useTheme();

  return (
    <Stack
      id="tour-actions"
      direction="row"
      spacing={1}
      lineHeight={0}
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      <Button
        size="sm"
        color="neutral"
        variant="outlined"
        sx={{
          fontSize: theme.vars.fontSize.xs,
        }}
        onClick={() => {
          window.dispatchEvent(
            new CustomEvent('tour:dismiss', {
              detail: { featureId },
            })
          );
        }}
      >
        Don’t show again
      </Button>
      <Button
        size="sm"
        variant="outlined"
        color="warning"
        startDecorator={<Refresh fontSize="md" />}
        sx={{
          fontSize: theme.vars.fontSize.xs,
        }}
        onClick={() => {
          window.dispatchEvent(
            new CustomEvent('tour:restart', {
              detail: { featureId },
            })
          );
        }}
      >
        Restart
      </Button>
    </Stack>
  );
};

export default TourActions;

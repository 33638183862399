import { type ReactElement, useRef } from 'react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import FormInput from 'components/technical/form/FormInput.tsx';
import FormSelect from 'components/technical/form/FormSelect.tsx';
import RemoveButton from 'components/technical/RemoveButton.tsx';
import { createAssetAutocompleteProps, useAssetPaginatedOptions } from '../../../market/asset/AssetService.tsx';
import { type PortfolioStepInput, positionSideValues } from './PortfoliosStep.validation.tsx';
import { IAssetType, IDerivativeType } from '../../../../generated/graphql.tsx';
import isNil from 'lodash/fp/isNil';
import FormSingleAutocomplete from '../../../technical/form/FormSingleAutocomplete.tsx';
import dayjs from 'dayjs';
import { formatISODate } from '../../../formatter.utils.ts';

const isPremiumRequired = (
  asset:
    | {
        type: IAssetType;
        derivativeDetails?:
          | {
              derivativeType: IDerivativeType;
            }
          | null
          | undefined;
      }
    | null
    | undefined
): boolean => {
  if (isNil(asset)) {
    return true;
  }
  return asset.type === IAssetType.Derivative && asset.derivativeDetails?.derivativeType === IDerivativeType.Option;
};

export const isEntryPriceRequired = (
  asset:
    | {
        type: IAssetType;
        derivativeDetails?:
          | {
              derivativeType: IDerivativeType;
            }
          | null
          | undefined;
      }
    | null
    | undefined
): boolean => {
  if (isNil(asset)) {
    return true;
  }

  return (
    asset.type === IAssetType.Derivative &&
    [IDerivativeType.PerpetualFuture, IDerivativeType.Future].includes(
      asset.derivativeDetails?.derivativeType ?? IDerivativeType.Option
    )
  );
};

export const PortfolioPositionRow = ({
  portfolioIndex,
  positionIndex,
  onRemove,
}: {
  portfolioIndex: number;
  positionIndex: number;
  onRemove: () => void;
}): ReactElement => {
  const { setValue } = useFormContext<PortfolioStepInput>();
  const { isSubmitting } = useFormState<PortfolioStepInput>();
  const pathPrefix = `portfolios.${portfolioIndex}.portfolio.${positionIndex}` as const;
  const expirationTime = useRef(dayjs());

  const asset = useWatch<PortfolioStepInput, `${typeof pathPrefix}.asset`>({
    name: `${pathPrefix}.asset` as const,
  });

  const optionalPremium = isPremiumRequired(asset);
  const requiresEntryPrice = isEntryPriceRequired(asset);
  const { getOptions } = useAssetPaginatedOptions({
    expirationSince: formatISODate(expirationTime.current).toString(),
    assetTypes: [IAssetType.Public, IAssetType.Derivative],
  });

  return (
    <>
      <FormSingleAutocomplete<PortfolioStepInput>
        name={`${pathPrefix}.asset` as const}
        width="xl3"
        placeholder="Select a contract"
        {...createAssetAutocompleteProps()}
        getOptions={getOptions}
        onChange={(asset) => {
          if (!isPremiumRequired(asset)) {
            setValue(`${pathPrefix}.premium`, null);
          }

          if (!isNil(asset) && isNil(asset.derivativeDetails)) {
            setValue(`${pathPrefix}.side`, null);
          }
        }}
      />
      <FormInput<PortfolioStepInput>
        name={`${pathPrefix}.amount` as const}
        type="number"
        width="normal"
        placeholder="Add amount"
      />
      <FormSelect<PortfolioStepInput>
        name={`${pathPrefix}.side` as const}
        width="normal"
        options={positionSideValues}
        disabled={!isNil(asset) && isNil(asset.derivativeDetails)}
      />
      <FormInput<PortfolioStepInput>
        name={`${pathPrefix}.premium` as const}
        type="number"
        width="normal"
        startDecorator="$"
        disabled={!optionalPremium}
      />
      <FormInput<PortfolioStepInput>
        name={`${pathPrefix}.entryPrice` as const}
        type="number"
        width="normal"
        startDecorator="$"
        disabled={!requiresEntryPrice}
      />
      <RemoveButton
        disabled={isSubmitting}
        onClick={(): void => {
          onRemove();
        }}
      />
    </>
  );
};

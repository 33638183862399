import { FormLabel, Modal, ModalDialog, Stack } from '@mui/joy';
import type { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';
import GFormProvider from 'components/technical/form/GFormProvider.tsx';
import { GraphQLApiFormErrorMessage } from 'components/technical/form/GraphQLApiErrorMessage.tsx';
import { getErrorDetails } from 'components/technical/form/GraphQLAPIService.ts';
import gYupResolver from 'components/technical/form/gYupResolver.ts';
import SubmitButton from 'components/technical/form/SubmitButton.tsx';
import { useGraphQLApiError } from 'components/technical/form/UseGraphQLApiError.tsx';
import GDialogHeader from 'components/technical/GDialog/GDialogHeader.tsx';
import { FormInput } from 'components/technical/inputs';
import {
  PrivateAssetsDocument,
  SearchableAssetIdsDocument,
  SearchableAssetsDocument,
  type IPrivateAssetsQuery,
  useUpdatePrivateAssetMutation,
} from '../../../../../generated/graphql.tsx';

interface FormState {
  name: string;
  symbol: string;
}

const UpdatePrivateAssetDialog = ({
  onClose,
  onUpdate,
  asset,
}: {
  onClose: () => void;
  onUpdate: () => void;
  asset: IPrivateAssetsQuery['assets']['list'][number];
}): ReactElement => {
  const formSchema = yup.object().shape({
    name: yup.string().required(),
    symbol: yup.string().required(),
  });

  const methods = useForm<FormState>({
    resolver: gYupResolver(formSchema),
    defaultValues: {
      name: asset.name ?? '',
      symbol: asset.symbol ?? '',
    },
  });

  const { onError } = useGraphQLApiError(methods);

  const [updateAssetMutation] = useUpdatePrivateAssetMutation();
  const { showSuccessMessage } = useFeedback();

  const handleFormSubmit = async (data: FormState): Promise<void> => {
    try {
      await updateAssetMutation({
        variables: {
          input: {
            id: asset.id,
            name: data.name,
            symbol: data.symbol,
          },
        },
        refetchQueries: [SearchableAssetsDocument, SearchableAssetIdsDocument, PrivateAssetsDocument],
      });

      showSuccessMessage('Asset successfully updated');

      onUpdate();
    } catch (e) {
      const details = getErrorDetails(e);
      if (details?.code === 'asset_duplicate_name') {
        methods.setError('name', {
          type: 'custom',
          message: 'Asset with given name already exists',
        });
      } else {
        onError(e);
      }

      throw e;
    }
  };

  return (
    <Modal open={true} onClose={onClose}>
      <ModalDialog>
        <GFormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
            <GDialogHeader>Rename asset {asset.name}</GDialogHeader>
            <Stack spacing={2}>
              <Stack direction="column">
                <FormLabel>Name</FormLabel>
                <FormInput name="name" width="fullWidth" />
              </Stack>
              <Stack direction="column">
                <FormLabel>Symbol</FormLabel>
                <FormInput name="symbol" width="fullWidth" />
              </Stack>
              <Stack alignItems="center" spacing={1.5}>
                <GraphQLApiFormErrorMessage />
                <SubmitButton width="xl2">Update</SubmitButton>
              </Stack>
            </Stack>
          </form>
        </GFormProvider>
      </ModalDialog>
    </Modal>
  );
};

export default UpdatePrivateAssetDialog;

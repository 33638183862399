import { Card, Stack, Typography, type TypographySystem } from '@mui/joy';
import type { SxProps } from '@mui/system';
import type { FunctionComponent, PropsWithChildren, ReactNode } from 'react';

export interface HeaderBarProps {
  title: ReactNode;
  children?: ReactNode;
  level?: 'inherit' | keyof TypographySystem;
  showCard?: boolean;
  sx?: SxProps;
  hideTopPadding?: boolean;
  hideBottomPadding?: boolean;
}

export const HeaderActions: FunctionComponent<PropsWithChildren> = ({ children }) => {
  return (
    <Stack alignItems="center" spacing={2}>
      {children}
    </Stack>
  );
};

const HeaderBar: FunctionComponent<HeaderBarProps> = ({
  title,
  showCard = false,
  children,
  level = 'h3',
  sx,
  hideTopPadding,
  hideBottomPadding,
}) => {
  const padding = 2;
  const content = (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      flexWrap="wrap"
      spacing={2}
      pb={showCard ? 0 : hideBottomPadding ? 0 : padding}
      pt={hideTopPadding || showCard ? 0 : padding}
    >
      <Typography sx={sx} level={level}>
        {title}
      </Typography>
      {children && (
        <Stack direction={'row'} alignItems={'center'} gap={2}>
          {children}
        </Stack>
      )}
    </Stack>
  );

  if (showCard) {
    return <Card>{content}</Card>;
  }

  return content;
};

export default HeaderBar;

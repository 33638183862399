import {
  Badge,
  Box,
  Dropdown,
  ListDivider,
  ListItemDecorator,
  MenuButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/joy';
import Avatar from '@mui/joy/Avatar';
import IconButton from '@mui/joy/IconButton';
import Menu from '@mui/joy/Menu';
import MenuItem from '@mui/joy/MenuItem';
import { Auth } from 'aws-amplify';
import isNil from 'lodash/fp/isNil';
import { type FunctionComponent, useContext } from 'react';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';

import { ImpersonationBarContext } from 'components/technical/ImpersonationBarProvider.tsx';
import { useAuth } from '../../../../UseAuth.tsx';
import GLink from '../../GLink/GLink';
import { Logout, People } from '@mui/icons-material';
import { BuildingSkyscraper, Gear } from 'components/technical/icons/index.tsx';
import { useHasDeliveredNotifications } from 'components/copilot/newsNotification/useHasDeliveredNotifications.ts';
import { useBackgroundPolling } from 'components/useBackgroundPolling.ts';
import { DrawerType } from 'components/technical/Drawer/Drawer.types.ts';
import { useDrawer } from 'components/technical/Drawer/UseDrawer.tsx';

const handleLogout = (): void => {
  Auth.signOut();
};
const pollingIntervalMSec = 60 * 1000;

const AppBarProfile: FunctionComponent = () => {
  const { user, localUser, isAdmin, isImpersonating } = useAuth();
  const { setImpersonationBarOpen } = useContext(ImpersonationBarContext);
  const activePollInterval = useBackgroundPolling(pollingIntervalMSec);

  const theme = useTheme();
  const { drawer, openDrawer, closeDrawer } = useDrawer();

  let initials = (user?.name ?? '')
    .split(' ')
    .map((word) => word.at(0))
    .filter((el) => !isNil(el))
    .join('')
    .toUpperCase();

  if (initials.length === 0) {
    initials = user?.email.slice(0, 3) ?? '';
  }

  const { hasNotifications } = useHasDeliveredNotifications({
    pollInterval: activePollInterval,
  });

  const isGenieOrAdmin = user?.groups.includes('genie') || isAdmin;
  // if User is not Genie or Admin, show org name
  const companyName = !isGenieOrAdmin ? user?.organization.name : 'Genie Admin';

  return (
    <Dropdown>
      <MenuButton
        slots={{ root: Avatar }}
        slotProps={{
          root: {
            size: 'md',
            color: isImpersonating ? 'danger' : 'primary',
            sx: {
              '&:hover': {
                // align with IconButton hover style as avatar is a bit bigger
                backgroundColor: theme.palette.neutral.plainHoverBg,
              },
            },
          },
        }}
      >
        <IconButton size="md">{initials}</IconButton>
      </MenuButton>
      <Menu placement="bottom-start" size="sm">
        <MenuItem disabled>
          <Stack direction="column" justifyContent="space-between">
            <Typography level="body-sm">{user?.name}</Typography>
            {companyName && (
              <Stack direction="row" alignItems="end" justifyContent="space-between">
                <BuildingSkyscraper size="sm" />
                <Typography level="body-xs">{companyName}</Typography>
              </Stack>
            )}
          </Stack>
        </MenuItem>
        <ListDivider />
        {(isAdmin || isImpersonating) && (
          <MenuItem onClick={() => setImpersonationBarOpen(true)}>
            <ListItemDecorator>
              <People fontSize="small" />
            </ListItemDecorator>
            Impersonate
          </MenuItem>
        )}
        <MenuItem>
          <ListItemDecorator
            sx={{
              display: { xs: 'none', sm: 'flex', flexDirection: 'row', justifyContent: 'space-between', zIndex: 10 },
            }}
            onClick={(): void =>
              drawer === DrawerType.Notifications ? closeDrawer() : openDrawer(DrawerType.Notifications)
            }
          >
            <Stack direction="row" alignItems="center" gap={1}>
              <Badge invisible={!hasNotifications} badgeInset={2} size="sm">
                <NotificationsNoneOutlinedIcon fontSize="small" />
              </Badge>
              <Box pl={0.5}>Notifications</Box>
            </Stack>
          </ListItemDecorator>
        </MenuItem>
        <ListDivider />
        {localUser && (
          // xs: 'none' till we make settings page responsive
          <MenuItem
            component={GLink}
            to="/app/management/settings"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '&:hover': {
                textDecoration: 'none',
                textTransform: 'none',
              },
            }}
          >
            <Stack direction="row" alignItems="center" gap={1}>
              <ListItemDecorator>
                <Gear />
              </ListItemDecorator>
              Settings
            </Stack>
          </MenuItem>
        )}
        <MenuItem onClick={handleLogout}>
          <ListItemDecorator>
            <Logout fontSize="small" />
          </ListItemDecorator>
          Logout
        </MenuItem>
      </Menu>
    </Dropdown>
  );
};

export default AppBarProfile;

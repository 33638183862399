import type { ReactElement, ReactNode } from 'react';
import { Grid, Stack, Typography } from '@mui/joy';
import Tile, { TileContent } from 'components/technical/Tile/Tile';

const YieldResultTile = ({
  value,
  title,
  withSign = false,
}: { value: string; title: ReactNode | string; withSign?: boolean }): ReactElement => {
  const sign = withSign ? '+' : '';
  return (
    <Grid xs={12} sm={12} md={6} lg={3} direction={'row'}>
      <Tile fullHeight>
        <TileContent title={<Typography level="title-md-emphasis">{title}</Typography>}>
          <Stack justifyContent="space-between" height="100%">
            <Stack direction="row" alignItems="center" justifyContent="space-between" flexWrap="wrap">
              <Typography level="h2" component="span" sx={{ color: sign === '+' ? 'success.500' : 'error.500' }}>
                {withSign ? `${sign}${value}` : value}
              </Typography>
            </Stack>
          </Stack>
        </TileContent>
      </Tile>
    </Grid>
  );
};

export default YieldResultTile;

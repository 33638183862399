import { Card } from '@mui/joy';
import type { FunctionComponent } from 'react';
import { useParams } from 'react-router';
import { TwoThirdsLayout } from 'components/TwoThirdsLayout.tsx';
import HeaderBar from 'components/technical/HeaderBar/HeaderBar';
import SectionColumn from 'components/technical/layout/Column/SectionColumn';
import Message from 'components/technical/Message';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';
import { useStrategyQuery } from 'generated/graphql';

import StrategyAllocation from './StrategyAllocationContainer';
import StrategyMonthlyReturnsContainer from './StrategyMonthlyReturnsContainer';
import StrategyPerformanceContainer from './StrategyPerformanceChart';
import StrategyReturnDistributionContainer from './StrategyReturnDistributionContainer';
import StrategyRiskMetricsContainer from './StrategyRiskMetricsContainer';
import StrategySortinoSharpeContainer from './StrategySortinoSharpeContainer';
import StrategyTradeList from './StrategyTradeList';
import StrategyTradingStatistics from './StrategyTradingStatistics';
import StrategyUnderwaterContainer from './StrategyUnderwaterContainer';

const StrategyDashboard: FunctionComponent = () => {
  const { label: strategy } = useParams();

  const { data, loaded, Fallback } = useDefaultErrorHandling(useStrategyQuery({ variables: { label: strategy! } }));

  if (!loaded) {
    return <Fallback />;
  }

  if (!strategy) {
    return <Message>Invalid strategy: {strategy}</Message>;
  }

  if (!data.strategy.strategy) {
    return <Message>Missing strategy data</Message>;
  }

  return (
    <>
      <TwoThirdsLayout
        left={
          <SectionColumn>
            <HeaderBar title={data.strategy.strategy.name} />
            <Card size="sm">{data.strategy.strategy.description}</Card>
          </SectionColumn>
        }
      />
      <TwoThirdsLayout
        left={<StrategyPerformanceContainer strategy={strategy} />}
        right={<StrategyRiskMetricsContainer strategy={strategy} />}
      />
      <StrategyAllocation strategy={strategy} />
      <TwoThirdsLayout
        left={<StrategyTradeList strategy={strategy} />}
        right={<StrategyTradingStatistics strategy={strategy} />}
      />
      <TwoThirdsLayout left={<StrategyReturnDistributionContainer strategy={strategy} />} />
      <StrategySortinoSharpeContainer strategy={strategy} />
      <StrategyUnderwaterContainer strategy={strategy} />
      <TwoThirdsLayout left={<StrategyMonthlyReturnsContainer strategy={strategy} />} />
    </>
  );
};

export default StrategyDashboard;

import type { Schema } from 'yup';
import * as yup from 'yup';
import { type IAsset, IAssetType, IDerivativeType, IPositionSide } from '../../../../generated/graphql.tsx';
import { arrayLengthSchemaFactory } from '../../../../validation.ts';
import type { FormInputType } from '../../../technical/form/Form.types.ts';
import type { SelectOption } from '../../../technical/inputs/Select/Select.props.ts';
import capitalize from 'lodash/fp/capitalize';
import type { RecursivePartial } from '../../../type.utils.ts';
import isNil from 'lodash/fp/isNil';
import { isAsset } from '../../../market/asset/Asset.types.ts';
import {
  type AssetLabelInput,
  type DerivativeAssetLabelInput,
  isAssetLabelInput,
  type NotVerifiedAsset,
} from '../../../market/asset/AssetLabelService.ts';
import { useMemo } from 'react';

export type StressTestFormAsset =
  | Exclude<AssetLabelInput, { type: IAssetType.Derivative }>
  | (DerivativeAssetLabelInput & { derivativeDetails: { derivativeType: IDerivativeType } });

export const isStressTestFormAsset = (asset: NotVerifiedAsset): asset is StressTestFormAsset => {
  if (!isAssetLabelInput(asset)) {
    return false;
  }

  if (![IAssetType.Public, IAssetType.Derivative].includes(asset.type)) {
    return false;
  }

  if (asset.type === IAssetType.Public) {
    return true;
  }

  return !isNil((asset as RecursivePartial<IAsset>).derivativeDetails?.derivativeType);
};

export const useGetStressTestFormAsset = (assets: NotVerifiedAsset[]): StressTestFormAsset[] => {
  return useMemo(() => assets.filter((asset): asset is StressTestFormAsset => isStressTestFormAsset(asset)), [assets]);
};

export interface PortfolioPosition {
  asset: {
    id: string;
    symbol: string;
    type: IAssetType;
    derivativeDetails?: {
      derivativeType: IDerivativeType;
    };
  };
  amount: number;
  side: IPositionSide | null;
  premium: null | undefined | number;
  entryPrice: null | undefined | number;
}

export interface PortfolioStepPortfolioOutput {
  portfolio: PortfolioPosition[];
  portfolioLength?: undefined | number;
}

export interface PortfolioStepOutput {
  portfolios: PortfolioStepPortfolioOutput[];
  portfolioLength?: undefined | number;
}

export type PortfolioStepInput = FormInputType<PortfolioStepOutput>;

export const positionSideValues: SelectOption<IPositionSide>[] = Object.values(IPositionSide).map((value) => ({
  label: capitalize(value),
  value: value,
  key: value,
}));

// biome-ignore lint/suspicious/noExplicitAny:
const isInvalidAssetOrNotDerivative = (asset: any): boolean => {
  if (isNil(asset)) {
    return true;
  }

  if (!isAsset(asset)) {
    return true;
  }

  if (asset.type !== IAssetType.Derivative) {
    return true;
  }

  return false;
};

const positionSchema = yup.object({
  asset: yup
    .object({
      id: yup.string().required(),
    })
    .required()
    .default(null),
  amount: yup
    .number()
    .required()
    .when('asset', ([asset], schema) => {
      if (isInvalidAssetOrNotDerivative(asset)) {
        return schema;
      }

      return schema.positive();
    }),
  side: yup.string().when('asset', ([asset], schema) => {
    if (isInvalidAssetOrNotDerivative(asset)) {
      return schema.oneOf(positionSideValues.map((value) => value.value)).nullable();
    }

    return schema.oneOf(Object.values(IPositionSide), 'Derivatives must be long or short').required();
  }),
  premium: yup.number().nullable(),
  entryPrice: yup
    .number()
    .positive()
    .nullable()
    .when('asset', ([asset], schema) => {
      if (isInvalidAssetOrNotDerivative(asset)) {
        return schema;
      }

      if (![IDerivativeType.PerpetualFuture, IDerivativeType.Future].includes(asset.derivativeDetails.derivativeType)) {
        return schema;
      }

      return schema.required();
    }),
});

const portfolioSchema = yup.object({
  portfolio: yup.array().of(positionSchema).required(),
  portfolioLength: arrayLengthSchemaFactory('portfolio', 'At least one position is required'),
});

export const schema: Schema<unknown> = yup.object({
  portfolios: yup.array().of(portfolioSchema).required(),
  portfolioLength: arrayLengthSchemaFactory('portfolios', 'At least one portfolio is required'),
});

import { Card, Stack } from '@mui/joy';
import type { Dayjs } from 'dayjs';
import { type ReactElement, useEffect, useRef, useState } from 'react';
import { getDefaultRange } from 'components/predefinedDateRanges';
import ActionsHeaderBar from 'components/technical/actions/ActionsHeaderBar';
import { useRegisterActions } from 'components/technical/actions/UseRegisterActions';
import type { HighChartRef } from 'components/technical/charts/HighChartsWrapper/Highchart.utils';
import { HeaderActions } from 'components/technical/HeaderBar/HeaderBar';
import PredefinedDateRangeButtonsWithCalendar from 'components/technical/inputs/date/PredefinedDateRangeButtonsWithCalendar';
import GRadioGroup from 'components/technical/inputs/GRadioGroup/GRadioGroup';
import { ExposureChart, type ExposureDataType } from './ExposureChart.tsx';

type Option = {
  value: ExposureDataType;
  label: string;
  key: string;
};

export const exposureRadioOptions: Option[] = [
  { value: 'dollars', label: 'Dollars', key: 'dollars' },
  { value: 'percentages', label: 'Percentages', key: 'percentages' },
];

export const CHART_TITLE = 'Portfolio exposure';

const ExposureChartWrapper = (): ReactElement => {
  const defaultDateRange = getDefaultRange();
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs] | null>(defaultDateRange.value);
  const registerActions = useRegisterActions();

  const [dataType, setDataType] = useState<ExposureDataType>('dollars');
  const chartRef = useRef<null | HighChartRef>(null);

  useEffect(() => {
    return registerActions(
      <HeaderActions>
        <GRadioGroup
          width="minContent"
          value={dataType}
          onChange={(selectedDataType): void => {
            setDataType(selectedDataType);
            chartRef?.current?.chart.zoomOut();
          }}
          options={exposureRadioOptions}
        />
      </HeaderActions>
    ).deregister;
  }, [registerActions, dataType]);

  return (
    <Stack spacing={1.5}>
      <ActionsHeaderBar title={CHART_TITLE} />
      <Card>
        <Stack spacing={1.5}>
          <PredefinedDateRangeButtonsWithCalendar
            defaultValue={defaultDateRange}
            onChange={(val): void => setDateRange(val)}
          />
          <ExposureChart ref={chartRef} dataType={dataType} dateRange={dateRange} fullHeight={false} />
        </Stack>
      </Card>
    </Stack>
  );
};

export default ExposureChartWrapper;

import SubmitStep from './SubmitStep.tsx';
import type { AssetOptimizerInputFields } from '../asset/AssetOptimizer.validation.ts';
import type { StepConfig } from '../../../../technical/wizard/StepConfig.ts';
import type { TFallback } from '../../../../technical/UseDefaultErrorHandling.tsx';
import type { PortfolioOptimizerInputFields } from '../portfolio/PortfolioOptimizer.validation.ts';

export const config = (
  forecastFallback: TFallback | undefined,
  skippedForecast: boolean
): StepConfig<AssetOptimizerInputFields | PortfolioOptimizerInputFields> => ({
  label: 'Submit',
  element: <SubmitStep forecastFallback={forecastFallback} skippedForecast={skippedForecast} />,
  fields: [],
});

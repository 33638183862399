import { bignumber, type BigNumber } from 'mathjs';
import type { SunburstChartData } from './SunburstChart.props';
import isNil from 'lodash/fp/isNil';
import bigNumMath from '../../../../bigNumMath.ts';
import stringify from 'json-stable-stringify';

export const isChildElement = <CHILD extends ElementChild>(root: ElementTree<CHILD>): root is CHILD => {
  return root.elements.length === 0;
};

export type ElementChild = {
  label: string;
  color: string;
  elements: [];
};

export type ElementTree<CHILD extends ElementChild> =
  | {
      label: string;
      color: string;
      elements: ElementTree<CHILD>[];
    }
  | CHILD;

const calculateChartInputLevel = <CHILD extends ElementChild, TEXT = string>({
  root,
  rootValue,
  valueProvider,
  textProvider,
  cap = undefined,
  context,
}: {
  root: ElementTree<CHILD>;
  // only present at root
  rootValue: BigNumber | null;
  valueProvider: (row: CHILD, cap?: BigNumber) => BigNumber;
  textProvider: (value: number) => TEXT;
  cap?: BigNumber;
  context: {
    parentId: string;
    data: Omit<SunburstChartData<TEXT>, 'hoverinfo' | 'textinfo'>;
  };
}): { total: BigNumber; totalCoeff: BigNumber } => {
  if (isChildElement(root)) {
    const total = rootValue ?? valueProvider(root);
    const totalCoeff = isNil(rootValue)
      ? valueProvider(root, cap).abs()
      : isNil(cap)
        ? rootValue.abs()
        : bigNumMath.min(cap, rootValue).abs();

    // ids cannot change between rerenders - it crashes the chart
    context.data.ids.push(`${context.parentId}-${stringify(root)}`);
    context.data.labels.push(root.label);
    context.data.parents.push(context.parentId);
    context.data.values.push(totalCoeff.toNumber());
    context.data.text.push(textProvider(total.toNumber()));
    context.data.marker.colors.push(root.color);

    return {
      total,
      totalCoeff: totalCoeff,
    };
  }

  let totalLevel = bignumber(0);
  let totalLevelCoeff = bignumber(0);
  const { elements, ...rest } = root;
  const groupId = `${context.parentId}-${stringify(rest)}`;
  for (const element of elements) {
    const { total, totalCoeff } = calculateChartInputLevel({
      root: element,
      rootValue: null,
      cap,
      valueProvider,
      context: {
        parentId: groupId,
        data: context.data,
      },
      textProvider,
    });

    totalLevel = totalLevel.plus(total);
    totalLevelCoeff = totalLevelCoeff.plus(totalCoeff);
  }

  context.data.ids.push(groupId);
  context.data.labels.push(root.label);
  context.data.parents.push(context.parentId);
  context.data.values.push(totalLevelCoeff.toNumber());
  context.data.text.push(textProvider(totalLevel.toNumber()));
  context.data.marker.colors.push(root.color);

  return {
    total: totalLevel,
    totalCoeff: totalLevelCoeff,
  };
};

export const calculateChartInput = <CHILD extends ElementChild, TEXT = string>({
  root,
  valueProvider,
  textProvider,
  cap = undefined,
  rootValue,
}: {
  root: ElementTree<CHILD>;
  valueProvider: (row: CHILD, cap?: BigNumber) => BigNumber;
  textProvider: (value: number) => TEXT;
  cap?: BigNumber;
  rootValue: BigNumber;
}): Omit<SunburstChartData<TEXT>, 'hoverinfo' | 'textinfo'> => {
  const ids: string[] = [];
  const labels: string[] = [];
  const parents: string[] = [];
  const values: number[] = [];
  const colors: string[] = [];
  const text: TEXT[] = [];
  const links: string[] = [];

  const data: Omit<SunburstChartData<TEXT>, 'hoverinfo' | 'textinfo'> = {
    ids,
    labels,
    parents,
    values,
    marker: {
      colors,
    },
    text,
    links,
  };

  calculateChartInputLevel({
    root: root,
    rootValue: rootValue,
    valueProvider,
    textProvider,
    cap,
    context: {
      parentId: '',
      data: data,
    },
  });

  return data;
};

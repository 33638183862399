import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import { Chip, Stack, Typography } from '@mui/joy';
import { isNegative, isPositive } from 'mathjs';
import type { FunctionComponent, ReactElement, ReactNode } from 'react';
import { formatNumber, formatPercentage } from '../../formatter.utils';
import type { DefaultColorPalette } from '@mui/joy/styles/types';

export const getChangeColor = (value: number): DefaultColorPalette => {
  if (isPositive(value)) {
    return 'success';
  }

  if (isNegative(value)) {
    return 'danger';
  }
  return 'neutral';
};

interface TitleWithChipProps {
  title: ReactNode;
  change: number | undefined | number[];
  type?: 'bps' | 'percentage';
}

export const TileChangeChip = ({
  change,
  type,
  title,
}: { change: number | undefined | number[]; type?: 'bps' | 'percentage'; title?: ReactNode }): ReactElement => {
  const changes = typeof change === 'number' ? [change] : change;
  return (
    <Stack lineHeight={2} direction="row" justifyContent="space-between" alignItems="center">
      {typeof title === 'string' ? <Typography level="title-sm">{title}</Typography> : title}
      <Stack direction="row" gap={1}>
        {(changes ?? []).map((val) => (
          <Chip key={val} size="sm" color={getChangeColor(val)}>
            <Stack alignItems="center" direction="row" gap={1}>
              {isPositive(val) && <CallMadeIcon fontSize="inherit" />}
              {isNegative(val) && <CallReceivedIcon fontSize="inherit" />}
              <Typography level="body-xs2">
                {type === 'bps' ? `${formatNumber(val)} bps` : formatPercentage(val)}
              </Typography>
            </Stack>
          </Chip>
        ))}
      </Stack>
    </Stack>
  );
};

const TitleWithChip: FunctionComponent<TitleWithChipProps> = ({ title, change, type = 'percentage' }) => {
  return (
    <Stack lineHeight={2} direction="row" justifyContent="space-between" alignItems="center">
      {title}
      <TileChangeChip change={change} type={type} />
    </Stack>
  );
};

export default TitleWithChip;

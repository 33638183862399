import {
  type IAllocation,
  type IPortfolioExposureQuery,
  usePortfolioExposureQuery,
} from '../../../../generated/graphql.tsx';
import type { ReactElement, Ref } from 'react';
import {
  cashFormatter,
  dateTimeAxisFormat,
  dateTimeExportFormat,
  type HighChartRef,
  type HighchartSeries,
  percentageFormatter,
  tooltipFormat,
} from '../../../technical/charts/HighChartsWrapper/Highchart.utils.ts';
import dayjs, { type Dayjs } from 'dayjs';
import { useSubAccountAssetFilters } from '../../../technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters.tsx';
import { convertDateRangeToSinceToDate } from '../../../technical/inputs/date/dateRange.utils.ts';
import HighChartsContainer from '../../../technical/charts/HighChartsWrapper/HighChartsWrapper.tsx';
import type Highcharts from 'highcharts';
import { capitalize } from 'lodash/fp';
import { bignumber } from 'mathjs';
import { formatCash, formatPercentage } from '../../../formatter.utils.ts';

export type ExposureDataType = 'dollars' | 'percentages';

const exposureSummaryFields: (keyof IAllocation)[] = ['cash', 'long', 'short', 'net', 'gross'];

const calculateChartData = (data: IPortfolioExposureQuery, dataType: ExposureDataType): HighchartSeries[] => {
  return exposureSummaryFields.map((field) => ({
    name: capitalize(field),
    data: data.portfolio.snapshot
      .map((snapshot) => ({
        date: snapshot.date,
        exposure: snapshot.summary.exposure,
        balance: snapshot.summary.balance,
      }))
      .map(({ exposure, balance, date }) => {
        const yValue =
          dataType === 'dollars'
            ? bignumber(exposure[field]).toNumber()
            : bignumber(balance.total).isZero()
              ? null
              : bignumber(exposure[field]).div(balance.total).toNumber();

        return {
          x: dayjs.utc(date.toString()).valueOf(),
          y: yValue,
          textValue: dataType === 'dollars' ? formatCash(yValue) : formatPercentage(yValue),
        };
      }),
    type: 'line',
  }));
};

const calculateOptions = (dataType: ExposureDataType): Highcharts.Options => {
  return {
    ...dateTimeAxisFormat,
    ...dateTimeExportFormat(`portfolio-exposure-${dataType === 'dollars' ? 'dollars' : 'percentages'}`),
    ...tooltipFormat,
    yAxis: {
      labels: {
        enabled: true,
        formatter: dataType === 'dollars' ? cashFormatter : percentageFormatter,
      },
      title: {
        text: dataType === 'dollars' ? 'Dollars' : 'Percentages',
      },
    },
    plotOptions: {
      series: {
        marker: {
          symbol: 'circle',
        },
      },
    },
  };
};

export const ExposureChart = (props: {
  ref?: Ref<HighChartRef>;
  dateRange: [Dayjs, Dayjs] | null;
  dataType: ExposureDataType;
  fullHeight: boolean;
}): ReactElement => {
  const { subAccountAssetFilters } = useSubAccountAssetFilters();

  const queryOutput = usePortfolioExposureQuery({
    variables: {
      ...convertDateRangeToSinceToDate(props.dateRange),
      subAccountAssetFilters,
    },
  });

  return (
    <HighChartsContainer<IPortfolioExposureQuery>
      ref={props.ref}
      height={props.fullHeight ? 'fullHeight' : undefined}
      {...queryOutput}
      calculateOptions={(): Highcharts.Options => calculateOptions(props.dataType)}
      calculateChartData={(data): HighchartSeries[] => calculateChartData(data, props.dataType)}
    />
  );
};

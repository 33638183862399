import { Box, ButtonGroup, FormControl } from '@mui/joy';
import type { FunctionComponent, ReactNode, Ref } from 'react';
import 'react-datepicker/dist/react-datepicker.css';

import { REPLAY_UNMASK_CLASS_NAME } from 'setup/initializeSentry';
import { type PredefinedRange, defaultQuickAccessDateRanges } from '../../../predefinedDateRanges';
import GButton from '../GButton/GButton';
import { shouldRenderInputLabel } from '../LabelService.ts';
import InputLabel from '../InputLabel.tsx';
import InputError from '../InputError.tsx';

export type PredefinedDateRangeButtonsProps = {
  value: PredefinedRange | undefined;
  alignRight?: boolean;
  onChange: (rangeLabel: PredefinedRange) => void;
  onBlur?: () => void;
  dateRanges?: PredefinedRange[];
  error?: string;
  ref?: Ref<HTMLDivElement>;
  disabled?: boolean;
} & {
  label?: ReactNode;
  required?: boolean;
};

const PredefinedDateRangeButtons: FunctionComponent<PredefinedDateRangeButtonsProps> = ({
  onChange,
  onBlur,
  alignRight = true,
  value,
  dateRanges = defaultQuickAccessDateRanges,
  error,
  ref,
  ...rest
}) => {
  return (
    <Box marginLeft={alignRight ? 'auto' : undefined} width={'min-content'}>
      <FormControl error={Boolean(error)} onBlur={onBlur}>
        {shouldRenderInputLabel({
          ...rest,
          showLabelAboveInput: true,
        }) ? (
          <InputLabel {...rest} />
        ) : (
          <></>
        )}
        <Box ref={ref} className={REPLAY_UNMASK_CLASS_NAME}>
          <ButtonGroup>
            {dateRanges.map((range) => (
              <GButton
                disabled={rest.disabled}
                key={range.label}
                variant={value?.label === range.label ? 'soft' : 'outlined'}
                color="primary"
                onClick={(): void => {
                  onChange(range);
                }}
              >
                {range.label}
              </GButton>
            ))}
          </ButtonGroup>
        </Box>
        <InputError error={error} />
      </FormControl>
    </Box>
  );
};

export default PredefinedDateRangeButtons;

import { Box, Stack } from '@mui/joy';
import * as Sentry from '@sentry/react';
import { type FunctionComponent, useState } from 'react';
import { Outlet } from 'react-router';

import { REPLAY_UNMASK_CLASS_NAME } from 'setup/initializeAll.ts';
import { getEnvConfig } from '../../../config';
import CookieBanner from '../../../CookieBanner';
import MsClarity from '../../../MsClarity';
import { useAuth } from '../../../UseAuth.tsx';
import NotificationListener from '../../copilot/newsNotification/NotificationListener';
import { FiltersContainer as QuantInsightsFilters } from '../../market/asset/filters/FiltersContainer.tsx';
import Appbar from '../Appbar/Appbar';
import ImpersonationAppbar from '../Appbar/ImpersonationAppbar';
import ImperativeModalProvider from '../ImperativeModal/ImperativeModalProvider.tsx';
import GSnackbarProvider from '../GSnackbarProvider/GSnackbarProvider';
import InpersonationBarProvider from '../ImpersonationBarProvider.tsx';
import Sidebar, { sidebarAnimation } from '../Sidebar/Sidebar';
import GErrorBoundary from '../GErrorBoundary.tsx';
import { useScreenBreakpointDown } from '../screenSizeUtils.ts';
import Drawer from '../Drawer/Drawer.tsx';
import { TourProvider } from '../FeatureTour/TourContext.tsx';
import TourLauncher from '../FeatureTour/TourLauncher.tsx';

const Main: FunctionComponent = () => {
  const { originalUserCognitoUsername } = useAuth();
  const config = getEnvConfig();
  const mobileView = useScreenBreakpointDown('sm');
  const [isSideBarOpen, setSideBarOpen] = useState(!mobileView);

  return (
    <QuantInsightsFilters>
      <>
        {config.clarityId && originalUserCognitoUsername && (
          <MsClarity id={config.clarityId} userId={originalUserCognitoUsername} />
        )}
        <CookieBanner />
        <Sentry.ErrorBoundary fallback={<div>Application error</div>}>
          <ImperativeModalProvider>
            <GSnackbarProvider>
              <Drawer>
                <InpersonationBarProvider>
                  <NotificationListener />
                  <Stack
                    direction="row"
                    sx={{
                      flexGrow: 1,
                    }}
                    width="100%"
                    spacing={0}
                  >
                    <Sidebar isSideBarOpen={isSideBarOpen} setSideBarOpen={setSideBarOpen} />
                    <Stack
                      spacing={0}
                      sx={{
                        ml: isSideBarOpen ? 'var(--sidebar-width)' : 0,
                        transition: `margin ${sidebarAnimation}`,
                        background: 'background.body',
                        flexGrow: 1,
                        minWidth: 0,
                      }}
                    >
                      <ImpersonationAppbar />
                      <Appbar />
                      <Box className={REPLAY_UNMASK_CLASS_NAME} sx={{ height: '100%', flexGrow: 1, minWidth: 0 }}>
                        <GErrorBoundary resetOnNavigation>
                          <TourProvider>
                            <Outlet />
                            <TourLauncher />
                          </TourProvider>
                        </GErrorBoundary>
                      </Box>
                    </Stack>
                  </Stack>
                </InpersonationBarProvider>
              </Drawer>
            </GSnackbarProvider>
          </ImperativeModalProvider>
        </Sentry.ErrorBoundary>
      </>
    </QuantInsightsFilters>
  );
};

export default Main;

import { Card, Grid } from '@mui/joy';
import { capitalize, isNil, sortBy } from 'lodash/fp';
import { bignumber } from 'mathjs';
import type { FunctionComponent } from 'react';
import { formatNumber } from 'components/formatter.utils';
import {
  type HighchartSeries,
  noYAxisTitle,
  tooltipFormat,
} from 'components/technical/charts/HighChartsWrapper/Highchart.utils';
import HighChartsContainer from 'components/technical/charts/HighChartsWrapper/HighChartsWrapper';
import { type IOptionsQuery, type IGreeks, IOptionType } from 'generated/graphql';

import { factorName, factors } from './FactorService.ts';
import type * as Highcharts from 'highcharts';
type Greeks = keyof Omit<IGreeks, '__typename'>;

type GreekChartProps = {
  greek: Greeks;
  filename: string;
  data: IOptionsQuery['assets']['options']['pricedOptions'];
};

const calculateChartData = (
  data: IOptionsQuery['assets']['options']['pricedOptions'],
  greek: Greeks
): HighchartSeries[] => {
  const chartData: HighchartSeries[] = [];

  for (const factor of factors) {
    for (const optionType in IOptionType) {
      const option = IOptionType[optionType as keyof typeof IOptionType];
      const dataByType = data.filter((row) => row.option.derivativeDetails?.optionType === option);

      const dataPoints = dataByType.map((row) => {
        const factorRow = row[factor];
        const strikePrice = row.option.derivativeDetails?.strikePrice;

        const greekValue = factorRow ? bignumber(factorRow.greeks[greek]).toNumber() : null;

        return {
          x: bignumber(strikePrice ?? 0).toNumber(),
          y: greekValue,
          textValue: isNil(greekValue) ? '' : formatNumber(greekValue),
        };
      });

      const serie = {
        name: `${optionType} (${factorName[factor]})`,
        type: 'line',
        data: dataPoints,
        marker: {
          enabled: true,
          radius: 2,
          symbol: 'circle',
        },
        visible: factor === 'markFactors',
      } as const;

      const sortedData = sortBy((row) => row.x, serie.data);
      chartData.push({ ...serie, data: sortedData });
    }
  }

  return chartData;
};

const calculateOptions = (filename: string, title: string): Highcharts.Options => {
  return {
    title: {
      text: capitalize(title),
    },
    exporting: {
      filename,
    },
    ...tooltipFormat,
    ...noYAxisTitle,
    xAxis: {
      title: {
        text: 'Strike',
      },
    },
  };
};

const GreekChart: FunctionComponent<GreekChartProps> = ({ data, filename, greek }) => {
  return (
    <Grid md={4} xs={12}>
      <Card>
        <HighChartsContainer<IOptionsQuery['assets']['options']['pricedOptions']>
          data={data}
          loading={false}
          key={greek}
          calculateChartData={(data): HighchartSeries[] => calculateChartData(data, greek)}
          calculateOptions={(): Highcharts.Options => calculateOptions(filename, greek)}
        />
      </Card>
    </Grid>
  );
};

export default GreekChart;

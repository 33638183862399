import * as yup from 'yup';
import type { Schema } from 'yup';
import type { AssetLabelInput } from 'components/market/asset/AssetLabelService.ts';
import type { FormInputType } from 'components/technical/form/Form.types.ts';
import type { SelectOption } from 'components/technical/inputs/Select/Select.props.ts';

import { IConstraintFormulation, IGroupConstrainedQuantity } from '../../../../../generated/graphql.tsx';
import { translate, yupLocale } from '../../../../../setup/yupLocale.ts';
import { yupWhen } from '../../../../../validation.ts';
import { ConstraintType, constraintTypeValues } from '../ConstraintTypeValues.validation.ts';
import { capitalize } from 'lodash/fp';
import type { Portfolio } from '../portfolio/PortfolioOptimizerWizard.tsx';
import { greaterThanMin, smallerThanMax } from '../MinMax.validation.ts';
import type { AssetGroup } from '../asset/AssetOptimizerWizard.tsx';

export const groupConstrainedQuantityLabels = Object.fromEntries(
  Object.entries(IGroupConstrainedQuantity).map(([key, value]) => [value, key])
);

export const groupConstrainedQuantityValues: SelectOption<string>[] = Object.entries(IGroupConstrainedQuantity).map(
  ([key, value]) => ({
    key,
    label: capitalize(value.replace(/_/g, ' ')),
    value: value,
  })
);

export const allocationConstraintTypeValues: SelectOption<ConstraintType>[] = constraintTypeValues.filter((option) =>
  [ConstraintType.Equal, ConstraintType.Between].includes(option.value)
);

export interface AllocationConstraintOutput {
  item: {
    asset?: AssetLabelInput;
    group?: AssetGroup;
    portfolio?: Portfolio;
    type: 'asset' | 'group' | 'portfolio';
  };
  constrainedQuantity?: IGroupConstrainedQuantity | unknown; // not relevant for assets
  constraintType: ConstraintType;
  constraintValue: { value: number | null; min: number | null; max: number | null };
}

export interface AllocationConstraintsOutput {
  constraintType: IConstraintFormulation;
  constraints: AllocationConstraintOutput[];
}

export type AllocationConstraintsInput = FormInputType<Omit<AllocationConstraintsOutput, 'constraints'>> & {
  constraints: {
    item: {
      asset?: AssetLabelInput;
      group?: AssetGroup;
      portfolio?: Portfolio;
      type: 'asset' | 'group' | 'portfolio';
    } | null;
    constrainedQuantity?: IGroupConstrainedQuantity | unknown; // not relevant for assets
    constraintType: ConstraintType;
    constraintValue: { value: number | null; min: number | null; max: number | null };
  }[];
};

const baseRequiredHiddenLabelNumberSchema = yup.number().required(translate(yupLocale.number.required));

export const schema: Schema<unknown> = yup.object({
  constraintType: yup.string().required().oneOf(Object.values(IConstraintFormulation)),
  constraints: yupWhen(
    ['assetConstraintType', 'allowShortAndLeverage'],
    ([assetConstraintType, allowShortAndLeverage]) => {
      return yup.array(
        yup.object({
          item: yup.object({}).required(),
          constrainedQuantity: yupWhen(['item'], ([item]) => {
            if (item?.type === 'group') {
              return yup.string().required().oneOf(Object.values(IGroupConstrainedQuantity));
            }

            return yup.mixed().nullable();
          }),
          constraintType: yup.string().oneOf(Object.values(ConstraintType)).required(),
          constraintValue: yupWhen(
            ['item', 'constraintType'],
            ([item, constraint]: [AllocationConstraintOutput['item'] | null, ConstraintType | null]) => {
              const fallbackSchema = yup.number().nullable().optional();

              const maxValueSchema =
                !allowShortAndLeverage &&
                (item?.type === 'group' || assetConstraintType === IConstraintFormulation.Percentage)
                  ? yup.number().max(100, translate(yupLocale.number.max))
                  : yup.number();

              const minValueSchema = !allowShortAndLeverage
                ? yup.number().min(0, translate(yupLocale.number.min))
                : yup.number();

              return yup
                .object({
                  value:
                    constraint === ConstraintType.Equal
                      ? baseRequiredHiddenLabelNumberSchema.concat(minValueSchema).concat(maxValueSchema)
                      : fallbackSchema,
                  min:
                    constraint === ConstraintType.Between
                      ? baseRequiredHiddenLabelNumberSchema
                          .test('minValue', 'Must be smaller or equal to max', smallerThanMax)
                          .concat(minValueSchema)
                          .concat(maxValueSchema)
                          .required() // necessary to display correct error message
                      : fallbackSchema,
                  max:
                    constraint === ConstraintType.Between
                      ? baseRequiredHiddenLabelNumberSchema
                          .test('maxValue', 'Must be greater or equal to min', greaterThanMin)
                          .concat(minValueSchema)
                          .concat(maxValueSchema)
                          .required() // necessary to display correct error message
                      : fallbackSchema,
                })
                .required();
            }
          ),
        })
      );
    }
  ),
});

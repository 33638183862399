import { Grid, Stack, Typography } from '@mui/joy';
import type { ReactElement } from 'react';
import ColorSchemeChanger from './ColorSchemeToggler';
import TilesDateRangeSelector from './TilesDateRangeSelector';
import { JournalModeChanger } from './JournalModeChanger';
import { Title } from 'components/technical/StackTitle';
import { SubHeader } from 'components/technical/typography/SubHeader';
import {
  AccountBalanceWalletOutlined,
  DiamondOutlined,
  GroupsOutlined,
  NotificationsActiveOutlined,
  ReceiptLongOutlined,
  SavingsOutlined,
  SettingsOutlined,
  ViewDayOutlined,
} from '@mui/icons-material';
import { CONTENT_MIN_HEIGHT } from './constant';
import GridCard from 'components/technical/GCard/GridCard';
import { useNavigate } from 'react-router-dom';
import { GRID_FULL_WIDTH, SELECTABLE_CARD_STYLE } from 'components/technical/GCard/consts';

const ManagementDashboard = (): ReactElement => {
  const navigate = useNavigate();

  const dashboardGroups = [
    {
      key: 'portfolio',
      title: (
        <Stack width="100%" spacing={2}>
          <Stack direction="row" justifyContent="flex-end" spacing={5}>
            <Stack spacing={0.5}>
              <Typography level="title-sm" alignSelf="end">
                Default tiles time frame
              </Typography>
              <TilesDateRangeSelector />
            </Stack>
            <Stack spacing={0.5}>
              <Typography level="title-sm" alignSelf="end">
                Mode
              </Typography>
              <ColorSchemeChanger color="primary" variant="button-group" size="sm" />
            </Stack>
          </Stack>
          <SubHeader title="Portfolio" withBorder />
        </Stack>
      ),
      items: [
        <GridCard
          key="accounts"
          gridWidth={GRID_FULL_WIDTH}
          height="100%"
          cardSx={SELECTABLE_CARD_STYLE}
          onClick={() => navigate('/app/management/account')}
          title={<Title className="title" icon={<AccountBalanceWalletOutlined fontSize="large" />} title="Accounts" />}
          content={
            <Typography level="body-sm" minHeight={CONTENT_MIN_HEIGHT}>
              View and edit your accounts.
            </Typography>
          }
        />,
        <GridCard
          key="sub-funds"
          gridWidth={GRID_FULL_WIDTH}
          height="100%"
          cardSx={SELECTABLE_CARD_STYLE}
          onClick={() => navigate('/app/management/sub-funds')}
          title={<Title className="title" icon={<SavingsOutlined fontSize="large" />} title="Sub-funds" />}
          content={
            <Typography level="body-sm" minHeight={CONTENT_MIN_HEIGHT}>
              Create sub-funds by grouping sub-accounts/exchange accounts/wallets
            </Typography>
          }
        />,
        <GridCard
          key="positions"
          gridWidth={GRID_FULL_WIDTH}
          height="100%"
          cardSx={SELECTABLE_CARD_STYLE}
          onClick={() => navigate('/app/management/positions')}
          title={<Title className="title" icon={<ViewDayOutlined fontSize="large" />} title="Positions" />}
          content={
            <Typography level="body-sm" minHeight={CONTENT_MIN_HEIGHT}>
              Edit portfolio positions for a specific date
            </Typography>
          }
        />,
        <GridCard
          selectable={false}
          key="journal"
          gridWidth={GRID_FULL_WIDTH}
          height="100%"
          title={<Title className="title" icon={<ReceiptLongOutlined fontSize="large" />} title="Journal" />}
          content={
            <Stack>
              <Typography level="body-sm" minHeight={CONTENT_MIN_HEIGHT}>
                Set cost basis by organization or sub-fund
              </Typography>
              <JournalModeChanger width="fullWidth" />
            </Stack>
          }
        />,
      ],
    },
    {
      key: 'market',
      title: <SubHeader title="Market" withBorder />,
      items: [
        <GridCard
          key="assets"
          gridWidth={GRID_FULL_WIDTH}
          height="100%"
          cardSx={SELECTABLE_CARD_STYLE}
          onClick={() => navigate('/app/management/asset')}
          title={<Title className="title" icon={<DiamondOutlined fontSize="large" />} title="Assets" />}
          content={
            <Typography level="body-sm" minHeight={CONTENT_MIN_HEIGHT}>
              View and edit your assets.
            </Typography>
          }
        />,
        <GridCard
          key="asset-groups"
          gridWidth={GRID_FULL_WIDTH}
          height="100%"
          cardSx={SELECTABLE_CARD_STYLE}
          onClick={() => navigate('/app/management/asset-groups')}
          title={<Title className="title" icon={<GroupsOutlined fontSize="large" />} title="Groups" />}
          content={
            <Typography level="body-sm" minHeight={CONTENT_MIN_HEIGHT}>
              View and edit your asset groups.
            </Typography>
          }
        />,
      ],
    },
    {
      key: 'settings',
      title: <SubHeader title="Settings" withBorder />,
      items: [
        <GridCard
          key="notifications"
          gridWidth={GRID_FULL_WIDTH}
          height="100%"
          cardSx={SELECTABLE_CARD_STYLE}
          onClick={() => navigate('/app/management/notification')}
          title={
            <Title className="title" title="Notifications" icon={<NotificationsActiveOutlined fontSize="large" />} />
          }
          content={
            <Typography level="body-sm" minHeight={CONTENT_MIN_HEIGHT}>
              Subscribe and unsubscribe from different types of notifications
            </Typography>
          }
        />,

        <GridCard
          key="account-settings"
          gridWidth={GRID_FULL_WIDTH}
          height="100%"
          cardSx={SELECTABLE_CARD_STYLE}
          onClick={() => navigate('/app/management/settings')}
          title={<Title className="title" title="Account settings" icon={<SettingsOutlined fontSize="large" />} />}
          content={
            <Typography level="body-sm" minHeight={CONTENT_MIN_HEIGHT}>
              Configure credentials, multi-factor authentication, and privacy
            </Typography>
          }
        />,
      ],
    },
  ];
  return (
    <Stack direction="column" spacing={10} p={1}>
      <Stack direction="column" spacing={2} sx={{ height: '100%' }}>
        {dashboardGroups.map((group) => (
          <Stack key={group.key} direction="column" spacing={0}>
            {group.title}
            <Grid container xs={12} spacing={2}>
              {group.items.map((item, index) => (
                <Grid key={index} xs={12} sm={6} md={6} xl={4}>
                  {item}
                </Grid>
              ))}
            </Grid>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default ManagementDashboard;

import { useForm } from 'react-hook-form';
import gYupResolver from '../../../../technical/form/gYupResolver.ts';
import * as yup from 'yup';
import { Stack } from '@mui/joy';
import { FormPredefinedDateRangeInput } from '../../../../technical/form/FormPredefinedDateRangeInput.tsx';
import type { PredefinedRangeLabel } from '../../../../predefinedDateRanges.ts';
import { defaultRowSpacing } from '../../../../StackSpacing.ts';
import { updateWidgetState, useWidgetState } from 'components/management/customDashboard/CustomWidgetContext.tsx';
import { calculateDefaultPresetRange } from 'components/management/TilesDateRangeSelector.tsx';
import WidgetSettings from 'components/management/customDashboard/WidgetSettings.tsx';
import { createDimensionAutocompleteOptions } from '../../../fund/SubFundService.tsx';
import FormSelect from 'components/technical/form/FormSelect.tsx';
import { useSubFundGridWidgetSettingsInputSuspenseQuery } from 'generated/graphql.tsx';
import { noDimensionsMessage } from './../SubFundPnlDashboard.tsx';

const schema = yup.object({
  title: yup.string().required(),
  dimension: yup.string().required(),
  predefinedDateRange: yup.object().required(),
});

interface FormState {
  title: string;
  dimension: string;
  predefinedDateRange: {
    label: PredefinedRangeLabel;
  };
}

export interface SubFundGridWidgetState {
  title: string;
  predefinedDateRange?: PredefinedRangeLabel;
  dimension?: string;
}

export const SubFundGridWidgetSettings = ({
  id,
  onFinishedEditing,
  onRemovedWidget,
}: {
  id: string;
  onFinishedEditing: () => void;
  onRemovedWidget: () => Promise<void>;
}) => {
  const initialState = useWidgetState<SubFundGridWidgetState>(id);
  const updater = updateWidgetState<SubFundGridWidgetState>();
  const { data } = useSubFundGridWidgetSettingsInputSuspenseQuery();
  const dimensionAutocompleteOptions = createDimensionAutocompleteOptions(
    data.portfolio.subFunds.list.map((item) => item.dimension)
  );

  const methods = useForm<FormState>({
    resolver: gYupResolver(schema),
    defaultValues: {
      dimension: dimensionAutocompleteOptions.options[0]?.value,
      ...initialState,
      predefinedDateRange: calculateDefaultPresetRange(initialState.predefinedDateRange),
    },
  });

  const handleFormSubmit = async (input: FormState): Promise<void> => {
    await updater({
      id,
      state: {
        title: input.title,
        predefinedDateRange: input.predefinedDateRange.label,
        dimension: input.dimension,
      },
      updateBackend: true,
    });

    onFinishedEditing();
  };

  return (
    <WidgetSettings methods={methods} handleFormSubmit={handleFormSubmit} onRemovedWidget={onRemovedWidget}>
      {dimensionAutocompleteOptions.options.length === 0 ? (
        noDimensionsMessage
      ) : (
        <Stack width={'100%'} rowGap={defaultRowSpacing}>
          <FormSelect
            {...dimensionAutocompleteOptions}
            width={'normal'}
            name={'dimension'}
            menuWidth={'normal'}
            label={'Dimension'}
            showLabelAboveInput
          />
          <FormPredefinedDateRangeInput name={'predefinedDateRange'} label={'Date range'} />
        </Stack>
      )}
    </WidgetSettings>
  );
};

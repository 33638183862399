import { PortfolioAttributionWidgetView } from './PortfolioAttributionWidgetView.tsx';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import {
  type PortfolioAttributionState,
  PortfolioAttributionWidgetSettings,
} from './PortfolioAttributionWidgetSettings.tsx';
import type { CustomWidgetDescription } from 'components/management/customDashboard/CustomWidgetDescription.types.ts';
import { DEFAULT_CLUSTER } from '../attribution/PerformanceAttribution.tsx';

export const attributionWidget: CustomWidgetDescription = {
  name: 'Daily P&L attribution',
  type: 'performanceAttribution',
  description: 'Analyze what contributed to your P&L',
  icon: () => <StackedLineChartIcon />,
  viewComponent: PortfolioAttributionWidgetView,
  settingsComponent: PortfolioAttributionWidgetSettings,
  initialState: {
    title: 'Daily P&L attribution',
    side: 'total',
    cluster: DEFAULT_CLUSTER,
  } satisfies Partial<PortfolioAttributionState>,
  initialH: 4,
  initialW: 3,
};

import { useWidgetState } from 'components/management/customDashboard/CustomWidgetContext';
import { calculateDefaultPresetRange } from 'components/management/TilesDateRangeSelector';
import SubFundPnlGrid from './SubFundPnlGrid.tsx';
import type { SubFundGridWidgetState } from './SubFundGridWidgetSettings.tsx';
import {
  useSubFundGridWidgetSettingsInputSuspenseQuery,
  useSubFundPnlSuspenseQuery,
} from '../../../../../generated/graphql.tsx';
import uniq from 'lodash/fp/uniq';
import { noDimensionsMessage } from './../SubFundPnlDashboard.tsx';
import Message from '../../../../technical/Message.tsx';
import { isNil } from 'lodash/fp';
import { formatISODate } from '../../../../formatter.utils.ts';
import type { ReactElement } from 'react';
import type { Dayjs } from 'dayjs';

const subFundGridWidgetPresetKey = (id: string) => `customWidget/subFundPerformanceStats/${id}`;
const SubFundGridWidgetContainer = ({
  id,
  dimension,
  startDate,
}: { id: string; dimension: string; startDate: Dayjs | null }): ReactElement => {
  const { data } = useSubFundPnlSuspenseQuery({
    variables: {
      dimensions: isNil(dimension) ? [] : [dimension],
      since: isNil(startDate) ? null : formatISODate(startDate),
    },
  });

  return (
    <SubFundPnlGrid
      data={data.portfolio.subFundsTimeWeightedReturns.subFunds}
      fullHeight
      presetKey={subFundGridWidgetPresetKey(id)}
    />
  );
};

export const SubFundPnlGridWidget = ({ id }: { id: string }): ReactElement => {
  const { data } = useSubFundGridWidgetSettingsInputSuspenseQuery();
  const state = useWidgetState<SubFundGridWidgetState>(id);
  const dimensions = uniq(data.portfolio.subFunds.list.map((item) => item.dimension));
  if (dimensions.length === 0) {
    return noDimensionsMessage;
  }

  const dimension = state.dimension ?? dimensions[0];
  if (!dimensions.includes(dimension)) {
    return <Message>Selected dimension doesn't exist</Message>;
  }

  const range = calculateDefaultPresetRange(state.predefinedDateRange);
  const rangeValue = range.value();
  return (
    <SubFundGridWidgetContainer id={id} dimension={dimension} startDate={isNil(rangeValue) ? null : rangeValue[0]} />
  );
};

import type { FieldValues, UseFormReturn } from 'react-hook-form';

const UNKNOWN_ERROR_MESSAGE = 'Unknown error';
export const useCognitoApiError = <T extends FieldValues>(
  methods: UseFormReturn<T>
): ((exception: unknown) => void) => {
  return (exception: unknown) => {
    if (exception instanceof Error) {
      // @ts-ignore
      methods.setError('cognitoApiError', {
        type: 'custom',
        message: exception.message,
      });

      return;
    }

    if (typeof exception === 'string') {
      // @ts-ignore
      methods.setError('cognitoApiError', {
        type: 'custom',
        message: exception,
      });

      return;
    }

    // @ts-ignore
    methods.setError('cognitoApiError', {
      type: 'custom',
      message: UNKNOWN_ERROR_MESSAGE,
    });
  };
};

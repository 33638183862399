import type { FunctionComponent } from 'react';
import { LinearProgress, Stack, Typography } from '@mui/joy';

type TileProgressFooterProps = {
  minValue: string;
  maxValue: string;
  progressValue: number | undefined;
};

export const TileProgressFooter: FunctionComponent<TileProgressFooterProps> = ({
  minValue,
  maxValue,
  progressValue,
}) => {
  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography level="body-xs2">{minValue}</Typography>
        <Typography level="body-xs2">{maxValue}</Typography>
      </Stack>
      <LinearProgress size="lg" color="primary" determinate value={progressValue ?? undefined} />
    </Stack>
  );
};

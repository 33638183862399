import { Modal, ModalDialog, Stack } from '@mui/joy';
import { Auth } from 'aws-amplify';
import { type ReactElement, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';
import CognitoApiErrorMessage from 'components/technical/form/CognitoErrorMessage';
import GFormProvider from 'components/technical/form/GFormProvider';
import gYupResolver from 'components/technical/form/gYupResolver';
import SubmitButton from 'components/technical/form/SubmitButton.tsx';
import { useCognitoApiError } from 'components/technical/form/UseCognitoApiError.tsx';
import GDialogHeader from 'components/technical/GDialog/GDialogHeader.tsx';
import { GDialogDescription } from 'components/technical/GDialogDescription/GDialogDescription.tsx';
import { FormInput } from 'components/technical/inputs';
import ResponsiveColumn from 'components/technical/layout/Column/ResponsiveColumn';
import { updatePasswordSchema } from './UpdatePasswordSchema.tsx';

interface Input {
  currentPassword: string;
  newPassword: string;
  newPasswordRepeat: string;
}

const UpdatePasswordDialog = ({ onClose }: { onClose: () => void }): ReactElement => {
  const methods = useForm<Input>({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      newPasswordRepeat: '',
    },
    resolver: gYupResolver(updatePasswordSchema),
  });

  const onError = useCognitoApiError(methods);
  const { showSuccessMessage } = useFeedback();

  useEffect(() => {
    const sub = methods.watch((_value, { name }) => {
      if (name !== 'newPassword' || !methods.formState.isSubmitted) {
        return;
      }

      methods.trigger('newPasswordRepeat');
    });

    return (): void => sub.unsubscribe();
  }, [methods]);

  const handleFormSubmit = async (data: Input): Promise<void> => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(cognitoUser, data.currentPassword, data.newPassword);

      showSuccessMessage('Your password was changed successfully');

      onClose();
    } catch (e) {
      // @ts-ignore
      if (e.code === 'NotAuthorizedException') {
        onError('Incorrect current password');
        return;
      }

      onError(e);
      throw e;
    }
  };

  return (
    <>
      <Modal open={true} onClose={onClose}>
        <ModalDialog>
          <GFormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
              <GDialogHeader>Update password</GDialogHeader>
              <GDialogDescription>Update the password that you use to access Genie Dashboard.</GDialogDescription>
              <Stack spacing={3}>
                <Stack spacing={1.5}>
                  <FormInput
                    name="currentPassword"
                    label="Current password"
                    type="password"
                    width="fullWidth"
                    autoComplete="off"
                  />
                  <FormInput
                    name="newPassword"
                    label="Enter new password"
                    type="password"
                    width="fullWidth"
                    autoComplete="off"
                  />
                  <FormInput
                    name="newPasswordRepeat"
                    label="Confirm new password"
                    type="password"
                    width="fullWidth"
                    autoComplete="off"
                  />
                </Stack>
                <CognitoApiErrorMessage />
                <ResponsiveColumn xs={6}>
                  <SubmitButton width="fullWidth">Confirm</SubmitButton>
                </ResponsiveColumn>
              </Stack>
            </form>
          </GFormProvider>
        </ModalDialog>
      </Modal>
    </>
  );
};

export default UpdatePasswordDialog;

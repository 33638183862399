import { DarkMode, DarkModeOutlined, WbSunnyOutlined } from '@mui/icons-material';
import { Button, ButtonGroup, Stack, Switch, switchClasses, type Theme, useColorScheme } from '@mui/joy';
import type { ReactElement } from 'react';
import type { GButtonProps } from 'components/technical/inputs/GButton/GButton.props';
import GButton from 'components/technical/inputs/GButton/GButton';
import type { Mode } from '@mui/system/cssVars/useCurrentColorScheme';

interface ColorSchemeButtonGroupProps {
  color: GButtonProps['color'];
  buttonVariant?: GButtonProps['variant'];
  size: GButtonProps['size'];
}

const ColorSchemeButtonGroup = ({ color, size = 'md' }: ColorSchemeButtonGroupProps): ReactElement => {
  const { mode, setMode } = useColorScheme();
  const modes: Mode[] = ['light', 'dark'];
  return (
    <ButtonGroup size={size} color={color}>
      {modes.map((range) => (
        <GButton
          size={size}
          key={range}
          variant={mode === range ? 'soft' : 'outlined'}
          color={color}
          onClick={(): void => {
            setMode(range);
          }}
        >
          {range === 'light' ? <WbSunnyOutlined fontSize="small" /> : <DarkModeOutlined fontSize="small" />}
        </GButton>
      ))}
    </ButtonGroup>
  );
};

const ColorSchemeToggler = ({ color, size = 'md', buttonVariant }: ColorSchemeButtonGroupProps): ReactElement => {
  const { mode, setMode } = useColorScheme();
  const icon = mode === 'light' ? <DarkModeOutlined /> : <WbSunnyOutlined />;
  return (
    <Stack
      component={Button}
      direction="row"
      variant={buttonVariant}
      color={color}
      px={0.5}
      size={size}
      width="100%"
      border={0}
      justifyContent="space-between"
      onClick={(): void => {
        setMode(mode === 'light' ? 'dark' : 'light');
      }}
    >
      <Stack direction="row" alignItems="center">
        {icon}
      </Stack>
      <Stack></Stack>
    </Stack>
  );
};

const ColorSchemaSwitch = (): ReactElement => {
  const { mode, setMode } = useColorScheme();
  return (
    <Switch
      onChange={() => setMode(mode === 'light' ? 'dark' : 'light')}
      slotProps={{
        input: { 'aria-label': `${mode} mode` },
        thumb: {
          children: mode === 'light' ? <WbSunnyOutlined /> : <DarkMode />,
        },
      }}
      sx={(theme: Theme) => ({
        display: 'inherit',
        '--Switch-thumbSize': theme.fontSize.lg,
        '--Switch-thumbShadow': theme.vars.shadow.md,
        '--Switch-trackWidth': theme.spacing(5),
        '--Switch-trackHeight': theme.spacing(3),
        '--Switch-trackBackground': theme.palette.background.level2,
        '--Switch-thumbBorder': 'none',
        '--Switch-thumbBackground': theme.palette.background.level2,
        '&:hover': {
          '--Switch-trackBackground': theme.palette.background.level3,
        },
        [theme.getColorSchemeSelector('light')]: {
          '--Switch-thumbBackground': theme.palette.background.body,
        },
        [theme.getColorSchemeSelector('dark')]: {
          '--Switch-trackBackground': 'rgba(255 255 255 / 0.4)',
        },
        [`&.${switchClasses.checked}`]: {
          '--Switch-trackBackground': theme.vars.palette.primary,
          '&:hover': {
            '--Switch-trackBackground': theme.vars.palette.primary,
          },
        },
      })}
    />
  );
};

const ColorSchemeChanger = ({
  color,
  variant,
  size = 'md',
  buttonVariant = 'soft',
}: {
  color: GButtonProps['color'];
  variant: 'button-group' | 'button' | 'switch';
  size?: GButtonProps['size'];
  label?: string;
  buttonVariant?: GButtonProps['variant'];
}): ReactElement => {
  if (variant === 'button-group') {
    return <ColorSchemeButtonGroup color={color} size={size} buttonVariant={buttonVariant} />;
  }

  if (variant === 'switch') {
    return <ColorSchemaSwitch />;
  }

  return <ColorSchemeToggler color={color} size={size} buttonVariant={buttonVariant} />;
};

export default ColorSchemeChanger;

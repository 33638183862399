import HighChartsWrapper from '../../../../technical/charts/HighChartsWrapper/HighChartsWrapper.tsx';
import {
  dateTimeExportFormat,
  DEFAULT_DATE_FORMAT,
  type HighchartSeries,
  percentageFormatter,
  tooltipFormat,
} from '../../../../technical/charts/HighChartsWrapper/Highchart.utils.ts';
import dayjs from 'dayjs';
import { calculateCummulativeReturns } from '../../returnsMath.ts';
import { bignumber } from 'mathjs';
import { formatPercentage } from '../../../../formatter.utils.ts';
import type Highcharts from 'highcharts';
import type { ReactElement } from 'react';
import { memo } from 'react';
import type { PortfolioReturnsSectionProps } from './PortfolioReturnsSectionProps.tsx';

const calculateChartData = (data: PortfolioReturnsSectionProps['analysis']): HighchartSeries[] => {
  return data.map(({ returns, portfolioDefinition }) => {
    const dates = returns.map((row) => dayjs.utc(row.date.toString()).valueOf());
    const values = calculateCummulativeReturns(returns.map((val) => bignumber(val.portfolioWeightedReturn)));
    return {
      name: portfolioDefinition.name,
      data: dates.map((date, i) => ({
        x: date,
        y: values[i].toNumber(),
        textValue: formatPercentage(values[i]),
      })),
      type: 'line' as const,
    };
  });
};

const calculateOptions = (): Highcharts.Options => {
  return {
    ...dateTimeExportFormat('backtest-returns'),
    ...tooltipFormat,
    xAxis: {
      type: 'datetime' as const,
      labels: {
        format: DEFAULT_DATE_FORMAT,
      },
    },
    yAxis: {
      labels: {
        formatter: percentageFormatter,
      },
      title: {
        text: undefined,
      },
    },
  };
};

const PortfolioReturnsChart = ({ analysis }: { analysis: PortfolioReturnsSectionProps['analysis'] }): ReactElement => {
  return (
    <HighChartsWrapper<PortfolioReturnsSectionProps['analysis']>
      loading={false}
      data={analysis}
      calculateChartData={calculateChartData}
      calculateOptions={calculateOptions}
    />
  );
};

export default memo(PortfolioReturnsChart);

import type { ApolloError } from '@apollo/client';
import * as Highcharts from 'highcharts';
import { HighchartsReact } from 'highcharts-react-official';
import isNil from 'lodash/fp/isNil';
import merge from 'lodash/fp/merge';
import mergeAllWith from 'lodash/fp/mergeAllWith';
import type { ReactElement, Ref } from 'react';
import { memo, useState } from 'react';
import { GraphQLErrorMessage } from 'components/technical/form/GraphQLApiErrorMessage';
import Loader from 'components/technical/Loader/Loader';
import Message from 'components/technical/Message';

import DarkTheme, { colorAxis as colorAxisDarkTheme } from './DarkTheme';
import {
  chartSize,
  type HighChartRef,
  type HighchartsDataPoint,
  type HighchartsDataPointDefault,
  type HighchartSeries,
  markerRadius,
} from './Highchart.utils';
import LightTheme, { colorAxis as colorAxisLightTheme } from './LightTheme';
import { useFinalColorScheme } from '../../../../useFinalColorScheme';
import { defaultHeight } from '../Chart.constants';
import GErrorBoundary from '../../GErrorBoundary.tsx';
import { Stack } from '@mui/joy';
import useResizeObserver from '@react-hook/resize-observer/src/index.tsx';

export type HighChartProps<T, S extends HighchartsDataPoint = HighchartsDataPointDefault> = {
  data: T | undefined;
  exporting?: boolean;
  loading: boolean;
  height?: number | Exclude<string, '100%'> | 'fullHeight';
  error?: ApolloError;
  calculateOptions: (data: T) => Highcharts.Options;
  calculateChartData: (data: T) => HighchartSeries<S>[];
  ref?: Ref<HighChartRef>;
};

Highcharts.SVGRenderer.prototype.symbols.cross = function crossSvg(
  x: number,
  y: number,
  w: number,
  h: number
): [string, number, number, string, number, number, string, number, number, string, number, number, string] {
  return ['M', x, y, 'L', x + w, y + h, 'M', x + w, y, 'L', x, y + h, 'z'];
};

const contextMenuOffset = -10;
const spacingTop = 15;
const HighChartsWrapper = <T, S extends HighchartsDataPoint = HighchartsDataPointDefault>({
  data,
  loading,
  height,
  error,
  calculateOptions,
  calculateChartData,
  exporting = true,
  ref,
}: Omit<HighChartProps<T, S>, 'height'> & { height?: number | string }): ReactElement => {
  const colorScheme = useFinalColorScheme();

  if (loading || !isNil(error) || isNil(data)) {
    return (
      <Stack justifyContent="center" alignItems="center" height={chartSize}>
        {loading ? <Loader /> : error ? <GraphQLErrorMessage error={error} /> : <Message>No data</Message>}
      </Stack>
    );
  }

  const calculatedOptions = calculateOptions(data);
  const finalData: HighchartSeries<S>[] = data
    ? calculateChartData(data).map((trace) => {
        return merge(
          {
            type: trace.type ?? calculatedOptions.chart?.type,
            marker: {
              radius: 0.1,
              states: {
                select: {
                  radius: markerRadius,
                  lineColor: 'white',
                  // @ts-ignore
                  fillColor: (trace as unknown).color,
                },
                hover: {
                  radius: markerRadius,
                },
              },
            },
          },
          trace
        );
      })
    : [];

  const hasHeatmapSerie = finalData.some((serie) => serie.type === 'heatmap');

  const layout: Highcharts.Options = {
    credits: {
      enabled: false,
    },
    ...(hasHeatmapSerie && {
      colorAxis: colorScheme === 'dark' ? colorAxisDarkTheme : colorAxisLightTheme,
    }),
    exporting: {
      enabled: exporting,
      buttons: {
        contextButton: {
          x: -1,
          y: contextMenuOffset,
          menuItems: ['downloadPNG', 'downloadJPEG', 'downloadCSV', 'downloadXLS'],
        },
      },
    },
    title: {
      text: undefined,
    },
    tooltip: {
      shared: true,
      style: {
        whiteSpace: 'nowrap',
      },
    },
    chart: {
      height: height ?? defaultHeight,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: spacingTop,
      zooming: {
        type: 'xy',
      },
    },
    // biome-ignore lint/suspicious/noExplicitAny: higcharts doesnt allow series with type:undefined, which is valid for histograms
    series: finalData as any,
    plotOptions: {
      series: {
        // disables turbo mode, we might need to enable if some charts will be slow
        // for that we would need to change data shape from object {x,y,textValue} to tuples [x,y]
        // for more info see https://api.highcharts.com/highcharts/plotOptions.series.turboThreshold
        turboThreshold: 0,
        boostThreshold: 0,
      },
    },
  };

  const options = mergeAllWith(
    (value: unknown, srcValue: unknown, key: string) => {
      if (!['yAxis', 'xAxis'].includes(key)) {
        return undefined;
      }

      if (!Array.isArray(srcValue)) {
        return undefined;
      }

      return srcValue.map((srcVal) => merge(value, srcVal));
    },
    [colorScheme === 'dark' ? DarkTheme : LightTheme, layout, calculatedOptions]
  );

  return (
    <HighchartsReact
      ref={ref}
      // recreate chart when scheme changes to populate highcharts with a completely new theme. without it, some properties are not overridden
      key={`${colorScheme}`}
      highcharts={Highcharts}
      options={options}
    />
  );
};

const HighChartHeightSetter = <T, S extends HighchartsDataPoint = HighchartsDataPointDefault>(
  props: HighChartProps<T, S>
): ReactElement => {
  // keep ref in state to trigger rerender for resize observer
  const [containerRef, setContainerRef] = useState<null | HTMLDivElement>(null);
  const [wrapperHeight, setWrapperHeight] = useState<null | number>(null);
  useResizeObserver(containerRef, (entry) => {
    const heightLayoutOffsetPx = 0.5;
    // floor can contribute max 1px difference, heightLayoutOffsetPx another pixels, add a buffer of 0.1px for safety due to calculation imprecision
    const maxHeightDiffTolerancePx = 1 + heightLayoutOffsetPx + 0.1;
    // don't resize if the hight if off by few px - floor
    if (isNil(wrapperHeight) || Math.abs(wrapperHeight - entry.contentRect.height) > maxHeightDiffTolerancePx) {
      // it's better to use borderBoxSize or contentBoxSize, but these are not supported before safari 15.4, so I'll use older prop

      // take floor of height to avoid layouting on fractional pixels, reduce wrapper height by 0.5px to avoid issues with rounding which may cause high being slighly
      // higher than the container which triggers a scrollbar and infinite resize loop

      // when setting the height, we need to take into account the distance from the top edge of the chart to the context menu, otherwise
      // the chart will resize whenever the context menu is opened
      setWrapperHeight(
        Math.max(0, Math.floor(entry.contentRect.height - heightLayoutOffsetPx - spacingTop - contextMenuOffset))
      );
    }
  });

  // highcharts when passed 'null' height automatically calculates height based on the content
  // the default behaviour is that when you lower the height it redraws the chart with the new height,
  // but when you extend the height, it keeps the current aspect ratio and the chart doesn't fill the whole container
  // as a result we need to calculate the height on our own. We use double wrapper div to use the innermost for showing scrollbars
  if (props.height === 'fullHeight') {
    return (
      <div ref={setContainerRef} style={{ height: '100%', minHeight: 0 }}>
        <div style={{ height: '100%', overflow: 'auto' }}>
          <HighChartsWrapper {...props} height={wrapperHeight ?? undefined} ref={props.ref} />
        </div>
      </div>
    );
  }

  return <HighChartsWrapper {...props} height={props.height} ref={props.ref} />;
};

const HighChartHeightSetterMemoized = memo(HighChartHeightSetter) as typeof HighChartHeightSetter;

const ErrorHandledHighChartsWrapper = <T, S extends HighchartsDataPoint = HighchartsDataPointDefault>(
  props: HighChartProps<T, S>
): ReactElement => {
  return (
    <GErrorBoundary>
      <HighChartHeightSetterMemoized {...props} ref={props.ref} />
    </GErrorBoundary>
  );
};

export default ErrorHandledHighChartsWrapper;

import { Modal, ModalDialog, ModalOverflow } from '@mui/joy';
import { type ReactElement, useState } from 'react';

import * as yup from 'yup';
import { getErrorDetails } from 'components/technical/form/GraphQLAPIService.ts';
import {
  createSecretFields,
  createSecretFieldsSchema,
  getInitialValues,
  prepareSecretInput,
} from './CredentialsService.ts';
import InputCredentialsStep from './InputCredentialsStep';
import InputGenieAccountDetailsStep from './InputGenieAccountDetailsStep.tsx';
import SelectExchangeStep from './SelectExchangeStep';
import { AccountsDocument, useCreateAccountMutation } from '../../../../generated/graphql.tsx';
import { GENIE_VENUE, venues } from '../../../venue/VenueData.tsx';
import { AccountType } from '../AccountService.tsx';
import { useFeedback } from '../../../technical/Feedback/UseFeedback.tsx';
import { createOnboardingSchema, getInitialFields as getInitialOnboardingFields } from './Onboarding.tsx';

interface FormState {
  venue: string;
}

type DialogStep = 'connectAccount' | 'createGenieAccount' | 'createAccountForVenue';

const formDefaultSchema = yup.object().shape({
  name: yup.string().trim().required(),
});

const selectStep = ({
  step,
  setStep,
  onCreated,
  formState,
  setFormState,
  accountsType,
}: {
  step: DialogStep;
  setStep: (num: DialogStep) => void;
  onCreated: () => void;
  formState: Partial<FormState>;
  setFormState: (state: Partial<FormState>) => void;
  accountsType?: AccountType;
}): ReactElement => {
  const [addAccountMutation] = useCreateAccountMutation({
    refetchQueries: [AccountsDocument],
  });

  const defaultCreateAccountFormName = { name: 'name', label: 'Account name' };

  switch (step) {
    case 'connectAccount':
      return (
        <SelectExchangeStep
          onGenieAccountSelected={(): void => {
            setStep('createGenieAccount');
          }}
          onVenueSelected={(venue): void => {
            setFormState({
              venue: venue,
            });
            setStep('createAccountForVenue');
          }}
          accountsType={accountsType}
        />
      );
    case 'createGenieAccount':
      return (
        <InputGenieAccountDetailsStep
          onGoBack={
            accountsType === AccountType.Virtual
              ? undefined
              : (): void => {
                  setStep('connectAccount');
                }
          }
          title="Create a new account"
          defaultValues={{
            name: '',
          }}
          fields={[defaultCreateAccountFormName]}
          formSchema={formDefaultSchema}
          handleSubmit={async (data, onError, setError): Promise<void> => {
            try {
              await addAccountMutation({
                variables: {
                  input: {
                    name: data.name,
                    venue: GENIE_VENUE,
                  },
                },
              });

              onCreated();
            } catch (e) {
              const details = getErrorDetails(e);
              if (details?.code === 'account_duplicate_name') {
                setError('name', {
                  type: 'custom',
                  message: 'Account with the same name already exists',
                });
              } else {
                onError(e);
              }

              throw e;
            }
          }}
        />
      );
    case 'createAccountForVenue': {
      const venue = formState.venue!;
      const secrets = createSecretFields(venues[venue]);

      const venueSchema = formDefaultSchema
        .concat(createSecretFieldsSchema(venues[venue]))
        .concat(createOnboardingSchema(venue));

      const defaultValues = {
        ...getInitialValues([defaultCreateAccountFormName, ...secrets]),
        ...getInitialOnboardingFields(),
      };

      return (
        <InputCredentialsStep<{ name: string; secrets: Record<string, string> }>
          onGoBack={(): void => {
            setStep('connectAccount');
          }}
          title={`Connect to ${venues[venue].name}`}
          defaultValues={defaultValues}
          fields={[defaultCreateAccountFormName, ...secrets]}
          formSchema={venueSchema}
          venue={venue}
          handleSubmit={async (data, onError): Promise<void> => {
            const secrets = prepareSecretInput(venues[venue], data.secrets);

            try {
              await addAccountMutation({
                variables: {
                  input: {
                    venue,
                    name: data.name,
                    secrets,
                  },
                },
              });

              onCreated();
            } catch (e) {
              onError(e);
              throw e;
            }
          }}
        />
      );
    }
    default:
      return <div>New step</div>;
  }
};

export const CreateAccountDialog = (props: {
  onClose: () => void;
  onConnected: () => void;
  accountsType?: AccountType;
}): ReactElement => {
  const [step, setStep] = useState<DialogStep>(
    props.accountsType === AccountType.Virtual ? 'createGenieAccount' : 'connectAccount'
  );
  const [formState, setFormState] = useState<Partial<FormState>>({});
  const { showSuccessMessage } = useFeedback();

  return (
    <Modal open={true} onClose={props.onClose}>
      <ModalOverflow>
        <ModalDialog size={props.accountsType === AccountType.Virtual ? 'md' : 'lg'} maxWidth={700}>
          {selectStep({
            step,
            setStep,
            formState,
            setFormState,
            onCreated: () => {
              showSuccessMessage('Account created');
              props.onConnected();
            },
            accountsType: props.accountsType,
          })}
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
};

import type { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import type { YieldOptimizerInputFields } from '../YieldOptimizer.validation.ts';
import isNil from 'lodash/fp/isNil';
import { bignumber } from 'mathjs';
import { isValidNumber } from '../../../../../number.utils.ts';

export const getAvailableAssetIds = (givenPortfolio: YieldOptimizerInputFields['givenPortfolio']): Set<string> => {
  const assetIds = new Set<string>();

  for (const [assetId, assetValue] of Object.entries(givenPortfolio)) {
    if (assetValue && !isNil(assetValue.amount) && isValidNumber(assetValue.amount)) {
      const amount = bignumber(assetValue.amount);
      if (amount.isPositive() && !amount.isZero()) {
        assetIds.add(assetId);
      }
    }
  }

  return assetIds;
};

export const syncInitialAssetsWithPoolsAndConstraints = (
  getValues: UseFormGetValues<YieldOptimizerInputFields>,
  setValue: UseFormSetValue<YieldOptimizerInputFields>
): void => {
  const useUnderlying = getValues('useUnderlying');
  const availableAssetIds = getAvailableAssetIds(getValues('givenPortfolio'));
  const pools = getValues('universe').filter((pool) => {
    if (useUnderlying) {
      return availableAssetIds.has(pool.underlyingAsset?.id || '');
    }
    return availableAssetIds.has(pool.collateralAsset.id);
  });
  const poolIds = new Set(pools.map((pool) => pool.id));
  const origConstraints = getValues('constraints');
  const constraints = origConstraints.filter((con) => {
    if (isNil(con.pool)) {
      return true;
    }

    return poolIds.has(con.pool.id);
  });

  setValue('constraints', constraints, {
    shouldValidate: true,
  });

  setValue('universe', pools, {
    shouldValidate: true,
  });
};

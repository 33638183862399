import type { ReactElement } from 'react';
import type { IMultiFactor } from '../../../../../generated/graphql.tsx';
import type { LoadMultifactorFormInputFields } from 'components/market/asset/assetScreener/AssetScreenerService.ts';
import FormStaticSingleAutocomplete from 'components/technical/form/FormStaticSingleAutocomplete.tsx';
import FormInput from 'components/technical/form/FormInput.tsx';
import type { FormInputType } from 'components/technical/form/Form.types.ts';
import type { AssetRuleBasedFormOutputFields } from './AssetRuleBasedPortfolioSchema.validation.ts';
import { createMultifactorOptions } from 'components/market/asset/assetScreener/MultifactorService.ts';
import { useFormContext } from 'react-hook-form';

const AssetRuleBasedPortfolioFormFields = ({
  multifactors,
}: {
  multifactors: {
    maxFactors: number;
    multifactor: Pick<IMultiFactor, 'id' | 'name'>;
  }[];
}): ReactElement => {
  const multifactorOptions = createMultifactorOptions(multifactors);
  const { trigger } = useFormContext<FormInputType<AssetRuleBasedFormOutputFields>>();
  return (
    <>
      <FormInput<FormInputType<AssetRuleBasedFormOutputFields>>
        name={'longAssets'}
        type="number"
        width="xl2"
        label="Long assets"
        showLabelAboveInput
      />
      <FormInput<FormInputType<AssetRuleBasedFormOutputFields>>
        name={'shortAssets'}
        type="number"
        width="xl2"
        label="Short assets"
        showLabelAboveInput
      />
      <FormStaticSingleAutocomplete<LoadMultifactorFormInputFields>
        name={'multifactor'}
        width="xl2"
        label="Choose a multifactor"
        options={multifactorOptions}
        onChange={() => {
          trigger('minFactors');
        }}
        showLabelAboveInput
      />

      <FormInput<FormInputType<AssetRuleBasedFormOutputFields>>
        name={'minFactors'}
        type="number"
        width="xl2"
        label="Minimum factors"
        showLabelAboveInput
      />
    </>
  );
};

export default AssetRuleBasedPortfolioFormFields;

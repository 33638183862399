import {
  type DescriptionStepInput,
  type DescriptionStepOutput,
  schema as descriptionSchema,
} from '../description/DescriptionStep.validation.ts';
import {
  type InitialAssetsStepOutput,
  type InitialAssetsStepInput,
  schema as initialPortfolioSchema,
} from './initialAssets/InitialAssetsStep.validation.ts';
import {
  type PoolUniverseStepInput,
  type PoolUniverseStepOutput,
  schema as portfolioUniverseSchema,
} from './poolUniverse/PoolUniverseStep.validation.ts';
import {
  type AllocationConstraintsInput,
  type AllocationConstraintsOutput,
  schema as allocationConstraintSchema,
} from './allocationConstraints/AllocationConstraintsStep.validation.ts';

export type YieldOptimizerOutputFields = DescriptionStepOutput &
  InitialAssetsStepOutput &
  PoolUniverseStepOutput &
  AllocationConstraintsOutput;

export type YieldOptimizerInputFields = DescriptionStepInput &
  InitialAssetsStepInput &
  PoolUniverseStepInput &
  AllocationConstraintsInput;

export const schema = descriptionSchema
  .concat(initialPortfolioSchema)
  .concat(portfolioUniverseSchema)
  .concat(allocationConstraintSchema);

import { lazy, Suspense } from 'react';
import { FormProvider, type FormProviderProps } from 'react-hook-form';
import type { FieldValues } from 'react-hook-form';

import { getEnvConfig } from '../../../config';

const envConfig = getEnvConfig();
const LazyDevTool = lazy(() => import('@hookform/devtools').then((mod) => ({ default: mod.DevTool })));

const GFormProvider = <TFieldValues extends FieldValues, TContext = unknown>(
  props: FormProviderProps<TFieldValues, TContext>
): ReturnType<typeof FormProvider<TFieldValues, TContext>> => {
  return (
    <FormProvider<TFieldValues, TContext> {...props}>
      {envConfig.formDevTools && (
        <Suspense>
          {/* @ts-ignore */}
          <LazyDevTool control={props.control} placement="top-left" />
        </Suspense>
      )}
      {props.children}
    </FormProvider>
  );
};

export default GFormProvider;

import PageShell from '../components/technical/PageShell/PageShell.tsx';
import OrderList from '../components/bookkeeping/orders/OrderList.tsx';
import TransactionDashboard from '../components/bookkeeping/transactions/TransactionDashboard.tsx';
import TradingActivityDashboard from '../components/bookkeeping/tradingActivity/TradingActivityDashboard.tsx';
import { createIndexRoute } from './CreateIndexRoute.tsx';
import InvestmentDashboard from '../components/bookkeeping/investments/InvestmentDashboard.tsx';
import PortfolioReport from '../components/bookkeeping/report/PortfolioReport.tsx';
import ReconciliationPage from 'components/bookkeeping/reconciliation/ReconciliationPage.tsx';

export const operationRoutes = {
  handle: { breadcrumb: 'Operations' },
  path: 'operations',
  children: [
    {
      handle: { title: 'Orders', breadcrumb: 'Orders' },
      path: 'orders',
      element: (
        <PageShell>
          <OrderList />
        </PageShell>
      ),
    },
    {
      handle: { title: 'Transactions', breadcrumb: 'Transactions' },
      path: 'transactions',
      element: (
        <PageShell>
          <TransactionDashboard />
        </PageShell>
      ),
    },
    {
      handle: { breadcrumb: 'Trading activity' },
      path: 'trading-activity',
      element: (
        <PageShell title="Trading activity" hideBottomPadding>
          <TradingActivityDashboard />
        </PageShell>
      ),
    },
    {
      handle: { title: 'Investments', breadcrumb: 'Investments' },
      path: 'investments',
      element: (
        <PageShell>
          <InvestmentDashboard />
        </PageShell>
      ),
    },
    {
      handle: { breadcrumb: 'Report' },
      path: 'report',
      element: (
        <PageShell title="Report" hideBottomPadding>
          <PortfolioReport />
        </PageShell>
      ),
    },
    {
      handle: { title: 'Reconciliation', breadcrumb: 'Reconciliation' },
      path: 'reconciliation',
      element: (
        <PageShell title="Reconciliation">
          <ReconciliationPage />
        </PageShell>
      ),
    },
    createIndexRoute('orders'),
  ],
};

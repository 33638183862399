import jsCookie from 'js-cookie';

import { addConsentUpdateListener } from '../cookieConsentUpdateEmitter';

interface CookieStorage {
  cookies: Record<string, string[]>;
  removeCookie: (name: string) => void;
  getCookie: (name: string) => string | undefined;
  name: string;
}

const cookieStorages: CookieStorage[] = [
  {
    name: 'cookie',
    cookies: {
      analytics: ['_clsk', '_clck'],
    },
    removeCookie: (name: string): void => jsCookie.remove(name),
    getCookie: (name: string): string | undefined => jsCookie.get(name),
  },
  {
    name: 'sessionStorage',
    cookies: {
      performance: ['_cltk', 'portfolioBalanceExposureSunburst'],
    },
    removeCookie: (name: string): void => sessionStorage.removeItem(name),
    getCookie: (name: string): string | undefined => sessionStorage.getItem(name) ?? undefined,
  },
];

const allAvailableCookieCategories = new Set(
  cookieStorages.flatMap((setting): string[] => Object.keys(setting.cookies))
);

const clearNonAllowedCookies = (input: TermlyCookieWhitelist): void => {
  for (const category of allAvailableCookieCategories) {
    if (input.categories.includes(category)) {
      continue;
    }

    for (const storage of cookieStorages) {
      for (const cookie of storage.cookies[category] ?? []) {
        if (storage.getCookie(cookie)) {
          storage.removeCookie(cookie);
          console.info('Removed cookie', cookie, 'from', storage.name, 'due to cookie consent change');
        }
      }
    }
  }
};

addConsentUpdateListener(clearNonAllowedCookies);

import HeaderBar from '../../../../technical/HeaderBar/HeaderBar.tsx';
import { defaultName } from '../description/DescriptionStep.tsx';
import GLink from '../../../../technical/GLink/GLink.tsx';
import GButton from '../../../../technical/inputs/GButton/GButton.tsx';
import GFormProvider from '../../../../technical/form/GFormProvider.tsx';
import { GStepper } from '../../../../technical/wizard/GStepper.tsx';
import type { ReactElement } from 'react';
import { OptimizationType } from '../../optimization.utils.ts';
import { useNavigate } from 'react-router';
import { Chip, Stack } from '@mui/joy';
import { AccountTreeOutlined, ContentPasteOutlined } from '@mui/icons-material';
import type { FieldValues, UseFormReturn } from 'react-hook-form';
import type { useSteps } from '../../../../technical/wizard/UseSteps.ts';
import type { StepConfig } from '../../../../technical/wizard/StepConfig.ts';
import type { FetchResult } from '@apollo/client';

const OptimizerWizard = <INPUT extends FieldValues, VARS, RES>({
  name,
  type,
  useUnderlying = false,
  onErrorAndThrow,
  createRequestInput,
  methods,
  stepApi,
  steps,
  submitOptimization,
  getOptimizationId,
}: {
  name: string;
  type: OptimizationType;
  useUnderlying?: boolean;
  onErrorAndThrow: (e: unknown) => void;
  createRequestInput: (input: INPUT) => VARS;
  methods: UseFormReturn<INPUT>;
  stepApi: ReturnType<typeof useSteps>;
  // biome-ignore lint/suspicious/noExplicitAny:
  steps: StepConfig<any>[];
  submitOptimization: (options: {
    variables: {
      input: VARS;
    };
  }) => Promise<FetchResult<RES | null | undefined>>;
  getOptimizationId: (result: RES) => number | string;
}): ReactElement => {
  const navigate = useNavigate();

  const handleFormSubmit = async (data: INPUT): Promise<void> => {
    const input = createRequestInput(data);
    try {
      const result = await submitOptimization({
        variables: {
          input,
        },
      });

      navigate(
        `/app/copilot/portfolio-optimizer/optimizations/${
          type === OptimizationType.portfolio ? 'portfolio' : type === OptimizationType.yield ? 'yield' : 'asset'
        }/${getOptimizationId(result.data!)}`
      );
    } catch (e) {
      onErrorAndThrow(e);
    }
  };

  return (
    <Stack direction="column" spacing={1}>
      <HeaderBar
        title={
          <>
            {name ? name : defaultName} <Chip startDecorator={<ContentPasteOutlined fontSize="small" />}>Draft</Chip>
            {useUnderlying && (
              <Chip sx={{ marginLeft: 1 }} startDecorator={<AccountTreeOutlined fontSize="small" />} color="primary">
                Using underlying assets
              </Chip>
            )}
          </>
        }
      >
        <GLink to="/app/copilot/portfolio-optimizer/optimizations" marginLeft="auto">
          <GButton width="normal" color="primary">
            See past optimizations
          </GButton>
        </GLink>
      </HeaderBar>
      <GFormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
          <GStepper
            goToStep={stepApi.goToStep}
            getStepState={stepApi.getStepState}
            formState={methods.formState}
            steps={steps}
            activeStep={stepApi.activeStep}
          />
        </form>
      </GFormProvider>
    </Stack>
  );
};

export default OptimizerWizard;

import { Stack } from '@mui/joy';
import isEqual from 'lodash/fp/isEqual';
import sortBy from 'lodash/fp/sortBy';
import { useFormContext } from 'react-hook-form';
import { HEIGHT_PX } from 'components/market/asset/AssetSelectOption/AssetSelectOption.tsx';
import FormSelect from 'components/technical/form/FormSelect.tsx';
import FormStaticSingleAutocomplete from 'components/technical/form/FormStaticSingleAutocomplete.tsx';
import type { StaticAutocompleteOption } from 'components/technical/inputs/Autocomplete/StaticSingleAutocomplete.props.ts';
import RemoveButton from 'components/technical/RemoveButton.tsx';

import { allocationConstraintTypeValues } from './AllocationConstraintsStep.validation.ts';
import { ConstraintValueInputs } from '../../ConstraintValueInputs.tsx';
import type { YieldOptimizerInputFields } from '../YieldOptimizer.validation.ts';

type OptionValue = YieldOptimizerInputFields['universe'][number];
const createOptions = (
  universe: YieldOptimizerInputFields['universe']
): {
  isValueEqual: (a: OptionValue | undefined | null, b: OptionValue | undefined | null) => boolean;
  groupBy: (opt: StaticAutocompleteOption<OptionValue>) => string;
  options: StaticAutocompleteOption<OptionValue>[];
} => {
  const groupBy = (opt: StaticAutocompleteOption<OptionValue>): string => {
    const { value } = opt;
    return value.collateralAsset.symbol;
  };

  const isValueEqual = (a: OptionValue | undefined | null, b: OptionValue | undefined | null): boolean => {
    const unifiedNullA = a ?? null;
    const unifiedNullB = b ?? null;
    return isEqual(unifiedNullA?.id, unifiedNullB?.id);
  };

  const options: StaticAutocompleteOption<OptionValue>[] = universe.map(
    (pool): StaticAutocompleteOption<OptionValue> => ({
      value: pool,
      key: pool.id,
      label: pool.poolName,
      inputText: pool.poolName,
      searchText: pool.poolName,
    })
  );

  return {
    groupBy,
    options: sortBy((option: StaticAutocompleteOption<OptionValue>): unknown => {
      return groupBy(option);
    }, options),
    isValueEqual,
  };
};

interface AllocationRowProps {
  index: number;
  onRemove: () => void;
  constraints: YieldOptimizerInputFields['constraints'];
  activePools: YieldOptimizerInputFields['universe'];
}

const AllocationRow = ({ index, onRemove, constraints, activePools }: AllocationRowProps) => {
  const { formState } = useFormContext<YieldOptimizerInputFields>();
  // selector for pools should allow only selecting pools that are not selected in other constraints,
  // we also need to allow selecting the current option, so that value is rendered correctly

  // constraints are updated after rendering so immediately after adding a new allocation contstraint
  // current constraint may be undefined
  const currentConstraint = constraints[index];
  const otherSelectedPoolIds = new Set(
    constraints
      .map((con) => con.pool?.id)
      .filter((pool) => !!pool)
      .filter((pool) => pool !== currentConstraint?.pool?.id)
  );
  const { options, groupBy, isValueEqual } = createOptions(
    activePools.filter((pool) => !otherSelectedPoolIds.has(pool.id))
  );

  return (
    <Stack direction="row" flexWrap="wrap" spacing={1} alignItems="center">
      <FormStaticSingleAutocomplete<YieldOptimizerInputFields>
        name={`constraints.${index}.pool`}
        label={'Pool'}
        width="xl4"
        options={options}
        limitTags={1}
        groupBy={groupBy}
        isValueEqual={isValueEqual}
        menuWidth="xl4"
        optionHeight={HEIGHT_PX}
        showClearable
      />
      <span>has a</span>
      <FormSelect<YieldOptimizerInputFields>
        name={`constraints.${index}.constraintType` as const}
        width="normal"
        options={allocationConstraintTypeValues}
        label="Condition"
      />
      <ConstraintValueInputs width="normal" pathPrefix={`constraints.${index}`} />
      <RemoveButton
        disabled={formState.isSubmitting}
        onClick={(): void => {
          onRemove();
        }}
      />
    </Stack>
  );
};

export default AllocationRow;

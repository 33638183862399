import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import { PerformanceExposureWidgetView } from './PerformanceExposureWidgetView.tsx';
import { type PortfolioExposureState, PortfolioExposureWidgetSettings } from './PortfolioExposureWidgetSettings.tsx';
import type { CustomWidgetDescription } from 'components/management/customDashboard/CustomWidgetDescription.types.ts';

export const exposureWidget: CustomWidgetDescription = {
  name: 'Total exposure',
  type: 'exposureChart',
  description: 'Exposure to different types of positions',
  icon: () => <StackedLineChartIcon />,
  viewComponent: PerformanceExposureWidgetView,
  settingsComponent: PortfolioExposureWidgetSettings,
  initialState: { title: 'Total exposure', dataType: 'dollars' } satisfies Partial<PortfolioExposureState>,
  initialH: 4,
  initialW: 3,
};

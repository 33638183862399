import type { ReactNode } from 'react';

import Help from 'components/technical/Help/Help';
import type { MetricData, MetricParams } from './MetricsData.types';
import { metricsData as nonPortfolioMetricsData } from './NonPortfolioMetricsData';
import { metricsData as portfolioMetricsData } from './PortfolioMetricsData';
import { metricsData as riskMeasureTargetsMetricData } from './PortfolioRiskMeasures.ts';
import type { IPortfolioMetricsQuery } from '../../generated/graphql.tsx';
import { formatNumber, formatterForName } from '../formatter.utils';

export const MET_MAX_SUPPLY = 'met:max_supply';

const metricsData: Record<string, MetricData> = {
  ...portfolioMetricsData,
  ...nonPortfolioMetricsData,
  ...riskMeasureTargetsMetricData,
};

export const getTooltip = (metric: string, params?: MetricParams): ReactNode => {
  const tooltip = metricsData[metric]?.tooltip;
  if (!(tooltip instanceof Function)) {
    return tooltip;
  }
  return tooltip(params);
};

export const getName = (metric: string, params?: MetricParams, withoutDay?: boolean): string => {
  const name = metricsData[metric]?.name;
  if (name instanceof Function) {
    return name(params, withoutDay);
  }
  return name ?? metric;
};

export const getNameWithTooltip = (metric: string, params?: MetricParams): ReactNode => {
  const metricInfo = metricsData[metric];

  if (!metricInfo) {
    console.error('Missing metric: ', metric);
    return <>{metric}</>;
  }
  const name = getName(metric, params);

  let { tooltip } = metricInfo;
  if (!tooltip) {
    return name;
  }

  if (tooltip instanceof Function) {
    tooltip = tooltip(params) ?? '';
  }

  return (
    <>
      {name}&nbsp;<Help>{tooltip}</Help>
    </>
  );
};

export const getFormat = (metric: string): 'number' | 'cash' | 'percentage' | 'percentage_4' => {
  if (!metricsData[metric]) {
    console.error('Missing metric: ', metric);
    return 'number';
  }

  return metricsData[metric].format;
};

export const formatMetricValue = (metric: string, value: string | number): string => {
  const metricData = metricsData[metric];
  if (!metricData) {
    console.error('Unknown metric: ', metric);
    return formatNumber(value);
  }

  const formatter = formatterForName(metricData.format);
  return formatter(value);
};

export const formatMetric = (metric: string, metrics: Record<string, string | number>): string => {
  return formatMetricValue(metric, metrics[metric]);
};

export function metricParamsFromParameters(
  parameters: IPortfolioMetricsQuery['portfolio']['metrics'][number]['series'][number]['parameters']
): MetricParams {
  const result: MetricParams = {};
  for (const parameter of parameters) {
    if (parameter.name === 'Metric type') {
      result.metricType = parameter.strValue as 'realized' | 'expected' | undefined;
    }
    if (parameter.name === 'Window type') {
      result.windowType = parameter.strValue as 'rolling' | 'expanding' | undefined;
    }
    if (parameter.name === 'Annualized') {
      result.annualized = parameter.strValue === 'annualized';
    }

    if (parameter.name === 'Benchmark') {
      result.assetSymbol = parameter.asset?.symbol;
    }

    if (parameter.name === 'Distribution') {
      result.distribution = parameter.strValue as 'historic' | 'gaussian';
    }
  }
  return result;
}

import type { ReactElement, ReactNode } from 'react';
import { createContext } from 'react';

export type ImperativeModal = ({ onClose }: { onClose: () => void }) => ReactElement;

export interface ImperativeModalContext {
  showModal(element: ImperativeModal): void;
  modal: ReactNode | null;
}

export const Context = createContext<ImperativeModalContext>({
  showModal: () => null,
  modal: null,
});

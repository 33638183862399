import type { ReactElement } from 'react';

import type { PortfolioBuilderInputFields } from './PortfolioBuilder.utils.ts';
import GMultiAutocomplete from 'components/technical/inputs/Autocomplete/GMultiAutocomplete.tsx';
import { createAssetAutocompleteProps, useAssetPaginatedOptions } from 'components/market/asset/AssetService.tsx';
import type { BuilderPortfolioPosition } from './PortfolioBuilder.tsx';
import { IAssetType } from 'generated/graphql.tsx';
import { useFormContext, useWatch } from 'react-hook-form';
import { Box } from '@mui/joy';

const PortfolioBuilderAssetList = (): ReactElement => {
  // only supported public and private assets
  const { getOptions } = useAssetPaginatedOptions({
    assetTypes: [IAssetType.Public, IAssetType.Private],
  });

  const { setValue } = useFormContext<PortfolioBuilderInputFields>();

  const positions = useWatch<PortfolioBuilderInputFields, 'positions'>({
    name: 'positions',
  });

  // Helper function to check if an asset exists in positions
  const isAssetInPositions = (assetId: string): boolean => {
    return !!positions[assetId];
  };

  // Helper function to initialize a new position
  const initializePosition = (asset: BuilderPortfolioPosition['asset']): BuilderPortfolioPosition => ({
    asset,
    value: null,
    weight: null,
  });

  const handleAssetChange = (newVal: BuilderPortfolioPosition['asset'][]): void => {
    // Create a map for quick lookup of new assets by ID
    const newAssetMap = new Map(newVal.map((asset) => [asset.id, asset]));

    // Retain only existing positions that are still selected
    const existingAssets: BuilderPortfolioPosition[] = Object.values(positions).filter((pos) =>
      newAssetMap.has(pos.asset.id)
    );

    // Identify assets that have been newly added
    const addedAssets = newVal.filter((asset) => !isAssetInPositions(asset.id));

    // Initialize positions for added assets
    const initializedAddedAssets = addedAssets.map(initializePosition);

    // Combine existing and newly added positions
    const updatedPositions: BuilderPortfolioPosition[] = [...existingAssets, ...initializedAddedAssets];

    const newPositions = Object.fromEntries(updatedPositions.map((pos) => [pos.asset.id, pos]));

    // Update the form's positions field
    setValue('positions', newPositions);
  };

  return (
    <Box sx={{ width: '50%' }}>
      <GMultiAutocomplete<BuilderPortfolioPosition['asset']>
        placeholder="Choose assets"
        label="Asset"
        showLabelAboveInput
        value={Object.values(positions).map((pos) => pos.asset)}
        getOptions={getOptions}
        {...createAssetAutocompleteProps()}
        width="fullWidth"
        onChange={handleAssetChange}
      />
    </Box>
  );
};

export default PortfolioBuilderAssetList;

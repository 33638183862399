import type { AutocompleteRenderOptionState } from '@mui/joy/Autocomplete';
import type { HTMLAttributes, ReactNode } from 'react';

import type { GOption } from '../GAutocomplete.tsx';

export const virtualizedRenderOptions = <T,>(
  elementProps: HTMLAttributes<HTMLLIElement>,
  option: GOption<T>,
  state: AutocompleteRenderOptionState
): ReactNode => {
  // incorrect cast, but the values are provided as they are to VirtualListbox component
  return { elementProps, option, state } as unknown as ReactNode;
};

import type { ReactElement } from 'react';
import { Pencil } from '../../icons';
import { ListItemDecorator } from '@mui/joy';
import { DialogMenuItem, type DialogItemProps } from '../../DialogDropdown/DialogMenuItem.tsx';

const EditDialogButton = (props: Omit<DialogItemProps, 'children'>): ReactElement => (
  <DialogMenuItem {...props}>
    <>
      <ListItemDecorator>
        <Pencil fontSize="md" />
      </ListItemDecorator>
      Edit
    </>
  </DialogMenuItem>
);

export default EditDialogButton;

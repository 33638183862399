import type { ApolloError } from '@apollo/client';
import { type ReactElement, useMemo } from 'react';
import type { NonUndefined } from 'react-hook-form';

import { GraphQLErrorMessage } from './form/GraphQLApiErrorMessage';
import Loader from './Loader/Loader';
import type { LoaderProps, Variant } from './Loader/Loader.props';
import Message from './Message';

type DuplicatedTypes = 'data';

export type TFallback = () => ReactElement;
type StandardOutputProps<OUTPUT> =
  | { loaded: false; data: undefined; Fallback: TFallback }
  | { loaded: true; data: NonUndefined<OUTPUT>; Fallback: undefined };

export type ErrorHandlingOutput<QUERY_OUTPUT> = QUERY_OUTPUT extends {
  data: infer OUTPUT;
}
  ? Omit<QUERY_OUTPUT, DuplicatedTypes> & StandardOutputProps<OUTPUT>
  : never;

export type QueryOutputBase = {
  loading: boolean;
  error?: ApolloError;
  data: unknown;
};

export type ErrorHandlingOptions = {
  loaderSizeVariant?: Variant;
  loaderPositionVariant?: LoaderProps['positionVariant'];
  loaderFullHeight?: boolean;
  disableNoDataFallback?: boolean;
};

export const useDefaultErrorHandling = <QUERY_OUTPUT extends QueryOutputBase>(
  input: QUERY_OUTPUT,
  options: ErrorHandlingOptions = {}
): ErrorHandlingOutput<QUERY_OUTPUT> =>
  useMemo((): ErrorHandlingOutput<QUERY_OUTPUT> => {
    // sanitize input to not return extra fields
    const { loading, error, ...rest } = input;

    if (input.loading) {
      // @ts-ignore
      return {
        ...rest,
        loaded: false,
        data: undefined,
        loading,
        Fallback: (): ReactElement => {
          return (
            <Loader
              variant={options.loaderSizeVariant}
              positionVariant={options.loaderPositionVariant}
              fullHeight={options.loaderFullHeight}
            />
          );
        },
      };
    }

    if (input.error) {
      const error = input.error;
      // @ts-ignore
      return {
        ...rest,
        loaded: false,
        data: undefined,
        error,
        Fallback: () => <GraphQLErrorMessage error={error} />,
      };
    }

    if (!input.data && !options.disableNoDataFallback) {
      // @ts-ignore
      return {
        ...rest,
        loaded: false,
        data: undefined,
        Fallback: () => <Message>No data</Message>,
      };
    }

    // @ts-ignore
    return {
      ...rest,
      loaded: true,
      data: input.data,
      Fallback: undefined,
    };
  }, [
    input,
    options.disableNoDataFallback,
    options.loaderFullHeight,
    options.loaderPositionVariant,
    options.loaderSizeVariant,
  ]);

import { Stack } from '@mui/joy';
import GButton from 'components/technical/inputs/GButton/GButton';
import type { ReactElement } from 'react';
import { useParams } from 'react-router';
import HeaderBar from '../../../../../technical/HeaderBar/HeaderBar.tsx';
import { ImportPositionsList } from './ImportPositionsList.tsx';
import { downloadLocalCsvFile } from '../../../../../file.utils.tsx';
import { useFeedback } from '../../../../../technical/Feedback/UseFeedback.tsx';
import { FileUploadButton } from '../../../../../technical/inputs/FileUploadButton/FileUploadButton.tsx';
import { ImportPositionsHistoryDocument, useImportPositionsMutation } from '../../../../../../generated/graphql.tsx';

const sampleFile = `
date,BTC,ETH
2024-01-01,1,2
2024-01-03,4,5
2024-01-04,7,8`.trim();

const ImportPositions = (): ReactElement => {
  const { portfolioDefinitionId } = useParams();
  const { showSuccessMessage, showGraphqlError } = useFeedback();
  const [importPortfolioFromFile, { loading: importRunning }] = useImportPositionsMutation({
    refetchQueries: [ImportPositionsHistoryDocument],
  });

  if (!portfolioDefinitionId) {
    throw new Error('Missing portfolio definition');
  }

  return (
    <>
      <HeaderBar title={'Import positions history'}>
        <Stack direction={'row'} columnGap={1}>
          <GButton
            variant={'soft'}
            onClick={() => {
              downloadLocalCsvFile(sampleFile, 'sampleImportPositions.csv');
            }}
          >
            Get sample file
          </GButton>
          <FileUploadButton
            fileName={'uploadPositions.csv'}
            contentType={'text/csv'}
            loading={importRunning}
            onChange={async (value) => {
              if (!value.remoteFileId) {
                return;
              }

              try {
                await importPortfolioFromFile({
                  variables: {
                    remoteFileId: value.remoteFileId,
                    rebalancedPortfolioId: portfolioDefinitionId,
                  },
                });
                showSuccessMessage('File uploaded. Starting processing');
              } catch (e) {
                console.error('Failed to run import process', e);
                showGraphqlError(e);
              }
            }}
          >
            Upload a file
          </FileUploadButton>
        </Stack>
      </HeaderBar>
      <ImportPositionsList portfolioDefinitionId={portfolioDefinitionId} />
    </>
  );
};

export default ImportPositions;

import { calculateTooltipText, getTileValues } from './PortfolioTilesService.ts';
import TitleValueTile, { TitleValueContent } from '../../../technical/Tile/TitleValueTile.tsx';
import TitleWithChip, { TileChangeChip } from '../../../technical/Tile/TitleWithChip.tsx';
import { formatCash } from '../../../formatter.utils.ts';
import { Tooltip } from '@mui/joy';
import { tooltipEnterDelay } from '../../../../theme/consts.ts';
import { TileProgressFooter } from './TileProgressFooter.tsx';
import type { IPortfolioTwrEquityTileInputQuery } from '../../../../generated/graphql.tsx';
import type { Dayjs } from 'dayjs';

export const EquityTileContent = ({
  loading,
  since,
  twr,
  hideTitle,
}: {
  loading: boolean;
  since: Dayjs;
  twr?: IPortfolioTwrEquityTileInputQuery['portfolio']['portfolioTimeWeightedReturns']['values'];
  hideTitle?: boolean;
}) => {
  const equityRangeTooltip = calculateTooltipText(since);
  const equityRows = (twr ?? []).map((row) => ({
    value: row.return.balance,
  }));
  const equityTile = getTileValues(equityRows);

  return (
    <TitleValueContent
      loading={loading}
      title={!hideTitle && <TitleWithChip title="Equity value" change={equityTile?.change} />}
      value={formatCash(equityTile?.latestValue)}
      caption={hideTitle && <TileChangeChip change={equityTile?.change} />}
      footer={
        <Tooltip title={equityRangeTooltip} enterDelay={tooltipEnterDelay.Slow}>
          <span>
            <TileProgressFooter
              minValue={formatCash(equityTile?.minValue)}
              maxValue={formatCash(equityTile?.maxValue)}
              progressValue={equityTile?.progressValue}
            />
          </span>
        </Tooltip>
      }
    />
  );
};

const EquityTitle = ({
  loading,
  since,
  twr,
}: {
  loading: boolean;
  since: Dayjs;
  twr?: IPortfolioTwrEquityTileInputQuery['portfolio']['portfolioTimeWeightedReturns']['values'];
}) => {
  return (
    <TitleValueTile>
      <EquityTileContent loading={loading} since={since} twr={twr} />
    </TitleValueTile>
  );
};

export default EquityTitle;

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Stack, Tooltip, Typography, useTheme } from '@mui/joy';
import { flexRender } from '@tanstack/react-table';
import type { Table } from '@tanstack/table-core';
import { type MutableRefObject, type ReactElement, useState } from 'react';
import { useMount } from 'react-use';

import GTableCell from './GTableCell/GTableCell';
import findScrollableParent from './findScrollableParent.ts';

export const GTableHead = <ROW,>({
  table,
  hideHeaders = false,
  hideFirstLastPadding = false,
  sticky = false,
  backgroundColor,
  tableWrapperRef,
  padding,
}: {
  table: Table<ROW>;
  hideHeaders?: boolean;
  hideFirstLastPadding?: boolean;
  sticky?: boolean;
  backgroundColor?: string;
  tableWrapperRef?: MutableRefObject<HTMLDivElement | null>;
  padding?: number;
}): ReactElement | null => {
  const [parentBody, setParentBody] = useState<boolean>(false);
  const calculateScrollableParent = (): void => {
    if (sticky) {
      const parent = findScrollableParent(tableWrapperRef?.current ?? null);
      setParentBody(parent === document.body);
    }
  };

  useMount(calculateScrollableParent);
  const theme = useTheme();

  if (hideHeaders) {
    return null;
  }

  return (
    <Box
      component={'thead'}
      sx={{
        backgroundColor,
        ...(sticky && {
          position: 'sticky',
          zIndex: 'var(--gtable-header-z-index)',
          backgroundColor: theme.palette.background.level2,
          top: parentBody ? 'var(--header-height)' : 0,
        }),
      }}
    >
      {table.getHeaderGroups().map((headerGroup) => (
        <tr
          key={headerGroup.id}
          style={{
            background: sticky ? theme.palette.background.level2 : backgroundColor,
          }}
        >
          {headerGroup.headers.map((header) => {
            const headerElement = flexRender(header.column.columnDef.header, header.getContext());

            return (
              <th
                key={header.id}
                colSpan={header.colSpan}
                style={{
                  // paddingTop: '1rem',
                  ...header.column.columnDef.meta?.headerStyles,
                }}
              >
                <GTableCell
                  align={header.column.columnDef.meta?.align}
                  first={header.index === 0}
                  last={header.index === headerGroup.headers.length - 1}
                  body={false}
                  padding={padding}
                  hideFirstLastPadding={hideFirstLastPadding}
                >
                  <Typography level="title-sm" component="div">
                    <Stack
                      direction="row"
                      flexWrap="wrap"
                      onClick={header.column.getToggleSortingHandler()}
                      sx={
                        header.column.getCanSort()
                          ? {
                              cursor: 'pointer',
                              userSelect: 'none',
                            }
                          : {}
                      }
                      alignItems="center"
                    >
                      {header.column.columnDef.meta?.headerTooltip ? (
                        <Tooltip title={header.column.columnDef.meta.headerTooltip}>
                          <Box>{headerElement}</Box>
                        </Tooltip>
                      ) : (
                        headerElement
                      )}

                      {{
                        asc: <KeyboardArrowUpIcon />,
                        desc: <KeyboardArrowDownIcon />,
                      }[header.column.getIsSorted() as string] ?? null}
                    </Stack>
                  </Typography>
                </GTableCell>
              </th>
            );
          })}
        </tr>
      ))}
    </Box>
  );
};

import type { CustomWidgetDescription } from 'components/management/customDashboard/CustomWidgetDescription.types.ts';
import { GridView } from '@mui/icons-material';
import { AgGridTitleSettings } from '../../../management/customDashboard/AgGridWithTitleSettings.tsx';
import DailyPnlGrid from './DailyPnlGrid.tsx';
import { useWidgetState } from '../../../management/customDashboard/CustomWidgetContext.tsx';
import type { PortfolioAttributionState } from '../attributionOverview/PortfolioAttributionWidgetSettings.tsx';
import { calculateDefaultPresetRange } from '../../../management/TilesDateRangeSelector.tsx';

const createPresetKey = (id: string) => `customWidget/dailyPnl/${id}`;

export const dailyPnlWidget: CustomWidgetDescription = {
  name: 'Daily P&L',
  type: 'dailyPnl',
  description: 'Analyze daily returns by sub-account',
  icon: () => <GridView />,
  viewComponent: ({ id }) => {
    const initialState = useWidgetState<PortfolioAttributionState>(id);
    const range = calculateDefaultPresetRange(initialState.predefinedDateRange);
    return <DailyPnlGrid presetSettingsKey={createPresetKey(id)} dateRange={range.value()} fullHeight />;
  },
  settingsComponent: (props) => <AgGridTitleSettings {...props} presetKey={createPresetKey(props.id)} />,
  initialState: {
    title: 'Daily P&L',
  },
  initialH: 4,
  initialW: 3,
};

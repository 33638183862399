import { type ReactElement, Suspense } from 'react';
import { Input, Stack, Tab, TabList, TabPanel, Tabs } from '@mui/joy';
import PrivateAssetManagementList from './privateAsset/PrivateAssetManagementList.tsx';
import Loader from 'components/technical/Loader/Loader.tsx';
import PublicAssetRequestManagementList from './publicAsset/PublicAssetRequestManagementList.tsx';
import BlacklistedAssets from './blacklistedAsset/BlacklistedAsset.tsx';
import { useState } from 'react';

const cardHeight = '10rem';
const gridWidth = { sm: 12, md: 6, lg: 4, xl: 4 };

const AssetManagementDashboard = (): ReactElement => {
  const [filterInput, setFilterInput] = useState<string>('');
  const [activeTab, setActiveTab] = useState<string>('private-assets');

  const handleTabChange = (newValue: string | number | null) => {
    setActiveTab(newValue as string);
    setFilterInput('');
  };

  return (
    <Tabs aria-label="Tabs" value={activeTab} onChange={(_, newValue) => handleTabChange(newValue)}>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <TabList>
          <Tab value="private-assets">Private asset</Tab>
          <Tab value="public-asset">Asset support requests</Tab>
          <Tab value="blacklisted-assets">Blacklisted assets</Tab>
        </TabList>
        {/* No Search bar for Blacklisted assets */}
        {activeTab !== 'blacklisted-assets' && (
          <Input placeholder="Search" value={filterInput} onChange={(e) => setFilterInput(e.target.value)} />
        )}
      </Stack>
      <TabPanel value="private-assets">
        <Suspense fallback={<Loader />}>
          <PrivateAssetManagementList cardHeight={cardHeight} gridWidth={gridWidth} filterInput={filterInput} />
        </Suspense>
      </TabPanel>
      <TabPanel value="public-asset">
        <Suspense fallback={<Loader />}>
          <PublicAssetRequestManagementList cardHeight={cardHeight} gridWidth={gridWidth} filterInput={filterInput} />
        </Suspense>
      </TabPanel>
      <TabPanel value="blacklisted-assets">
        <Suspense fallback={<Loader />}>
          <BlacklistedAssets />
        </Suspense>
      </TabPanel>
    </Tabs>
  );
};

export default AssetManagementDashboard;

import { Stack } from '@mui/joy';
import type { ColDef } from 'ag-grid-community';
import { useState, type ReactElement, useEffect } from 'react';
import GButton from 'components/technical/inputs/GButton/GButton.tsx';
import { defaultRowSpacing } from '../../../../../StackSpacing.ts';
import Universe from '../../universe/Universe.tsx';
import type { IYieldOptimizerWizardInputQuery } from 'generated/graphql.tsx';
import {
  getAvailableAssetIds,
  syncInitialAssetsWithPoolsAndConstraints,
} from '../initialAssets/InitialAssetStep.utils.ts';
import {
  createNameColumn,
  poolWithLink,
  protocolWithSymbolColumn,
} from '../../../../../technical/grids/SharedReportColumns.tsx';
import { useWatch } from 'react-hook-form';
import type { YieldOptimizerInputFields } from '../YieldOptimizer.validation.ts';
import { createAssetNameGetter } from 'components/technical/grids/agGrid.utils.tsx';
import { createAssetCellRenderer } from 'components/technical/grids/agGrid.utils.tsx';

export type UniversePool = IYieldOptimizerWizardInputQuery['yieldOptimization']['listPools'][number];

const columns: ColDef<UniversePool>[] = [
  poolWithLink<UniversePool>(),
  protocolWithSymbolColumn<UniversePool>(),
  {
    colId: 'chain',
    headerName: 'Chain',
    field: 'chain',
    initialWidth: 200,
  },
  createNameColumn('collateralAsset', {
    initialHide: false,
    columnName: 'Collateral',
    initialSort: 'desc',
    hide: true,
    initialRowGroup: true,
    initialRowGroupIndex: 1,
  }),
  {
    colId: 'name',
    type: 'textColumn',
    headerName: 'Underlying',
    initialRowGroup: true,
    initialRowGroupIndex: 0,
    hide: true,
    valueGetter: createAssetNameGetter('underlyingAsset'),
    cellRenderer: createAssetCellRenderer('underlyingAsset'),
  },
  {
    colId: 'apy',
    headerName: 'APY',
    initialSort: 'desc',
    field: 'metrics.apy',
    type: ['numericColumn', 'percentageColumn'],
    initialWidth: 200,
  },
  {
    colId: 'tvl',
    headerName: 'TVL USD',
    field: 'metrics.tvlUsd',
    type: ['numericColumn', 'cashColumn'],
    initialWidth: 200,
  },
];

const PoolUniverseStep = ({
  pools,
  goToNextStep,
}: {
  pools: IYieldOptimizerWizardInputQuery['yieldOptimization']['listPools'];
  goToNextStep: () => void;
}): ReactElement => {
  const [shownPools, setShownPools] = useState(pools);
  const assets = useWatch<YieldOptimizerInputFields, 'givenPortfolio'>({
    name: 'givenPortfolio',
  });

  const useUnderlying = useWatch<YieldOptimizerInputFields, 'useUnderlying'>({
    name: 'useUnderlying',
  });

  useEffect(() => {
    let filteredPools = pools;
    if (useUnderlying) {
      filteredPools = filteredPools.filter((pool) => avAssetIds.has(pool.underlyingAsset?.id || ''));
    } else {
      filteredPools = filteredPools.filter((pool) => avAssetIds.has(pool.collateralAsset.id));
    }
    setShownPools(filteredPools);
  }, [useUnderlying, pools]);

  const avAssetIds = getAvailableAssetIds(assets);

  return (
    <Stack spacing={defaultRowSpacing} height="700px">
      <Universe<IYieldOptimizerWizardInputQuery['yieldOptimization']['listPools'][number], YieldOptimizerInputFields>
        columnDefs={columns}
        groupDefaultExpanded={2}
        rowData={shownPools}
        idGetter={(row) => row?.id ?? ''}
        syncSelection={({ getValues, setValue }) => {
          syncInitialAssetsWithPoolsAndConstraints(getValues, setValue);
        }}
      />
      <GButton onClick={goToNextStep} sx={{ marginLeft: 'auto' }}>
        Next
      </GButton>
    </Stack>
  );
};

export default PoolUniverseStep;

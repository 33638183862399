import { getEnvConfig } from 'config';
import type { ReactElement } from 'react';
import ErrorMessage from './technical/ErrorMessage';

export const downloadLocalCsvFile = (content: string, filename: string): void => {
  downloadLocalFile({
    contentType: 'text/csv;charset=utf-8',
    content,
    filename,
  });
};

export const downloadLocalFile = ({
  contentType,
  content,
  filename,
}: { contentType: string; content: string; filename: string }): void => {
  const blob = new Blob([content], { type: `${contentType}` });
  const url = URL.createObjectURL(blob);
  downloadFile(url, filename);

  URL.revokeObjectURL(url);
};

export const downloadFile = (downloadLink: string, filename: string | undefined = undefined): void => {
  const a = document.createElement('a');
  a.href = downloadLink;
  if (filename) {
    a.download = filename;
  }
  a.click();
};

export const downloadTransactionOrderCsv = (token: string): void => {
  const config = getEnvConfig();
  const url = new URL(config.apiHostnameAndPort || window.location.origin);
  url.pathname = '/api/download/csv';
  url.search = new URLSearchParams({ token }).toString();

  downloadFile(url.toString());
};

export const uploadFile = async ({
  blob,
  uploadUrl,
  signal,
}: {
  blob: Blob;
  uploadUrl: string;
  signal?: AbortSignal;
}): Promise<void> => {
  const response = await fetch(uploadUrl, {
    signal,
    method: 'PUT',
    body: blob,
    headers: {
      'Content-Type': blob.type,
    },
  });

  if (!response.ok) {
    throw new Error('File upload failed');
  }

  console.debug('Uploaded file to', uploadUrl);
};

export const generateErrorSummary = (data: {
  lineErrors: { line: number; error: string }[];
  generalErrors: string[];
}): string[] => {
  const errorText = [];
  if (data.generalErrors.length > 0) {
    errorText.push('General file errors: ' + data.generalErrors.join(', '));
  }

  for (const lineError of data.lineErrors) {
    errorText.push(`Line: ${lineError.line}. Error: ${lineError.error}`);
  }

  return errorText;
};

export const FileErrorSummary = (props: {
  fileValidationErrors: string[];
}): ReactElement | null => {
  if (props.fileValidationErrors.length === 0) {
    return null;
  }

  return (
    <ErrorMessage>
      {props.fileValidationErrors.map((row, i) => (
        <div key={i}>{row}</div>
      ))}
    </ErrorMessage>
  );
};

import { Box } from '@mui/joy';

import HeaderBar from 'components/technical/HeaderBar/HeaderBar';
import { useRef, useState } from 'react';
import GButton from '../../technical/inputs/GButton/GButton.tsx';
import { Pencil } from '../../technical/icons';
import AddCustomWidgetButton from './AddCustomWidgetButton.tsx';
import { CustomWidgetContextProvider } from './CustomWidgetContext.tsx';
import WidgetContainer, { type Widget } from 'components/technical/widget/WidgetContainer.tsx';
import {
  type IGetWidgetsQuery,
  useDeleteWidgetMutation,
  useGetWidgetsSuspenseQuery,
  useUpdateWidgetsLayoutMutation,
} from 'generated/graphql.tsx';
import { useFeedback } from '../../technical/Feedback/UseFeedback.tsx';
import { CustomWidgetCard } from './CustomWidgetCard.tsx';
import { getWidgetDescriptionByType } from './widgetDescription.utils.tsx';
import pick from 'lodash/fp/pick';

const CustomDashboard = ({
  initialData,
  page,
}: {
  initialData: IGetWidgetsQuery['management']['widgets'];
  page: string;
}) => {
  const [editable, setEditable] = useState(false);
  const [deleteWidget] = useDeleteWidgetMutation();
  const [updateLayout] = useUpdateWidgetsLayoutMutation();
  const layoutPendingUpdate = useRef(false);
  const feedback = useFeedback();

  const [widgets, setWidgets] = useState<Widget[]>(
    initialData.map((w) => {
      const description = getWidgetDescriptionByType(w.type);
      return {
        id: w.id,
        type: w.type,
        component: ({ id, editing, forceStatic }) => (
          <CustomWidgetCard
            id={id}
            editing={editing}
            viewComponent={description.viewComponent}
            settingsComponent={description.settingsComponent}
            onRemovedWidget={async () => {
              try {
                await deleteWidget({ variables: { id, page } });
              } catch (e) {
                feedback.showGraphqlError(e);
                throw e;
              }

              setWidgets((widgets) => widgets.filter((widget) => widget.id !== id));
            }}
            forceStatic={forceStatic}
          />
        ),
        layout: w.layout,
      };
    })
  );

  const initialState = new Map(
    initialData.map((w) => [
      w.id,
      {
        ...w.data,
        title: w.title,
      },
    ])
  );

  return (
    <CustomWidgetContextProvider initialState={initialState} page={page}>
      <Box data-tour="widget-dashboard-container">
        <HeaderBar title={'Dashboard'}>
          {editable && (
            <AddCustomWidgetButton
              page={page}
              data-tour="add-widget-container"
              onWidgetAdded={async (widget) => {
                setWidgets((widgets) => [widget, ...widgets]);
              }}
              onRemovedWidget={async (id) => {
                try {
                  await deleteWidget({ variables: { id, page } });
                } catch (e) {
                  feedback.showGraphqlError(e);
                  throw e;
                }

                setWidgets((widgets) => widgets.filter((widget) => widget.id !== id));
              }}
            />
          )}
          <GButton
            startDecorator={<Pencil />}
            data-tour="edit-widget-page-button"
            variant={editable ? 'outlined' : 'solid'}
            onClick={() => {
              setEditable((edit) => !edit);
              if (editable && layoutPendingUpdate.current) {
                updateLayout({
                  variables: {
                    input: widgets.map((widget) => ({
                      id: widget.id,
                      page: pageId,
                      ...pick(['w', 'h', 'x', 'y'], widget.layout),
                    })),
                  },
                  onError: (e) => {
                    feedback.showGraphqlError(e);
                    setWidgets(widgets);
                  },
                });
              }

              layoutPendingUpdate.current = false;
            }}
          >
            {editable ? 'Done' : 'Edit'}
          </GButton>
        </HeaderBar>

        <WidgetContainer
          editing={editable}
          widgets={widgets}
          setWidgets={async (processor) => {
            const newWidgets = processor(widgets);
            setWidgets(newWidgets);
            // don't immediately update layout to avoid multiple concurrent updates and race
            // condition between removing widget and updating layout
            layoutPendingUpdate.current = true;
          }}
        />
      </Box>
    </CustomWidgetContextProvider>
  );
};

const pageId = 'customUserPage';
const CustomDashboardContainer = () => {
  const { data } = useGetWidgetsSuspenseQuery({
    variables: {
      pageId: pageId,
    },
  });

  return <CustomDashboard page={pageId} initialData={data.management.widgets} />;
};

export default CustomDashboardContainer;

import type { FunctionComponent } from 'react';

import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';
import { useNewsBulletPointsQuery } from '../../../generated/graphql';
import { NewsList } from '../NewsList';

const SIZE_OF_TWO_CHARTS = 640;

const NewsSectionContainer: FunctionComponent<{ assetIds: string[]; height?: number }> = ({ assetIds, height }) => {
  const { data, loaded, Fallback, fetchMore } = useDefaultErrorHandling(
    useNewsBulletPointsQuery({
      variables: {
        filters: {
          assetIds: assetIds,
        },
        paging: {
          offset: 0,
          limit: 50,
        },
      },
    })
  );

  if (!loaded) {
    return <Fallback />;
  }

  return (
    <NewsList data={data.news.bulletPoints.bulletPoints} fetchMore={fetchMore} height={height ?? SIZE_OF_TWO_CHARTS} />
  );
};

export default NewsSectionContainer;

import { Suspense, useState } from 'react';
import MarketRegimeForm from './MarketRegimeForm.tsx';
import { useMarketRegimeInputSuspenseQuery } from 'generated/graphql.tsx';
import type { FormOutputFields } from './MarketRegimeForm.validation.ts';
import Loader from 'components/technical/Loader/Loader.tsx';
import MarketRegimeResult from './MarketRegimeResult.tsx';
import { formatISODate } from '../../formatter.utils.ts';
import isNil from 'lodash/fp/isNil';

const MarketRegime = () => {
  const { data } = useMarketRegimeInputSuspenseQuery();
  const [formData, setFormData] = useState<FormOutputFields | null>(null);

  return (
    <>
      <MarketRegimeForm
        onSubmit={(val) => setFormData(val)}
        models={data!.portfolio.supportedRegimeDefinitions}
        availableAssets={data!.assets.list}
      />
      {formData && (
        <Suspense fallback={<Loader />}>
          <MarketRegimeResult
            input={{
              ...formData,
              since: isNil(formData.since) ? null : formatISODate(formData.since),
              to: isNil(formData.to) ? null : formatISODate(formData.to),
            }}
          />
        </Suspense>
      )}
    </>
  );
};

export default MarketRegime;

import type { ReactElement } from 'react';
import { Controller, type FieldValues, type Path, useFormContext } from 'react-hook-form';
import type { DistributiveOmit } from '../../type.utils.ts';
import PredefinedDateRangeButtons, {
  type PredefinedDateRangeButtonsProps,
} from '../inputs/date/PredefinedDateRangeButtons.tsx';

export type FormPredefinedDateRangeInputProps = DistributiveOmit<
  PredefinedDateRangeButtonsProps,
  'value' | 'onChange' | 'error'
>;

export const FormPredefinedDateRangeInput = <T extends FieldValues>(
  props: FormPredefinedDateRangeInputProps & {
    name: Path<T>;
  }
): ReactElement => {
  /* jscpd:ignore-start */
  const { control } = useFormContext<T>();
  return (
    <Controller
      name={props.name}
      control={control}
      render={({ field: { value, onChange, onBlur, ref }, fieldState, formState }): ReactElement => (
        /* jscpd:ignore-end */
        <PredefinedDateRangeButtons
          {...props}
          ref={ref}
          value={value}
          alignRight={props.alignRight ?? false}
          onChange={onChange}
          onBlur={onBlur}
          disabled={(props.disabled ?? false) || formState.isSubmitting || formState.disabled}
          error={fieldState.error?.message}
        />
      )}
    />
  );
};

import { memo } from 'react';
import type { IPortfolioDefinition } from '../../../../../generated/graphql.tsx';
import type { CreateAccountIdAutocompleteOptionsInputAccount } from '../../../../portfolio/account/AccountService.tsx';
import type { SubfundAutocompleteOptionInput } from '../../../../portfolio/fund/SubFundService.tsx';
import GridCard from '../../../../technical/GCard/GridCard.tsx';
import { calculateItemProps } from '../PortfolioDefList.utils.ts';
import { PortfolioDefTile } from '../PortfolioDefTile.tsx';
import UpdatePortfolioDialogItem from '../update/UpdatePortfolioDialogItem.tsx';
import { VenueIconList } from '../../../../venue/VenueIconList.tsx';
import { Typography } from '@mui/joy';
import truncate from 'lodash/fp/truncate';
import { maxLength, PortfolioDescriptionText } from '../PortfolioDescriptionText.tsx';
import PortfolioCreatedAtText from '../PortfolioCreatedAt.tsx';
import SeeMoreDropdown from 'components/technical/SeeMoreDropDown/SeeMoreDropdown.tsx';
import { DeletePortfolioMenuItem } from '../DeletePortfolioMenuItem.tsx';

export const gridWidth = { sm: 12, md: 6, lg: 3 };
const RealPortfolioCard = memo(
  ({
    definition,
    cardHeight,
    selected,
    accounts,
    subFunds,
  }: {
    definition: Pick<IPortfolioDefinition, 'createdAt' | 'description' | 'id' | 'name' | 'type' | 'genie'> & {
      realDefAccounts: { id: string; name: string; venue: { label: string } }[];
      realDefSubFunds: { id: number; dimension: string; name: string }[];
    };
    accounts: CreateAccountIdAutocompleteOptionsInputAccount[];
    subFunds: SubfundAutocompleteOptionInput[];
    cardHeight: string;
    selected: boolean;
  }) => {
    return (
      <GridCard
        key={definition.id}
        height={cardHeight}
        gridWidth={gridWidth}
        {...calculateItemProps(selected, definition.id)}
      >
        <PortfolioDefTile
          name={definition.name}
          dropdown={
            definition.genie ? undefined : (
              <SeeMoreDropdown>
                <UpdatePortfolioDialogItem def={definition} availableAccounts={accounts} subFunds={subFunds} />
                <DeletePortfolioMenuItem name={definition.name} id={definition.id} />
              </SeeMoreDropdown>
            )
          }
        >
          <VenueIconList maxItems={6} accounts={definition.realDefAccounts} />
          {definition.realDefSubFunds.length > 0 && (
            <Typography level={'body-sm'}>
              {truncate(
                {
                  length: maxLength,
                },
                definition.realDefSubFunds.map((fund) => fund.name).join(', ')
              )}
            </Typography>
          )}
          <PortfolioDescriptionText definition={definition} />
          <PortfolioCreatedAtText createdAt={definition.createdAt} />
        </PortfolioDefTile>
      </GridCard>
    );
  }
);

export default RealPortfolioCard;

import { useContext } from 'react';
import { TourContext } from './TourContext';
import type { TourContextType } from './TourContext';

export const useTour = (): TourContextType => {
  const context = useContext(TourContext);
  if (!context) {
    throw new Error('useTour must be used within a TourProvider');
  }
  return context;
};

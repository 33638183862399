import { Button as Btn } from '@mui/joy';
import type { ReactElement } from 'react';
import type { GButtonProps } from './GButton.props';
import widthSx from '../../../width.styles.ts';
import { mergeSx } from '../../../sx.utils.ts';

const removeIconDecoratorMargin = { m: 0, '--Icon-margin': 0 };
const GButton = (props: GButtonProps): ReactElement => {
  const widthVariant = props.width ?? 'minContent';
  const { removeDecoratorMargin, ...rest } = props;
  return (
    <Btn
      {...rest}
      ref={props.ref}
      slotProps={
        removeDecoratorMargin
          ? {
              startDecorator: {
                ...props.slotProps?.startDecorator,
                sx: mergeSx(
                  // @ts-expect-error sx exists on the prop, but typing doesnt well reflect it
                  props.slotProps?.startDecorator?.sx,
                  removeIconDecoratorMargin
                ),
              },
            }
          : props.slotProps
      }
      sx={mergeSx(
        widthVariant === 'minContent' ? {} : widthVariant === 'fullWidth' ? { width: '100%' } : widthSx[widthVariant],
        props.sx
      )}
    />
  );
};

export default GButton;

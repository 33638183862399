// default presets can be generated via "See all" -> "Copy current report state as JSON"

export const positionsSummaryDefaultPresets = [
  {
    name: 'Default',
    isDefault: true,
    settings: {
      filters: {},
      pivotMode: false,
      columns: [
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'ag-Grid-AutoColumn',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'name',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'symbol',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'underlyingAsset',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'assetType',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'cluster-sector',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'cluster-category',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'cluster-cluster_price_action_optics',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'cluster-hey',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'subAccount.name',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'type',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'side-with-badge',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'balance',
          pivot: false,
          pinned: null,
          aggFunc: 'sum',
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'exposure-sideAware-true',
          pivot: false,
          pinned: null,
          aggFunc: 'sum',
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'amount-sideAware-true',
          pivot: false,
          pinned: null,
          aggFunc: 'sum',
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'unrealizedPnl',
          pivot: false,
          pinned: null,
          aggFunc: 'sum',
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'price',
          pivot: false,
          pinned: null,
          aggFunc: 'avg',
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'balanceContribution',
          pivot: false,
          pinned: null,
          aggFunc: 'sum',
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'exposure-sideAware-false',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'amount-sideAware-false',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'price-change-24h',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'past-price-latest',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'price-change-MTD',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'past-price-MTD',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'price-change-QTD',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'past-price-QTD',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'price-change-YTD',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'past-price-YTD',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
      ],
    },
  },
];

import { type ReactElement, Suspense, useState } from 'react';
import { range7Days } from '../../predefinedDateRanges.ts';
import PredefinedDateRangeButtonsWithCalendar from '../../technical/inputs/date/PredefinedDateRangeButtonsWithCalendar.tsx';
import Loader from '../../technical/Loader/Loader.tsx';
import { Box, Stack } from '@mui/joy';
import type { Dayjs } from 'dayjs';
import { defaultRowSpacing } from '../../StackSpacing.ts';
import { useSubAccountAssetFilters } from '../../technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters.tsx';
import { useTradingActivityReportSuspenseQuery } from '../../../generated/graphql.tsx';
import { convertDateRangeToSinceToDate } from '../../technical/inputs/date/dateRange.utils.ts';
import { useReportAssetGroup } from '../../UseReportAssetGroups.tsx';
import { TradingActivityReport } from './TradingActivityReport.tsx';

const TradingActivityDashboard = (): ReactElement => {
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs] | null>(range7Days.value);
  return (
    <Stack spacing={defaultRowSpacing} height="100%">
      <Box display="flex" justifyContent="space-between" alignSelf="flex-end">
        <PredefinedDateRangeButtonsWithCalendar
          alignRight={false}
          defaultValue={range7Days}
          onChange={(val): void => setDateRange(val)}
        />
      </Box>
      <Suspense fallback={<Loader />}>
        <TradingActivityReportContainer dateRange={dateRange} />
      </Suspense>
    </Stack>
  );
};

const TradingActivityReportContainer = ({ dateRange }: { dateRange: [Dayjs, Dayjs] | null }): ReactElement => {
  const { subAccountAssetFilters } = useSubAccountAssetFilters();
  const queryResult = useTradingActivityReportSuspenseQuery({
    variables: {
      date: convertDateRangeToSinceToDate(dateRange),
      subAccountAssetFilters,
    },
  });

  const reportAssetGroup = useReportAssetGroup();

  if (!reportAssetGroup.loaded) {
    return <reportAssetGroup.Fallback />;
  }

  return (
    <TradingActivityReport
      subFunds={queryResult.data.portfolio.subFunds.list}
      rows={queryResult.data.bookkeeping.tradingActivity}
      assetAndGroupClusterMapToGroup={reportAssetGroup.assetAndGroupClusterMapToGroup}
      assetClusters={reportAssetGroup.clusters}
    />
  );
};

export default TradingActivityDashboard;

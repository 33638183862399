import { Stack } from '@mui/joy';
import type { ReactElement } from 'react';

import { GraphQLApiFormErrorMessage } from 'components/technical/form/GraphQLApiErrorMessage.tsx';
import SubmitButton from 'components/technical/form/SubmitButton.tsx';
import type { TFallback } from 'components/technical/UseDefaultErrorHandling.tsx';
import { defaultRowSpacing } from '../../../../StackSpacing.ts';

const SubmitStep = ({
  forecastFallback: ForecastFallback,
  skippedForecast,
}: {
  forecastFallback: TFallback | undefined;
  skippedForecast: boolean;
}): ReactElement => {
  return (
    <Stack gap={defaultRowSpacing} alignItems="flex-start">
      <GraphQLApiFormErrorMessage />
      {!skippedForecast && ForecastFallback && <ForecastFallback />}
      <SubmitButton>Submit</SubmitButton>
    </Stack>
  );
};

export default SubmitStep;

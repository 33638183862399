import { Card, Divider, Grid, Stack, Tooltip } from '@mui/joy';
import bigNumMath from 'bigNumMath';
import { bignumber } from 'mathjs';
import type { FunctionComponent, ReactElement } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import GFormProvider from 'components/technical/form/GFormProvider';
import gYupResolver from 'components/technical/form/gYupResolver';
import HeaderBar from 'components/technical/HeaderBar/HeaderBar';
import Help from 'components/technical/Help/Help';
import { FormInput } from 'components/technical/inputs';
import GButton from 'components/technical/inputs/GButton/GButton';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';

import { type PortfolioBuilderInputFields, schema } from './PortfolioBuilder.utils.ts';
import PortfolioBuilderAssetList from './PortfolioBuilderAssetList';
import { type IPortfolioBuilderInputQuery, usePortfolioBuilderInputQuery } from '../../../generated/graphql';
import {
  aggregateSupportedAssetPositions,
  getPublicSpotPositionsWithValue,
} from '../../portfolio/account/SubAccountPositionsService.ts';
import { assetMinPercentageContribution } from '../ImportPortfolioButton';

import ConfirmationDialog from 'components/technical/form/dialog/ConfirmationDialog.tsx';
import { defaultRowSpacing } from 'components/StackSpacing.ts';
import { useReportAssetGroup, type UseReportAssetGroupResultLoaded } from 'components/UseReportAssetGroups.tsx';
import PositionsGrid from './PositionsGrid.tsx';
import { useImperativeModal } from '../../technical/ImperativeModal/UseImperativeModal.tsx';
import PositionsSunburst from './PositionsSunburst.tsx';
import type { GroupWithAssetId } from 'components/portfolio/dashboard/PositionAggregationsService.ts';
import CreatePortfolioBuilderButton from './CreatePortfolioBuilderButton.tsx';
import { isValidNumber } from 'components/number.utils.ts';
import type { NotVerifiedAssetWithId } from 'components/market/asset/AssetService.tsx';
import { Download } from '@mui/icons-material';

const getDefaultValues = (): PortfolioBuilderInputFields => ({
  portfolioEquity: null,
  positions: {},
});

export const DEFAULT_PORTFOLIO_EQUITY = '100';

type PortfolioBuilderProps = {
  reportAssetGroup: UseReportAssetGroupResultLoaded;
  assetGroups: {
    genieGroups: GroupWithAssetId[];
    userGroups: GroupWithAssetId[];
  };
  assets: IPortfolioBuilderInputQuery['assets']['feature'];
  portfolioPositions: IPortfolioBuilderInputQuery['portfolio']['positions']['positions'];
};

export type BuilderPortfolioPosition = {
  asset: NotVerifiedAssetWithId & {
    symbol: string;
  };
  weight: string | null;
  value: string | null;
};

const PortfolioBuilder: FunctionComponent<PortfolioBuilderProps> = ({
  assets,
  assetGroups,
  portfolioPositions,
  reportAssetGroup,
}) => {
  const defaultValues = getDefaultValues();

  const noPortfolioMessage = portfolioPositions.length === 0 ? 'No positions found in portfolio' : undefined;

  const methods = useForm<PortfolioBuilderInputFields>({
    resolver: gYupResolver(schema),
    mode: 'onChange',
    defaultValues,
  });

  const { showModal } = useImperativeModal();

  const portfolioEquity = useWatch({
    name: 'portfolioEquity',
    control: methods.control,
  });

  const portfolioEquityNumber = bignumber(portfolioEquity || DEFAULT_PORTFOLIO_EQUITY).toNumber();
  const showDollarValues = portfolioEquityNumber !== 0;

  const assetById = new Map(assets.map((asset) => [asset.id, asset]));

  function importFromPortfolio(): void {
    const publicSpotPositions = getPublicSpotPositionsWithValue(portfolioPositions);
    const aggregatedPositions = aggregateSupportedAssetPositions(publicSpotPositions, assets);

    const equity = bigNumMath.sum(aggregatedPositions.map((asset) => asset.value.abs()));
    const minCashValue = equity.mul(assetMinPercentageContribution);

    const newPositions = Object.fromEntries(
      aggregatedPositions
        .filter((pos) => pos.value.abs().greaterThan(minCashValue))
        .map((pos) => [
          pos.id,
          {
            asset: assetById.get(pos.id)!,
            value: pos.value.abs().toDP(2).toString(),
            weight: pos.value.mul(100).div(equity).toDP(2).toString(),
          },
        ])
    );

    methods.setValue('portfolioEquity', equity.toDP(2).toString());
    methods.setValue('positions', newPositions, { shouldValidate: true });
  }

  const handleEquityOnChange = (newEquityValue: string | null): void => {
    if (newEquityValue === null) {
      return;
    }

    const equityNumber = Number.parseFloat(newEquityValue);
    const positions = methods.getValues('positions');

    // update each position's value and weight based on the new equity value
    const updatedPositions = Object.fromEntries(
      Object.entries(positions).map(([id, pos]) => [
        id,
        {
          ...pos,
          value:
            isValidNumber(pos.weight) && isValidNumber(equityNumber)
              ? bignumber(pos.weight).div(100).mul(equityNumber).toDP(2).toString()
              : '0',
          weight: pos.weight,
        },
      ])
    );

    methods.setValue('positions', updatedPositions);
  };

  const positions = useWatch({
    control: methods.control,
    name: 'positions',
  });

  return (
    <Stack spacing={defaultRowSpacing}>
      <GFormProvider {...methods}>
        <HeaderBar title="Portfolio builder">
          <Stack gap={defaultRowSpacing} direction="row">
            <Stack direction="column" spacing={1} justifyContent="center" alignItems="center">
              {Object.keys(positions).length > 0 ? (
                <GButton
                  variant="outlined"
                  width="smaller"
                  onClick={() => {
                    methods.setValue('positions', {});
                    methods.setValue('portfolioEquity', null);
                  }}
                >
                  Clear All
                </GButton>
              ) : (
                <Tooltip title={noPortfolioMessage}>
                  {/* span needed so tooltip is shown even when button is disabled */}
                  <span>
                    <GButton
                      variant="solid"
                      width="normal"
                      disabled={portfolioPositions.length === 0}
                      onClick={(): void => {
                        if (methods.formState.isDirty) {
                          showModal(({ onClose }) => (
                            <ConfirmationDialog
                              onClose={onClose}
                              onApprove={async () => {
                                onClose();
                                importFromPortfolio();
                              }}
                            >
                              Operation will override current portfolio composition. Are you sure you want to proceed?
                            </ConfirmationDialog>
                          ));
                        } else {
                          importFromPortfolio();
                        }
                      }}
                      startDecorator={<Download />}
                    >
                      Import from portfolio
                    </GButton>
                  </span>
                </Tooltip>
              )}
            </Stack>
            {Object.keys(positions).length > 0 && <CreatePortfolioBuilderButton />}
          </Stack>
        </HeaderBar>
        <form>
          <Stack direction="row" spacing={defaultRowSpacing}>
            <FormInput<PortfolioBuilderInputFields>
              showLabelAboveInput
              startDecorator="$"
              name="portfolioEquity"
              type="number"
              width="normal"
              label={
                <>
                  Portfolio equity&nbsp;
                  <Help>Unleveraged amount of funds in the portfolio</Help>
                </>
              }
              onChange={(newValue) => handleEquityOnChange(newValue)}
            />
            <PortfolioBuilderAssetList />
          </Stack>

          <Card
            sx={{
              marginTop: defaultRowSpacing,
            }}
          >
            <Grid container spacing={4}>
              <Grid xs={12} sm={12} md={9} gap={8} minHeight="400px">
                <PositionsGrid reportAssetGroup={reportAssetGroup} showValue={showDollarValues} />
              </Grid>
              <Divider
                sx={{ height: '90%', marginY: 'auto', display: { xs: 'none', sm: 'block' } }}
                orientation="vertical"
              />
              <Grid
                sm={12}
                md={3}
                sx={{
                  height: '90%',
                  display: { xs: 'none', sm: 'block' },
                }}
              >
                <PositionsSunburst portfolioEquity={portfolioEquity} assetGroups={assetGroups} />
              </Grid>
            </Grid>
          </Card>
        </form>
      </GFormProvider>
    </Stack>
  );
};

const PortfolioBuilderContainer = (): ReactElement => {
  const { data: inputData, loaded, Fallback } = useDefaultErrorHandling(usePortfolioBuilderInputQuery());
  const reportAssetGroup = useReportAssetGroup();

  if (!loaded) {
    return <Fallback />;
  }

  if (!reportAssetGroup.loaded) {
    return <reportAssetGroup.Fallback />;
  }

  return (
    <PortfolioBuilder
      assetGroups={inputData.assets.assetGroups}
      portfolioPositions={inputData.portfolio.positions.positions}
      assets={inputData.assets.feature}
      reportAssetGroup={reportAssetGroup}
    />
  );
};

export default PortfolioBuilderContainer;

import { TabList, Tabs, Tab, TabPanel } from '@mui/joy';
import type { ReactElement } from 'react';

import ReconciliationReportContainer from '../../bookkeeping/reconciliation/ReconciliationReport.tsx';
import JournalErrorList from './JournalList.tsx';

const ReconciliationPage = (): ReactElement => {
  return (
    <Tabs defaultValue={1}>
      <TabList>
        <Tab>Journal</Tab>
        <Tab>Reconciliation</Tab>
      </TabList>
      <TabPanel value={0}>
        <JournalErrorList />
      </TabPanel>
      <TabPanel value={1}>
        <ReconciliationReportContainer />
      </TabPanel>
    </Tabs>
  );
};

export default ReconciliationPage;

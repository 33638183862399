import { Stack, SvgIcon } from '@mui/joy';
import type { ReactElement } from 'react';

import GButton from 'components/technical/inputs/GButton/GButton';
import type { GButtonProps } from 'components/technical/inputs/GButton/GButton.props';
import GoogleIcon from './google.svg?react';
import { oAuthResponseType, oAuthScope, getEnvConfig } from '../../../config';

const GoogleLoginButton = ({ width }: { width: GButtonProps['width'] }): ReactElement => {
  const { authSettings } = getEnvConfig();
  const oauthSettings = authSettings.oAuthSettings;
  if (!oauthSettings) {
    return <></>;
  }

  const params = new URLSearchParams();
  params.append('identity_provider', 'Google');
  params.append('client_id', authSettings.cognitoPool.clientAppId);
  params.append('response_type', oAuthResponseType);
  params.append('scope', oAuthScope.join(' '));
  params.append('redirect_uri', window.location.origin);
  const loginUrl = `https://${oauthSettings.domain}/oauth2/authorize?${params.toString()}`;

  return (
    <GButton
      width={width}
      onClick={(): void => {
        window.location.href = loginUrl;
      }}
      slotProps={{
        startDecorator: {
          sx: {
            display: 'block',
            height: '100%',
            aspectRatio: '1',
          },
        },
      }}
      startDecorator={
        <Stack
          sx={{
            background: 'var(--joy-palette-common-white)',
            padding: 0.3,
            borderRadius: 'var(--joy-radius-sm)',
            height: '100%',
            aspectRatio: '1',
          }}
          justifyContent="center"
          alignItems="center"
        >
          <SvgIcon viewBox="0 0 256 262" component={GoogleIcon} fontSize="md" style={{ margin: 0 }} />
        </Stack>
      }
    >
      <span
        style={{
          paddingLeft: '.2rem',
        }}
      >
        Continue with Google
      </span>
    </GButton>
  );
};

export default GoogleLoginButton;

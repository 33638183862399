import type { ReactElement } from 'react';

import ChartSkeleton from './SunburstChartSkeleton.svg?react';
import { defaultHeight } from '../Chart.constants';

const SunburstChartSkeleton = ({
  width = '100%',
  height = `${defaultHeight}px`,
}: {
  width?: string;
  height?: string;
}): ReactElement => {
  return (
    <ChartSkeleton
      style={{
        height,
        width,
      }}
    />
  );
};

export default SunburstChartSkeleton;

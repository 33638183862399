import type { ReactElement } from 'react';
import { usePrivateAssetsSuspenseQuery } from '../../../../../generated/graphql.tsx';
import { Grid } from '@mui/joy';
import { CreatePrivateAssetDialog } from './CreatePrivateAssetDialog.tsx';
import GridCard, { type GridWidth } from '../../../../technical/GCard/GridCard.tsx';
import WithDialog from '../../../../technical/inputs/withDialogHoc/WithDialog.tsx';
import PrivateAssetCard from './PrivateAssetCard.tsx';
import { ADD_NEW_CARD_STYLE } from 'components/technical/GCard/consts.ts';

const GCardWithDialog = WithDialog(GridCard);
const PrivateAssetManagementList = ({
  cardHeight,
  gridWidth,
  filterInput,
}: { cardHeight: string; gridWidth: GridWidth; filterInput: string }): ReactElement => {
  const { data } = usePrivateAssetsSuspenseQuery();

  const filteredList = filterInput
    ? data.assets.list.filter(
        (asset) =>
          asset.name?.toLowerCase().includes(filterInput.toLowerCase()) ||
          asset.symbol?.toLowerCase().includes(filterInput.toLowerCase())
      )
    : data.assets.list;

  return (
    <Grid container spacing={3}>
      <GCardWithDialog
        cardSx={ADD_NEW_CARD_STYLE}
        renderDialog={({ onClose }): ReactElement => <CreatePrivateAssetDialog onAdded={onClose} onClose={onClose} />}
        height={cardHeight}
        gridWidth={gridWidth}
        addNewText="Add new private asset"
      />
      {filteredList.map((asset) => (
        <PrivateAssetCard key={asset.id} asset={asset} gridWidth={gridWidth} height={cardHeight} />
      ))}
    </Grid>
  );
};

export default PrivateAssetManagementList;

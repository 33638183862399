import { useForm } from 'react-hook-form';
import gYupResolver from '../../../technical/form/gYupResolver.ts';
import * as yup from 'yup';
import { FormPredefinedDateRangeInput } from '../../../technical/form/FormPredefinedDateRangeInput.tsx';
import type { PredefinedRangeLabel } from '../../../predefinedDateRanges.ts';
import FormStaticSingleAutocomplete from '../../../technical/form/FormStaticSingleAutocomplete.tsx';
import { getClusterOptions } from '../../../market/asset/groups/GroupService.ts';
import { usePerformanceAttributionSettingsWidgetInputSuspenseQuery } from 'generated/graphql.tsx';
import { type AttributionReportSide, attributionSideOptions } from './PerformanceAttributionChart.tsx';
import { updateWidgetState, useWidgetState } from 'components/management/customDashboard/CustomWidgetContext.tsx';
import { calculateDefaultPresetRange } from 'components/management/TilesDateRangeSelector.tsx';
import WidgetSettings from 'components/management/customDashboard/WidgetSettings.tsx';

const schema = yup.object({
  title: yup.string().required(),
  predefinedDateRange: yup.object().required(),
  cluster: yup.string().required(),
  side: yup.string().required(),
});

interface FormState {
  title: string;
  predefinedDateRange: {
    label: PredefinedRangeLabel;
  };
  cluster: string;
  side: AttributionReportSide;
}

export interface PortfolioAttributionState {
  title: string;
  predefinedDateRange?: PredefinedRangeLabel;
  cluster?: string;
  side: AttributionReportSide;
}

export const PortfolioAttributionWidgetSettings = ({
  id,
  onFinishedEditing,
  onRemovedWidget,
}: {
  id: string;
  onFinishedEditing: () => void;
  onRemovedWidget: () => Promise<void>;
}) => {
  const initialState = useWidgetState<PortfolioAttributionState>(id);
  const updater = updateWidgetState<PortfolioAttributionState>();

  const { data } = usePerformanceAttributionSettingsWidgetInputSuspenseQuery();
  const classificationOptions = getClusterOptions(data.assets.clusterByUser);

  const methods = useForm<FormState>({
    resolver: gYupResolver(schema),
    defaultValues: {
      ...initialState,
      predefinedDateRange: calculateDefaultPresetRange(initialState.predefinedDateRange),
    },
  });

  const handleFormSubmit = async (input: FormState): Promise<void> => {
    await updater({
      id,
      state: {
        title: input.title,
        predefinedDateRange: input.predefinedDateRange.label,
        side: input.side,
        cluster: input.cluster,
      },
      updateBackend: true,
    });

    onFinishedEditing();
  };

  return (
    <WidgetSettings methods={methods} handleFormSubmit={handleFormSubmit} onRemovedWidget={onRemovedWidget}>
      <FormPredefinedDateRangeInput name={'predefinedDateRange'} label={'Date range'} />
      <FormStaticSingleAutocomplete
        name={'cluster'}
        width="normal"
        options={classificationOptions}
        showLabelAboveInput
      />
      <FormStaticSingleAutocomplete name={'side'} width="normal" options={attributionSideOptions} showLabelAboveInput />
    </WidgetSettings>
  );
};

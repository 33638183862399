import { useUpdateUserSettingsMutation, useUserSettingsSuspenseQuery } from 'generated/graphql';
import { UserSettingsDocument } from 'generated/graphql';
import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';
import type { Aggregation } from 'components/portfolio/dashboard/PositionAggregationsService';

type AggregationsByCategory<T> = Record<string, Aggregation<T>>;

const extractAggregationFullData = <T,>(
  allAvailableAggregations: Aggregation<T>[],
  aggToExtract: { category: string; active: boolean }
): (Aggregation<T> & { active: boolean }) | null => {
  const foundAgg = allAvailableAggregations.find((agg) => agg.category === aggToExtract.category);
  if (!foundAgg) {
    return null;
  }
  return {
    ...foundAgg,
    active: aggToExtract.active ?? false,
  };
};

export const createAggregations = <T,>({
  savedAggs,
  defaultAggs,
  allAvailableAggregations,
}: {
  savedAggs: { category: string; active: boolean }[];
  defaultAggs: Aggregation<T>[];
  allAvailableAggregations: Aggregation<T>[];
}): {
  allAggs: Aggregation<T>[];
  aggregationsByCategory: Record<string, Aggregation<T>>;
  activeAggregationsOrdered: string[];
} => {
  const savedAggregationsRawOrDefault =
    savedAggs.length > 0 ? savedAggs : defaultAggs.map((agg) => ({ active: true, ...agg }));

  const savedAggregations: (Aggregation<T> & { active: boolean })[] = savedAggregationsRawOrDefault
    .map((val) => extractAggregationFullData(allAvailableAggregations, val))
    .filter(
      (agg: (Aggregation<T> & { active: boolean }) | null): agg is Aggregation<T> & { active: boolean } => agg !== null
    );

  const newNotSavedYetAggregations = allAvailableAggregations.filter(
    (agg) =>
      !savedAggregations.some((savedAgg: Aggregation<T> & { active: boolean }) => savedAgg.category === agg.category)
  );

  const allAggs = [...savedAggregations, ...newNotSavedYetAggregations.map((agg) => ({ active: false, ...agg }))];

  return {
    allAggs,
    aggregationsByCategory: createAggregationsByCategory<T>(allAggs),
    activeAggregationsOrdered: allAggs.filter((agg) => agg.active).map((agg) => agg.category),
  };
};

export const useAggregations = <T,>({
  allAvailableAggregations,
  defaultAggregations,
  userSettingsKey,
}: {
  allAvailableAggregations: Aggregation<T>[];
  defaultAggregations: Aggregation<T>[];
  userSettingsKey: string;
}): {
  aggregations: Aggregation<T>[];
  activeAggregationsOrdered: string[];
  aggregationsByCategory: AggregationsByCategory<T>;
  handleSetAggregationConfig: (config: { category: string; active: boolean }[]) => Promise<void>;
} => {
  const { showGraphqlError } = useFeedback();

  const { data } = useUserSettingsSuspenseQuery({
    variables: {
      field: userSettingsKey,
    },
  });

  const { allAggs, aggregationsByCategory, activeAggregationsOrdered } = createAggregations({
    savedAggs: (data?.management?.userSettings as { category: string; active: boolean }[]) ?? [],
    defaultAggs: defaultAggregations,
    allAvailableAggregations,
  });

  const [updateUserSettings] = useUpdateUserSettingsMutation();

  const handleSetAggregationConfig = async (config: { category: string; active: boolean }[]): Promise<void> => {
    try {
      await updateUserSettings({
        variables: {
          settings: {
            [userSettingsKey]: config,
          },
        },
        refetchQueries: [UserSettingsDocument],
      });
    } catch (error) {
      showGraphqlError(error);
    }
  };

  return {
    aggregations: allAggs,
    aggregationsByCategory,
    handleSetAggregationConfig,
    activeAggregationsOrdered,
  };
};

const createAggregationsByCategory = <T,>(aggregations: Aggregation<T>[]): AggregationsByCategory<T> =>
  Object.fromEntries(aggregations.map((agg) => [agg.category, agg]));

import type { IAgent } from '../../../../generated/graphql.tsx';
import type { ReactElement, ReactNode } from 'react';
import { Grid, Stack, Typography } from '@mui/joy';
import GridCard from '../../../technical/GCard/GridCard.tsx';
import { GCardContentWithDivider } from '../../../technical/GCard/GCardContentWithDivider.tsx';
import GIcon from 'components/technical/GIcon.tsx';

export const SelectAgent = ({
  agents,
  onSelected,
}: {
  agents: (Pick<IAgent, 'id' | 'icon' | 'description'> & { name: ReactNode })[];
  onSelected: (agent: { id: string; name: ReactNode }) => void;
}): ReactElement => {
  return (
    <Stack rowGap={1}>
      <Typography level={'h4'} pb={2} mx={'auto'}>
        Pick your AI guide
      </Typography>
      <Grid container columnSpacing={4} rowSpacing={2} justifyContent={'center'}>
        {agents.map((agent, i) => (
          <GridCard
            onClick={() => onSelected(agent)}
            key={i}
            gridWidth={{
              xs: 12,
              sm: 6,
            }}
            height={'8rem'}
            size={'md'}
          >
            <GCardContentWithDivider>
              <Stack direction="row" columnGap={0.75}>
                <GIcon src={agent.icon} alt={''} fontSize={'xl'} />
                <Typography
                  level={'title-lg'}
                  color="primary"
                  sx={{
                    wordBreak: 'break-word',
                  }}
                >
                  {agent.name}
                </Typography>
              </Stack>
              <Typography level={'body-xs'}>{agent.description}</Typography>
            </GCardContentWithDivider>
          </GridCard>
        ))}
      </Grid>
    </Stack>
  );
};

// Highlight a circle around the target element
export const highlightCircle = (
  x: number,
  y: number,
  options: {
    id?: string;
    radius?: number;
    color?: string;
    borderWidth?: number;
    zIndex?: number;
    animation?: boolean;
  } = {}
) => {
  const {
    id = 'tour-highlight-circle',
    radius = 20,
    color = 'var(--joy-palette-primary-outlinedBorder)',
    borderWidth = 2,
    zIndex = 999999,
    animation = true,
  } = options;

  if (document.getElementById(id)) return;

  const circle = document.createElement('div');
  circle.id = id;
  circle.style.position = 'fixed';
  circle.style.top = `${y}px`;
  circle.style.left = `${x}px`;
  circle.style.width = `${radius * 2}px`;
  circle.style.height = `${radius * 2}px`;
  circle.style.marginLeft = `-${radius}px`;
  circle.style.marginTop = `-${radius}px`;
  circle.style.borderRadius = '50%';
  circle.style.border = `${borderWidth}px solid ${color}`;
  circle.style.background = 'rgba(255, 255, 255, 0.3)';
  circle.style.zIndex = `${zIndex}`;
  circle.style.pointerEvents = 'none';
  circle.style.transition = 'opacity 0.3s ease-in-out';

  if (animation) {
    circle.style.animation = 'tour-pulse 1.5s infinite';
    const style = document.createElement('style');
    style.innerHTML = `
      @keyframes tour-pulse {
        0% { transform: scale(1); opacity: 0.9; }
        50% { transform: scale(1.15); opacity: 0.6; }
        100% { transform: scale(1); opacity: 0.9; }
      }
    `;
    document.head.appendChild(style);
  }

  document.body.appendChild(circle);
};

export const removeHighlightCircle = (id = 'tour-highlight-circle') => {
  const el = document.getElementById(id);
  if (el) el.remove();
};

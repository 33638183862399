import type { BigNumber } from 'mathjs';
import { type ReactElement, useState } from 'react';
import type { SunburstChartData } from './SunburstChart.props';
import SunburstChart from './SunburstChart.tsx';
import type { IAsset } from '../../../../generated/graphql';
import Select from '../../inputs/Select/Select';
import Loader from '../../Loader/Loader.tsx';
import { Stack } from '@mui/joy';
import { defaultHeight } from '../Chart.constants.ts';

export type AssetRow = {
  asset: Omit<IAsset, '__typename' | 'unvestedAsset' | 'derivativeDetails' | 'priceAsset'>;
  balance: Decimal | BigNumber;
};

export type StaticAssetGroupSunburstProps = {
  calculateChartData: (category: string) => Omit<SunburstChartData, 'hoverinfo' | 'textinfo'>;
  options: { label: string; category: string }[];
  loaded?: boolean;
  Fallback?: () => ReactElement;
  csvExportFileName?: string;
  hideCategorySelection?: boolean;
  fullHeight?: boolean;
};

const exposureSelectorTopOffset = '-2rem';
const StaticAssetGroupSunburst = ({
  calculateChartData,
  options,
  loaded = true,
  Fallback = (): ReactElement => <Loader />,
  hideCategorySelection = false,
  fullHeight,
}: StaticAssetGroupSunburstProps): ReactElement => {
  const [category, setCategory] = useState(options[0]?.category);
  const sunburstData = calculateChartData(category);

  return (
    <Stack
      sx={{
        position: 'relative',
        height: fullHeight ? '100%' : undefined,
        minHeight: fullHeight ? 0 : undefined,
      }}
    >
      <Stack sx={{ position: 'absolute', top: fullHeight ? 0 : exposureSelectorTopOffset }}>
        {!hideCategorySelection && (
          <Select
            label="Chart dimension"
            value={category}
            onChange={(value): void => setCategory(value)}
            options={options.map((opt) => ({
              label: opt.label,
              value: opt.category,
              key: opt.category,
            }))}
            width="normal"
          />
        )}
      </Stack>
      <Stack
        height={fullHeight ? '100%' : defaultHeight}
        width={'100%'}
        pt={fullHeight ? `calc(-1 * ${exposureSelectorTopOffset})` : 0}
      >
        {loaded ? (
          <SunburstChart
            key={category}
            height={fullHeight ? 'fullHeight' : undefined}
            data={{
              ...sunburstData,
              hoverinfo: 'percent root+label+text',
              textinfo: 'percent root+label+text',
            }}
          />
        ) : (
          <Fallback />
        )}
      </Stack>
    </Stack>
  );
};

export default StaticAssetGroupSunburst;

import { Add } from '@mui/icons-material';
import { Typography as JoyTypography, Link, Modal, ModalDialog, Stack } from '@mui/joy';
import { convertDateInUtcToUTCISODate } from 'components/date.utils';
import { downloadLocalCsvFile, FileErrorSummary, generateErrorSummary } from 'components/file.utils';
import { defaultRowSpacing } from 'components/StackSpacing';
import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';
import type { FormInputType } from 'components/technical/form/Form.types';
import FormFileUploadInput from 'components/technical/form/FormFileUploadInput';
import GFormProvider from 'components/technical/form/GFormProvider';
import { GraphQLApiFormErrorMessage } from 'components/technical/form/GraphQLApiErrorMessage';
import gYupResolver from 'components/technical/form/gYupResolver';
import SubmitButton from 'components/technical/form/SubmitButton';
import { useGraphQLApiError } from 'components/technical/form/UseGraphQLApiError.tsx';
import GDialogHeader from 'components/technical/GDialog/GDialogHeader.tsx';
import { MsExcelCsvConversionDisclaimer } from 'components/technical/MsExcelCsvConversionDisclaimer.tsx';
import { useState, type ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  PortfolioDefListInputDocument,
  useUploadRebalancePortfolioReturnsMutation,
} from '../../../../../generated/graphql';
import { parseFile, sampleFile } from './UploadRebalancePortfoliosReturnsDialog.util';

interface FormOutputFields {
  file: File;
}

type FormInputFields = FormInputType<FormOutputFields>;

const formSchema = yup.object({
  file: yup.mixed().required(),
});

export const UploadRebalancePortfoliosReturns = (props: {
  onClose: () => void;
  onAdded: () => void;
}): ReactElement => {
  const methods = useForm<FormInputFields>({
    resolver: gYupResolver(formSchema),
    defaultValues: {
      file: null,
    },
  });

  const [fileValidationErrors, setFileValidationErrors] = useState<string[]>([]);
  const { onErrorAndThrow } = useGraphQLApiError(methods);
  const { showSuccessMessage } = useFeedback();

  const [createReturnsBasedPortfolio] = useUploadRebalancePortfolioReturnsMutation({
    refetchQueries: [PortfolioDefListInputDocument],
  });

  const handleFormSubmit = async (input: FormInputFields): Promise<void> => {
    const formOutput = input as unknown as FormOutputFields;
    setFileValidationErrors([]);
    const fileText = (await formOutput.file.text()) ?? '';
    const { generalErrors, lineErrors, data } = parseFile(fileText);
    const errorText = generateErrorSummary({ generalErrors, lineErrors });

    if (errorText.length > 0) {
      setFileValidationErrors(errorText);
      console.error('File validation errors', errorText);
      throw new Error('Invalid form');
    }

    try {
      await createReturnsBasedPortfolio({
        variables: {
          input: data.map((row) => ({
            date: convertDateInUtcToUTCISODate(row.date),
            portfolioName: row.portfolioName,
            value: Number.parseFloat(row.value),
          })),
        },
      });

      showSuccessMessage('New portfolios successfully added');

      props.onAdded();
    } catch (e) {
      onErrorAndThrow(e);
    }
  };

  const inputWidth = 'normal';
  return (
    <Modal open={true} onClose={props.onClose}>
      <ModalDialog>
        <GFormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
            <GDialogHeader>Upload returns</GDialogHeader>
            <Stack spacing={2}>
              <FormFileUploadInput name={'file'} width={inputWidth} onChange={() => setFileValidationErrors([])} />

              <div>
                <JoyTypography color="neutral" level="body-sm" maxWidth={'100%'}>
                  Click on the link to download a{' '}
                  <Link
                    onClick={() => {
                      downloadLocalCsvFile(sampleFile, 'returns.txt');
                    }}
                  >
                    sample file
                  </Link>
                  . The file contains columns:
                  <br />- Date, mandatory, uses format YYYY-MM-DD and cannot refer to the future
                  <br />- Any additional column creates a new portfolio based on provided returns. For 2% return,
                  provide value 0.02. For negative returns, use minus sign, for example for -5% use -0.05. If the
                  portfolio doesnt have returns for a given day, we assume returns of 0%.
                  <br />
                  <br />
                  <MsExcelCsvConversionDisclaimer />
                </JoyTypography>
              </div>

              <FileErrorSummary fileValidationErrors={fileValidationErrors} />
              <Stack direction={'column'} spacing={defaultRowSpacing} alignItems={'center'}>
                <GraphQLApiFormErrorMessage />
                <SubmitButton width="small" startDecorator={<Add />}>
                  Upload returns
                </SubmitButton>
              </Stack>
            </Stack>
          </form>
        </GFormProvider>
      </ModalDialog>
    </Modal>
  );
};

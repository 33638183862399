import { memo, type ReactNode, Suspense, useEffect, useMemo, useState } from 'react';
import { Box, Card, Typography } from '@mui/joy';
import { useWidgetState } from './CustomWidgetContext.tsx';
import { transitionDurationS } from '../../../theme/consts.ts';
import { AnimatePresence, motion } from 'framer-motion';
import { iconPadding, CustomWidgetCloseAction } from './CustomWidgetCloseAction.tsx';
import GErrorBoundary from 'components/technical/GErrorBoundary.tsx';
import Loader from 'components/technical/Loader/Loader.tsx';

export const CustomWidgetCard = memo(
  ({
    id,
    justCreated = false,
    // widgets are in editing mode
    editing,
    viewComponent: ViewComponent,
    settingsComponent: SettingsComponent,
    forceStatic,
    onRemovedWidget,
  }: {
    id: string;
    editing: boolean;
    justCreated?: boolean;
    viewComponent: (props: { id: string }) => ReactNode;
    settingsComponent: (props: {
      id: string;
      onFinishedEditing: () => void;
      onRemovedWidget: () => Promise<void>;
    }) => ReactNode;
    forceStatic: (id: string, val: boolean) => void;
    onRemovedWidget: () => Promise<void>;
  }) => {
    const [editingWidget, setEditingWidget] = useState(false);
    const widgetProps = useWidgetState(id);

    useEffect(() => {
      if (!editing && editingWidget) {
        setEditingWidget(false);
      }
    }, [editing, editingWidget]);

    // aggrid crashed when rerendered, so we memoize it to avoid rerendering views not updated
    const memoizedView = useMemo(() => <ViewComponent id={id} />, [id, ViewComponent]);

    return (
      <Card
        data-tour-index={justCreated ? 'just-added' : undefined}
        variant={'outlined'}
        sx={{
          cursor: editing && !editingWidget ? 'move' : 'default',
          height: '100%',
          '*::selection': {
            backgroundColor: editing && !editingWidget ? 'transparent' : '',
          },
          position: 'relative',
        }}
        size={'sm'}
      >
        {!editingWidget && <Typography pr={iconPadding}>{widgetProps.title}</Typography>}
        <AnimatePresence>
          {editing && (
            <motion.div
              key={'settings'}
              data-tour="widget-card-close-div"
              style={{
                marginLeft: 'auto',
                position: 'absolute',
                right: 'var(--Card-padding)',
                top: 'calc(var(--Card-padding) - var(--Card-padding) / 2)',
                pointerEvents: 'auto',
                zIndex: 1000,
              }}
              transition={{ duration: transitionDurationS.Short }}
            >
              <CustomWidgetCloseAction
                setEditingWidget={setEditingWidget}
                editingWidget={editingWidget}
                forceStatic={(val) => forceStatic(id, val)}
              />
            </motion.div>
          )}
        </AnimatePresence>
        <GErrorBoundary key={editingWidget ? 'editing' : 'viewing'}>
          <Suspense fallback={<Loader fullHeight />}>
            {editingWidget ? (
              <SettingsComponent
                id={id}
                onFinishedEditing={() => {
                  setEditingWidget(false);
                  forceStatic(id, false);
                }}
                onRemovedWidget={async () => {
                  await onRemovedWidget();
                }}
              />
            ) : (
              <Box sx={{ overflow: 'auto', height: '100%' }}>{memoizedView}</Box>
            )}
          </Suspense>
        </GErrorBoundary>
      </Card>
    );
  }
);

import { ClickAwayListener } from '@mui/base';
import { Popper } from '@mui/base/Popper';
import { CalendarToday } from '@mui/icons-material';
import { ButtonGroup, FormControl, Stack, SvgIcon, useTheme } from '@mui/joy';
import IconButton from '@mui/joy/IconButton';
import type { Dayjs } from 'dayjs';
import { type ComponentProps, type FunctionComponent, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';

import { REPLAY_UNMASK_CLASS_NAME } from 'setup/initializeAll.ts';
import { canBeSelected, ensureDateRangeWithinBounds } from './dateRange.utils';
import DateRangeInputCalendar from './DateRangeInputCalendar';
import {
  defaultCalendarDateRanges,
  defaultQuickAccessDateRanges,
  type PredefinedRange,
} from '../../../predefinedDateRanges';
import GButton from '../GButton/GButton';
import InputLabel from '../InputLabel';

type PredefinedDateRangeButtonsWithCalendarProps = {
  defaultValue: PredefinedRange;
  alignRight?: boolean;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  onChange: (val: [Dayjs, Dayjs] | null) => void;
  label?: string;
  quickAccessDateRanges?: PredefinedRange[];
  calendarDateRanges?: PredefinedRange[];
  alignItems?: ComponentProps<typeof Stack>['alignItems'];
};

const PredefinedDateRangeButtonsWithCalendar: FunctionComponent<PredefinedDateRangeButtonsWithCalendarProps> = ({
  onChange,
  alignRight = true,
  minDate,
  maxDate,
  defaultValue,
  label,
  quickAccessDateRanges = defaultQuickAccessDateRanges,
  calendarDateRanges = defaultCalendarDateRanges,
  alignItems,
}) => {
  const [quickAccessRangeLabel, setQuickAccessRangeLabel] = useState<string | undefined>(defaultValue.label);
  const lastRangeSelected = useRef<[Dayjs, Dayjs] | null>(defaultValue.value());
  const anchorEl = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const theme = useTheme();
  return (
    <Stack
      direction="row"
      marginLeft={alignRight ? 'auto' : undefined}
      className={REPLAY_UNMASK_CLASS_NAME}
      alignItems={alignItems ?? 'center'}
    >
      <FormControl>
        {label && <InputLabel label={label} />}
        <Stack ref={anchorEl} direction="row" alignItems="center">
          <ButtonGroup>
            {quickAccessDateRanges.map((predefinedRange) => (
              <GButton
                sx={{
                  fontSize: theme.fontSize.xs,
                  padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
                  ...(quickAccessRangeLabel === predefinedRange.label && {
                    border: `1px solid ${theme.vars.palette.primary[300]}`,
                  }),
                }}
                key={predefinedRange.label}
                variant={quickAccessRangeLabel === predefinedRange.label ? 'soft' : 'outlined'}
                color="primary"
                onClick={(): void => {
                  const range = predefinedRange.value();
                  setQuickAccessRangeLabel(predefinedRange.label);
                  lastRangeSelected.current = range;
                  onChange(ensureDateRangeWithinBounds(minDate, maxDate, range));
                }}
                disabled={!canBeSelected(minDate, maxDate, predefinedRange.value?.())}
              >
                {predefinedRange.label}
              </GButton>
            ))}
            <IconButton
              color="primary"
              onClick={(): void => {
                setOpen(true);
              }}
            >
              <SvgIcon component={CalendarToday} size="sm" />
            </IconButton>
          </ButtonGroup>
        </Stack>
      </FormControl>
      {open && (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Popper anchorEl={anchorEl.current} placement="bottom-start" open>
            <DateRangeInputCalendar
              minDate={minDate}
              maxDate={maxDate}
              predefinedRanges={calendarDateRanges}
              defaultValue={lastRangeSelected.current}
              onConfirm={(newValue): void => {
                lastRangeSelected.current = newValue;
                setQuickAccessRangeLabel(undefined);
                setOpen(false);
                onChange(newValue);
              }}
              onCancel={(): void => setOpen(false)}
            />
          </Popper>
        </ClickAwayListener>
      )}
    </Stack>
  );
};

export default PredefinedDateRangeButtonsWithCalendar;

import { Stack } from '@mui/joy';
import { type ReactElement, useState } from 'react';
import { Select } from 'components/technical/inputs';

import { type CorrelationAsset, metricValues } from './Correlation.utils';
import HistoricalCorrelationSection from './HistoricalCorrelationSection';
import LatestCorrelationSection from './LatestCorrelationSection';

const CorrelationContainer = ({
  defaultAssets,
  supportedAssets,
}: {
  defaultAssets: CorrelationAsset[];
  supportedAssets: CorrelationAsset[];
}): ReactElement => {
  const [metric, setMetric] = useState(metricValues[0].value);

  return (
    <Stack spacing={1.5}>
      <Stack direction="row" justifyContent="space-between" alignSelf="flex-end">
        <Select
          label="Metric"
          value={metric}
          onChange={(value): void => setMetric(value)}
          options={metricValues}
          width="normal"
        />
      </Stack>
      <LatestCorrelationSection metric={metric} supportedAssets={supportedAssets} defaultAssets={defaultAssets} />
      <HistoricalCorrelationSection metric={metric} supportedAssets={supportedAssets} defaultAssets={defaultAssets} />
    </Stack>
  );
};

export default CorrelationContainer;

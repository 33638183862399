import { Link } from '@mui/joy';
import type { ReactElement } from 'react';

export const MsExcelCsvConversionDisclaimer = (): ReactElement => (
  <>
    Please note that opening CSV file in Microsoft Excel may automatically alter the format of columns containing dates
    and numerical values, depending on your system settings. To prevent this, you can adjust Excel's data conversion
    settings by following{' '}
    <Link
      href="https://techcommunity.microsoft.com/blog/microsoft365insiderblog/control-data-conversions-in-excel-for-windows-and-mac/4215336"
      target="_blank"
    >
      Control data conversion in Excel
    </Link>{' '}
    or import the data as text using{' '}
    <Link
      href="https://support.microsoft.com/en-us/office/import-or-export-text-txt-or-csv-files-5250ac4c-663c-47ce-937b-339e391393ba"
      target="_blank"
    >
      Import CSV as text
    </Link>
    .
  </>
);

import type React from 'react';
import { createContext, useEffect, useState, useCallback } from 'react';
import { TourGuideClient } from '@sjmc11/tourguidejs/src/Tour';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/joy';
import { useAuth } from 'UseAuth';
import { useTourGuideConfig, type TourGuideKey } from './useTourGuideConfig';
import { useUserSettingsQuery, useUpdateUserSettingsMutation, UserSettingsDocument } from 'generated/graphql';

export interface TourContextType {
  startTour: (featureId: TourGuideKey) => void;
  startTourManually: (featureId: TourGuideKey) => void;
  stopTour: () => void;
  restartTour: (featureId: TourGuideKey) => void;
  dismissTourPermanently: (featureId: TourGuideKey) => void;
  isTourRunning: boolean;
  activeFeatureId: TourGuideKey | null;
  availableTours: { featureId: TourGuideKey; title?: string; description?: string }[];
}

export const TourContext = createContext<TourContextType | undefined>(undefined);

export const TourProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const theme = useTheme();
  const location = useLocation();
  const { isAdmin } = useAuth();
  const tourGuideConfig = useTourGuideConfig();

  const [tourGuide, setTourGuide] = useState<TourGuideClient | null>(null);
  const [tourManuallyClosed, setTourManuallyClosed] = useState(false);
  const [activeFeatureId, setActiveFeatureId] = useState<TourGuideKey | null>(null);
  const [isTourRunning, setIsTourRunning] = useState(false);
  const [_, setTourStartedManually] = useState(false);

  const { data: settingsData, loading } = useUserSettingsQuery({ variables: { field: 'dismissedTours' } });
  const [updateUserSettings] = useUpdateUserSettingsMutation();
  const dismissedTours = settingsData?.management.userSettings ?? [];

  const cleanupTour = useCallback(() => {
    for (const el of Array.from(
      document.querySelectorAll(
        '.tg-dialog, .tg-backdrop, .tg-tooltip, .tg-dismiss-btn, .tg-restart-btn, #tour-highlight-circle'
      )
    )) {
      el?.parentNode?.removeChild(el);
    }
  }, []);

  const getMatchingTours = useCallback(() => {
    return Object.entries(tourGuideConfig)
      .filter(([, config]) => location.pathname.startsWith(config.metadata.startingRoute))
      .sort((a, b) => (a[1].metadata.runOrder ?? 0) - (b[1].metadata.runOrder ?? 0));
  }, [location.pathname, tourGuideConfig]);

  const onTourExit = useCallback(() => {
    cleanupTour();
    setIsTourRunning(false);
    setTourGuide(null);
    setActiveFeatureId(null);
    setTourStartedManually(false);
    setTourManuallyClosed(true);
  }, [cleanupTour]);

  const createTourInstance = useCallback(
    (featureId: TourGuideKey) => {
      const config = tourGuideConfig[featureId];
      if (!config) return null;

      const tour = new TourGuideClient({
        debug: !!isAdmin,
        showStepProgress: false,
        exitOnEscape: false,
        showStepDots: false,
        exitOnClickOutside: false,
        closeButton: true,
        completeOnFinish: false,
        hidePrev: true,
        progressBar: theme.vars.palette.primary[500],
        backdropColor: 'rgba(0, 0, 0, 0.5)',
        dialogZ: 100000,
        steps: [],
      });

      const steps = config.createSteps(tour).map((s) => ({
        ...s,
        target: typeof s.target === 'string' ? s.target : config.createSteps(tour)[0].target,
      }));

      tour.addSteps(steps);
      tour.onAfterExit(onTourExit);
      return tour;
    },
    [tourGuideConfig, isAdmin, theme, onTourExit]
  );

  const startTour = useCallback(
    (featureId: TourGuideKey) => {
      setTourManuallyClosed(false);
      const instance = createTourInstance(featureId);
      if (instance) {
        instance.activeStep = 0;
        setTourGuide(instance);
        instance.start();
        instance.refresh();
        setIsTourRunning(true);
        setActiveFeatureId(featureId);
      }
    },
    [createTourInstance]
  );

  const startTourManually = useCallback(
    (featureId: TourGuideKey) => {
      setTourStartedManually(true);
      startTour(featureId);
    },
    [startTour]
  );

  const stopTour = useCallback(() => {
    if (tourGuide && activeFeatureId) onTourExit();
  }, [tourGuide, activeFeatureId, onTourExit]);

  const restartTour = useCallback(
    (featureId: TourGuideKey) => {
      stopTour();
      setTimeout(() => startTourManually(featureId), 50);
    },
    [stopTour, startTourManually]
  );

  const dismissTourPermanently = useCallback(
    (featureId: TourGuideKey) => {
      updateUserSettings({
        variables: { settings: { dismissedTours: [...dismissedTours, featureId] } },
        refetchQueries: [UserSettingsDocument],
      }).catch(console.error);
      stopTour();
    },
    [dismissedTours, updateUserSettings, stopTour]
  );

  useEffect(() => {
    const handleDismiss = (e: Event) => {
      const detail = (e as CustomEvent).detail;
      if (detail?.featureId) dismissTourPermanently(detail.featureId);
    };

    const handleRestart = (e: Event) => {
      const detail = (e as CustomEvent).detail;
      if (detail?.featureId) restartTour(detail.featureId);
    };

    window.addEventListener('tour:dismiss', handleDismiss);
    window.addEventListener('tour:restart', handleRestart);
    return () => {
      window.removeEventListener('tour:dismiss', handleDismiss);
      window.removeEventListener('tour:restart', handleRestart);
    };
  }, [dismissTourPermanently, restartTour]);

  useEffect(() => {
    if (!isTourRunning || !activeFeatureId) return;
    const config = tourGuideConfig[activeFeatureId];
    const allowed = config?.metadata?.allowedRoutes ?? [config.metadata.startingRoute];
    const valid = allowed.some((route) => location.pathname.startsWith(route));
    if (!valid) stopTour();
  }, [location.pathname, isTourRunning, activeFeatureId, stopTour, tourGuideConfig]);

  useEffect(() => {
    if (!isAdmin || loading || isTourRunning || tourManuallyClosed) return;
    const firstAuto = getMatchingTours().find(
      ([, config]) => config.metadata.autoPlay && !dismissedTours.includes(config.metadata.featureId)
    );
    if (firstAuto) {
      const [featureId] = firstAuto;
      setTimeout(() => startTour(featureId as TourGuideKey), 500);
    }
  }, [isAdmin, loading, isTourRunning, tourManuallyClosed, getMatchingTours, dismissedTours, startTour]);

  const availableTours = getMatchingTours().map(([, config]) => ({
    featureId: config.metadata.featureId,
    title: config.metadata.title,
    description: config.metadata.description,
  }));

  return (
    <TourContext.Provider
      value={{
        startTour,
        startTourManually,
        stopTour,
        restartTour,
        dismissTourPermanently,
        isTourRunning,
        activeFeatureId,
        availableTours,
      }}
    >
      {children}
    </TourContext.Provider>
  );
};

import { formatCash, formatNumber, formatPercentage, type formatterForName } from 'components/formatter.utils';
import {
  type IAssetOptimizerResultQuery,
  IConstraintFormulation,
  type IPortfolioOptimizerResultQuery,
} from 'generated/graphql';

export type PortfolioResultInput = IPortfolioOptimizerResultQuery['optimization']['getOptimizationInput'];
export type AssetResultInput = IAssetOptimizerResultQuery['optimization']['getOptimizationInput'];
export type ResultInput = AssetResultInput | PortfolioResultInput;
export type PortfolioConstraints = NonNullable<ResultInput['portfolioConstraints']>;

interface FormatConstraintBoundInput {
  constraint: { lowerBound: number; upperBound: number };
  constraintFormulation: IConstraintFormulation;
  usdValue: boolean;
}
export function formatConstraintBound({
  constraint,
  constraintFormulation,
  usdValue,
}: FormatConstraintBoundInput): string {
  const format =
    constraintFormulation === IConstraintFormulation.Percentage
      ? formatPercentage
      : usdValue
        ? formatCash
        : formatNumber;
  return constraint.lowerBound === constraint.upperBound
    ? `${format(constraint.lowerBound)}`
    : `${format(constraint.lowerBound)} - ${format(constraint.upperBound)}`;
}

export function formatPortfolioConstraintBound(
  constraint: { lowerBound: number; upperBound: number },
  formatter: ReturnType<typeof formatterForName>
): string {
  return constraint.lowerBound === constraint.upperBound
    ? `equals ${formatter(constraint.lowerBound)}`
    : `between ${formatter(constraint.lowerBound)} and ${formatter(constraint.upperBound)}`;
}

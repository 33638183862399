import { AutocompleteOption, ListSubheader } from '@mui/joy';
import type { AutocompleteRenderOptionState } from '@mui/joy/Autocomplete';
import type { HTMLAttributes, ReactElement } from 'react';
import { useContext } from 'react';
import type { ListChildComponentProps } from 'react-window';

import { LISTBOX_PADDING_PX } from './renderRow.utils.ts';
import { VirtualizedListContext } from './VirtualizedListContext.tsx';
import { LoadingResultsItem, MoreResultsItem, NoResultsItem, TypeMoreResultsItem } from '../AutocompleteOptions.tsx';
import type { GOption } from '../GAutocomplete.tsx';

export type RowProps<T> = {
  elementProps: Omit<HTMLAttributes<HTMLLIElement>, 'color'>;
  option: GOption<T>;
  state?: AutocompleteRenderOptionState; // undefined for group options
};

export const renderRow = <T,>(props: ListChildComponentProps<RowProps<T>[]>): ReactElement => {
  const context = useContext(VirtualizedListContext);
  const row = props.data[props.index];
  const option = row.option;
  const finalStyles = {
    ...props.style,
    top: ((props.style.top as number) ?? 0) + LISTBOX_PADDING_PX,
  } as const;

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const { key, ...rowProps } = row.elementProps as any;
  if (option.type === 'regular') {
    const node = context.renderOption(props, option.value as T, row.state!);
    return (
      <AutocompleteOption {...rowProps} key={context.getOptionKey(option.value!)} style={finalStyles}>
        {node}
      </AutocompleteOption>
    );
  }

  if (option.type === 'loading') {
    return <LoadingResultsItem {...rowProps} style={finalStyles} />;
  }

  if (option.type === 'hasMoreResults') {
    return <MoreResultsItem {...rowProps} style={finalStyles} />;
  }

  if (option.type === 'hasNoResults') {
    return <NoResultsItem {...rowProps} style={finalStyles} />;
  }

  if (option.type === 'typeMore') {
    return <TypeMoreResultsItem {...rowProps} style={finalStyles} />;
  }

  return (
    <ListSubheader key={option.key} component="li" style={finalStyles}>
      {option.name}
    </ListSubheader>
  );
};

export const LISTBOX_ITEM_PADDING_PX = 8;

import type { StaticAutocompleteOption } from 'components/technical/inputs/Autocomplete/StaticSingleAutocomplete.props';
import type { IMultiFactor } from 'generated/graphql';

export const createMultifactorIdOptions = (
  multifactors: Pick<IMultiFactor, 'id' | 'name'>[]
): StaticAutocompleteOption<number>[] => {
  return multifactors.map((mf) => {
    return {
      searchText: mf.name,
      label: mf.name,
      key: `${mf.id}`,
      value: mf.id,
    };
  });
};

export const createMultifactorOptions = <
  T extends { maxFactors: number; multifactor: Pick<IMultiFactor, 'id' | 'name'> },
>(
  multifactors: T[]
): StaticAutocompleteOption<T>[] => {
  return multifactors.map((mf) => {
    return {
      searchText: mf.multifactor.name,
      label: mf.multifactor.name,
      key: `${mf.multifactor.id}`,
      value: mf,
    };
  });
};

import { calculateAllSubAccountIds } from '../fund/SubFundService.tsx';
import type { IOpenPositionsSummaryQuery } from '../../../generated/graphql.tsx';

export const calculateSubAccountToSubFundsMapper = (
  dimension: string | null,
  subFunds: IOpenPositionsSummaryQuery['portfolio']['subFunds']['list']
): ((subAccountId: string) => number | null) => {
  if (!dimension) {
    return () => null;
  }

  const map = new Map(
    subFunds
      .filter((subFund) => subFund.dimension === dimension)
      .flatMap((subFund) => calculateAllSubAccountIds(subFund).map((subAcc) => [subAcc, subFund.id]))
  );

  return (subAccountId: string) => map.get(subAccountId) ?? null;
};

import { useWidgetState } from 'components/management/customDashboard/CustomWidgetContext.tsx';
import type { PortfolioExposureState } from './PortfolioExposureWidgetSettings.tsx';
import { calculateDefaultPresetRange } from 'components/management/TilesDateRangeSelector.tsx';
import { ExposureChart } from './ExposureChart.tsx';

export const PerformanceExposureWidgetView = ({ id }: { id: string }) => {
  const initialState = useWidgetState<PortfolioExposureState>(id);
  const range = calculateDefaultPresetRange(initialState.predefinedDateRange);
  return <ExposureChart dateRange={range.value()} fullHeight dataType={initialState.dataType} />;
};

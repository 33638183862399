import isNil from 'lodash/fp/isNil';
import { type Schema, ValidationError } from 'yup';
import * as yup from 'yup';
import { isValidNumber } from '../../../../../number.utils.ts';
import { bignumber } from 'mathjs';
import type { NotVerifiedAssetWithId } from '../../../../../market/asset/AssetService.tsx';
import type { IAsset } from '../../../../../../generated/graphql.tsx';

export interface InitialAssetsStepInput {
  givenPortfolio: Record<
    string,
    {
      asset: NotVerifiedAssetWithId & Pick<IAsset, 'symbol' | 'name' | 'type'>;
      amount: string | null;
    }
  >;
}

export interface InitialAssetsStepOutput {
  givenPortfolio: Record<
    string,
    {
      asset: NotVerifiedAssetWithId & Pick<IAsset, 'symbol' | 'name' | 'type'>;
      amount: string;
    }
  >;
}

export const isValidGivenValue = (amount: unknown): { valid: boolean; error?: string } => {
  if (isNil(amount)) {
    return {
      valid: true,
    };
  }

  if (!isValidNumber(amount)) {
    return { valid: false, error: 'Value must be a valid number' };
  }

  const val = bignumber(amount);
  if (val.isNegative()) {
    return { valid: false, error: 'Value must be positive' };
  }

  return {
    valid: true,
  };
};

export const schema: Schema<unknown> = yup.object({
  givenPortfolio: yup
    .mixed()
    .required()
    .test('test', 'Test failed', (value, ctx): boolean | ValidationError => {
      if (isNil(value)) {
        return true;
      }

      const errors: ValidationError[] = [];
      let foundNonNilAmount = false;
      for (const [key, assetInfo] of Object.entries(value)) {
        if (isNil(assetInfo)) {
          continue;
        }

        const castAssetInfo = assetInfo as InitialAssetsStepInput['givenPortfolio'][string];
        foundNonNilAmount = true;
        const fieldKey = `givenPortfolio.${key}.amount`;
        const result = isValidGivenValue(castAssetInfo.amount);
        if (!result.valid) {
          errors.push(
            ctx.createError({
              message: result.error,
              path: fieldKey,
            })
          );
        }
      }

      if (!foundNonNilAmount) {
        return ctx.createError({
          message: 'At least one asset is required',
        });
      }

      if (errors.length > 0) {
        return new ValidationError(errors);
      }

      return true;
    }),
});

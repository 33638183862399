import HighChartsContainer from '../../../technical/charts/HighChartsWrapper/HighChartsWrapper.tsx';
import { type ITimeWeightedReturnQuery, useTimeWeightedReturnQuery } from '../../../../generated/graphql.tsx';
import type Highcharts from 'highcharts';
import {
  dateTimeAxisFormat,
  dateTimeExportFormat,
  generateZones,
  getHighchartColorWithOpacity,
  type HighchartSeries,
  highChartsNegativeColorIndex,
  highChartsPositiveColorIndex,
  tooltipFormat,
} from '../../../technical/charts/HighChartsWrapper/Highchart.utils.ts';
import dayjs, { type Dayjs } from 'dayjs';
import { bignumber } from 'mathjs';
import { formatPercentage } from '../../../formatter.utils.ts';
import { useFinalColorScheme } from '../../../../useFinalColorScheme.ts';
import isNil from 'lodash/fp/isNil';
import { convertDateRangeToSinceToDate } from '../../../technical/inputs/date/dateRange.utils.ts';
import { useSubAccountAssetFilters } from '../../../technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters.tsx';

const calculateOptions = (): Highcharts.Options => {
  return {
    ...dateTimeAxisFormat,
    ...dateTimeExportFormat('profit-loss'),
    ...tooltipFormat,
    yAxis: {
      labels: {
        format: '{value:.2f}%',
      },
    },
    plotOptions: {
      area: {
        fillOpacity: 0.4, // Ensure background is visible
        marker: {
          enabled: false,
        },
      },
    },
  };
};

const calculateChartData = (colorScheme: 'light' | 'dark', data: ITimeWeightedReturnQuery): HighchartSeries[] => {
  return [
    {
      name: 'Time weighted rate of return (TWR)',
      // Because the type is not accepted by highcharts but it works
      // @ts-expect-error
      type: 'areaspline',
      data: data.portfolio.portfolioTimeWeightedReturns.values.map(({ date, return: returns }) => {
        return {
          x: dayjs.utc(date.toString()).valueOf(),
          y: bignumber(returns.weight).toNumber() * 100,
          textValue: formatPercentage(bignumber(returns.weight).toNumber()),
        };
      }),
      fillColor: {
        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 }, // Gradient from top to bottom
        stops: [
          [0, getHighchartColorWithOpacity(colorScheme, highChartsPositiveColorIndex, 1)], // Stronger at the top
          [0.4, getHighchartColorWithOpacity(colorScheme, highChartsPositiveColorIndex, 0.3)], // Stronger at the top
          [1, getHighchartColorWithOpacity(colorScheme, highChartsPositiveColorIndex, 0)], // Fade to transparent
        ],
      },
      zones: generateZones(colorScheme, highChartsPositiveColorIndex, highChartsNegativeColorIndex),
    },
  ];
};

const ProfitLossChart = ({ dateRange, fullHeight }: { dateRange: [Dayjs, Dayjs] | null; fullHeight: boolean }) => {
  const { subAccountAssetFilters } = useSubAccountAssetFilters();
  const colorScheme = useFinalColorScheme();

  // Twr endpoint filters out snapshots by 'since' and 'to' parameters.
  // This unfortunately means that the return for the first day is always 0,
  // we want to change this behaviour to actually show the return compared to the previous day, so
  // we need to subtract 1 day from since. 'To' parameter should stay the same

  const shiftedSinceDateRange: null | [Dayjs, Dayjs] = isNil(dateRange)
    ? null
    : [dateRange[0].subtract(1, 'day'), dateRange[1]];
  const queryOutput = useTimeWeightedReturnQuery({
    variables: {
      ...convertDateRangeToSinceToDate(shiftedSinceDateRange),
      subAccountAssetFilters,
    },
  });

  return (
    <HighChartsContainer<ITimeWeightedReturnQuery>
      {...queryOutput}
      height={fullHeight ? 'fullHeight' : undefined}
      calculateOptions={calculateOptions}
      calculateChartData={(data) => calculateChartData(colorScheme, data)}
    />
  );
};

export default ProfitLossChart;

import type { Theme } from '@mui/joy';
import type { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import NotificationsDrawer from 'components/copilot/newsNotification/NotificationsDrawer';
import { LoginForm } from 'components/login';
import SubAccountAssetFilterDrawer from 'components/technical/SubAccountAssetFilterDrawer/SubAccountAssetFiltersDrawer.tsx';

import { type DrawerConfig, DrawerType } from './Drawer.types';
import FiltersDrawer from '../../market/asset/filters/FiltersDrawer';
import { canShowQuantInsightsFilters } from '../../market/asset/filters/UseShowQuantInsightsFilters.tsx';
import type { Location } from 'react-router';
import { canShowSubAccountAssetFilter } from '../SubAccountAssetFilterDrawer/UseShowSubAccountAssetFilters.tsx';
import ReportPresetsDrawer, { type ReportPresetsDrawerProps } from '../grids/ReportPresetsDrawer.tsx';
import GDrawerHeader from '../GDrawer/GDrawerHeader.tsx';
import AgentDrawer from '../../copilot/agent/AgentDrawer.tsx';

// joy ui drawers have
export const drawerContentSxProps = (theme: Theme): SystemStyleObject<Theme> => {
  const containerSpacing = theme.spacing(2);
  return {
    padding: containerSpacing,
    minWidth: '0',
    width: 'var(--drawer-width)',
    maxWidth: '100vw', // make drawer narrower, so that sidebar is visible
  } as const;
};

export const drawerContent: Record<DrawerType, DrawerConfig<{ open: boolean }>> = {
  [DrawerType.Login]: {
    children: () => <LoginForm />,
    persistent: false,
  },
  [DrawerType.QuantInsightsFilters]: {
    children: ({ open }) => (
      <>
        <GDrawerHeader>Filters</GDrawerHeader>
        <FiltersDrawer open={open} />
      </>
    ),
    persistent: true,
    contentSx: drawerContentSxProps,
    shouldHideDrawer: (loc: Location): boolean => {
      return !canShowQuantInsightsFilters(loc);
    },
  },
  [DrawerType.Notifications]: {
    children: () => (
      <>
        <GDrawerHeader>Notifications</GDrawerHeader>
        <NotificationsDrawer />
      </>
    ),
    persistent: true,
    contentSx: drawerContentSxProps,
  },
  [DrawerType.SubAccountAssetFilter]: {
    children: ({ open }) => (
      <>
        <GDrawerHeader>Filters</GDrawerHeader>
        <SubAccountAssetFilterDrawer open={open} />
      </>
    ),
    persistent: true,
    contentSx: drawerContentSxProps,
    shouldHideDrawer: (loc: Location): boolean => {
      return !canShowSubAccountAssetFilter(loc);
    },
  },
  [DrawerType.Presets]: {
    // @ts-expect-error hack to handle contravariant type
    children: ({ drawerKey, ...props }: ReportPresetsDrawerProps & { open: boolean }) => {
      return (
        <>
          <GDrawerHeader>Report presets</GDrawerHeader>
          <ReportPresetsDrawer key={drawerKey} {...props} />
        </>
      );
    },
    persistent: true,
    contentSx: drawerContentSxProps,
  },
  [DrawerType.Agent]: {
    children: AgentDrawer,
    persistent: true,
    contentSx: {
      padding: 0,
      minWidth: '0',
      width: '50vw',
      maxWidth: '100vw', // make drawer narrower, so that sidebar is visible
    },
  },
};

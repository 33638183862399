import { useSubFundPnlSuspenseQuery } from '../../../../generated/graphql.tsx';
import { isNil, sortBy, uniqBy } from 'lodash/fp';
import { formatISODate } from '../../../formatter.utils.ts';
import dayjs, { type Dayjs } from 'dayjs';
import { Card, Grid, Stack } from '@mui/joy';
import { defaultRowSpacing } from '../../../StackSpacing.ts';
import SubFundPerformanceLineChart from './chart/SubFundPerformanceLineChart.tsx';
import SubFundPnlGrid from './grid/SubFundPnlGrid.tsx';
import type { ITimeWeightedReturnType } from '../../../../scalars.ts';
import Message from '../../../technical/Message.tsx';
import { parseUtcDate } from '../../../date.utils.ts';
import HeaderBar from '../../../technical/HeaderBar/HeaderBar.tsx';
import { TwoThirdsLayout } from 'components/TwoThirdsLayout.tsx';
import { SubFundLastDayBarChart } from './chart/SubFundLastDayBarChart.tsx';
import { type ReactElement, type ReactNode, Suspense } from 'react';
import Loader from 'components/technical/Loader/Loader.tsx';
import { UserSettings } from '../../../management/UserSettings.types.ts';
import SubFundBalanceChart from './chart/SubFundBalanceChart.tsx';

const LAYOUT_SPACING = 4;
export interface SubFundTwrRow {
  subFund: {
    id: number;
    name: string;
  };
  twr: ITimeWeightedReturnType[];
}

const gridFullWidthItemProps = {
  sm: 12,
  md: 12,
} as const;

export const findTwrForDate = <SubFund,>(
  twrRows: {
    subFund: SubFund;
    twr: ITimeWeightedReturnType[];
  }[],
  date: Dayjs
): {
  subFund: SubFund;
  twr: ITimeWeightedReturnType;
}[] => {
  return twrRows
    .map((row) => ({
      ...row,
      twr: row.twr.find((twr) => parseUtcDate(twr.date).isSame(date)),
    }))
    .filter(
      (
        row
      ): row is Omit<(typeof twrRows)[number], 'twr'> & {
        twr: ITimeWeightedReturnType;
      } => !isNil(row.twr)
    );
};

export const filterTwrRowsByAsset = ({
  rows,
  asset,
  defaultTwrIfNoAsset,
}: {
  rows: {
    subFund: { id: number; name: string; referenceAsset: { id: string } };
    referenceAssetReturns: { twr: ITimeWeightedReturnType[] };
    usdReturns: { twr: ITimeWeightedReturnType[] };
  }[];
  asset: { id: string } | null | undefined;
  defaultTwrIfNoAsset: 'reference' | 'usd';
}) => {
  return rows
    .map((row) => {
      if (isNil(asset)) {
        return {
          subFund: row.subFund,
          twr: defaultTwrIfNoAsset === 'reference' ? row.referenceAssetReturns.twr : row.usdReturns.twr,
        };
      }

      if (row.subFund.referenceAsset.id !== asset.id) {
        return null;
      }

      return {
        subFund: row.subFund,
        twr: row.referenceAssetReturns.twr,
      };
    })
    .filter((rows) => !isNil(rows));
};

const TwoChartAssetLayout = ({
  title,
  left,
  right,
}: {
  title: string;
  left: ReactNode;
  right: ReactNode;
}): ReactElement => {
  return (
    <Grid {...gridFullWidthItemProps}>
      <Card>
        <TwoThirdsLayout title={title} spacing={LAYOUT_SPACING} withDivider left={left} right={right} />
      </Card>
    </Grid>
  );
};

export const getTwrDatesByIndex = (
  twrRows: {
    twr: ITimeWeightedReturnType[];
  }[],
  index: number
): dayjs.Dayjs[] => {
  return twrRows
    .map((row) => {
      return row.twr.at(index)?.date;
    })
    .filter((date) => !isNil(date))
    .map((date) => parseUtcDate(date));
};

// when given asset is provided, we show data only for it as a reference asset, otherwise we display data for usd
const AssetSection = ({
  asset,
  subFundTwr,
  subFundIdToColorIndex,
}: {
  asset?: { id: string; symbol: string };
  subFundTwr: {
    subFund: {
      id: number;
      name: string;
      referenceAsset: {
        id: string;
      };
    };
    referenceAssetReturns: { twr: ITimeWeightedReturnType[] };
    usdReturns: { twr: ITimeWeightedReturnType[] };
  }[];
  subFundIdToColorIndex: Map<number, number>;
}) => {
  const twrRows = filterTwrRowsByAsset({ rows: subFundTwr, asset, defaultTwrIfNoAsset: 'usd' });
  const performanceTwrRows = filterTwrRowsByAsset({ rows: subFundTwr, asset, defaultTwrIfNoAsset: 'reference' });

  const twrFirstDate = getTwrDatesByIndex(twrRows, 0);
  const twrLastDate = getTwrDatesByIndex(twrRows, -1);

  if (twrLastDate.length === 0) {
    return <Message>No twr data</Message>;
  }

  const maxTwrDate = dayjs.max(twrLastDate);
  const assetSymbol = asset?.symbol ?? 'USD';

  // TODO: fix date calculation - different subfund may have different starting dates
  const firstDayLabel = dayjs(twrFirstDate[0]).format('DD-MM-YYYY');
  // TODO: fix date calculation - different subfund may have different ending dates
  const lastDayLabel = dayjs(twrLastDate[0]).format('DD-MM-YYYY');

  const lastDayPerformance = findTwrForDate(performanceTwrRows, maxTwrDate!);
  const lastDayTwr = findTwrForDate(twrRows, maxTwrDate!);

  return (
    <Grid container gap={4}>
      <TwoChartAssetLayout
        title={'Performance [%]'}
        left={
          <SubFundPerformanceLineChart
            title={`from ${firstDayLabel} to ${lastDayLabel}`}
            data={performanceTwrRows}
            twrField={'weight'}
          />
        }
        right={<SubFundLastDayBarChart data={lastDayPerformance} twrField={'weight'} />}
      />
      <TwoChartAssetLayout
        title={`P&L [${assetSymbol}]`}
        left={
          <SubFundPerformanceLineChart
            title={`from ${firstDayLabel} to ${lastDayLabel}`}
            data={twrRows}
            twrField={'value'}
          />
        }
        right={<SubFundLastDayBarChart data={lastDayTwr} twrField={'value'} />}
      />
      <TwoChartAssetLayout
        title={`Balance [${assetSymbol}]`}
        left={
          <SubFundPerformanceLineChart
            title={`from ${firstDayLabel} to ${lastDayLabel}`}
            data={twrRows}
            twrField={'balance'}
          />
        }
        right={
          <SubFundBalanceChart
            data={lastDayTwr}
            subFundIdToColorIndex={subFundIdToColorIndex}
            title={`Last day: ${lastDayLabel}`}
          />
        }
      />
    </Grid>
  );
};

const SubFundPnlResult = ({ dimension, startDate }: { dimension: string | null; startDate: Dayjs | null }) => {
  const { data } = useSubFundPnlSuspenseQuery({
    variables: {
      dimensions: isNil(dimension) ? [] : [dimension],
      since: isNil(startDate) ? null : formatISODate(startDate),
    },
  });

  const subFundRows = data.portfolio.subFundsTimeWeightedReturns.subFunds;
  const uniqueSubFunds = uniqBy(
    (subFund) => subFund.id,
    subFundRows.map((row) => row.subFund)
  );
  const allSubFundsSorted = sortBy((subFund) => subFund.name.toLowerCase(), uniqueSubFunds);
  const subFundToColorIndex = new Map(allSubFundsSorted.map((subFund, index) => [subFund.id, index]));
  const idToReferenceAsset = new Map(
    subFundRows.map((row) => [row.subFund.referenceAsset.id, row.subFund.referenceAsset])
  );
  const sortedReferenceAssets = sortBy((asset) => asset.symbol.toLowerCase(), Array.from(idToReferenceAsset.values()));
  return (
    <Stack spacing={defaultRowSpacing}>
      <SubFundPnlGrid data={subFundRows} presetKey={UserSettings.SubFundPnlPresets} />
      <Stack>
        <Suspense fallback={<Loader />}>
          <HeaderBar title={'All sub-funds'} />
          <AssetSection subFundTwr={subFundRows} subFundIdToColorIndex={subFundToColorIndex} />
        </Suspense>
      </Stack>

      {sortedReferenceAssets.map((asset) => (
        <div key={asset.id}>
          <HeaderBar title={`${asset.symbol} P&L`} />
          <AssetSection asset={asset} subFundTwr={subFundRows} subFundIdToColorIndex={subFundToColorIndex} />
        </div>
      ))}
    </Stack>
  );
};

export default SubFundPnlResult;

import { type CellContext, type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import type { FunctionComponent, ReactNode } from 'react';
import GTable from 'components/technical/GTable/GTable.tsx';
import { useTablePagePaginator } from 'components/technical/GTable/UseTablePaginator.tsx';
import Loader from 'components/technical/Loader/Loader.tsx';
import Message from 'components/technical/Message';
import { INotificationStatus, useNotificationsQuery } from 'generated/graphql';

import type { NotificationItem } from './NotificationItem.ts';
import NotificationTile from './NotificationTile';

const NotificationList: FunctionComponent<{
  showUnreadOnly: boolean;
  defaultPageSize: number;
  hideRowsPerPage: boolean;
}> = ({ showUnreadOnly, defaultPageSize, hideRowsPerPage }) => {
  const { tablePaginator, page } = useTablePagePaginator({ defaultPageSize: defaultPageSize });

  const { data, loading, error } = useNotificationsQuery({
    variables: {
      pageLimit: page,
      status: showUnreadOnly ? INotificationStatus.Delivered : undefined,
    },
  });

  if (loading) {
    return <Loader />;
  }

  if (!data) {
    return <Message>Missing notifications</Message>;
  }

  const tableData = data.notification.list.data;

  const columnHelper = createColumnHelper<NotificationItem>();

  const columns: ColumnDef<NotificationItem>[] = [
    columnHelper.display({
      header: 'Main',
      cell: (ctx: CellContext<NotificationItem, unknown>): ReactNode => {
        return <NotificationTile notification={ctx.row.original} variant={'plain'} />;
      },
    }),
  ];

  return (
    <GTable
      hideBorders
      hideShadow
      hidePadding
      hideRowsPerPage={hideRowsPerPage}
      error={error}
      columns={columns}
      data={tableData}
      pagePaginator={tablePaginator}
      totalResults={data.notification.list.pageInfo.totalResults}
      hideHeaders
    />
  );
};

export default NotificationList;

import { Stack, Typography } from '@mui/joy';
import { type ReactElement, type ReactNode, Suspense } from 'react';

import { shellClass, shellPadding } from './PageShell.utils.ts';
import Loader from '../Loader/Loader.tsx';

const PageShell = ({
  children,
  title,
  px = shellPadding,
  hideBottomPadding = false,
}: {
  children: ReactNode;
  title?: string;
  px?: number;
  hideBottomPadding?: boolean;
}): ReactElement => {
  return (
    <Stack height="100%" px={px}>
      <Suspense fallback={<Loader />}>
        <Stack spacing={0} flexGrow={1} height="100%" py={1} className={shellClass}>
          {title && (
            <Typography level="h2" pb={hideBottomPadding ? 0 : 2}>
              {title}
            </Typography>
          )}
          {children}
        </Stack>
      </Suspense>
    </Stack>
  );
};

export default PageShell;

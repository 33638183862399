import type { CustomWidgetDescription } from './CustomWidgetDescription.types.ts';
import { openPositionWidgets } from '../../portfolio/openPositions/widget.tsx';
import { attributionWidget } from '../../portfolio/profitLoss/attributionOverview/widget.tsx';
import { profitLossWidget } from '../../portfolio/profitLoss/overview/widget.tsx';
import { exposureWidget } from '../../portfolio/profitLoss/exposure/widget.tsx';
import { dailyPnlWidget } from '../../portfolio/profitLoss/dailyPnl/widget.tsx';
import { portfolioTileWidgets } from '../../portfolio/dashboard/tiles/widget.tsx';
import { currentExposureWidget } from '../../portfolio/dashboard/sunburst/widget.tsx';
import { subFundWidgets } from '../../portfolio/profitLoss/subFund/widget.tsx';

export const widgetDescriptions: CustomWidgetDescription[] = [
  ...openPositionWidgets,
  profitLossWidget,
  exposureWidget,
  attributionWidget,
  dailyPnlWidget,
  ...portfolioTileWidgets,
  currentExposureWidget,
  ...subFundWidgets,
];

export const getWidgetDescriptionByType = (type: string): CustomWidgetDescription => {
  const foundWidgetDescription = widgetDescriptions.find((desc) => desc.type === type);
  if (!foundWidgetDescription) {
    throw new Error(`Cannot find widget with type ${type}`);
  }

  return foundWidgetDescription;
};

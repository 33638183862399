import { useForm } from 'react-hook-form';
import gYupResolver from '../../technical/form/gYupResolver.ts';
import * as yup from 'yup';
import { updateWidgetState, useWidgetState } from './CustomWidgetContext.tsx';
import { UserSettingsDocument, useUpdateUserSettingsMutation } from '../../../generated/graphql.tsx';
import WidgetSettings from './WidgetSettings.tsx';

const schema = yup.object({
  title: yup.string().required(),
});

interface FormState {
  title: string;
}

export const AgGridTitleSettings = ({
  id,
  onFinishedEditing,
  onRemovedWidget,
  presetKey,
}: {
  id: string;
  onFinishedEditing: () => void;
  onRemovedWidget: () => Promise<void>;
  presetKey: string;
}) => {
  const initialState = useWidgetState(id);
  const updater = updateWidgetState();
  const [updateUserSettings] = useUpdateUserSettingsMutation();

  const methods = useForm<FormState>({
    resolver: gYupResolver(schema),
    defaultValues: {
      ...initialState,
    },
  });

  const handleFormSubmit = async (input: FormState): Promise<void> => {
    await updater({
      id,
      state: input,
      updateBackend: true,
    });

    onFinishedEditing();
  };

  return (
    <WidgetSettings
      methods={methods}
      handleFormSubmit={handleFormSubmit}
      onRemovedWidget={async () => {
        await updateUserSettings({
          variables: {
            settings: {
              [presetKey]: null,
            },
          },
          refetchQueries: [UserSettingsDocument],
        });

        await onRemovedWidget();
      }}
    />
  );
};

import { ListItemDecorator, type VariantProp } from '@mui/joy';
import type { ReactElement, ReactNode } from 'react';

import DialogButton from './DialogButton';
import { useFeedback } from '../../Feedback/UseFeedback.tsx';
import ConfirmationDialog from '../../form/dialog/ConfirmationDialog';
import { TrashBin } from '../../icons';

import { DialogMenuItem } from '../../DialogDropdown/DialogMenuItem.tsx';

export const DeleteMenuItem = ({
  deletedMessage,
  confirmationMessage,
  deleteItem,
}: {
  deletedMessage: ReactNode;
  confirmationMessage: ReactNode;
  deleteItem: () => Promise<unknown>;
}): ReactElement => {
  const { showSuccessMessage, showGraphqlError } = useFeedback();
  return (
    <DialogMenuItem
      color={'danger'}
      variant={'soft'}
      renderDialog={({ onClose }): ReactElement => (
        <ConfirmationDialog
          onClose={onClose}
          onApprove={async () => {
            try {
              await deleteItem();
              showSuccessMessage(deletedMessage);
            } catch (e) {
              showGraphqlError(e);
            }
            onClose();
          }}
        >
          {confirmationMessage}
        </ConfirmationDialog>
      )}
    >
      <ListItemDecorator>
        <TrashBin fontSize="md" />
      </ListItemDecorator>
      Remove
    </DialogMenuItem>
  );
};

// use DeleteMenuItem when rendering buttons in a menu. Joyui changes font weight of menu items
const DeleteDialogButton = ({
  dataTour,
  deletedMessage,
  confirmationMessage,
  deleteItem,
  onCompleted = (): Promise<void> => Promise.resolve(),
  length = 'long',
  variant = 'soft',
}: {
  dataTour?: string;
  deletedMessage: ReactNode;
  confirmationMessage: ReactNode;
  onCompleted?: () => Promise<unknown>;
  deleteItem: () => Promise<unknown>;
  length?: 'long' | 'short';
  variant?: VariantProp;
}): ReactElement => {
  const { showSuccessMessage, showGraphqlError } = useFeedback();

  return (
    <DialogButton
      data-tour={dataTour}
      color="neutral"
      variant={variant}
      paddingVariant={length}
      renderDialog={({ onClose }): ReactElement => (
        <ConfirmationDialog
          onClose={onClose}
          onApprove={async () => {
            try {
              await deleteItem();
              showSuccessMessage(deletedMessage);

              onCompleted();
            } catch (e) {
              showGraphqlError(e);
            }
            onClose();
          }}
        >
          {confirmationMessage}
        </ConfirmationDialog>
      )}
      startDecorator={<TrashBin fontSize="md" />}
    >
      {length === 'long' ? 'Remove' : ''}
    </DialogButton>
  );
};

export default DeleteDialogButton;

import { Card, Stack } from '@mui/joy';
import type { Dayjs } from 'dayjs';
import { type FunctionComponent, useState } from 'react';
import { parseUtcDate } from 'components/date.utils';
import { getDefaultRange } from 'components/predefinedDateRanges.ts';
import { defaultRowSpacing } from 'components/StackSpacing.ts';
import ActionsHeaderBar from 'components/technical/actions/ActionsHeaderBar';
import { Select } from 'components/technical/inputs';
import StaticMultiAutocomplete from 'components/technical/inputs/Autocomplete/StaticMultiAutocomplete.tsx';
import PredefinedDateRangeButtonsWithCalendar from 'components/technical/inputs/date/PredefinedDateRangeButtonsWithCalendar.tsx';
import type { ISupportedDateTimeWindow } from 'generated/graphql';

import { DEFAULT_CLUSTER } from './../attribution/PerformanceAttribution.tsx';
import { getClusterOptions } from '../../../market/asset/groups/GroupService.ts';
import PerformanceAttributionChart, {
  type AttributionReportSide,
  attributionSideOptions,
} from './PerformanceAttributionChart.tsx';

export const ATTRIBUTION_CHART_TITLE = 'Performance attribution';

export type PerformanceAttributionChartProps = {
  supportedTimeWindow: Pick<ISupportedDateTimeWindow, 'since' | 'until'> | undefined | null;
  supportedClusters: string[];
};

const PerformanceAttributionChartWrapper: FunctionComponent<PerformanceAttributionChartProps> = ({
  supportedClusters,
  supportedTimeWindow,
}) => {
  const classificationOptions = getClusterOptions(supportedClusters);

  const defaultDateRange = getDefaultRange();
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs] | null>(defaultDateRange.value);
  const [sides, setSides] = useState<AttributionReportSide[]>(['total']);

  const [classification, setClassification] = useState<string>(DEFAULT_CLUSTER);

  return (
    <Stack spacing={1.5}>
      <ActionsHeaderBar title={ATTRIBUTION_CHART_TITLE} />
      <Card>
        <Stack spacing={1.5}>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-end" spacing={defaultRowSpacing}>
            <Select
              value={classification}
              onChange={(value): void => setClassification(value)}
              options={classificationOptions}
              width="minContent"
            />
            <StaticMultiAutocomplete
              width="xl2"
              onChange={setSides}
              value={sides}
              limitTags={2}
              options={attributionSideOptions}
            />
            <PredefinedDateRangeButtonsWithCalendar
              {...(supportedTimeWindow && {
                minDate: parseUtcDate(supportedTimeWindow.since),
                maxDate: parseUtcDate(supportedTimeWindow.until),
              })}
              defaultValue={defaultDateRange}
              onChange={(val): void => setDateRange(val)}
            />
          </Stack>
          <Stack spacing={2}>
            {attributionSideOptions
              .map((opt) => opt.value)
              .map((side) =>
                sides.includes(side) ? (
                  <PerformanceAttributionChart
                    key={`${classification}-${side}`}
                    dateRange={dateRange}
                    side={side}
                    classification={classification}
                  />
                ) : null
              )}
          </Stack>
        </Stack>
      </Card>
    </Stack>
  );
};

export default PerformanceAttributionChartWrapper;

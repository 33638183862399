import { Card, Grid, Stack, Typography } from '@mui/joy';
import dayjs from 'dayjs';
import { bignumber } from 'mathjs';
import type { FunctionComponent, ReactElement } from 'react';
import { formatNumber, formatPercentage } from 'components/formatter.utils';
import {
  dateTimeAxisFormat,
  dateTimeExportFormat,
  type HighchartSeries,
  tooltipFormat,
} from 'components/technical/charts/HighChartsWrapper/Highchart.utils';
import HighChartsContainer from 'components/technical/charts/HighChartsWrapper/HighChartsWrapper';
import HeaderBar from 'components/technical/HeaderBar/HeaderBar';
import SectionColumn from 'components/technical/layout/Column/SectionColumn';
import Tile, { TileContent } from 'components/technical/Tile/Tile';
import { type IStrategyPerformanceQuery, useStrategyPerformanceQuery } from 'generated/graphql';
import type Highcharts from 'highcharts';

const PerformanceTile = ({ title, value }: { title: string; value: string }): ReactElement => {
  return (
    <Grid xs={12} sm={4} md={2} lg={2}>
      <Tile fullHeight>
        <TileContent title={title} alignment="space-between">
          <Stack justifyContent="center" width="100%" flexWrap="wrap">
            <Typography level={'h3'}>{value}</Typography>
          </Stack>
        </TileContent>
      </Tile>
    </Grid>
  );
};

const strategyMetrics = {
  CUMULATIVE_RETURN: { name: 'Cumulative return', format: 'percentage' },
  RETURNS_YTD: { name: 'Returns YTD', format: 'percentage' },
  RETURNS_30D: { name: 'Returns 30D', format: 'percentage' },
  ASSETS_TRADED: { name: 'Assets traded', format: 'number' },
  SHARPE_RATIO: { name: 'Sharpe ratio (Annualized)', format: 'number' },
};

const calculateOptions = (): Highcharts.Options => {
  return {
    ...dateTimeAxisFormat,
    ...dateTimeExportFormat('strategy-performance'),
    ...tooltipFormat,
    yAxis: {
      labels: {
        formatter: ({ value }) => formatPercentage(bignumber(value).toNumber()),
      },
      title: {
        text: undefined,
      },
    },

    plotOptions: {
      area: {
        threshold: Number.NEGATIVE_INFINITY, // By default area starts from 0, with this prop, Y axis scales between Y extremes
        fillOpacity: 0.5,
      },
    },
  };
};

const calculateChartData = (data: IStrategyPerformanceQuery): HighchartSeries[] => {
  return [
    {
      data: data.strategy.cumulativeReturns.backtest.map((row) => ({
        x: dayjs.utc(row.time).valueOf(),
        y: bignumber(row.value).toNumber(),
        textValue: formatPercentage(bignumber(row.value).toNumber()),
      })),

      name: 'Backtest performance',
      type: 'area',
    },
    {
      data: data.strategy.cumulativeReturns.live.map((row) => ({
        x: dayjs.utc(row.time).valueOf(),
        y: bignumber(row.value).toNumber(),
        textValue: formatPercentage(bignumber(row.value).toNumber()),
      })),
      name: 'Live performance',
      type: 'area',
    },
  ];
};

const StrategyPerformanceChart: FunctionComponent<{ strategy: Label }> = ({ strategy }) => {
  const queryOutput = useStrategyPerformanceQuery({ variables: { label: strategy } });

  const metrics = queryOutput.data?.strategy.strategyPerformanceMetrics;

  return (
    <SectionColumn>
      <HeaderBar title="Performance since strategy inception" />
      <Stack spacing={1.5}>
        <Grid container alignItems="stretch" spacing={1.5}>
          {metrics?.map(({ name, value }) => {
            return (
              <PerformanceTile
                key={name}
                title={strategyMetrics[name].name}
                value={strategyMetrics[name].format === 'percentage' ? formatPercentage(value) : formatNumber(value)}
              />
            );
          })}
        </Grid>
        <Card>
          <HighChartsContainer<IStrategyPerformanceQuery>
            {...queryOutput}
            calculateOptions={calculateOptions}
            calculateChartData={calculateChartData}
          />
        </Card>
      </Stack>
    </SectionColumn>
  );
};

export default StrategyPerformanceChart;

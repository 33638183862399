import { Checkbox, type Theme } from '@mui/joy';
import type { CSSProperties, ReactElement } from 'react';
import type { GCheckboxProps } from './GCheckbox.props';
import widthSx from '../../../width.styles.ts';
import { mergeSx } from '../../../sx.utils.ts';

const GCheckbox = (props: GCheckboxProps): ReactElement => {
  const allWidthSx = {
    ...widthSx,
    minContent: {
      width: 'auto',
    },
    fullWidth: {
      width: '100%',
    },
  };

  // @ts-expect-error showLabelAboveInput is not a valid prop for Checkbox, here we extract it when gtable passes it
  const { shiftByLabelHeight, onChange, ref, showLabelAboveInput, ...otherProps } = props;

  return (
    <Checkbox
      {...otherProps}
      onChange={(e) => onChange?.(e.target.checked)}
      sx={mergeSx(
        (theme: Theme): CSSProperties => ({
          paddingTop: props.shiftByLabelHeight ? theme.spacing(3.75) : 0,
          ...allWidthSx[props.width],
        }),
        props.sx
      )}
      slotProps={{
        input: {
          ...props.slotProps?.input,
          ref: ref,
        },
        ...props.slotProps,
      }}
    />
  );
};

export default GCheckbox;

import { DeleteMenuItem } from 'components/technical/inputs/GButton/DeleteDialogButton';
import { PortfolioDefListInputDocument, useDeletePortfolioDefinitionMutation } from 'generated/graphql';

export const DeletePortfolioMenuItem = (props: { name: string; id: string }) => {
  const [deletePortfolioDefinition] = useDeletePortfolioDefinitionMutation();

  return (
    <DeleteMenuItem
      deletedMessage="Portfolio definition successfully deleted"
      confirmationMessage={<>Are you sure you want to remove portfolio {props.name}?</>}
      deleteItem={(): Promise<unknown> => {
        return deletePortfolioDefinition({
          variables: {
            input: props.id,
          },
          refetchQueries: [PortfolioDefListInputDocument],
        });
      }}
    />
  );
};

import type { PortfolioAttributionState } from './PortfolioAttributionWidgetSettings.tsx';
import PerformanceAttributionChart from './PerformanceAttributionChart.tsx';
import { useWidgetState } from 'components/management/customDashboard/CustomWidgetContext.tsx';
import { calculateDefaultPresetRange } from 'components/management/TilesDateRangeSelector.tsx';
import { DEFAULT_CLUSTER } from '../attribution/PerformanceAttribution.tsx';

export const PortfolioAttributionWidgetView = ({ id }: { id: string }) => {
  const initialState = useWidgetState<PortfolioAttributionState>(id);
  const range = calculateDefaultPresetRange(initialState.predefinedDateRange);

  return (
    <PerformanceAttributionChart
      dateRange={range.value()}
      fullHeight
      side={initialState.side}
      classification={initialState.cluster ?? DEFAULT_CLUSTER}
    />
  );
};

import { type FunctionComponent, type ReactElement, Suspense, useState } from 'react';
import { Box, Stack } from '@mui/joy';
import { defaultRowSpacing } from '../../../StackSpacing.ts';
import { Add } from '@mui/icons-material';
import DialogButton from '../../../technical/inputs/GButton/DialogButton.tsx';
import { useUnifiedPnlDialogInputSuspenseQuery } from '../../../../generated/graphql.tsx';
import uniq from 'lodash/fp/uniq';
import sortBy from 'lodash/fp/sortBy';
import Loader from '../../../technical/Loader/Loader.tsx';
import GenerateUnifiedPnlDialog from './GenerateUnifiedPnlDialog.tsx';
import isNil from 'lodash/fp/isNil';
import UnifiedPnlGrid from './UnifiedPnlGrid.tsx';

const UnifiedPnl: FunctionComponent = () => {
  const { data } = useUnifiedPnlDialogInputSuspenseQuery();
  const uniqDim = uniq(data.portfolio.subFunds.list.map((sub) => sub.dimension));
  const [reportId, setReportId] = useState<number | null>(null);

  return (
    <Stack spacing={defaultRowSpacing} height="100%">
      <Box ml={'auto'}>
        <DialogButton
          startDecorator={<Add />}
          variant="solid"
          renderDialog={({ onClose }): ReactElement => (
            <GenerateUnifiedPnlDialog
              onClose={onClose}
              existingDimensions={sortBy((dim) => dim, uniqDim)}
              onReportSelected={(id) => {
                setReportId(id);
                onClose();
              }}
              onReportDeleted={(id) => {
                if (id !== reportId) {
                  return;
                }

                setReportId(null);
              }}
            />
          )}
        >
          Generate
        </DialogButton>
      </Box>
      <Suspense fallback={<Loader />}>{isNil(reportId) ? null : <UnifiedPnlGrid reportId={reportId} />}</Suspense>
    </Stack>
  );
};

const UnifiedPnlContainer: FunctionComponent = () => {
  return (
    <Suspense fallback={<Loader />}>
      <UnifiedPnl />
    </Suspense>
  );
};

export default UnifiedPnlContainer;

import type { TourGuideStep } from '@sjmc11/tourguidejs/src/types/TourGuideStep';
import { getCDTourSteps } from './tours/CustomDashboardTourSteps';
import type { TourGuideClient } from '@sjmc11/tourguidejs/src/Tour';

export const tourGuideKeys = {
  CUSTOM_DASHBOARD: 'cd-tour',
} as const;

export type TourGuideKey = (typeof tourGuideKeys)[keyof typeof tourGuideKeys];

export type TourMetadata = {
  featureId: TourGuideKey;
  title: string;
  description?: string;
  startingRoute: string;
  allowedRoutes?: string[];
  runOrder: number;
  autoPlay: boolean;
};

export type TourGuideConfigEntry = {
  metadata: TourMetadata;
  createSteps: (tourInstance: TourGuideClient) => TourGuideStep[];
};

export const useTourGuideConfig = (): Record<TourGuideKey, TourGuideConfigEntry> => {
  return {
    [tourGuideKeys.CUSTOM_DASHBOARD]: {
      metadata: {
        featureId: tourGuideKeys.CUSTOM_DASHBOARD,
        title: 'Custom Dashboard Tour',
        description: 'This tour will guide you through the custom dashboard.',
        startingRoute: '/app/portfolio/custom-dashboard',
        runOrder: 1,
        autoPlay: true,
      },
      createSteps: (tourInstance: TourGuideClient) => getCDTourSteps(tourInstance),
    },
  };
};

import { type FunctionComponent, type ReactElement, useState } from 'react';
import { useAuth } from 'UseAuth';
import {
  type PredefinedRange,
  getRangeByLabel,
  range7Days,
  rangeMonthToDate,
  rangeQuarterToDate,
  rangeYearToDate,
  getDefaultRange,
  type PredefinedRangeLabel,
} from 'components/predefinedDateRanges';
import ConfirmationDialog from 'components/technical/form/dialog/ConfirmationDialog';
import PredefinedDateRangeButtons from 'components/technical/inputs/date/PredefinedDateRangeButtons';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';
import {
  UserSettingsDocument,
  useUpdateUserSettingsMutation,
  useUserSettingsQuery,
  useUserSettingsSuspenseQuery,
} from 'generated/graphql';
import { UserSettings } from './UserSettings.types';
import { useImperativeModal } from '../technical/ImperativeModal/UseImperativeModal.tsx';
import isNil from 'lodash/fp/isNil';

function TilesDateRangeSelector(): ReactElement {
  const userSettingsQueryResult = useDefaultErrorHandling(
    useUserSettingsQuery({
      variables: {
        field: UserSettings.TilesDateRange,
      },
    }),
    {
      loaderSizeVariant: 'small',
    }
  );

  if (!userSettingsQueryResult.loaded) {
    return <userSettingsQueryResult.Fallback />;
  }

  const savedRangeLabel = userSettingsQueryResult.data.management.userSettings;
  const savedRange = savedRangeLabel ? getRangeByLabel(savedRangeLabel) : undefined;

  return <TilesDateRangeSelectorButtons savedRange={savedRange} />;
}

type TilesDateRangeSelectorButtonsProps = {
  savedRange: PredefinedRange | undefined;
};

export const useDefaultTilesPresetDateRange = (): PredefinedRange => {
  const userSettingsQueryResult = useUserSettingsSuspenseQuery({
    variables: {
      field: UserSettings.TilesDateRange,
    },
  });

  const savedRangeLabel = userSettingsQueryResult.data.management.userSettings;
  return savedRangeLabel ? (getRangeByLabel(savedRangeLabel) ?? getDefaultRange()) : getDefaultRange();
};

export const calculateDefaultPresetRange = (initialState: PredefinedRangeLabel | undefined): PredefinedRange => {
  const defaultTilePresetRange = useDefaultTilesPresetDateRange();
  return isNil(initialState) ? defaultTilePresetRange : (getRangeByLabel(initialState) ?? defaultTilePresetRange);
};

const TilesDateRangeSelectorButtons: FunctionComponent<TilesDateRangeSelectorButtonsProps> = ({ savedRange }) => {
  const { isImpersonating } = useAuth();
  const [updateSettings] = useUpdateUserSettingsMutation();

  const { showModal } = useImperativeModal();
  const [dateRange, setDateRange] = useState<PredefinedRange | undefined>(savedRange);

  return (
    <PredefinedDateRangeButtons
      onChange={(range) => {
        async function performUpdate(): Promise<void> {
          await updateSettings({
            variables: {
              settings: { [UserSettings.TilesDateRange]: range.label },
            },
            refetchQueries: [UserSettingsDocument],
          });
          setDateRange(range);
        }

        if (isImpersonating) {
          showModal(({ onClose }) => (
            <ConfirmationDialog
              onClose={onClose}
              onApprove={async () => {
                performUpdate();
                onClose();
              }}
            >
              Are you sure you want to change the date range for impersonated user?
            </ConfirmationDialog>
          ));
        } else {
          performUpdate();
        }
      }}
      value={dateRange}
      dateRanges={[range7Days, rangeMonthToDate, rangeQuarterToDate, rangeYearToDate]}
    />
  );
};

export default TilesDateRangeSelector;

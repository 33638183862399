import { createContext } from 'react';
import type { ReactNode } from 'react';

export const DialogDropdownContext = createContext<{
  setDialog: (element: ReactNode) => void;
}>({
  setDialog: (): void => {
    console.warn('DialogDropDown context not initialized');
  },
});

import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import SyncIcon from '@mui/icons-material/Sync';
import { Badge, Box, Stack, Tooltip } from '@mui/joy';
import IconButton from '@mui/joy/IconButton';
import dayjs, { type Dayjs } from 'dayjs';
import type { FunctionComponent, ReactNode } from 'react';
import { useMarketFilters } from 'components/market/asset/filters/UseMarketFilters.ts';
import { DrawerType } from 'components/technical/Drawer/Drawer.types';
import Loader from 'components/technical/Loader/Loader';
import { useShowSubAccountAssetFilter } from 'components/technical/SubAccountAssetFilterDrawer/UseShowSubAccountAssetFilters.tsx';
import { useSubAccountAssetFilters } from 'components/technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters.tsx';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';
import { useCanRequestPortfolioSnapshotQuery, useAccountsQuery, type IAccountsQuery } from 'generated/graphql';
import { useShowQuantInsightsFilters } from '../../../market/asset/filters/UseShowQuantInsightsFilters.tsx';
import { useRequestSubAccountSnapshot } from '../../../portfolio/account/useSubAccountSnapshot.ts';
import { getLastUpdatedDate } from '../../../portfolio/PortfolioService.ts';
import { useDrawer } from '../../Drawer/UseDrawer.tsx';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import { useBackgroundPolling } from '../../../useBackgroundPolling.ts';
import ColorSchemeChanger from 'components/management/ColorSchemeToggler.tsx';

const LastUpdatedTooltip: FunctionComponent<{
  accounts: IAccountsQuery['portfolio']['accounts'];
  text: string;
}> = ({ accounts, text }) => {
  const lastSuccessfulSnapshotDate: Dayjs | null = getLastUpdatedDate(accounts);

  return (
    <Box display="flex" flexDirection="column" border={0} alignItems="center">
      {text}
      {lastSuccessfulSnapshotDate && (
        <span>Last updated: {dayjs(lastSuccessfulSnapshotDate.toString()).fromNow()}</span>
      )}
    </Box>
  );
};

const getSyncTooltip = (canSync: boolean | undefined, accounts: IAccountsQuery['portfolio']['accounts']): ReactNode => {
  if (accounts.length === 0) {
    return 'No accounts added yet. Nothing to sync';
  }

  if (canSync) {
    return <LastUpdatedTooltip accounts={accounts} text="Click to sync your portfolio" />;
  }

  return <LastUpdatedTooltip accounts={accounts} text="Updating your portfolio data" />;
};

const pollingIntervalMSec = 60 * 1000;
const AppbarActions: FunctionComponent = () => {
  const { drawer, openDrawer, closeDrawer } = useDrawer();
  const shouldShowMarketFilters = useShowQuantInsightsFilters();
  const { isFilteredByMarketFilter } = useMarketFilters();
  const activePollInterval = useBackgroundPolling(pollingIntervalMSec);

  const shouldShowSubAccountAssetFilter = useShowSubAccountAssetFilter();
  const { isFiltered: isFilteredBySubAccountAsset } = useSubAccountAssetFilters();
  const {
    loaded: accountsLoaded,
    data: accountData,
    Fallback,
  } = useDefaultErrorHandling(useAccountsQuery(), {
    loaderSizeVariant: 'small',
    loaderPositionVariant: 'min-content',
  });

  const { data, loading: snapshotLoading } = useCanRequestPortfolioSnapshotQuery({
    pollInterval: activePollInterval,
  });

  const canSync = data?.portfolio.canRequestPortfolioSnapshot;

  const syncPortfolio = useRequestSubAccountSnapshot();

  const hasAccounts = accountData?.portfolio.accounts ? accountData?.portfolio.accounts.length > 0 : false;

  if (!accountsLoaded) {
    return <Fallback />;
  }

  const badgeInset = -5;
  const showingFilteredResults =
    (shouldShowSubAccountAssetFilter && isFilteredBySubAccountAsset) ||
    (shouldShowMarketFilters && isFilteredByMarketFilter);

  // ButtonGroup adds extra border, which already buttons have, but as we use a box, we need to remove it
  const boxAsButtonGroupChildBorder = { border: '0!important' };

  return (
    <Stack direction="row" spacing={1}>
      <Tooltip title={'Open conversations with an agent'}>
        <IconButton
          variant="soft"
          color="primary"
          onClick={(): void => (drawer === DrawerType.Agent ? closeDrawer() : openDrawer(DrawerType.Agent))}
        >
          <AutoAwesomeOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={getSyncTooltip(canSync, accountData.portfolio.accounts)}>
        {/* div is needed to make tooltip work even if the icon is disabled */}
        <Box sx={boxAsButtonGroupChildBorder}>
          <IconButton disabled={!canSync || !hasAccounts} onClick={(): void => syncPortfolio()}>
            {(hasAccounts && !canSync) || snapshotLoading ? <Loader variant="small" /> : <SyncIcon />}
          </IconButton>
        </Box>
      </Tooltip>

      <ColorSchemeChanger variant="switch" color="neutral" />
      <IconButton
        variant="plain"
        color="neutral"
        disabled={!shouldShowMarketFilters && !shouldShowSubAccountAssetFilter}
        onClick={(): void => {
          if (drawer !== null && [DrawerType.QuantInsightsFilters, DrawerType.SubAccountAssetFilter].includes(drawer)) {
            closeDrawer();
            return;
          }
          let drawerType = null;
          if (shouldShowMarketFilters) {
            drawerType = DrawerType.QuantInsightsFilters;
          } else if (shouldShowSubAccountAssetFilter) {
            drawerType = DrawerType.SubAccountAssetFilter;
          } else {
            throw new Error(
              'Unexpected filter click - correspondent filter type was not found, filter supposed to be disabled'
            );
          }
          openDrawer(drawerType);
        }}
      >
        <Tooltip title={showingFilteredResults ? 'Showing filtered results' : ''}>
          <Badge invisible={!showingFilteredResults} badgeInset={badgeInset} color="warning">
            <FilterListOutlinedIcon />
          </Badge>
        </Tooltip>
      </IconButton>
    </Stack>
  );
};

export default AppbarActions;

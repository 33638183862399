import type { ReactElement } from 'react';
import { Box, ListItemDecorator, Stack, Tooltip, Typography } from '@mui/joy';
import GridCard, { type GridWidth } from 'components/technical/GCard/GridCard.tsx';
import SeeMoreDropdown from 'components/technical/SeeMoreDropDown/SeeMoreDropdown.tsx';
import UpdateApiKeysDialog from './UpdateApiKeysDialog';
import UpdateNameDialog from './UpdateNameDialog';
import {
  AccountsDocument,
  useDeleteAccountMutation,
  useUpdateAccountApiKeysMutation,
  useUpdateAccountMutation,
} from 'generated/graphql';
import type { AccountType } from './Account.types';
import { IconVariant } from 'components/market/asset/cryptocurrencies/CryptocurrenciesData';
import AccountStatusChip from './AccountStatusChip';
import { GENIE_VENUE, venues } from 'components/venue/VenueData';
import { DateTimeFormat, formatDate } from 'components/formatter.utils';
import { useUserTimezone } from 'components/technical/UseUserTimezone';
import { Pencil } from 'components/technical/icons';
import AccountCardSubAccounts from './AccountCardSubAccounts';
import { BUTTON_MENU_POSITION_STYLE } from 'components/technical/GCard/consts';
import GCardKeyValuePairs from 'components/technical/GCard/GCardKeyValuePairs';
import { DeleteMenuItem } from '../../technical/inputs/GButton/DeleteDialogButton.tsx';
import { GCardContentWithDivider } from '../../technical/GCard/GCardContentWithDivider.tsx';
import { DialogMenuItem } from '../../technical/DialogDropdown/DialogMenuItem.tsx';
import { VenueIcon } from 'components/venue/VenueLabel.tsx';

const AccountCard = ({
  account,
  gridWidth,
  height,
  showSubAccounts = true,
  showVenueLabel = true,
}: {
  account: AccountType;
  gridWidth: GridWidth;
  height: string;
  showSubAccounts?: boolean;
  showVenueLabel?: boolean;
}): ReactElement => {
  const [deleteAccount] = useDeleteAccountMutation({ ignoreResults: true });
  const timezone = useUserTimezone();

  const venueName = `${venues[account.venue.label].name} ${venues[account.venue.label].adminOnly ? '*' : ''}`;

  const accountKeyValueData = [
    ...(showVenueLabel ? [{ key: 'Venue', value: venueName }] : []),
    { key: 'Added On', value: formatDate(account.createdAt, DateTimeFormat.DateTime, timezone) },
    {
      key: 'Last Updated',
      value: formatDate(account.snapshotSyncStatus.latestCompleted?.time, DateTimeFormat.DateTime, timezone),
    },
  ];

  return (
    <GridCard height={height} gridWidth={gridWidth} cardWrapperSx={{ position: 'relative' }}>
      <GCardContentWithDivider>
        <Box sx={BUTTON_MENU_POSITION_STYLE}>
          <SeeMoreDropdown>
            <DialogMenuItem
              renderDialog={({ onClose }): ReactElement => (
                <UpdateNameDialog
                  onClose={onClose}
                  onUpdate={onClose}
                  mutation={useUpdateAccountMutation}
                  id={account.id}
                  title={`Rename "${account.name}" account`}
                />
              )}
            >
              <>
                <ListItemDecorator>
                  <Pencil fontSize="md" />
                </ListItemDecorator>
                Edit name
              </>
            </DialogMenuItem>
            {account.venue.label !== GENIE_VENUE && (
              <DialogMenuItem
                renderDialog={({ onClose }): ReactElement => (
                  <UpdateApiKeysDialog
                    onClose={onClose}
                    onUpdate={onClose}
                    mutation={useUpdateAccountApiKeysMutation}
                    name={account.name}
                    venue={account.venue.label}
                    id={account.id}
                  />
                )}
              >
                <Stack direction="row" gap={1} alignItems="center">
                  <Pencil fontSize="md" />
                  Edit API credentials
                </Stack>
              </DialogMenuItem>
            )}
            <DeleteMenuItem
              deleteItem={async (): Promise<void> => {
                await deleteAccount({
                  variables: {
                    id: account.id,
                  },
                  refetchQueries: [AccountsDocument],
                });
              }}
              confirmationMessage={
                <>Are you sure you want to remove account {account.name}? This action will remove all snapshots.</>
              }
              deletedMessage={'Account successfully deleted'}
            />
          </SeeMoreDropdown>
        </Box>
        <Stack justifyContent="space-between" direction="row">
          <Stack direction={'column'} spacing={0} width={'100%'}>
            <Stack
              direction={'row'}
              gap={0.8}
              spacing={0.5}
              sx={{
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <VenueIcon size={IconVariant.XXL} accountName={account.name} venue={account.venue.label} />
              <Stack
                direction={'column'}
                gap={0.8}
                spacing={0.5}
                sx={{
                  width: '100%',
                  overflow: 'auto',
                  justifyContent: 'space-between',
                }}
              >
                <Tooltip title={account.name} placement="top-start" variant="plain">
                  <Typography
                    component={'h3'}
                    sx={{
                      cursor: 'pointer',
                      maxWidth: '75%',
                      textAlign: 'left',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      fontSize: 'inherit',
                      lineHeight: 'inherit',
                    }}
                  >
                    {account.name}
                  </Typography>
                </Tooltip>
                <Stack
                  direction={'row'}
                  spacing={0.5}
                  sx={{
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  <AccountStatusChip
                    resultCode={account.snapshotSyncStatus.latestCompleted?.resultCode}
                    lastSync={formatDate(
                      account.snapshotSyncStatus.latestSuccessfulTime,
                      DateTimeFormat.DateTime,
                      timezone
                    )}
                  />
                  {showSubAccounts && <AccountCardSubAccounts subAccounts={account.subAccounts} />}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <GCardKeyValuePairs data={accountKeyValueData} />
      </GCardContentWithDivider>
    </GridCard>
  );
};

export default AccountCard;

import { useState, type ReactElement } from 'react';
import { Box, Stack, Typography, useTheme, MenuItem, ListItemDecorator } from '@mui/joy';
import type { GridWidth } from 'components/technical/GCard/GridCard.tsx';
import {
  type IPrivateAssetsQuery,
  PrivateAssetsDocument,
  SearchableAssetIdsDocument,
  SearchableAssetsDocument,
  useDeletePrivateAssetMutation,
} from 'generated/graphql';
import { AssetIcon } from '../../AssetLabel.tsx';
import { IconVariant } from '../../cryptocurrencies/CryptocurrenciesData.tsx';
import UpdatePrivateAssetDialog from './UpdatePrivateAssetDialog.tsx';
import SeeMoreDropdown from 'components/technical/SeeMoreDropDown/SeeMoreDropdown.tsx';
import { BUTTON_MENU_POSITION_STYLE, SELECTABLE_CARD_STYLE } from 'components/technical/GCard/consts.ts';
import { DeleteMenuItem } from '../../../../technical/inputs/GButton/DeleteDialogButton.tsx';
import GridCard from 'components/technical/GCard/GridCard.tsx';
import { useNavigate } from 'react-router-dom';
import { Pencil } from 'components/technical/icons/index.tsx';

const messageStyle = {
  '&:hover .navigate-to-asset-details': {
    opacity: 1,
  },
};

const PrivateAssetCard = ({
  asset,
  gridWidth,
  height,
}: {
  asset: IPrivateAssetsQuery['assets']['list'][number];
  gridWidth: GridWidth;
  height: string;
}): ReactElement => {
  const navigate = useNavigate();
  const [deletePrivateAsset] = useDeletePrivateAssetMutation({ ignoreResults: true });
  const theme = useTheme();

  const [isEditDialogOpen, setEditDialogOpen] = useState(false);

  return (
    <>
      <GridCard
        height={height}
        gridWidth={gridWidth}
        cardSx={{
          ...SELECTABLE_CARD_STYLE(theme),
          ...messageStyle,
        }}
        onClick={() => navigate(`/app/market/assets/${asset.id}`)}
      >
        <Box sx={BUTTON_MENU_POSITION_STYLE}>
          <SeeMoreDropdown>
            <MenuItem onClick={() => setEditDialogOpen(true)}>
              <>
                <ListItemDecorator>
                  <Pencil fontSize="md" />
                </ListItemDecorator>
                Edit
              </>
            </MenuItem>
            <DeleteMenuItem
              deletedMessage={'Asset successfully deleted'}
              confirmationMessage={
                <>
                  Are you sure you want to remove asset {asset.name}? This action will remove all trades and balances
                  that use it.
                </>
              }
              deleteItem={(): Promise<unknown> =>
                deletePrivateAsset({
                  variables: {
                    id: asset.id,
                  },
                  refetchQueries: [SearchableAssetsDocument, SearchableAssetIdsDocument, PrivateAssetsDocument],
                })
              }
            />
          </SeeMoreDropdown>
        </Box>
        <Stack justifyContent="flex-start" direction="row" alignItems={'center'} columnGap={1}>
          <AssetIcon asset={asset} size={IconVariant.XXXL} />
          <Stack direction="column" alignItems={'start'} justifyContent={'start'}>
            <Typography level="title-lg" className="title">
              {asset.name ?? asset.symbol} <Typography color="neutral">({asset.symbol})</Typography>
            </Typography>
            <Typography
              level="body-xs"
              sx={{
                opacity: 0,
                transition: 'all 0.3s ease-in-out',
                fontStyle: 'italic',
              }}
              className="navigate-to-asset-details"
            >
              Navigate to the asset details
            </Typography>
          </Stack>
        </Stack>
      </GridCard>

      {/* Render the dialog OUTSIDE of GridCard to prevent propagation issues */}
      {isEditDialogOpen && (
        <UpdatePrivateAssetDialog
          asset={asset}
          onClose={() => setEditDialogOpen(false)}
          onUpdate={() => setEditDialogOpen(false)}
        />
      )}
    </>
  );
};

export default PrivateAssetCard;

import { Grid } from '@mui/joy';
import type { FunctionComponent } from 'react';
import { useTwrEquityData } from './PortfolioTilesService';
import VolatilityTile from './VolatilityTile.tsx';
import DailyReturnsTile from './DailyReturnsTile.tsx';
import EquityTile from './EquityTile.tsx';
import TwrTile from './TwrTile.tsx';
import type { PredefinedRange } from '../../../predefinedDateRanges.ts';

type PortfolioTilesProps = {
  // saved range is used only for equity and TWR tiles
  // "Daily return" uses fixed 30 days range, "Volatility" uses fixed 31 days range
  savedTimeFrameInDays: number;
  savedDateRange: PredefinedRange;
};

const PortfolioTiles: FunctionComponent<PortfolioTilesProps> = ({ savedTimeFrameInDays, savedDateRange }) => {
  const { twr, loading, twrSince } = useTwrEquityData({ savedTimeFrameInDays });
  return (
    <Grid container alignItems="stretch" spacing={1.5} pt={2}>
      <EquityTile loading={loading} since={savedDateRange.value()![0]} twr={twr} />
      <TwrTile twr={twr} dateRangeLabel={savedDateRange.label} loading={loading} since={twrSince} />
      <DailyReturnsTile />
      <VolatilityTile />
    </Grid>
  );
};

export default PortfolioTiles;

import { Avatar, Chip, Stack, Typography } from '@mui/joy';
import type { FunctionComponent, ReactElement } from 'react';
import GLink from 'components/technical/GLink/GLink';
import { useScreenBreakpointDown } from 'components/technical/screenSizeUtils.ts';
import UnknownIcon from 'components/technical/UnknownIcon/UnknownIcon';
import { type AssetLabelInput, isAssetLabelInput, type NotVerifiedAsset } from './AssetLabelService.ts';
import { createAssetLink, IconVariant, iconVariantToPx } from './cryptocurrencies/CryptocurrenciesData';
import { IAssetType } from '../../../generated/graphql';
import { venues } from '../../venue/VenueData.tsx';
import { getAssetName } from './AssetService.tsx';
import { logWarnOnce } from 'components/log.utils.ts';
import { truncateText } from '../../text.styles.ts';
import { ASSET_LINK_BLACKLIST, PUBLIC_ASSET_START_WITH } from './assetLinkBlacklist.ts';

export interface AssetLabelProps {
  asset: NotVerifiedAsset;
  format?: 'short' | 'long';
  link?: boolean;
  size?: IconVariant;
  wrap?: boolean;
  plain?: boolean;
  hideDebug?: boolean;
  withSymbol?: boolean;
}

export type AssetIconAssetProps = NotVerifiedAsset;

export const AssetIcon = ({
  asset,
  size = IconVariant.LARGE,
}: {
  asset: AssetIconAssetProps;
  size?: IconVariant;
}): ReactElement => {
  const iconSize = iconVariantToPx(size);
  const iconSizeStyles = {
    height: iconSize,
    width: iconSize,
  } as const;

  const centerIconInAvatarStyle = {
    '& .MuiAvatar-img': {
      objectFit: 'fill',
    },
  };

  const fallbackAsset = <UnknownIcon size={size} text={asset.symbol} />;
  if (asset.type === IAssetType.Exchange) {
    if (!asset.exchangeDetails) {
      logWarnOnce(`unknown exchangeDetails for exchange asset: ${asset.symbol}`, asset);
      return fallbackAsset;
    }

    const venue = venues[asset.exchangeDetails];
    if (!venue) {
      logWarnOnce(`unknown venue for exchange asset: ${asset.symbol}`, asset);
      return fallbackAsset;
    }

    return (
      <Avatar
        component={venue.icon}
        alt={asset.symbol}
        variant="plain"
        sx={{
          ...iconSizeStyles,
          ...centerIconInAvatarStyle,
          background: venue.iconBackground,
          padding: 0.25, // add spacing so that exchange is in the icon
        }}
      />
    );
  }

  if (asset.type === IAssetType.Derivative) {
    if (!asset.derivativeDetails) {
      logWarnOnce(`unknown derivativeDetails for derivative asset: ${asset.symbol}`, asset);
      return fallbackAsset;
    }

    if (!asset.derivativeDetails.exchange) {
      logWarnOnce(`unknown venue for derivative asset: ${asset.symbol}`, asset);
      return fallbackAsset;
    }

    const venue = venues[asset.derivativeDetails.exchange];
    return (
      <Avatar
        component={venue?.icon}
        alt={asset.symbol}
        variant="plain"
        sx={{
          ...iconSizeStyles,
          ...centerIconInAvatarStyle,
          background: venue?.iconBackground ?? 'transparent',
          padding: 0.25, // add spacing so that exchange is in the icon
        }}
      />
    );
  }

  if (asset.type === IAssetType.Private) {
    return <UnknownIcon text={asset.symbol} size={size} />;
  }

  if (asset.type === IAssetType.Public) {
    return (
      <Avatar
        src={asset.icon ?? '?'}
        alt={asset.symbol}
        variant="plain"
        sx={{
          ...iconSizeStyles,
          ...centerIconInAvatarStyle,
        }}
      />
    );
  }

  return <UnknownIcon size={size} text={asset.symbol} />;
};

function shouldShowLink(asset: AssetLabelInput, link: boolean, isXsScreen: boolean): boolean {
  const { type, label } = asset;
  const allowedTypes = [IAssetType.Public, IAssetType.Private, IAssetType.Derivative];

  if (!link || isXsScreen || !allowedTypes.includes(type)) {
    return false;
  }

  if (type === IAssetType.Public) {
    if (!label?.toLowerCase().startsWith(PUBLIC_ASSET_START_WITH)) {
      return false;
    }
    if (ASSET_LINK_BLACKLIST.has(label.toLowerCase())) {
      return false;
    }
  }
  return true;
}

function shouldShowIcon(asset: AssetLabelInput, format: 'short' | 'long'): boolean {
  return (
    format === 'long' &&
    [IAssetType.Public, IAssetType.Private, IAssetType.Derivative, IAssetType.Exchange].includes(asset.type)
  );
}

const AssetLabel: FunctionComponent<AssetLabelProps> = ({
  asset: notVerifiedAsset,
  format = 'long',
  size = IconVariant.MEDIUM,
  link = false,
  wrap = true,
  plain = false,
  withSymbol = false,
}) => {
  const isXsScreen = useScreenBreakpointDown('sm');

  if (!isAssetLabelInput(notVerifiedAsset)) {
    return notVerifiedAsset?.symbol ?? '';
  }

  const asset = notVerifiedAsset as AssetLabelInput;
  const showIcon = shouldShowIcon(asset, format);

  const showLink = shouldShowLink(asset, link, isXsScreen);
  const shownLabel = getAssetName(asset, format);

  const showSymbol = withSymbol && !isXsScreen && shownLabel !== asset.symbol;

  // Show a chip if the symbol is the same as the label
  const chipSymbol =
    shownLabel === asset.symbol && withSymbol ? (
      <Chip size="sm" variant="outlined" color="neutral" sx={{}}>
        {shownLabel}
      </Chip>
    ) : (
      <Typography
        sx={{
          ...truncateText,
          mr: !plain ? 1 : undefined,
        }}
        level={'title-sm'}
      >
        {shownLabel}
      </Typography>
    );

  return (
    <Stack
      direction="row"
      gap={1}
      alignItems="center"
      display="flex"
      maxWidth="100%"
      flexWrap={wrap ? 'wrap' : 'nowrap'}
    >
      {/* Icon & Symbol (Not inside the Link) */}
      {showIcon && <AssetIcon asset={asset} size={size} />}
      {showSymbol && (
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Chip size="sm" variant="outlined" color="neutral">
            {asset.symbol}
          </Chip>
        </Stack>
      )}

      {/* Only the text should be a link */}
      {showLink ? <GLink to={createAssetLink(asset)}>{chipSymbol}</GLink> : chipSymbol}
    </Stack>
  );
};

const AssetLabelWithOptionalIds = (props: AssetLabelProps): ReactElement => {
  return <AssetLabel {...props} />;
};

export default AssetLabelWithOptionalIds;

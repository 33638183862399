import { IConstraintFormulation, type IYieldOptimizerResultQuery } from '../../../../../../generated/graphql.tsx';
import type { ReactElement, ReactNode } from 'react';
import { defaultRowSpacing } from '../../../../../StackSpacing.ts';
import CardWithHeader from '../CardWithHeader.tsx';
import ValueWithLabel from '../ValueWithLabel.tsx';
import { Stack } from '@mui/joy';
import GTable from '../../../../../technical/GTable/GTable.tsx';
import { createColumnHelper } from '@tanstack/react-table';
import type { CellContext } from '@tanstack/table-core';
import { formatNumber } from '../../../../../formatter.utils.ts';
import { formatConstraintBound } from '../OptimizerInputForResult.utils.ts';
import AssetLabel from 'components/market/asset/AssetLabel.tsx';
import { getAssetCategory } from 'components/market/asset/AssetService.tsx';
import { poolWithLinkElement } from 'components/technical/grids/SharedReportColumns.tsx';
import { protocolCellElement } from 'components/technical/grids/agGrid.utils.tsx';

type Input = IYieldOptimizerResultQuery['optimization']['getOptimizationInput'];
type AmountRow = Input['amounts'][number];
type PoolRow = Input['pools'][number];

const amountColumnHelper = createColumnHelper<AmountRow>();
const poolColumnHelper = createColumnHelper<PoolRow>();
const YieldOptimizerInput = ({ data }: { data: IYieldOptimizerResultQuery }): ReactElement => {
  const optimization = data.optimization.getOptimizationInput;
  return (
    <Stack gap={defaultRowSpacing}>
      <Stack flexWrap="wrap" direction="row" gap={defaultRowSpacing}>
        <CardWithHeader title="Description">
          <ValueWithLabel label="Name">{optimization.name}</ValueWithLabel>
          <ValueWithLabel label="Description">{optimization.description}</ValueWithLabel>
        </CardWithHeader>
      </Stack>
      <CardWithHeader title="Assets">
        <GTable
          data={optimization.amounts}
          columns={[
            amountColumnHelper.accessor('asset.symbol', {
              header: 'Asset',
              cell: (info) => <AssetLabel asset={info.row.original.asset!} wrap={false} />,
            }),
            amountColumnHelper.accessor((info) => getAssetCategory(info.asset!), {
              header: 'Type',
            }),
            {
              cell: (props: CellContext<AmountRow, unknown>): ReactNode => formatNumber(props.row.original.amount),
              header: 'Amount',
            },
          ]}
        />
      </CardWithHeader>
      <CardWithHeader title="Pools">
        <GTable
          data={optimization.pools}
          columns={[
            poolColumnHelper.accessor('poolInfo', {
              header: 'Pool',
              meta: {
                headerStyles: {
                  width: '300px',
                  minWidth: '100px',
                },
              },
              cell: (props) =>
                poolWithLinkElement({
                  externalLink: props.row.original.poolInfo.externalLink ?? undefined,
                  poolName: props.row.original.poolInfo.poolName,
                }),
            }),
            poolColumnHelper.accessor('poolInfo.collateralAsset.symbol', {
              header: 'Asset',
            }),
            poolColumnHelper.accessor('poolInfo.chain', {
              header: 'Chain',
            }),
            poolColumnHelper.accessor('poolInfo.protocol', {
              header: 'Protocol',
              cell: (props) => {
                return protocolCellElement({
                  name: props.row.original.poolInfo.protocol?.name ?? '',
                  icon: props.row.original.poolInfo.protocol?.icon ?? null,
                });
              },
            }),
            poolColumnHelper.accessor('poolInfo.category', {
              header: 'Category',
            }),
            poolColumnHelper.accessor(
              (info) =>
                info.constraint
                  ? formatConstraintBound({
                      constraint: info.constraint,
                      constraintFormulation: IConstraintFormulation.AbsoluteValue,
                      usdValue: false,
                    })
                  : undefined,
              {
                header: 'Constraint',
              }
            ),
          ]}
        />
      </CardWithHeader>
    </Stack>
  );
};

export default YieldOptimizerInput;

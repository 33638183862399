import type {
  ColDef,
  ColDefField,
  ColGroupDef,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterFunc,
  ValueGetterParams,
} from 'ag-grid-community';
import type { OverridableStringUnion } from '@mui/types';
import { relativeChange, reverseRelativeChange } from 'bigNumMath';
import { isNil } from 'lodash/fp';
import { type BigNumber, bignumber } from 'mathjs';
import type { ReactElement } from 'react';
import {
  assetCellRenderer,
  assetNameGetter,
  type AssetNameRow,
  assetSymbolGetter,
  createAssetCellRenderer,
  createAssetNameGetter,
  createProtocolCellRenderer,
  dateReadableValueGetter,
  type DerivativeAssetNameRow,
  getRowDataForRowGroupColumn,
  getValueFormatterCellValue,
  sideAwareHeaderName,
  underlyingAssetValueGetter,
} from 'components/technical/grids/agGrid.utils.tsx';
import { logErrorOnce } from 'components/log.utils.ts';
import { IconVariant } from 'components/market/asset/cryptocurrencies/CryptocurrenciesData';
import type { TupleKeyMap } from 'components/TupleKeyMap.ts';
import {
  type IAssetType,
  type IDerivativeType,
  IDerivativeType as IDerivativeTypeEnum,
  IOptionType,
  type IAsset,
  type IAssetRiskMetricsQuery,
  type IPositionSide,
  type ISlotType,
} from 'generated/graphql';

import { PriceSummaryType } from '../../portfolio/openPositions/UsePriceChanges.tsx';
import { venues } from '../../venue/VenueData.tsx';
import VenueLabel from '../../venue/VenueLabel.tsx';
import { SubAccountLabel, type SubAccountLabelInputAccount } from '../../portfolio/account/SubAccountLabel.tsx';
import {
  calculateBalance,
  calculateExposure,
  calculateUnrealizedGain,
  getUnderlyingPosAssetId,
  sideMultiplier,
  type SubAccountPosition,
} from '../../portfolio/account/SubAccountPositionsService.ts';
import { formatMetricValue, getName, MET_MAX_SUPPLY } from 'components/metrics/MetricsData.tsx';
import type { MetricParams } from 'components/metrics/MetricsData.types.ts';
import { formatCash, formatEnum, formatPercentage } from 'components/formatter.utils.ts';
import type { ObjectKeyMap } from 'components/objectKeyMap.ts';
import { AccountLabel } from 'components/portfolio/account/AccountLabel.tsx';
import dayjs from 'dayjs';
import get from 'lodash/fp/get';
import { parseUtcDate } from '../../date.utils.ts';
import capitalize from 'lodash/fp/capitalize';
import type { AssetLabelInput } from '../../market/asset/AssetLabelService.ts';
import type { FlattenUnion } from '../../type.utils.ts';
import {
  Chip,
  type ChipPropsColorOverrides,
  type ColorPaletteProp,
  Stack,
  type Theme,
  Tooltip,
  Typography,
} from '@mui/joy';
import {
  TrendingUp,
  LockOutlined,
  TrendingDown,
  SavingsOutlined,
  LockClockOutlined,
  LockOpenOutlined,
  CallReceivedOutlined,
  CallMadeOutlined,
  OpenInNewOutlined,
} from '@mui/icons-material';
import { clusterHeaderMap } from './colHeaderFactory';
import type { AgBarSeriesItemStylerParams, AgChartLabelFormatterParams, AgSparklineOptions } from 'ag-charts-community';
import { assetToAssetType, assetTypeComparator } from 'components/market/asset/AssetService.tsx';
import GLink from '../GLink/GLink.tsx';

type ColumnOptions = Partial<
  Pick<
    ColDef,
    | 'initialHide'
    | 'initialSort'
    | 'sortIndex'
    | 'initialRowGroup'
    | 'initialRowGroupIndex'
    | 'lockVisible'
    | 'lockPinned'
    | 'pinned'
    | 'hide'
    | 'initialWidth'
    | 'headerName'
    | 'allowedAggFuncs'
    | 'suppressHeaderMenuButton'
  >
>;

const stateLabelConfig: Record<
  ISlotType,
  { icon: ReactElement; color: 'primary' | 'success' | 'danger' | 'warning' | 'neutral' }
> = {
  BORROWED: { icon: <CallReceivedOutlined />, color: 'danger' },
  FREE: { icon: <LockOpenOutlined />, color: 'primary' },
  STAKING: { icon: <SavingsOutlined />, color: 'success' },
  UNVESTED: { icon: <LockClockOutlined />, color: 'warning' },
  LENT: { icon: <CallMadeOutlined />, color: 'success' },
  LOCKED: { icon: <LockOutlined />, color: 'neutral' },
};

type ColumnOptionsWithSideAware = ColumnOptions & { sideAware: boolean };

export interface AggFuncRule {
  target: string | RegExp;
  aggFunc: string | null | undefined;
}

export const COLUMN_IDS_WITH_ALTERNATIVE_AGG_FUNC = {
  assetWithSymbol: 'assetWithSymbol',
  underlyingAsset: 'underlyingAsset',
  groups: 'groups',
  extra: 'extra',
  clusters: /^cluster-/,
};

// Define aggregation rules
export const aggFuncRules: AggFuncRule[] = [
  {
    target: COLUMN_IDS_WITH_ALTERNATIVE_AGG_FUNC.groups,
    aggFunc: 'first',
  },
  {
    target: COLUMN_IDS_WITH_ALTERNATIVE_AGG_FUNC.extra,
    aggFunc: null,
  },
  {
    target: COLUMN_IDS_WITH_ALTERNATIVE_AGG_FUNC.clusters,
    aggFunc: 'first',
  },
];

export const NAV_PERC_MIN_DECIMAL_THRESHOLD = 0.0001;
export const NAV_PLACEHOLDER_SMALL_VALUES = '< 0.001%';
export const SPARKLINE_Y_MIN_OFFSET = -0.5;

const defaultNavPercentageItemStyler = (
  theme: Theme,
  params: AgBarSeriesItemStylerParams<{ x: number; y: number }>
) => {
  const realValue = params.datum.y;
  const absValue = Math.abs(realValue);

  let fillColor: string;

  if (realValue < 0) {
    fillColor =
      absValue > 60
        ? theme.palette.danger[900]
        : absValue > 40
          ? theme.palette.danger[700]
          : absValue > 20
            ? theme.palette.danger[500]
            : theme.palette.danger[300];
  } else {
    fillColor =
      absValue > 60
        ? theme.palette.primary[900]
        : absValue > 40
          ? theme.palette.primary[700]
          : absValue > 20
            ? theme.palette.primary[500]
            : theme.palette.primary[300];
  }

  return { fill: fillColor };
};

const UnrealizedPnlRenderer = (params: ICellRendererParams) => {
  if (!params.value) return null;

  const value = bignumber(params.value);
  const isPositive = value.isPositive();
  const color = isPositive ? 'success' : 'danger';

  return (
    <Chip
      size="sm"
      variant="plain"
      endDecorator={isPositive ? <TrendingUp fill={color} /> : <TrendingDown fill={color} />}
      color={color}
    >
      <Typography level="body-sm" color={color}>
        {formatCash(value.absoluteValue())}
      </Typography>
    </Chip>
  );
};

const priceWithLast24hPriceChangeRenderer = (params: ICellRendererParams) => {
  if (!params.value) return null;

  const { price, priceChange } = params.value; // Extract values

  const priceChangeBN = bignumber(priceChange ?? 0); // Handle undefined safely
  const isPositive = priceChangeBN.isPositive();
  const color = isPositive ? 'success' : 'danger';

  return (
    <Stack height="100%" justifyContent="center" alignItems="center">
      <Chip size="sm" variant="plain" color={color}>
        <Typography level="body-sm" color={color}>
          {formatPercentage(priceChangeBN)}
        </Typography>
      </Chip>
      <Typography level="body-sm">{formatCash(price)}</Typography>
    </Stack>
  );
};

const StateWithIconRenderer = (params: ICellRendererParams) => {
  if (!params.value) return null;

  const config = stateLabelConfig[params.value.toUpperCase() as keyof typeof stateLabelConfig];

  if (!config) {
    // handle unknown labels gracefully
    return <Chip variant="outlined">{params.value}</Chip>;
  }

  return (
    <Chip variant="soft" size="sm" color={config.color} startDecorator={config.icon}>
      {params.value}
    </Chip>
  );
};

export const poolWithLinkElement = (element: { externalLink?: string; poolName: string }) => {
  const { externalLink, poolName } = element;

  return (
    <Stack direction="row" spacing="1">
      {externalLink && (
        <GLink to={externalLink} target="_blank" underline="none">
          <Tooltip title="Pool link">
            <OpenInNewOutlined
              fontSize="xs"
              sx={{
                mr: 0.5,
                width: '1rem',
                height: '1rem',
              }}
              color="disabled"
            />
          </Tooltip>
        </GLink>
      )}
      <Typography level="title-sm">{poolName}</Typography>
    </Stack>
  );
};

export const poolWithLinkRenderer = (params: ICellRendererParams, field?: string) => {
  const pool = field ? params.data?.[field] : params.data;

  if (!pool) {
    return undefined;
  }
  return poolWithLinkElement({
    externalLink: pool.externalLink ?? undefined,
    poolName: pool.poolName,
  });
};

type PoolData = {
  poolName: string;
  externalLink?: string;
};

export function poolWithLink<TRow>(columnOptions: ColumnOptions & { field?: string } = {}): ColDef<TRow, string> {
  const { field, ...rest } = columnOptions;

  return {
    colId: 'pool',
    headerName: 'Pool',
    type: 'textColumn',
    valueGetter: (params: ValueGetterParams<TRow, string>): string | undefined => {
      if (!params.data) return undefined;
      if (field) {
        const fieldData = params.data[field as keyof TRow] as PoolData | undefined;
        return fieldData?.poolName;
      }
      const data = params.data as unknown as PoolData;
      return data.poolName;
    },
    filterValueGetter: (params: ValueGetterParams<TRow, string>): string | undefined => {
      if (!params.data) return undefined;

      if (field) {
        const fieldData = params.data[field as keyof TRow] as PoolData | undefined;
        return fieldData?.poolName;
      }
      const data = params.data as unknown as PoolData;
      return data.poolName;
    },
    cellRenderer: (params: ICellRendererParams) => poolWithLinkRenderer(params, field as string),
    ...rest,
  };
}

type ProtocolData = {
  symbol: string;
};

export function protocolWithSymbolColumn<TRow>(
  columnOptions: ColumnOptions & { field?: string } = {}
): ColDef<TRow, string> {
  const { field, ...rest } = columnOptions;
  const fieldValue = field || 'protocol';

  return {
    colId: 'protocol',
    headerName: 'Protocol',
    type: 'textColumn',
    // ...(fieldValue && { field: fieldValue as keyof TRow }),
    filter: 'agSetColumnFilter',
    valueGetter: createAssetNameGetter(fieldValue as string),
    filterValueGetter: (params: ValueGetterParams<TRow, string>): string | undefined => {
      if (!params.data) return undefined;
      const data = params.data[fieldValue as keyof TRow] as ProtocolData | undefined;
      return data?.symbol;
    },
    cellRenderer: createProtocolCellRenderer(fieldValue as string),
    ...rest,
  };
}

export const exposureColumn = <
  TRow extends {
    spot?: { amount: string | number | BigNumber; price: string } | null;
    derivative?: { notional?: string | null; side: IPositionSide } | null;
  },
>({
  headerName = 'Exposure',
  sideAware,
  ...columnOptions
}: ColumnOptionsWithSideAware): ColDef<TRow> => ({
  colId: `exposure-sideAware-${sideAware}`,
  headerName: sideAwareHeaderName(headerName, sideAware),
  type: ['numericColumn', 'cashColumn'],
  valueGetter: (params: ValueGetterParams<TRow, number>): number | undefined => {
    if (!params.data) {
      return undefined;
    }

    return calculateExposure(params.data, sideAware)?.toNumber();
  },
  initialAggFunc: 'sum',
  ...columnOptions,
});

export const amountColumn = <
  TRow extends {
    spot?: { amount: string | number | BigNumber } | null;
    derivative?: { amount: string; side: IPositionSide } | null;
  },
>({
  sideAware,
  ...columnOptions
}: ColumnOptionsWithSideAware): ColDef<TRow> => ({
  colId: `amount-sideAware-${sideAware}`,
  headerName: sideAwareHeaderName('Amount', sideAware),
  type: ['numericColumn', 'extendedNumericColumn'],
  valueGetter: (params: ValueGetterParams<TRow>): number | undefined => {
    if (!params.data) {
      return undefined;
    }

    if (params.data.spot) {
      return bignumber(params.data.spot.amount).toNumber();
    }

    if (!params.data.derivative) {
      console.error('Missing data for amount of derivative', params.data);
      return undefined;
    }
    const derivative = params.data.derivative;

    const value = bignumber(derivative.amount);
    if (sideAware) {
      return value.mul(sideMultiplier(derivative.side)).toNumber();
    }

    return value.toNumber();
  },
  aggFunc: null,
  ...columnOptions,
});

export const underlyingAssetColumn = <TRow extends DerivativeAssetNameRow>(
  columnOptions: ColumnOptions = {}
): ColDef<TRow> => ({
  colId: COLUMN_IDS_WITH_ALTERNATIVE_AGG_FUNC.underlyingAsset,
  headerName: 'Underlying asset',
  type: 'textColumn',

  initialHide: true,
  cellRenderer: 'agGroupCellRenderer',
  filter: 'agSetColumnFilter',
  valueGetter: underlyingAssetValueGetter,
  filterValueGetter: (params: ValueGetterParams<TRow, string>): string | undefined => params.data?.asset?.symbol,
  cellRendererParams: {
    innerRenderer: assetCellRenderer('asset', true),
  },
  ...columnOptions,
});

export const createNameColumn = <
  Field extends string,
  T extends Record<Field, Pick<IAsset, 'type' | 'symbol' | 'name'>>,
>(
  fieldName: Field,
  columnOptions: ColumnOptions & { columnName?: string } = {}
): ColDef<T> => {
  const { columnName, ...rest } = columnOptions;
  return {
    colId: 'name',
    headerName: columnName ?? 'Name',
    type: 'textColumn',
    minWidth: 160,
    initialHide: true,
    valueGetter: createAssetNameGetter(fieldName),
    cellRenderer: createAssetCellRenderer(fieldName),
    ...rest,
  };
};

export const nameColumn = <TRow extends AssetNameRow>(columnOptions: ColumnOptions = {}): ColDef<TRow> =>
  createNameColumn('asset', columnOptions);

export const symbolColumn = <TRow extends { asset: { symbol: string } }>(
  columnOptions: ColumnOptions = {}
): ColDef<TRow> => ({
  colId: 'symbol',
  headerName: 'Symbol',
  type: 'textColumn',
  minWidth: 160,
  initialHide: true,
  valueGetter: assetSymbolGetter,
  ...columnOptions,
});

export const assetWithSymbolColumn = <TRow extends { asset: { symbol: string } }>(
  columnOptions: ColumnOptions = {}
): ColDef<TRow> => ({
  colId: COLUMN_IDS_WITH_ALTERNATIVE_AGG_FUNC.assetWithSymbol,
  headerName: 'Asset',
  type: 'textColumn',
  cellRenderer: 'agGroupCellRenderer',
  filter: 'agSetColumnFilter',
  valueGetter: assetNameGetter,
  filterValueGetter: (params: ValueGetterParams<TRow, string>): string | undefined => params.data?.asset?.symbol,
  cellRendererParams: {
    innerRenderer: assetCellRenderer('asset', true),
  },
  ...columnOptions,
});

export const slotColumn = <TRow extends SubAccountPosition<unknown>>(
  columnOptions: ColumnOptions = {}
): ColDef<TRow> => ({
  colId: 'state',
  headerName: 'State',
  type: ['textColumn'],
  valueGetter: (params: ValueGetterParams<TRow>): string | undefined => {
    if (!params.data) {
      return undefined;
    }

    const spot = params.data.spot;
    if (isNil(spot)) {
      return undefined;
    }

    return formatEnum(spot.slot);
  },
  cellRenderer: StateWithIconRenderer,
  ...columnOptions,
});

export const balanceColumn = <
  TRow extends {
    spot?: { balance: string | number | BigNumber } | null;
    derivative?: { balance?: string | null } | null;
  },
>(
  columnOptions: ColumnOptions = {}
): ColDef<TRow> => ({
  colId: 'balance',
  headerName: 'Balance',
  type: ['numericColumn', 'cashColumn'],
  valueGetter: (params: ValueGetterParams<TRow>): number | undefined => {
    if (!params.data) {
      return undefined;
    }

    return calculateBalance(params.data)?.toNumber();
  },
  initialAggFunc: 'sum',
  ...columnOptions,
});

export const navColumn = <TRow extends SubAccountPosition<unknown> & { asset: { id: string } }>(
  columnOptions: ColumnOptions = {}
): ColDef<TRow> => ({
  colId: 'nav',
  headerName: 'NAV',
  suppressHeaderMenuButton: true,
  type: ['numericColumn', 'cashColumn'],
  initialWidth: 200,
  minWidth: 200,
  width: 200,
  valueGetter: (params: ValueGetterParams<TRow, number>): number | undefined => {
    if (!params.data) return undefined;
    if (params.data.spot) {
      return bignumber(params.data.spot.balance).toNumber();
    }
    if (params.data.derivative) {
      return bignumber(params.data.derivative.balance).toNumber();
    }
    return undefined;
  },
  ...columnOptions,
});

export const navPercentageColumn = <TRow extends SubAccountPosition<unknown> & { asset: { id: string } }>({
  totalNAV,
  theme,
  minNavValue,
  maxNavValue,
  showBar,
}: {
  totalNAV: number;
  theme: Theme;
  minNavValue?: number;
  maxNavValue?: number;
  showBar?: boolean;
}): ColDef<TRow> => ({
  colId: 'nav',
  headerName: 'NAV %',
  suppressHeaderMenuButton: true,
  type: ['numericColumn', 'percentageColumn'],
  initialSort: 'desc',
  initialWidth: 200,
  minWidth: 200,
  width: 200,
  enableValue: false,
  enableRowGroup: false,
  enablePivot: false,
  comparator: (valueA: number[], valueB: number[]) => {
    const a = valueA?.[0] ?? 0;
    const b = valueB?.[0] ?? 0;
    return Math.abs(a) - Math.abs(b);
  },
  valueGetter: (params: ValueGetterParams<TRow, number[]>): number[] | undefined => {
    if (!params.data) return undefined;

    let value: number;
    if (params.data.spot) {
      value = bignumber(params.data.spot.balance).div(totalNAV).toNumber();
    } else if (params.data.derivative) {
      value = bignumber(params.data.derivative.balance).div(totalNAV).toNumber();
    } else {
      return undefined;
    }

    if (value > -NAV_PERC_MIN_DECIMAL_THRESHOLD && value < NAV_PERC_MIN_DECIMAL_THRESHOLD) {
      // Force small values to be visually positive for alignment
      return [0.000001];
    }

    return [value];
  },
  aggFunc: (params) => {
    const values = params.values as number[][];
    const sum = values.reduce((total: number, value: number[]) => total + (value?.[0] || 0), 0);
    return [sum];
  },
  valueFormatter: (params: ValueFormatterParams<TRow>) => {
    if (!params || !params.value || params.value.length === 0 || params.value[0] === undefined) return '';

    const bigNumberValue = bignumber(params.value[0]);
    const absValue = bigNumberValue.abs().toNumber();

    if (absValue < NAV_PERC_MIN_DECIMAL_THRESHOLD) {
      return NAV_PLACEHOLDER_SMALL_VALUES;
    }

    return formatPercentage(absValue, 2);
  },
  cellRenderer: showBar ? 'agSparklineCellRenderer' : undefined,
  cellRendererParams: showBar
    ? {
        sparklineOptions: {
          type: 'bar',
          direction: 'horizontal',
          axis: {
            stroke: theme.palette.primary[500],
            strokeWidth: 1,
          },
          padding: {
            top: 2,
            left: 2,
            right: 2,
          },
          label: {
            enabled: true,
            color: theme.palette.text.secondary[400] as string,
            placement: 'outside-end',
            fontSize: 12,
            formatter: (params: AgChartLabelFormatterParams<number>) => {
              const bigNumberValue = bignumber(params.value);
              const absValue = bigNumberValue.abs().toNumber();

              if (bigNumberValue.gt(0) && absValue < NAV_PERC_MIN_DECIMAL_THRESHOLD) {
                return NAV_PLACEHOLDER_SMALL_VALUES;
              }

              if (bigNumberValue.lt(0) && absValue < NAV_PERC_MIN_DECIMAL_THRESHOLD) {
                return NAV_PLACEHOLDER_SMALL_VALUES;
              }

              return formatPercentage(bigNumberValue, 2);
            },
          },
          min: minNavValue,
          max: maxNavValue ?? 1.25,
          tooltip: {
            enabled: false,
          },
          itemStyler: (params: AgBarSeriesItemStylerParams<{ x: number; y: number }>) =>
            defaultNavPercentageItemStyler(theme, params),
        } as unknown as AgSparklineOptions,
      }
    : undefined,
});

export const fdvColumn = <TRow extends SubAccountPosition<unknown> & { asset: { id: string } }>({
  metricsByAsset,
}: {
  metricsByAsset: Map<string, AssetMetricValues>;
}): ColDef<TRow> => ({
  headerName: 'FDV',
  colId: 'totalSupply',
  type: ['numericColumn', 'cashColumn'],
  valueGetter: (params: ValueGetterParams<TRow, number>): number | undefined => {
    if (!params.data) {
      return undefined;
    }
    if (params.data.spot) {
      const metricValues = metricsByAsset.get(params.data.asset.id);
      if (!metricValues) {
        return undefined;
      }
      const maxSupply = metricValues[MET_MAX_SUPPLY];
      if (!maxSupply) {
        return undefined;
      }
      return bignumber(maxSupply).mul(params.data.spot.price).toNumber();
    }
    // if DERIVATIVE do nothing
    return undefined;
  },
  aggFunc: 'avg',
});

export const balanceToMetricColumn = <TRow extends SubAccountPosition<unknown> & { asset: { id: string } }>({
  metricsByAsset,
  metricLabel,
  headerName,
}: {
  metricsByAsset: Map<string, AssetMetricValues>;
  metricLabel: string;
  headerName: string;
}): ColDef<TRow> => ({
  colId: 'balance',
  headerName,
  type: ['numericColumn', 'percentageColumn'],
  valueGetter: (params: ValueGetterParams<TRow>): number | undefined => {
    if (!params.data) {
      return undefined;
    }
    const metricValues = metricsByAsset.get(params.data.asset.id);
    if (!metricValues) {
      return undefined;
    }
    const metricValue = metricValues[metricLabel] ? bignumber(metricValues[metricLabel]).toNumber() : undefined;
    const balance = calculateBalance(params.data)?.toNumber();

    if (isNil(balance) || isNil(metricValue) || metricValue === 0) {
      return undefined;
    }

    return balance / metricValue;
  },
  initialAggFunc: 'sum',
});

export const subAccountColumn = <TRow extends { subAccount: SubAccountLabelInputAccount }>(
  columnOptions: ColumnOptions = {}
): ColDef<TRow, string> => {
  return {
    field: 'subAccount.name' satisfies ColDefField<{ subAccount: SubAccountLabelInputAccount }> as ColDefField<TRow>,
    headerName: 'Sub-account',
    type: 'textColumn',
    cellRenderer: (
      params: ICellRendererParams<{ subAccount: SubAccountLabelInputAccount }>
    ): ReactElement | undefined => {
      const rowData: { subAccount: SubAccountLabelInputAccount } | undefined = getRowDataForRowGroupColumn(params);
      if (!rowData) {
        return undefined;
      }
      const subAccount = rowData.subAccount;
      return subAccount ? (
        <SubAccountLabel subAccount={subAccount} wrap={false} size={IconVariant.MEDIUM} plain />
      ) : undefined;
    },
    ...columnOptions,
  };
};

export const accountColumn = <TRow extends { subAccount: SubAccountLabelInputAccount }>(
  columnOptions: ColumnOptions = {}
): ColDef<TRow> => ({
  // biome-ignore lint/suspicious/noExplicitAny:
  field: 'subAccount.account.name' as any,
  headerName: 'Account',
  type: 'textColumn',
  cellRenderer: (
    params: ICellRendererParams<{ subAccount: SubAccountLabelInputAccount }>
  ): ReactElement | undefined => {
    const rowData: { subAccount: SubAccountLabelInputAccount } | undefined = getRowDataForRowGroupColumn(params);
    if (!rowData) {
      return undefined;
    }
    const account = rowData.subAccount.account;
    return account ? <AccountLabel account={account} wrap={false} size={IconVariant.MEDIUM} plain /> : undefined;
  },
  ...columnOptions,
});

export const venueColumn = <TRow extends { subAccount: SubAccountLabelInputAccount }>(
  columnOptions: ColumnOptions = {}
): ColDef<TRow> => ({
  colId: 'venue',
  headerName: 'Venue',
  type: 'textColumn',
  valueGetter: (params: ValueGetterParams<TRow>): string | undefined => {
    if (!params.data) {
      return undefined;
    }
    const venueLabel = params.data.subAccount.account.venue.label;

    if (!venueLabel) {
      return undefined;
    }

    return venues[venueLabel]?.name;
  },
  cellRenderer: (
    params: ICellRendererParams<{ subAccount: SubAccountLabelInputAccount }>
  ): ReactElement | undefined => {
    const rowData: { subAccount: SubAccountLabelInputAccount } | undefined = getRowDataForRowGroupColumn(params);
    if (!rowData) {
      return undefined;
    }

    return (
      <VenueLabel
        accountName={rowData.subAccount.account.name}
        venue={rowData.subAccount.account.venue.label}
        size={IconVariant.MEDIUM}
        format="long"
      />
    );
  },
  ...columnOptions,
});

export const unrealizedPnlWithColorsColumn = <TRow extends SubAccountPosition<{ id: string }>>(
  costBasisPerAssetSubFund?: TupleKeyMap<[string, number | null], BigNumber>,
  subAccountToFunds?: (subAcc: string) => number | null,
  columnOptions: ColumnOptions = {}
): ColDef<TRow> => ({
  colId: 'unrealizedPnl-with-colors',
  headerName: 'Unrealized P&L',
  type: ['numericColumn', 'cashColumn'],
  cellDataType: 'number',
  valueGetter(params: ValueGetterParams<TRow>): number | undefined {
    if (!params.data) {
      return undefined;
    }
    const { derivative, spot, asset } = params.data;
    if (derivative) {
      return derivative.unrealizedPnl ? bignumber(derivative.unrealizedPnl)?.toNumber() : undefined;
    }

    if (!costBasisPerAssetSubFund) {
      logErrorOnce('costBasisPerAssetSubFund should be provided for spot Unrealized P&L calculation');
      return undefined;
    }

    if (!subAccountToFunds) {
      logErrorOnce('subAccountToFunds should be provided for spot Unrealized P&L calculation');
      return undefined;
    }

    const unitCostBasis = costBasisPerAssetSubFund.get([asset.id, subAccountToFunds(params.data.subAccount.id)]);
    if (spot?.amount && spot.price && unitCostBasis) {
      return calculateUnrealizedGain(bignumber(spot.amount), bignumber(spot.price), unitCostBasis).toNumber();
    }
  },
  aggFunc: 'sum',
  cellRenderer: UnrealizedPnlRenderer,
  ...columnOptions,
});

export const unrealizedPnlColumn = <TRow extends SubAccountPosition<{ id: string }>>(
  costBasisPerAssetSubFund?: TupleKeyMap<[string, number | null], BigNumber>,
  subAccountToFunds?: (subAcc: string) => number | null
): ColDef<TRow> => ({
  colId: 'unrealizedPnl',
  headerName: 'Unrealized P&L',
  type: ['numericColumn', 'cashColumn'],
  valueGetter(params: ValueGetterParams<TRow>): number | undefined {
    if (!params.data) {
      return undefined;
    }
    const { derivative, spot, asset } = params.data;
    if (derivative) {
      return derivative.unrealizedPnl ? bignumber(derivative.unrealizedPnl)?.toNumber() : undefined;
    }

    if (!costBasisPerAssetSubFund) {
      logErrorOnce('costBasisPerAssetSubFund should be provided for spot Unrealized P&L calculation');
      return undefined;
    }

    if (!subAccountToFunds) {
      logErrorOnce('subAccountToFunds should be provided for spot Unrealized P&L calculation');
      return undefined;
    }

    const unitCostBasis = costBasisPerAssetSubFund.get([asset.id, subAccountToFunds(params.data.subAccount.id)]);
    if (spot?.amount && spot.price && unitCostBasis) {
      return calculateUnrealizedGain(bignumber(spot.amount), bignumber(spot.price), unitCostBasis).toNumber();
    }
  },
  initialAggFunc: 'sum',
  enableValue: false,
});

export const costBasisColumn = <TRow extends SubAccountPosition>(
  costBasisPerAssetSubFund: TupleKeyMap<[string, number | null], BigNumber>,
  subAccountToFunds?: (subAcc: string) => number | null
): ColDef<TRow> => ({
  colId: 'costBasis',
  headerName: 'Cost basis',
  type: ['numericColumn', 'cashColumn'],
  valueGetter(params: ValueGetterParams<TRow>): number | undefined {
    if (!params.data) {
      return undefined;
    }
    const { spot, asset, derivative } = params.data;

    if (!costBasisPerAssetSubFund) {
      logErrorOnce('costBasisPerAssetSubFund should be provided for spot CostBasis P&L calculation');
      return undefined;
    }

    if (!subAccountToFunds) {
      logErrorOnce('subAccountToFunds should be provided for spot CostBasis P&L calculation');
      return undefined;
    }

    const unitCostBasis = costBasisPerAssetSubFund.get([asset.id, subAccountToFunds(params.data.subAccount.id)]);
    if (spot?.amount && unitCostBasis) {
      return unitCostBasis.mul(bignumber(spot.amount)).toNumber();
    }

    if (derivative?.unitEntryPrice) {
      return bignumber(derivative.unitEntryPrice).mul(bignumber(derivative.amount)).toNumber();
    }
  },
  initialAggFunc: 'sum',
  enableValue: false,
});

export const priceWithLast24hPriceChangeColumn = <TRow extends SubAccountPosition>(
  pricesByAssetAndSummaryType: TupleKeyMap<[string, PriceSummaryType], string>,
  summaryType: PriceSummaryType,
  priceChanges24h: Map<string, number | undefined>,
  columnOptions: ColumnOptions = {}
): ColDef<TRow> => ({
  colId: 'price-with-last-24h-price-change',
  headerName: 'Price',
  type: ['numericColumn', 'cashColumn'],
  valueGetter: (params): { price: number | undefined; priceChange: number | undefined } | undefined => {
    if (!params.data) {
      return undefined;
    }

    const assetId = params.data.asset.id;
    const price = pricesByAssetAndSummaryType.get([assetId, summaryType]);
    const priceChange = priceChanges24h.get(params.data.asset.id);
    return {
      price: price ? bignumber(price).toNumber() : undefined,
      priceChange: priceChange ? bignumber(priceChange).toNumber() : undefined,
    };
  },
  cellDataType: 'number',
  cellRenderer: priceWithLast24hPriceChangeRenderer,
  ...columnOptions,
});

export const unitCostBasisColumn = <TRow extends SubAccountPosition>(
  costBasisPerAssetSubFund: TupleKeyMap<[string, number | null], BigNumber>,
  subAccountToFunds: (subAcc: string) => number | null,
  columnOptions: ColumnOptions = {}
): ColDef<TRow> => ({
  colId: 'unitCostBasis',
  headerName: 'Entry Price',
  type: ['numericColumn', 'cashColumn'],
  valueGetter: (params: ValueGetterParams<TRow>): number | undefined => {
    if (!params.data) {
      return undefined;
    }
    const { spot, asset, derivative } = params.data;

    if (!costBasisPerAssetSubFund) {
      logErrorOnce('costBasisPerAssetSubFund should be provided for spot CostBasis P&L calculation');
      return undefined;
    }

    if (!subAccountToFunds) {
      logErrorOnce('subAccountToFunds should be provided for spot CostBasis P&L calculation');
      return undefined;
    }

    const unitCostBasis = costBasisPerAssetSubFund.get([asset.id, subAccountToFunds(params.data.subAccount.id)]);

    if (spot?.amount && unitCostBasis) {
      return unitCostBasis.toNumber();
    }

    if (derivative?.unitEntryPrice) {
      return bignumber(derivative.unitEntryPrice).toNumber();
    }
  },
  enableValue: false,
  ...columnOptions,
});

export const weightColumn = <TRow extends SubAccountPosition<unknown>>(totalBalance: BigNumber): ColDef<TRow> => ({
  colId: 'balanceContribution',
  headerName: 'Balance %',
  headerTooltip: 'Contribution to balance',
  type: ['numericColumn', 'percentageColumn'],
  valueGetter(params: ValueGetterParams<TRow>): number | undefined {
    if (!params.data) {
      return undefined;
    }
    const balance = calculateBalance(params.data);
    if (!isNil(balance)) {
      const balanceContribution = balance.div(totalBalance);
      return balanceContribution.isFinite() ? balanceContribution.toNumber() : undefined;
    }
    return undefined;
  },
  initialAggFunc: 'sum',
});

export const calculateLongShort = (pos: {
  spot?: { amount: string | number | BigNumber } | null;
  derivative?: { side: string } | null;
}): string => {
  const spot = pos.spot;
  if (spot) {
    if (bignumber(spot.amount).isNegative()) {
      return 'Short';
    }

    return 'Long';
  }

  const derivativeDetails = pos.derivative;
  if (derivativeDetails) {
    return capitalize(derivativeDetails.side);
  }

  return '';
};

export const sideColumn = <
  TRow extends {
    spot?: { amount: string | number | BigNumber } | null;
    derivative?: { side: string } | null;
  },
>(
  columnOptions: ColumnOptions = {}
): ColDef<TRow> => ({
  colId: 'side',
  headerName: 'Side',
  type: 'textColumn',
  valueGetter: (params: ValueGetterParams<TRow>): string => {
    if (!params.data) {
      return '';
    }

    return calculateLongShort(params.data);
  },
  ...columnOptions,
});

export const sideWithBadgeColumn = <
  TRow extends {
    spot?: { amount: string | number | BigNumber } | null;
    derivative?: { side: string } | null;
  },
>(
  columnOptions: ColumnOptions = {}
): ColDef<TRow> => ({
  colId: 'side-with-badge',
  headerName: 'Side',
  type: 'textColumn',
  initialWidth: 120,
  width: 120,
  valueGetter: (params: ValueGetterParams<TRow>): string => {
    if (!params.data) {
      return '';
    }
    return calculateLongShort(params.data);
  },
  valueFormatter: (params: ValueFormatterParams<TRow>): string => {
    return params.value || '';
  },
  comparator: (valueA: string, valueB: string) => {
    if (valueA === 'Long' && valueB === 'Short') return -1;
    if (valueA === 'Short' && valueB === 'Long') return 1;
    return 0;
  },
  cellRenderer: (params: ICellRendererParams<TRow>): ReactElement | undefined => {
    if (!params.value) {
      return undefined;
    }
    return (
      <Stack direction="row" alignItems="center" justifyContent="center">
        <Chip color={params.value === 'Long' ? 'success' : 'danger'} variant="outlined" size="md">
          {params.value}
        </Chip>
      </Stack>
    );
  },
  ...columnOptions,
});

export const assetTypeColumn = <
  TRow extends {
    asset: {
      type: IAssetType;
      derivativeDetails?: { derivativeType: IDerivativeType; optionType: IOptionType } | null;
    };
  },
>(
  columnOptions: ColumnOptions = {}
): ColDef<TRow> => ({
  colId: 'assetType',
  headerName: 'Asset type',
  type: 'textColumn',
  minWidth: 120,
  valueGetter: (params: ValueGetterParams<TRow>): string | undefined => {
    if (!params.data) {
      return undefined;
    }
    const { asset } = params.data;
    return formatEnum(assetToAssetType(asset));
  },
  cellRenderer: (params: ICellRendererParams<TRow>): ReactElement | undefined => {
    if (!params.value) {
      return undefined;
    }

    const chipColor = (): OverridableStringUnion<ColorPaletteProp, ChipPropsColorOverrides> => {
      switch (params.value) {
        case 'Spot':
          return 'primary';
        case formatEnum(IDerivativeTypeEnum.PerpetualFuture):
          return 'success';
        case formatEnum(IDerivativeTypeEnum.Future):
          return 'neutral';
        case formatEnum(IOptionType.Call):
          return 'warning';
        case formatEnum(IOptionType.Put):
          return 'danger';
        default:
          return 'primary';
      }
    };

    return (
      <Chip color={chipColor()} variant="soft" size="md">
        {params.value}
      </Chip>
    );
  },
  comparator: assetTypeComparator,
  pivotComparator: assetTypeComparator,
  ...columnOptions,
});

export const typeColumn = <TRow extends { spot?: unknown | null }>(
  columnOptions: ColumnOptions = {}
): ColDef<TRow> => ({
  colId: 'type',
  headerName: 'Type',
  type: 'textColumn',
  minWidth: 100,
  valueGetter: (params: ValueGetterParams<TRow>): string => {
    if (!params.data) {
      return '';
    }

    return params.data.spot ? 'Spot' : 'Derivative';
  },
  ...columnOptions,
});

export function clusterColumn<
  TRow extends {
    asset: { id: string } & FlattenUnion<AssetLabelInput>;
  },
>(cluster: string, assetAndGroupClusterMapToGroup: TupleKeyMap<[string, string], string>): ColDef<TRow> {
  const headerName =
    clusterHeaderMap[cluster] ?? cluster.replaceAll('_', ' ').replace(/\b\w/g, (char) => char.toUpperCase());

  return {
    colId: `cluster-${cluster}`,
    headerName,
    type: 'textColumn',
    initialHide: true,
    valueGetter: (params: ValueGetterParams<TRow>): string | undefined => {
      if (!params.data) {
        return undefined;
      }

      const assetId = getUnderlyingPosAssetId(params.data);
      if (!assetId) {
        return undefined;
      }

      return assetAndGroupClusterMapToGroup.get([assetId, cluster]);
    },
  };
}

export const initialMarginColumn = <TRow extends SubAccountPosition<unknown>>(): ColDef<TRow> => ({
  colId: 'initialMargin',
  headerName: 'Initial margin',
  type: ['numericColumn', 'cashColumn'],
  initialHide: true,
  valueGetter: (params: ValueGetterParams<TRow>): number | undefined =>
    params.data?.derivative?.initialMargin ? bignumber(params.data.derivative.initialMargin).toNumber() : undefined,
  initialAggFunc: 'sum',
  aggFunc: 'sum',
});

export const maintenanceMarginColumn = <TRow extends SubAccountPosition<unknown>>(): ColDef<TRow> => ({
  colId: 'maintenanceMargin',
  headerName: 'Maintenance margin',
  type: ['numericColumn', 'cashColumn'],
  initialHide: true,
  valueGetter: (params: ValueGetterParams<TRow>): number | undefined =>
    params.data?.derivative?.maintenanceMargin
      ? bignumber(params.data.derivative.maintenanceMargin).toNumber()
      : undefined,
  initialAggFunc: 'sum',
  aggFunc: 'sum',
});

export const leverageColumn = <TRow extends SubAccountPosition<unknown>>(): ColDef<TRow> => ({
  colId: 'leverage',
  headerName: 'Leverage',
  type: ['numericColumn'],
  initialHide: true,
  valueGetter: (params: ValueGetterParams<TRow>): number | undefined =>
    params.data?.derivative?.leverage ? bignumber(params.data.derivative.leverage).toNumber() : undefined,
  initialAggFunc: 'last',
});

export const notionalColumn = <TRow extends SubAccountPosition<unknown>>(
  columnOptions: ColumnOptions = {}
): ColDef<TRow> => ({
  colId: 'notional',
  headerName: 'Notional',
  type: ['numericColumn', 'cashColumn'],
  initialHide: true,
  valueGetter: (params: ValueGetterParams<TRow>): number | undefined => {
    if (!params.data) {
      return undefined;
    }
    if (params.data.spot) {
      return bignumber(params.data.spot.balance).toNumber();
    }
    if (params.data.derivative) {
      return bignumber(params.data.derivative.notional).toNumber();
    }
    return undefined;
  },
  initialAggFunc: 'sum',
  ...columnOptions,
});

export const unitMarketPriceColumn = <TRow extends SubAccountPosition<unknown>>(): ColDef<TRow> => ({
  colId: 'unitMarketPrice',
  headerName: 'Unit Market Price',
  type: ['numericColumn', 'cashColumn'],
  valueGetter: (params: ValueGetterParams<TRow>): number | undefined =>
    params.data?.derivative?.unitMarketPrice ? bignumber(params.data.derivative.unitMarketPrice).toNumber() : undefined,
});

export const unitEntryPriceColumn = <TRow extends SubAccountPosition<unknown>>(): ColDef<TRow> => ({
  colId: 'unitEntryPrice',
  headerName: 'Unit Entry Price',
  type: ['numericColumn', 'cashColumn'],
  valueGetter: (params: ValueGetterParams<TRow>): number | undefined =>
    params.data?.derivative?.unitEntryPrice ? bignumber(params.data.derivative.unitEntryPrice).toNumber() : undefined,
});

export const priceChangesGroupColumns = <TRow extends { asset: { id: string } }>(
  priceSummary: TupleKeyMap<[string, PriceSummaryType], string>,
  priceChanges24hByAssetId: Map<string, number | undefined>,
  columnOptions: ColumnOptions = {}
): ColGroupDef<TRow>[] => [
  {
    headerName: 'Price',
    marryChildren: true,
    children: [
      // for today date endpoint assets.priceForDay returns the most recent price
      priceColumn(priceSummary, PriceSummaryType.latestToday, 'Latest', columnOptions),
      minus24HoursPriceColumn(priceSummary, priceChanges24hByAssetId, columnOptions),
      priceColumn(priceSummary, PriceSummaryType.firstDayOfMonth, 'MTD', columnOptions),
      priceColumn(priceSummary, PriceSummaryType.firstDayOfQuarter, 'QTD', columnOptions),
      priceColumn(priceSummary, PriceSummaryType.firstDayOfYear, 'YTD', columnOptions),
    ],
    ...columnOptions,
  },
  {
    headerName: 'Price change %',
    marryChildren: true,
    children: [
      todayPriceChangeColumn(priceChanges24hByAssetId, columnOptions),
      priceChangeColumn(priceSummary, PriceSummaryType.firstDayOfMonth, 'MTD', 'Month to date', columnOptions),
      priceChangeColumn(priceSummary, PriceSummaryType.firstDayOfQuarter, 'QTD', 'Quarter to date', columnOptions),
      priceChangeColumn(priceSummary, PriceSummaryType.firstDayOfYear, 'YTD', 'Year to date', columnOptions),
    ],
    ...columnOptions,
  },
];

export const priceChangeAgainstAssetGroupColumns = <TRow extends { asset: { id: string } }>(
  priceSummary: TupleKeyMap<[string, PriceSummaryType], string>,
  priceChanges24hByAssetId: Map<string, number | undefined>,
  compareAsset: { id: string; name: string },
  columnOptions: ColumnOptions = {}
): ColGroupDef<TRow>[] => [
  {
    headerName: `Perf vs ${compareAsset.name}`,
    marryChildren: true,
    children: [
      minus24hoursPriceAgainstAssetColumn(priceSummary, priceChanges24hByAssetId, compareAsset, columnOptions),
      priceChangeAgainstAssetColumn(
        priceSummary,
        PriceSummaryType.firstDayOfMonth,
        'MTD',
        'Month to date',
        compareAsset,
        columnOptions
      ),
      priceChangeAgainstAssetColumn(
        priceSummary,
        PriceSummaryType.firstDayOfQuarter,
        'QTD',
        'Quarter to date',
        compareAsset,
        columnOptions
      ),
      priceChangeAgainstAssetColumn(
        priceSummary,
        PriceSummaryType.firstDayOfYear,
        'YTD',
        'Year to date',
        compareAsset,
        columnOptions
      ),
    ],
  },
];

export const priceChangeAgainstAssetColumn = <TRow extends { asset: { id: string }; derivative?: { id: string } }>(
  pricesByAssetAndDate: TupleKeyMap<[string, PriceSummaryType], string>,
  pastSummary: PriceSummaryType,
  headerName: string,
  headerTooltip: string,
  compareAsset: { id: string; name: string },
  columnOptions: ColumnOptions = {}
): ColDef<TRow> => {
  return {
    headerName,
    colId: `perf-against-${compareAsset.name}-${headerName}`,
    type: ['numericColumn', 'percentageColumn'],
    headerTooltip,
    initialHide: false,
    initialAggFunc: 'avg',
    valueGetter: (params: ValueGetterParams<TRow>): number | undefined => {
      if (!params.data) {
        return undefined;
      }

      const assetId = params.data.asset.id;
      const pastPrice = pricesByAssetAndDate.get([assetId, pastSummary]);
      const compareAssetPastPrice = pricesByAssetAndDate.get([compareAsset.id, pastSummary]);
      const todayPrice = pricesByAssetAndDate.get([assetId, PriceSummaryType.latestToday]);
      const compareAssetTodayPrice = pricesByAssetAndDate.get([compareAsset.id, PriceSummaryType.latestToday]);
      if (!pastPrice || !todayPrice || !compareAssetPastPrice || !compareAssetTodayPrice) {
        return undefined;
      }
      const relativeVariation = relativeChange(bignumber(todayPrice), bignumber(pastPrice));
      const compareRelativeVariation = relativeChange(
        bignumber(compareAssetTodayPrice),
        bignumber(compareAssetPastPrice)
      );
      if (!relativeVariation || !compareRelativeVariation) {
        return undefined;
      }
      return relativeVariation.sub(compareRelativeVariation).toNumber();
    },
    aggFunc: 'avg',
    ...columnOptions,
  };
};

export const todayPriceChangeColumn = <TRow extends { asset: { id: string }; derivative?: { id: string } }>(
  priceChanges24h: Map<string, number | undefined>,
  columnOptions: ColumnOptions = {}
): ColDef<TRow> => ({
  headerName: '24h',
  colId: 'price-change-24h',
  type: ['numericColumn', 'percentageColumn'],
  initialAggFunc: 'avg',
  valueGetter: (params: ValueGetterParams<TRow>): number | undefined => {
    if (!params.data) {
      return undefined;
    }

    return priceChanges24h.get(params.data.asset.id);
  },
  aggFunc: 'avg',
  ...columnOptions,
});

export const priceColumn = <TRow extends { asset: { id: string }; derivative?: { id: string } }>(
  pricesByAssetAndSummaryType: TupleKeyMap<[string, PriceSummaryType], string>,
  summaryType: PriceSummaryType,
  priceDateForHeader: string,
  columnOptions: ColumnOptions = {}
): ColDef<TRow> => {
  return {
    headerName: priceDateForHeader,
    colId: `past-price-${priceDateForHeader}`,
    type: ['numericColumn', 'cashColumn'],
    initialAggFunc: 'avg',
    valueGetter: (params): number | undefined => {
      if (!params.data) {
        return undefined;
      }

      const assetId = params.data.asset.id;
      const price = pricesByAssetAndSummaryType.get([assetId, summaryType]);

      if (!price) {
        return undefined;
      }

      return bignumber(price).toNumber();
    },
    aggFunc: 'avg',
    ...columnOptions,
  };
};

export const minus24hoursPriceAgainstAssetColumn = <
  TRow extends { asset: { id: string }; derivative?: { id: string } },
>(
  priceSummary: TupleKeyMap<[string, PriceSummaryType], string>,
  priceChanges24h: Map<string, number | undefined>,
  compareAsset: { id: string; name: string },
  columnOptions: ColumnOptions = {}
): ColDef<TRow> => ({
  headerName: '24h',
  colId: 'past-price-24h-against-asset',
  type: ['numericColumn', 'percentageColumn'],
  initialAggFunc: 'avg',
  valueGetter: (params): number | undefined => {
    if (!params.data) {
      return undefined;
    }

    const assetId = params.data.asset.id;
    const todayPrice = priceSummary.get([assetId, PriceSummaryType.latestToday]);
    const priceChange = priceChanges24h.get(assetId);

    if (!todayPrice || priceChange === undefined) {
      return undefined;
    }

    const compareAssetTodayPrice = priceSummary.get([compareAsset.id, PriceSummaryType.latestToday]);
    const comparePriceChange = priceChanges24h.get(compareAsset.id);

    if (!compareAssetTodayPrice || comparePriceChange === undefined) {
      return undefined;
    }

    // Calculate the relative price change for the asset
    const relativeVariation = relativeChange(bignumber(todayPrice), bignumber(todayPrice).minus(priceChange));

    // Calculate the relative price change for BTC (compare asset)
    const compareRelativeVariation = relativeChange(
      bignumber(compareAssetTodayPrice),
      bignumber(compareAssetTodayPrice).minus(comparePriceChange)
    );

    if (!relativeVariation || !compareRelativeVariation) {
      return undefined;
    }

    return relativeVariation.sub(compareRelativeVariation).toNumber();
  },
  aggFunc: 'avg',
  ...columnOptions,
});

export const minus24HoursPriceColumn = <TRow extends { asset: { id: string }; derivative?: { id: string } }>(
  priceSummary: TupleKeyMap<[string, PriceSummaryType], string>,
  priceChanges24h: Map<string, number | undefined>,
  columnOptions: ColumnOptions = {}
): ColDef<TRow> => ({
  headerName: '24h',
  colId: 'past-price-24h',
  type: ['numericColumn', 'cashColumn'],
  initialAggFunc: 'avg',
  valueGetter: (params): number | undefined => {
    if (!params.data) {
      return undefined;
    }

    const assetId = params.data.asset.id;
    const todayPrice = priceSummary.get([assetId, PriceSummaryType.latestToday]);
    const priceChange = priceChanges24h.get(assetId);

    if (!todayPrice || !priceChange) {
      return undefined;
    }
    return reverseRelativeChange(bignumber(todayPrice), bignumber(priceChange)).toNumber();
  },
  aggFunc: 'avg',
  ...columnOptions,
});

export const priceChangeColumn = <TRow extends { asset: { id: string }; derivative?: { id: string } }>(
  pricesByAssetAndDate: TupleKeyMap<[string, PriceSummaryType], string>,
  pastSummary: PriceSummaryType,
  headerName: string,
  headerTooltip: string,
  columnOptions: ColumnOptions = {}
): ColDef<TRow> => ({
  headerName,
  headerTooltip,
  colId: `price-change-${headerName}`,
  type: ['numericColumn', 'percentageColumn'],
  initialAggFunc: 'avg',
  valueGetter: (params): number | undefined => {
    if (!params.data) {
      return undefined;
    }

    const assetId = params.data.asset.id;
    const pastPrice = pricesByAssetAndDate.get([assetId, pastSummary]);
    const todayPrice = pricesByAssetAndDate.get([assetId, PriceSummaryType.latestToday]);
    if (!pastPrice || !todayPrice) {
      return undefined;
    }

    return relativeChange(bignumber(todayPrice), bignumber(pastPrice))?.toNumber();
  },
  aggFunc: 'avg',
  ...columnOptions,
});

export type AssetMetricValues = IAssetRiskMetricsQuery['assets']['details'][number]['metrics'];

export type MetricColumnConfig = {
  metricLabel: string;
  params?: MetricParams;
  initialHide: boolean;
};

export function assetMetricColumn<TRow extends { asset: { id: string } }>(
  metricsByAsset: Map<string, AssetMetricValues>,
  metricLabel: string,
  initialHide: boolean
): ColDef<TRow, number> {
  return {
    colId: metricLabel,
    headerName: getName(metricLabel, undefined, true),
    initialHide,
    type: ['numericColumn', 'extendedNumericColumn'],
    valueFormatter: (params): string => {
      const metricValue = getValueFormatterCellValue(params.value);
      if (isNil(metricValue)) {
        return '';
      }
      return formatMetricValue(metricLabel, metricValue);
    },
    valueGetter: (params): number | undefined => {
      if (!params.data) {
        return undefined;
      }
      const metricValues = metricsByAsset.get(params.data.asset.id);
      if (isNil(metricValues)) {
        return undefined;
      }
      return metricValues[metricLabel] ? bignumber(metricValues[metricLabel]).toNumber() : undefined;
    },
  };
}

export type PortfolioRiskMetricReportMapKey = {
  assetId: string;
  window: number;
  metricLabel: string;
  benchmark: string;
};

export type PortfolioRiskMetricReportMapValue = {
  value: string;
  seriesTotal: string;
};

export function portfolioMetricContributionColumn<TRow extends { asset: { id: string } }>({
  portfolioMetricValues,
  metricLabel,
  window,
  benchmark,
  ofTotal,
  ...columnOptions
}: {
  portfolioMetricValues: ObjectKeyMap<PortfolioRiskMetricReportMapKey, PortfolioRiskMetricReportMapValue>;
  metricLabel: string;
  window: number;
  benchmark: { id: string; symbol: string } | undefined;
  ofTotal: boolean;
} & ColumnOptions): ColDef<TRow> {
  const params = benchmark ? { assetSymbol: benchmark.symbol } : undefined;
  const metricName = getName(metricLabel, params);
  const headerSuffix = ofTotal ? ' of total' : '';
  return {
    colId: metricLabel,
    headerName: metricName + headerSuffix,
    ...columnOptions,
    type: ['numericColumn', 'extendedNumericColumn'],
    valueFormatter: (params): string => {
      if (!params.value) {
        return '';
      }
      return ofTotal ? formatPercentage(params.value) : formatMetricValue(metricLabel, params.value);
    },
    valueGetter: (params: ValueGetterParams<TRow>): number | undefined => {
      if (!params.data) {
        return undefined;
      }

      const metricValue = portfolioMetricValues.get({
        assetId: params.data.asset.id,
        window,
        metricLabel,
        benchmark: benchmark?.id ?? '',
      });
      if (!metricValue) {
        return undefined;
      }
      const value = bignumber(metricValue.value);
      if (ofTotal) {
        return value.toNumber();
      }
      return value.mul(metricValue.seriesTotal).toNumber();
    },
  };
}

export const subFundGroupColumn = <TRow extends { subAccount: SubAccountLabelInputAccount }>(
  subFundDimension: string,
  subAccountAndDimensionToFund: TupleKeyMap<[string, string], string>
): ColDef<TRow> => ({
  colId: `subFund-group-dimension-${subFundDimension}`,
  headerName: subFundDimension,
  type: 'textColumn',
  hide: true,
  valueGetter: (params): string | undefined => {
    if (!params.data) {
      return undefined;
    }

    const subAccountId = params.data.subAccount.id;
    return subAccountAndDimensionToFund.get([subAccountId, subFundDimension]);
  },
});

const getFormattedDate = <T, F extends ColDefField<T, UtcDate>>(
  field: F,
  format: string
): ValueGetterFunc<T, string | undefined> => {
  return (params: ValueGetterParams<T>): string | undefined => {
    if (!params.data) {
      return undefined;
    }

    return parseUtcDate(get(field, params.data)).format(format);
  };
};

const dayFormat = 'DD';
const isoWeekFormat = 'W';
const monthFormat = 'MMMM';
const yearFormat = 'YYYY';

const dayComparator = (valueA: string, valueB: string): number => {
  return Number.parseInt(valueA) - Number.parseInt(valueB);
};

const isoWeekComparator = (valueA: string, valueB: string): number => {
  return dayjs(valueA, [isoWeekFormat]).valueOf() - dayjs(valueB, [isoWeekFormat]).valueOf();
};

const monthComparator = (valueA: string, valueB: string): number => {
  return dayjs(valueA, [monthFormat]).valueOf() - dayjs(valueB, [monthFormat]).valueOf();
};

const yearComparator = (valueA: string, valueB: string): number => {
  return Number.parseInt(valueA) - Number.parseInt(valueB);
};

export const yearMonthDayColumns = <RowData, F extends ColDefField<RowData, UtcDate> = ColDefField<RowData, UtcDate>>({
  field,
  show,
}: { field: F; show: { day?: boolean; week?: boolean } }): ColDef<RowData> & ColGroupDef<RowData> => {
  const dateColumn: ColDef<RowData> = {
    headerName: 'Date',
    colId: 'date-date',
    rowGroup: false,
    type: 'dateColumn',
    hide: true,
    valueGetter: (params: ValueGetterParams<RowData>): string | undefined =>
      params.data ? dateReadableValueGetter(get(field, params.data)) : undefined,
  };

  const dayColumn: ColDef<RowData> = {
    headerName: 'Day',
    enableRowGroup: true,
    colId: 'date-day',
    valueGetter: getFormattedDate(field, dayFormat),
    chartDataType: 'category' as const,
    filter: 'agSetColumnFilter',
    comparator: dayComparator,
    pivotComparator: dayComparator,
    pivot: false,
    rowGroup: true,
    filterParams: {
      comparator: dayComparator,
    },
  };

  const isoWeekColumn: ColDef<RowData> = {
    headerName: 'Week',
    enableRowGroup: true,
    colId: 'date-week',
    valueGetter: getFormattedDate(field, isoWeekFormat),
    chartDataType: 'category' as const,
    filter: 'agSetColumnFilter',
    comparator: isoWeekComparator,
    pivotComparator: isoWeekComparator,
    hide: true,
    pivot: false,
    rowGroup: true,
    filterParams: {
      comparator: isoWeekComparator,
    },
  };

  const monthColumn: ColDef<RowData> = {
    headerName: 'Month',
    enableRowGroup: true,
    colId: 'date-month',
    valueGetter: getFormattedDate(field, monthFormat),
    chartDataType: 'category' as const,
    filter: 'agSetColumnFilter',
    comparator: monthComparator,
    pivotComparator: monthComparator,
    pivot: false,
    rowGroup: true,
    filterParams: {
      comparator: monthComparator,
    },
  };

  const yearColumn: ColDef<RowData> = {
    headerName: 'Year',
    enableRowGroup: true,
    colId: 'date-year',
    valueGetter: getFormattedDate(field, yearFormat),
    chartDataType: 'category' as const,
    filter: 'agSetColumnFilter',
    comparator: yearComparator,
    pivotComparator: yearComparator,
    pivot: false,
    rowGroup: true,
    filterParams: {
      comparator: yearComparator,
    },
  };

  const children = [yearColumn, monthColumn, isoWeekColumn, dayColumn, dateColumn].filter((col) => {
    if (col.colId === dayColumn.colId) {
      return show.day;
    }

    if (col.colId === isoWeekColumn.colId) {
      return show.week;
    }

    return true;
  });

  return {
    headerName: 'Date',
    colId: 'date',
    field: field,

    enableRowGroup: true,
    chartDataType: 'series' as const,

    children,
  };
};

import isNil from 'lodash/fp/isNil';
import type { ReactElement } from 'react';
import { memo } from 'react';

import { createAutocompleteProps } from './CreateAutocompleteProps.tsx';
import GSingleAutocomplete from './GSingleAutocomplete';
import type { StaticAutocompleteOption, StaticSingleAutocompleteProps } from './StaticSingleAutocomplete.props';
import isEqual from 'lodash/fp/isEqual';

function StaticSingleAutocomplete<TValue>(props: StaticSingleAutocompleteProps<TValue>): ReactElement {
  const matchingValue = props.options.find((opt) => {
    if (isNil(props.value)) {
      return false;
    }

    if (props.isValueEqual) {
      return props.isValueEqual(opt.value, props.value);
    }

    return isEqual(opt.value, props.value);
  });

  const restProps = {
    ...createAutocompleteProps(props),
    getOptionLabelIcon: (value: StaticAutocompleteOption<TValue>): ReactElement | undefined => value.icon,
  };

  const finalValue = isNil(matchingValue)
    ? null
    : {
        ...matchingValue,
        toJSON() {
          // mui autocomplete json stringifies values which are not present in options, which fails when option contains markup
          return JSON.stringify({
            ...matchingValue,
            label: 'skippedInJsonStringify',
            icon: 'skippedInJsonStringify',
          });
        },
      };

  return (
    <GSingleAutocomplete<StaticAutocompleteOption<TValue>>
      {...props}
      {...restProps}
      value={finalValue}
      onChange={(option: StaticAutocompleteOption<TValue> | null): void => {
        const finalOpt = option as StaticAutocompleteOption<TValue> | undefined | null;
        props.onChange?.(finalOpt ? finalOpt.value : null);
      }}
      ref={props.ref}
    />
  );
}

const ForwardedStaticSingleAutocompleteMemoized = memo(StaticSingleAutocomplete) as typeof StaticSingleAutocomplete;

export default ForwardedStaticSingleAutocompleteMemoized;

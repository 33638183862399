import { Grid, Stack, Typography } from '@mui/joy';
import isNil from 'lodash/fp/isNil';
import type { ReactElement, ReactNode } from 'react';

import Tile, { TileContent } from 'components/technical/Tile/Tile';
import type { IGreeks } from '../../../../generated/graphql';
import { formatCash, formatNumber } from '../../../formatter.utils';
import HeaderBar from '../../../technical/HeaderBar/HeaderBar.tsx';

const OptionsTile = ({
  children,
  title,
  variant,
}: {
  children: ReactNode;
  title: string;
  variant: 'small' | 'large';
}): ReactElement => {
  return (
    <Grid md={variant === 'small' ? 2 : 6}>
      <Tile>
        <TileContent title={title}>
          <Typography level="h3" component="div">
            {children}
          </Typography>
        </TileContent>
      </Tile>
    </Grid>
  );
};

const OptionsStressTestPortfolioValue = ({
  data,
}: {
  data: {
    npvPnl: number;
    marketPnl?: number | null;
    greeks: Omit<IGreeks, '__typename'>;
  };
}): ReactElement => {
  return (
    <>
      <HeaderBar title="Portfolio value" />
      <Stack spacing={1.5}>
        <Grid container>
          <OptionsTile variant="large" title="P&L (net present value)">
            {formatCash(data.npvPnl)}
          </OptionsTile>
          {!isNil(data.marketPnl) && (
            <OptionsTile variant="large" title="P&L (market value)">
              {formatCash(data.marketPnl)}
            </OptionsTile>
          )}
        </Grid>
        <Grid container>
          <OptionsTile variant="small" title="Delta">
            {formatNumber(data.greeks.delta)}
          </OptionsTile>
          <OptionsTile variant="small" title="Gamma">
            {formatNumber(data.greeks.gamma)}
          </OptionsTile>
          <OptionsTile variant="small" title="Rho">
            {formatNumber(data.greeks.rho)}
          </OptionsTile>
          <OptionsTile variant="small" title="Vega">
            {formatNumber(data.greeks.vega)}
          </OptionsTile>
          <OptionsTile variant="small" title="Theta">
            {formatNumber(data.greeks.theta)}
          </OptionsTile>
        </Grid>
      </Stack>
    </>
  );
};

export default OptionsStressTestPortfolioValue;

import { Add } from '@mui/icons-material';
import type { ReactElement, MouseEvent } from 'react';
import { widgetDescriptions } from './widgetDescription.utils.tsx';
import { v4 } from 'uuid';
import { CustomWidgetCard } from './CustomWidgetCard.tsx';
import { updateWidgetState } from './CustomWidgetContext.tsx';
import type { Widget } from 'components/technical/widget/WidgetContainer.tsx';
import { useCreateWidgetMutation } from '../../../generated/graphql.tsx';
import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';
import { ListItemDecorator, Stack, Typography, Menu, MenuItem, Button } from '@mui/joy';
import omit from 'lodash/fp/omit';
import { useState } from 'react';

const WidgetOption = ({
  title,
  description,
  icon: Icon,
  onSelect,
}: { title: string; description: string; icon: () => ReactElement; onSelect: () => void }): ReactElement => {
  return (
    <MenuItem data-tour="widget-list-item" onClick={onSelect} sx={{ width: 300 }}>
      <Stack direction={'row'} gap={1} p={0.5}>
        <ListItemDecorator>
          <Icon />
        </ListItemDecorator>
        <div>
          <Typography level="title-md">{title}</Typography>
          <Typography level="body-sm">{description}</Typography>
        </div>
      </Stack>
    </MenuItem>
  );
};

const AddCustomWidgetButton = ({
  onWidgetAdded,
  onRemovedWidget,
  page,
}: {
  onWidgetAdded: (widget: Widget) => void;
  onRemovedWidget: (id: string) => Promise<void>;
  page: string;
}): ReactElement => {
  const widgetStateUpdater = updateWidgetState();
  const [addWidget] = useCreateWidgetMutation();
  const feedback = useFeedback();
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  const onSelectWidget = async (widgetName: string) => {
    const foundWidgetDescription = widgetDescriptions.find((desc) => desc.name === widgetName);
    if (!foundWidgetDescription) {
      console.warn('Cannot find widget', widgetName);
      return;
    }

    const widgetId = v4();
    const layout = {
      x: 0,
      y: 0,
      w: foundWidgetDescription.initialW ?? 3,
      h: foundWidgetDescription.initialH ?? 3,
    };

    const title = foundWidgetDescription.initialState?.title ?? foundWidgetDescription.name;
    const initialData = omit(['title'], foundWidgetDescription.initialState ?? {});

    try {
      await addWidget({
        variables: {
          input: {
            id: widgetId,
            page,
            title: title,
            type: foundWidgetDescription.type,
            layout,
            data: initialData,
          },
        },
      });
    } catch (e) {
      feedback.showGraphqlError(e);
      throw e;
    }

    await widgetStateUpdater({
      id: widgetId,
      state: {
        ...initialData,
        title: title,
      },
      updateBackend: false,
    });

    onWidgetAdded({
      id: widgetId,
      type: foundWidgetDescription.type,
      component: ({ id, editing, forceStatic }) => (
        <CustomWidgetCard
          justCreated={true}
          id={id}
          editing={editing}
          viewComponent={foundWidgetDescription.viewComponent}
          settingsComponent={foundWidgetDescription.settingsComponent}
          onRemovedWidget={async () => {
            await onRemovedWidget(id);
          }}
          forceStatic={forceStatic}
        />
      ),
      layout,
    });

    // Close menu after selection
    handleCloseMenu();
  };

  return (
    <>
      <Button startDecorator={<Add />} onClick={handleOpenMenu} data-tour="widget-dropdown-button">
        Add Widget
      </Button>

      <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={handleCloseMenu} data-tour="widget-dropdown-menu">
        {widgetDescriptions.map((widgetDescription) => (
          <WidgetOption
            key={widgetDescription.name}
            title={widgetDescription.name}
            description={widgetDescription.description}
            icon={widgetDescription.icon}
            onSelect={() => onSelectWidget(widgetDescription.name)}
          />
        ))}
      </Menu>
    </>
  );
};

export default AddCustomWidgetButton;

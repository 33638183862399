import { Grid, Stack, Tab, TabList, TabPanel, Tabs } from '@mui/joy';
import type { FunctionComponent } from 'react';
import { defaultRowSpacing } from '../../StackSpacing.ts';
import GridCard from 'components/technical/GCard/GridCard.tsx';
import OptimizationCard from './OptimizationCard.tsx';
import { useNavigate } from 'react-router';
import {
  portfolioOptimizerLinkNewPortfolioOptimization,
  portfolioOptimizerLinkNewStrategyOptimization,
  portfolioOptimizerLinkNewYieldOptimization,
} from 'components/technical/Sidebar/Menu.tsx';
import { OptimizationType } from './optimization.utils.ts';
import { usePortfolioOptimizationListSuspenseQuery } from '../../../generated/graphql.tsx';

const gridWidth = { sm: 12, md: 6, lg: 4, xl: 3 };
export const optimizationCardHeight = '17rem';

const PortfolioOptimizerDashboard: FunctionComponent = () => {
  const query = usePortfolioOptimizationListSuspenseQuery();
  const navigate = useNavigate();

  return (
    <Stack gap={defaultRowSpacing}>
      <Tabs aria-label="Tabs" defaultValue={0}>
        <TabList>
          <Tab>Portfolio optimizations</Tab>
          <Tab>Strategy optimizations</Tab>
          <Tab>Yield optimizations</Tab>
        </TabList>
        <TabPanel value={0}>
          <Grid container spacing={3}>
            <GridCard
              height={optimizationCardHeight}
              gridWidth={gridWidth}
              addNewText="Run new optimization"
              onClick={(): void => navigate(portfolioOptimizerLinkNewPortfolioOptimization)}
            />
            {query.data.portfolioOptimization.listOptimizations.map((optimization) => (
              <OptimizationCard
                optimizationType={OptimizationType.asset}
                key={optimization.optimizationId}
                optimization={optimization}
                gridWidth={gridWidth}
              />
            ))}
          </Grid>
        </TabPanel>
        <TabPanel value={1}>
          <Grid container spacing={3}>
            <GridCard
              height={optimizationCardHeight}
              gridWidth={gridWidth}
              addNewText="Run new optimization"
              onClick={(): void => navigate(portfolioOptimizerLinkNewStrategyOptimization)}
            />
            {query.data.fundOptimization.listOptimizations.map((optimization) => (
              <OptimizationCard
                optimizationType={OptimizationType.portfolio}
                key={optimization.optimizationId}
                optimization={optimization}
                gridWidth={gridWidth}
              />
            ))}
          </Grid>
        </TabPanel>
        <TabPanel value={2}>
          <Grid container spacing={3}>
            <GridCard
              height={optimizationCardHeight}
              gridWidth={gridWidth}
              addNewText="Run new optimization"
              onClick={(): void => navigate(portfolioOptimizerLinkNewYieldOptimization)}
            />
            {query.data.yieldOptimization.listOptimizations.map((optimization) => (
              <OptimizationCard
                optimizationType={OptimizationType.yield}
                key={optimization.optimizationId}
                optimization={optimization}
                gridWidth={gridWidth}
              />
            ))}
          </Grid>
        </TabPanel>
      </Tabs>
    </Stack>
  );
};

export default PortfolioOptimizerDashboard;

import { Modal, ModalDialog, Stack } from '@mui/joy';
import type { ReactElement, ReactNode } from 'react';
import { useForm } from 'react-hook-form';

import GDialogHeader from '../../GDialog/GDialogHeader.tsx';
import GButton from '../../inputs/GButton/GButton';
import GFormProvider from '../GFormProvider';
import { GraphQLApiFormErrorMessage } from '../GraphQLApiErrorMessage';
import SubmitButton from '../SubmitButton.tsx';
import { useGraphQLApiError } from '../UseGraphQLApiError.tsx';

type FormState = Record<string, never>;

const ConfirmationDialog = <T,>({
  onClose,
  onApprove,
  children,
  title = 'Confirmation',
  okButtonText = 'Yes',
  cancelButtonText = 'No',
}: {
  onClose: () => void;
  onApprove: (() => Promise<T>) | (() => T);
  children: ReactNode;
  title?: string;
  okButtonText?: string;
  cancelButtonText?: string;
}): ReactElement => {
  const methods = useForm<FormState>();
  const { onErrorAndThrow } = useGraphQLApiError(methods);

  const handleFormSubmit = async (): Promise<void> => {
    try {
      await onApprove();
    } catch (e) {
      onErrorAndThrow(e);
    }
  };

  return (
    <Modal open={true} onClose={onClose}>
      <ModalDialog>
        <GFormProvider {...methods}>
          <form
            onSubmit={(e) => {
              // Confirmation dialog can be used inside outer forms.
              // we don't want approving the dialog to propagate submiting outer form
              e.stopPropagation();
              methods.handleSubmit(handleFormSubmit)(e);
            }}
          >
            <GDialogHeader>{title}</GDialogHeader>
            <Stack spacing={1.5}>
              {children}
              <Stack alignItems="center" spacing={1.5}>
                <GraphQLApiFormErrorMessage />
                <Stack direction="row" spacing={1.5}>
                  <SubmitButton width="smaller">{okButtonText}</SubmitButton>
                  <GButton
                    type="button"
                    variant="soft"
                    color="neutral"
                    onClick={onClose}
                    width="smaller"
                    disabled={methods.formState.isSubmitting}
                  >
                    {cancelButtonText}
                  </GButton>
                </Stack>
              </Stack>
            </Stack>
          </form>
        </GFormProvider>
      </ModalDialog>
    </Modal>
  );
};

export default ConfirmationDialog;

import { Stack, Switch, type SwitchProps, Typography } from '@mui/joy';
import type { ReactElement, ReactNode, Ref } from 'react';

export type GSwitchProps = {
  label?: ReactNode;
  matchInputWithLabelHeight?: boolean;
  value: boolean;
  preLabel?: ReactNode;
  ref?: Ref<HTMLDivElement>;
} & Omit<SwitchProps, 'checked'>;

const GSwitchLabel = ({
  text,
  startDecorator,
  matchInputWithLabelHeight,
}: {
  text: ReactNode;
  startDecorator?: ReactNode;
  matchInputWithLabelHeight?: boolean;
}): ReactElement => {
  return (
    <Typography
      component="label"
      level="body-xs"
      sx={
        matchInputWithLabelHeight
          ? {
              mt: '2rem', // value hardcoded in joy ui input styles for a variable '--Input-minHeight
              mb: '0.5rem', // value hardcoded in joy ui input styles for a variable '--Input-minHeight
            }
          : {}
      }
      startDecorator={startDecorator}
    >
      {text}
    </Typography>
  );
};

const GSwitch = ({ matchInputWithLabelHeight, preLabel, label, ref, ...props }: GSwitchProps): ReactElement => {
  return (
    <Stack direction={'row'} flexWrap={'nowrap'} gap={0.5}>
      {preLabel && <GSwitchLabel matchInputWithLabelHeight={matchInputWithLabelHeight} text={preLabel} />}
      <GSwitchLabel
        startDecorator={<Switch {...props} ref={ref} checked={props.value} />}
        matchInputWithLabelHeight={matchInputWithLabelHeight}
        text={label}
      />
    </Stack>
  );
};

export default GSwitch;

import GAgGrid from '../../../../technical/grids/GAgGrid.tsx';
import type { ColDef, ColGroupDef, IAggFuncParams, ValueGetterParams } from 'ag-grid-enterprise';
import { createAssetCellRenderer, createAssetNameGetter } from '../../../../technical/grids/agGrid.utils.tsx';
import type { OptimizationOutput } from './YieldOptimizerResult.types.ts';
import bigNumMath from 'bigNumMath.ts';
import { protocolWithSymbolColumn } from 'components/technical/grids/SharedReportColumns.tsx';
import { poolWithLink } from 'components/technical/grids/SharedReportColumns.tsx';

type RowType = OptimizationOutput['allocations'][number];

const DEFAULT_COL_DEF = {
  resizable: true,
  sortable: true,
  filter: true,
};

const columns: (ColDef<RowType> | ColGroupDef<RowType>)[] = [
  poolWithLink<RowType>({
    pinned: 'left',
    field: 'pool',
    initialWidth: 120,
  }),
  protocolWithSymbolColumn<RowType>({
    field: 'pool.protocol',
  }),
  {
    colId: 'chain',
    headerName: 'Chain',
    type: 'textColumn',
    field: 'pool.chain',
    initialWidth: 120,
  },
  {
    colId: 'collateralAsset',
    headerName: 'Collateral',
    type: 'textColumn',
    initialSort: 'desc',
    hide: true,
    initialRowGroup: true,
    initialRowGroupIndex: 1,
    initialWidth: 150,
    valueGetter: createAssetNameGetter('pool.collateralAsset'),
    cellRenderer: createAssetCellRenderer('pool.collateralAsset'),
  },
  {
    colId: 'underlyingAsset',
    type: 'textColumn',
    headerName: 'Underlying',
    pinned: 'left',
    hide: true,
    initialRowGroup: true,
    initialRowGroupIndex: 0,
    initialWidth: 300,
    valueGetter: createAssetNameGetter('pool.underlyingAsset'),
    cellRenderer: createAssetCellRenderer('pool.underlyingAsset'),
  },
  {
    colId: 'amount',
    headerName: 'Amount',
    field: 'absoluteAllocationInCollateral',
    aggFunc: 'sum',
    initialWidth: 150,
    type: ['numericColumn', 'extendedNumericColumn'],
  },
  {
    colId: 'amountInUsd',
    headerName: 'Value in USD',
    initialWidth: 150,
    field: 'dollarValueOfAllocation',
    aggFunc: 'sum',
    type: ['numericColumn', 'cashColumn'],
  },
  {
    colId: 'allocationPercentage',
    headerName: '% of collateral',
    field: 'percentageAllocationInCollateral',
    type: ['numericColumn', 'percentageColumn'],
  },
  {
    colId: 'allocationPercentageInUsd',
    headerName: '% of total collateral USD',
    field: 'percentageAllocationInUsd',
    aggFunc: 'sum',
    type: ['numericColumn', 'percentageColumn'],
  },
  {
    colId: 'apy',
    headerName: 'APY',
    initialWidth: 120,
    field: 'pool.metrics.apy',
    type: ['numericColumn', 'percentageColumn'],
  },
  {
    colId: 'baseApy',
    headerName: 'Base APY',
    field: 'baseApy',
    type: ['numericColumn', 'percentageColumn'],
  },
  {
    colId: 'apyReward',
    headerName: 'Reward APY',
    field: 'pool.metrics.apyReward',
    type: ['numericColumn', 'percentageColumn'],
  },
  {
    colId: 'impactedApy',
    headerName: 'Impacted APY',
    field: 'impactedApy',
    initialWidth: 100,
    filter: 'agNumberColumnFilter',
    aggFunc: function impactedApyAggregate(
      params: IAggFuncParams<
        RowType,
        {
          totalYieldInUsd: number;
          totalAmountInUsd: number;
          value: number;
        }
      >
    ) {
      let value: number;
      const totalYieldInUsd = bigNumMath.sum(params.values.map((v) => v?.totalYieldInUsd ?? 0));
      const totalAmountInUsd = bigNumMath.sum(params.values.map((v) => v?.totalAmountInUsd ?? 0));

      if (totalAmountInUsd === 0) {
        value = 0;
      } else {
        value = totalYieldInUsd / totalAmountInUsd;
      }
      return {
        totalYieldInUsd,
        totalAmountInUsd,
        value,
      };
    },
    comparator: function impactedApyComparator(valueA, valueB, isDescending) {
      return (valueA.value - valueB.value) * (isDescending ? -1 : 1);
    },
    valueGetter: function impactedApyValueGetter(
      params: ValueGetterParams<
        RowType,
        {
          totalYieldInUsd: number;
          totalAmountInUsd: number;
          value: number;
        }
      >
    ) {
      return {
        totalYieldInUsd: params.data?.yieldInUsd,
        totalAmountInUsd: params.data?.dollarValueOfAllocation,
        value: params.data?.impactedApy,
      };
    },
    filterValueGetter: function impactedApyFilterValueGetter(params: ValueGetterParams<RowType, number>) {
      return (params.data?.impactedApy ?? 0) * 100; // Scale from decimal to percentage
    },
    type: ['numericColumn', 'percentageColumn'],
  },
  {
    colId: 'yieldInCollateral',
    headerName: 'Yield in collateral',
    field: 'yieldInCollateral',
    aggFunc: 'sum',
    type: ['numericColumn', 'extendedNumericColumn'],
  },
  {
    colId: 'yieldInUsd',
    headerName: 'Yield in USD',
    field: 'yieldInUsd',
    aggFunc: 'sum',
    type: ['numericColumn', 'cashColumn'],
  },
  {
    colId: 'tvl',
    headerName: 'TVL USD',
    initialWidth: 150,
    field: 'pool.metrics.tvlUsd',
    type: ['numericColumn', 'cashColumn'],
  },
];

const YieldAllocationGrid = ({ data, useUnderlying }: { data: RowType[]; useUnderlying: boolean }) => {
  return (
    <GAgGrid<RowType>
      height={700}
      rowData={data}
      groupDefaultExpanded={useUnderlying ? 2 : 1}
      autoGroupColumnDef={{ pinned: 'left', lockPinned: true }}
      enableCharts
      defaultColDef={DEFAULT_COL_DEF}
      columnDefs={columns}
    />
  );
};

export default YieldAllocationGrid;

import type { TourGuideStep } from '@sjmc11/tourguidejs/src/types/TourGuideStep';
import type { TourGuideClient } from '@sjmc11/tourguidejs/src/Tour';
import TourActions from '../TourActions';
import ReactDOM from 'react-dom/client';
import { highlightCircle } from '../tour.utils';
import { removeHighlightCircle } from '../tour.utils';

let handleClick: ((e: Event) => void) | null = null;

const waitForElement = (selector: string, timeout = 5000): Promise<void> => {
  return new Promise((resolve, reject) => {
    const startTime = Date.now();

    const checkElement = () => {
      const element = document.querySelector(selector);
      if (element) {
        resolve();
      } else if (Date.now() - startTime > timeout) {
        reject(new Error(`Timeout waiting for element: ${selector}`));
      } else {
        setTimeout(checkElement, 300);
      }
    };

    checkElement();
  });
};

export const getCDTourSteps = (tour: TourGuideClient): TourGuideStep[] => [
  {
    target: 'body',
    title: '👋 Welcome to your custom dashboard',
    content: 'Here you can organize your dashboard with all the widgets you want.',
    order: 1,
    group: 'dashboard-intro',
    beforeEnter: () => {
      setTimeout(() => {
        if (document.getElementById('custom-tour-actions')) return;

        const footerSup = document.querySelector('.tg-dialog-footer-sup');
        if (footerSup) footerSup.remove();

        const container = document.querySelector('.tg-dialog-footer');
        if (!container) return;

        // Mount React component
        const mountPoint = document.createElement('div');
        mountPoint.id = 'custom-tour-actions';
        container.insertBefore(mountPoint, container.firstChild);

        const root = ReactDOM.createRoot(mountPoint);
        root.render(<TourActions featureId="cd-tour" />);

        // Wait for #tour-actions to be rendered, then move the button
        const moveNextBtn = () => {
          const nextBtn = document.getElementById('tg-dialog-next-btn');
          const tourActions = document.getElementById('tour-actions');

          if (nextBtn && tourActions && !tourActions.contains(nextBtn)) {
            tourActions.appendChild(nextBtn);
          } else {
            setTimeout(moveNextBtn, 50);
          }
        };

        moveNextBtn();
      }, 0);
    },
    beforeLeave: () => waitForElement('[data-tour="edit-widget-page-button"]'),
  },
  {
    target: '[data-tour="edit-widget-page-button"]',
    title: 'Edit Mode',
    content: 'Click here to customize your dashboard.',
    order: 2,
    group: 'dashboard-intro',
    beforeEnter: () => waitForElement('[data-tour="edit-widget-page-button"]'),
    beforeLeave: () => {
      const dropdownBtn = document.querySelector('[data-tour="widget-dropdown-button"]');
      if (!dropdownBtn) {
        const currentTarget = document.querySelector('[data-tour="edit-widget-page-button"]') as HTMLElement;
        currentTarget?.click();
      }
    },
  },
  {
    target: '[data-tour="widget-dropdown-button"]',
    title: 'Add a Widget',
    content: 'Click here to add a new widget.',
    order: 3,
    group: 'dashboard-intro',
    beforeEnter: () => waitForElement('[data-tour="widget-dropdown-button"]'),
    beforeLeave: () => {
      const button = document.querySelector('[data-tour="widget-dropdown-button"]') as HTMLElement;
      button?.click();
    },
  },
  {
    target: '[data-tour="widget-dropdown-menu"]',
    title: 'Select a Widget',
    content: 'Choose a widget to add it to your dashboard.',
    order: 4,
    group: 'disable-next-button',
    beforeEnter: () => {
      return new Promise((resolve) => {
        waitForElement('[data-tour="widget-dropdown-menu"]').then(() => {
          setTimeout(() => {
            const nextBtn = document.getElementById('tg-dialog-next-btn');
            const footer = document.querySelector('.tg-dialog-footer') as HTMLElement;

            if (nextBtn) nextBtn.style.display = 'none';

            if (footer && !document.getElementById('custom-next-button')) {
              const box = document.createElement('div');
              box.id = 'custom-next-button';
              box.innerHTML = '→';
              box.style.cssText = `
                padding: 0px 4px;
                color: var(--joy-palette-primary-plainColor);
                text-align: center;
                font-size: 30px;
              `;
              footer.appendChild(box);
            }

            handleClick = (e: Event) => {
              const target = e.target as HTMLElement;
              const item = target.closest('[data-tour="widget-list-item"]');
              if (item) {
                document.removeEventListener('click', handleClick!);
                handleClick = null;

                if (nextBtn) nextBtn.style.display = 'inline-block';
                tour.nextStep();
              }
            };

            document.addEventListener('click', handleClick);
            resolve(true);
          }, 0);
        });
      });
    },
    beforeLeave: () => {
      if (handleClick) {
        document.removeEventListener('click', handleClick);
        handleClick = null;
      }

      const customBtn = document.getElementById('custom-next-button');
      if (customBtn) {
        customBtn.remove();
      }

      return waitForElement('[data-tour="widget-card-item"] [data-tour-index="just-added"]');
    },
  },
  {
    target: '[data-tour="widget-card-item"] [data-tour-index="just-added"]',
    title: 'Widget Created ! ',
    content: 'Click here to access widget settings or remove it.',
    order: 5,
    group: 'dashboard-intro',
  },
  {
    target: '[data-tour="widget-card-item"] [data-tour-index="just-added"]',
    title: 'Move and resize widget',
    content: `From the bottom right corner, you can resize the widget.
    You can also move it by dragging the entire card.`,
    order: 6,
    group: 'dashboard-intro',
    beforeEnter: () => {
      setTimeout(() => {
        const widget = document.querySelector('[data-tour="widget-card-item"] [data-tour-index="just-added"]');
        if (!widget) return;
        const rect = widget.getBoundingClientRect();
        const offset = 5;
        // Position bottom-right corner of widget
        highlightCircle(rect.right - offset, rect.bottom - offset, { borderWidth: 8 });
      }, 0);
    },
    beforeLeave: () => {
      removeHighlightCircle();
    },
  },
  {
    target: '[data-tour="widget-card-item"] [data-tour="widget-card-close-div"]',
    title: 'Settings',
    content: 'Click here to access widget settings or remove it.',
    order: 7,
    group: 'dashboard-intro',
    beforeLeave: async () => {
      await waitForElement('[data-tour="widget-card-close-button"]');
      const settingsBtn = document.querySelector('[data-tour="widget-card-close-button"]') as HTMLElement;
      settingsBtn?.dispatchEvent(new MouseEvent('mousedown', { bubbles: true, cancelable: true }));
      await waitForElement('[data-tour="widget-card-settings-actions"]');
    },
  },
  {
    target: '[data-tour="widget-card-settings-actions"]',
    title: 'Settings Panel',
    content: 'From here you can edit the widget settings or delete it.',
    order: 8,
    group: 'dashboard-intro',
  },
  {
    target: '[data-tour="edit-widget-page-button"]',
    title: 'Save it',
    content: 'Click here to save the widget settings.',
    order: 9,
    group: 'dashboard-intro',
    beforeLeave: () => {
      const btn = document.querySelector('[data-tour="edit-widget-page-button"]') as HTMLElement;
      btn?.click();
    },
  },
  {
    target: '[data-tour="widget-dashboard"]',
    title: '🎉 Tour completed',
    content: 'You can restart the tour anytime!',
    order: 10,
    group: 'dashboard-intro',
    beforeEnter: () => {
      setTimeout(() => {
        const tourDialog = document.querySelector('.tg-dialog');

        if (!tourDialog || tourDialog.querySelector('#tg-dismiss-button')) return;

        const dismissButton = document.createElement('button');
        dismissButton.id = 'tg-dismiss-button';
        dismissButton.innerText = "Don't show again";
        dismissButton.className = 'tg-dismiss-btn';
        dismissButton.style.cssText = `
          padding: 0px 4px;
          border: none;
          cursor: pointer;
          font-size: 12px;
          background-color: transparent;
          text-align: center;
        `;
        dismissButton.onclick = () => {
          window.dispatchEvent(
            new CustomEvent('tour:dismiss', {
              detail: { featureId: 'cd-tour' },
            })
          );
        };

        const restartButton = document.createElement('button');
        restartButton.innerText = 'Restart Tour';
        restartButton.className = 'tg-restart-btn';
        restartButton.style.cssText = `
          padding: 0px 4px;
          border: none;
          cursor: pointer;
          font-size: 12px;
          background-color: transparent;
          text-align: center;
        `;
        restartButton.onclick = () => {
          window.dispatchEvent(
            new CustomEvent('tour:restart', {
              detail: { featureId: 'cd-tour' },
            })
          );
        };

        const footer = document.querySelector('.tg-dialog-footer-sup');
        if (footer) {
          footer.append(dismissButton);
          footer.append(restartButton);
        }
      }, 0);
    },
  },
];

import type { ObservableQuery } from '@apollo/client/core';
import { Divider, Stack } from '@mui/joy';
import { type FunctionComponent, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import Loader from 'components/technical/Loader/Loader';
import Message from 'components/technical/Message';
import { NewsTile } from './NewsTile';
import type { INewsBulletPointsQuery, INewsBulletPointsQueryVariables, IReactionType } from '../../generated/graphql';

const PAGING_LIMIT = 50;
export const NewsList: FunctionComponent<{
  data: INewsBulletPointsQuery['news']['bulletPoints']['bulletPoints'];
  height?: number;
  fetchMore: Pick<ObservableQuery<INewsBulletPointsQuery, INewsBulletPointsQueryVariables>, 'fetchMore'>['fetchMore'];
}> = ({ data, fetchMore, height = 640 }) => {
  const totalResults = data.pageInfo.totalResults;
  const offset = useRef(data.data.length);
  const [items, setItems] = useState(data.data);
  const hasMore = totalResults > items.length;

  const updateItem = (id: string, newReaction: IReactionType | null): void => {
    setItems((items) => items.map((item) => (item.id === id ? { ...item, reaction: newReaction } : item)));
  };

  const getMoreNews = async (): Promise<void> => {
    if (items.length >= totalResults) {
      return setItems(items);
    }

    const { data: additionalData, loading } = await fetchMore({
      variables: {
        paging: {
          offset: offset.current,
          limit: PAGING_LIMIT,
        },
      },
    });

    offset.current = offset.current + PAGING_LIMIT;

    if (!loading) {
      setItems(items.concat(additionalData!.news.bulletPoints.bulletPoints.data));
    }
  };

  if (items.length === 0) {
    return <Message>No news</Message>;
  }

  return (
    <InfiniteScroll
      dataLength={items.length}
      next={getMoreNews}
      hasMore={hasMore}
      height={height}
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '0.75rem',
      }}
      loader={
        <Stack direction="row" justifyContent="center">
          <Loader />
        </Stack>
      }
    >
      <Stack spacing={1.5} divider={<Divider color="neutral" />}>
        {items.length > 0 &&
          items.map((item) => {
            return <NewsTile key={item.id} item={item} updateItem={updateItem} />;
          })}
      </Stack>
    </InfiniteScroll>
  );
};

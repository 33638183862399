import { useFeedback } from '../../Feedback/UseFeedback.tsx';
import { useCreatePresignedUrlMutation } from '../../../../generated/graphql.tsx';
import GButton from '../GButton/GButton.tsx';
import { uploadFile } from '../../../file.utils.tsx';
import { type ChangeEvent, type ReactElement, type ReactNode, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

export const FileUploadButton = (props: {
  contentType: string;
  fileName: string;
  children: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  onChange: (value: {
    remoteFileId?: string;
    loading: boolean;
  }) => void;
}): ReactElement => {
  const [loading, setLoading] = useState(false);
  const abortController = useRef<AbortController | null>(null);
  const { showGraphqlError } = useFeedback();

  const [createPresignedUrl, _tmp] = useCreatePresignedUrlMutation();
  // file upload cannot be triggered via keyboard, so we disable
  // navigating to this element entirely

  return (
    <GButton
      component={'label'}
      startDecorator={<CloudUploadIcon />}
      tabIndex={-1}
      loading={loading || props.loading}
      disabled={loading || props.disabled}
    >
      <>
        {props.children}
        <input
          type={'file'}
          style={{ display: 'none' }}
          onChange={async (e: ChangeEvent<HTMLInputElement>) => {
            const files = e.target.files;
            if (!files || files.length === 0) {
              return;
            }

            const file = files[0];

            // clear file input, so that we can upload the same file again to retry
            e.target.value = '';

            props.onChange({
              loading: true,
            });

            try {
              setLoading(true);
              if (abortController.current) {
                abortController.current.abort();
              }

              const currentController = new AbortController();
              abortController.current = currentController;

              const presignedResponse = await createPresignedUrl({
                variables: {
                  contentType: props.contentType,
                  fileName: props.fileName,
                },
              });

              // aborted after clicking on generating presigned url
              if (currentController.signal.aborted) {
                return;
              }

              const makeContentTypeMatchPresignedUrl = file.slice(0, file.size, props.contentType);
              const presignedData = presignedResponse.data!.createPresignedUrl;
              await uploadFile({
                blob: makeContentTypeMatchPresignedUrl,
                uploadUrl: presignedData.uploadLink,
                signal: currentController.signal,
              });

              props.onChange({
                loading: false,
                remoteFileId: presignedData.remoteFile.id,
              });
            } catch (e) {
              console.error('Error uploading file', e);
              showGraphqlError(e);
              Sentry.captureException(e);
              props.onChange({
                loading: false,
              });
            } finally {
              abortController.current = null;
              setLoading(false);
            }
          }}
        />
      </>
    </GButton>
  );
};

import PieChartIcon from '@mui/icons-material/PieChart';
import type { CustomWidgetDescription } from 'components/management/customDashboard/CustomWidgetDescription.types.ts';
import { useCurrentExposureInputSuspenseQuery } from '../../../../generated/graphql.tsx';
import { createAggregations } from '../../../../UseUserAggregations.tsx';
import { calculateAllAggregations } from './PortfolioSnapshotSunburstContainer.tsx';
import { useSubAccountAssetFilters } from '../../../technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters.tsx';
import PortfolioSnapshotSunburst from './PortfolioSnapshotSunburst.tsx';
import { calculateBalance, calculateExposure } from '../../account/SubAccountPositionsService.ts';
import { CurrentExposureSettingsContainer, type CurrentExposureWidgetState } from './CurrentExposureSettings.tsx';
import { useWidgetState } from 'components/management/customDashboard/CustomWidgetContext.tsx';

export const currentExposureWidget: CustomWidgetDescription = {
  name: 'Current exposure',
  type: 'currentExposureSunburst',
  description: 'See your portfolio equity',
  icon: () => <PieChartIcon />,
  viewComponent: ({ id }) => {
    const { subAccountAssetFilters } = useSubAccountAssetFilters();
    const initialState = useWidgetState<CurrentExposureWidgetState>(id);
    const { data } = useCurrentExposureInputSuspenseQuery({
      variables: {
        subAccountAssetFilters: subAccountAssetFilters,
      },
    });

    const { aggregationsByCategory, activeAggregationsOrdered } = createAggregations({
      savedAggs: initialState.aggs,
      defaultAggs: [],
      allAvailableAggregations: calculateAllAggregations(data.assets.assetGroups),
    });

    return (
      <PortfolioSnapshotSunburst
        fullHeight
        loaded={true}
        Fallback={undefined}
        positions={data.portfolio.positions.positions}
        aggregationOrder={activeAggregationsOrdered}
        aggregationsByCategory={aggregationsByCategory}
        calculateBalance={(pos) => calculateBalance(pos)}
        calculateExposure={(pos) => calculateExposure(pos, false)}
      />
    );
  },
  settingsComponent: CurrentExposureSettingsContainer,
  initialState: { title: 'Current exposure', aggs: [] },
  initialH: 4,
  initialW: 3,
};

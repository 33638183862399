import { useRef, type ReactElement } from 'react';
import TitleValueTile, { TitleValueContent } from '../../../technical/Tile/TitleValueTile.tsx';
import TitleWithChip, { TileChangeChip } from '../../../technical/Tile/TitleWithChip.tsx';
import { formatPercentage } from '../../../formatter.utils.ts';
import { Typography } from '@mui/joy';
import { TileProgressFooter } from './TileProgressFooter.tsx';
import {
  type IMetricCalculatorInput,
  type IPortfolioVolatilityTileInputQuery,
  usePortfolioTilesMetricsQuery,
  usePortfolioVolatilityTileInputQuery,
} from '../../../../generated/graphql.tsx';
import { PORTFOLIO_VOLATILITY_METRIC } from '../../../metrics/PortfolioMetricsData.ts';
import { validateMetricParameters } from '../../../copilot/risk/assetRiskMetrics/assetRiskMetricsReport.utils.ts';
import { convertDateInUtcToUTCISODate } from '../../../date.utils.ts';
import dayjs from 'dayjs';
import { logErrorOnce } from '../../../log.utils.ts';
import { getTileValues } from './PortfolioTilesService.ts';
import { useSubAccountAssetFilters } from '../../../technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters.tsx';
import { useDefaultErrorHandling } from '../../../technical/UseDefaultErrorHandling.tsx';

const loopback = 30;
const VolatilityTileContent = ({
  metricParameters,
  hideTitle,
}: {
  metricParameters: IPortfolioVolatilityTileInputQuery['portfolio']['metricParameters'];
  hideTitle?: boolean;
}): ReactElement => {
  const { subAccountAssetFilters } = useSubAccountAssetFilters();
  const currentDate = useRef(dayjs.utc());

  const volatilityInput: IMetricCalculatorInput = {
    label: PORTFOLIO_VOLATILITY_METRIC,
    parameters: [
      {
        name: 'Window',
        intValues: [loopback],
      },
      {
        name: 'Window type',
        strValues: ['rolling'],
      },
      {
        name: 'Metric type',
        strValues: ['realized'],
      },
      {
        name: 'Annualized',
        strValues: ['not_annualized'],
      },
    ],
  };

  const volatilityParametersAreValid = validateMetricParameters(metricParameters, [volatilityInput]).isValid;

  const metricQuery = usePortfolioTilesMetricsQuery({
    variables: {
      metrics: volatilityInput,
      metricsSince: convertDateInUtcToUTCISODate(currentDate.current.subtract(loopback + 1, 'day')),
      metricsTo: convertDateInUtcToUTCISODate(dayjs.utc()),
      subAccountAssetFilters,
    },
    skip: !volatilityParametersAreValid,
  });

  if (metricQuery.error) {
    logErrorOnce('Failed to fetch portfolio metric tiles', metricQuery.error);
  }

  const volatilityRows = metricQuery.data?.portfolio.metrics[0]?.series[0]?.values.map((volatility) => ({
    value: volatility.value,
  }));
  const volatilityTile = getTileValues(volatilityRows);

  return (
    <TitleValueContent
      loading={metricQuery.loading}
      title={!hideTitle && <TitleWithChip title="Volatility (30D)" change={volatilityTile?.change} />}
      value={formatPercentage(volatilityTile?.latestValue)}
      caption={hideTitle && <TileChangeChip change={volatilityTile?.change} />}
      footer={
        volatilityParametersAreValid ? (
          <TileProgressFooter
            maxValue={formatPercentage(volatilityTile?.maxValue)}
            minValue={formatPercentage(volatilityTile?.minValue)}
            progressValue={volatilityTile?.progressValue}
          />
        ) : (
          <Typography level="body-xs2">Not enough data</Typography>
        )
      }
    />
  );
};

export const VolatilityTileContentContainer = ({ hideTitle }: { hideTitle?: boolean }): ReactElement => {
  const query = useDefaultErrorHandling(usePortfolioVolatilityTileInputQuery());
  if (!query.loaded) {
    return <query.Fallback />;
  }

  return <VolatilityTileContent hideTitle={hideTitle} metricParameters={query.data.portfolio.metricParameters} />;
};

const VolatilityTile = (): ReactElement => {
  return (
    <TitleValueTile>
      <VolatilityTileContentContainer />
    </TitleValueTile>
  );
};

export default VolatilityTile;

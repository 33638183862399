import type { FunctionComponent } from 'react';
import PositionsSummary from './PositionsSummary';
import { UserSettings } from 'components/management/UserSettings.types';
import { Box } from '@mui/joy';

const OpenPositionsDashboard: FunctionComponent = () => {
  return (
    <Box height="100%" width="100%">
      <PositionsSummary presetSettingsKey={UserSettings.PositionsSummaryPresets} />
    </Box>
  );
};

export default OpenPositionsDashboard;

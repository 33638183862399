import { type FC, useState } from 'react';
import { Box, Button, Stack, Tooltip, Typography, IconButton } from '@mui/joy';
import { Close } from '@mui/icons-material';
import { useAuth } from 'UseAuth';
import { Genie } from '../icons';
import { useTour } from './UseTour';

const TourLauncher: FC = () => {
  const { isAdmin } = useAuth();
  const { availableTours, startTourManually } = useTour();
  const [collapsed, setCollapsed] = useState(true);

  if (!isAdmin || availableTours.length === 0) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 20,
        right: 10,
      }}
    >
      {collapsed ? (
        <Tooltip title="Need a tour?" placement="left">
          <IconButton size="lg" variant="soft" color="neutral" onClick={() => setCollapsed(false)}>
            <Genie />
          </IconButton>
        </Tooltip>
      ) : (
        <Box
          sx={{
            p: 2,
            borderRadius: 'lg',
            boxShadow: 'md',
            backgroundColor: 'background.surface',
            border: '1px solid',
            borderColor: 'divider',
            minWidth: 260,
            maxWidth: 360,
          }}
        >
          <Stack spacing={1}>
            <Typography level="body-sm">👋 Choose a tour to get started:</Typography>
            <Stack spacing={1}>
              {availableTours.map((tour) => (
                <Box key={tour.featureId}>
                  <Typography level="title-sm" fontWeight="lg">
                    {tour.title}
                  </Typography>
                  {tour.description && (
                    <Typography level="body-xs" sx={{ mb: 1 }}>
                      {tour.description}
                    </Typography>
                  )}
                  <Button
                    size="sm"
                    variant="solid"
                    color="primary"
                    onClick={() => {
                      startTourManually(tour.featureId);
                      setCollapsed(true);
                    }}
                  >
                    Start
                  </Button>
                </Box>
              ))}
            </Stack>
            <IconButton
              size="sm"
              variant="plain"
              color="neutral"
              onClick={() => setCollapsed(true)}
              sx={{ alignSelf: 'flex-end' }}
            >
              <Close />
            </IconButton>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default TourLauncher;

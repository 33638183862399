import { getClusterOptions, priceActionClusterId } from '../../market/asset/groups/GroupService.ts';
import type { BigNumber } from 'mathjs';
import type { StaticAutocompleteOption } from '../../technical/inputs/Autocomplete/StaticSingleAutocomplete.props.ts';
import { getAssetGroupReportMapping } from '../../UseReportAssetGroups.tsx';
import { useMemo } from 'react';
import type { GroupWithAssetId } from '../../portfolio/dashboard/PositionAggregationsService.ts';
import isNil from 'lodash/fp/isNil';
import type { SingleSelectProps } from '../../technical/inputs/Select/Select.props.ts';

export interface CategoryId {
  id: string;
  name: string;
}

export interface OptionValue {
  clusterId: string;
  getCategory: (item: { id: string; symbol: string }, value: BigNumber) => CategoryId;
}

export const portfolioDefinitionExposure = {
  label: 'Portfolio',
  searchText: 'Portfolio',
  inputText: 'Portfolio',
  key: 'portfolio',
  value: {
    clusterId: 'portfolio',
    getCategory: (portfolio: { id: string; symbol: string }): CategoryId => ({
      id: portfolio.id,
      name: portfolio.symbol,
    }),
  },
} as const;

export const assetExposure: StaticAutocompleteOption<OptionValue> = {
  label: 'Asset',
  searchText: 'Asset',
  inputText: 'Asset',
  key: 'asset',
  value: {
    clusterId: 'asset',
    getCategory: (asset: { id: string; symbol: string }): CategoryId => ({
      id: asset.id,
      name: asset.symbol,
    }),
  },
};

export const isExposureOptionEqual = (a: OptionValue | null, b: OptionValue | null): boolean => {
  return a?.clusterId === b?.clusterId;
};

export const useAssetExposureOptions = ({
  groups,
  cashAssetIds,
}: {
  groups: GroupWithAssetId[];
  cashAssetIds?: Set<string>;
}): Pick<SingleSelectProps<OptionValue>, 'options' | 'isValueEqual'> => {
  return useMemo(() => {
    const { clusters, assetAndGroupClusterMapToGroup } = getAssetGroupReportMapping(groups);

    const finalClusters = clusters.filter((cluster) => cluster !== priceActionClusterId);
    const hasCashAssets = !isNil(cashAssetIds);
    return {
      isValueEqual: isExposureOptionEqual,
      options: [
        assetExposure,
        {
          // if cash assets are not provided, we default to long,short
          label: !hasCashAssets ? 'Long/Short' : 'Long/Short/Cash',
          searchText: !hasCashAssets ? 'long short' : 'long short cash',
          inputText: !hasCashAssets ? 'Long/Short' : 'Long/Short/Cash',
          key: !hasCashAssets ? 'long/short' : 'long/short/cash',
          value: {
            clusterId: 'long/short/cash',
            getCategory: (asset: { id: string }, value: BigNumber): CategoryId => {
              if (hasCashAssets && cashAssetIds.has(asset.id)) {
                return {
                  id: 'cash',
                  name: 'Cash',
                };
              }

              return value.isNegative()
                ? {
                    id: 'short',
                    name: 'Short',
                  }
                : {
                    id: 'long',
                    name: 'Long',
                  };
            },
          },
        },
        ...getClusterOptions(finalClusters).map((option) => ({
          ...option,
          key: `cluster-${option.key}`,
          value: {
            clusterId: `cluster-${option.key}`,
            getCategory: (asset: { id: string }): CategoryId => {
              const category = assetAndGroupClusterMapToGroup.get([asset.id, option.value]) ?? 'Other';
              return {
                id: category,
                name: category,
              };
            },
          },
        })),
      ],
    };
  }, [cashAssetIds, groups]);
};

import PoolUniverseStep from './PoolUniverseStep.tsx';
import type { StepConfig } from '../../../../../technical/wizard/StepConfig.ts';
import type { IYieldOptimizerWizardInputQuery } from '../../../../../../generated/graphql.tsx';
import type { YieldOptimizerInputFields } from '../YieldOptimizer.validation.ts';

export const config = (
  pools: IYieldOptimizerWizardInputQuery['yieldOptimization']['listPools'],
  goToNextStep: () => void
): StepConfig<YieldOptimizerInputFields> => ({
  label: 'Pool universe',
  element: <PoolUniverseStep pools={pools} goToNextStep={goToNextStep} />,
  fields: ['universe'],
});

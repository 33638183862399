import GDialogHeader from '../../../../technical/GDialog/GDialogHeader.tsx';
import { Modal, ModalDialog, Stack } from '@mui/joy';
import type { ReactElement, ReactNode } from 'react';
import gYupResolver from '../../../../technical/form/gYupResolver.ts';
import { useForm } from 'react-hook-form';
import type * as yup from 'yup';
import { useGraphQLApiError } from '../../../../technical/form/UseGraphQLApiError.tsx';
import { useFeedback } from '../../../../technical/Feedback/UseFeedback.tsx';
import FormInput from '../../../../technical/form/FormInput.tsx';
import GFormProvider from '../../../../technical/form/GFormProvider.tsx';
import { GraphQLApiFormErrorMessage } from '../../../../technical/form/GraphQLApiErrorMessage.tsx';
import SubmitButton from '../../../../technical/form/SubmitButton.tsx';
import { Add } from '@mui/icons-material';
import {
  type ICreatePortfolioDefInput,
  PortfolioDefListInputDocument,
  useCreatePortfolioDefinitionMutation,
} from '../../../../../generated/graphql.tsx';
import { defaultRowSpacing } from '../../../../StackSpacing.ts';
import { basePortfolioSchema, type FormInputFields, type FormOutputFields } from './CreatePortfolio.validation.ts';
import type { FormInputType } from '../../../../technical/form/Form.types.ts';

const CreatePortfolioDialog = <EXTRA_FIELDS,>(props: {
  onClose: () => void;
  onAdded: (defId: string) => void;
  children?: ReactNode;
  extraSchema?: yup.ObjectSchema<Record<string, unknown>>;
  defaultValues?: FormInputType<EXTRA_FIELDS>;
  createInput: (out: FormOutputFields & EXTRA_FIELDS) => ICreatePortfolioDefInput;
}): ReactElement => {
  const methods = useForm<FormInputFields>({
    resolver: gYupResolver(props.extraSchema ? basePortfolioSchema.concat(props.extraSchema) : basePortfolioSchema),
    mode: 'onChange',
    defaultValues: {
      name: 'Portfolio 1',
      description: '',
      ...props.defaultValues,
    },
  });

  const [createPortfolioDef] = useCreatePortfolioDefinitionMutation();
  const { onErrorAndThrow } = useGraphQLApiError(methods);
  const { showSuccessMessage } = useFeedback();

  const handleFormSubmit = async (input: FormInputFields): Promise<void> => {
    // biome-ignore lint/suspicious/noExplicitAny:
    const data = input as unknown as any;

    try {
      const { data: responseData } = await createPortfolioDef({
        variables: {
          input: props.createInput(data),
        },
        refetchQueries: [PortfolioDefListInputDocument],
      });

      showSuccessMessage('New portfolio definition successfully added');

      props.onAdded(responseData?.portfolio.createDefinition!);
    } catch (e) {
      onErrorAndThrow(e);
    }
  };

  const width = 'xl2';
  return (
    <Modal open={true} onClose={props.onClose}>
      <ModalDialog>
        <GDialogHeader>Add new portfolio definition</GDialogHeader>
        <GFormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
            <Stack spacing={defaultRowSpacing} alignItems={'center'}>
              <FormInput<FormInputFields>
                type="text"
                name="name"
                label="Name"
                width={width}
                autoComplete="off"
                showLabelAboveInput
              />
              <FormInput<FormInputFields>
                type="text"
                name="description"
                label="Description"
                width={width}
                autoComplete="off"
                showLabelAboveInput
              />
              {props.children}
              <Stack alignItems="center" spacing={defaultRowSpacing}>
                <GraphQLApiFormErrorMessage />
                <SubmitButton width="xl2" startDecorator={<Add />}>
                  Add portfolio
                </SubmitButton>
              </Stack>
            </Stack>
          </form>
        </GFormProvider>
      </ModalDialog>
    </Modal>
  );
};

export default CreatePortfolioDialog;

import { Grid, ListItemDecorator, Stack, Typography } from '@mui/joy';
import GridCard from 'components/technical/GCard/GridCard.tsx';
import WithDialog from 'components/technical/inputs/withDialogHoc/WithDialog';
import { MultifactorDocument, useDeleteMultifactorMutation, useMultifactorSuspenseQuery } from 'generated/graphql';
import type { ReactElement } from 'react';
import SeeMoreDropdown from 'components/technical/SeeMoreDropDown/SeeMoreDropdown.tsx';
import { Pencil } from 'components/technical/icons';
import { formatDate, DateTimeFormat } from 'components/formatter.utils';
import { useUserTimezone } from 'components/technical/UseUserTimezone';
import { DeleteMenuItem } from 'components/technical/inputs/GButton/DeleteDialogButton';
import UpdateMultifactorDialog from './UpdateMultifactorDialog';
import CreateMultifactorDialog from './CreateMultifactorDialog';
import { GCardKeyValueContent } from 'components/technical/GCard/GCardKeyValueContent';
import { GCardContentWithDivider } from '../../../technical/GCard/GCardContentWithDivider.tsx';
import { DialogMenuItem } from '../../../technical/DialogDropdown/DialogMenuItem.tsx';
import { ADD_NEW_CARD_STYLE } from 'components/technical/GCard/consts.ts';

const GCardWithDialog = WithDialog(GridCard);
const gridWidth = { sm: 12, md: 6, lg: 4, xl: 3 };
const cardHeight = '13rem';

function MultifactorsTab(): ReactElement {
  const [deleteMultifactorMutation] = useDeleteMultifactorMutation();
  const getAllMultifactorsForUserQuery = useMultifactorSuspenseQuery();
  const multifactorsInfo = getAllMultifactorsForUserQuery.data.multifactor.getAllMultifactorsForUser;

  const timezone = useUserTimezone();

  return (
    <Grid container>
      <GCardWithDialog
        cardSx={ADD_NEW_CARD_STYLE}
        renderDialog={({ onClose }): ReactElement => <CreateMultifactorDialog onClose={onClose} />}
        height={cardHeight}
        gridWidth={gridWidth}
        addNewText="Add new"
      />
      {multifactorsInfo.map(({ multifactor }) => {
        const updateSummary = [
          { key: 'Created At', value: formatDate(multifactor.createdAt, DateTimeFormat.DateTime, timezone) },
          { key: 'Updated At', value: formatDate(multifactor.updatedAt, DateTimeFormat.DateTime, timezone) },
        ];

        return (
          <GridCard key={multifactor.id} height={cardHeight} gridWidth={gridWidth}>
            <GCardContentWithDivider>
              <Stack justifyContent="space-between" direction="row">
                <Typography component="span" sx={{ fontSize: 'lg' }}>
                  {multifactor.name}
                </Typography>
                <SeeMoreDropdown>
                  <DialogMenuItem
                    renderDialog={({ onClose }): ReactElement => (
                      <UpdateMultifactorDialog onClose={onClose} multifactorId={multifactor.id} />
                    )}
                  >
                    <>
                      <ListItemDecorator>
                        <Pencil />
                      </ListItemDecorator>
                      Edit
                    </>
                  </DialogMenuItem>
                  <DeleteMenuItem
                    deletedMessage={'Multifactor successfully deleted'}
                    confirmationMessage={<>Are you sure you want to delete multifactor {multifactor.name}?</>}
                    deleteItem={(): Promise<unknown> => {
                      return deleteMultifactorMutation({
                        variables: {
                          multifactorId: multifactor.id,
                        },
                        refetchQueries: [MultifactorDocument],
                      });
                    }}
                  />
                </SeeMoreDropdown>
              </Stack>
              <GCardKeyValueContent
                data={[{ key: 'Description', value: multifactor.description }]}
                updateSummary={updateSummary}
              />
            </GCardContentWithDivider>
          </GridCard>
        );
      })}
    </Grid>
  );
}

export default MultifactorsTab;

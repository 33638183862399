import type { VenueEntry } from 'components/venue/VenueData';
import { pickBy, unset } from 'lodash/fp';
import isEmpty from 'lodash/fp/isEmpty';
import trim from 'lodash/fp/trim';
import * as yup from 'yup';
export const getInitialValues = (fields: { name: string }[]): Record<string, string> => {
  return Object.fromEntries(fields.map((field) => [field.name, '']));
};

export const removeEmptySecrets = (secrets: Record<string, string>): Record<string, string> => {
  return pickBy((value): value is string => !isEmpty(trim(value ?? '')), secrets);
};

export const API_SECRET_FIELD_NAME = 'secrets';

export const createSecretFields = (venue: VenueEntry): { label: string; name: string; type?: string | undefined }[] => {
  const venueBaseFields = venue.baseFields ?? [];
  return venueBaseFields.map((field) => ({
    ...field,
    name: `${API_SECRET_FIELD_NAME}.${field.name}`,
  }));
};

export const createSecretFieldsSchema = (venue: VenueEntry): yup.ObjectSchema<object> => {
  return yup.object({
    [API_SECRET_FIELD_NAME]: venue.getFieldsSchema(),
  });
};

export const prepareSecretInput = (venue: VenueEntry, secrets: Record<string, string>): Record<string, string> => {
  let sanitizedSecrets = removeEmptySecrets(secrets);
  for (const field of venue.uiOnlyFields) {
    sanitizedSecrets = unset(field, sanitizedSecrets);
  }
  return sanitizedSecrets;
};

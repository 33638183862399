import { GridView, StackedLineChart } from '@mui/icons-material';
import type { CustomWidgetDescription } from 'components/management/customDashboard/CustomWidgetDescription.types.ts';
import { SubFundGridWidgetSettings } from './grid/SubFundGridWidgetSettings.tsx';
import { SubFundPnlGridWidget } from './grid/SubFundPnlGridWidget.tsx';
import {
  SubFundChartWidgetSettingsContainer,
  type SubFundChartWidgetState,
} from './chart/SubFundChartWidgetSettings.tsx';
import { SubFundPnlChartWidget } from './chart/SubFundPnlChartWidget.tsx';

export const subFundWidgets: CustomWidgetDescription[] = [
  {
    name: 'Sub-funds performance stats',
    type: 'subFundPerformanceStats',
    description: '',
    icon: () => <GridView />,
    viewComponent: SubFundPnlGridWidget,
    settingsComponent: SubFundGridWidgetSettings,
    initialH: 4,
    initialW: 3,
  },
  {
    name: 'Sub-funds performance',
    type: 'subFundPerformanceChart',
    description: '',
    icon: () => <StackedLineChart />,
    viewComponent: SubFundPnlChartWidget,
    settingsComponent: SubFundChartWidgetSettingsContainer,
    initialState: {
      title: 'Sub-funds performance',
      twrField: 'weight',
      chartDataRange: 'latest',
    } satisfies SubFundChartWidgetState,
    initialH: 4,
    initialW: 3,
  },
];

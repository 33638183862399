import dropRight from 'lodash/fp/dropRight';
import { type FunctionComponent, type ReactElement, useState } from 'react';
import { UserSettings } from 'components/management/UserSettings.types.ts';
import GAgGridPresets from 'components/technical/grids/GAgGridPresets.tsx';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';
import type { TupleKeyMap } from 'components/TupleKeyMap.ts';
import { useReportAssetGroup } from 'components/UseReportAssetGroups.tsx';

import { getDefaultPresets } from './defaultPresets.ts';
import type { RowData } from './Report.types';
import { mapSubAccountAndDimensionToSubFund } from './Report.utils.tsx';
import { type IAssetType, type IPortfolioReportQuery, usePortfolioReportQuery } from '../../../generated/graphql';
import { dateReadableValueGetter } from 'components/technical/grids/agGrid.utils.tsx';
import type { ValueGetterParams, GridApi } from 'ag-grid-community';
import {
  accountColumn,
  amountColumn,
  assetTypeColumn,
  assetWithSymbolColumn,
  balanceColumn,
  clusterColumn,
  exposureColumn,
  sideWithBadgeColumn,
  slotColumn,
  subAccountColumn,
  subFundGroupColumn,
  underlyingAssetColumn,
  venueColumn,
} from 'components/technical/grids/SharedReportColumns';
import type { Dayjs } from 'dayjs';
import { getDefaultRange } from '../../predefinedDateRanges.ts';
import PredefinedDateRangeButtonsWithCalendar from '../../technical/inputs/date/PredefinedDateRangeButtonsWithCalendar.tsx';
import { useSubAccountAssetFilters } from '../../technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters.tsx';
import { convertDateRangeToSinceToDate } from '../../technical/inputs/date/dateRange.utils.ts';
import { timeAvgOfSumsFactory } from './agg.utils.ts';
import { Box, Stack } from '@mui/joy';
import { useMemo, useRef } from 'react';
import type { Asset } from 'components/market/asset/Asset.types.ts';

type ReportProps = {
  data: IPortfolioReportQuery;
  idToSubAccount: Record<
    string,
    {
      id: string;
      name: string;
      account: {
        id: string;
        name: string;
        venue: {
          label: string;
        };
      };
    }
  >;
  assetClusters: string[];
  assetAndGroupClusterMapToGroup: TupleKeyMap<[string, string], string>;
};

const GROUP_COLUMN_MIN_WIDTH = 220;
const dateColId = 'date';
const customAgg = {
  timeAvgOfSums: timeAvgOfSumsFactory(dateColId),
};

const Report: FunctionComponent<ReportProps> = ({
  data,
  assetClusters,
  assetAndGroupClusterMapToGroup,
  idToSubAccount,
}) => {
  const gridApi = useRef<GridApi | null>(null);

  const subFunds = data.portfolio.subFunds.list;
  const { subAccountAndDimensionToSubFund, subFundDimensions } = mapSubAccountAndDimensionToSubFund(subFunds);

  // remove the latest snapshot to use only daily snapshots
  const rows: RowData[] = dropRight(1, data.portfolio.snapshot).flatMap((snapshot) =>
    snapshot.positions.map((pos) => {
      return {
        ...pos,
        asset: pos.asset as Asset,
        date: snapshot.date,
        subAccount: idToSubAccount[pos.subAccount.id],
      };
    })
  );

  const columnDefs = useMemo(
    () => [
      {
        colId: dateColId,
        headerName: 'Date',
        type: 'dateColumn',
        valueGetter: (params: ValueGetterParams<RowData>): string | undefined =>
          params.data ? dateReadableValueGetter(params.data.date) : undefined,
      },
      assetWithSymbolColumn<RowData>({ initialHide: false }),
      underlyingAssetColumn<RowData>({ initialHide: true }),
      ...assetClusters.map((cluster) => clusterColumn<RowData>(cluster, assetAndGroupClusterMapToGroup)),
      sideWithBadgeColumn<RowData>(),
      assetTypeColumn<RowData & { asset: { type: IAssetType } }>(),
      slotColumn<RowData>(),
      {
        headerName: 'Account Details',
        colId: 'account-details',
        marryChildren: true,
        children: [accountColumn<RowData>(), subAccountColumn<RowData>(), venueColumn<RowData>()],
      },
      {
        headerName: 'Sub-funds',
        colId: 'sub-funds',
        marryChildren: true,
        children: subFundDimensions.map((subFundDimension) =>
          subFundGroupColumn<RowData>(subFundDimension, subAccountAndDimensionToSubFund)
        ),
      },
      {
        headerName: 'Positions',
        colId: 'Positions',
        marryChildren: true,
        children: [
          exposureColumn<RowData>({ sideAware: true, initialHide: true }),
          exposureColumn<RowData>({ sideAware: false, initialHide: true }),
          balanceColumn<RowData>(),
          amountColumn<RowData>({ sideAware: true, initialHide: true }),
          amountColumn<RowData>({ sideAware: false, initialHide: true }),
        ],
      },
    ],
    [assetClusters, assetAndGroupClusterMapToGroup, subAccountAndDimensionToSubFund, subFundDimensions]
  );

  return (
    <GAgGridPresets<RowData>
      aggFuncs={customAgg}
      presetSettingsKey={UserSettings.PortfolioReportPresets}
      defaultPresets={getDefaultPresets()}
      enableCharts
      cellSelection
      rowData={rows}
      autoGroupColumnDef={{
        minWidth: GROUP_COLUMN_MIN_WIDTH,
      }}
      onGridReady={(params: { api: GridApi }) => {
        gridApi.current = params.api;
      }}
      onGridPreDestroyed={() => {
        gridApi.current = null;
      }}
      defaultColDef={{
        resizable: true,
        sortable: true,
        filter: true,
      }}
      sideBar={{
        toolPanels: ['columns', 'filters'],
        defaultToolPanel: 'columns',
      }}
      columnDefs={columnDefs}
    />
  );
};

const ReportWithDateRange = (): ReactElement => {
  const defaultDateRange = getDefaultRange();
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs] | null>(defaultDateRange.value);

  return (
    <Stack spacing={1.5} height="100%">
      <Box display="flex" justifyContent="space-between" alignSelf="flex-end">
        <PredefinedDateRangeButtonsWithCalendar
          alignRight={false}
          defaultValue={defaultDateRange}
          onChange={setDateRange}
        />
      </Box>
      <ReportContainer dateRange={dateRange} />
    </Stack>
  );
};

type ReportContainerProps = {
  dateRange: [Dayjs, Dayjs] | null;
};

const ReportContainer: FunctionComponent<ReportContainerProps> = ({ dateRange }): ReactElement => {
  const { subAccountAssetFilters } = useSubAccountAssetFilters();
  const portfolioReportQueryResults = useDefaultErrorHandling(
    usePortfolioReportQuery({
      variables: {
        ...convertDateRangeToSinceToDate(dateRange),
        subAccountAssetFilters,
      },
    })
  );

  const reportAssetGroup = useReportAssetGroup();

  if (!reportAssetGroup.loaded) {
    return <reportAssetGroup.Fallback />;
  }

  if (!portfolioReportQueryResults.loaded) {
    return <portfolioReportQueryResults.Fallback />;
  }

  const idToSubAccount = Object.fromEntries(
    portfolioReportQueryResults.data.portfolio.accounts.flatMap((acc) =>
      acc.subAccounts.map((sub) => [
        sub.id,
        {
          ...sub,
          account: acc,
        },
      ])
    )
  );

  return (
    <Report
      data={portfolioReportQueryResults.data}
      idToSubAccount={idToSubAccount}
      assetClusters={reportAssetGroup.clusters}
      assetAndGroupClusterMapToGroup={reportAssetGroup.assetAndGroupClusterMapToGroup}
    />
  );
};

export default ReportWithDateRange;

import { Stack } from '@mui/joy';
import type { FunctionComponent } from 'react';
import ActionsContextProvider from 'components/technical/actions/ActionsContextProvider.tsx';
import LineChartSkeleton from 'components/technical/charts/LineChart/LineChartSkeleton';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';

import { CHART_TITLE as EXPOSURE_CHART_TITLE } from './exposure/ExposureChartWrapper.tsx';
import ExposureChartWrapper from './exposure/ExposureChartWrapper.tsx';
import { ATTRIBUTION_CHART_TITLE } from './attributionOverview/PerformanceAttributionChartWrapper.tsx';
import ProfitLossChartWrapper, { CHART_TITLE as PROFIT_LOSS_CHART_TITLE } from './overview/ProfitLossChartWrapper.tsx';
import { useProfitLossPerformanceInputQuery } from '../../../generated/graphql';
import { ChartSkeleton } from '../../ChartSkeleton.tsx';
import PerformanceAttributionChartWrapper from './attributionOverview/PerformanceAttributionChartWrapper.tsx';

const ProfitAndLossPerformance: FunctionComponent = () => {
  const { loaded, Fallback, data, refetch } = useDefaultErrorHandling(useProfitLossPerformanceInputQuery());

  if (!loaded) {
    return <Fallback />;
  }

  const subAccounts = data.portfolio.accounts.flatMap((acc) => acc.subAccounts);

  return subAccounts.length === 0 ? (
    <>
      <ChartSkeleton title={PROFIT_LOSS_CHART_TITLE} refetch={refetch} skeletonType={<LineChartSkeleton />} />
      <ChartSkeleton title={EXPOSURE_CHART_TITLE} refetch={refetch} skeletonType={<LineChartSkeleton />} />
      <ChartSkeleton title={ATTRIBUTION_CHART_TITLE} refetch={refetch} skeletonType={<LineChartSkeleton />} />
    </>
  ) : (
    <Stack spacing={1.5}>
      <ActionsContextProvider>
        <ProfitLossChartWrapper />
      </ActionsContextProvider>
      <ActionsContextProvider>
        <ExposureChartWrapper />
      </ActionsContextProvider>
      <ActionsContextProvider>
        <PerformanceAttributionChartWrapper
          supportedClusters={data.assets.clusterByUser}
          supportedTimeWindow={data.factorRegression.supportedTimeWindow.supportedTimeWindow}
        />
      </ActionsContextProvider>
    </Stack>
  );
};

export default ProfitAndLossPerformance;

import type { ReactNode } from 'react';
import { createContext } from 'react';

interface ActionContext {
  setActions(nodes: ReactNode): void;
  actions: ReactNode | undefined;
}

export const Context = createContext<ActionContext>({
  setActions(): void {
    console.info('Not registering actions');
  },
  actions: undefined,
});

import type { IWeightedValueType, ITimeWeightedReturnType } from 'scalars.ts';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar.This scalar is serialized to a string in ISO 8601 format and parsed from a string in ISO 8601 format. */
  ArrowType: { input: string; output: string };
  /** Represents binary data as Base64-encoded strings, using the standard alphabet. */
  Base64: { input: string; output: string };
  /** Date (isoformat) */
  Date: { input: UtcDate; output: UtcDate };
  /** Decimal (fixed-point) */
  Decimal: { input: Decimal; output: Decimal };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](https://ecma-international.org/wp-content/uploads/ECMA-404_2nd_edition_december_2017.pdf). */
  JSON: { input: any; output: any };
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any };
  LabelType: { input: Label; output: Label };
  LabelValueMappingType: { input: Record<string, string>; output: Record<string, string> };
  TimeFrameType: { input: any; output: any };
  TimeSeriesListValuesType: { input: any; output: any };
  TimeWeightedReturns: { input: ITimeWeightedReturnType[]; output: ITimeWeightedReturnType[] };
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  UUID: { input: string; output: string };
  /** Represents NULL values */
  Void: { input: any; output: any };
};

export type IAccount = {
  __typename?: 'Account';
  createdAt: Scalars['ArrowType']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  snapshotSyncStatus: IAccountSnapshotSyncStatus;
  subAccounts: Array<ISubAccount>;
  venue: IDataSource;
};

export type IAccountSubAccountsArgs = {
  filters?: InputMaybe<ISubAccountFilters>;
};

export type IAccountSnapshotSyncResult = {
  __typename?: 'AccountSnapshotSyncResult';
  resultCode: ISyncRequestResultCode;
  time: Scalars['ArrowType']['output'];
};

export type IAccountSnapshotSyncStatus = {
  __typename?: 'AccountSnapshotSyncStatus';
  latestCompleted?: Maybe<IAccountSnapshotSyncResult>;
  latestSuccessfulTime?: Maybe<Scalars['ArrowType']['output']>;
};

export enum IAction {
  Delete = 'DELETE',
  Update = 'UPDATE',
}

export type IAddAssetFromCoingeckoIdRequestInputType = {
  coingeckoId: Scalars['String']['input'];
};

export type IAgent = {
  __typename?: 'Agent';
  description: Scalars['String']['output'];
  examples: Array<Scalars['String']['output']>;
  icon: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['ArrowType']['output'];
};

export type IAgentResult = {
  __typename?: 'AgentResult';
  code?: Maybe<Scalars['String']['output']>;
  status: IAgentResultStatusType;
  type?: Maybe<IAgentResultType>;
  value?: Maybe<Scalars['JSON']['output']>;
};

export enum IAgentResultStatusType {
  Error = 'ERROR',
  FetchData = 'FETCH_DATA',
  GenerateCode = 'GENERATE_CODE',
  Pending = 'PENDING',
  RephraseQuery = 'REPHRASE_QUERY',
  ResolveIds = 'RESOLVE_IDS',
  Success = 'SUCCESS',
}

export enum IAgentResultType {
  Dataframe = 'DATAFRAME',
  Number = 'NUMBER',
  Plot = 'PLOT',
  String = 'STRING',
}

export type IAgents = {
  __typename?: 'Agents';
  /** Retrieve all agents with examples. */
  agents: Array<IAgent>;
  /** Retrieve all conversations. */
  conversations: Array<IConversation>;
  /** Retrieve message by id. */
  message: IMessage;
  /** Retrieve all messages for the given conversation. */
  messages: Array<IMessage>;
};

export type IAgentsConversationsArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type IAgentsMessageArgs = {
  messageId: Scalars['UUID']['input'];
};

export type IAgentsMessagesArgs = {
  conversationId: Scalars['UUID']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type IAllocation = {
  __typename?: 'Allocation';
  cash: Scalars['Decimal']['output'];
  gross: Scalars['Decimal']['output'];
  long: Scalars['Decimal']['output'];
  net: Scalars['Decimal']['output'];
  short: Scalars['Decimal']['output'];
  total: Scalars['Decimal']['output'];
};

export type IAllocationConstraintInput = {
  constrainedQuantity: IGroupConstrainedQuantity;
  constraint: IConstraintInput;
};

export type IAllocationConstraintType = {
  __typename?: 'AllocationConstraintType';
  constrainedQuantity: IGroupConstrainedQuantity;
  constraint: IConstraint;
};

export type IArticle = {
  __typename?: 'Article';
  assets: Array<IAsset>;
  bulletPoint?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  isBookmarked: Scalars['Boolean']['output'];
  lastSeenAt?: Maybe<Scalars['ArrowType']['output']>;
  newsFeed?: Maybe<INewsFeed>;
  publishedAt: Scalars['ArrowType']['output'];
  sentiment?: Maybe<ISentiment>;
  summary: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type IArticleBookmarkInput = {
  articleId: Scalars['UUID']['input'];
  bookmark: Scalars['Boolean']['input'];
};

export type IArticleGraph = {
  __typename?: 'ArticleGraph';
  edges: Array<IArticleToArticleLink>;
  nodes: Array<IArticle>;
};

export type IArticleInput = {
  articleId: Scalars['UUID']['input'];
};

export type IArticlePublishedAtTimeRange = {
  __typename?: 'ArticlePublishedAtTimeRange';
  since: Scalars['ArrowType']['output'];
  to: Scalars['ArrowType']['output'];
};

export type IArticleToArticleLink = {
  __typename?: 'ArticleToArticleLink';
  firstArticleId: Scalars['UUID']['output'];
  linkageScore: Scalars['Float']['output'];
  secondArticleId: Scalars['UUID']['output'];
};

export type IArticleView = {
  __typename?: 'ArticleView';
  article: IArticle;
  viewedAt: Scalars['ArrowType']['output'];
};

export type IArticles = {
  __typename?: 'Articles';
  /** Get graph of articles for a selected node of graph navigator. */
  articleGraph: IArticleGraph;
  /** Get all article views for a selected date. */
  viewedArticles: Array<IArticleView>;
};

export type IArticlesArticleGraphArgs = {
  filters?: InputMaybe<IGraphGlobalFilters>;
  limit?: Scalars['Int']['input'];
  nodeId?: InputMaybe<IGraphNodeIdInput>;
};

export type IArticlesViewedArticlesArgs = {
  date: Scalars['Date']['input'];
  filters?: InputMaybe<IGraphGlobalFilters>;
};

export type IAsset = {
  __typename?: 'Asset';
  derivativeDetails?: Maybe<IDerivativeDetails>;
  exchangeDetails?: Maybe<Scalars['LabelType']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['LabelType']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priceAsset: IAsset;
  symbol: Scalars['String']['output'];
  type: IAssetType;
};

export type IAssetAllocation = {
  __typename?: 'AssetAllocation';
  asset: IAsset;
  value: Scalars['Float']['output'];
};

export type IAssetAllocationSnapshot = {
  __typename?: 'AssetAllocationSnapshot';
  assetAllocations: Array<IAssetAllocation>;
  time: Scalars['ArrowType']['output'];
};

export type IAssetAmountInput = {
  amount: Scalars['Float']['input'];
  assetId: Scalars['UUID']['input'];
};

export type IAssetAmountInputType = {
  __typename?: 'AssetAmountInputType';
  amount: Scalars['Float']['output'];
  asset: IAsset;
};

export type IAssetBulletPoints = {
  __typename?: 'AssetBulletPoints';
  /** Get bullet points. */
  bulletPoints: IBulletPointOffsetLimitPage;
};

export type IAssetBulletPointsBulletPointsArgs = {
  filters?: InputMaybe<IBulletPointFilters>;
  paging: IPageLimitOffsetInput;
};

export type IAssetByNameFilters = {
  assetIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  assetTypes?: InputMaybe<Array<IAssetType>>;
  baseAssetIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  derivativeTypes?: InputMaybe<Array<IDerivativeType>>;
  exchanges?: InputMaybe<Array<Scalars['LabelType']['input']>>;
  expirationSince?: InputMaybe<Scalars['ArrowType']['input']>;
  fitnessFunction?: IFitnessFunctions;
  limit?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type IAssetChange = {
  __typename?: 'AssetChange';
  asset: Scalars['UUID']['output'];
  metrics: Scalars['LabelValueMappingType']['output'];
  price?: Maybe<Scalars['Decimal']['output']>;
};

export type IAssetCompositionWithValues = {
  __typename?: 'AssetCompositionWithValues';
  amount?: Maybe<Scalars['Float']['output']>;
  asset: IAsset;
  entryPrice?: Maybe<Scalars['Float']['output']>;
  weight: Scalars['Float']['output'];
};

export type IAssetContribution = {
  __typename?: 'AssetContribution';
  asset: IAsset;
  dailySnapshotValues: Array<IDailySnapshotValues>;
};

export type IAssetContributionResult = {
  __typename?: 'AssetContributionResult';
  assetContributions: Array<IAssetContribution>;
  portfolioPerformance: Array<IDailyPortfolioPerformance>;
};

export type IAssetContributionValue = {
  __typename?: 'AssetContributionValue';
  asset: IAsset;
  value: Scalars['Decimal']['output'];
};

export type IAssetCostBasis = {
  __typename?: 'AssetCostBasis';
  asset: IAsset;
  costBasis: Scalars['Decimal']['output'];
  subFund?: Maybe<ISubFund>;
};

export type IAssetDetails = {
  __typename?: 'AssetDetails';
  asset: Scalars['UUID']['output'];
  maxPrice?: Maybe<Scalars['Decimal']['output']>;
  metrics: Scalars['LabelValueMappingType']['output'];
  minPrice?: Maybe<Scalars['Decimal']['output']>;
  price?: Maybe<Scalars['Decimal']['output']>;
  price24hAgo?: Maybe<Scalars['Decimal']['output']>;
};

export type IAssetEvent = {
  __typename?: 'AssetEvent';
  asset?: Maybe<IAsset>;
  netReturn: Scalars['Float']['output'];
};

export type IAssetFilters = {
  assets?: InputMaybe<Array<Scalars['UUID']['input']>>;
  liquidity?: InputMaybe<IMinMaxFilter>;
  marketCap?: InputMaybe<IMinMaxFilter>;
  totalValueLocked?: InputMaybe<IMinMaxFilter>;
};

export type IAssetGroup = {
  __typename?: 'AssetGroup';
  assets: Array<IAsset>;
  clusterName: Scalars['String']['output'];
  createdAt: Scalars['ArrowType']['output'];
  groupName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['ArrowType']['output']>;
};

export type IAssetGroups = {
  __typename?: 'AssetGroups';
  genieGroups: Array<IAssetGroup>;
  groupsById: Array<IAssetGroup>;
  userGroups: Array<IAssetGroup>;
};

export type IAssetGroupsGroupsByIdArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type IAssetInput = {
  id: Scalars['UUID']['input'];
};

export type IAssetInvestmentBalance = {
  __typename?: 'AssetInvestmentBalance';
  asset: IAsset;
  investments: Array<IInvestmentBalance>;
};

export type IAssetInvestmentDetails = {
  __typename?: 'AssetInvestmentDetails';
  asset: IAsset;
  unvested: Scalars['Decimal']['output'];
  vested: Scalars['Decimal']['output'];
};

export enum IAssetManagerStatusType {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Running = 'RUNNING',
}

export type IAssetMeasure = {
  __typename?: 'AssetMeasure';
  amount: Scalars['Float']['output'];
  asset: IAsset;
  cashWeight: Scalars['Float']['output'];
  leverage: Scalars['Float']['output'];
  weights: Array<IAssetRiskMeasureType>;
};

export type IAssetMetricHistory = {
  __typename?: 'AssetMetricHistory';
  asset: IAsset;
  rows: Array<IAssetMetricHistoryRow>;
};

export type IAssetMetricHistoryFilters = {
  date?: InputMaybe<IDateRangeFilter>;
  metricIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  metricLabels?: InputMaybe<Array<Scalars['LabelType']['input']>>;
  resolutionDays?: InputMaybe<Scalars['Int']['input']>;
};

export type IAssetMetricHistoryRow = {
  __typename?: 'AssetMetricHistoryRow';
  date: Scalars['Date']['output'];
  metrics: Scalars['LabelValueMappingType']['output'];
};

export type IAssetPage = {
  __typename?: 'AssetPage';
  data: Array<IAsset>;
  pageInfo: IPageInfoV1;
};

export type IAssetPositionDelta = {
  __typename?: 'AssetPositionDelta';
  amount: Scalars['Float']['output'];
  asset: IAsset;
};

export type IAssetPrice = {
  __typename?: 'AssetPrice';
  asset: IAsset;
  rows: Array<IAssetPriceRow>;
};

export type IAssetPriceBulkInput = {
  prices: Array<IAssetPriceInput>;
};

export type IAssetPriceForecastRow = {
  __typename?: 'AssetPriceForecastRow';
  date: Scalars['Date']['output'];
  highConfidence: Scalars['Decimal']['output'];
  lowConfidence: Scalars['Decimal']['output'];
  value: Scalars['Decimal']['output'];
};

export type IAssetPriceIdInput = {
  baseAssetId: Scalars['UUID']['input'];
  date: Scalars['Date']['input'];
  quoteAssetId: Scalars['UUID']['input'];
};

export type IAssetPriceInput = {
  id: IAssetPriceIdInput;
  unitValue: Scalars['Decimal']['input'];
};

export type IAssetPriceRow = {
  __typename?: 'AssetPriceRow';
  date: Scalars['Date']['output'];
  price: Scalars['Decimal']['output'];
};

export type IAssetPriceSummary = {
  __typename?: 'AssetPriceSummary';
  asset: IAsset;
  firstDayOfMonth?: Maybe<Scalars['Decimal']['output']>;
  firstDayOfQuarter?: Maybe<Scalars['Decimal']['output']>;
  firstDayOfYear?: Maybe<Scalars['Decimal']['output']>;
  today?: Maybe<Scalars['Decimal']['output']>;
};

export type IAssetRiskMeasureType = {
  __typename?: 'AssetRiskMeasureType';
  benchmark?: Maybe<IAsset>;
  measure: Scalars['LabelType']['output'];
  value: Scalars['Float']['output'];
};

export type IAssetScore = {
  __typename?: 'AssetScore';
  asset: IAsset;
  score: Scalars['Decimal']['output'];
};

export type IAssetScreenerInput = {
  date: Scalars['Date']['input'];
  screeners: Array<IScreenerFactorInput>;
};

export type IAssetScreenerOutput = {
  __typename?: 'AssetScreenerOutput';
  scores: Array<IMultifactorScores>;
};

export type IAssetShock = {
  __typename?: 'AssetShock';
  assetId: Scalars['UUID']['output'];
  price?: Maybe<IShock>;
  volatility?: Maybe<IShock>;
};

export type IAssetShockList = {
  assetId: Scalars['UUID']['input'];
  price?: InputMaybe<IShockList>;
  volatility?: InputMaybe<IShockList>;
};

export enum IAssetType {
  Derivative = 'DERIVATIVE',
  Exchange = 'EXCHANGE',
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type IAssetValue = {
  __typename?: 'AssetValue';
  amount: Scalars['Decimal']['output'];
  asset: IAsset;
  unitValue?: Maybe<Scalars['Decimal']['output']>;
};

export type IAssetValueInput = {
  amount: Scalars['Decimal']['input'];
  assetId: Scalars['UUID']['input'];
  unitValue?: InputMaybe<Scalars['Decimal']['input']>;
};

export type IAssetWeight = {
  asset: IAssetInput;
  weight: Scalars['Float']['input'];
};

export type IAssets = {
  __typename?: 'Assets';
  /** Fetch taxonomy definitions */
  assetGroups: IAssetGroups;
  availableMetrics: Array<IMetric>;
  baseAssetForDerivatives: Array<IAsset>;
  /** List all blacklisted assets */
  blacklistedAssets: Array<IAsset>;
  changes: Array<IAssetChange>;
  clusterByUser: Array<Scalars['String']['output']>;
  /** Fetch prices and metrics for all assets */
  details: Array<IAssetDetails>;
  feature: Array<IAsset>;
  forecast: Array<IAssetPriceForecastRow>;
  /** Fetch all assets */
  list: Array<IAsset>;
  metricHistory: Array<IAssetMetricHistory>;
  metricStats: IMinMaxMetrics;
  options: IOptions;
  /** Fetch all assets with pagination */
  paginated: IAssetPage;
  precisePrice?: Maybe<IPreciseAssetPrice>;
  /** Fetch latest prices for all assets */
  price: Array<IAssetPrice>;
  /** Fetches summary of prices for asssets */
  priceSummary: Array<IAssetPriceSummary>;
  /** Fetch all public asset requested by the user */
  publicAssetRequests: Array<IPublicAssetRequestType>;
  twoAssetLatestMetrics: Array<ITwoAssetMetric>;
  twoAssetMetrics: Array<ITwoAssetMetric>;
};

export type IAssetsAvailableMetricsArgs = {
  assetId: Scalars['UUID']['input'];
};

export type IAssetsBaseAssetForDerivativesArgs = {
  filters?: InputMaybe<IListDerivativeAssetFilters>;
};

export type IAssetsChangesArgs = {
  filters?: InputMaybe<IAssetFilters>;
};

export type IAssetsDetailsArgs = {
  filters?: InputMaybe<IAssetFilters>;
};

export type IAssetsFeatureArgs = {
  feature: IFeature;
};

export type IAssetsForecastArgs = {
  asset: Scalars['LabelType']['input'];
};

export type IAssetsListArgs = {
  filters?: InputMaybe<IListAssetFilters>;
};

export type IAssetsMetricHistoryArgs = {
  assetIds: Array<Scalars['UUID']['input']>;
  filters?: InputMaybe<IAssetMetricHistoryFilters>;
};

export type IAssetsPaginatedArgs = {
  cursors?: InputMaybe<ICursorsV1>;
  filters?: InputMaybe<IAssetByNameFilters>;
};

export type IAssetsPrecisePriceArgs = {
  assetId: Scalars['UUID']['input'];
  quoteAssetId?: InputMaybe<Scalars['UUID']['input']>;
  time: Scalars['ArrowType']['input'];
};

export type IAssetsPriceArgs = {
  assetIds: Array<Scalars['UUID']['input']>;
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  resolutionDays?: InputMaybe<Scalars['Int']['input']>;
  toDate?: InputMaybe<Scalars['Date']['input']>;
};

export type IAssetsPriceSummaryArgs = {
  assetIds: Array<Scalars['UUID']['input']>;
};

export type IAssetsTwoAssetLatestMetricsArgs = {
  input: ITwoAssetMetricRequestInput;
};

export type IAssetsTwoAssetMetricsArgs = {
  filters?: InputMaybe<ITwoAssetMetricFilters>;
  input: ITwoAssetMetricRequestInput;
};

export type IAverageVolume = {
  __typename?: 'AverageVolume';
  asset: IAsset;
  averageVolume: Scalars['Float']['output'];
};

export type IAverageVolumeRequest = {
  assets: Array<Scalars['UUID']['input']>;
  lookback?: InputMaybe<Scalars['Int']['input']>;
};

export type IAverageVolumeResponse = {
  __typename?: 'AverageVolumeResponse';
  averageVolume: Array<IAverageVolume>;
};

export type IBalanceAmount = {
  __typename?: 'BalanceAmount';
  amount: Scalars['Decimal']['output'];
  time: Scalars['ArrowType']['output'];
};

export type IBalanceByVenue = {
  __typename?: 'BalanceByVenue';
  balance: Scalars['Decimal']['output'];
  name: Scalars['String']['output'];
  venue: Scalars['LabelType']['output'];
};

export type IBaseAsset = {
  __typename?: 'BaseAsset';
  exchangeDetails?: Maybe<Scalars['LabelType']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  label?: Maybe<Scalars['LabelType']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  symbol: Scalars['String']['output'];
  type: IAssetType;
};

export type IBasicCardinalityConstraintInput = {
  numAssets: Scalars['Int']['input'];
  weight: Scalars['Float']['input'];
};

export type IBasicCardinalityConstraintType = {
  __typename?: 'BasicCardinalityConstraintType';
  numAssets: Scalars['Int']['output'];
  weight: Scalars['Float']['output'];
};

export type IBiasFromMultiFactorInput = {
  assetIds: Array<Scalars['ID']['input']>;
  date: Scalars['Date']['input'];
  minNumberOfFactors: Scalars['Int']['input'];
  multifactorId: Scalars['Int']['input'];
  useAbsoluteScores: Scalars['Boolean']['input'];
};

export type IBiasFromMultiFactorOutput = {
  __typename?: 'BiasFromMultiFactorOutput';
  assetBias: Array<IAssetScore>;
};

export type IBookkeeping = {
  __typename?: 'Bookkeeping';
  changes: IBookkeepingChanges;
  details: IBookkeepingDetails;
  investments: IInvestments;
  orders: IOrderOffsetLimitPage;
  reconciliation: IReconciliation;
  tradingActivity: Array<ITradingActivity>;
  transactionTags: Array<Scalars['String']['output']>;
  transactionUpload: ITransactionUpload;
  transactionsV2: ITransactionV2CursorPage;
};

export type IBookkeepingOrdersArgs = {
  filters?: InputMaybe<IOrderFilters>;
  paging?: InputMaybe<IPageLimitOffsetInput>;
  sort?: InputMaybe<ISortField>;
};

export type IBookkeepingTradingActivityArgs = {
  filters?: InputMaybe<ITradingActivityFilters>;
};

export type IBookkeepingTransactionsV2Args = {
  cursor: ICursorInput;
  filters?: InputMaybe<ITransactionFilters>;
  orderAscendingByTime?: Scalars['Boolean']['input'];
};

export type IBookkeepingChanges = {
  __typename?: 'BookkeepingChanges';
  recentlyCompletedOrders?: Maybe<Scalars['Decimal']['output']>;
};

export type IBookkeepingDetails = {
  __typename?: 'BookkeepingDetails';
  openBuyOrders: IOrderDetails;
  openOrders: Scalars['Int']['output'];
  openSellOrders: IOrderDetails;
  recentlyCompletedOrders: Scalars['Int']['output'];
};

export enum IBrinsonAttributionType {
  BrinsonFachler = 'BRINSON_FACHLER',
  BrinsonHoodBeebower = 'BRINSON_HOOD_BEEBOWER',
}

export type IBulletPoint = {
  __typename?: 'BulletPoint';
  asset: IAsset;
  id: Scalars['UUID']['output'];
  isTrending: Scalars['Boolean']['output'];
  newsFeed?: Maybe<INewsFeed>;
  publishedAt: Scalars['ArrowType']['output'];
  reaction?: Maybe<IReactionType>;
  sentiment?: Maybe<ISentiment>;
  summary: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  topic?: Maybe<ITopic>;
  url: Scalars['String']['output'];
};

export type IBulletPointFilters = {
  assetIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  isTrending?: InputMaybe<Scalars['Boolean']['input']>;
  newsFeedIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  time?: InputMaybe<ITimeRangeFilterV2>;
  topicIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IBulletPointOffsetLimitPage = {
  __typename?: 'BulletPointOffsetLimitPage';
  data: Array<IBulletPoint>;
  pageInfo: IPageOffsetInfo;
};

export type IBulletPointReactionInput = {
  bulletPointId: Scalars['UUID']['input'];
  reaction?: InputMaybe<IReactionType>;
};

export type ICalculatorContributionOutput = {
  __typename?: 'CalculatorContributionOutput';
  label: Scalars['LabelType']['output'];
  portfolioDefinition?: Maybe<IPortfolioDefinition>;
  series: Array<ICalculatorContributionSeries>;
};

export type ICalculatorContributionSeries = {
  __typename?: 'CalculatorContributionSeries';
  issues: Array<IIssueItem>;
  parameters: Array<IParameterOutput>;
  total: Scalars['Decimal']['output'];
  values: Array<IAssetContributionValue>;
};

export type ICalculatorMetricSeriesOutput = {
  __typename?: 'CalculatorMetricSeriesOutput';
  label: Scalars['LabelType']['output'];
  portfolioDefinition?: Maybe<IPortfolioDefinition>;
  series: Array<ICalculatorSeries>;
};

export type ICalculatorSeries = {
  __typename?: 'CalculatorSeries';
  hasContributions: Scalars['Boolean']['output'];
  issues: Array<IIssueItem>;
  parameters: Array<IParameterOutput>;
  values: Array<ISerieValue>;
};

export type ICalendar = {
  __typename?: 'Calendar';
  calendarEvents: Array<ICalendarEvent>;
};

export type ICalendarCalendarEventsArgs = {
  filters?: InputMaybe<ICalendarEventFilters>;
};

export type ICalendarEvent = {
  __typename?: 'CalendarEvent';
  assets: Array<IAsset>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  time: Scalars['ArrowType']['output'];
  title: Scalars['String']['output'];
};

export type ICalendarEventFilters = {
  assets?: InputMaybe<Array<IAssetInput>>;
  time?: InputMaybe<ITimeRangeFilter>;
};

export type ICalendarEventInput = {
  assets: Array<IAssetInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  time: Scalars['ArrowType']['input'];
  title: Scalars['String']['input'];
};

export type ICandle = {
  __typename?: 'Candle';
  base?: Maybe<IAsset>;
  close: Scalars['Decimal']['output'];
  dataSource?: Maybe<IDataSource>;
  high: Scalars['Decimal']['output'];
  low: Scalars['Decimal']['output'];
  open: Scalars['Decimal']['output'];
  quote?: Maybe<IAsset>;
  time: Scalars['ArrowType']['output'];
  timeFrame: Scalars['TimeFrameType']['output'];
  volume: Scalars['Decimal']['output'];
};

export type ICandleFilters = {
  base?: InputMaybe<Scalars['LabelType']['input']>;
  dataSource?: InputMaybe<Scalars['LabelType']['input']>;
  limit?: Scalars['Int']['input'];
  quote?: InputMaybe<Scalars['LabelType']['input']>;
  time?: InputMaybe<ITimeRangeFilter>;
  timeFrame?: InputMaybe<Scalars['TimeFrameType']['input']>;
};

export type ICandlePage = {
  __typename?: 'CandlePage';
  data: Array<ICandle>;
  pageInfo: IPageInfoV1;
};

export type ICardinalityConstraintInput = {
  atLeast?: InputMaybe<IBasicCardinalityConstraintInput>;
  atMost?: InputMaybe<IBasicCardinalityConstraintInput>;
  side: ICardinalitySide;
};

export type ICardinalityConstraintType = {
  __typename?: 'CardinalityConstraintType';
  atLeast?: Maybe<IBasicCardinalityConstraintType>;
  atMost?: Maybe<IBasicCardinalityConstraintType>;
  side: ICardinalitySide;
};

export enum ICardinalitySide {
  Long = 'LONG',
  Short = 'SHORT',
}

export type ICategoryValue = {
  __typename?: 'CategoryValue';
  category: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type IClusterAttributionResult = {
  __typename?: 'ClusterAttributionResult';
  benchmarkClusterDailyReturns: Array<IDatedCategoryValues>;
  benchmarkClusterWeights: Array<IDatedCategoryValues>;
  clusterDailyReturnsDifference: Array<IDatedCategoryValues>;
  clusterWeightsDifference: Array<IDatedCategoryValues>;
  cumulativeAllocationEffect: Array<IDatedCategoryValues>;
  cumulativeBenchmarkReturns: Array<IDatedValue>;
  cumulativeInteractionEffect: Array<IDatedCategoryValues>;
  cumulativeOverperformance: Array<IDatedValue>;
  cumulativePortfolioReturns: Array<IDatedValue>;
  cumulativeSelectionEffect: Array<IDatedCategoryValues>;
  cumulativeTradingActivity: Array<IDatedValue>;
  dailyAllocationEffect: Array<IDatedCategoryValues>;
  dailyBenchmarkReturns: Array<IDatedValue>;
  dailyInteractionEffect: Array<IDatedCategoryValues>;
  dailyOverperformance: Array<IDatedValue>;
  dailyPortfolioReturns: Array<IDatedValue>;
  dailySelectionEffect: Array<IDatedCategoryValues>;
  dailyTradingActivity: Array<IDatedValue>;
  portfolioClusterDailyReturns: Array<IDatedCategoryValues>;
  portfolioClusterWeights: Array<IDatedCategoryValues>;
};

export type IConfidenceIntervals = {
  __typename?: 'ConfidenceIntervals';
  name: Scalars['String']['output'];
  value?: Maybe<Scalars['Float']['output']>;
};

export type IConfidenceIntervalsV2 = {
  __typename?: 'ConfidenceIntervalsV2';
  name: Scalars['String']['output'];
  value?: Maybe<Scalars['Float']['output']>;
};

export type IConstrainedAsset = {
  __typename?: 'ConstrainedAsset';
  asset: IAsset;
  constraint?: Maybe<IConstraint>;
  expectedYield?: Maybe<Scalars['Float']['output']>;
  maxLeverage?: Maybe<Scalars['Float']['output']>;
  riskBudget?: Maybe<Scalars['Float']['output']>;
  userExpectedReturn?: Maybe<Scalars['Float']['output']>;
};

export type IConstrainedAssetInput = {
  constraint?: InputMaybe<IConstraintInput>;
  expectedYield?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['UUID']['input'];
  maxLeverage?: InputMaybe<Scalars['Float']['input']>;
  riskBudget?: InputMaybe<Scalars['Float']['input']>;
  userExpectedReturn?: InputMaybe<Scalars['Float']['input']>;
};

export type IConstrainedFund = {
  __typename?: 'ConstrainedFund';
  constraint?: Maybe<IConstraint>;
  fund: IPortfolioDefinition;
  riskBudget?: Maybe<Scalars['Float']['output']>;
  userExpectedReturn?: Maybe<Scalars['Float']['output']>;
};

export type IConstrainedFundInput = {
  constraint?: InputMaybe<IConstraintInput>;
  id: Scalars['UUID']['input'];
  riskBudget?: InputMaybe<Scalars['Float']['input']>;
  userExpectedReturn?: InputMaybe<Scalars['Float']['input']>;
};

export type IConstraint = {
  __typename?: 'Constraint';
  lowerBound: Scalars['Float']['output'];
  upperBound: Scalars['Float']['output'];
};

export enum IConstraintFormulation {
  AbsoluteValue = 'ABSOLUTE_VALUE',
  Percentage = 'PERCENTAGE',
}

export type IConstraintInput = {
  lowerBound: Scalars['Float']['input'];
  upperBound: Scalars['Float']['input'];
};

export enum IContributionMethod {
  MultiPeriod = 'MULTI_PERIOD',
  SinglePeriod = 'SINGLE_PERIOD',
}

export type IConversation = {
  __typename?: 'Conversation';
  id: Scalars['UUID']['output'];
  privacyLevel: IConversationPrivacyLevelType;
  title: Scalars['String']['output'];
  updatedAt: Scalars['ArrowType']['output'];
};

export type IConversationInput = {
  id: Scalars['UUID']['input'];
  title: Scalars['String']['input'];
};

export enum IConversationPrivacyLevelType {
  OrganizationEdit = 'ORGANIZATION_EDIT',
  OrganizationView = 'ORGANIZATION_VIEW',
  Private = 'PRIVATE',
}

export type ICorrelationDailyValue = {
  __typename?: 'CorrelationDailyValue';
  date: Scalars['Date']['output'];
  value: Scalars['Float']['output'];
};

export type ICreateAccountInput = {
  inceptionDate?: InputMaybe<Scalars['ArrowType']['input']>;
  name: Scalars['String']['input'];
  onboarding?: InputMaybe<IOnboardingInput>;
  secrets?: InputMaybe<Scalars['JSON']['input']>;
  venue: Scalars['LabelType']['input'];
};

export type ICreateAssetGroupInput = {
  assets: Array<Scalars['UUID']['input']>;
  clusterName: Scalars['String']['input'];
  groupName: Scalars['String']['input'];
};

export type ICreatePortfolioDefCompositionInput = {
  id: Scalars['UUID']['input'];
  weight: Scalars['Float']['input'];
};

export type ICreatePortfolioDefInput = {
  assetRule?: InputMaybe<IRebalancedPortfolioAssetRuleInput>;
  composition?: InputMaybe<Array<ICreatePortfolioDefCompositionInput>>;
  description?: Scalars['String']['input'];
  name: Scalars['String']['input'];
  realDefAccounts?: InputMaybe<Array<Scalars['UUID']['input']>>;
  realDefSubFunds?: InputMaybe<Array<Scalars['Int']['input']>>;
  subType?: InputMaybe<IPortfolioDefinitionSubType>;
  tag?: InputMaybe<Scalars['String']['input']>;
  type: IUserPortfolioDefinitionType;
};

export type ICreatePrivateAssetInput = {
  name: Scalars['String']['input'];
  symbol: Scalars['String']['input'];
};

export type ICreateRebalancingRuleInput = {
  endAt?: InputMaybe<Scalars['Date']['input']>;
  maxRebalancingCost?: InputMaybe<Scalars['Decimal']['input']>;
  name: Scalars['String']['input'];
  periodUnit?: InputMaybe<IPeriodUnit>;
  periodValue?: InputMaybe<Scalars['Int']['input']>;
  portfolioDefinitionId: Scalars['UUID']['input'];
  startAt: Scalars['Date']['input'];
  tradingFee: Scalars['Decimal']['input'];
};

export type ICreateReturnsBasedPortfolioInput = {
  date: Scalars['Date']['input'];
  portfolioName: Scalars['String']['input'];
  value: Scalars['Float']['input'];
};

export type ICreateSubFund = {
  accounts: Array<Scalars['UUID']['input']>;
  dimension: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  referenceAsset?: InputMaybe<Scalars['UUID']['input']>;
  subAccounts: Array<Scalars['UUID']['input']>;
};

export type ICreateTransactionV2InputType = {
  attributedToAsset?: InputMaybe<IAssetInput>;
  comment?: InputMaybe<Scalars['String']['input']>;
  deduplicationId?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  externalType?: InputMaybe<Scalars['String']['input']>;
  legs: Array<ITransactionLegInputType>;
  order?: InputMaybe<IOrderV2InputType>;
  status: ITransactionStatus;
  subAccount: ISubAccountInput;
  tags: Array<Scalars['String']['input']>;
  time: Scalars['ArrowType']['input'];
  userType: IUserTransactionType;
};

export type ICreateWidgetInput = {
  data: Scalars['JSONObject']['input'];
  id: Scalars['String']['input'];
  layout: ILayoutInput;
  page: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type ICumulativeReturn = {
  __typename?: 'CumulativeReturn';
  backtest: Array<ITimeSeriesScalarData>;
  live: Array<ITimeSeriesScalarData>;
};

export type ICursorInput = {
  cursorType: ICursorType;
  cursorValue?: InputMaybe<Scalars['String']['input']>;
  limit?: Scalars['Int']['input'];
};

export enum ICursorType {
  After = 'AFTER',
  AfterEqual = 'AFTER_EQUAL',
  Before = 'BEFORE',
  BeforeEqual = 'BEFORE_EQUAL',
  First = 'FIRST',
  Last = 'LAST',
}

export type ICursors = {
  __typename?: 'Cursors';
  first?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  last?: Maybe<Scalars['String']['output']>;
};

export type ICursorsV1 = {
  after?: InputMaybe<Scalars['Base64']['input']>;
  before?: InputMaybe<Scalars['Base64']['input']>;
};

export type IDailyPerformanceByClusterAndSide = {
  __typename?: 'DailyPerformanceByClusterAndSide';
  long: Array<IDatedCategoryValues>;
  short: Array<IDatedCategoryValues>;
  total: Array<IDatedCategoryValues>;
};

export type IDailyPortfolioPerformance = {
  __typename?: 'DailyPortfolioPerformance';
  cumulativeOpportunisticTradingContribution: Scalars['Float']['output'];
  cumulativePortfolioTwr: Scalars['Float']['output'];
  cumulativeTotalContributionByAsset: Scalars['Float']['output'];
  dailyOpportunisticTradingContribution: Scalars['Float']['output'];
  dailyPortfolioTwr: Scalars['Float']['output'];
  dailyTotalContributionByAsset: Scalars['Float']['output'];
  date: Scalars['Date']['output'];
};

export type IDailySnapshotValues = {
  __typename?: 'DailySnapshotValues';
  amount?: Maybe<Scalars['Float']['output']>;
  cashWeight?: Maybe<Scalars['Float']['output']>;
  closePrice?: Maybe<Scalars['Float']['output']>;
  cumulativeAssetReturn?: Maybe<Scalars['Float']['output']>;
  cumulativeContributionsToReturn?: Maybe<Scalars['Float']['output']>;
  dailyAssetReturn?: Maybe<Scalars['Float']['output']>;
  dailyContributionToReturn?: Maybe<Scalars['Float']['output']>;
  date: Scalars['Date']['output'];
  exposure?: Maybe<Scalars['Float']['output']>;
  openPrice?: Maybe<Scalars['Float']['output']>;
};

export type IDataSource = {
  __typename?: 'DataSource';
  label: Scalars['LabelType']['output'];
};

export type IDateRangeFilter = {
  since?: InputMaybe<Scalars['Date']['input']>;
  to?: InputMaybe<Scalars['Date']['input']>;
};

export type IDatedCategoryValues = {
  __typename?: 'DatedCategoryValues';
  categoryValues: Array<ICategoryValue>;
  date: Scalars['Date']['output'];
};

export type IDatedValue = {
  __typename?: 'DatedValue';
  date: Scalars['Date']['output'];
  value: Scalars['Float']['output'];
};

export type IDeleteAssetGroupInput = {
  groupName: Scalars['String']['input'];
};

export type IDeleteSubFundInput = {
  name: Scalars['String']['input'];
};

export type IDerivativeDetails = {
  __typename?: 'DerivativeDetails';
  baseAsset: IBaseAsset;
  derivativeType: IDerivativeType;
  exchange: Scalars['LabelType']['output'];
  expirationDate?: Maybe<Scalars['ArrowType']['output']>;
  optionType?: Maybe<IOptionType>;
  quoteAsset: IBaseAsset;
  settlementAsset: IBaseAsset;
  strikePrice?: Maybe<Scalars['Decimal']['output']>;
};

export enum IDerivativeType {
  Future = 'FUTURE',
  Option = 'OPTION',
  PerpetualFuture = 'PERPETUAL_FUTURE',
}

export type IDownloadResponse = {
  __typename?: 'DownloadResponse';
  token: Scalars['String']['output'];
};

export type IEvent = {
  asset: Scalars['LabelType']['input'];
  netReturn: Scalars['Float']['input'];
};

export type IExecutionStrategyResult = {
  __typename?: 'ExecutionStrategyResult';
  displayName: Scalars['String']['output'];
  metricTimeValues: Array<ITradingMetricTimeValue>;
  metricValues: Array<ITradingMetricValue>;
};

export type IExtendedAllocationType = {
  __typename?: 'ExtendedAllocationType';
  absoluteAllocationInCollateral: Scalars['Float']['output'];
  baseApy: Scalars['Float']['output'];
  dollarValueOfAllocation: Scalars['Float']['output'];
  impactedApy: Scalars['Float']['output'];
  percentageAllocationInCollateral: Scalars['Float']['output'];
  percentageAllocationInUsd: Scalars['Float']['output'];
  pool: IPoolInfo;
  yieldInCollateral: Scalars['Float']['output'];
  yieldInUsd: Scalars['Float']['output'];
};

export type IFactor = {
  __typename?: 'Factor';
  category: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export enum IFactorCategory {
  Asset = 'ASSET',
  Factor = 'FACTOR',
  Portfolio = 'PORTFOLIO',
}

export enum IFactorCategoryV2 {
  Asset = 'ASSET',
  Factor = 'FACTOR',
}

export type IFactorPreset = {
  __typename?: 'FactorPreset';
  description: Scalars['String']['output'];
  factors: Array<ISupportedFactor>;
  name: Scalars['String']['output'];
};

export type IFactorPresetV2 = {
  __typename?: 'FactorPresetV2';
  description: Scalars['String']['output'];
  factors: Array<ISupportedFactorV2>;
  name: Scalars['String']['output'];
};

export type IFactorRequestResult = {
  __typename?: 'FactorRequestResult';
  code: IRequestResultCode;
  collinearAssets: Array<IAsset>;
  message?: Maybe<Array<Scalars['String']['output']>>;
  results: Array<IRegressionResult>;
};

export type IFactorRequestResultV2 = {
  __typename?: 'FactorRequestResultV2';
  code: IRequestResultCodeV2;
  collinearAssets: Array<IAsset>;
  message?: Maybe<Array<Scalars['String']['output']>>;
  portfolioDefinition: IPortfolioDefinition;
  results: Array<IRegressionResultV2>;
};

export type IFactorResult = {
  __typename?: 'FactorResult';
  beta?: Maybe<Scalars['Float']['output']>;
  confidenceIntervals: Array<IConfidenceIntervals>;
  factor?: Maybe<IAsset>;
  pValue?: Maybe<Scalars['Float']['output']>;
  stdErr?: Maybe<Scalars['Float']['output']>;
};

export type IFactorResultV2 = {
  __typename?: 'FactorResultV2';
  beta?: Maybe<Scalars['Float']['output']>;
  confidenceIntervals: Array<IConfidenceIntervalsV2>;
  factor?: Maybe<IAsset>;
  pValue?: Maybe<Scalars['Float']['output']>;
  stdErr?: Maybe<Scalars['Float']['output']>;
};

export type IFactorRouterType = {
  __typename?: 'FactorRouterType';
  /** Get factor presets library */
  factorPresets: Array<IFactorPreset>;
  /** Get portfolio factor regression given a list of factors */
  portfolioFactorRegression: IFactorRequestResult;
  /** Get supported factors */
  supportedFactors: Array<ISupportedFactor>;
  /** Get supported time window for factor regression based on user's available portfolio snapshots */
  supportedTimeWindow: IFactorServiceInfo;
};

export type IFactorRouterTypePortfolioFactorRegressionArgs = {
  input: IRegressionServiceInput;
};

export type IFactorRouterTypeSupportedTimeWindowArgs = {
  filters?: InputMaybe<ISubAccountAssetFilterInputType>;
};

export type IFactorRouterTypeV2 = {
  __typename?: 'FactorRouterTypeV2';
  /** Get factor presets library */
  factorPresets: Array<IFactorPresetV2>;
  /** Get portfolio factor regression given a list of factors */
  portfolioFactorRegression: Array<IFactorRequestResultV2>;
  /** Get supported factors */
  supportedFactors: Array<ISupportedFactorV2>;
  /** Get supported time window for factor regression based on user's available portfolio snapshots */
  supportedTimeWindow: Array<IFactorServiceInfoV2>;
};

export type IFactorRouterTypeV2PortfolioFactorRegressionArgs = {
  input: IRegressionServiceInputV2;
};

export type IFactorRouterTypeV2SupportedTimeWindowArgs = {
  portfolioDefinitionIds: Array<Scalars['UUID']['input']>;
};

export type IFactorServiceInfo = {
  __typename?: 'FactorServiceInfo';
  canUserUseService: Scalars['Boolean']['output'];
  supportedTimeWindow?: Maybe<ISupportedDateTimeWindow>;
};

export type IFactorServiceInfoV2 = {
  __typename?: 'FactorServiceInfoV2';
  canUserUseService: Scalars['Boolean']['output'];
  portfolioDefinition: IPortfolioDefinition;
  supportedTimeWindow?: Maybe<ISupportedDateTimeWindowV2>;
};

export enum IFeature {
  AssetsCorrelation = 'ASSETS_CORRELATION',
  AssetGroup = 'ASSET_GROUP',
  AssetRiskMetricCollector = 'ASSET_RISK_METRIC_COLLECTOR',
  Benchmark = 'BENCHMARK',
  Beta = 'BETA',
  Bond = 'BOND',
  CorrelationDashboard = 'CORRELATION_DASHBOARD',
  DashboardForecast = 'DASHBOARD_FORECAST',
  DataProvider = 'DATA_PROVIDER',
  DiscoveryDashboard = 'DISCOVERY_DASHBOARD',
  Factor = 'FACTOR',
  FineTuner = 'FINE_TUNER',
  Journal = 'JOURNAL',
  KnownAssets = 'KNOWN_ASSETS',
  Notification = 'NOTIFICATION',
  PcaWhitelist = 'PCA_WHITELIST',
  Polygon = 'POLYGON',
  PortfolioRiskDashboard = 'PORTFOLIO_RISK_DASHBOARD',
  PortfolioRiskService = 'PORTFOLIO_RISK_SERVICE',
  PortfolioSimulator = 'PORTFOLIO_SIMULATOR',
  PriceCollector = 'PRICE_COLLECTOR',
  RiskFreeRate = 'RISK_FREE_RATE',
  Search = 'SEARCH',
  SensitivitiesDashboard = 'SENSITIVITIES_DASHBOARD',
  Signal = 'SIGNAL',
  StressTest = 'STRESS_TEST',
  YahooFinance = 'YAHOO_FINANCE',
}

export enum IFeatureFlagStatus {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
}

export type IFee = {
  __typename?: 'Fee';
  amount: Scalars['Float']['output'];
  asset: IAsset;
};

export type IFileUpload = {
  __typename?: 'FileUpload';
  exists: Scalars['Boolean']['output'];
  fileName: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  uploadTime: Scalars['ArrowType']['output'];
};

export enum IFilterOperator {
  And = 'AND',
  Or = 'OR',
}

export enum IFilterType {
  Number = 'NUMBER',
  Set = 'SET',
}

export type IFineTunerInput = {
  assetWeights: Array<IAssetWeight>;
  date?: InputMaybe<Scalars['Date']['input']>;
  leverageType?: ILeverageType;
};

export type IFineTunerOutput = {
  __typename?: 'FineTunerOutput';
  riskMetrics: Array<IAssetRiskMeasureType>;
};

export type IFitWarningType = {
  __typename?: 'FitWarningType';
  poolId: Scalars['UUID']['output'];
  warnings: Array<Scalars['String']['output']>;
};

export enum IFitnessFunctions {
  Default = 'DEFAULT',
  Name = 'NAME',
  Symbol = 'SYMBOL',
}

export type IFloatRowValues = {
  __typename?: 'FloatRowValues';
  absolute: Scalars['Float']['output'];
  percentage: Scalars['Float']['output'];
};

export type IForecast = {
  __typename?: 'Forecast';
  asset: IAsset;
  expectedReturn: Scalars['Float']['output'];
  targetRiskBudget: Scalars['Float']['output'];
};

export enum IFormat {
  Number = 'NUMBER',
  Percentage = 'PERCENTAGE',
}

export type IFundExpectedReturnRiskBudget = {
  __typename?: 'FundExpectedReturnRiskBudget';
  expectedReturn: Scalars['Float']['output'];
  fundId: IPortfolioDefinition;
  riskBudget: Scalars['Float']['output'];
};

export type IFundMeasure = {
  __typename?: 'FundMeasure';
  amount: Scalars['Float']['output'];
  cashWeight: Scalars['Float']['output'];
  fund: IPortfolioDefinition;
  weights: Array<IFundRiskMeasureType>;
};

export type IFundOptimization = {
  __typename?: 'FundOptimization';
  getForecastst: Array<IFundExpectedReturnRiskBudget>;
  getOptimizableFundsForUser: Array<IPortfolioDefinition>;
  getOptimization: IFundOptimizationResult;
  getOptimizationInput: IFundOptimizationUserInput;
  listOptimizations: Array<IFundOptimizationResult>;
};

export type IFundOptimizationGetForecaststArgs = {
  fundIds: Array<Scalars['UUID']['input']>;
};

export type IFundOptimizationGetOptimizableFundsForUserArgs = {
  minDays?: InputMaybe<Scalars['Int']['input']>;
  optimizationDate?: InputMaybe<Scalars['Date']['input']>;
};

export type IFundOptimizationGetOptimizationArgs = {
  optimizationId: Scalars['Int']['input'];
};

export type IFundOptimizationGetOptimizationInputArgs = {
  optimizationId: Scalars['Int']['input'];
};

export type IFundOptimizationInput = {
  constraintFormulation?: IConstraintFormulation;
  description: Scalars['String']['input'];
  funds: Array<IConstrainedFundInput>;
  futureReturnsHorizon?: InputMaybe<Scalars['Int']['input']>;
  givenPortfolio?: Array<IGivenPortfolioFundInput>;
  lookback?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  objectives: Array<IOptimizationObjectiveInput>;
  optimizationDate?: InputMaybe<Scalars['Date']['input']>;
  portfolioAmount: Scalars['Float']['input'];
  portfolioConstraints?: InputMaybe<IFundPortfolioConstraintsInput>;
  returnMeasureName?: InputMaybe<IReturnMeasureNameUi>;
};

export type IFundOptimizationResult = {
  __typename?: 'FundOptimizationResult';
  createdAt: Scalars['ArrowType']['output'];
  description: Scalars['String']['output'];
  fundOutput: Array<IFundOutput>;
  givenPortfolioOutput?: Maybe<IFundOutput>;
  name: Scalars['String']['output'];
  objectives: Array<IPortfolioOptimizationObjective>;
  optimizationId: Scalars['Int']['output'];
  status: IPortfolioOptimizationResultStatusType;
  updatedAt?: Maybe<Scalars['ArrowType']['output']>;
};

export type IFundOptimizationUserInput = {
  __typename?: 'FundOptimizationUserInput';
  constraintFormulation: IConstraintFormulation;
  fundConstraints: Array<IConstrainedFund>;
  givenPortfolio: Array<IGivenPortfolioFund>;
  portfolioAmount: Scalars['Float']['output'];
  portfolioConstraints?: Maybe<IPortfolioConstraints>;
  returnMeasureName?: Maybe<IReturnMeasureNameUi>;
};

export type IFundOutput = {
  __typename?: 'FundOutput';
  fundDeltas: Array<IFundPositionDelta>;
  fundMeasures: Array<IFundMeasure>;
  nonDominated: Scalars['Boolean']['output'];
  objectiveValues: Array<IPortfolioObjectiveValue>;
  portfolioExposures: Array<IPortfolioExposure>;
  returnsPredictions: IReturnsPredictions;
  riskMeasures: Array<IFundRiskMeasureType>;
  solutionId: Scalars['Int']['output'];
  status?: Maybe<IOptimizationResultStatus>;
  statusMessage?: Maybe<Scalars['String']['output']>;
  suggested: Scalars['Boolean']['output'];
};

export type IFundPortfolioConstraintsInput = {
  primary?: InputMaybe<IPrimaryPortfolioConstraintInput>;
  secondary?: InputMaybe<IFundSecondaryPortfolioConstraintInput>;
};

export type IFundPositionDelta = {
  __typename?: 'FundPositionDelta';
  amount: Scalars['Float']['output'];
  fund: IPortfolioDefinition;
};

export type IFundRiskMeasureType = {
  __typename?: 'FundRiskMeasureType';
  benchmark?: Maybe<IPortfolioDefinition>;
  measure: Scalars['LabelType']['output'];
  value: Scalars['Float']['output'];
};

export type IFundSecondaryPortfolioConstraintInput = {
  constraint: IConstraintInput;
  riskMetric?: InputMaybe<IRiskMetricInput>;
};

export type IFuturePosition = {
  amount: Scalars['Float']['input'];
  assetId: Scalars['UUID']['input'];
  entryPrice: Scalars['Float']['input'];
  side: IPositionSide;
};

export type IGivenPortfolioAsset = {
  __typename?: 'GivenPortfolioAsset';
  asset: IAsset;
  cashWeight: Scalars['Float']['output'];
  expectedYield?: Maybe<Scalars['Float']['output']>;
  maxLeverage?: Maybe<Scalars['Float']['output']>;
  riskBudget?: Maybe<Scalars['Float']['output']>;
  userExpectedReturn?: Maybe<Scalars['Float']['output']>;
};

export type IGivenPortfolioAssetInput = {
  cashWeight: Scalars['Float']['input'];
  expectedYield?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['UUID']['input'];
  maxLeverage?: InputMaybe<Scalars['Float']['input']>;
  riskBudget?: InputMaybe<Scalars['Float']['input']>;
  userExpectedReturn?: InputMaybe<Scalars['Float']['input']>;
};

export type IGivenPortfolioFund = {
  __typename?: 'GivenPortfolioFund';
  cashWeight: Scalars['Float']['output'];
  fund: IPortfolioDefinition;
  riskBudget?: Maybe<Scalars['Float']['output']>;
  userExpectedReturn?: Maybe<Scalars['Float']['output']>;
};

export type IGivenPortfolioFundInput = {
  cashWeight: Scalars['Float']['input'];
  id: Scalars['UUID']['input'];
  riskBudget?: InputMaybe<Scalars['Float']['input']>;
  userExpectedReturn?: InputMaybe<Scalars['Float']['input']>;
};

export type IGraph = {
  __typename?: 'Graph';
  edges: Array<IGraphEdge>;
  nodes: Array<IGraphNode>;
};

export type IGraphEdge = {
  __typename?: 'GraphEdge';
  firstNodeId: IGraphNodeId;
  secondNodeId: IGraphNodeId;
  weight: Scalars['Float']['output'];
};

export type IGraphGlobalFilters = {
  assetIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  isBookmarked?: InputMaybe<Scalars['Boolean']['input']>;
  isViewed?: InputMaybe<Scalars['Boolean']['input']>;
  newsFeedIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  time?: InputMaybe<ITimeRangeFilterV2>;
};

export type IGraphNode = {
  __typename?: 'GraphNode';
  hasNewArticles: Scalars['Boolean']['output'];
  id: IGraphNodeId;
};

export type IGraphNodeId = {
  __typename?: 'GraphNodeID';
  asset?: Maybe<IAsset>;
  topic?: Maybe<ITopic>;
  type: IGraphNodeType;
};

export type IGraphNodeIdInput = {
  assetId?: InputMaybe<Scalars['UUID']['input']>;
  topicId?: InputMaybe<Scalars['Int']['input']>;
};

export enum IGraphNodeType {
  Asset = 'ASSET',
  Topic = 'TOPIC',
}

export type IGreeks = {
  __typename?: 'Greeks';
  delta: Scalars['Decimal']['output'];
  gamma: Scalars['Decimal']['output'];
  rho: Scalars['Decimal']['output'];
  theta: Scalars['Decimal']['output'];
  vega: Scalars['Decimal']['output'];
};

export enum IGroupConstrainedQuantity {
  GrossExposure = 'GROSS_EXPOSURE',
  NetExposure = 'NET_EXPOSURE',
}

export type IGroupConstraint = {
  __typename?: 'GroupConstraint';
  allocationConstraints: Array<IAllocationConstraintType>;
  cardinalityConstraints: Array<ICardinalityConstraintType>;
  group: IAssetGroup;
};

export type IGroupConstraintInput = {
  allocationConstraints?: Array<IAllocationConstraintInput>;
  cardinalityConstraints?: Array<ICardinalityConstraintInput>;
  groupId: Scalars['Int']['input'];
};

export type IGroupExposure = {
  __typename?: 'GroupExposure';
  exposures: Array<IGroupExposureValues>;
  group: IAssetGroup;
};

export enum IGroupExposureStatName {
  Gross = 'GROSS',
  Long = 'LONG',
  Net = 'NET',
  Short = 'SHORT',
}

export type IGroupExposureValues = {
  __typename?: 'GroupExposureValues';
  amount: Scalars['Float']['output'];
  name: IGroupExposureStatName;
  percentage: Scalars['Float']['output'];
};

export type IImmediateVestingInput = {
  date: Scalars['ArrowType']['input'];
  totalVestedAmount: Scalars['Decimal']['input'];
};

export type IIntIncludeExcludeFilter = {
  exclude?: InputMaybe<Array<Scalars['Int']['input']>>;
  include?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IIntRowValues = {
  __typename?: 'IntRowValues';
  absolute: Scalars['Int']['output'];
  percentage: Scalars['Float']['output'];
};

export type IInvestment = {
  __typename?: 'Investment';
  actions: Array<IAction>;
  credit: IAssetValue;
  debit: IAssetValue;
  executedAt: Scalars['ArrowType']['output'];
  fee?: Maybe<IAssetValue>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  status: IInvestmentStatus;
  subAccount: ISubAccount;
  vestedAmount: Scalars['Decimal']['output'];
};

export type IInvestmentBalance = {
  __typename?: 'InvestmentBalance';
  balance: Array<IBalanceAmount>;
  investment: IInvestment;
};

export type IInvestmentInput = {
  credit?: InputMaybe<IAssetValueInput>;
  debit?: InputMaybe<IAssetValueInput>;
  executedAt: Scalars['ArrowType']['input'];
  fee?: InputMaybe<IAssetValueInput>;
  name: Scalars['String']['input'];
  schedules: Array<IVestingInput>;
  subAccount: ISubAccountInput;
};

export enum IInvestmentStatus {
  Pending = 'PENDING',
  Succeeded = 'SUCCEEDED',
}

export type IInvestments = {
  __typename?: 'Investments';
  assetBalance: IAssetInvestmentBalance;
  assetDetails: IAssetInvestmentDetails;
  list: Array<IInvestment>;
};

export type IInvestmentsAssetBalanceArgs = {
  assetId: Scalars['UUID']['input'];
};

export type IInvestmentsAssetDetailsArgs = {
  assetId: Scalars['UUID']['input'];
};

export enum IIssue {
  LimitingHistory = 'LIMITING_HISTORY',
  Option = 'OPTION',
  RejectedPrivate = 'REJECTED_PRIVATE',
  Unsupported = 'UNSUPPORTED',
}

export type IIssueItem = {
  __typename?: 'IssueItem';
  asset: IAsset;
  issueType: IIssue;
};

export type IJournal = {
  __typename?: 'Journal';
  anyJournalError?: Maybe<IJournalCostBasisError>;
  costBasis: Array<IAssetCostBasis>;
  oldestJournalAssetErrors: Array<IJournalCostBasisError>;
  preferences: IJournalPreferences;
};

export type IJournalCostBasisArgs = {
  perOrganization: Scalars['Boolean']['input'];
};

export type IJournalCostBasisError = {
  __typename?: 'JournalCostBasisError';
  amount: Scalars['Decimal']['output'];
  asset: IAsset;
  error?: Maybe<IJournalError>;
  remainingAmount: Scalars['Decimal']['output'];
  subFund?: Maybe<ISubFund>;
  time: Scalars['ArrowType']['output'];
};

export type IJournalEntryPnlV2 = {
  __typename?: 'JournalEntryPnlV2';
  amount: Scalars['Decimal']['output'];
  asset: IAsset;
  costBasis?: Maybe<Scalars['Decimal']['output']>;
  entryType: IJournalEntryType;
  error?: Maybe<IJournalError>;
  id: Scalars['UUID']['output'];
  remainingAmount: Scalars['Decimal']['output'];
  subFundId: Scalars['Int']['output'];
  time: Scalars['ArrowType']['output'];
};

export enum IJournalEntryType {
  Credit = 'CREDIT',
  Debit = 'DEBIT',
}

export enum IJournalError {
  MissingAmount = 'MISSING_AMOUNT',
  MissingCostBasis = 'MISSING_COST_BASIS',
}

export type IJournalPreferences = {
  __typename?: 'JournalPreferences';
  dimension?: Maybe<Scalars['String']['output']>;
};

export type IKeyMeasure = {
  __typename?: 'KeyMeasure';
  asset?: Maybe<IAsset>;
  name: IKeyMeasureName;
  netChange: Scalars['Float']['output'];
  value: Scalars['Float']['output'];
};

export enum IKeyMeasureName {
  Balance = 'BALANCE',
  GaussianCvar95 = 'GAUSSIAN_CVAR95',
  GaussianCvar99 = 'GAUSSIAN_CVAR99',
  GaussianVar95 = 'GAUSSIAN_VAR95',
  GaussianVar99 = 'GAUSSIAN_VAR99',
  HistoricCvar95 = 'HISTORIC_CVAR95',
  HistoricCvar99 = 'HISTORIC_CVAR99',
  HistoricVar95 = 'HISTORIC_VAR95',
  HistoricVar99 = 'HISTORIC_VAR99',
  Variance = 'VARIANCE',
  Volatility = 'VOLATILITY',
  WorstAsset = 'WORST_ASSET',
}

export type IKeyMeasures = {
  __typename?: 'KeyMeasures';
  assetCount: Scalars['Int']['output'];
  measures: Array<IKeyMeasure>;
};

export type ILabelledScenario = {
  __typename?: 'LabelledScenario';
  description: Scalars['String']['output'];
  events: Array<IAssetEvent>;
  name: Scalars['String']['output'];
};

export type ILayout = {
  __typename?: 'Layout';
  h: Scalars['Float']['output'];
  w: Scalars['Float']['output'];
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

export type ILayoutInput = {
  h: Scalars['Float']['input'];
  w: Scalars['Float']['input'];
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
};

export type ILevelResult = {
  __typename?: 'LevelResult';
  asset?: Maybe<IAsset>;
  children: Array<ILevelResult>;
  name?: Maybe<Scalars['String']['output']>;
  values: Array<Maybe<Scalars['Float']['output']>>;
};

export enum ILeverageType {
  LongOnly = 'LONG_ONLY',
  LongShort = 'LONG_SHORT',
}

export type IListAssetFilters = {
  assetIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  assetTypes?: InputMaybe<Array<IAssetType>>;
  baseAssetIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  derivativeTypes?: InputMaybe<Array<IDerivativeType>>;
  exchanges?: InputMaybe<Array<Scalars['LabelType']['input']>>;
  expirationSince?: InputMaybe<Scalars['ArrowType']['input']>;
};

export type IListDerivativeAssetFilters = {
  derivativeTypes?: InputMaybe<Array<IDerivativeType>>;
  exchanges?: InputMaybe<Array<Scalars['LabelType']['input']>>;
  expirationSince?: InputMaybe<Scalars['ArrowType']['input']>;
};

export type ILogicAssetResult = {
  __typename?: 'LogicAssetResult';
  asset: IAsset;
  meanEquityChange: Scalars['Float']['output'];
  meanReturn: Scalars['Float']['output'];
  stdReturn: Scalars['Float']['output'];
  unitUsdValue: Scalars['Float']['output'];
};

export type ILogicResult = {
  __typename?: 'LogicResult';
  assetResults: Array<ILogicAssetResult>;
  portfolioReturns: Array<Scalars['Float']['output']>;
  portfolioStatistics: Array<IPortfolioStatistic>;
};

export type IManagement = {
  __typename?: 'Management';
  features: Array<IOrganizationFeature>;
  isExternalUser: Scalars['Boolean']['output'];
  userSettings?: Maybe<Scalars['JSON']['output']>;
  users: Array<IUser>;
  widgets: Array<IWidget>;
};

export type IManagementUserSettingsArgs = {
  field?: InputMaybe<Scalars['String']['input']>;
};

export type IManagementWidgetsArgs = {
  page: Scalars['String']['input'];
};

export type IMarketModel = {
  __typename?: 'MarketModel';
  benchmark?: Maybe<IAsset>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
};

export type IMessage = {
  __typename?: 'Message';
  agentId?: Maybe<Scalars['UUID']['output']>;
  agentResult?: Maybe<IAgentResult>;
  content: Scalars['String']['output'];
  createdAt: Scalars['ArrowType']['output'];
  id: Scalars['UUID']['output'];
  reaction?: Maybe<IMessageReaction>;
  role: IMessageRoleType;
  userId?: Maybe<Scalars['Int']['output']>;
};

export type IMessageInput = {
  agentId: Scalars['UUID']['input'];
  content: Scalars['String']['input'];
  conversationId: Scalars['UUID']['input'];
};

export type IMessageReaction = {
  __typename?: 'MessageReaction';
  reaction: IReactionType;
  updatedAt: Scalars['ArrowType']['output'];
};

export type IMessageReactionInput = {
  messageId: Scalars['UUID']['input'];
  reaction?: InputMaybe<IReactionType>;
};

export enum IMessageRoleType {
  Assistant = 'ASSISTANT',
  User = 'USER',
}

export type IMetric = {
  __typename?: 'Metric';
  id: Scalars['Int']['output'];
  label: Scalars['LabelType']['output'];
};

export type IMetricCalculatorInput = {
  label: Scalars['LabelType']['input'];
  parameters: Array<IMetricCalculatorParameterInput>;
};

export type IMetricCalculatorParameterInput = {
  intValues?: InputMaybe<Array<Scalars['Int']['input']>>;
  name: Scalars['String']['input'];
  strValues?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum IMetricFormat {
  Abs = 'ABS',
  Percentage = 'PERCENTAGE',
}

export type IMinMaxFilter = {
  max: Scalars['Decimal']['input'];
  min: Scalars['Decimal']['input'];
};

export type IMinMaxMetric = {
  __typename?: 'MinMaxMetric';
  max: Scalars['Decimal']['output'];
  min: Scalars['Decimal']['output'];
};

export type IMinMaxMetrics = {
  __typename?: 'MinMaxMetrics';
  liquidity?: Maybe<IMinMaxMetric>;
  marketCap?: Maybe<IMinMaxMetric>;
  totalValueLocked?: Maybe<IMinMaxMetric>;
};

export type IMinMaxValue = {
  __typename?: 'MinMaxValue';
  default: Scalars['Int']['output'];
  max: Scalars['Int']['output'];
  min: Scalars['Int']['output'];
};

export type IModelPrediction = {
  __typename?: 'ModelPrediction';
  model: IMarketModel;
  timeseries: Array<ITimeSeriesValue>;
};

export type IMultiAssetNaiveAllocationType = {
  __typename?: 'MultiAssetNaiveAllocationType';
  impactedApy: Scalars['Float']['output'];
  pools: Array<IPoolInfo>;
  yieldUsd: Scalars['Float']['output'];
};

export type IMultiAssetYieldAnalysisType = {
  __typename?: 'MultiAssetYieldAnalysisType';
  createdAt: Scalars['ArrowType']['output'];
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
  optimizationId: Scalars['Int']['output'];
  optimizationMode: IOptimizationMode;
  output?: Maybe<IMultiAssetYieldOptimizationOutputType>;
  status: IPortfolioOptimizationResultStatusType;
  updatedAt?: Maybe<Scalars['ArrowType']['output']>;
};

export type IMultiAssetYieldOptimizationInput = {
  amounts: Array<IAssetAmountInput>;
  description: Scalars['String']['input'];
  groupPoolsByUnderlying?: Scalars['Boolean']['input'];
  lookback?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  optimizationDate?: InputMaybe<Scalars['Date']['input']>;
  optimizationMode: IOptimizationMode;
  poolPreferences: Array<IYieldPoolInput>;
  portfolioConstraints?: InputMaybe<IYieldPortfolioConstraintsInput>;
};

export type IMultiAssetYieldOptimizationInputType = {
  __typename?: 'MultiAssetYieldOptimizationInputType';
  amounts: Array<IAssetAmountInputType>;
  description: Scalars['String']['output'];
  lookback?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  optimizationDate?: Maybe<Scalars['Date']['output']>;
  optimizationMode: IOptimizationMode;
  pools: Array<IYieldPoolType>;
  portfolioConstraints?: Maybe<IYieldPortfolioConstraintsType>;
};

export type IMultiAssetYieldOptimizationOutputType = {
  __typename?: 'MultiAssetYieldOptimizationOutputType';
  allocations: Array<IExtendedAllocationType>;
  fitWarnings: Array<IFitWarningType>;
  globalApy: Scalars['Float']['output'];
  groupPoolsByUnderlying: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  naiveAllocationApys: Array<IMultiAssetNaiveAllocationType>;
  yieldInUsd: Scalars['Float']['output'];
};

export type IMultiFactor = {
  __typename?: 'MultiFactor';
  createdAt: Scalars['ArrowType']['output'];
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['ArrowType']['output']>;
};

export type IMultiFactorDetails = {
  __typename?: 'MultiFactorDetails';
  factorWeights: Array<IMultiFactorWeight>;
  maxFactors: Scalars['Int']['output'];
  multifactor: IMultiFactor;
};

export type IMultiFactorQueryType = {
  __typename?: 'MultiFactorQueryType';
  /** Compute bias from multifactor */
  computeBiasFromMultifactor: IBiasFromMultiFactorOutput;
  /** Compute multifactor scores */
  computeMultifactorScores: IAssetScreenerOutput;
  /** Get all multifactors for user */
  getAllMultifactorsForUser: Array<IMultiFactorDetails>;
  /** Get all factors */
  getAllSupportedFactors: Array<IFactor>;
  /** Get multifactor details */
  getMultifactorDetails: IMultiFactorDetails;
};

export type IMultiFactorQueryTypeComputeBiasFromMultifactorArgs = {
  input: IBiasFromMultiFactorInput;
};

export type IMultiFactorQueryTypeComputeMultifactorScoresArgs = {
  input: IAssetScreenerInput;
};

export type IMultiFactorQueryTypeGetMultifactorDetailsArgs = {
  multifactorId: Scalars['Int']['input'];
};

export type IMultiFactorWeight = {
  __typename?: 'MultiFactorWeight';
  betterIfLower: Scalars['Boolean']['output'];
  metricId: Scalars['Int']['output'];
  weight: Scalars['Decimal']['output'];
};

export type IMultiFactorWeightInput = {
  betterIfLower: Scalars['Boolean']['input'];
  metricId: Scalars['Int']['input'];
  weight: Scalars['Decimal']['input'];
};

export enum IMultiLevelAttributionType {
  BottomUp = 'BOTTOM_UP',
}

export type IMultiLevelPerformanceClusterAttributionInput = {
  attributionType: IMultiLevelAttributionType;
  benchmarkId?: InputMaybe<Scalars['UUID']['input']>;
  clusterType: Scalars['String']['input'];
  dateRange?: InputMaybe<IDateRangeFilter>;
  returnsType: IReturnsType;
  subAccountAssetFilter?: InputMaybe<ISubAccountAssetFilterInputType>;
};

export type IMultifactorScores = {
  __typename?: 'MultifactorScores';
  assetScores: Array<IAssetScore>;
  multifactor: IMultiFactorDetails;
};

export type IMultilevelAttributionResult = {
  __typename?: 'MultilevelAttributionResult';
  columns: Array<Scalars['String']['output']>;
  data: ILevelResult;
  levelNames: Array<Scalars['String']['output']>;
};

export type IMutation = {
  __typename?: 'Mutation';
  /** Add new article view. */
  AddArticleView?: Maybe<Scalars['Void']['output']>;
  /** Add request to add asset given a  coingecko id */
  AddAssetFromCoingeckoId?: Maybe<Scalars['Void']['output']>;
  /** Blacklist an asset */
  BlacklistAsset?: Maybe<Scalars['Void']['output']>;
  ConfirmNotificationDelivery?: Maybe<Scalars['Void']['output']>;
  ContinueOptimization?: Maybe<Scalars['Void']['output']>;
  /** Create a new account of the current user */
  CreateAccount: IAccount;
  /** Create a new asset group for the authenticated user */
  CreateAssetGroup: IAssetGroup;
  /** Add a calendar event */
  CreateCalendarEvent: ICalendarEvent;
  CreateInvestment: IInvestment;
  /** Create a new private asset */
  CreatePrivateAsset: IAsset;
  /** Create a new sub fund for the authenticated user */
  CreateSubFund: ISubFund;
  /** Book a new transaction */
  CreateTransaction: ITransaction;
  CreateTransactionV2: ITransactionV2;
  /** Remove the account from the portfolio of the current user */
  DeleteAccount?: Maybe<Scalars['Void']['output']>;
  /** Delete an asset group for the authenticated user */
  DeleteAssetGroup?: Maybe<Scalars['Void']['output']>;
  /** Delete asset price from the database */
  DeleteAssetPrice?: Maybe<Scalars['Void']['output']>;
  /** Delete a calendar event */
  DeleteCalendarEvent?: Maybe<Scalars['Void']['output']>;
  /** Remove all asset groups for a cluster */
  DeleteClusterAssetGroups?: Maybe<Scalars['Void']['output']>;
  /** Delete conversation. */
  DeleteConversation?: Maybe<Scalars['Void']['output']>;
  /** Remove all sub-funds for a dimension */
  DeleteDimensionSubFunds?: Maybe<Scalars['Void']['output']>;
  /** Delete an investment */
  DeleteInvestment?: Maybe<Scalars['Void']['output']>;
  /** Delete a private asset */
  DeletePrivateAsset?: Maybe<Scalars['Void']['output']>;
  /** Delete a sub fund for the authenticated user */
  DeleteSubFund?: Maybe<Scalars['Void']['output']>;
  /** Delete a transaction */
  DeleteTransaction?: Maybe<Scalars['Void']['output']>;
  /** Delete a transaction */
  DeleteTransactionV2?: Maybe<Scalars['Void']['output']>;
  DownloadOrders: IDownloadResponse;
  DownloadTransactionsV2: IDownloadResponse;
  /** Deletes existing user settings and saves new settings */
  InsertUserSettings?: Maybe<Scalars['Void']['output']>;
  MarkNotificationsAsRead?: Maybe<Scalars['Void']['output']>;
  /** Rename a cluster for all user's asset groups */
  RenameCluster?: Maybe<Scalars['Void']['output']>;
  /** Rename a dimension for all user's sub funds */
  RenameDimension?: Maybe<Scalars['Void']['output']>;
  /** Request the computation of an incremental snapshot for all the sub_accounts in the portfolio that are not currently being synchronized. Return the ids of the sub_accounts for which the computation was requested */
  RequestPortfolioSnapshot: Array<Scalars['UUID']['output']>;
  /** Request the computation of an incremental snapshot for the selected sub-account */
  RequestSubAccountSnapshot?: Maybe<Scalars['Void']['output']>;
  /**
   * Send a new message.
   * This mutation creates a new agent's Message (with AgentResult).
   * The new message is processed in the background.
   * Please use agent.message to retrieve actual state of the message.
   */
  SendMessage: ISendMessageResult;
  SubmitOptimization: ISubmitOptimizationOutput;
  /** Submit Post Trade Analytics */
  SubmitPostTradeAnalytics?: Maybe<Scalars['Void']['output']>;
  /** Submit Unified PnL Analysis */
  SubmitUnifiedPnLAnalysis: Scalars['Int']['output'];
  /** Unblacklist an asset */
  UnblacklistAsset?: Maybe<Scalars['Void']['output']>;
  /** Update account name */
  UpdateAccount: IAccount;
  /** Update Account api keys */
  UpdateAccountApiKeys: IAccount;
  /** Create or delete an article bookmark. */
  UpdateArticleBookmark?: Maybe<Scalars['Void']['output']>;
  /** Update an asset group for the authenticated user */
  UpdateAssetGroup: IAssetGroup;
  /** Create or update asset price in the database */
  UpdateAssetPrice?: Maybe<Scalars['Void']['output']>;
  /** Create or update asset prices in bulk */
  UpdateAssetPriceBulk?: Maybe<Scalars['Void']['output']>;
  /** Update bullet point reaction. */
  UpdateBulletPointReaction?: Maybe<Scalars['Void']['output']>;
  /** Update conversation (update its title). */
  UpdateConversation?: Maybe<Scalars['Void']['output']>;
  /** Update message reaction. */
  UpdateMessageReaction?: Maybe<Scalars['Void']['output']>;
  /** Update status of a notification */
  UpdateNotificationStatus?: Maybe<Scalars['Void']['output']>;
  /** Update organization inception date */
  UpdateOrganization?: Maybe<Scalars['Void']['output']>;
  /** Update a private asset */
  UpdatePrivateAsset: IAsset;
  /** Update/Add sub-account snapshot position for a given asset, setting position to 0 is equivalent of deletion */
  UpdateSnapshotPosition?: Maybe<Scalars['Void']['output']>;
  /** Update a sub fund for the authenticated user */
  UpdateSubFund: ISubFund;
  UpdateSubscriptions?: Maybe<Scalars['Void']['output']>;
  /** Update an existing transaction */
  UpdateTransaction: ITransaction;
  /** Update an existing transaction */
  UpdateTransactionV2: ITransactionV2;
  /** Saves user settings. Overwrites existing keys */
  UpdateUserSettings?: Maybe<Scalars['Void']['output']>;
  /** Create a presigned URL for uploading a file to the storage service */
  createPresignedUrl: IPresignedUrl;
  createUploadLink: IUploadLink;
  createWidget?: Maybe<Scalars['Void']['output']>;
  /** Delete a multifactor */
  deleteMultifactor: Scalars['String']['output'];
  /** Deletes unified pnl analysis */
  deleteUnifiedPnlAnalysis?: Maybe<Scalars['Void']['output']>;
  deleteWidget?: Maybe<Scalars['Void']['output']>;
  portfolio: IPortfolioMutation;
  /** Save an edited multifactor */
  saveEditedMultifactor: IMultiFactorDetails;
  /** Save a new multifactor */
  saveNewMultifactor: IMultiFactorDetails;
  submitFundOptimization: ISubmitOptimizationOutput;
  submitMultiAssetYieldOptimization: ISubmitOptimizationOutput;
  updateJournalPreferences?: Maybe<Scalars['Void']['output']>;
  updateLayout?: Maybe<Scalars['Void']['output']>;
  updateWidget?: Maybe<Scalars['Void']['output']>;
  /** Upload transactions csv file from csv file in s3 bucket */
  uploadTransactions: IUploadResult;
  /** Validate transactions csv file from csv file in s3 bucket */
  validateTransactionsUpload: IUploadResult;
};

export type IMutationAddArticleViewArgs = {
  articleInput: IArticleInput;
};

export type IMutationAddAssetFromCoingeckoIdArgs = {
  input: IAddAssetFromCoingeckoIdRequestInputType;
};

export type IMutationBlacklistAssetArgs = {
  id: Scalars['UUID']['input'];
};

export type IMutationConfirmNotificationDeliveryArgs = {
  acknowledgedPlacedAt: Scalars['ArrowType']['input'];
};

export type IMutationContinueOptimizationArgs = {
  optimizationId: Scalars['Int']['input'];
};

export type IMutationCreateAccountArgs = {
  input: ICreateAccountInput;
};

export type IMutationCreateAssetGroupArgs = {
  input: ICreateAssetGroupInput;
};

export type IMutationCreateCalendarEventArgs = {
  input: ICalendarEventInput;
};

export type IMutationCreateInvestmentArgs = {
  input: IInvestmentInput;
};

export type IMutationCreatePrivateAssetArgs = {
  input: ICreatePrivateAssetInput;
};

export type IMutationCreateSubFundArgs = {
  input: ICreateSubFund;
};

export type IMutationCreateTransactionArgs = {
  input: ITransactionInput;
};

export type IMutationCreateTransactionV2Args = {
  input: ICreateTransactionV2InputType;
};

export type IMutationDeleteAccountArgs = {
  id: Scalars['UUID']['input'];
};

export type IMutationDeleteAssetGroupArgs = {
  input: IDeleteAssetGroupInput;
};

export type IMutationDeleteAssetPriceArgs = {
  input: IAssetPriceIdInput;
};

export type IMutationDeleteCalendarEventArgs = {
  id: Scalars['UUID']['input'];
};

export type IMutationDeleteClusterAssetGroupsArgs = {
  clusterName: Scalars['String']['input'];
};

export type IMutationDeleteConversationArgs = {
  conversationId: Scalars['UUID']['input'];
};

export type IMutationDeleteDimensionSubFundsArgs = {
  dimension: Scalars['String']['input'];
};

export type IMutationDeleteInvestmentArgs = {
  id: Scalars['UUID']['input'];
};

export type IMutationDeletePrivateAssetArgs = {
  id: Scalars['UUID']['input'];
};

export type IMutationDeleteSubFundArgs = {
  input: IDeleteSubFundInput;
};

export type IMutationDeleteTransactionArgs = {
  id: Scalars['UUID']['input'];
};

export type IMutationDeleteTransactionV2Args = {
  id: Scalars['UUID']['input'];
};

export type IMutationDownloadTransactionsV2Args = {
  filters?: InputMaybe<ITransactionFilters>;
  orderAscendingByTime?: Scalars['Boolean']['input'];
};

export type IMutationInsertUserSettingsArgs = {
  settings: Scalars['JSON']['input'];
};

export type IMutationMarkNotificationsAsReadArgs = {
  acknowledgedPlacedAt: Scalars['ArrowType']['input'];
};

export type IMutationRenameClusterArgs = {
  input: IRenameClusterInput;
};

export type IMutationRenameDimensionArgs = {
  input: IRenameDimensionInput;
};

export type IMutationRequestSubAccountSnapshotArgs = {
  id: Scalars['UUID']['input'];
};

export type IMutationSendMessageArgs = {
  messageInput: IMessageInput;
};

export type IMutationSubmitOptimizationArgs = {
  input: IPortfolioOptimizationInput;
};

export type IMutationSubmitPostTradeAnalyticsArgs = {
  accountId: Scalars['UUID']['input'];
};

export type IMutationSubmitUnifiedPnLAnalysisArgs = {
  assetIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  since: Scalars['Date']['input'];
  subFundDimension?: InputMaybe<Scalars['String']['input']>;
  until: Scalars['Date']['input'];
};

export type IMutationUnblacklistAssetArgs = {
  id: Scalars['UUID']['input'];
};

export type IMutationUpdateAccountArgs = {
  input: IUpdateNameInput;
};

export type IMutationUpdateAccountApiKeysArgs = {
  input: IUpdateApiKeysInput;
};

export type IMutationUpdateArticleBookmarkArgs = {
  articleBookmarkInput: IArticleBookmarkInput;
};

export type IMutationUpdateAssetGroupArgs = {
  input: IUpdateAssetGroupInput;
};

export type IMutationUpdateAssetPriceArgs = {
  input: IAssetPriceInput;
};

export type IMutationUpdateAssetPriceBulkArgs = {
  input: IAssetPriceBulkInput;
};

export type IMutationUpdateBulletPointReactionArgs = {
  bulletPointReactionInput: IBulletPointReactionInput;
};

export type IMutationUpdateConversationArgs = {
  conversationInput: IConversationInput;
};

export type IMutationUpdateMessageReactionArgs = {
  messageReactionInput: IMessageReactionInput;
};

export type IMutationUpdateNotificationStatusArgs = {
  requestId: Scalars['UUID']['input'];
  targetStatus: INotificationStatus;
};

export type IMutationUpdateOrganizationArgs = {
  input: IUpdateOrganizationInput;
};

export type IMutationUpdatePrivateAssetArgs = {
  input: IUpdatePrivateAssetInput;
};

export type IMutationUpdateSnapshotPositionArgs = {
  updateInput: IUpdatePositionInput;
};

export type IMutationUpdateSubFundArgs = {
  input: IUpdateSubFundInput;
};

export type IMutationUpdateSubscriptionsArgs = {
  categories: Array<Scalars['String']['input']>;
};

export type IMutationUpdateTransactionArgs = {
  input: IUpdateTransactionInput;
};

export type IMutationUpdateTransactionV2Args = {
  input: IUpdateTransactionV2InputType;
};

export type IMutationUpdateUserSettingsArgs = {
  settings: Scalars['JSON']['input'];
};

export type IMutationCreatePresignedUrlArgs = {
  contentType: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
};

export type IMutationCreateUploadLinkArgs = {
  contentType: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
};

export type IMutationCreateWidgetArgs = {
  input: ICreateWidgetInput;
};

export type IMutationDeleteMultifactorArgs = {
  multifactorId: Scalars['Int']['input'];
};

export type IMutationDeleteUnifiedPnlAnalysisArgs = {
  analysisId: Scalars['Int']['input'];
};

export type IMutationDeleteWidgetArgs = {
  page: Scalars['String']['input'];
  widgetId: Scalars['String']['input'];
};

export type IMutationSaveEditedMultifactorArgs = {
  input: IPartiallyDefinedMultiFactorInput;
  multifactorId: Scalars['Int']['input'];
};

export type IMutationSaveNewMultifactorArgs = {
  input: IPartiallyDefinedMultiFactorInput;
};

export type IMutationSubmitFundOptimizationArgs = {
  input: IFundOptimizationInput;
};

export type IMutationSubmitMultiAssetYieldOptimizationArgs = {
  input: IMultiAssetYieldOptimizationInput;
};

export type IMutationUpdateJournalPreferencesArgs = {
  dimension?: InputMaybe<Scalars['String']['input']>;
};

export type IMutationUpdateLayoutArgs = {
  input: Array<IUpdateLayoutInput>;
};

export type IMutationUpdateWidgetArgs = {
  input: IUpdateWidgetInput;
};

export type IMutationUploadTransactionsArgs = {
  validationUploadId: Scalars['UUID']['input'];
};

export type IMutationValidateTransactionsUploadArgs = {
  extraTag?: InputMaybe<Scalars['String']['input']>;
  s3FileName: Scalars['String']['input'];
  subAccountId: Scalars['UUID']['input'];
};

export type INavigatorGraph = {
  __typename?: 'NavigatorGraph';
  /** Get graph elements and links. */
  graph: IGraph;
};

export type INavigatorGraphGraphArgs = {
  filters?: InputMaybe<IGraphGlobalFilters>;
};

export type INewsFeed = {
  __typename?: 'NewsFeed';
  humanName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  url: Scalars['String']['output'];
};

export type INewsType = {
  __typename?: 'NewsType';
  agents: IAgents;
  articles: IArticles;
  bulletPoints: IAssetBulletPoints;
  navigatorGraph: INavigatorGraph;
  /** Get supported assets. */
  supportedAssets: Array<IAsset>;
  /** Get supported news feeds. */
  supportedNewsFeeds: Array<INewsFeed>;
  /** Get supported time range. */
  supportedTimeRange: IArticlePublishedAtTimeRange;
  /** Get all available topics. */
  supportedTopics: Array<ITopic>;
};

export type INotification = {
  __typename?: 'Notification';
  list: IUserNotificationOffsetLimitPage;
  subscription: Array<IUserSubscription>;
};

export type INotificationListArgs = {
  paging: IPageLimitOffsetInput;
  status?: InputMaybe<INotificationStatus>;
};

export enum INotificationStatus {
  Delivered = 'DELIVERED',
  NotDelivered = 'NOT_DELIVERED',
  Read = 'READ',
}

export type INumericFilterConditionInput = {
  filter?: InputMaybe<Scalars['Float']['input']>;
  filterTo?: InputMaybe<Scalars['Float']['input']>;
  type: INumericFilterConditionOperator;
};

export enum INumericFilterConditionOperator {
  Blank = 'BLANK',
  Eq = 'EQ',
  Gt = 'GT',
  Gte = 'GTE',
  InRange = 'IN_RANGE',
  Lt = 'LT',
  Lte = 'LTE',
  Neq = 'NEQ',
  NotBlank = 'NOT_BLANK',
}

export enum IObjectiveType {
  MaxRiskAdjustedReturns = 'MAX_RISK_ADJUSTED_RETURNS',
  MinRisk = 'MIN_RISK',
  RiskParity = 'RISK_PARITY',
}

export type IOnboardingInput = {
  assetIds?: Array<Scalars['UUID']['input']>;
  date?: InputMaybe<Scalars['ArrowType']['input']>;
  extra?: InputMaybe<Scalars['JSON']['input']>;
  historicalReconstruction?: Scalars['Boolean']['input'];
};

export enum IOptimizationMode {
  Aggressive = 'AGGRESSIVE',
  Conservative = 'CONSERVATIVE',
}

export type IOptimizationObjectiveInput = {
  objectiveType: IObjectiveType;
  returnMeasureName?: InputMaybe<IReturnMeasureName>;
  riskMetric: IRiskMetricInput;
};

export enum IOptimizationResultStatus {
  MaxIterReached = 'MAX_ITER_REACHED',
  Other = 'OTHER',
  Success = 'SUCCESS',
  UnfeasibleEquality = 'UNFEASIBLE_EQUALITY',
}

export type IOptionPosition = {
  amount: Scalars['Float']['input'];
  assetId: Scalars['UUID']['input'];
  premiumPerContract?: InputMaybe<Scalars['Float']['input']>;
  side: IPositionSide;
};

export type IOptionPositionInputType = {
  amount: Scalars['Float']['input'];
  assetId: Scalars['UUID']['input'];
  premiumPerContract?: Scalars['Float']['input'];
  side: IPositionSide;
};

export enum IOptionType {
  Call = 'CALL',
  Put = 'PUT',
}

export type IOptionValue = {
  __typename?: 'OptionValue';
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type IOptions = {
  __typename?: 'Options';
  /** Get exchanges where options are listed on. */
  exchanges: Array<IDataSource>;
  /** Get option contracts with prices. */
  pricedOptions: Array<IPricedOption>;
};

export type IOptionsExchangesArgs = {
  baseAssetIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  expirationSince?: InputMaybe<Scalars['ArrowType']['input']>;
};

export type IOptionsPricedOptionsArgs = {
  baseAssetId: Scalars['UUID']['input'];
  exchanges?: InputMaybe<Array<Scalars['LabelType']['input']>>;
  expirationSince?: InputMaybe<Scalars['ArrowType']['input']>;
};

export type IOptionsAnalysis = {
  __typename?: 'OptionsAnalysis';
  /** Evaluate a portfolio of options */
  portfolioEvaluation: IPortfolioValue;
  /** Perform scenario analysis */
  scenarioAnalysis: IScenarioAnalysisResult;
};

export type IOptionsAnalysisPortfolioEvaluationArgs = {
  positions: Array<IOptionPositionInputType>;
};

export type IOptionsAnalysisScenarioAnalysisArgs = {
  input: IScenarioAnalysisInputType;
};

export type IOrder = {
  __typename?: 'Order';
  amount?: Maybe<Scalars['Decimal']['output']>;
  average?: Maybe<Scalars['Decimal']['output']>;
  baseAsset?: Maybe<IAsset>;
  creationTime?: Maybe<Scalars['ArrowType']['output']>;
  externalId: Scalars['String']['output'];
  filled?: Maybe<Scalars['Decimal']['output']>;
  lastUpdateTime?: Maybe<Scalars['ArrowType']['output']>;
  price?: Maybe<Scalars['Decimal']['output']>;
  quoteAsset?: Maybe<IAsset>;
  side: IOrderSide;
  status?: Maybe<IOrderStatus>;
  subAccount: ISubAccount;
  timeInForce?: Maybe<IOrderTimeInForce>;
  type: IOrderType;
};

export type IOrderDetails = {
  __typename?: 'OrderDetails';
  completed?: Maybe<Scalars['Decimal']['output']>;
  value: Scalars['Decimal']['output'];
};

export type IOrderFilters = {
  assetIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  createdAt?: InputMaybe<ITimeRangeFilterV2>;
  orderSide?: InputMaybe<Array<IOrderSide>>;
  orderStatus?: InputMaybe<Array<IOrderStatus>>;
  subAccountIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type IOrderInput = {
  side?: InputMaybe<IOrderSide>;
  type?: InputMaybe<IOrderType>;
};

export type IOrderOffsetLimitPage = {
  __typename?: 'OrderOffsetLimitPage';
  data: Array<IOrder>;
  pageInfo: IPageOffsetInfo;
};

export enum IOrderSide {
  Buy = 'BUY',
  Sell = 'SELL',
}

export enum IOrderStatus {
  Canceled = 'CANCELED',
  Closed = 'CLOSED',
  Expired = 'EXPIRED',
  Open = 'OPEN',
  Rejected = 'REJECTED',
  Unknown = 'UNKNOWN',
}

export enum IOrderTimeInForce {
  FillOrKill = 'FILL_OR_KILL',
  GoodTillCancel = 'GOOD_TILL_CANCEL',
  ImmediateOrCancel = 'IMMEDIATE_OR_CANCEL',
  PostOnly = 'POST_ONLY',
  Unknown = 'UNKNOWN',
}

export enum IOrderType {
  Limit = 'LIMIT',
  Market = 'MARKET',
  RequestForQuote = 'REQUEST_FOR_QUOTE',
  TimeWeightedAveragePrice = 'TIME_WEIGHTED_AVERAGE_PRICE',
  Unknown = 'UNKNOWN',
  VolumeWeightedAveragePrice = 'VOLUME_WEIGHTED_AVERAGE_PRICE',
}

export type IOrderV2InputType = {
  externalId: Scalars['String']['input'];
  side: IOrderSide;
  type?: InputMaybe<IOrderType>;
};

export type IOrganization = {
  __typename?: 'Organization';
  id: Scalars['Int']['output'];
  inceptionDate?: Maybe<Scalars['ArrowType']['output']>;
  name: Scalars['String']['output'];
  referenceAsset: IAsset;
};

export type IOrganizationFeature = {
  __typename?: 'OrganizationFeature';
  name: Scalars['String']['output'];
  status: IFeatureFlagStatus;
};

export type IPageInfoV1 = {
  __typename?: 'PageInfoV1';
  endCursor?: Maybe<Scalars['Base64']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['Base64']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type IPageLimitOffsetInput = {
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
};

export type IPageOffsetInfo = {
  __typename?: 'PageOffsetInfo';
  totalResults: Scalars['Int']['output'];
};

export type IParameterChange = {
  __typename?: 'ParameterChange';
  price: Scalars['Float']['output'];
  riskFree: Scalars['Float']['output'];
  volatility: Scalars['Float']['output'];
};

export type IParameterDescription = {
  __typename?: 'ParameterDescription';
  benchmarks?: Maybe<Array<IAsset>>;
  id: Scalars['String']['output'];
  minMax?: Maybe<IMinMaxValue>;
  name: Scalars['String']['output'];
  options?: Maybe<Array<IOptionValue>>;
  type: IParameterType;
  /** if not none, parameter is visible only if user selected given option */
  visibleIf?: Maybe<Array<IParameterVisibilityPredicate>>;
};

export type IParameterGridChange = {
  price?: Array<Scalars['Float']['input']>;
  riskFree?: Array<Scalars['Float']['input']>;
  volatility?: Array<Scalars['Float']['input']>;
};

export type IParameterOutput = {
  __typename?: 'ParameterOutput';
  asset?: Maybe<IAsset>;
  intValue?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  strValue?: Maybe<Scalars['String']['output']>;
};

export enum IParameterType {
  Benchmark = 'BENCHMARK',
  Int = 'INT',
  Options = 'OPTIONS',
}

export type IParameterVisibilityPredicate = {
  __typename?: 'ParameterVisibilityPredicate';
  /** parameter id from ParameterDescription */
  parameter: Scalars['String']['output'];
  /** if given option is selected, parameter is visible */
  selectedOption: Scalars['String']['output'];
};

export type IPartiallyDefinedMultiFactorInput = {
  description: Scalars['String']['input'];
  factorWeights: Array<IMultiFactorWeightInput>;
  name: Scalars['String']['input'];
};

export type IPerformanceAssetAttributionInput = {
  dateRange?: InputMaybe<IDateRangeFilter>;
  method?: IContributionMethod;
  subAccountAssetFilter?: InputMaybe<ISubAccountAssetFilterInputType>;
};

export type IPerformanceClusterAttributionDailyInput = {
  clusterType: Scalars['String']['input'];
  dateRange?: InputMaybe<IDateRangeFilter>;
  subAccountAssetFilter?: InputMaybe<ISubAccountAssetFilterInputType>;
};

export type IPerformanceClusterAttributionInput = {
  attributionType: IBrinsonAttributionType;
  benchmarkId?: InputMaybe<Scalars['UUID']['input']>;
  clusterType: Scalars['String']['input'];
  dateRange?: InputMaybe<IDateRangeFilter>;
  subAccountAssetFilter?: InputMaybe<ISubAccountAssetFilterInputType>;
};

export type IPeriodInput = {
  unit: IPeriodUnit;
  value: Scalars['Int']['input'];
};

export type IPeriodReturnStatisticsType = {
  __typename?: 'PeriodReturnStatisticsType';
  cumulativeReturns: IFloatRowValues;
  endDate: Scalars['Date']['output'];
  periodType: IPeriodType;
  qsMetrics: Array<IQsMetricType>;
  startDate: Scalars['Date']['output'];
  statistics: IValuesByCategory;
};

export enum IPeriodType {
  All = 'ALL',
  D_7 = 'D_7',
  D_30 = 'D_30',
  D_90 = 'D_90',
  Mtd = 'MTD',
  Qtd = 'QTD',
  Wtd = 'WTD',
  Ytd = 'YTD',
}

export enum IPeriodUnit {
  Day = 'DAY',
  Month = 'MONTH',
  Quarter = 'QUARTER',
  Week = 'WEEK',
  Year = 'YEAR',
}

export type IPerpPosition = {
  amount: Scalars['Float']['input'];
  assetId: Scalars['UUID']['input'];
  entryPrice: Scalars['Float']['input'];
  side: IPositionSide;
};

export type IPoolFilters = {
  matchChains?: InputMaybe<Array<Scalars['String']['input']>>;
  matchCollateralIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  matchNames?: InputMaybe<Array<Scalars['String']['input']>>;
  matchProtocols?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type IPoolInfo = {
  __typename?: 'PoolInfo';
  category?: Maybe<Scalars['String']['output']>;
  chain: Scalars['String']['output'];
  collateralAsset: IAsset;
  externalLink?: Maybe<Scalars['String']['output']>;
  externalMetadata?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  metrics?: Maybe<IPoolMetrics>;
  poolName: Scalars['String']['output'];
  protocol?: Maybe<IProtocol>;
  underlyingAsset: IAsset;
};

export type IPoolMetrics = {
  __typename?: 'PoolMetrics';
  apy?: Maybe<Scalars['Float']['output']>;
  apyBase?: Maybe<Scalars['Float']['output']>;
  apyBaseBorrow?: Maybe<Scalars['Float']['output']>;
  apyReward?: Maybe<Scalars['Float']['output']>;
  apyRewardBorrow?: Maybe<Scalars['Float']['output']>;
  timestamp: Scalars['ArrowType']['output'];
  totalBorrowUsd?: Maybe<Scalars['Float']['output']>;
  totalSupplyUsd?: Maybe<Scalars['Float']['output']>;
  tvlUsd: Scalars['Float']['output'];
  utilizationRate: Scalars['Float']['output'];
};

export type IPortfolio = {
  __typename?: 'Portfolio';
  accounts: Array<IAccount>;
  analysis: Array<IPortfolioAnalysis>;
  /** Return true if the user can request a portfolio snapshot */
  canRequestPortfolioSnapshot: Scalars['Boolean']['output'];
  changes: IPortfolioChanges;
  definition: IPortfolioDefinition;
  definitions: Array<IPortfolioDefinition>;
  details: IPortfolioDetails;
  imports: Array<IPortfolioImportStatus>;
  journal: IJournal;
  metricContributions: Array<ICalculatorContributionOutput>;
  metricParameters: Array<IPortfolioMetricDescription>;
  metrics: Array<ICalculatorMetricSeriesOutput>;
  multilevelPerformanceAttributions: IMultilevelAttributionResult;
  performanceAssetContributions: IAssetContributionResult;
  performanceClusterAttributions: IClusterAttributionResult;
  performanceClusterAttributionsDaily: IDailyPerformanceByClusterAndSide;
  /** Return the results for different strategies for a given trading period */
  periodResults: Array<IExecutionStrategyResult>;
  /** Return the trading periods for the current user */
  periods: IPositionTradingPeriodOffsetLimitPage;
  portfolioCorrelations: Array<IPortfolioCorrelation>;
  portfolioSummary: Array<ISummaryParagraph>;
  /** Return time-weighted returns for the portfolio */
  portfolioTimeWeightedReturns: IPortfolioTimeWeightedReturnsType;
  /** Return current positions for all the sub-accounts in the portfolio (latest snapshot per sub-account) */
  positions: ISubAccountPositions;
  regimeRisk: Array<IPortfolioRegimeOutput>;
  /** Returns grouped by day positions from snapshots for given date range, with summary for each day */
  snapshot: Array<ISubAccountSnapshot>;
  spotPositionChanges: Array<IPositionChanges>;
  /** Return time-weighted returns for each sub-account */
  subAccountsTimeWeightedReturns: ISubAccountsTimeWeightedReturnsType;
  subFunds: ISubFunds;
  /** Return performance for each sub-fund */
  subFundsTimeWeightedReturns: ISubFundPerformance;
  supportedBenchmarksWithComposition: Array<IPortfolioDefinition>;
  supportedRegimeDefinitions: Array<IRegimeDefinitions>;
  /** Return all available trading metrics */
  tradingMetrics: Array<ITradingMetric>;
  /** Return the results of the Unified PnL Analysis */
  unifiedPnl: Array<IUnifiedPnLAnalysisResult>;
  /** Return the status of the Unified PnL Analysis */
  unifiedPnlStatus: Array<IUnifiedPnLAnalysis>;
};

export type IPortfolioAnalysisArgs = {
  portfolioIds: Array<Scalars['UUID']['input']>;
  since?: InputMaybe<Scalars['Date']['input']>;
  to?: InputMaybe<Scalars['Date']['input']>;
};

export type IPortfolioDefinitionArgs = {
  id: Scalars['UUID']['input'];
};

export type IPortfolioDetailsArgs = {
  filters?: InputMaybe<ISubAccountAssetFilterInputType>;
};

export type IPortfolioImportsArgs = {
  portfolioDefinitionIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  processStatus?: InputMaybe<Array<IProcessStatus>>;
  remoteFileIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type IPortfolioMetricContributionsArgs = {
  input: IPortfolioRiskMetricsContributionInput;
};

export type IPortfolioMetricParametersArgs = {
  subAccountAssetFilter?: InputMaybe<ISubAccountAssetFilterInputType>;
};

export type IPortfolioMetricsArgs = {
  input: IPortfolioRiskMetricsInput;
};

export type IPortfolioMultilevelPerformanceAttributionsArgs = {
  input: IMultiLevelPerformanceClusterAttributionInput;
};

export type IPortfolioPerformanceAssetContributionsArgs = {
  input: IPerformanceAssetAttributionInput;
};

export type IPortfolioPerformanceClusterAttributionsArgs = {
  input: IPerformanceClusterAttributionInput;
};

export type IPortfolioPerformanceClusterAttributionsDailyArgs = {
  input: IPerformanceClusterAttributionDailyInput;
};

export type IPortfolioPeriodResultsArgs = {
  tradingPeriodId: Scalars['UUID']['input'];
};

export type IPortfolioPeriodsArgs = {
  filters?: InputMaybe<IPositionTradingPeriodFilters>;
  paging: IPageLimitOffsetInput;
  sort?: InputMaybe<ISortField>;
};

export type IPortfolioPortfolioCorrelationsArgs = {
  input: IPortfolioCorrelationInputType;
};

export type IPortfolioPortfolioSummaryArgs = {
  input: ISummaryInput;
};

export type IPortfolioPortfolioTimeWeightedReturnsArgs = {
  filters?: InputMaybe<IPortfolioTimeWeightedReturnsFilters>;
};

export type IPortfolioPositionsArgs = {
  filters?: InputMaybe<ISubAccountSnapshotFilters>;
};

export type IPortfolioRegimeRiskArgs = {
  input: IRegimeRiskInput;
};

export type IPortfolioSnapshotArgs = {
  filters?: InputMaybe<ISubAccountSnapshotFilters>;
};

export type IPortfolioSpotPositionChangesArgs = {
  filters?: InputMaybe<ISubAccountAssetFilterInputType>;
};

export type IPortfolioSubAccountsTimeWeightedReturnsArgs = {
  filters?: InputMaybe<IPortfolioTimeWeightedReturnsFilters>;
};

export type IPortfolioSubFundsTimeWeightedReturnsArgs = {
  filters?: InputMaybe<ISubFundsTimeWeightedReturnsFilters>;
};

export type IPortfolioUnifiedPnlArgs = {
  analysisId: Scalars['Int']['input'];
};

export type IPortfolioAllocations = {
  __typename?: 'PortfolioAllocations';
  assetAllocation: Array<IPortfolioAssetAllocation>;
  date: Scalars['Date']['output'];
};

export type IPortfolioAnalysis = {
  __typename?: 'PortfolioAnalysis';
  allocations: Array<IPortfolioAllocations>;
  cashAssetIds: Array<Scalars['UUID']['output']>;
  portfolioDefinition: IPortfolioDefinition;
  returns: Array<IPortfolioReturns>;
};

export enum IPortfolioAnalysisStatusType {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
}

export type IPortfolioAssetAllocation = {
  __typename?: 'PortfolioAssetAllocation';
  asset: IAsset;
  value: Scalars['Float']['output'];
};

export type IPortfolioChanges = {
  __typename?: 'PortfolioChanges';
  openPositionsCount: Scalars['Int']['output'];
};

export type IPortfolioConstraints = {
  __typename?: 'PortfolioConstraints';
  primary?: Maybe<IPrimaryPortfolioConstraintType>;
  secondary?: Maybe<ISecondaryPortfolioConstraint>;
};

export type IPortfolioConstraintsInput = {
  cardinality?: Array<ICardinalityConstraintInput>;
  primary?: InputMaybe<IPrimaryPortfolioConstraintInput>;
  secondary?: InputMaybe<ISecondaryPortfolioConstraintInput>;
};

export type IPortfolioCorrelation = {
  __typename?: 'PortfolioCorrelation';
  correlations: Array<ICorrelationDailyValue>;
  portfolioPair: IPortfolioPair;
};

export type IPortfolioCorrelationInputType = {
  dateRange: IDateRangeFilter;
  portfolios: Array<Scalars['UUID']['input']>;
  windowParams: IWindowParamsType;
};

export type IPortfolioDefinition = {
  __typename?: 'PortfolioDefinition';
  assets: Array<IAsset>;
  createdAt: Scalars['ArrowType']['output'];
  createdFrom?: Maybe<IPortfolioRebalancingRule>;
  dateRange?: Maybe<IPortfolioDefinitionDateRange>;
  description: Scalars['String']['output'];
  genie: Scalars['Boolean']['output'];
  id: Scalars['UUID']['output'];
  latestAssetComposition: Array<IAssetCompositionWithValues>;
  name: Scalars['String']['output'];
  realDefAccounts?: Maybe<Array<IAccount>>;
  realDefSubFunds?: Maybe<Array<ISubFund>>;
  rebalancingRules: Array<IPortfolioRebalancingRule>;
  /**
   * The earliest date for which there is a price for all assets in the portfolio.
   *         (Function is to be called only for a single portfolio and if we need it for multiple portfolios,
   *         it will need to be optimized)
   */
  sinceForRebalancedPortfolio?: Maybe<Scalars['Date']['output']>;
  subType: IPortfolioDefinitionSubType;
  transactionTag?: Maybe<Scalars['String']['output']>;
  type: IPortfolioDefinitionType;
};

export type IPortfolioDefinitionDateRange = {
  __typename?: 'PortfolioDefinitionDateRange';
  since?: Maybe<Scalars['Date']['output']>;
  to?: Maybe<Scalars['Date']['output']>;
};

export type IPortfolioDefinitionInput = {
  equity?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['UUID']['input'];
};

export enum IPortfolioDefinitionSubType {
  AssetRuleBased = 'ASSET_RULE_BASED',
  Default = 'DEFAULT',
  PositionBased = 'POSITION_BASED',
  ReturnBased = 'RETURN_BASED',
  TransactionBased = 'TRANSACTION_BASED',
}

export enum IPortfolioDefinitionType {
  Real = 'REAL',
  Rebalanced = 'REBALANCED',
  Synthetic = 'SYNTHETIC',
}

export type IPortfolioDetails = {
  __typename?: 'PortfolioDetails';
  openPositionsCount: Scalars['Int']['output'];
};

export type IPortfolioEvaluation = {
  __typename?: 'PortfolioEvaluation';
  /** Get portfolio expected risk metrics given a set of assets and their weights */
  portfolioEvaluation: IFineTunerOutput;
};

export type IPortfolioEvaluationPortfolioEvaluationArgs = {
  input: IFineTunerInput;
};

export type IPortfolioEvaluationInputType = {
  benchmark: Scalars['UUID']['input'];
  portfolioDefinitions?: InputMaybe<Array<IPortfolioDefinitionInput>>;
  portfolios?: InputMaybe<Array<IScenAnalysisPortfolioInput>>;
};

export type IPortfolioEvaluationV2 = {
  __typename?: 'PortfolioEvaluationV2';
  externalId?: Maybe<Scalars['UUID']['output']>;
  fairValue: Scalars['Float']['output'];
  greeks: IGreeks;
  marketValue?: Maybe<Scalars['Float']['output']>;
  portfolioDefinition?: Maybe<IPortfolioDefinition>;
};

export type IPortfolioExposure = {
  __typename?: 'PortfolioExposure';
  amount: Scalars['Float']['output'];
  name: IPortfolioExposureStatName;
  percentage: Scalars['Float']['output'];
};

export enum IPortfolioExposureStatName {
  Cash = 'CASH',
  CashShortFuture = 'CASH_SHORT_FUTURE',
  Gross = 'GROSS',
  Long = 'LONG',
  Net = 'NET',
  Short = 'SHORT',
}

export type IPortfolioImportStatus = {
  __typename?: 'PortfolioImportStatus';
  portfolioDefinition: IPortfolioDefinition;
  remoteFile: IFileUpload;
  result?: Maybe<IPortfolioImportStatusProcessResult>;
  status: IProcessStatus;
};

export type IPortfolioImportStatusProcessResult = {
  __typename?: 'PortfolioImportStatusProcessResult';
  errors: Array<IPortfolioImportStatusProcessResultError>;
  processedLines: Scalars['Int']['output'];
  totalErrors: Scalars['Int']['output'];
};

export type IPortfolioImportStatusProcessResultError = {
  __typename?: 'PortfolioImportStatusProcessResultError';
  lineNo: Scalars['Int']['output'];
  message: Scalars['String']['output'];
};

export type IPortfolioInput = {
  assets: Array<IPortfolioPosition>;
  portfolioUsdValue?: Scalars['Float']['input'];
  positionValueType?: IPositionValueType;
};

export type IPortfolioMetricDescription = {
  __typename?: 'PortfolioMetricDescription';
  label: Scalars['LabelType']['output'];
  parameters: Array<IParameterDescription>;
};

export type IPortfolioMutation = {
  __typename?: 'PortfolioMutation';
  createDefinition: Scalars['UUID']['output'];
  createRebalancingRule?: Maybe<Scalars['Void']['output']>;
  createReturnsBasedPortfolio?: Maybe<Scalars['Void']['output']>;
  deleteDefinition?: Maybe<Scalars['Void']['output']>;
  deleteRebalancingRule?: Maybe<Scalars['Void']['output']>;
  importPortfolioFromFile: Scalars['Boolean']['output'];
  recomputePortfolio?: Maybe<Scalars['Void']['output']>;
  updateDefinition?: Maybe<Scalars['Void']['output']>;
};

export type IPortfolioMutationCreateDefinitionArgs = {
  input: ICreatePortfolioDefInput;
};

export type IPortfolioMutationCreateRebalancingRuleArgs = {
  input: ICreateRebalancingRuleInput;
};

export type IPortfolioMutationCreateReturnsBasedPortfolioArgs = {
  rows: Array<ICreateReturnsBasedPortfolioInput>;
};

export type IPortfolioMutationDeleteDefinitionArgs = {
  input: Scalars['UUID']['input'];
};

export type IPortfolioMutationDeleteRebalancingRuleArgs = {
  id: Scalars['UUID']['input'];
};

export type IPortfolioMutationImportPortfolioFromFileArgs = {
  rebalancedPortfolioId: Scalars['UUID']['input'];
  remoteFileId: Scalars['UUID']['input'];
};

export type IPortfolioMutationRecomputePortfolioArgs = {
  id: Scalars['UUID']['input'];
};

export type IPortfolioMutationUpdateDefinitionArgs = {
  input: IUpdatePortfolioDefInput;
};

export type IPortfolioObjectiveValue = {
  __typename?: 'PortfolioObjectiveValue';
  value: Scalars['Float']['output'];
};

export type IPortfolioOptimization = {
  __typename?: 'PortfolioOptimization';
  averageVolume: IAverageVolumeResponse;
  forecast: Array<IForecast>;
  getOptimization: IPortfolioOptimizationResult;
  getOptimizationInput: IPortfolioOptimizationUserInput;
  listOptimizations: Array<IPortfolioOptimizationResult>;
  optimizableAssetsForUser: Array<IAsset>;
};

export type IPortfolioOptimizationAverageVolumeArgs = {
  request: IAverageVolumeRequest;
};

export type IPortfolioOptimizationForecastArgs = {
  input: Array<Scalars['UUID']['input']>;
};

export type IPortfolioOptimizationGetOptimizationArgs = {
  optimizationId: Scalars['Int']['input'];
};

export type IPortfolioOptimizationGetOptimizationInputArgs = {
  optimizationId: Scalars['Int']['input'];
};

export type IPortfolioOptimizationOptimizableAssetsForUserArgs = {
  privateAssetFilter?: InputMaybe<IPrivateAssetFilterInputType>;
};

export type IPortfolioOptimizationInput = {
  assets: Array<IConstrainedAssetInput>;
  constraintFormulation?: IConstraintFormulation;
  description: Scalars['String']['input'];
  futureReturnsHorizon?: InputMaybe<Scalars['Int']['input']>;
  givenPortfolio?: Array<IGivenPortfolioAssetInput>;
  groupConstraints?: Array<IGroupConstraintInput>;
  leverageType?: ILeverageType;
  lookback?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  objectives: Array<IOptimizationObjectiveInput>;
  optimizationDate?: InputMaybe<Scalars['Date']['input']>;
  portfolioAmount: Scalars['Float']['input'];
  portfolioConstraints?: InputMaybe<IPortfolioConstraintsInput>;
  privateAssetFilter?: InputMaybe<IPrivateAssetFilterInputType>;
  returnMeasureName?: InputMaybe<IReturnMeasureNameUi>;
};

export type IPortfolioOptimizationObjective = {
  __typename?: 'PortfolioOptimizationObjective';
  benchmark?: Maybe<IAsset>;
  objectiveType: IObjectiveType;
  risk: Scalars['LabelType']['output'];
  userRequested: Scalars['Boolean']['output'];
};

export type IPortfolioOptimizationResult = {
  __typename?: 'PortfolioOptimizationResult';
  createdAt: Scalars['ArrowType']['output'];
  description: Scalars['String']['output'];
  givenPortfolioOutput?: Maybe<IPortfolioOutput>;
  name: Scalars['String']['output'];
  objectives: Array<IPortfolioOptimizationObjective>;
  optimizationId: Scalars['Int']['output'];
  portfolioOutput: Array<IPortfolioOutput>;
  status: IPortfolioOptimizationResultStatusType;
  updatedAt?: Maybe<Scalars['ArrowType']['output']>;
};

export enum IPortfolioOptimizationResultStatusType {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
}

export type IPortfolioOptimizationUserInput = {
  __typename?: 'PortfolioOptimizationUserInput';
  assetConstraints: Array<IConstrainedAsset>;
  constraintFormulation: IConstraintFormulation;
  givenPortfolio: Array<IGivenPortfolioAsset>;
  groupConstraints: Array<IGroupConstraint>;
  leverageType: ILeverageType;
  portfolioAmount: Scalars['Float']['output'];
  portfolioConstraints?: Maybe<IPortfolioConstraints>;
  privateAssetFilter?: Maybe<IPrivateAssetFilterType>;
  returnMeasureName?: Maybe<IReturnMeasureNameUi>;
};

export type IPortfolioOutput = {
  __typename?: 'PortfolioOutput';
  assetDeltas: Array<IAssetPositionDelta>;
  assetsMeasures: Array<IAssetMeasure>;
  groupExposures: Array<IGroupExposure>;
  nonDominated: Scalars['Boolean']['output'];
  objectiveValues: Array<IPortfolioObjectiveValue>;
  portfolioExposures: Array<IPortfolioExposure>;
  returnsPredictions: IReturnsPredictions;
  riskMeasures: Array<IAssetRiskMeasureType>;
  solutionId: Scalars['Int']['output'];
  status?: Maybe<IOptimizationResultStatus>;
  statusMessage?: Maybe<Scalars['String']['output']>;
  suggested: Scalars['Boolean']['output'];
};

export type IPortfolioPair = {
  __typename?: 'PortfolioPair';
  portfolioA: IPortfolioDefinition;
  portfolioB: IPortfolioDefinition;
};

export type IPortfolioPosition = {
  asset: Scalars['LabelType']['input'];
  value: Scalars['Float']['input'];
};

export type IPortfolioRebalancingRule = {
  __typename?: 'PortfolioRebalancingRule';
  end?: Maybe<Scalars['Date']['output']>;
  id: Scalars['UUID']['output'];
  maxRebalancingCost?: Maybe<Scalars['Decimal']['output']>;
  name: Scalars['String']['output'];
  periodUnit?: Maybe<IPeriodUnit>;
  periodValue?: Maybe<Scalars['Int']['output']>;
  portfolioDefinition: IPortfolioDefinition;
  start: Scalars['Date']['output'];
  tradingFee: Scalars['Decimal']['output'];
};

export type IPortfolioRegimeOutput = {
  __typename?: 'PortfolioRegimeOutput';
  data: Array<IRegimeData>;
  portfolio: IPortfolioDefinition;
};

export type IPortfolioReturns = {
  __typename?: 'PortfolioReturns';
  date: Scalars['Date']['output'];
  portfolioWeightedReturn: Scalars['Float']['output'];
};

export type IPortfolioRiskMetricsContributionInput = {
  date: Scalars['Date']['input'];
  metrics: Array<IMetricCalculatorInput>;
  portfolioDefinitionIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  privateAssetFilter?: InputMaybe<IPrivateAssetFilterInput>;
  subAccountAssetFilter?: InputMaybe<ISubAccountAssetFilterInputType>;
};

export type IPortfolioRiskMetricsInput = {
  metrics: Array<IMetricCalculatorInput>;
  portfolioDefinitionIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  privateAssetFilterInput?: InputMaybe<IPrivateAssetFilterInput>;
  since?: InputMaybe<Scalars['Date']['input']>;
  subAccountAssetFilter?: InputMaybe<ISubAccountAssetFilterInputType>;
  to?: InputMaybe<Scalars['Date']['input']>;
};

export type IPortfolioStatistic = {
  __typename?: 'PortfolioStatistic';
  name: IPortfolioStatisticName;
  value: Scalars['Float']['output'];
};

export enum IPortfolioStatisticName {
  AverageGain = 'AVERAGE_GAIN',
  AverageLoss = 'AVERAGE_LOSS',
  GainProbability = 'GAIN_PROBABILITY',
  LossProbability = 'LOSS_PROBABILITY',
  MeanReturn = 'MEAN_RETURN',
  Percentile_5 = 'PERCENTILE_5',
  Percentile_25 = 'PERCENTILE_25',
  Percentile_50 = 'PERCENTILE_50',
  Percentile_75 = 'PERCENTILE_75',
  Percentile_95 = 'PERCENTILE_95',
}

export type IPortfolioTimeWeightedReturnsFilters = {
  date?: InputMaybe<IDateRangeFilter>;
  subAccountAssetFilters?: InputMaybe<ISubAccountAssetFilterInputType>;
};

export type IPortfolioTimeWeightedReturnsType = {
  __typename?: 'PortfolioTimeWeightedReturnsType';
  values: Scalars['TimeWeightedReturns']['output'];
};

export type IPortfolioValue = {
  __typename?: 'PortfolioValue';
  greeks: IGreeks;
  marketPnl?: Maybe<Scalars['Float']['output']>;
  npvPnl: Scalars['Float']['output'];
};

export type IPosition = {
  __typename?: 'Position';
  amount: Scalars['Float']['output'];
  base: IAsset;
  closePrice?: Maybe<Scalars['Float']['output']>;
  closedAt?: Maybe<Scalars['ArrowType']['output']>;
  exchange?: Maybe<Scalars['LabelType']['output']>;
  fee?: Maybe<IFee>;
  netQuoteProfit: Scalars['Float']['output'];
  netReturn: Scalars['Float']['output'];
  openAt: Scalars['ArrowType']['output'];
  openPrice: Scalars['Float']['output'];
  quote: IAsset;
  side: IPositionSide;
  status: IPositionStatus;
};

export type IPositionChanges = {
  __typename?: 'PositionChanges';
  amount?: Maybe<Scalars['Decimal']['output']>;
  asset: IAsset;
  price?: Maybe<Scalars['Decimal']['output']>;
  subAccount: ISubAccount;
};

export enum IPositionSide {
  Long = 'LONG',
  Short = 'SHORT',
}

export type IPositionStatistic = {
  __typename?: 'PositionStatistic';
  name: IPositionStatisticName;
  value: Scalars['Float']['output'];
};

export enum IPositionStatisticName {
  AvgHoldingTime = 'AVG_HOLDING_TIME',
  AvgTradesPerWeek = 'AVG_TRADES_PER_WEEK',
  AvgTradeWinRatio = 'AVG_TRADE_WIN_RATIO',
}

export enum IPositionStatus {
  Closed = 'CLOSED',
  Open = 'OPEN',
}

export type IPositionTradingPeriod = {
  __typename?: 'PositionTradingPeriod';
  baseAsset: IAsset;
  endTime: Scalars['ArrowType']['output'];
  id: Scalars['UUID']['output'];
  metricValues: Array<ITradingMetricValue>;
  quoteAsset: IAsset;
  side: IOrderSide;
  startTime: Scalars['ArrowType']['output'];
  subAccount: ISubAccount;
};

export type IPositionTradingPeriodFilters = {
  assetIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  side?: InputMaybe<Scalars['String']['input']>;
  subAccountIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  time?: InputMaybe<ITimeRangeFilter>;
};

export type IPositionTradingPeriodOffsetLimitPage = {
  __typename?: 'PositionTradingPeriodOffsetLimitPage';
  data: Array<IPositionTradingPeriod>;
  pageInfo: IPageOffsetInfo;
};

export enum IPositionValueType {
  Percentage = 'PERCENTAGE',
  UsdValue = 'USD_VALUE',
}

export type IPreciseAssetPrice = {
  __typename?: 'PreciseAssetPrice';
  price: Scalars['Decimal']['output'];
  time: Scalars['ArrowType']['output'];
};

export type IPresignedUrl = {
  __typename?: 'PresignedUrl';
  remoteFile: IFileUpload;
  uploadLink: Scalars['String']['output'];
};

export type IPricedOption = {
  __typename?: 'PricedOption';
  askFactors?: Maybe<IPricedOptionFactors>;
  bidFactors?: Maybe<IPricedOptionFactors>;
  markFactors?: Maybe<IPricedOptionFactors>;
  openInterest?: Maybe<Scalars['Decimal']['output']>;
  option: IAsset;
  time: Scalars['ArrowType']['output'];
  underlyingPrice: Scalars['Decimal']['output'];
};

export type IPricedOptionFactors = {
  __typename?: 'PricedOptionFactors';
  greeks: IGreeks;
  iv?: Maybe<Scalars['Decimal']['output']>;
  price: Scalars['Decimal']['output'];
};

export enum IPrimaryConstrainedQuantity {
  GrossExposure = 'GROSS_EXPOSURE',
  TargetReturn = 'TARGET_RETURN',
  TargetRisk = 'TARGET_RISK',
}

export type IPrimaryPortfolioConstraintInput = {
  constrainedQuantity?: IPrimaryConstrainedQuantity;
  constraint: IConstraintInput;
  riskMetric?: InputMaybe<IRiskMetricInput>;
};

export type IPrimaryPortfolioConstraintType = {
  __typename?: 'PrimaryPortfolioConstraintType';
  constrainedQuantity: IPrimaryConstrainedQuantity;
  constraint: IConstraint;
  riskMetric?: Maybe<IRiskMetric>;
};

export type IPrivateAssetFilterInput = {
  dropAllPrivateAssets?: Scalars['Boolean']['input'];
  maxGap?: Scalars['Int']['input'];
  minHistLength?: Scalars['Int']['input'];
};

export type IPrivateAssetFilterInputType = {
  dropAllPrivateAssets?: Scalars['Boolean']['input'];
  maxGap?: Scalars['Int']['input'];
  minHistLength?: Scalars['Int']['input'];
};

export type IPrivateAssetFilterType = {
  __typename?: 'PrivateAssetFilterType';
  dropAllPrivateAssets: Scalars['Boolean']['output'];
  maxGap: Scalars['Int']['output'];
  minHistLength: Scalars['Int']['output'];
};

export enum IProcessStatus {
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
  Succeeded = 'SUCCEEDED',
}

export type IProtocol = {
  __typename?: 'Protocol';
  description?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  symbol?: Maybe<Scalars['String']['output']>;
};

export type IPublicAssetRequestType = {
  __typename?: 'PublicAssetRequestType';
  endTime?: Maybe<Scalars['ArrowType']['output']>;
  startTime?: Maybe<Scalars['ArrowType']['output']>;
  status: IAssetManagerStatusType;
  symbol: Scalars['String']['output'];
};

export type IQsMetricType = {
  __typename?: 'QSMetricType';
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  value: Scalars['Float']['output'];
  valueType: IMetricFormat;
};

export type IQuery = {
  __typename?: 'Query';
  assets: IAssets;
  bookkeeping: IBookkeeping;
  calendar: ICalendar;
  candles: ICandlePage;
  dataSources: Array<IDataSource>;
  factorRegression: IFactorRouterType;
  factorRegressionV2: IFactorRouterTypeV2;
  fundOptimization: IFundOptimization;
  management: IManagement;
  multifactor: IMultiFactorQueryType;
  news: INewsType;
  notification: INotification;
  optionsAnalysis: IOptionsAnalysis;
  organization: IOrganization;
  portfolio: IPortfolio;
  portfolioEvaluation: IPortfolioEvaluation;
  portfolioOptimization: IPortfolioOptimization;
  regime: IRegimeType;
  scenarioAnalysisV2: IScenarioAnalysisV2;
  strategy: IStrategyType;
  stressTest: IStressTestType;
  timeSeries: ITimeSeriesPage;
  yieldOptimization: IYieldOptimization;
};

export type IQueryCandlesArgs = {
  cursors?: InputMaybe<ICursorsV1>;
  filters?: InputMaybe<ICandleFilters>;
};

export type IQueryDataSourcesArgs = {
  first?: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type IQueryTimeSeriesArgs = {
  cursors?: InputMaybe<ICursorsV1>;
  filters?: InputMaybe<ITimeSeriesFilters>;
};

export enum IReactionType {
  Down = 'DOWN',
  Up = 'UP',
}

export type IRebalancedPortfolioAssetRuleInput = {
  filters: Array<IRebalancedPortfolioFilterInput>;
  longAssets: Scalars['Int']['input'];
  minFactors: Scalars['Int']['input'];
  multifactorId: Scalars['Int']['input'];
  shortAssets: Scalars['Int']['input'];
  sorting: Array<IRebalancedPortfolioSortingInput>;
};

export type IRebalancedPortfolioFilterInput = {
  colId: Scalars['String']['input'];
  conditions?: Array<INumericFilterConditionInput>;
  filterType: IFilterType;
  operator?: InputMaybe<IFilterOperator>;
  values?: Array<Scalars['String']['input']>;
};

export type IRebalancedPortfolioSortingInput = {
  colId: Scalars['String']['input'];
  sortType: ISortType;
};

export type IReconciliation = {
  __typename?: 'Reconciliation';
  report: Array<IReconciliationReportRow>;
};

export type IReconciliationReportArgs = {
  dates?: InputMaybe<IDateRangeFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  minUsdDiscrepancy?: InputMaybe<Scalars['Decimal']['input']>;
  subAccountAssetFilters?: InputMaybe<ISubAccountAssetFilterInputType>;
};

export type IReconciliationReportRow = {
  __typename?: 'ReconciliationReportRow';
  asset: IAsset;
  assetClosingPriceUsd?: Maybe<Scalars['Decimal']['output']>;
  endTime: Scalars['ArrowType']['output'];
  side?: Maybe<IPositionSide>;
  snapshotBalanceChangeAmount: Scalars['Decimal']['output'];
  startTime?: Maybe<Scalars['ArrowType']['output']>;
  subAccount: ISubAccount;
  transactionBalanceChangeAmount: Scalars['Decimal']['output'];
};

export type IRecurringVestingInput = {
  schedule: IScheduleInput;
  totalVestedAmount: Scalars['Decimal']['input'];
};

export type IRegimeData = {
  __typename?: 'RegimeData';
  message?: Maybe<Scalars['String']['output']>;
  metrics: Array<IRegimeRiskMetric>;
  regimeName: Scalars['String']['output'];
  returns: Array<Scalars['Float']['output']>;
};

export type IRegimeDefinitions = {
  __typename?: 'RegimeDefinitions';
  benchmark?: Maybe<IAsset>;
  createdAt: Scalars['ArrowType']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  regimes: Array<Scalars['String']['output']>;
  updatedAt: Scalars['ArrowType']['output'];
};

export type IRegimeMetric = {
  __typename?: 'RegimeMetric';
  data: Array<IRegimeMetricData>;
  name: Scalars['LabelType']['output'];
};

export type IRegimeMetricData = {
  __typename?: 'RegimeMetricData';
  name: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type IRegimeRiskInput = {
  dateRange?: InputMaybe<IDateRangeFilter>;
  portfolioDefinitionIds: Array<Scalars['UUID']['input']>;
  privateAssetFilterInput?: InputMaybe<IPrivateAssetFilterInput>;
  regimeDefinitionId: Scalars['UUID']['input'];
  requestedRegimes: Array<Scalars['String']['input']>;
  subAccountAssetFilter?: InputMaybe<ISubAccountAssetFilterInputType>;
};

export type IRegimeRiskMetric = {
  __typename?: 'RegimeRiskMetric';
  benchmark?: Maybe<IAsset>;
  format: IFormat;
  metricName: Scalars['String']['output'];
  metricValue: Scalars['Float']['output'];
};

export type IRegimeType = {
  __typename?: 'RegimeType';
  /** Get available models */
  availableModels: Array<IMarketModel>;
  /** Get historical predictions */
  historicalPredictions: Array<IModelPrediction>;
  /** Get model metrics */
  modelMetrics: Array<IRegimeMetric>;
};

export type IRegimeTypeHistoricalPredictionsArgs = {
  modelIds: Array<Scalars['UUID']['input']>;
  since?: InputMaybe<Scalars['Date']['input']>;
  until?: InputMaybe<Scalars['Date']['input']>;
};

export type IRegimeTypeModelMetricsArgs = {
  assetId: Scalars['UUID']['input'];
  modelId: Scalars['UUID']['input'];
  since?: InputMaybe<Scalars['Date']['input']>;
  until?: InputMaybe<Scalars['Date']['input']>;
};

export enum IRegressionModel {
  L1Regularized = 'L1_REGULARIZED',
  Linear = 'LINEAR',
}

export enum IRegressionModelV2 {
  L1Regularized = 'L1_REGULARIZED',
  Linear = 'LINEAR',
}

export type IRegressionResult = {
  __typename?: 'RegressionResult';
  alpha?: Maybe<IFactorResult>;
  alphaValue?: Maybe<Scalars['Float']['output']>;
  date: Scalars['Date']['output'];
  fValue?: Maybe<Scalars['Float']['output']>;
  rSquared?: Maybe<Scalars['Float']['output']>;
  results: Array<IFactorResult>;
};

export type IRegressionResultV2 = {
  __typename?: 'RegressionResultV2';
  alpha?: Maybe<IFactorResultV2>;
  alphaValue?: Maybe<Scalars['Float']['output']>;
  date: Scalars['Date']['output'];
  fValue?: Maybe<Scalars['Float']['output']>;
  rSquared?: Maybe<Scalars['Float']['output']>;
  results: Array<IFactorResultV2>;
};

export type IRegressionServiceInput = {
  factors: Array<ISupportedFactorInputType>;
  lookbackWindowSize: Scalars['Int']['input'];
  regressionModel: IRegressionModel;
  since?: InputMaybe<Scalars['Date']['input']>;
  subAccountAssetFilter?: InputMaybe<ISubAccountAssetFilterInputType>;
  until?: InputMaybe<Scalars['Date']['input']>;
};

export type IRegressionServiceInputV2 = {
  factors: Array<ISupportedFactorInputV2>;
  lookbackWindowSize: Scalars['Int']['input'];
  portfolioDefinitionIds?: Array<Scalars['UUID']['input']>;
  regressionModel: IRegressionModelV2;
  since?: InputMaybe<Scalars['Date']['input']>;
  until?: InputMaybe<Scalars['Date']['input']>;
};

export type IRenameClusterInput = {
  newClusterName: Scalars['String']['input'];
  oldClusterName: Scalars['String']['input'];
};

export type IRenameDimensionInput = {
  newDimension: Scalars['String']['input'];
  oldDimension: Scalars['String']['input'];
};

export enum IRequestResultCode {
  Success = 'SUCCESS',
  Warning = 'WARNING',
}

export enum IRequestResultCodeV2 {
  Success = 'SUCCESS',
  Warning = 'WARNING',
}

export enum IReturnMeasureName {
  HistoricMedian = 'HISTORIC_MEDIAN',
  UserSupplied = 'USER_SUPPLIED',
}

export enum IReturnMeasureNameUi {
  ForecastedReturns = 'FORECASTED_RETURNS',
  HistoricMedian = 'HISTORIC_MEDIAN',
  UserSupplied = 'USER_SUPPLIED',
}

export type IReturnMetrics = {
  __typename?: 'ReturnMetrics';
  bestDay: IFloatRowValues;
  dayCount: IIntRowValues;
  meanReturn: IFloatRowValues;
  medianReturn: IFloatRowValues;
  worstDay: IFloatRowValues;
};

export enum IReturnStatName {
  AverageGain = 'AVERAGE_GAIN',
  AverageLoss = 'AVERAGE_LOSS',
  GainProbability = 'GAIN_PROBABILITY',
  LossProbability = 'LOSS_PROBABILITY',
  MeanReturn = 'MEAN_RETURN',
  PctQuantile_5 = 'PCT_QUANTILE_5',
  PctQuantile_25 = 'PCT_QUANTILE_25',
  PctQuantile_50 = 'PCT_QUANTILE_50',
  PctQuantile_75 = 'PCT_QUANTILE_75',
  PctQuantile_95 = 'PCT_QUANTILE_95',
}

export type IReturnStatistics = {
  __typename?: 'ReturnStatistics';
  statName: IReturnStatName;
  statValue: Scalars['Float']['output'];
};

export type IReturnsPredictions = {
  __typename?: 'ReturnsPredictions';
  returnStatistics: Array<IReturnStatistics>;
  returns: Array<Scalars['Float']['output']>;
};

export enum IReturnsType {
  Algebraic = 'ALGEBRAIC',
  Geometric = 'GEOMETRIC',
}

export type IRiskMetric = {
  __typename?: 'RiskMetric';
  benchmark?: Maybe<IAsset>;
  metricLabel: Scalars['LabelType']['output'];
};

export type IRiskMetricInput = {
  benchmark?: InputMaybe<Scalars['UUID']['input']>;
  riskMeasureName: Scalars['LabelType']['input'];
};

export type IScenAnalysisInputType = {
  portfolioDefinitions?: InputMaybe<Array<IPortfolioDefinitionInput>>;
  portfolios?: InputMaybe<Array<IScenAnalysisPortfolioInput>>;
  scenario: IScenarioDefinition;
};

export type IScenAnalysisPortfolioInput = {
  externalId: Scalars['UUID']['input'];
  futures?: Array<IFuturePosition>;
  options?: Array<IOptionPosition>;
  perps?: Array<IPerpPosition>;
  spots?: Array<ISpotPosition>;
};

export type IScenAnalysisResult = {
  __typename?: 'ScenAnalysisResult';
  externalId?: Maybe<Scalars['UUID']['output']>;
  portfolioDefinition?: Maybe<IPortfolioDefinition>;
  referenceEvaluation: IPortfolioEvaluationV2;
  simulatedResults: Array<IScenarioResult>;
};

export type IScenario = {
  events: Array<IEvent>;
};

export type IScenarioAnalysisInputType = {
  parameterGridChange: IParameterGridChange;
  portfolio: Array<IOptionPositionInputType>;
};

export type IScenarioAnalysisResult = {
  __typename?: 'ScenarioAnalysisResult';
  referenceValue: IPortfolioValue;
  simulatedResults: Array<ISingleScenarioAnalysisResult>;
};

export type IScenarioAnalysisV2 = {
  __typename?: 'ScenarioAnalysisV2';
  /** Evaluate a portfolio */
  portfolioEvaluationV2: Array<IPortfolioEvaluationV2>;
  /** Run a scenario analysis */
  scenarioAnalysisV2: Array<IScenAnalysisResult>;
};

export type IScenarioAnalysisV2PortfolioEvaluationV2Args = {
  input: IPortfolioEvaluationInputType;
};

export type IScenarioAnalysisV2ScenarioAnalysisV2Args = {
  input: IScenAnalysisInputType;
};

export type IScenarioDefinition = {
  benchmarkShocks?: InputMaybe<IAssetShockList>;
  extraAssetShocks?: InputMaybe<Array<IAssetShockList>>;
  riskFreeShocks?: InputMaybe<IShockList>;
};

export type IScenarioInstance = {
  __typename?: 'ScenarioInstance';
  benchmark: IAssetShock;
  extraAssetShocks?: Maybe<Array<IAssetShock>>;
  riskFree?: Maybe<IShock>;
};

export type IScenarioResult = {
  __typename?: 'ScenarioResult';
  portfolioEvaluationV2: IPortfolioEvaluationV2;
  scenario: IScenarioInstance;
};

export type IScheduleInput = {
  begin: Scalars['ArrowType']['input'];
  end: Scalars['ArrowType']['input'];
  period: IPeriodInput;
};

export type IScreenerFactorInput = {
  minNumberOfFactors: Scalars['Int']['input'];
  multifactorId: Scalars['Int']['input'];
};

export enum ISecondaryConstrainedQuantity {
  Beta = 'BETA',
  NetExposure = 'NET_EXPOSURE',
}

export type ISecondaryPortfolioConstraint = {
  __typename?: 'SecondaryPortfolioConstraint';
  constrainedQuantity: ISecondaryConstrainedQuantity;
  constraint: IConstraint;
  riskMetric?: Maybe<IRiskMetric>;
};

export type ISecondaryPortfolioConstraintInput = {
  constrainedQuantity?: ISecondaryConstrainedQuantity;
  constraint: IConstraintInput;
  riskMetric?: InputMaybe<IRiskMetricInput>;
};

export type ISendMessageResult = {
  __typename?: 'SendMessageResult';
  agentMessage: IMessage;
  userMessage: IMessage;
};

export enum ISentiment {
  Negative = 'NEGATIVE',
  Neutral = 'NEUTRAL',
  Positive = 'POSITIVE',
}

export type ISerieValue = {
  __typename?: 'SerieValue';
  date: Scalars['Date']['output'];
  value: Scalars['Decimal']['output'];
};

export type IShock = {
  __typename?: 'Shock';
  mode: IShockMode;
  reference?: Maybe<Scalars['Float']['output']>;
  shock: Scalars['Float']['output'];
};

export type IShockList = {
  mode?: IShockMode;
  reference?: InputMaybe<Scalars['Float']['input']>;
  shocks?: Array<Scalars['Float']['input']>;
};

export enum IShockMode {
  Absolute = 'ABSOLUTE',
  Percentage = 'PERCENTAGE',
}

export type ISingleScenarioAnalysisResult = {
  __typename?: 'SingleScenarioAnalysisResult';
  parameterChange: IParameterChange;
  portfolioValue: IPortfolioValue;
};

export enum ISlotType {
  Borrowed = 'BORROWED',
  Free = 'FREE',
  Lent = 'LENT',
  Locked = 'LOCKED',
  Staking = 'STAKING',
  Unvested = 'UNVESTED',
}

/** sort direction, ascending or descending */
export enum ISortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

/** the camel case name of the column to sort by and the sort direction */
export type ISortField = {
  direction: ISortDirection;
  name: Scalars['String']['input'];
};

export enum ISortType {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type ISpotPosition = {
  amount: Scalars['Float']['input'];
  assetId: Scalars['UUID']['input'];
};

export type IStrIncludeExcludeFilter = {
  exclude?: InputMaybe<Array<Scalars['String']['input']>>;
  include?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type IStrategy = {
  __typename?: 'Strategy';
  description: Scalars['String']['output'];
  label: Scalars['LabelType']['output'];
  name: Scalars['String']['output'];
};

export type IStrategyPerformanceMetric = {
  __typename?: 'StrategyPerformanceMetric';
  name: IStrategyPerformanceMetricName;
  value: Scalars['Float']['output'];
};

export enum IStrategyPerformanceMetricName {
  AssetsTraded = 'ASSETS_TRADED',
  CumulativeReturn = 'CUMULATIVE_RETURN',
  Returns_30D = 'RETURNS_30D',
  ReturnsYtd = 'RETURNS_YTD',
  SharpeRatio = 'SHARPE_RATIO',
}

export type IStrategyRiskMetric = {
  __typename?: 'StrategyRiskMetric';
  name: Scalars['LabelType']['output'];
  value: Scalars['Float']['output'];
};

export type IStrategyRiskMetricRolling = {
  __typename?: 'StrategyRiskMetricRolling';
  label: Scalars['LabelType']['output'];
  mean: Scalars['Float']['output'];
  timeSeries: Array<ITimeSeriesScalarData>;
};

export type IStrategyType = {
  __typename?: 'StrategyType';
  /** Get strategy's asset allocations by label */
  assetAllocations: Array<IAssetAllocationSnapshot>;
  /** Get strategy's cumulative returns by label */
  cumulativeReturns: ICumulativeReturn;
  /** List the available strategies */
  list: Array<IStrategy>;
  /** Get strategy's monthly returns by label */
  monthlyReturns: Array<ITimeSeriesScalarData>;
  /** Get strategy's trade list by label */
  positionList: Array<IPosition>;
  /** Get strategy's trade statistics by label */
  positionStatistics: Array<IPositionStatistic>;
  /** Get strategy by label */
  strategy?: Maybe<IStrategy>;
  /** Get strategy's performance metrics by label */
  strategyPerformanceMetrics: Array<IStrategyPerformanceMetric>;
  /** Get strategy's risk metrics by label */
  strategyRiskMetrics: Array<IStrategyRiskMetric>;
  /** Get strategy's rolling risk metrics by label */
  strategyRiskMetricsRolling: Array<IStrategyRiskMetricRolling>;
};

export type IStrategyTypeAssetAllocationsArgs = {
  label: Scalars['LabelType']['input'];
};

export type IStrategyTypeCumulativeReturnsArgs = {
  label: Scalars['LabelType']['input'];
};

export type IStrategyTypeMonthlyReturnsArgs = {
  label: Scalars['LabelType']['input'];
};

export type IStrategyTypePositionListArgs = {
  label: Scalars['LabelType']['input'];
};

export type IStrategyTypePositionStatisticsArgs = {
  label: Scalars['LabelType']['input'];
};

export type IStrategyTypeStrategyArgs = {
  label: Scalars['LabelType']['input'];
};

export type IStrategyTypeStrategyPerformanceMetricsArgs = {
  label: Scalars['LabelType']['input'];
};

export type IStrategyTypeStrategyRiskMetricsArgs = {
  label: Scalars['LabelType']['input'];
};

export type IStrategyTypeStrategyRiskMetricsRollingArgs = {
  label: Scalars['LabelType']['input'];
};

export type IStressTestInputType = {
  portfolio: IPortfolioInput;
  scenario: IScenario;
};

export type IStressTestOutputType = {
  __typename?: 'StressTestOutputType';
  keyMeasures: IKeyMeasures;
  logicResult: ILogicResult;
};

export type IStressTestType = {
  __typename?: 'StressTestType';
  /** List scenario library */
  scenarioLibrary: Array<ILabelledScenario>;
  /** Run a stress test */
  stressTest: IStressTestOutputType;
};

export type IStressTestTypeStressTestArgs = {
  input: IStressTestInputType;
};

export type ISubAccount = {
  __typename?: 'SubAccount';
  account: IAccount;
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['ArrowType']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
};

export type ISubAccountAssetFilterInputType = {
  accountIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  assetIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  subAccountIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  subFundIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type ISubAccountFilters = {
  includeInactive?: Scalars['Boolean']['input'];
};

export type ISubAccountInput = {
  id: Scalars['UUID']['input'];
};

export type ISubAccountPositionDerivativeSnapshot = {
  __typename?: 'SubAccountPositionDerivativeSnapshot';
  amount: Scalars['Decimal']['output'];
  balance?: Maybe<Scalars['Decimal']['output']>;
  initialMargin?: Maybe<Scalars['Decimal']['output']>;
  leverage: Scalars['Decimal']['output'];
  maintenanceMargin?: Maybe<Scalars['Decimal']['output']>;
  notional?: Maybe<Scalars['Decimal']['output']>;
  side: IPositionSide;
  unitEntryPrice?: Maybe<Scalars['Decimal']['output']>;
  unitMarketPrice?: Maybe<Scalars['Decimal']['output']>;
  unrealizedPnl?: Maybe<Scalars['Decimal']['output']>;
};

export type ISubAccountPositionSnapshot = {
  __typename?: 'SubAccountPositionSnapshot';
  asset: IAsset;
  derivative?: Maybe<ISubAccountPositionDerivativeSnapshot>;
  spot?: Maybe<ISubAccountPositionSpotSnapshot>;
  subAccount: ISubAccount;
  time: Scalars['ArrowType']['output'];
};

export type ISubAccountPositionSpotSnapshot = {
  __typename?: 'SubAccountPositionSpotSnapshot';
  /** Asset amount. It might be either positive or negative depending on the slot */
  amount: Scalars['Decimal']['output'];
  balance: Scalars['Decimal']['output'];
  price: Scalars['Decimal']['output'];
  slot: ISlotType;
};

export type ISubAccountPositions = {
  __typename?: 'SubAccountPositions';
  positions: Array<ISubAccountPositionSnapshot>;
  summary: ISummary;
};

export type ISubAccountSnapshot = {
  __typename?: 'SubAccountSnapshot';
  date: Scalars['Date']['output'];
  positions: Array<ISubAccountPositionSnapshot>;
  summary: ISummary;
};

export type ISubAccountSnapshotFilters = {
  date?: InputMaybe<IDateRangeFilter>;
  subAccountAssetFilters?: InputMaybe<ISubAccountAssetFilterInputType>;
};

export type ISubAccountTimeWeightedReturnsType = {
  __typename?: 'SubAccountTimeWeightedReturnsType';
  subAccount: ISubAccount;
  values: Scalars['TimeWeightedReturns']['output'];
};

export type ISubAccountsTimeWeightedReturnsType = {
  __typename?: 'SubAccountsTimeWeightedReturnsType';
  subAccounts: Array<ISubAccountTimeWeightedReturnsType>;
};

export type ISubFund = {
  __typename?: 'SubFund';
  accounts: Array<IAccount>;
  createdAt: Scalars['ArrowType']['output'];
  dimension: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isDefault: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  referenceAsset: IAsset;
  subAccounts: Array<ISubAccount>;
  updatedAt?: Maybe<Scalars['ArrowType']['output']>;
};

/** Sub-fund performance */
export type ISubFundPerformance = {
  __typename?: 'SubFundPerformance';
  subFunds: Array<ISubFundTimeWeightedReturns>;
};

/** Sub-fund performance by asset */
export type ISubFundPerformanceByAsset = {
  __typename?: 'SubFundPerformanceByAsset';
  asset: IAsset;
  summaries: Array<IPeriodReturnStatisticsType>;
  twr: Scalars['TimeWeightedReturns']['output'];
};

/** Sub-fund time-weighted returns type */
export type ISubFundTimeWeightedReturns = {
  __typename?: 'SubFundTimeWeightedReturns';
  referenceAssetReturns: ISubFundPerformanceByAsset;
  subFund: ISubFund;
  usdReturns: ISubFundPerformanceByAsset;
};

export type ISubFunds = {
  __typename?: 'SubFunds';
  byId: Array<ISubFund>;
  list: Array<ISubFund>;
};

export type ISubFundsByIdArgs = {
  ids: Array<Scalars['Int']['input']>;
};

export type ISubFundsTimeWeightedReturnsFilters = {
  dimensions?: InputMaybe<IStrIncludeExcludeFilter>;
  includeDefaultSubFunds?: Scalars['Boolean']['input'];
  since?: InputMaybe<Scalars['Date']['input']>;
  subFunds?: InputMaybe<IIntIncludeExcludeFilter>;
};

export type ISubmitOptimizationOutput = {
  __typename?: 'SubmitOptimizationOutput';
  optimizationId: Scalars['Int']['output'];
};

export type ISummary = {
  __typename?: 'Summary';
  balance: IAllocation;
  balanceByVenue: Array<IBalanceByVenue>;
  exposure: IAllocation;
};

export type ISummaryInput = {
  portfolioDefinitionIds: Array<Scalars['UUID']['input']>;
  since?: InputMaybe<Scalars['Date']['input']>;
  to?: InputMaybe<Scalars['Date']['input']>;
};

export type ISummaryParagraph = {
  __typename?: 'SummaryParagraph';
  content: Scalars['String']['output'];
  index: Scalars['Int']['output'];
  portfolio?: Maybe<IPortfolioDefinition>;
  title: Scalars['String']['output'];
};

export type ISupportedDateTimeWindow = {
  __typename?: 'SupportedDateTimeWindow';
  defaultWindowSize: Scalars['Int']['output'];
  maxWindowSizeSupported: Scalars['Int']['output'];
  minWindowSizeSupported: Scalars['Int']['output'];
  since: Scalars['Date']['output'];
  until: Scalars['Date']['output'];
};

export type ISupportedDateTimeWindowV2 = {
  __typename?: 'SupportedDateTimeWindowV2';
  defaultWindowSize: Scalars['Int']['output'];
  maxWindowSizeSupported: Scalars['Int']['output'];
  minWindowSizeSupported: Scalars['Int']['output'];
  since: Scalars['Date']['output'];
  until: Scalars['Date']['output'];
};

export type ISupportedFactor = {
  __typename?: 'SupportedFactor';
  asset: IAsset;
  type: IFactorCategory;
};

export type ISupportedFactorInputType = {
  assetId: Scalars['UUID']['input'];
  category: IFactorCategory;
};

export type ISupportedFactorInputV2 = {
  assetId: Scalars['UUID']['input'];
  category: IFactorCategoryV2;
};

export type ISupportedFactorV2 = {
  __typename?: 'SupportedFactorV2';
  asset: IAsset;
  type: IFactorCategoryV2;
};

export enum ISyncRequestResultCode {
  AccessDenied = 'ACCESS_DENIED',
  CompletedPartially = 'COMPLETED_PARTIALLY',
  ExchangeError = 'EXCHANGE_ERROR',
  NetworkError = 'NETWORK_ERROR',
  Ok = 'OK',
  PermissionDenied = 'PERMISSION_DENIED',
  UnknownError = 'UNKNOWN_ERROR',
}

export type ITimeRangeFilter = {
  gte?: InputMaybe<Scalars['ArrowType']['input']>;
  lte?: InputMaybe<Scalars['ArrowType']['input']>;
};

export type ITimeRangeFilterV2 = {
  since?: InputMaybe<Scalars['ArrowType']['input']>;
  to?: InputMaybe<Scalars['ArrowType']['input']>;
};

export type ITimeSeries = {
  __typename?: 'TimeSeries';
  base?: Maybe<Scalars['LabelType']['output']>;
  columns?: Maybe<Array<ITimeSeriesColumn>>;
  name?: Maybe<Scalars['LabelType']['output']>;
  quote?: Maybe<Scalars['LabelType']['output']>;
  version?: Maybe<Scalars['ArrowType']['output']>;
};

export type ITimeSeriesColumn = {
  __typename?: 'TimeSeriesColumn';
  name: Scalars['String']['output'];
  values: Scalars['TimeSeriesListValuesType']['output'];
};

export type ITimeSeriesData = {
  __typename?: 'TimeSeriesData';
  name: Scalars['LabelType']['output'];
  value: Scalars['Float']['output'];
};

export type ITimeSeriesFilters = {
  base?: InputMaybe<Scalars['LabelType']['input']>;
  name?: InputMaybe<Scalars['LabelType']['input']>;
  quote?: InputMaybe<Scalars['LabelType']['input']>;
  version?: InputMaybe<ITimeRangeFilter>;
};

export type ITimeSeriesPage = {
  __typename?: 'TimeSeriesPage';
  data: Array<ITimeSeries>;
  pageInfo: IPageInfoV1;
};

export type ITimeSeriesScalarData = {
  __typename?: 'TimeSeriesScalarData';
  time: Scalars['ArrowType']['output'];
  value: Scalars['Float']['output'];
};

export type ITimeSeriesValue = {
  __typename?: 'TimeSeriesValue';
  date: Scalars['Date']['output'];
  values: Array<ITimeSeriesData>;
};

export type ITopic = {
  __typename?: 'Topic';
  humanName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
};

export type ITrade = {
  __typename?: 'Trade';
  order?: Maybe<ITradeOrderLink>;
  type: ITradeType;
};

export type ITradeInput = {
  order?: InputMaybe<IOrderInput>;
  type?: InputMaybe<ITradeType>;
};

export type ITradeOrderLink = {
  __typename?: 'TradeOrderLink';
  externalId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  side?: Maybe<IOrderSide>;
  type?: Maybe<IOrderType>;
};

export enum ITradeType {
  Maker = 'MAKER',
  Taker = 'TAKER',
  Unknown = 'UNKNOWN',
}

export type ITradingActivity = {
  __typename?: 'TradingActivity';
  /** Amount of the asset */
  amount: Scalars['Decimal']['output'];
  asset: IAsset;
  /** Asset that the profit/loss is attributed to: e.g. funding fees or staking rewards */
  attributedToAssetId: IAsset;
  day: Scalars['Date']['output'];
  /** Number of transaction legs that contributed to the asset flow */
  legCount: Scalars['Int']['output'];
  /** Type of the transaction leg */
  legType: ITransactionLegType;
  /** Price of the asset at end of day */
  price: Scalars['Decimal']['output'];
  /** Side of the position */
  side?: Maybe<IPositionSide>;
  /** State of the asset at end of day */
  state: ISlotType;
  subAccount: ISubAccount;
  userType: IUserTransactionType;
  /** Value of the asset at end of day */
  value: Scalars['Decimal']['output'];
};

export type ITradingActivityFilters = {
  date?: InputMaybe<IDateRangeFilter>;
  subAccountAssetFilters?: InputMaybe<ISubAccountAssetFilterInputType>;
};

export type ITradingMetric = {
  __typename?: 'TradingMetric';
  displayName: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export type ITradingMetricTimeValue = {
  __typename?: 'TradingMetricTimeValue';
  metric: ITradingMetric;
  time: Scalars['ArrowType']['output'];
  value: Scalars['Float']['output'];
};

export type ITradingMetricValue = {
  __typename?: 'TradingMetricValue';
  metric: ITradingMetric;
  value: Scalars['Float']['output'];
};

export type ITransaction = {
  __typename?: 'Transaction';
  actions: Array<IAction>;
  comment: Scalars['String']['output'];
  credit?: Maybe<IAssetValue>;
  debit?: Maybe<IAssetValue>;
  executedAt: Scalars['ArrowType']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  fee?: Maybe<IAssetValue>;
  id: Scalars['UUID']['output'];
  originalTransactionId?: Maybe<Scalars['UUID']['output']>;
  status?: Maybe<ITransactionStatus>;
  subAccount: ISubAccount;
  subType: Scalars['String']['output'];
  tags?: Maybe<Array<Scalars['String']['output']>>;
  trade?: Maybe<ITrade>;
  type: ITransactionType;
};

export type ITransactionFilters = {
  accountId?: InputMaybe<Array<Scalars['UUID']['input']>>;
  assetIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  comment?: InputMaybe<Scalars['String']['input']>;
  executedAt?: InputMaybe<ITimeRangeFilter>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  legType?: InputMaybe<Array<ITransactionLegType>>;
  orderSide?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  status?: InputMaybe<Array<ITransactionStatus>>;
  subAccountId?: InputMaybe<Array<Scalars['UUID']['input']>>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ITransactionInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  credit?: InputMaybe<IAssetValueInput>;
  debit?: InputMaybe<IAssetValueInput>;
  executedAt: Scalars['ArrowType']['input'];
  externalId?: InputMaybe<Scalars['String']['input']>;
  fee?: InputMaybe<IAssetValueInput>;
  status?: InputMaybe<ITransactionStatus>;
  subAccount: ISubAccountInput;
  subType: Scalars['String']['input'];
  tags?: Array<Scalars['String']['input']>;
  trade?: InputMaybe<ITradeInput>;
  type: ITransactionType;
};

export type ITransactionLeg = {
  __typename?: 'TransactionLeg';
  amount: Scalars['Decimal']['output'];
  asset: IAsset;
  id: Scalars['UUID']['output'];
  journalEntry?: Maybe<IJournalEntryPnlV2>;
  side?: Maybe<IPositionSide>;
  slot: ISlotType;
  time: Scalars['ArrowType']['output'];
  type: ITransactionLegType;
  unitValue?: Maybe<Scalars['Decimal']['output']>;
  value?: Maybe<Scalars['Decimal']['output']>;
};

export type ITransactionLegInputType = {
  amount: Scalars['Decimal']['input'];
  asset: IAssetInput;
  side?: InputMaybe<IPositionSide>;
  slot: ISlotType;
  time: Scalars['ArrowType']['input'];
  type: ITransactionLegType;
  unitValue?: InputMaybe<Scalars['Decimal']['input']>;
};

export enum ITransactionLegType {
  Any = 'ANY',
  Cashflow = 'CASHFLOW',
  Fee = 'FEE',
}

export enum ITransactionStatus {
  Canceled = 'CANCELED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Succeeded = 'SUCCEEDED',
  Unknown = 'UNKNOWN',
  Unsupported = 'UNSUPPORTED',
}

export enum ITransactionType {
  Deposit = 'DEPOSIT',
  Fee = 'FEE',
  Income = 'INCOME',
  Investment = 'INVESTMENT',
  Loss = 'LOSS',
  Trade = 'TRADE',
  TradeDerivative = 'TRADE_DERIVATIVE',
  Transfer = 'TRANSFER',
  Unknown = 'UNKNOWN',
  Vesting = 'VESTING',
  Withdrawal = 'WITHDRAWAL',
}

export type ITransactionUpload = {
  __typename?: 'TransactionUpload';
  upload: ITransactionsUpload;
  uploads: Array<ITransactionsUpload>;
};

export type ITransactionUploadUploadArgs = {
  uploadId: Scalars['UUID']['input'];
};

export type ITransactionV2 = {
  __typename?: 'TransactionV2';
  attributedToAsset?: Maybe<IAsset>;
  comment?: Maybe<Scalars['String']['output']>;
  deduplicationId?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  externalType?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  legs: Array<ITransactionLeg>;
  order?: Maybe<IOrder>;
  status: ITransactionStatus;
  subAccount: ISubAccount;
  tags: Array<Scalars['String']['output']>;
  time: Scalars['ArrowType']['output'];
  userType: IUserTransactionType;
};

export type ITransactionV2CursorPage = {
  __typename?: 'TransactionV2CursorPage';
  cursors: ICursors;
  data: Array<ITransactionV2>;
};

export type ITransactionsUpload = {
  __typename?: 'TransactionsUpload';
  createdCount?: Maybe<Scalars['Int']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  executionTimeSec?: Maybe<Scalars['Int']['output']>;
  extraTag?: Maybe<Scalars['String']['output']>;
  fileName: Scalars['String']['output'];
  fileRowsCount?: Maybe<Scalars['Int']['output']>;
  firstInvalidRows: Array<IUploadRowError>;
  id: Scalars['UUID']['output'];
  invalidRowsCount?: Maybe<Scalars['Int']['output']>;
  possibleToProceedWithUpload: Scalars['Boolean']['output'];
  processedRows?: Maybe<Scalars['Int']['output']>;
  startedAt: Scalars['ArrowType']['output'];
  status: ITransactionsUploadStatus;
  subAccount: ISubAccount;
  type: ITransactionsUploadType;
};

export enum ITransactionsUploadStatus {
  Creation = 'CREATION',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
  Succeeded = 'SUCCEEDED',
  ValidationFailed = 'VALIDATION_FAILED',
}

export enum ITransactionsUploadType {
  Upload = 'UPLOAD',
  Validation = 'VALIDATION',
}

export type ITwoAssetMetric = {
  __typename?: 'TwoAssetMetric';
  date: Scalars['Date']['output'];
  primaryAsset: IAsset;
  secondaryAsset: IAsset;
  value: Scalars['Float']['output'];
};

export type ITwoAssetMetricFilters = {
  date?: InputMaybe<IDateRangeFilter>;
};

export type ITwoAssetMetricRequestInput = {
  metric: Scalars['LabelType']['input'];
  primaryAssets: Array<Scalars['UUID']['input']>;
  secondaryAssets: Array<Scalars['UUID']['input']>;
};

export type IUnifiedPnLAnalysis = {
  __typename?: 'UnifiedPnLAnalysis';
  assets?: Maybe<Array<IAsset>>;
  createdAt: Scalars['ArrowType']['output'];
  id: Scalars['Int']['output'];
  since: Scalars['Date']['output'];
  status: IPortfolioAnalysisStatusType;
  subFundDimension?: Maybe<Scalars['String']['output']>;
  until: Scalars['Date']['output'];
  updatedAt: Scalars['ArrowType']['output'];
};

export type IUnifiedPnLAnalysisResult = {
  __typename?: 'UnifiedPnLAnalysisResult';
  amount?: Maybe<Scalars['Decimal']['output']>;
  asset: IAsset;
  avgPrice?: Maybe<Scalars['Decimal']['output']>;
  date: Scalars['Date']['output'];
  price?: Maybe<Scalars['Decimal']['output']>;
  rpnl?: Maybe<Scalars['Decimal']['output']>;
  subFund?: Maybe<ISubFund>;
  upnl?: Maybe<Scalars['Decimal']['output']>;
};

export type IUpdateApiKeysInput = {
  id: Scalars['UUID']['input'];
  secrets: Scalars['JSON']['input'];
};

export type IUpdateAssetGroupInput = {
  assets: Array<Scalars['UUID']['input']>;
  clusterName: Scalars['String']['input'];
  newGroupName: Scalars['String']['input'];
  oldGroupName: Scalars['String']['input'];
};

export type IUpdateLayoutInput = {
  h: Scalars['Float']['input'];
  id: Scalars['String']['input'];
  page: Scalars['String']['input'];
  w: Scalars['Float']['input'];
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
};

export type IUpdateNameInput = {
  id: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
};

export type IUpdateOrganizationInput = {
  inceptionDate?: InputMaybe<Scalars['ArrowType']['input']>;
  referenceAssetId?: InputMaybe<Scalars['UUID']['input']>;
};

export type IUpdatePortfolioDefInput = {
  description?: Scalars['String']['input'];
  id: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
  realDefAccounts?: InputMaybe<Array<Scalars['UUID']['input']>>;
  realDefSubFunds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IUpdatePositionInput = {
  amount: Scalars['Decimal']['input'];
  assetId: Scalars['UUID']['input'];
  date: Scalars['Date']['input'];
  slot?: ISlotType;
  subAccountId: Scalars['UUID']['input'];
};

export type IUpdatePrivateAssetInput = {
  id: Scalars['UUID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
};

export type IUpdateSubFundInput = {
  accounts: Array<Scalars['UUID']['input']>;
  dimension: Scalars['String']['input'];
  newName: Scalars['String']['input'];
  oldName: Scalars['String']['input'];
  referenceAsset?: InputMaybe<Scalars['UUID']['input']>;
  subAccounts: Array<Scalars['UUID']['input']>;
};

export type IUpdateTransactionInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  credit?: InputMaybe<IAssetValueInput>;
  debit?: InputMaybe<IAssetValueInput>;
  executedAt: Scalars['ArrowType']['input'];
  externalId?: InputMaybe<Scalars['String']['input']>;
  fee?: InputMaybe<IAssetValueInput>;
  id: Scalars['UUID']['input'];
  status?: InputMaybe<ITransactionStatus>;
  subType: Scalars['String']['input'];
  tags?: Array<Scalars['String']['input']>;
  trade?: InputMaybe<ITradeInput>;
  type: ITransactionType;
};

export type IUpdateTransactionV2InputType = {
  attributedToAsset?: InputMaybe<IAssetInput>;
  comment?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  externalType?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  legs: Array<ITransactionLegInputType>;
  order?: InputMaybe<IOrderV2InputType>;
  status: ITransactionStatus;
  subAccount: ISubAccountInput;
  tags: Array<Scalars['String']['input']>;
  time: Scalars['ArrowType']['input'];
  userType: IUserTransactionType;
};

export type IUpdateWidgetInput = {
  data: Scalars['JSONObject']['input'];
  id: Scalars['String']['input'];
  page: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type IUploadLink = {
  __typename?: 'UploadLink';
  s3FileName: Scalars['String']['output'];
  uploadLink: Scalars['String']['output'];
};

export type IUploadResult = {
  __typename?: 'UploadResult';
  uploadId: Scalars['UUID']['output'];
};

export type IUploadRowError = {
  __typename?: 'UploadRowError';
  message?: Maybe<Scalars['String']['output']>;
  row?: Maybe<Scalars['String']['output']>;
  rowNumber?: Maybe<Scalars['Int']['output']>;
};

export type IUser = {
  __typename?: 'User';
  cognitoUsername: Scalars['String']['output'];
  email: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  groups: Array<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<IOrganization>;
};

export type IUserNotification = {
  __typename?: 'UserNotification';
  body: Scalars['String']['output'];
  placedAt: Scalars['ArrowType']['output'];
  requestId: Scalars['UUID']['output'];
  status: INotificationStatus;
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type IUserNotificationOffsetLimitPage = {
  __typename?: 'UserNotificationOffsetLimitPage';
  data: Array<IUserNotification>;
  pageInfo: IPageOffsetInfo;
};

export enum IUserPortfolioDefinitionType {
  Real = 'REAL',
  Rebalanced = 'REBALANCED',
}

export type IUserSubscription = {
  __typename?: 'UserSubscription';
  category: Scalars['String']['output'];
};

export enum IUserTransactionType {
  AddCollateral = 'ADD_COLLATERAL',
  AddLiquidity = 'ADD_LIQUIDITY',
  Airdrop = 'AIRDROP',
  Borrowing = 'BORROWING',
  ClaimRewards = 'CLAIM_REWARDS',
  Delivery = 'DELIVERY',
  Deposit = 'DEPOSIT',
  Expense = 'EXPENSE',
  Fee = 'FEE',
  FundingFee = 'FUNDING_FEE',
  Income = 'INCOME',
  Interest = 'INTEREST',
  InternalTransfer = 'INTERNAL_TRANSFER',
  Investment = 'INVESTMENT',
  Lending = 'LENDING',
  LiquidStaking = 'LIQUID_STAKING',
  LiquidUnstaking = 'LIQUID_UNSTAKING',
  Loan = 'LOAN',
  LoanRepay = 'LOAN_REPAY',
  Loss = 'LOSS',
  Minting = 'MINTING',
  RedeemLending = 'REDEEM_LENDING',
  RemoveCollateral = 'REMOVE_COLLATERAL',
  RemoveLiquidity = 'REMOVE_LIQUIDITY',
  RepayBorrow = 'REPAY_BORROW',
  Settlement = 'SETTLEMENT',
  Staking = 'STAKING',
  Trade = 'TRADE',
  TradeDerivative = 'TRADE_DERIVATIVE',
  Unknown = 'UNKNOWN',
  Unstaking = 'UNSTAKING',
  Vesting = 'VESTING',
  Withdrawal = 'WITHDRAWAL',
}

export type IValuesByCategory = {
  __typename?: 'ValuesByCategory';
  all: IReturnMetrics;
  negative?: Maybe<IReturnMetrics>;
  positive?: Maybe<IReturnMetrics>;
};

export type IVestingInput = {
  immediate?: InputMaybe<IImmediateVestingInput>;
  recurring?: InputMaybe<IRecurringVestingInput>;
  type: IVestingType;
};

export enum IVestingType {
  Immediate = 'IMMEDIATE',
  Recurring = 'RECURRING',
}

export type IWidget = {
  __typename?: 'Widget';
  data: Scalars['JSONObject']['output'];
  id: Scalars['String']['output'];
  layout: ILayout;
  page: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type IWindowParamsType = {
  windowSize?: Scalars['Int']['input'];
  windowType: IWindowType;
};

export enum IWindowType {
  Expanding = 'EXPANDING',
  Rolling = 'ROLLING',
  RollingExponential = 'ROLLING_EXPONENTIAL',
}

export type IYieldCardinalityConstraintInput = {
  atLeast?: InputMaybe<IBasicCardinalityConstraintInput>;
  atMost?: InputMaybe<IBasicCardinalityConstraintInput>;
};

export type IYieldCardinalityConstraintType = {
  __typename?: 'YieldCardinalityConstraintType';
  atLeast?: Maybe<IBasicCardinalityConstraintType>;
  atMost?: Maybe<IBasicCardinalityConstraintType>;
};

export type IYieldOptimization = {
  __typename?: 'YieldOptimization';
  getMultiAssetOptimization: IMultiAssetYieldAnalysisType;
  getMultiassetOptimizationInput: IMultiAssetYieldOptimizationInputType;
  listMultiassetOptimizations: Array<IMultiAssetYieldAnalysisType>;
  listPools: Array<IPoolInfo>;
};

export type IYieldOptimizationGetMultiAssetOptimizationArgs = {
  optimizationId: Scalars['Int']['input'];
};

export type IYieldOptimizationGetMultiassetOptimizationInputArgs = {
  optimizationId: Scalars['Int']['input'];
};

export type IYieldOptimizationListPoolsArgs = {
  input: IPoolFilters;
};

export type IYieldPoolInput = {
  constraint?: InputMaybe<IConstraintInput>;
  poolId: Scalars['UUID']['input'];
};

export type IYieldPoolType = {
  __typename?: 'YieldPoolType';
  constraint?: Maybe<IConstraint>;
  poolInfo: IPoolInfo;
};

export type IYieldPortfolioConstraintsInput = {
  cardinality?: InputMaybe<IYieldCardinalityConstraintInput>;
  primary?: InputMaybe<IYieldPrimaryPortfolioConstraintInput>;
};

export type IYieldPortfolioConstraintsType = {
  __typename?: 'YieldPortfolioConstraintsType';
  cardinality?: Maybe<IYieldCardinalityConstraintType>;
  primary?: Maybe<IYieldPrimaryPortfolioConstraintType>;
};

export type IYieldPrimaryPortfolioConstraintInput = {
  constraint: IConstraintInput;
};

export type IYieldPrimaryPortfolioConstraintType = {
  __typename?: 'YieldPrimaryPortfolioConstraintType';
  constraint: IConstraint;
};

export type IIsExternalUserQueryVariables = Exact<{ [key: string]: never }>;

export type IIsExternalUserQuery = {
  __typename?: 'Query';
  management: { __typename?: 'Management'; isExternalUser: boolean };
};

export type IUserOrganizationQueryVariables = Exact<{ [key: string]: never }>;

export type IUserOrganizationQuery = {
  __typename?: 'Query';
  organization: { __typename?: 'Organization'; id: number; name: string };
};

export type IAllAssetValueFieldsFragment = {
  __typename?: 'AssetValue';
  unitValue?: Decimal | null;
  amount: Decimal;
  asset: {
    __typename?: 'Asset';
    type: IAssetType;
    symbol: string;
    label?: Label | null;
    name?: string | null;
    icon?: string | null;
    id: string;
    exchangeDetails?: Label | null;
    derivativeDetails?: {
      __typename?: 'DerivativeDetails';
      exchange: Label;
      derivativeType: IDerivativeType;
      expirationDate?: string | null;
      optionType?: IOptionType | null;
      strikePrice?: Decimal | null;
      baseAsset: {
        __typename?: 'BaseAsset';
        type: IAssetType;
        symbol: string;
        label?: Label | null;
        name?: string | null;
        icon?: string | null;
        id: string;
        exchangeDetails?: Label | null;
      };
      quoteAsset: {
        __typename?: 'BaseAsset';
        type: IAssetType;
        symbol: string;
        label?: Label | null;
        name?: string | null;
        icon?: string | null;
        id: string;
        exchangeDetails?: Label | null;
      };
      settlementAsset: {
        __typename?: 'BaseAsset';
        type: IAssetType;
        symbol: string;
        label?: Label | null;
        name?: string | null;
        icon?: string | null;
        id: string;
        exchangeDetails?: Label | null;
      };
    } | null;
  };
};

export type ICreateInvestmentMutationVariables = Exact<{
  input: IInvestmentInput;
}>;

export type ICreateInvestmentMutation = { __typename?: 'Mutation'; CreateInvestment: { __typename: 'Investment' } };

export type IDeleteInvestmentMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type IDeleteInvestmentMutation = { __typename?: 'Mutation'; DeleteInvestment?: any | null };

export type IInvestmentsInputQueryVariables = Exact<{
  filters: IListAssetFilters;
}>;

export type IInvestmentsInputQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    accounts: Array<{
      __typename?: 'Account';
      id: string;
      name: string;
      venue: { __typename?: 'DataSource'; label: Label };
      subAccounts: Array<{ __typename?: 'SubAccount'; id: string; name: string }>;
    }>;
  };
  bookkeeping: {
    __typename?: 'Bookkeeping';
    investments: {
      __typename?: 'Investments';
      list: Array<{
        __typename?: 'Investment';
        id: string;
        name: string;
        executedAt: string;
        status: IInvestmentStatus;
        actions: Array<IAction>;
        vestedAmount: Decimal;
        subAccount: {
          __typename?: 'SubAccount';
          id: string;
          name: string;
          account: {
            __typename?: 'Account';
            id: string;
            name: string;
            venue: { __typename?: 'DataSource'; label: Label };
          };
        };
        credit: {
          __typename?: 'AssetValue';
          unitValue?: Decimal | null;
          amount: Decimal;
          asset: {
            __typename?: 'Asset';
            type: IAssetType;
            symbol: string;
            label?: Label | null;
            name?: string | null;
            icon?: string | null;
            id: string;
            exchangeDetails?: Label | null;
            derivativeDetails?: {
              __typename?: 'DerivativeDetails';
              exchange: Label;
              derivativeType: IDerivativeType;
              expirationDate?: string | null;
              optionType?: IOptionType | null;
              strikePrice?: Decimal | null;
              baseAsset: {
                __typename?: 'BaseAsset';
                type: IAssetType;
                symbol: string;
                label?: Label | null;
                name?: string | null;
                icon?: string | null;
                id: string;
                exchangeDetails?: Label | null;
              };
              quoteAsset: {
                __typename?: 'BaseAsset';
                type: IAssetType;
                symbol: string;
                label?: Label | null;
                name?: string | null;
                icon?: string | null;
                id: string;
                exchangeDetails?: Label | null;
              };
              settlementAsset: {
                __typename?: 'BaseAsset';
                type: IAssetType;
                symbol: string;
                label?: Label | null;
                name?: string | null;
                icon?: string | null;
                id: string;
                exchangeDetails?: Label | null;
              };
            } | null;
          };
        };
        debit: {
          __typename?: 'AssetValue';
          unitValue?: Decimal | null;
          amount: Decimal;
          asset: {
            __typename?: 'Asset';
            type: IAssetType;
            symbol: string;
            label?: Label | null;
            name?: string | null;
            icon?: string | null;
            id: string;
            exchangeDetails?: Label | null;
            derivativeDetails?: {
              __typename?: 'DerivativeDetails';
              exchange: Label;
              derivativeType: IDerivativeType;
              expirationDate?: string | null;
              optionType?: IOptionType | null;
              strikePrice?: Decimal | null;
              baseAsset: {
                __typename?: 'BaseAsset';
                type: IAssetType;
                symbol: string;
                label?: Label | null;
                name?: string | null;
                icon?: string | null;
                id: string;
                exchangeDetails?: Label | null;
              };
              quoteAsset: {
                __typename?: 'BaseAsset';
                type: IAssetType;
                symbol: string;
                label?: Label | null;
                name?: string | null;
                icon?: string | null;
                id: string;
                exchangeDetails?: Label | null;
              };
              settlementAsset: {
                __typename?: 'BaseAsset';
                type: IAssetType;
                symbol: string;
                label?: Label | null;
                name?: string | null;
                icon?: string | null;
                id: string;
                exchangeDetails?: Label | null;
              };
            } | null;
          };
        };
        fee?: {
          __typename?: 'AssetValue';
          unitValue?: Decimal | null;
          amount: Decimal;
          asset: {
            __typename?: 'Asset';
            type: IAssetType;
            symbol: string;
            label?: Label | null;
            name?: string | null;
            icon?: string | null;
            id: string;
            exchangeDetails?: Label | null;
            derivativeDetails?: {
              __typename?: 'DerivativeDetails';
              exchange: Label;
              derivativeType: IDerivativeType;
              expirationDate?: string | null;
              optionType?: IOptionType | null;
              strikePrice?: Decimal | null;
              baseAsset: {
                __typename?: 'BaseAsset';
                type: IAssetType;
                symbol: string;
                label?: Label | null;
                name?: string | null;
                icon?: string | null;
                id: string;
                exchangeDetails?: Label | null;
              };
              quoteAsset: {
                __typename?: 'BaseAsset';
                type: IAssetType;
                symbol: string;
                label?: Label | null;
                name?: string | null;
                icon?: string | null;
                id: string;
                exchangeDetails?: Label | null;
              };
              settlementAsset: {
                __typename?: 'BaseAsset';
                type: IAssetType;
                symbol: string;
                label?: Label | null;
                name?: string | null;
                icon?: string | null;
                id: string;
                exchangeDetails?: Label | null;
              };
            } | null;
          };
        } | null;
      }>;
    };
  };
  assets: {
    __typename?: 'Assets';
    list: Array<{
      __typename?: 'Asset';
      type: IAssetType;
      symbol: string;
      label?: Label | null;
      name?: string | null;
      icon?: string | null;
      id: string;
      exchangeDetails?: Label | null;
      derivativeDetails?: {
        __typename?: 'DerivativeDetails';
        exchange: Label;
        derivativeType: IDerivativeType;
        expirationDate?: string | null;
        optionType?: IOptionType | null;
        strikePrice?: Decimal | null;
        baseAsset: {
          __typename?: 'BaseAsset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
        };
        quoteAsset: {
          __typename?: 'BaseAsset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
        };
        settlementAsset: {
          __typename?: 'BaseAsset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
        };
      } | null;
    }>;
  };
};

export type IDownloadOrdersMutationVariables = Exact<{ [key: string]: never }>;

export type IDownloadOrdersMutation = {
  __typename?: 'Mutation';
  DownloadOrders: { __typename?: 'DownloadResponse'; token: string };
};

export type IOrderFilterInputQueryVariables = Exact<{ [key: string]: never }>;

export type IOrderFilterInputQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    accounts: Array<{
      __typename?: 'Account';
      id: string;
      name: string;
      subAccounts: Array<{ __typename?: 'SubAccount'; name: string; id: string }>;
      venue: { __typename?: 'DataSource'; label: Label };
    }>;
  };
};

export type IOrdersQueryVariables = Exact<{
  filters: IOrderFilters;
  pageLimit: IPageLimitOffsetInput;
  sort: ISortField;
}>;

export type IOrdersQuery = {
  __typename?: 'Query';
  bookkeeping: {
    __typename?: 'Bookkeeping';
    orders: {
      __typename?: 'OrderOffsetLimitPage';
      data: Array<{
        __typename?: 'Order';
        amount?: Decimal | null;
        price?: Decimal | null;
        average?: Decimal | null;
        creationTime?: string | null;
        filled?: Decimal | null;
        externalId: string;
        lastUpdateTime?: string | null;
        side: IOrderSide;
        status?: IOrderStatus | null;
        type: IOrderType;
        subAccount: {
          __typename?: 'SubAccount';
          id: string;
          name: string;
          account: {
            __typename?: 'Account';
            id: string;
            name: string;
            venue: { __typename?: 'DataSource'; label: Label };
          };
        };
        baseAsset?: {
          __typename?: 'Asset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
          derivativeDetails?: {
            __typename?: 'DerivativeDetails';
            exchange: Label;
            derivativeType: IDerivativeType;
            expirationDate?: string | null;
            optionType?: IOptionType | null;
            strikePrice?: Decimal | null;
            baseAsset: {
              __typename?: 'BaseAsset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
            };
            quoteAsset: {
              __typename?: 'BaseAsset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
            };
            settlementAsset: {
              __typename?: 'BaseAsset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
            };
          } | null;
        } | null;
        quoteAsset?: {
          __typename?: 'Asset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
          derivativeDetails?: {
            __typename?: 'DerivativeDetails';
            exchange: Label;
            derivativeType: IDerivativeType;
            expirationDate?: string | null;
            optionType?: IOptionType | null;
            strikePrice?: Decimal | null;
            baseAsset: {
              __typename?: 'BaseAsset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
            };
            quoteAsset: {
              __typename?: 'BaseAsset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
            };
            settlementAsset: {
              __typename?: 'BaseAsset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
            };
          } | null;
        } | null;
      }>;
      pageInfo: { __typename?: 'PageOffsetInfo'; totalResults: number };
    };
  };
};

export type IJournalErrorsQueryVariables = Exact<{ [key: string]: never }>;

export type IJournalErrorsQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    journal: {
      __typename?: 'Journal';
      oldestJournalAssetErrors: Array<{
        __typename?: 'JournalCostBasisError';
        time: string;
        amount: Decimal;
        remainingAmount: Decimal;
        error?: IJournalError | null;
        asset: {
          __typename?: 'Asset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
          derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
        };
        subFund?: { __typename?: 'SubFund'; id: number; name: string; dimension: string } | null;
      }>;
    };
  };
};

export type IReconciliationReportQueryVariables = Exact<{
  minUsdDiscrepancy?: InputMaybe<Scalars['Decimal']['input']>;
  dates: IDateRangeFilter;
  subAccountAssetFilters: ISubAccountAssetFilterInputType;
}>;

export type IReconciliationReportQuery = {
  __typename?: 'Query';
  bookkeeping: {
    __typename?: 'Bookkeeping';
    reconciliation: {
      __typename?: 'Reconciliation';
      report: Array<{
        __typename?: 'ReconciliationReportRow';
        side?: IPositionSide | null;
        startTime?: string | null;
        endTime: string;
        snapshotBalanceChangeAmount: Decimal;
        transactionBalanceChangeAmount: Decimal;
        assetClosingPriceUsd?: Decimal | null;
        asset: {
          __typename?: 'Asset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
          derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
        };
        subAccount: {
          __typename?: 'SubAccount';
          id: string;
          name: string;
          account: {
            __typename?: 'Account';
            id: string;
            name: string;
            venue: { __typename?: 'DataSource'; label: Label };
          };
        };
      }>;
    };
  };
};

export type IPortfolioReportQueryVariables = Exact<{
  since?: InputMaybe<Scalars['Date']['input']>;
  to?: InputMaybe<Scalars['Date']['input']>;
  subAccountAssetFilters: ISubAccountAssetFilterInputType;
}>;

export type IPortfolioReportQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    snapshot: Array<{
      __typename?: 'SubAccountSnapshot';
      date: UtcDate;
      positions: Array<{
        __typename?: 'SubAccountPositionSnapshot';
        subAccount: { __typename?: 'SubAccount'; id: string };
        asset: {
          __typename?: 'Asset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
          derivativeDetails?: {
            __typename?: 'DerivativeDetails';
            exchange: Label;
            derivativeType: IDerivativeType;
            expirationDate?: string | null;
            optionType?: IOptionType | null;
            strikePrice?: Decimal | null;
            baseAsset: {
              __typename?: 'BaseAsset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
            };
            quoteAsset: {
              __typename?: 'BaseAsset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
            };
            settlementAsset: {
              __typename?: 'BaseAsset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
            };
          } | null;
        };
        spot?: {
          __typename?: 'SubAccountPositionSpotSnapshot';
          amount: Decimal;
          price: Decimal;
          balance: Decimal;
          slot: ISlotType;
        } | null;
        derivative?: {
          __typename?: 'SubAccountPositionDerivativeSnapshot';
          amount: Decimal;
          notional?: Decimal | null;
          side: IPositionSide;
          unrealizedPnl?: Decimal | null;
          balance?: Decimal | null;
        } | null;
      }>;
    }>;
    subFunds: {
      __typename?: 'SubFunds';
      list: Array<{
        __typename?: 'SubFund';
        id: number;
        name: string;
        dimension: string;
        subAccounts: Array<{ __typename?: 'SubAccount'; id: string }>;
        accounts: Array<{
          __typename?: 'Account';
          id: string;
          subAccounts: Array<{ __typename?: 'SubAccount'; id: string }>;
        }>;
      }>;
    };
    accounts: Array<{
      __typename?: 'Account';
      id: string;
      name: string;
      venue: { __typename?: 'DataSource'; label: Label };
      subAccounts: Array<{ __typename?: 'SubAccount'; name: string; id: string }>;
    }>;
  };
};

export type ITradingActivityReportQueryVariables = Exact<{
  date?: InputMaybe<IDateRangeFilter>;
  subAccountAssetFilters?: InputMaybe<ISubAccountAssetFilterInputType>;
}>;

export type ITradingActivityReportQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    subFunds: {
      __typename?: 'SubFunds';
      list: Array<{
        __typename?: 'SubFund';
        id: number;
        name: string;
        dimension: string;
        subAccounts: Array<{ __typename?: 'SubAccount'; id: string }>;
        accounts: Array<{
          __typename?: 'Account';
          id: string;
          subAccounts: Array<{ __typename?: 'SubAccount'; id: string }>;
        }>;
      }>;
    };
  };
  bookkeeping: {
    __typename?: 'Bookkeeping';
    tradingActivity: Array<{
      __typename?: 'TradingActivity';
      day: UtcDate;
      userType: IUserTransactionType;
      price: Decimal;
      amount: Decimal;
      value: Decimal;
      state: ISlotType;
      legCount: number;
      side?: IPositionSide | null;
      legType: ITransactionLegType;
      subAccount: {
        __typename?: 'SubAccount';
        id: string;
        name: string;
        account: {
          __typename?: 'Account';
          id: string;
          name: string;
          venue: { __typename?: 'DataSource'; label: Label };
        };
      };
      asset: {
        __typename?: 'Asset';
        type: IAssetType;
        symbol: string;
        label?: Label | null;
        name?: string | null;
        icon?: string | null;
        id: string;
        exchangeDetails?: Label | null;
        derivativeDetails?: {
          __typename?: 'DerivativeDetails';
          exchange: Label;
          baseAsset: { __typename?: 'BaseAsset'; symbol: string };
        } | null;
      };
      attributedToAssetId: { __typename?: 'Asset'; symbol: string };
    }>;
  };
};

export type IDeleteTransactionMutationVariables = Exact<{
  input: Scalars['UUID']['input'];
}>;

export type IDeleteTransactionMutation = { __typename?: 'Mutation'; DeleteTransactionV2?: any | null };

export type IDownloadTransactionsMutationVariables = Exact<{
  filters: ITransactionFilters;
  orderAscendingByTime: Scalars['Boolean']['input'];
}>;

export type IDownloadTransactionsMutation = {
  __typename?: 'Mutation';
  DownloadTransactionsV2: { __typename?: 'DownloadResponse'; token: string };
};

export type IPrecisePriceQueryVariables = Exact<{
  assetId: Scalars['UUID']['input'];
  time: Scalars['ArrowType']['input'];
}>;

export type IPrecisePriceQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'Assets';
    precisePrice?: { __typename?: 'PreciseAssetPrice'; price: Decimal; time: string } | null;
  };
};

export type ITransactionFilterInputQueryVariables = Exact<{ [key: string]: never }>;

export type ITransactionFilterInputQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    accounts: Array<{
      __typename?: 'Account';
      id: string;
      name: string;
      subAccounts: Array<{ __typename?: 'SubAccount'; name: string; id: string }>;
      venue: { __typename?: 'DataSource'; label: Label };
    }>;
  };
  bookkeeping: { __typename?: 'Bookkeeping'; transactionTags: Array<string> };
};

export type ITransactionsQueryVariables = Exact<{
  filters: ITransactionFilters;
  cursor: ICursorInput;
  orderAscendingByTime: Scalars['Boolean']['input'];
}>;

export type ITransactionsQuery = {
  __typename?: 'Query';
  bookkeeping: {
    __typename?: 'Bookkeeping';
    transactionsV2: {
      __typename?: 'TransactionV2CursorPage';
      data: Array<{
        __typename?: 'TransactionV2';
        id: string;
        deduplicationId?: string | null;
        tags: Array<string>;
        externalType?: string | null;
        comment?: string | null;
        status: ITransactionStatus;
        userType: IUserTransactionType;
        externalId?: string | null;
        time: string;
        attributedToAsset?: {
          __typename?: 'Asset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
          derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
        } | null;
        subAccount: {
          __typename?: 'SubAccount';
          id: string;
          name: string;
          account: {
            __typename?: 'Account';
            id: string;
            name: string;
            venue: { __typename?: 'DataSource'; label: Label };
          };
        };
        order?: { __typename?: 'Order'; type: IOrderType; externalId: string; side: IOrderSide } | null;
        legs: Array<{
          __typename?: 'TransactionLeg';
          id: string;
          type: ITransactionLegType;
          time: string;
          unitValue?: Decimal | null;
          amount: Decimal;
          slot: ISlotType;
          side?: IPositionSide | null;
          value?: Decimal | null;
          asset: {
            __typename?: 'Asset';
            type: IAssetType;
            symbol: string;
            label?: Label | null;
            name?: string | null;
            icon?: string | null;
            id: string;
            exchangeDetails?: Label | null;
            derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
          };
          journalEntry?: {
            __typename?: 'JournalEntryPnlV2';
            error?: IJournalError | null;
            entryType: IJournalEntryType;
            costBasis?: Decimal | null;
            remainingAmount: Decimal;
            amount: Decimal;
            asset: {
              __typename?: 'Asset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
              derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
            };
          } | null;
        }>;
      }>;
      cursors: {
        __typename?: 'Cursors';
        first?: string | null;
        last?: string | null;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
      };
    };
  };
};

export type IUpdateTransactionMutationVariables = Exact<{
  input: IUpdateTransactionV2InputType;
}>;

export type IUpdateTransactionMutation = {
  __typename?: 'Mutation';
  UpdateTransactionV2: { __typename?: 'TransactionV2'; id: string };
};

export type ICreateTransactionMutationVariables = Exact<{
  input: ICreateTransactionV2InputType;
}>;

export type ICreateTransactionMutation = {
  __typename?: 'Mutation';
  CreateTransactionV2: { __typename?: 'TransactionV2'; id: string };
};

export type IAgentListQueryVariables = Exact<{ [key: string]: never }>;

export type IAgentListQuery = {
  __typename?: 'Query';
  news: {
    __typename?: 'NewsType';
    agents: {
      __typename?: 'Agents';
      agents: Array<{ __typename?: 'Agent'; id: string; name: string; description: string; icon: string }>;
    };
  };
};

export type IGetMessageUpdateQueryVariables = Exact<{
  messageId: Scalars['UUID']['input'];
}>;

export type IGetMessageUpdateQuery = {
  __typename?: 'Query';
  news: {
    __typename?: 'NewsType';
    agents: {
      __typename?: 'Agents';
      message: {
        __typename?: 'Message';
        id: string;
        createdAt: string;
        content: string;
        role: IMessageRoleType;
        agentId?: string | null;
        agentResult?: {
          __typename?: 'AgentResult';
          status: IAgentResultStatusType;
          code?: string | null;
          type?: IAgentResultType | null;
          value?: any | null;
        } | null;
        reaction?: { __typename?: 'MessageReaction'; reaction: IReactionType } | null;
      };
    };
  };
};

export type IMessageHistoryQueryVariables = Exact<{
  conversationId: Scalars['UUID']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
}>;

export type IMessageHistoryQuery = {
  __typename?: 'Query';
  news: {
    __typename?: 'NewsType';
    agents: {
      __typename?: 'Agents';
      messages: Array<{
        __typename?: 'Message';
        id: string;
        role: IMessageRoleType;
        content: string;
        agentId?: string | null;
        createdAt: string;
        agentResult?: {
          __typename?: 'AgentResult';
          value?: any | null;
          type?: IAgentResultType | null;
          code?: string | null;
          status: IAgentResultStatusType;
        } | null;
        reaction?: { __typename?: 'MessageReaction'; reaction: IReactionType } | null;
      }>;
    };
  };
};

export type ISendMessageMutationVariables = Exact<{
  input: IMessageInput;
}>;

export type ISendMessageMutation = {
  __typename?: 'Mutation';
  SendMessage: {
    __typename?: 'SendMessageResult';
    userMessage: { __typename?: 'Message'; id: string; createdAt: string };
    agentMessage: { __typename?: 'Message'; id: string; agentId?: string | null; createdAt: string };
  };
};

export type IUpdateReactionMutationVariables = Exact<{
  input: IMessageReactionInput;
}>;

export type IUpdateReactionMutation = { __typename?: 'Mutation'; UpdateMessageReaction?: any | null };

export type IConversationHistoryQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
}>;

export type IConversationHistoryQuery = {
  __typename?: 'Query';
  news: {
    __typename?: 'NewsType';
    agents: {
      __typename?: 'Agents';
      conversations: Array<{ __typename?: 'Conversation'; id: string; title: string; updatedAt: string }>;
    };
  };
};

export type IDeleteConversationMutationVariables = Exact<{
  convId: Scalars['UUID']['input'];
}>;

export type IDeleteConversationMutation = { __typename?: 'Mutation'; DeleteConversation?: any | null };

export type IFactorRegressionQueryVariables = Exact<{
  input: IRegressionServiceInputV2;
}>;

export type IFactorRegressionQuery = {
  __typename?: 'Query';
  factorRegressionV2: {
    __typename?: 'FactorRouterTypeV2';
    portfolioFactorRegression: Array<{
      __typename?: 'FactorRequestResultV2';
      message?: Array<string> | null;
      code: IRequestResultCodeV2;
      portfolioDefinition: { __typename?: 'PortfolioDefinition'; id: string; name: string };
      results: Array<{
        __typename?: 'RegressionResultV2';
        date: UtcDate;
        fValue?: number | null;
        rSquared?: number | null;
        alphaValue?: number | null;
        results: Array<{
          __typename?: 'FactorResultV2';
          beta?: number | null;
          pValue?: number | null;
          stdErr?: number | null;
          factor?: { __typename?: 'Asset'; id: string; symbol: string } | null;
          confidenceIntervals: Array<{ __typename?: 'ConfidenceIntervalsV2'; name: string; value?: number | null }>;
        }>;
        alpha?: {
          __typename?: 'FactorResultV2';
          beta?: number | null;
          pValue?: number | null;
          stdErr?: number | null;
          factor?: { __typename?: 'Asset'; id: string; symbol: string } | null;
          confidenceIntervals: Array<{ __typename?: 'ConfidenceIntervalsV2'; name: string; value?: number | null }>;
        } | null;
      }>;
    }>;
  };
};

export type IFactorRegressionInputQueryVariables = Exact<{ [key: string]: never }>;

export type IFactorRegressionInputQuery = {
  __typename?: 'Query';
  factorRegressionV2: {
    __typename?: 'FactorRouterTypeV2';
    supportedFactors: Array<{
      __typename?: 'SupportedFactorV2';
      type: IFactorCategoryV2;
      asset: {
        __typename?: 'Asset';
        type: IAssetType;
        symbol: string;
        label?: Label | null;
        name?: string | null;
        icon?: string | null;
        id: string;
        exchangeDetails?: Label | null;
        derivativeDetails?: {
          __typename?: 'DerivativeDetails';
          exchange: Label;
          derivativeType: IDerivativeType;
          expirationDate?: string | null;
          optionType?: IOptionType | null;
          strikePrice?: Decimal | null;
          baseAsset: {
            __typename?: 'BaseAsset';
            type: IAssetType;
            symbol: string;
            label?: Label | null;
            name?: string | null;
            icon?: string | null;
            id: string;
            exchangeDetails?: Label | null;
          };
          quoteAsset: {
            __typename?: 'BaseAsset';
            type: IAssetType;
            symbol: string;
            label?: Label | null;
            name?: string | null;
            icon?: string | null;
            id: string;
            exchangeDetails?: Label | null;
          };
          settlementAsset: {
            __typename?: 'BaseAsset';
            type: IAssetType;
            symbol: string;
            label?: Label | null;
            name?: string | null;
            icon?: string | null;
            id: string;
            exchangeDetails?: Label | null;
          };
        } | null;
      };
    }>;
    factorPresets: Array<{
      __typename?: 'FactorPresetV2';
      name: string;
      description: string;
      factors: Array<{
        __typename?: 'SupportedFactorV2';
        type: IFactorCategoryV2;
        asset: {
          __typename?: 'Asset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
          derivativeDetails?: {
            __typename?: 'DerivativeDetails';
            exchange: Label;
            derivativeType: IDerivativeType;
            expirationDate?: string | null;
            optionType?: IOptionType | null;
            strikePrice?: Decimal | null;
            baseAsset: {
              __typename?: 'BaseAsset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
            };
            quoteAsset: {
              __typename?: 'BaseAsset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
            };
            settlementAsset: {
              __typename?: 'BaseAsset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
            };
          } | null;
        };
      }>;
    }>;
  };
};

export type IPortfolioBacktestingInputQueryVariables = Exact<{ [key: string]: never }>;

export type IPortfolioBacktestingInputQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    definitions: Array<{
      __typename?: 'PortfolioDefinition';
      id: string;
      name: string;
      description: string;
      type: IPortfolioDefinitionType;
      genie: boolean;
      createdFrom?: {
        __typename?: 'PortfolioRebalancingRule';
        name: string;
        portfolioDefinition: { __typename?: 'PortfolioDefinition'; id: string; name: string };
      } | null;
      dateRange?: { __typename?: 'PortfolioDefinitionDateRange'; since?: UtcDate | null; to?: UtcDate | null } | null;
    }>;
    supportedRegimeDefinitions: Array<{
      __typename?: 'RegimeDefinitions';
      id: string;
      name: string;
      regimes: Array<string>;
    }>;
  };
  assets: {
    __typename?: 'Assets';
    assetGroups: {
      __typename?: 'AssetGroups';
      userGroups: Array<{
        __typename?: 'AssetGroup';
        clusterName: string;
        groupName: string;
        assets: Array<{ __typename?: 'Asset'; symbol: string; id: string }>;
      }>;
      genieGroups: Array<{
        __typename?: 'AssetGroup';
        clusterName: string;
        groupName: string;
        assets: Array<{ __typename?: 'Asset'; symbol: string; id: string }>;
      }>;
    };
  };
};

export type IPortfolioCorrelationsQueryVariables = Exact<{
  input: IPortfolioCorrelationInputType;
}>;

export type IPortfolioCorrelationsQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    portfolioCorrelations: Array<{
      __typename?: 'PortfolioCorrelation';
      correlations: Array<{ __typename?: 'CorrelationDailyValue'; date: UtcDate; value: number }>;
      portfolioPair: {
        __typename?: 'PortfolioPair';
        portfolioA: { __typename?: 'PortfolioDefinition'; id: string; name: string };
        portfolioB: { __typename?: 'PortfolioDefinition'; id: string; name: string };
      };
    }>;
  };
};

export type IPortfolioDetailsQueryVariables = Exact<{
  portfolioIds: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
  since: Scalars['Date']['input'];
  to?: InputMaybe<Scalars['Date']['input']>;
}>;

export type IPortfolioDetailsQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    analysis: Array<{
      __typename?: 'PortfolioAnalysis';
      cashAssetIds: Array<string>;
      allocations: Array<{
        __typename?: 'PortfolioAllocations';
        date: UtcDate;
        assetAllocation: Array<{
          __typename?: 'PortfolioAssetAllocation';
          value: number;
          asset: { __typename?: 'Asset'; symbol: string; id: string };
        }>;
      }>;
      returns: Array<{ __typename?: 'PortfolioReturns'; date: UtcDate; portfolioWeightedReturn: number }>;
      portfolioDefinition: { __typename?: 'PortfolioDefinition'; id: string; name: string };
    }>;
  };
};

export type IPortfolioDetailsCommentaryQueryVariables = Exact<{
  input: ISummaryInput;
}>;

export type IPortfolioDetailsCommentaryQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    portfolioSummary: Array<{
      __typename?: 'SummaryParagraph';
      content: string;
      index: number;
      title: string;
      portfolio?: { __typename?: 'PortfolioDefinition'; id: string } | null;
    }>;
  };
};

export type IPortfolioRegimeQueryVariables = Exact<{ [key: string]: never }>;

export type IPortfolioRegimeQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    supportedRegimeDefinitions: Array<{
      __typename?: 'RegimeDefinitions';
      id: string;
      name: string;
      regimes: Array<string>;
    }>;
  };
};

export type IPortfolioRegimeResultQueryVariables = Exact<{
  input: IRegimeRiskInput;
}>;

export type IPortfolioRegimeResultQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    regimeRisk: Array<{
      __typename?: 'PortfolioRegimeOutput';
      portfolio: { __typename?: 'PortfolioDefinition'; name: string; id: string };
      data: Array<{
        __typename?: 'RegimeData';
        message?: string | null;
        regimeName: string;
        returns: Array<number>;
        metrics: Array<{
          __typename?: 'RegimeRiskMetric';
          format: IFormat;
          metricName: string;
          metricValue: number;
          benchmark?: { __typename?: 'Asset'; symbol: string; id: string } | null;
        }>;
      }>;
    }>;
  };
};

export type IPortfolioRiskInputQueryVariables = Exact<{ [key: string]: never }>;

export type IPortfolioRiskInputQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    metricParameters: Array<{
      __typename?: 'PortfolioMetricDescription';
      label: Label;
      parameters: Array<{
        __typename?: 'ParameterDescription';
        name: string;
        type: IParameterType;
        minMax?: { __typename?: 'MinMaxValue'; default: number; max: number; min: number } | null;
        options?: Array<{ __typename?: 'OptionValue'; id: string; label: string; value: string }> | null;
        visibleIf?: Array<{
          __typename?: 'ParameterVisibilityPredicate';
          parameter: string;
          selectedOption: string;
        }> | null;
        benchmarks?: Array<{
          __typename?: 'Asset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
          derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
        }> | null;
      }>;
    }>;
  };
};

export type IDeletePortfolioDefinitionMutationVariables = Exact<{
  input: Scalars['UUID']['input'];
}>;

export type IDeletePortfolioDefinitionMutation = {
  __typename?: 'Mutation';
  portfolio: { __typename?: 'PortfolioMutation'; deleteDefinition?: any | null };
};

export type IPortfolioDefListInputQueryVariables = Exact<{ [key: string]: never }>;

export type IPortfolioDefListInputQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    definitions: Array<{
      __typename?: 'PortfolioDefinition';
      id: string;
      name: string;
      description: string;
      type: IPortfolioDefinitionType;
      subType: IPortfolioDefinitionSubType;
      createdAt: string;
      transactionTag?: string | null;
      genie: boolean;
      realDefAccounts?: Array<{
        __typename?: 'Account';
        id: string;
        name: string;
        subAccounts: Array<{ __typename?: 'SubAccount'; name: string; id: string }>;
        venue: { __typename?: 'DataSource'; label: Label };
      }> | null;
      realDefSubFunds?: Array<{ __typename?: 'SubFund'; id: number; name: string; dimension: string }> | null;
      assets: Array<{
        __typename?: 'Asset';
        type: IAssetType;
        symbol: string;
        label?: Label | null;
        name?: string | null;
        icon?: string | null;
        id: string;
        exchangeDetails?: Label | null;
        derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
      }>;
    }>;
    accounts: Array<{
      __typename?: 'Account';
      id: string;
      name: string;
      subAccounts: Array<{ __typename?: 'SubAccount'; name: string; id: string }>;
      venue: { __typename?: 'DataSource'; label: Label };
    }>;
    subFunds: {
      __typename?: 'SubFunds';
      list: Array<{ __typename?: 'SubFund'; id: number; name: string; dimension: string }>;
    };
  };
};

export type IPortfolioDefNameQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type IPortfolioDefNameQuery = {
  __typename?: 'Query';
  portfolio: { __typename?: 'Portfolio'; definition: { __typename?: 'PortfolioDefinition'; id: string; name: string } };
};

export type ICreatePortfolioDefinitionMutationVariables = Exact<{
  input: ICreatePortfolioDefInput;
}>;

export type ICreatePortfolioDefinitionMutation = {
  __typename?: 'Mutation';
  portfolio: { __typename?: 'PortfolioMutation'; createDefinition: string };
};

export type ICreateTransactionBasedPortfolioDialogInputQueryVariables = Exact<{ [key: string]: never }>;

export type ICreateTransactionBasedPortfolioDialogInputQuery = {
  __typename?: 'Query';
  bookkeeping: { __typename?: 'Bookkeeping'; transactionTags: Array<string> };
};

export type IRecomputePortfolioMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type IRecomputePortfolioMutation = {
  __typename?: 'Mutation';
  portfolio: { __typename?: 'PortfolioMutation'; recomputePortfolio?: any | null };
};

export type IUploadRebalancePortfolioReturnsMutationVariables = Exact<{
  input: Array<ICreateReturnsBasedPortfolioInput> | ICreateReturnsBasedPortfolioInput;
}>;

export type IUploadRebalancePortfolioReturnsMutation = {
  __typename?: 'Mutation';
  portfolio: { __typename?: 'PortfolioMutation'; createReturnsBasedPortfolio?: any | null };
};

export type IImportPositionsMutationVariables = Exact<{
  remoteFileId: Scalars['UUID']['input'];
  rebalancedPortfolioId: Scalars['UUID']['input'];
}>;

export type IImportPositionsMutation = {
  __typename?: 'Mutation';
  portfolio: { __typename?: 'PortfolioMutation'; importPortfolioFromFile: boolean };
};

export type IImportPositionsHistoryQueryVariables = Exact<{
  portfolioDefinitionId: Scalars['UUID']['input'];
}>;

export type IImportPositionsHistoryQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    imports: Array<{
      __typename?: 'PortfolioImportStatus';
      status: IProcessStatus;
      remoteFile: { __typename?: 'FileUpload'; uploadTime: string };
      result?: {
        __typename?: 'PortfolioImportStatusProcessResult';
        processedLines: number;
        totalErrors: number;
        errors: Array<{ __typename?: 'PortfolioImportStatusProcessResultError'; message: string; lineNo: number }>;
      } | null;
    }>;
  };
};

export type ICreateRebalancingRuleMutationVariables = Exact<{
  input: ICreateRebalancingRuleInput;
}>;

export type ICreateRebalancingRuleMutation = {
  __typename?: 'Mutation';
  portfolio: { __typename?: 'PortfolioMutation'; createRebalancingRule?: any | null };
};

export type ICreateRebalancingRuleInputQueryVariables = Exact<{
  portfolioDefinitionId: Scalars['UUID']['input'];
}>;

export type ICreateRebalancingRuleInputQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    definition: { __typename?: 'PortfolioDefinition'; id: string; sinceForRebalancedPortfolio?: UtcDate | null };
  };
};

export type IDeleteRebalancingRuleMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type IDeleteRebalancingRuleMutation = {
  __typename?: 'Mutation';
  portfolio: { __typename?: 'PortfolioMutation'; deleteRebalancingRule?: any | null };
};

export type IRebalancingRulesQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type IRebalancingRulesQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    definition: {
      __typename?: 'PortfolioDefinition';
      genie: boolean;
      type: IPortfolioDefinitionType;
      rebalancingRules: Array<{
        __typename?: 'PortfolioRebalancingRule';
        id: string;
        name: string;
        start: UtcDate;
        end?: UtcDate | null;
        periodUnit?: IPeriodUnit | null;
        periodValue?: number | null;
        tradingFee: Decimal;
        maxRebalancingCost?: Decimal | null;
      }>;
    };
  };
};

export type IUpdatePortfolioDefinitionMutationVariables = Exact<{
  input: IUpdatePortfolioDefInput;
}>;

export type IUpdatePortfolioDefinitionMutation = {
  __typename?: 'Mutation';
  portfolio: { __typename?: 'PortfolioMutation'; updateDefinition?: any | null };
};

export type IConfirmNotificationDeliveryMutationVariables = Exact<{
  acknowledgedPlacedAt: Scalars['ArrowType']['input'];
}>;

export type IConfirmNotificationDeliveryMutation = {
  __typename?: 'Mutation';
  ConfirmNotificationDelivery?: any | null;
};

export type IMarkNotificationsAsReadMutationVariables = Exact<{
  acknowledgedPlacedAt: Scalars['ArrowType']['input'];
}>;

export type IMarkNotificationsAsReadMutation = { __typename?: 'Mutation'; MarkNotificationsAsRead?: any | null };

export type INotificationSubscriptionsQueryVariables = Exact<{ [key: string]: never }>;

export type INotificationSubscriptionsQuery = {
  __typename?: 'Query';
  notification: {
    __typename?: 'Notification';
    subscription: Array<{ __typename?: 'UserSubscription'; category: string }>;
  };
};

export type INotificationsQueryVariables = Exact<{
  pageLimit: IPageLimitOffsetInput;
  status?: InputMaybe<INotificationStatus>;
}>;

export type INotificationsQuery = {
  __typename?: 'Query';
  notification: {
    __typename?: 'Notification';
    list: {
      __typename?: 'UserNotificationOffsetLimitPage';
      data: Array<{
        __typename?: 'UserNotification';
        title: string;
        body: string;
        status: INotificationStatus;
        type: string;
        placedAt: string;
        requestId: string;
      }>;
      pageInfo: { __typename?: 'PageOffsetInfo'; totalResults: number };
    };
  };
};

export type IUpdateNotificationStatusMutationVariables = Exact<{
  requestId: Scalars['UUID']['input'];
  status: INotificationStatus;
}>;

export type IUpdateNotificationStatusMutation = { __typename?: 'Mutation'; UpdateNotificationStatus?: any | null };

export type IUpdateNotificationSubscriptionsMutationVariables = Exact<{
  categories: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type IUpdateNotificationSubscriptionsMutation = { __typename?: 'Mutation'; UpdateSubscriptions?: any | null };

export type IEvaluateOptionsPortfolioQueryVariables = Exact<{
  input: IScenarioAnalysisInputType;
}>;

export type IEvaluateOptionsPortfolioQuery = {
  __typename?: 'Query';
  optionsAnalysis: {
    __typename?: 'OptionsAnalysis';
    scenarioAnalysis: {
      __typename?: 'ScenarioAnalysisResult';
      referenceValue: {
        __typename?: 'PortfolioValue';
        marketPnl?: number | null;
        npvPnl: number;
        greeks: { __typename?: 'Greeks'; delta: Decimal; gamma: Decimal; rho: Decimal; theta: Decimal; vega: Decimal };
      };
      simulatedResults: Array<{
        __typename?: 'SingleScenarioAnalysisResult';
        parameterChange: { __typename?: 'ParameterChange'; price: number; riskFree: number; volatility: number };
        portfolioValue: {
          __typename?: 'PortfolioValue';
          npvPnl: number;
          marketPnl?: number | null;
          greeks: {
            __typename?: 'Greeks';
            vega: Decimal;
            theta: Decimal;
            rho: Decimal;
            gamma: Decimal;
            delta: Decimal;
          };
        };
      }>;
    };
  };
};

export type IOptionsStressTestInputQueryVariables = Exact<{
  expirationSince: Scalars['ArrowType']['input'];
  baseAssetId: Scalars['UUID']['input'];
}>;

export type IOptionsStressTestInputQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'Assets';
    list: Array<{
      __typename?: 'Asset';
      symbol: string;
      id: string;
      derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
    }>;
  };
};

export type IEvaluatePortfolioQueryVariables = Exact<{
  input: IFineTunerInput;
}>;

export type IEvaluatePortfolioQuery = {
  __typename?: 'Query';
  portfolioEvaluation: {
    __typename?: 'PortfolioEvaluation';
    portfolioEvaluation: {
      __typename?: 'FineTunerOutput';
      riskMetrics: Array<{
        __typename?: 'AssetRiskMeasureType';
        measure: Label;
        value: number;
        benchmark?: {
          __typename?: 'Asset';
          id: string;
          icon?: string | null;
          label?: Label | null;
          name?: string | null;
          symbol: string;
          type: IAssetType;
          exchangeDetails?: Label | null;
        } | null;
      }>;
    };
  };
};

export type IPortfolioBuilderInputQueryVariables = Exact<{ [key: string]: never }>;

export type IPortfolioBuilderInputQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'Assets';
    feature: Array<{
      __typename?: 'Asset';
      type: IAssetType;
      symbol: string;
      label?: Label | null;
      name?: string | null;
      icon?: string | null;
      id: string;
      exchangeDetails?: Label | null;
      derivativeDetails?: {
        __typename?: 'DerivativeDetails';
        exchange: Label;
        derivativeType: IDerivativeType;
        expirationDate?: string | null;
        optionType?: IOptionType | null;
        strikePrice?: Decimal | null;
        baseAsset: {
          __typename?: 'BaseAsset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
        };
        quoteAsset: {
          __typename?: 'BaseAsset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
        };
        settlementAsset: {
          __typename?: 'BaseAsset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
        };
      } | null;
    }>;
    assetGroups: {
      __typename?: 'AssetGroups';
      genieGroups: Array<{
        __typename?: 'AssetGroup';
        clusterName: string;
        groupName: string;
        assets: Array<{ __typename?: 'Asset'; id: string }>;
      }>;
      userGroups: Array<{
        __typename?: 'AssetGroup';
        clusterName: string;
        groupName: string;
        assets: Array<{ __typename?: 'Asset'; id: string }>;
      }>;
    };
  };
  portfolio: {
    __typename?: 'Portfolio';
    positions: {
      __typename?: 'SubAccountPositions';
      positions: Array<{
        __typename?: 'SubAccountPositionSnapshot';
        asset: {
          __typename?: 'Asset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
          derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
        };
        spot?: {
          __typename?: 'SubAccountPositionSpotSnapshot';
          price: Decimal;
          amount: Decimal;
          balance: Decimal;
        } | null;
      }>;
    };
  };
};

export type IAssetOptimizationNameQueryVariables = Exact<{
  optimizationId: Scalars['Int']['input'];
}>;

export type IAssetOptimizationNameQuery = {
  __typename?: 'Query';
  portfolioOptimization: {
    __typename?: 'PortfolioOptimization';
    getOptimization: { __typename?: 'PortfolioOptimizationResult'; name: string };
  };
};

export type IPortfolioOptimizationListQueryVariables = Exact<{ [key: string]: never }>;

export type IPortfolioOptimizationListQuery = {
  __typename?: 'Query';
  portfolioOptimization: {
    __typename?: 'PortfolioOptimization';
    listOptimizations: Array<{
      __typename?: 'PortfolioOptimizationResult';
      optimizationId: number;
      name: string;
      description: string;
      status: IPortfolioOptimizationResultStatusType;
      createdAt: string;
      updatedAt?: string | null;
    }>;
  };
  yieldOptimization: {
    __typename?: 'YieldOptimization';
    listOptimizations: Array<{
      __typename?: 'MultiAssetYieldAnalysisType';
      optimizationId: number;
      name: string;
      description: string;
      status: IPortfolioOptimizationResultStatusType;
      createdAt: string;
      updatedAt?: string | null;
    }>;
  };
  fundOptimization: {
    __typename?: 'FundOptimization';
    listOptimizations: Array<{
      __typename?: 'FundOptimizationResult';
      optimizationId: number;
      name: string;
      description: string;
      status: IPortfolioOptimizationResultStatusType;
      createdAt: string;
      updatedAt?: string | null;
    }>;
  };
};

export type IPortfolioOptimizationNameQueryVariables = Exact<{
  optimizationId: Scalars['Int']['input'];
}>;

export type IPortfolioOptimizationNameQuery = {
  __typename?: 'Query';
  fundOptimization: {
    __typename?: 'FundOptimization';
    getOptimization: { __typename?: 'FundOptimizationResult'; name: string };
  };
};

export type IYieldOptimizationNameQueryVariables = Exact<{
  optimizationId: Scalars['Int']['input'];
}>;

export type IYieldOptimizationNameQuery = {
  __typename?: 'Query';
  yieldOptimization: {
    __typename?: 'YieldOptimization';
    getMultiAssetOptimization: { __typename?: 'MultiAssetYieldAnalysisType'; name: string };
  };
};

export type IAssetOptimizerResultQueryVariables = Exact<{
  optimizationId: Scalars['Int']['input'];
}>;

export type IAssetOptimizerResultQuery = {
  __typename?: 'Query';
  optimization: {
    __typename?: 'PortfolioOptimization';
    getOptimizationInput: {
      __typename?: 'PortfolioOptimizationUserInput';
      portfolioAmount: number;
      leverageType: ILeverageType;
      constraintFormulation: IConstraintFormulation;
      returnMeasureName?: IReturnMeasureNameUi | null;
      constraints: Array<{
        __typename?: 'ConstrainedAsset';
        userExpectedReturn?: number | null;
        riskBudget?: number | null;
        maxLeverage?: number | null;
        expectedYield?: number | null;
        asset: {
          __typename?: 'Asset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
          derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
        };
        constraint?: { __typename?: 'Constraint'; upperBound: number; lowerBound: number } | null;
      }>;
      portfolioConstraints?: {
        __typename?: 'PortfolioConstraints';
        primary?: {
          __typename?: 'PrimaryPortfolioConstraintType';
          constrainedQuantity: IPrimaryConstrainedQuantity;
          constraint: { __typename?: 'Constraint'; lowerBound: number; upperBound: number };
          riskMetric?: {
            __typename?: 'RiskMetric';
            metricLabel: Label;
            benchmark?: { __typename?: 'Asset'; id: string; symbol: string } | null;
          } | null;
        } | null;
        secondary?: {
          __typename?: 'SecondaryPortfolioConstraint';
          constrainedQuantity: ISecondaryConstrainedQuantity;
          constraint: { __typename?: 'Constraint'; lowerBound: number; upperBound: number };
          riskMetric?: {
            __typename?: 'RiskMetric';
            metricLabel: Label;
            benchmark?: {
              __typename?: 'Asset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
              derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
            } | null;
          } | null;
        } | null;
      } | null;
      givenPortfolio: Array<{
        __typename?: 'GivenPortfolioAsset';
        cashWeight: number;
        asset: {
          __typename?: 'Asset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
          derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
        };
      }>;
      groupConstraints: Array<{
        __typename?: 'GroupConstraint';
        group: { __typename?: 'AssetGroup'; groupName: string; clusterName: string };
        allocationConstraints: Array<{
          __typename?: 'AllocationConstraintType';
          constrainedQuantity: IGroupConstrainedQuantity;
          constraint: { __typename?: 'Constraint'; lowerBound: number; upperBound: number };
        }>;
      }>;
    };
    getOptimization: {
      __typename?: 'PortfolioOptimizationResult';
      optimizationId: number;
      status: IPortfolioOptimizationResultStatusType;
      name: string;
      description: string;
      objectives: Array<{
        __typename?: 'PortfolioOptimizationObjective';
        objectiveType: IObjectiveType;
        risk: Label;
        benchmark?: {
          __typename?: 'Asset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
          derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
        } | null;
      }>;
      givenPortfolioOutput?: {
        __typename?: 'PortfolioOutput';
        nonDominated: boolean;
        suggested: boolean;
        solutionId: number;
        status?: IOptimizationResultStatus | null;
        statusMessage?: string | null;
        riskMeasures: Array<{
          __typename?: 'AssetRiskMeasureType';
          measure: Label;
          value: number;
          benchmark?: {
            __typename?: 'Asset';
            id: string;
            name?: string | null;
            symbol: string;
            type: IAssetType;
          } | null;
        }>;
        objectiveValues: Array<{ __typename?: 'PortfolioObjectiveValue'; value: number }>;
        portfolioExposures: Array<{
          __typename?: 'PortfolioExposure';
          name: IPortfolioExposureStatName;
          amount: number;
          percentage: number;
        }>;
        groupExposures: Array<{
          __typename?: 'GroupExposure';
          group: { __typename?: 'AssetGroup'; id: string; clusterName: string; groupName: string };
          exposures: Array<{
            __typename?: 'GroupExposureValues';
            name: IGroupExposureStatName;
            amount: number;
            percentage: number;
          }>;
        }>;
        returnsPredictions: {
          __typename?: 'ReturnsPredictions';
          returns: Array<number>;
          returnStatistics: Array<{ __typename?: 'ReturnStatistics'; statName: IReturnStatName; statValue: number }>;
        };
        measures: Array<{
          __typename?: 'AssetMeasure';
          cashWeight: number;
          amount: number;
          leverage: number;
          item: {
            __typename?: 'Asset';
            type: IAssetType;
            symbol: string;
            label?: Label | null;
            name?: string | null;
            icon?: string | null;
            id: string;
            exchangeDetails?: Label | null;
            derivativeDetails?: {
              __typename?: 'DerivativeDetails';
              exchange: Label;
              derivativeType: IDerivativeType;
              expirationDate?: string | null;
              optionType?: IOptionType | null;
              strikePrice?: Decimal | null;
              baseAsset: {
                __typename?: 'BaseAsset';
                type: IAssetType;
                symbol: string;
                label?: Label | null;
                name?: string | null;
                icon?: string | null;
                id: string;
                exchangeDetails?: Label | null;
              };
              quoteAsset: {
                __typename?: 'BaseAsset';
                type: IAssetType;
                symbol: string;
                label?: Label | null;
                name?: string | null;
                icon?: string | null;
                id: string;
                exchangeDetails?: Label | null;
              };
              settlementAsset: {
                __typename?: 'BaseAsset';
                type: IAssetType;
                symbol: string;
                label?: Label | null;
                name?: string | null;
                icon?: string | null;
                id: string;
                exchangeDetails?: Label | null;
              };
            } | null;
          };
          weights: Array<{
            __typename?: 'AssetRiskMeasureType';
            measure: Label;
            value: number;
            benchmark?: { __typename?: 'Asset'; id: string; symbol: string } | null;
          }>;
        }>;
      } | null;
      output: Array<{
        __typename?: 'PortfolioOutput';
        nonDominated: boolean;
        suggested: boolean;
        solutionId: number;
        status?: IOptimizationResultStatus | null;
        statusMessage?: string | null;
        riskMeasures: Array<{
          __typename?: 'AssetRiskMeasureType';
          measure: Label;
          value: number;
          benchmark?: {
            __typename?: 'Asset';
            id: string;
            name?: string | null;
            symbol: string;
            type: IAssetType;
          } | null;
        }>;
        objectiveValues: Array<{ __typename?: 'PortfolioObjectiveValue'; value: number }>;
        portfolioExposures: Array<{
          __typename?: 'PortfolioExposure';
          name: IPortfolioExposureStatName;
          amount: number;
          percentage: number;
        }>;
        groupExposures: Array<{
          __typename?: 'GroupExposure';
          group: { __typename?: 'AssetGroup'; id: string; clusterName: string; groupName: string };
          exposures: Array<{
            __typename?: 'GroupExposureValues';
            name: IGroupExposureStatName;
            amount: number;
            percentage: number;
          }>;
        }>;
        returnsPredictions: {
          __typename?: 'ReturnsPredictions';
          returns: Array<number>;
          returnStatistics: Array<{ __typename?: 'ReturnStatistics'; statName: IReturnStatName; statValue: number }>;
        };
        measures: Array<{
          __typename?: 'AssetMeasure';
          cashWeight: number;
          amount: number;
          leverage: number;
          item: {
            __typename?: 'Asset';
            type: IAssetType;
            symbol: string;
            label?: Label | null;
            name?: string | null;
            icon?: string | null;
            id: string;
            exchangeDetails?: Label | null;
            derivativeDetails?: {
              __typename?: 'DerivativeDetails';
              exchange: Label;
              derivativeType: IDerivativeType;
              expirationDate?: string | null;
              optionType?: IOptionType | null;
              strikePrice?: Decimal | null;
              baseAsset: {
                __typename?: 'BaseAsset';
                type: IAssetType;
                symbol: string;
                label?: Label | null;
                name?: string | null;
                icon?: string | null;
                id: string;
                exchangeDetails?: Label | null;
              };
              quoteAsset: {
                __typename?: 'BaseAsset';
                type: IAssetType;
                symbol: string;
                label?: Label | null;
                name?: string | null;
                icon?: string | null;
                id: string;
                exchangeDetails?: Label | null;
              };
              settlementAsset: {
                __typename?: 'BaseAsset';
                type: IAssetType;
                symbol: string;
                label?: Label | null;
                name?: string | null;
                icon?: string | null;
                id: string;
                exchangeDetails?: Label | null;
              };
            } | null;
          };
          weights: Array<{
            __typename?: 'AssetRiskMeasureType';
            measure: Label;
            value: number;
            benchmark?: { __typename?: 'Asset'; id: string; symbol: string } | null;
          }>;
        }>;
      }>;
    };
  };
};

export type IAssetSolutionFieldsFragment = {
  __typename?: 'PortfolioOutput';
  nonDominated: boolean;
  suggested: boolean;
  solutionId: number;
  status?: IOptimizationResultStatus | null;
  statusMessage?: string | null;
  riskMeasures: Array<{
    __typename?: 'AssetRiskMeasureType';
    measure: Label;
    value: number;
    benchmark?: { __typename?: 'Asset'; id: string; name?: string | null; symbol: string; type: IAssetType } | null;
  }>;
  objectiveValues: Array<{ __typename?: 'PortfolioObjectiveValue'; value: number }>;
  portfolioExposures: Array<{
    __typename?: 'PortfolioExposure';
    name: IPortfolioExposureStatName;
    amount: number;
    percentage: number;
  }>;
  groupExposures: Array<{
    __typename?: 'GroupExposure';
    group: { __typename?: 'AssetGroup'; id: string; clusterName: string; groupName: string };
    exposures: Array<{
      __typename?: 'GroupExposureValues';
      name: IGroupExposureStatName;
      amount: number;
      percentage: number;
    }>;
  }>;
  returnsPredictions: {
    __typename?: 'ReturnsPredictions';
    returns: Array<number>;
    returnStatistics: Array<{ __typename?: 'ReturnStatistics'; statName: IReturnStatName; statValue: number }>;
  };
  measures: Array<{
    __typename?: 'AssetMeasure';
    cashWeight: number;
    amount: number;
    leverage: number;
    item: {
      __typename?: 'Asset';
      type: IAssetType;
      symbol: string;
      label?: Label | null;
      name?: string | null;
      icon?: string | null;
      id: string;
      exchangeDetails?: Label | null;
      derivativeDetails?: {
        __typename?: 'DerivativeDetails';
        exchange: Label;
        derivativeType: IDerivativeType;
        expirationDate?: string | null;
        optionType?: IOptionType | null;
        strikePrice?: Decimal | null;
        baseAsset: {
          __typename?: 'BaseAsset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
        };
        quoteAsset: {
          __typename?: 'BaseAsset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
        };
        settlementAsset: {
          __typename?: 'BaseAsset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
        };
      } | null;
    };
    weights: Array<{
      __typename?: 'AssetRiskMeasureType';
      measure: Label;
      value: number;
      benchmark?: { __typename?: 'Asset'; id: string; symbol: string } | null;
    }>;
  }>;
};

export type IPortfolioOptimizerResultQueryVariables = Exact<{
  optimizationId: Scalars['Int']['input'];
}>;

export type IPortfolioOptimizerResultQuery = {
  __typename?: 'Query';
  optimization: {
    __typename?: 'FundOptimization';
    getOptimizationInput: {
      __typename?: 'FundOptimizationUserInput';
      portfolioAmount: number;
      constraintFormulation: IConstraintFormulation;
      returnMeasureName?: IReturnMeasureNameUi | null;
      constraints: Array<{
        __typename?: 'ConstrainedFund';
        userExpectedReturn?: number | null;
        riskBudget?: number | null;
        fund: {
          __typename?: 'PortfolioDefinition';
          id: string;
          name: string;
          description: string;
          type: IPortfolioDefinitionType;
          createdAt: string;
          createdFrom?: {
            __typename?: 'PortfolioRebalancingRule';
            name: string;
            periodUnit?: IPeriodUnit | null;
            periodValue?: number | null;
            portfolioDefinition: { __typename?: 'PortfolioDefinition'; name: string };
          } | null;
        };
        constraint?: { __typename?: 'Constraint'; upperBound: number; lowerBound: number } | null;
      }>;
      portfolioConstraints?: {
        __typename?: 'PortfolioConstraints';
        primary?: {
          __typename?: 'PrimaryPortfolioConstraintType';
          constrainedQuantity: IPrimaryConstrainedQuantity;
          constraint: { __typename?: 'Constraint'; lowerBound: number; upperBound: number };
          riskMetric?: {
            __typename?: 'RiskMetric';
            metricLabel: Label;
            benchmark?: { __typename?: 'Asset'; symbol: string } | null;
          } | null;
        } | null;
        secondary?: {
          __typename?: 'SecondaryPortfolioConstraint';
          constrainedQuantity: ISecondaryConstrainedQuantity;
          constraint: { __typename?: 'Constraint'; lowerBound: number; upperBound: number };
          riskMetric?: {
            __typename?: 'RiskMetric';
            metricLabel: Label;
            benchmark?: {
              __typename?: 'Asset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
              derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
            } | null;
          } | null;
        } | null;
      } | null;
      givenPortfolio: Array<{
        __typename?: 'GivenPortfolioFund';
        cashWeight: number;
        fund: {
          __typename?: 'PortfolioDefinition';
          id: string;
          name: string;
          createdFrom?: {
            __typename?: 'PortfolioRebalancingRule';
            name: string;
            periodValue?: number | null;
            periodUnit?: IPeriodUnit | null;
            portfolioDefinition: { __typename?: 'PortfolioDefinition'; name: string };
          } | null;
        };
      }>;
    };
    getOptimization: {
      __typename?: 'FundOptimizationResult';
      optimizationId: number;
      status: IPortfolioOptimizationResultStatusType;
      name: string;
      description: string;
      objectives: Array<{
        __typename?: 'PortfolioOptimizationObjective';
        objectiveType: IObjectiveType;
        risk: Label;
        benchmark?: {
          __typename?: 'Asset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
          derivativeDetails?: {
            __typename?: 'DerivativeDetails';
            exchange: Label;
            derivativeType: IDerivativeType;
            expirationDate?: string | null;
            optionType?: IOptionType | null;
            strikePrice?: Decimal | null;
            baseAsset: {
              __typename?: 'BaseAsset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
            };
            quoteAsset: {
              __typename?: 'BaseAsset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
            };
            settlementAsset: {
              __typename?: 'BaseAsset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
            };
          } | null;
        } | null;
      }>;
      givenPortfolioOutput?: {
        __typename?: 'FundOutput';
        nonDominated: boolean;
        suggested: boolean;
        status?: IOptimizationResultStatus | null;
        statusMessage?: string | null;
        solutionId: number;
        riskMeasures: Array<{
          __typename?: 'FundRiskMeasureType';
          measure: Label;
          value: number;
          benchmark?: {
            __typename?: 'PortfolioDefinition';
            id: string;
            name: string;
            type: IPortfolioDefinitionType;
          } | null;
        }>;
        objectiveValues: Array<{ __typename?: 'PortfolioObjectiveValue'; value: number }>;
        portfolioExposures: Array<{
          __typename?: 'PortfolioExposure';
          name: IPortfolioExposureStatName;
          amount: number;
          percentage: number;
        }>;
        returnsPredictions: {
          __typename?: 'ReturnsPredictions';
          returns: Array<number>;
          returnStatistics: Array<{ __typename?: 'ReturnStatistics'; statName: IReturnStatName; statValue: number }>;
        };
        measures: Array<{
          __typename?: 'FundMeasure';
          amount: number;
          cashWeight: number;
          item: {
            __typename?: 'PortfolioDefinition';
            createdAt: string;
            type: IPortfolioDefinitionType;
            description: string;
            genie: boolean;
            id: string;
            name: string;
            createdFrom?: {
              __typename?: 'PortfolioRebalancingRule';
              end?: UtcDate | null;
              id: string;
              maxRebalancingCost?: Decimal | null;
              name: string;
              periodUnit?: IPeriodUnit | null;
              periodValue?: number | null;
              start: UtcDate;
              tradingFee: Decimal;
              portfolioDefinition: {
                __typename?: 'PortfolioDefinition';
                id: string;
                name: string;
                type: IPortfolioDefinitionType;
              };
            } | null;
          };
          weights: Array<{
            __typename?: 'FundRiskMeasureType';
            measure: Label;
            value: number;
            benchmark?: {
              __typename?: 'PortfolioDefinition';
              id: string;
              name: string;
              type: IPortfolioDefinitionType;
            } | null;
          }>;
        }>;
      } | null;
      output: Array<{
        __typename?: 'FundOutput';
        nonDominated: boolean;
        suggested: boolean;
        status?: IOptimizationResultStatus | null;
        statusMessage?: string | null;
        solutionId: number;
        riskMeasures: Array<{
          __typename?: 'FundRiskMeasureType';
          measure: Label;
          value: number;
          benchmark?: {
            __typename?: 'PortfolioDefinition';
            id: string;
            name: string;
            type: IPortfolioDefinitionType;
          } | null;
        }>;
        objectiveValues: Array<{ __typename?: 'PortfolioObjectiveValue'; value: number }>;
        portfolioExposures: Array<{
          __typename?: 'PortfolioExposure';
          name: IPortfolioExposureStatName;
          amount: number;
          percentage: number;
        }>;
        returnsPredictions: {
          __typename?: 'ReturnsPredictions';
          returns: Array<number>;
          returnStatistics: Array<{ __typename?: 'ReturnStatistics'; statName: IReturnStatName; statValue: number }>;
        };
        measures: Array<{
          __typename?: 'FundMeasure';
          amount: number;
          cashWeight: number;
          item: {
            __typename?: 'PortfolioDefinition';
            createdAt: string;
            type: IPortfolioDefinitionType;
            description: string;
            genie: boolean;
            id: string;
            name: string;
            createdFrom?: {
              __typename?: 'PortfolioRebalancingRule';
              end?: UtcDate | null;
              id: string;
              maxRebalancingCost?: Decimal | null;
              name: string;
              periodUnit?: IPeriodUnit | null;
              periodValue?: number | null;
              start: UtcDate;
              tradingFee: Decimal;
              portfolioDefinition: {
                __typename?: 'PortfolioDefinition';
                id: string;
                name: string;
                type: IPortfolioDefinitionType;
              };
            } | null;
          };
          weights: Array<{
            __typename?: 'FundRiskMeasureType';
            measure: Label;
            value: number;
            benchmark?: {
              __typename?: 'PortfolioDefinition';
              id: string;
              name: string;
              type: IPortfolioDefinitionType;
            } | null;
          }>;
        }>;
      }>;
    };
  };
};

export type IPortfolioSolutionFieldsFragment = {
  __typename?: 'FundOutput';
  nonDominated: boolean;
  suggested: boolean;
  status?: IOptimizationResultStatus | null;
  statusMessage?: string | null;
  solutionId: number;
  riskMeasures: Array<{
    __typename?: 'FundRiskMeasureType';
    measure: Label;
    value: number;
    benchmark?: { __typename?: 'PortfolioDefinition'; id: string; name: string; type: IPortfolioDefinitionType } | null;
  }>;
  objectiveValues: Array<{ __typename?: 'PortfolioObjectiveValue'; value: number }>;
  portfolioExposures: Array<{
    __typename?: 'PortfolioExposure';
    name: IPortfolioExposureStatName;
    amount: number;
    percentage: number;
  }>;
  returnsPredictions: {
    __typename?: 'ReturnsPredictions';
    returns: Array<number>;
    returnStatistics: Array<{ __typename?: 'ReturnStatistics'; statName: IReturnStatName; statValue: number }>;
  };
  measures: Array<{
    __typename?: 'FundMeasure';
    amount: number;
    cashWeight: number;
    item: {
      __typename?: 'PortfolioDefinition';
      createdAt: string;
      type: IPortfolioDefinitionType;
      description: string;
      genie: boolean;
      id: string;
      name: string;
      createdFrom?: {
        __typename?: 'PortfolioRebalancingRule';
        end?: UtcDate | null;
        id: string;
        maxRebalancingCost?: Decimal | null;
        name: string;
        periodUnit?: IPeriodUnit | null;
        periodValue?: number | null;
        start: UtcDate;
        tradingFee: Decimal;
        portfolioDefinition: {
          __typename?: 'PortfolioDefinition';
          id: string;
          name: string;
          type: IPortfolioDefinitionType;
        };
      } | null;
    };
    weights: Array<{
      __typename?: 'FundRiskMeasureType';
      measure: Label;
      value: number;
      benchmark?: {
        __typename?: 'PortfolioDefinition';
        id: string;
        name: string;
        type: IPortfolioDefinitionType;
      } | null;
    }>;
  }>;
};

export type IYieldOptimizerResultQueryVariables = Exact<{
  optimizationId: Scalars['Int']['input'];
}>;

export type IYieldOptimizerResultQuery = {
  __typename?: 'Query';
  optimization: {
    __typename?: 'YieldOptimization';
    getOptimizationInput: {
      __typename?: 'MultiAssetYieldOptimizationInputType';
      name: string;
      description: string;
      pools: Array<{
        __typename?: 'YieldPoolType';
        constraint?: { __typename?: 'Constraint'; lowerBound: number; upperBound: number } | null;
        poolInfo: {
          __typename?: 'PoolInfo';
          poolName: string;
          chain: string;
          externalLink?: string | null;
          category?: string | null;
          protocol?: { __typename?: 'Protocol'; id: string; name: string; icon?: string | null } | null;
          collateralAsset: {
            __typename?: 'Asset';
            type: IAssetType;
            symbol: string;
            label?: Label | null;
            name?: string | null;
            icon?: string | null;
            id: string;
            exchangeDetails?: Label | null;
            derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
          };
          underlyingAsset: {
            __typename?: 'Asset';
            type: IAssetType;
            symbol: string;
            label?: Label | null;
            name?: string | null;
            icon?: string | null;
            id: string;
            exchangeDetails?: Label | null;
            derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
          };
        };
      }>;
      amounts: Array<{
        __typename?: 'AssetAmountInputType';
        amount: number;
        asset: {
          __typename?: 'Asset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
          derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
        };
      }>;
    };
    getOptimization: {
      __typename?: 'MultiAssetYieldAnalysisType';
      optimizationId: number;
      status: IPortfolioOptimizationResultStatusType;
      name: string;
      description: string;
      optimizationMode: IOptimizationMode;
      output?: {
        __typename?: 'MultiAssetYieldOptimizationOutputType';
        groupPoolsByUnderlying: boolean;
        globalApy: number;
        yieldInUsd: number;
        naiveAllocationApys: Array<{
          __typename?: 'MultiAssetNaiveAllocationType';
          impactedApy: number;
          yieldUsd: number;
        }>;
        allocations: Array<{
          __typename?: 'ExtendedAllocationType';
          dollarValueOfAllocation: number;
          percentageAllocationInUsd: number;
          percentageAllocationInCollateral: number;
          absoluteAllocationInCollateral: number;
          yieldInUsd: number;
          yieldInCollateral: number;
          baseApy: number;
          impactedApy: number;
          pool: {
            __typename?: 'PoolInfo';
            poolName: string;
            chain: string;
            externalLink?: string | null;
            category?: string | null;
            protocol?: { __typename?: 'Protocol'; id: string; name: string; icon?: string | null } | null;
            collateralAsset: {
              __typename?: 'Asset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
              derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
            };
            underlyingAsset: {
              __typename?: 'Asset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
              derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
            };
            metrics?: {
              __typename?: 'PoolMetrics';
              tvlUsd: number;
              apyBase?: number | null;
              apy?: number | null;
              apyReward?: number | null;
            } | null;
          };
        }>;
      } | null;
    };
  };
};

export type IAssetOptimizerForecastQueryVariables = Exact<{
  assets: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
}>;

export type IAssetOptimizerForecastQuery = {
  __typename?: 'Query';
  portfolioOptimization: {
    __typename?: 'PortfolioOptimization';
    forecast: Array<{
      __typename?: 'Forecast';
      expectedReturn: number;
      targetRiskBudget: number;
      asset: { __typename?: 'Asset'; id: string };
    }>;
  };
};

export type IAssetOptimizerMultifactorForecastQueryVariables = Exact<{
  input: IBiasFromMultiFactorInput;
}>;

export type IAssetOptimizerMultifactorForecastQuery = {
  __typename?: 'Query';
  multifactor: {
    __typename?: 'MultiFactorQueryType';
    computeBiasFromMultifactor: {
      __typename?: 'BiasFromMultiFactorOutput';
      assetBias: Array<{ __typename?: 'AssetScore'; score: Decimal; asset: { __typename?: 'Asset'; id: string } }>;
    };
  };
};

export type IAssetOptimizerWizardInputQueryVariables = Exact<{ [key: string]: never }>;

export type IAssetOptimizerWizardInputQuery = {
  __typename?: 'Query';
  multifactor: {
    __typename?: 'MultiFactorQueryType';
    getAllMultifactorsForUser: Array<{
      __typename?: 'MultiFactorDetails';
      maxFactors: number;
      multifactor: { __typename?: 'MultiFactor'; id: number; name: string };
    }>;
  };
  assets: {
    __typename?: 'Assets';
    benchmark: Array<{
      __typename?: 'Asset';
      type: IAssetType;
      symbol: string;
      label?: Label | null;
      name?: string | null;
      icon?: string | null;
      id: string;
      exchangeDetails?: Label | null;
      derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
    }>;
    feature: Array<{
      __typename?: 'Asset';
      type: IAssetType;
      symbol: string;
      label?: Label | null;
      name?: string | null;
      icon?: string | null;
      id: string;
      exchangeDetails?: Label | null;
      derivativeDetails?: {
        __typename?: 'DerivativeDetails';
        exchange: Label;
        baseAsset: { __typename?: 'BaseAsset'; id: string };
      } | null;
    }>;
    assetGroups: {
      __typename?: 'AssetGroups';
      genieGroups: Array<{
        __typename?: 'AssetGroup';
        id: string;
        clusterName: string;
        groupName: string;
        assets: Array<{ __typename?: 'Asset'; id: string }>;
      }>;
      userGroups: Array<{
        __typename?: 'AssetGroup';
        id: string;
        clusterName: string;
        groupName: string;
        assets: Array<{ __typename?: 'Asset'; id: string }>;
      }>;
    };
  };
  portfolio: {
    __typename?: 'Portfolio';
    positions: {
      __typename?: 'SubAccountPositions';
      positions: Array<{
        __typename?: 'SubAccountPositionSnapshot';
        asset: {
          __typename?: 'Asset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
          derivativeDetails?: {
            __typename?: 'DerivativeDetails';
            exchange: Label;
            derivativeType: IDerivativeType;
            expirationDate?: string | null;
            optionType?: IOptionType | null;
            strikePrice?: Decimal | null;
            baseAsset: {
              __typename?: 'BaseAsset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
            };
            quoteAsset: {
              __typename?: 'BaseAsset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
            };
            settlementAsset: {
              __typename?: 'BaseAsset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
            };
          } | null;
        };
        spot?: { __typename?: 'SubAccountPositionSpotSnapshot'; balance: Decimal } | null;
        derivative?: { __typename?: 'SubAccountPositionDerivativeSnapshot'; notional?: Decimal | null } | null;
      }>;
    };
  };
};

export type ISubmitAssetOptimizationMutationVariables = Exact<{
  input: IPortfolioOptimizationInput;
}>;

export type ISubmitAssetOptimizationMutation = {
  __typename?: 'Mutation';
  optimization: { __typename?: 'SubmitOptimizationOutput'; optimizationId: number };
};

export type IPortfolioOptimizerForecastQueryVariables = Exact<{
  portfolioIds: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
}>;

export type IPortfolioOptimizerForecastQuery = {
  __typename?: 'Query';
  fundOptimization: {
    __typename?: 'FundOptimization';
    getForecastst: Array<{
      __typename?: 'FundExpectedReturnRiskBudget';
      expectedReturn: number;
      riskBudget: number;
      fundId: { __typename?: 'PortfolioDefinition'; id: string };
    }>;
  };
};

export type IPortfolioOptimizerWizardInputQueryVariables = Exact<{ [key: string]: never }>;

export type IPortfolioOptimizerWizardInputQuery = {
  __typename?: 'Query';
  fundOptimization: {
    __typename?: 'FundOptimization';
    getOptimizableFundsForUser: Array<{
      __typename?: 'PortfolioDefinition';
      name: string;
      type: IPortfolioDefinitionType;
      id: string;
      createdAt: string;
      description: string;
      genie: boolean;
      createdFrom?: {
        __typename?: 'PortfolioRebalancingRule';
        id: string;
        name: string;
        start: UtcDate;
        tradingFee: Decimal;
        periodUnit?: IPeriodUnit | null;
        periodValue?: number | null;
        portfolioDefinition: {
          __typename?: 'PortfolioDefinition';
          id: string;
          type: IPortfolioDefinitionType;
          name: string;
        };
      } | null;
    }>;
  };
  assets: {
    __typename?: 'Assets';
    benchmark: Array<{
      __typename?: 'Asset';
      type: IAssetType;
      symbol: string;
      label?: Label | null;
      name?: string | null;
      icon?: string | null;
      id: string;
      exchangeDetails?: Label | null;
      derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
    }>;
  };
};

export type ISubmitPortfolioOptimizationMutationVariables = Exact<{
  input: IFundOptimizationInput;
}>;

export type ISubmitPortfolioOptimizationMutation = {
  __typename?: 'Mutation';
  optimization: { __typename?: 'SubmitOptimizationOutput'; optimizationId: number };
};

export type IYieldOptimizerWizardInputQueryVariables = Exact<{ [key: string]: never }>;

export type IYieldOptimizerWizardInputQuery = {
  __typename?: 'Query';
  yieldOptimization: {
    __typename?: 'YieldOptimization';
    listPools: Array<{
      __typename?: 'PoolInfo';
      id: string;
      category?: string | null;
      chain: string;
      externalLink?: string | null;
      poolName: string;
      collateralAsset: {
        __typename?: 'Asset';
        type: IAssetType;
        symbol: string;
        label?: Label | null;
        name?: string | null;
        icon?: string | null;
        id: string;
        exchangeDetails?: Label | null;
        derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
      };
      underlyingAsset: {
        __typename?: 'Asset';
        type: IAssetType;
        symbol: string;
        label?: Label | null;
        name?: string | null;
        icon?: string | null;
        id: string;
        exchangeDetails?: Label | null;
        derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
      };
      protocol?: { __typename?: 'Protocol'; id: string; name: string; icon?: string | null } | null;
      metrics?: { __typename?: 'PoolMetrics'; apy?: number | null; apyBase?: number | null; tvlUsd: number } | null;
    }>;
  };
  portfolio: {
    __typename?: 'Portfolio';
    positions: {
      __typename?: 'SubAccountPositions';
      positions: Array<{
        __typename?: 'SubAccountPositionSnapshot';
        asset: { __typename?: 'Asset'; id: string };
        spot?: { __typename?: 'SubAccountPositionSpotSnapshot'; amount: Decimal } | null;
      }>;
    };
  };
};

export type ISubmitYieldOptimizationMutationVariables = Exact<{
  input: IMultiAssetYieldOptimizationInput;
}>;

export type ISubmitYieldOptimizationMutation = {
  __typename?: 'Mutation';
  optimization: { __typename?: 'SubmitOptimizationOutput'; optimizationId: number };
};

export type ICounterpartyRiskReportQueryVariables = Exact<{
  subAccountAssetFilters: ISubAccountAssetFilterInputType;
}>;

export type ICounterpartyRiskReportQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    positions: {
      __typename?: 'SubAccountPositions';
      positions: Array<{
        __typename?: 'SubAccountPositionSnapshot';
        subAccount: {
          __typename?: 'SubAccount';
          id: string;
          name: string;
          account: {
            __typename?: 'Account';
            id: string;
            name: string;
            venue: { __typename?: 'DataSource'; label: Label };
          };
        };
        asset: {
          __typename?: 'Asset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
          derivativeDetails?: {
            __typename?: 'DerivativeDetails';
            exchange: Label;
            derivativeType: IDerivativeType;
            expirationDate?: string | null;
            optionType?: IOptionType | null;
            strikePrice?: Decimal | null;
            baseAsset: {
              __typename?: 'BaseAsset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
            };
            quoteAsset: {
              __typename?: 'BaseAsset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
            };
            settlementAsset: {
              __typename?: 'BaseAsset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
            };
          } | null;
        };
        spot?: {
          __typename?: 'SubAccountPositionSpotSnapshot';
          price: Decimal;
          amount: Decimal;
          balance: Decimal;
        } | null;
        derivative?: {
          __typename?: 'SubAccountPositionDerivativeSnapshot';
          amount: Decimal;
          notional?: Decimal | null;
          side: IPositionSide;
          unrealizedPnl?: Decimal | null;
          balance?: Decimal | null;
        } | null;
      }>;
    };
    subFunds: {
      __typename?: 'SubFunds';
      list: Array<{
        __typename?: 'SubFund';
        id: number;
        name: string;
        dimension: string;
        subAccounts: Array<{ __typename?: 'SubAccount'; id: string }>;
        accounts: Array<{
          __typename?: 'Account';
          id: string;
          subAccounts: Array<{ __typename?: 'SubAccount'; id: string }>;
        }>;
      }>;
    };
  };
};

export type IPortfolioRiskMetricsContributionQueryVariables = Exact<{
  input: IPortfolioRiskMetricsContributionInput;
}>;

export type IPortfolioRiskMetricsContributionQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    metricContributions: Array<{
      __typename?: 'CalculatorContributionOutput';
      label: Label;
      portfolioDefinition?: { __typename?: 'PortfolioDefinition'; id: string; name: string } | null;
      series: Array<{
        __typename?: 'CalculatorContributionSeries';
        total: Decimal;
        values: Array<{
          __typename?: 'AssetContributionValue';
          value: Decimal;
          asset: {
            __typename?: 'Asset';
            id: string;
            symbol: string;
            type: IAssetType;
            derivativeDetails?: {
              __typename?: 'DerivativeDetails';
              baseAsset: { __typename?: 'BaseAsset'; id: string };
            } | null;
          };
        }>;
        parameters: Array<{
          __typename?: 'ParameterOutput';
          strValue?: string | null;
          intValue?: number | null;
          name: string;
          asset?: { __typename?: 'Asset'; symbol: string; id: string } | null;
        }>;
      }>;
    }>;
  };
};

export type IRiskDashboardInputQueryVariables = Exact<{
  subAccountAssetFilters: ISubAccountAssetFilterInputType;
}>;

export type IRiskDashboardInputQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    accounts: Array<{
      __typename?: 'Account';
      name: string;
      id: string;
      venue: { __typename?: 'DataSource'; label: Label };
      subAccounts: Array<{ __typename?: 'SubAccount'; id: string; name: string }>;
    }>;
    metricParameters: Array<{
      __typename?: 'PortfolioMetricDescription';
      label: Label;
      parameters: Array<{
        __typename?: 'ParameterDescription';
        name: string;
        type: IParameterType;
        minMax?: { __typename?: 'MinMaxValue'; default: number; max: number; min: number } | null;
        options?: Array<{ __typename?: 'OptionValue'; id: string; label: string; value: string }> | null;
        visibleIf?: Array<{
          __typename?: 'ParameterVisibilityPredicate';
          parameter: string;
          selectedOption: string;
        }> | null;
        benchmarks?: Array<{
          __typename?: 'Asset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
          derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
        }> | null;
      }>;
    }>;
  };
  assets: {
    __typename?: 'Assets';
    assetGroups: {
      __typename?: 'AssetGroups';
      genieGroups: Array<{
        __typename?: 'AssetGroup';
        clusterName: string;
        groupName: string;
        assets: Array<{ __typename?: 'Asset'; id: string }>;
      }>;
    };
  };
};

export type IAssetRiskMetricsQueryVariables = Exact<{
  assetIds: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
}>;

export type IAssetRiskMetricsQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'Assets';
    details: Array<{ __typename?: 'AssetDetails'; asset: string; metrics: Record<string, string> }>;
  };
};

export type IAssetRiskMetricsReportAssetsAndParametersQueryVariables = Exact<{
  subAccountAssetFilters: ISubAccountAssetFilterInputType;
}>;

export type IAssetRiskMetricsReportAssetsAndParametersQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    positions: {
      __typename?: 'SubAccountPositions';
      positions: Array<{
        __typename?: 'SubAccountPositionSnapshot';
        asset: {
          __typename?: 'Asset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
          derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
        };
        spot?: { __typename?: 'SubAccountPositionSpotSnapshot'; balance: Decimal } | null;
        derivative?: { __typename?: 'SubAccountPositionDerivativeSnapshot'; balance?: Decimal | null } | null;
      }>;
    };
    metricParameters: Array<{
      __typename?: 'PortfolioMetricDescription';
      label: Label;
      parameters: Array<{
        __typename?: 'ParameterDescription';
        name: string;
        type: IParameterType;
        minMax?: { __typename?: 'MinMaxValue'; max: number; min: number } | null;
        options?: Array<{ __typename?: 'OptionValue'; value: string }> | null;
        benchmarks?: Array<{ __typename?: 'Asset'; id: string; symbol: string }> | null;
      }>;
    }>;
  };
  assets: { __typename?: 'Assets'; feature: Array<{ __typename?: 'Asset'; id: string; symbol: string }> };
};

export type IStrategyQueryVariables = Exact<{
  label: Scalars['LabelType']['input'];
}>;

export type IStrategyQuery = {
  __typename?: 'Query';
  strategy: {
    __typename?: 'StrategyType';
    strategy?: { __typename?: 'Strategy'; label: Label; name: string; description: string } | null;
  };
};

export type IStrategyAllocationQueryVariables = Exact<{
  label: Scalars['LabelType']['input'];
}>;

export type IStrategyAllocationQuery = {
  __typename?: 'Query';
  strategy: {
    __typename?: 'StrategyType';
    assetAllocations: Array<{
      __typename?: 'AssetAllocationSnapshot';
      time: string;
      assetAllocations: Array<{
        __typename?: 'AssetAllocation';
        value: number;
        asset: { __typename?: 'Asset'; id: string; name?: string | null; symbol: string };
      }>;
    }>;
  };
};

export type IStrategyListQueryVariables = Exact<{ [key: string]: never }>;

export type IStrategyListQuery = {
  __typename?: 'Query';
  strategy: {
    __typename?: 'StrategyType';
    list: Array<{ __typename?: 'Strategy'; label: Label; name: string; description: string }>;
  };
};

export type IStrategyMonthlyReturnsQueryVariables = Exact<{
  label: Scalars['LabelType']['input'];
}>;

export type IStrategyMonthlyReturnsQuery = {
  __typename?: 'Query';
  strategy: {
    __typename?: 'StrategyType';
    monthlyReturns: Array<{ __typename?: 'TimeSeriesScalarData'; time: string; value: number }>;
  };
};

export type IStrategyPerformanceQueryVariables = Exact<{
  label: Scalars['LabelType']['input'];
}>;

export type IStrategyPerformanceQuery = {
  __typename?: 'Query';
  strategy: {
    __typename?: 'StrategyType';
    strategyPerformanceMetrics: Array<{
      __typename?: 'StrategyPerformanceMetric';
      name: IStrategyPerformanceMetricName;
      value: number;
    }>;
    cumulativeReturns: {
      __typename?: 'CumulativeReturn';
      backtest: Array<{ __typename?: 'TimeSeriesScalarData'; time: string; value: number }>;
      live: Array<{ __typename?: 'TimeSeriesScalarData'; time: string; value: number }>;
    };
  };
};

export type IStrategyPositionStatisticsQueryVariables = Exact<{
  label: Scalars['LabelType']['input'];
}>;

export type IStrategyPositionStatisticsQuery = {
  __typename?: 'Query';
  strategy: {
    __typename?: 'StrategyType';
    positionStatistics: Array<{ __typename?: 'PositionStatistic'; name: IPositionStatisticName; value: number }>;
  };
};

export type IStrategyPositionsQueryVariables = Exact<{
  label: Scalars['LabelType']['input'];
}>;

export type IStrategyPositionsQuery = {
  __typename?: 'Query';
  strategy: {
    __typename?: 'StrategyType';
    positionList: Array<{
      __typename?: 'Position';
      exchange?: Label | null;
      openAt: string;
      closedAt?: string | null;
      side: IPositionSide;
      status: IPositionStatus;
      amount: number;
      openPrice: number;
      closePrice?: number | null;
      netReturn: number;
      netQuoteProfit: number;
      base: {
        __typename?: 'Asset';
        type: IAssetType;
        symbol: string;
        label?: Label | null;
        name?: string | null;
        icon?: string | null;
        id: string;
        exchangeDetails?: Label | null;
        derivativeDetails?: {
          __typename?: 'DerivativeDetails';
          exchange: Label;
          derivativeType: IDerivativeType;
          expirationDate?: string | null;
          optionType?: IOptionType | null;
          strikePrice?: Decimal | null;
          baseAsset: {
            __typename?: 'BaseAsset';
            type: IAssetType;
            symbol: string;
            label?: Label | null;
            name?: string | null;
            icon?: string | null;
            id: string;
            exchangeDetails?: Label | null;
          };
          quoteAsset: {
            __typename?: 'BaseAsset';
            type: IAssetType;
            symbol: string;
            label?: Label | null;
            name?: string | null;
            icon?: string | null;
            id: string;
            exchangeDetails?: Label | null;
          };
          settlementAsset: {
            __typename?: 'BaseAsset';
            type: IAssetType;
            symbol: string;
            label?: Label | null;
            name?: string | null;
            icon?: string | null;
            id: string;
            exchangeDetails?: Label | null;
          };
        } | null;
      };
      quote: {
        __typename?: 'Asset';
        type: IAssetType;
        symbol: string;
        label?: Label | null;
        name?: string | null;
        icon?: string | null;
        id: string;
        exchangeDetails?: Label | null;
        derivativeDetails?: {
          __typename?: 'DerivativeDetails';
          exchange: Label;
          derivativeType: IDerivativeType;
          expirationDate?: string | null;
          optionType?: IOptionType | null;
          strikePrice?: Decimal | null;
          baseAsset: {
            __typename?: 'BaseAsset';
            type: IAssetType;
            symbol: string;
            label?: Label | null;
            name?: string | null;
            icon?: string | null;
            id: string;
            exchangeDetails?: Label | null;
          };
          quoteAsset: {
            __typename?: 'BaseAsset';
            type: IAssetType;
            symbol: string;
            label?: Label | null;
            name?: string | null;
            icon?: string | null;
            id: string;
            exchangeDetails?: Label | null;
          };
          settlementAsset: {
            __typename?: 'BaseAsset';
            type: IAssetType;
            symbol: string;
            label?: Label | null;
            name?: string | null;
            icon?: string | null;
            id: string;
            exchangeDetails?: Label | null;
          };
        } | null;
      };
      fee?: {
        __typename?: 'Fee';
        amount: number;
        asset: {
          __typename?: 'Asset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
          derivativeDetails?: {
            __typename?: 'DerivativeDetails';
            exchange: Label;
            derivativeType: IDerivativeType;
            expirationDate?: string | null;
            optionType?: IOptionType | null;
            strikePrice?: Decimal | null;
            baseAsset: {
              __typename?: 'BaseAsset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
            };
            quoteAsset: {
              __typename?: 'BaseAsset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
            };
            settlementAsset: {
              __typename?: 'BaseAsset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
            };
          } | null;
        };
      } | null;
    }>;
  };
};

export type IStrategyRiskMetricsQueryVariables = Exact<{
  label: Scalars['LabelType']['input'];
}>;

export type IStrategyRiskMetricsQuery = {
  __typename?: 'Query';
  strategy: {
    __typename?: 'StrategyType';
    strategyRiskMetrics: Array<{ __typename?: 'StrategyRiskMetric'; name: Label; value: number }>;
  };
};

export type IStrategyRollingMetricsQueryVariables = Exact<{
  label: Scalars['LabelType']['input'];
}>;

export type IStrategyRollingMetricsQuery = {
  __typename?: 'Query';
  strategy: {
    __typename?: 'StrategyType';
    strategyRiskMetricsRolling: Array<{
      __typename?: 'StrategyRiskMetricRolling';
      label: Label;
      mean: number;
      timeSeries: Array<{ __typename?: 'TimeSeriesScalarData'; time: string; value: number }>;
    }>;
  };
};

export type IPerformStressTestQueryVariables = Exact<{
  input: IStressTestInputType;
}>;

export type IPerformStressTestQuery = {
  __typename?: 'Query';
  stressTest: {
    __typename?: 'StressTestType';
    stressTest: {
      __typename?: 'StressTestOutputType';
      keyMeasures: {
        __typename?: 'KeyMeasures';
        assetCount: number;
        measures: Array<{
          __typename?: 'KeyMeasure';
          name: IKeyMeasureName;
          value: number;
          netChange: number;
          asset?: { __typename?: 'Asset'; symbol: string } | null;
        }>;
      };
      logicResult: {
        __typename?: 'LogicResult';
        portfolioReturns: Array<number>;
        assetResults: Array<{
          __typename?: 'LogicAssetResult';
          unitUsdValue: number;
          meanEquityChange: number;
          meanReturn: number;
          asset: {
            __typename?: 'Asset';
            type: IAssetType;
            symbol: string;
            label?: Label | null;
            name?: string | null;
            icon?: string | null;
            id: string;
            exchangeDetails?: Label | null;
            derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
          };
        }>;
        portfolioStatistics: Array<{ __typename?: 'PortfolioStatistic'; value: number; name: IPortfolioStatisticName }>;
      };
    };
  };
};

export type IStressTestSimulatorInputQueryVariables = Exact<{ [key: string]: never }>;

export type IStressTestSimulatorInputQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'Assets';
    feature: Array<{
      __typename?: 'Asset';
      type: IAssetType;
      symbol: string;
      label?: Label | null;
      name?: string | null;
      icon?: string | null;
      id: string;
      exchangeDetails?: Label | null;
      derivativeDetails?: {
        __typename?: 'DerivativeDetails';
        exchange: Label;
        derivativeType: IDerivativeType;
        expirationDate?: string | null;
        optionType?: IOptionType | null;
        strikePrice?: Decimal | null;
        baseAsset: {
          __typename?: 'BaseAsset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
        };
        quoteAsset: {
          __typename?: 'BaseAsset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
        };
        settlementAsset: {
          __typename?: 'BaseAsset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
        };
      } | null;
    }>;
    assetGroups: {
      __typename?: 'AssetGroups';
      genieGroups: Array<{
        __typename?: 'AssetGroup';
        groupName: string;
        clusterName: string;
        assets: Array<{
          __typename?: 'Asset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
          derivativeDetails?: {
            __typename?: 'DerivativeDetails';
            exchange: Label;
            derivativeType: IDerivativeType;
            expirationDate?: string | null;
            optionType?: IOptionType | null;
            strikePrice?: Decimal | null;
            baseAsset: {
              __typename?: 'BaseAsset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
            };
            quoteAsset: {
              __typename?: 'BaseAsset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
            };
            settlementAsset: {
              __typename?: 'BaseAsset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
            };
          } | null;
        }>;
      }>;
    };
  };
  portfolio: {
    __typename?: 'Portfolio';
    positions: {
      __typename?: 'SubAccountPositions';
      positions: Array<{
        __typename?: 'SubAccountPositionSnapshot';
        asset: {
          __typename?: 'Asset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
          derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
        };
        spot?: { __typename?: 'SubAccountPositionSpotSnapshot'; balance: Decimal } | null;
      }>;
    };
  };
  stressTest: {
    __typename?: 'StressTestType';
    scenarioLibrary: Array<{
      __typename?: 'LabelledScenario';
      name: string;
      description: string;
      events: Array<{
        __typename?: 'AssetEvent';
        netReturn: number;
        asset?: {
          __typename?: 'Asset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
          derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
        } | null;
      }>;
    }>;
  };
};

export type IUnifiedStressTestQueryVariables = Exact<{
  input: IScenAnalysisInputType;
}>;

export type IUnifiedStressTestQuery = {
  __typename?: 'Query';
  scenarioAnalysisV2: {
    __typename?: 'ScenarioAnalysisV2';
    scenarioAnalysisV2: Array<{
      __typename?: 'ScenAnalysisResult';
      externalId?: string | null;
      referenceEvaluation: {
        __typename?: 'PortfolioEvaluationV2';
        fairValue: number;
        marketValue?: number | null;
        greeks: { __typename?: 'Greeks'; vega: Decimal; theta: Decimal; rho: Decimal; gamma: Decimal; delta: Decimal };
      };
      simulatedResults: Array<{
        __typename?: 'ScenarioResult';
        scenario: {
          __typename?: 'ScenarioInstance';
          benchmark: {
            __typename?: 'AssetShock';
            price?: { __typename?: 'Shock'; mode: IShockMode; reference?: number | null; shock: number } | null;
            volatility?: { __typename?: 'Shock'; shock: number; reference?: number | null; mode: IShockMode } | null;
          };
          riskFree?: { __typename?: 'Shock'; mode: IShockMode; reference?: number | null; shock: number } | null;
        };
        portfolioEvaluationV2: {
          __typename?: 'PortfolioEvaluationV2';
          marketValue?: number | null;
          fairValue: number;
          greeks: {
            __typename?: 'Greeks';
            delta: Decimal;
            gamma: Decimal;
            rho: Decimal;
            theta: Decimal;
            vega: Decimal;
          };
        };
      }>;
    }>;
  };
};

export type IUnifiedStressTestInputQueryVariables = Exact<{ [key: string]: never }>;

export type IUnifiedStressTestInputQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'Assets';
    feature: Array<{
      __typename?: 'Asset';
      type: IAssetType;
      symbol: string;
      label?: Label | null;
      name?: string | null;
      icon?: string | null;
      id: string;
      exchangeDetails?: Label | null;
      derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
    }>;
  };
  portfolio: {
    __typename?: 'Portfolio';
    definitions: Array<{
      __typename?: 'PortfolioDefinition';
      genie: boolean;
      type: IPortfolioDefinitionType;
      name: string;
      latestAssetComposition: Array<{
        __typename?: 'AssetCompositionWithValues';
        amount?: number | null;
        weight: number;
        entryPrice?: number | null;
        asset: {
          __typename?: 'Asset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
          derivativeDetails?: {
            __typename?: 'DerivativeDetails';
            derivativeType: IDerivativeType;
            exchange: Label;
          } | null;
        };
      }>;
    }>;
  };
};

export type IManagementDashboardInputQueryVariables = Exact<{ [key: string]: never }>;

export type IManagementDashboardInputQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    subFunds: { __typename?: 'SubFunds'; list: Array<{ __typename?: 'SubFund'; dimension: string }> };
    journal: { __typename?: 'Journal'; preferences: { __typename?: 'JournalPreferences'; dimension?: string | null } };
  };
};

export type IUpdateJournalDimensionMutationVariables = Exact<{
  dimension?: InputMaybe<Scalars['String']['input']>;
}>;

export type IUpdateJournalDimensionMutation = { __typename?: 'Mutation'; updateJournalPreferences?: any | null };

export type IUpdateUserSettingsMutationVariables = Exact<{
  settings: Scalars['JSON']['input'];
}>;

export type IUpdateUserSettingsMutation = { __typename?: 'Mutation'; UpdateUserSettings?: any | null };

export type IUserSettingsQueryVariables = Exact<{
  field: Scalars['String']['input'];
}>;

export type IUserSettingsQuery = {
  __typename?: 'Query';
  management: { __typename?: 'Management'; userSettings?: any | null };
};

export type ICreateWidgetMutationVariables = Exact<{
  input: ICreateWidgetInput;
}>;

export type ICreateWidgetMutation = { __typename?: 'Mutation'; createWidget?: any | null };

export type IDeleteWidgetMutationVariables = Exact<{
  page: Scalars['String']['input'];
  id: Scalars['String']['input'];
}>;

export type IDeleteWidgetMutation = { __typename?: 'Mutation'; deleteWidget?: any | null };

export type IGetWidgetsQueryVariables = Exact<{
  pageId: Scalars['String']['input'];
}>;

export type IGetWidgetsQuery = {
  __typename?: 'Query';
  management: {
    __typename?: 'Management';
    widgets: Array<{
      __typename?: 'Widget';
      id: string;
      title: string;
      type: string;
      data: any;
      layout: { __typename?: 'Layout'; x: number; y: number; w: number; h: number };
    }>;
  };
};

export type IUpdateWidgetStateMutationVariables = Exact<{
  input: IUpdateWidgetInput;
}>;

export type IUpdateWidgetStateMutation = { __typename?: 'Mutation'; updateWidget?: any | null };

export type IUpdateWidgetsLayoutMutationVariables = Exact<{
  input: Array<IUpdateLayoutInput> | IUpdateLayoutInput;
}>;

export type IUpdateWidgetsLayoutMutation = { __typename?: 'Mutation'; updateLayout?: any | null };

export type IUsersQueryVariables = Exact<{ [key: string]: never }>;

export type IUsersQuery = {
  __typename?: 'Query';
  management: {
    __typename?: 'Management';
    users: Array<{
      __typename?: 'User';
      name?: string | null;
      id?: number | null;
      email: string;
      groups: Array<string>;
      enabled: boolean;
      cognitoUsername: string;
      organization?: { __typename?: 'Organization'; id: number; name: string } | null;
    }>;
  };
};

export type IAssetQueryVariables = Exact<{
  assetId: Scalars['UUID']['input'];
}>;

export type IAssetQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'Assets';
    list: Array<{
      __typename?: 'Asset';
      type: IAssetType;
      symbol: string;
      label?: Label | null;
      name?: string | null;
      icon?: string | null;
      id: string;
      exchangeDetails?: Label | null;
      derivativeDetails?: {
        __typename?: 'DerivativeDetails';
        exchange: Label;
        derivativeType: IDerivativeType;
        expirationDate?: string | null;
        optionType?: IOptionType | null;
        strikePrice?: Decimal | null;
        baseAsset: {
          __typename?: 'BaseAsset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
        };
        quoteAsset: {
          __typename?: 'BaseAsset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
        };
        settlementAsset: {
          __typename?: 'BaseAsset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
        };
      } | null;
    }>;
  };
};

export type IAssetLabelFieldsFragment = {
  __typename?: 'Asset';
  type: IAssetType;
  symbol: string;
  label?: Label | null;
  name?: string | null;
  icon?: string | null;
  id: string;
  exchangeDetails?: Label | null;
  derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
};

export type IAssetNameQueryVariables = Exact<{
  filters?: InputMaybe<IListAssetFilters>;
}>;

export type IAssetNameQuery = {
  __typename?: 'Query';
  assets: { __typename?: 'Assets'; list: Array<{ __typename?: 'Asset'; name?: string | null; id: string }> };
};

export type IAssetPaginatedQueryVariables = Exact<{
  cursors?: InputMaybe<ICursorsV1>;
  filters?: InputMaybe<IAssetByNameFilters>;
}>;

export type IAssetPaginatedQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'Assets';
    paginated: {
      __typename?: 'AssetPage';
      data: Array<{
        __typename?: 'Asset';
        type: IAssetType;
        symbol: string;
        label?: Label | null;
        name?: string | null;
        icon?: string | null;
        id: string;
        exchangeDetails?: Label | null;
        derivativeDetails?: {
          __typename?: 'DerivativeDetails';
          derivativeType: IDerivativeType;
          exchange: Label;
        } | null;
      }>;
      pageInfo: { __typename?: 'PageInfoV1'; endCursor?: string | null; hasNextPage: boolean };
    };
  };
};

export type IMainAssetFieldsFragment = {
  __typename?: 'Asset';
  type: IAssetType;
  symbol: string;
  label?: Label | null;
  name?: string | null;
  icon?: string | null;
  id: string;
  exchangeDetails?: Label | null;
  derivativeDetails?: {
    __typename?: 'DerivativeDetails';
    exchange: Label;
    derivativeType: IDerivativeType;
    expirationDate?: string | null;
    optionType?: IOptionType | null;
    strikePrice?: Decimal | null;
    baseAsset: {
      __typename?: 'BaseAsset';
      type: IAssetType;
      symbol: string;
      label?: Label | null;
      name?: string | null;
      icon?: string | null;
      id: string;
      exchangeDetails?: Label | null;
    };
    quoteAsset: {
      __typename?: 'BaseAsset';
      type: IAssetType;
      symbol: string;
      label?: Label | null;
      name?: string | null;
      icon?: string | null;
      id: string;
      exchangeDetails?: Label | null;
    };
    settlementAsset: {
      __typename?: 'BaseAsset';
      type: IAssetType;
      symbol: string;
      label?: Label | null;
      name?: string | null;
      icon?: string | null;
      id: string;
      exchangeDetails?: Label | null;
    };
  } | null;
};

export type IMainNonDerivativeAssetFieldsFragment = {
  __typename?: 'Asset';
  type: IAssetType;
  symbol: string;
  label?: Label | null;
  name?: string | null;
  icon?: string | null;
  id: string;
  exchangeDetails?: Label | null;
};

export type IMainNonDerivativeBaseAssetFieldsFragment = {
  __typename?: 'BaseAsset';
  type: IAssetType;
  symbol: string;
  label?: Label | null;
  name?: string | null;
  icon?: string | null;
  id: string;
  exchangeDetails?: Label | null;
};

export type IPublicAssetsQueryVariables = Exact<{ [key: string]: never }>;

export type IPublicAssetsQuery = {
  __typename?: 'Query';
  assets: { __typename?: 'Assets'; list: Array<{ __typename?: 'Asset'; id: string; symbol: string }> };
};

export type IAssetsQueryVariables = Exact<{ [key: string]: never }>;

export type IAssetsQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'Assets';
    feature: Array<{
      __typename?: 'Asset';
      type: IAssetType;
      symbol: string;
      label?: Label | null;
      name?: string | null;
      icon?: string | null;
      id: string;
    }>;
  };
};

export type IComputeMultifactorScoresQueryVariables = Exact<{
  input: IAssetScreenerInput;
}>;

export type IComputeMultifactorScoresQuery = {
  __typename?: 'Query';
  multifactor: {
    __typename?: 'MultiFactorQueryType';
    computeMultifactorScores: {
      __typename?: 'AssetScreenerOutput';
      scores: Array<{
        __typename?: 'MultifactorScores';
        assetScores: Array<{
          __typename?: 'AssetScore';
          score: Decimal;
          asset: {
            __typename?: 'Asset';
            type: IAssetType;
            symbol: string;
            label?: Label | null;
            name?: string | null;
            icon?: string | null;
            id: string;
            exchangeDetails?: Label | null;
            derivativeDetails?: {
              __typename?: 'DerivativeDetails';
              exchange: Label;
              derivativeType: IDerivativeType;
              expirationDate?: string | null;
              optionType?: IOptionType | null;
              strikePrice?: Decimal | null;
              baseAsset: {
                __typename?: 'BaseAsset';
                type: IAssetType;
                symbol: string;
                label?: Label | null;
                name?: string | null;
                icon?: string | null;
                id: string;
                exchangeDetails?: Label | null;
              };
              quoteAsset: {
                __typename?: 'BaseAsset';
                type: IAssetType;
                symbol: string;
                label?: Label | null;
                name?: string | null;
                icon?: string | null;
                id: string;
                exchangeDetails?: Label | null;
              };
              settlementAsset: {
                __typename?: 'BaseAsset';
                type: IAssetType;
                symbol: string;
                label?: Label | null;
                name?: string | null;
                icon?: string | null;
                id: string;
                exchangeDetails?: Label | null;
              };
            } | null;
          };
        }>;
        multifactor: {
          __typename?: 'MultiFactorDetails';
          maxFactors: number;
          factorWeights: Array<{
            __typename?: 'MultiFactorWeight';
            betterIfLower: boolean;
            metricId: number;
            weight: Decimal;
          }>;
          multifactor: {
            __typename?: 'MultiFactor';
            description: string;
            id: number;
            name: string;
            createdAt: string;
            updatedAt?: string | null;
          };
        };
      }>;
    };
  };
};

export type IFactorQueryVariables = Exact<{ [key: string]: never }>;

export type IFactorQuery = {
  __typename?: 'Query';
  multifactor: {
    __typename?: 'MultiFactorQueryType';
    getAllSupportedFactors: Array<{ __typename?: 'Factor'; id: number; name: string; category: string }>;
  };
};

export type IMultifactorQueryVariables = Exact<{ [key: string]: never }>;

export type IMultifactorQuery = {
  __typename?: 'Query';
  multifactor: {
    __typename?: 'MultiFactorQueryType';
    getAllMultifactorsForUser: Array<{
      __typename?: 'MultiFactorDetails';
      maxFactors: number;
      factorWeights: Array<{
        __typename?: 'MultiFactorWeight';
        betterIfLower: boolean;
        metricId: number;
        weight: Decimal;
      }>;
      multifactor: {
        __typename?: 'MultiFactor';
        createdAt: string;
        description: string;
        name: string;
        updatedAt?: string | null;
        id: number;
      };
    }>;
  };
};

export type IMultifactorDetailsQueryVariables = Exact<{
  multifactorId: Scalars['Int']['input'];
}>;

export type IMultifactorDetailsQuery = {
  __typename?: 'Query';
  multifactor: {
    __typename?: 'MultiFactorQueryType';
    getMultifactorDetails: {
      __typename?: 'MultiFactorDetails';
      maxFactors: number;
      factorWeights: Array<{
        __typename?: 'MultiFactorWeight';
        betterIfLower: boolean;
        metricId: number;
        weight: Decimal;
      }>;
      multifactor: {
        __typename?: 'MultiFactor';
        createdAt: string;
        description: string;
        name: string;
        updatedAt?: string | null;
        id: number;
      };
    };
  };
};

export type ISaveEditedMultifactorMutationVariables = Exact<{
  input: IPartiallyDefinedMultiFactorInput;
  multifactorId: Scalars['Int']['input'];
}>;

export type ISaveEditedMultifactorMutation = {
  __typename?: 'Mutation';
  saveEditedMultifactor: {
    __typename?: 'MultiFactorDetails';
    factorWeights: Array<{
      __typename?: 'MultiFactorWeight';
      betterIfLower: boolean;
      metricId: number;
      weight: Decimal;
    }>;
    multifactor: {
      __typename?: 'MultiFactor';
      createdAt: string;
      description: string;
      name: string;
      updatedAt?: string | null;
      id: number;
    };
  };
};

export type ISaveNewMultifactorMutationVariables = Exact<{
  input: IPartiallyDefinedMultiFactorInput;
}>;

export type ISaveNewMultifactorMutation = {
  __typename?: 'Mutation';
  saveNewMultifactor: {
    __typename?: 'MultiFactorDetails';
    factorWeights: Array<{
      __typename?: 'MultiFactorWeight';
      betterIfLower: boolean;
      metricId: number;
      weight: Decimal;
    }>;
    multifactor: {
      __typename?: 'MultiFactor';
      createdAt: string;
      description: string;
      name: string;
      updatedAt?: string | null;
      id: number;
    };
  };
};

export type IDeleteMultifactorMutationVariables = Exact<{
  multifactorId: Scalars['Int']['input'];
}>;

export type IDeleteMultifactorMutation = { __typename?: 'Mutation'; deleteMultifactor: string };

export type ICorrelationInputQueryVariables = Exact<{ [key: string]: never }>;

export type ICorrelationInputQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    positions: {
      __typename?: 'SubAccountPositions';
      positions: Array<{
        __typename?: 'SubAccountPositionSnapshot';
        asset: {
          __typename?: 'Asset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
          derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
        };
        spot?: { __typename?: 'SubAccountPositionSpotSnapshot'; balance: Decimal } | null;
      }>;
    };
  };
  assets: {
    __typename?: 'Assets';
    feature: Array<{
      __typename?: 'Asset';
      type: IAssetType;
      symbol: string;
      label?: Label | null;
      name?: string | null;
      icon?: string | null;
      id: string;
      exchangeDetails?: Label | null;
      derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
    }>;
  };
};

export type ICorrelationsQueryVariables = Exact<{
  input: ITwoAssetMetricRequestInput;
  filters?: InputMaybe<ITwoAssetMetricFilters>;
}>;

export type ICorrelationsQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'Assets';
    twoAssetMetrics: Array<{
      __typename?: 'TwoAssetMetric';
      date: UtcDate;
      value: number;
      primaryAsset: { __typename?: 'Asset'; id: string };
      secondaryAsset: { __typename?: 'Asset'; id: string };
    }>;
  };
};

export type ILatestCorrelationsQueryVariables = Exact<{
  input: ITwoAssetMetricRequestInput;
}>;

export type ILatestCorrelationsQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'Assets';
    twoAssetLatestMetrics: Array<{
      __typename?: 'TwoAssetMetric';
      date: UtcDate;
      value: number;
      primaryAsset: {
        __typename?: 'Asset';
        type: IAssetType;
        symbol: string;
        label?: Label | null;
        name?: string | null;
        icon?: string | null;
        id: string;
        exchangeDetails?: Label | null;
        derivativeDetails?: {
          __typename?: 'DerivativeDetails';
          exchange: Label;
          derivativeType: IDerivativeType;
          expirationDate?: string | null;
          optionType?: IOptionType | null;
          strikePrice?: Decimal | null;
          baseAsset: {
            __typename?: 'BaseAsset';
            type: IAssetType;
            symbol: string;
            label?: Label | null;
            name?: string | null;
            icon?: string | null;
            id: string;
            exchangeDetails?: Label | null;
          };
          quoteAsset: {
            __typename?: 'BaseAsset';
            type: IAssetType;
            symbol: string;
            label?: Label | null;
            name?: string | null;
            icon?: string | null;
            id: string;
            exchangeDetails?: Label | null;
          };
          settlementAsset: {
            __typename?: 'BaseAsset';
            type: IAssetType;
            symbol: string;
            label?: Label | null;
            name?: string | null;
            icon?: string | null;
            id: string;
            exchangeDetails?: Label | null;
          };
        } | null;
      };
      secondaryAsset: {
        __typename?: 'Asset';
        type: IAssetType;
        symbol: string;
        label?: Label | null;
        name?: string | null;
        icon?: string | null;
        id: string;
        exchangeDetails?: Label | null;
        derivativeDetails?: {
          __typename?: 'DerivativeDetails';
          exchange: Label;
          derivativeType: IDerivativeType;
          expirationDate?: string | null;
          optionType?: IOptionType | null;
          strikePrice?: Decimal | null;
          baseAsset: {
            __typename?: 'BaseAsset';
            type: IAssetType;
            symbol: string;
            label?: Label | null;
            name?: string | null;
            icon?: string | null;
            id: string;
            exchangeDetails?: Label | null;
          };
          quoteAsset: {
            __typename?: 'BaseAsset';
            type: IAssetType;
            symbol: string;
            label?: Label | null;
            name?: string | null;
            icon?: string | null;
            id: string;
            exchangeDetails?: Label | null;
          };
          settlementAsset: {
            __typename?: 'BaseAsset';
            type: IAssetType;
            symbol: string;
            label?: Label | null;
            name?: string | null;
            icon?: string | null;
            id: string;
            exchangeDetails?: Label | null;
          };
        } | null;
      };
    }>;
  };
};

export type IAssetDashboardQueryVariables = Exact<{
  filters: IAssetFilters;
}>;

export type IAssetDashboardQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'Assets';
    feature: Array<{
      __typename?: 'Asset';
      type: IAssetType;
      symbol: string;
      label?: Label | null;
      name?: string | null;
      icon?: string | null;
      id: string;
    }>;
    changes: Array<{
      __typename?: 'AssetChange';
      asset: string;
      price?: Decimal | null;
      metrics: Record<string, string>;
    }>;
    details: Array<{
      __typename?: 'AssetDetails';
      asset: string;
      metrics: Record<string, string>;
      price?: Decimal | null;
    }>;
    assetGroups: {
      __typename?: 'AssetGroups';
      genieGroups: Array<{
        __typename?: 'AssetGroup';
        id: string;
        clusterName: string;
        groupName: string;
        assets: Array<{
          __typename?: 'Asset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
        }>;
      }>;
      userGroups: Array<{
        __typename?: 'AssetGroup';
        id: string;
        clusterName: string;
        groupName: string;
        assets: Array<{
          __typename?: 'Asset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
        }>;
      }>;
    };
  };
};

export type IAssetClusterGroupPriceQueryVariables = Exact<{
  assetIds: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
  fromDate?: InputMaybe<Scalars['Date']['input']>;
}>;

export type IAssetClusterGroupPriceQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'Assets';
    price: Array<{
      __typename?: 'AssetPrice';
      asset: { __typename?: 'Asset'; id: string };
      rows: Array<{ __typename?: 'AssetPriceRow'; date: UtcDate; price: Decimal }>;
    }>;
  };
};

export type IFilterOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type IFilterOptionsQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'Assets';
    metricStats: {
      __typename?: 'MinMaxMetrics';
      liquidity?: { __typename?: 'MinMaxMetric'; min: Decimal; max: Decimal } | null;
      marketCap?: { __typename?: 'MinMaxMetric'; max: Decimal; min: Decimal } | null;
      totalValueLocked?: { __typename?: 'MinMaxMetric'; max: Decimal; min: Decimal } | null;
    };
  };
};

export type IAssetGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type IAssetGroupsQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'Assets';
    assetGroups: {
      __typename?: 'AssetGroups';
      userGroups: Array<{
        __typename?: 'AssetGroup';
        id: string;
        clusterName: string;
        groupName: string;
        createdAt: string;
        updatedAt?: string | null;
        assets: Array<{
          __typename?: 'Asset';
          id: string;
          icon?: string | null;
          label?: Label | null;
          name?: string | null;
          symbol: string;
          type: IAssetType;
          exchangeDetails?: Label | null;
        }>;
      }>;
    };
    feature: Array<{
      __typename?: 'Asset';
      id: string;
      icon?: string | null;
      label?: Label | null;
      name?: string | null;
      symbol: string;
      type: IAssetType;
      exchangeDetails?: Label | null;
    }>;
  };
};

export type ICreateAssetGroupMutationVariables = Exact<{
  input: ICreateAssetGroupInput;
}>;

export type ICreateAssetGroupMutation = {
  __typename?: 'Mutation';
  CreateAssetGroup: { __typename?: 'AssetGroup'; groupName: string };
};

export type IDeleteAssetGroupMutationVariables = Exact<{
  input: IDeleteAssetGroupInput;
}>;

export type IDeleteAssetGroupMutation = { __typename?: 'Mutation'; DeleteAssetGroup?: any | null };

export type IDeleteClusterAssetGroupsMutationVariables = Exact<{
  clusterName: Scalars['String']['input'];
}>;

export type IDeleteClusterAssetGroupsMutation = { __typename?: 'Mutation'; DeleteClusterAssetGroups?: any | null };

export type IRenameClusterMutationVariables = Exact<{
  oldClusterName: Scalars['String']['input'];
  newClusterName: Scalars['String']['input'];
}>;

export type IRenameClusterMutation = { __typename?: 'Mutation'; RenameCluster?: any | null };

export type IUpdateAssetGroupMutationVariables = Exact<{
  input: IUpdateAssetGroupInput;
}>;

export type IUpdateAssetGroupMutation = {
  __typename?: 'Mutation';
  UpdateAssetGroup: {
    __typename?: 'AssetGroup';
    id: string;
    groupName: string;
    clusterName: string;
    updatedAt?: string | null;
    createdAt: string;
    assets: Array<{
      __typename?: 'Asset';
      id: string;
      icon?: string | null;
      label?: Label | null;
      name?: string | null;
      symbol: string;
      type: IAssetType;
      exchangeDetails?: Label | null;
    }>;
  };
};

export type IBlacklistAssetMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type IBlacklistAssetMutation = { __typename?: 'Mutation'; BlacklistAsset?: any | null };

export type IBlacklistedAssetInputQueryVariables = Exact<{ [key: string]: never }>;

export type IBlacklistedAssetInputQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'Assets';
    blacklistedAssets: Array<{
      __typename?: 'Asset';
      type: IAssetType;
      symbol: string;
      label?: Label | null;
      name?: string | null;
      icon?: string | null;
      id: string;
      exchangeDetails?: Label | null;
      derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
    }>;
  };
};

export type IUnBlacklistAssetMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type IUnBlacklistAssetMutation = { __typename?: 'Mutation'; UnblacklistAsset?: any | null };

export type ICreatePrivateAssetMutationVariables = Exact<{
  input: ICreatePrivateAssetInput;
}>;

export type ICreatePrivateAssetMutation = {
  __typename?: 'Mutation';
  CreatePrivateAsset: { __typename?: 'Asset'; symbol: string; id: string; name?: string | null };
};

export type IDeletePrivateAssetMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type IDeletePrivateAssetMutation = { __typename?: 'Mutation'; DeletePrivateAsset?: any | null };

export type IPrivateAssetsQueryVariables = Exact<{ [key: string]: never }>;

export type IPrivateAssetsQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'Assets';
    list: Array<{ __typename?: 'Asset'; type: IAssetType; name?: string | null; id: string; symbol: string }>;
  };
};

export type IUpdatePrivateAssetMutationVariables = Exact<{
  input: IUpdatePrivateAssetInput;
}>;

export type IUpdatePrivateAssetMutation = {
  __typename?: 'Mutation';
  UpdatePrivateAsset: { __typename?: 'Asset'; name?: string | null; id: string; symbol: string };
};

export type ICreatePublicAssetRequestMutationVariables = Exact<{
  input: IAddAssetFromCoingeckoIdRequestInputType;
}>;

export type ICreatePublicAssetRequestMutation = { __typename?: 'Mutation'; AddAssetFromCoingeckoId?: any | null };

export type IPublicAssetRequestsQueryVariables = Exact<{ [key: string]: never }>;

export type IPublicAssetRequestsQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'Assets';
    publicAssetRequests: Array<{
      __typename?: 'PublicAssetRequestType';
      endTime?: string | null;
      startTime?: string | null;
      status: IAssetManagerStatusType;
      symbol: string;
    }>;
  };
};

export type ICalendarEventsQueryVariables = Exact<{
  assetId: Scalars['UUID']['input'];
  assetTypes: Array<IAssetType> | IAssetType;
}>;

export type ICalendarEventsQuery = {
  __typename?: 'Query';
  calendar: {
    __typename?: 'Calendar';
    calendarEvents: Array<{
      __typename?: 'CalendarEvent';
      id: string;
      time: string;
      description?: string | null;
      title: string;
    }>;
  };
  assets: {
    __typename?: 'Assets';
    list: Array<{
      __typename?: 'Asset';
      type: IAssetType;
      symbol: string;
      label?: Label | null;
      name?: string | null;
      icon?: string | null;
      id: string;
      exchangeDetails?: Label | null;
      derivativeDetails?: {
        __typename?: 'DerivativeDetails';
        exchange: Label;
        derivativeType: IDerivativeType;
        expirationDate?: string | null;
        optionType?: IOptionType | null;
        strikePrice?: Decimal | null;
        baseAsset: {
          __typename?: 'BaseAsset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
        };
        quoteAsset: {
          __typename?: 'BaseAsset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
        };
        settlementAsset: {
          __typename?: 'BaseAsset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
        };
      } | null;
    }>;
  };
};

export type ICreateCalendarEventMutationVariables = Exact<{
  input: ICalendarEventInput;
}>;

export type ICreateCalendarEventMutation = {
  __typename?: 'Mutation';
  CreateCalendarEvent: { __typename?: 'CalendarEvent'; id: string };
};

export type IDeleteCalendarEventMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type IDeleteCalendarEventMutation = { __typename?: 'Mutation'; DeleteCalendarEvent?: any | null };

export type IPrivateAssetPriceQueryVariables = Exact<{
  filters?: InputMaybe<IListAssetFilters>;
}>;

export type IPrivateAssetPriceQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'Assets';
    feature: Array<{ __typename?: 'Asset'; id: string; label?: Label | null }>;
    list: Array<{
      __typename?: 'Asset';
      type: IAssetType;
      symbol: string;
      label?: Label | null;
      name?: string | null;
      icon?: string | null;
      id: string;
      exchangeDetails?: Label | null;
      priceAsset: { __typename?: 'Asset'; id: string; symbol: string };
      derivativeDetails?: {
        __typename?: 'DerivativeDetails';
        exchange: Label;
        derivativeType: IDerivativeType;
        expirationDate?: string | null;
        optionType?: IOptionType | null;
        strikePrice?: Decimal | null;
        baseAsset: {
          __typename?: 'BaseAsset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
        };
        quoteAsset: {
          __typename?: 'BaseAsset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
        };
        settlementAsset: {
          __typename?: 'BaseAsset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
        };
      } | null;
    }>;
  };
};

export type ISinglePrivateAssetQueryVariables = Exact<{
  assetId: Scalars['UUID']['input'];
}>;

export type ISinglePrivateAssetQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'Assets';
    pricePerformance: Array<{
      __typename?: 'AssetPrice';
      rows: Array<{ __typename?: 'AssetPriceRow'; price: Decimal; date: UtcDate }>;
    }>;
  };
};

export type IAssetPriceChartQueryVariables = Exact<{
  assetIds: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
}>;

export type IAssetPriceChartQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'Assets';
    price: Array<{
      __typename?: 'AssetPrice';
      asset: { __typename?: 'Asset'; id: string; name?: string | null; symbol: string };
      rows: Array<{ __typename?: 'AssetPriceRow'; date: UtcDate; price: Decimal }>;
    }>;
  };
};

export type ICreateUpdateAssetPriceMutationVariables = Exact<{
  input: IAssetPriceInput;
}>;

export type ICreateUpdateAssetPriceMutation = { __typename?: 'Mutation'; UpdateAssetPrice?: any | null };

export type ICreateUpdateAssetPricesMutationVariables = Exact<{
  input: IAssetPriceBulkInput;
}>;

export type ICreateUpdateAssetPricesMutation = { __typename?: 'Mutation'; UpdateAssetPriceBulk?: any | null };

export type IDeleteAssetPriceMutationVariables = Exact<{
  input: IAssetPriceIdInput;
}>;

export type IDeleteAssetPriceMutation = { __typename?: 'Mutation'; DeleteAssetPrice?: any | null };

export type IAssetVestingQueryVariables = Exact<{
  assetId: Scalars['UUID']['input'];
}>;

export type IAssetVestingQuery = {
  __typename?: 'Query';
  bookkeeping: {
    __typename?: 'Bookkeeping';
    investments: {
      __typename?: 'Investments';
      assetBalance: {
        __typename?: 'AssetInvestmentBalance';
        investments: Array<{
          __typename?: 'InvestmentBalance';
          balance: Array<{ __typename?: 'BalanceAmount'; time: string; amount: Decimal }>;
          investment: { __typename?: 'Investment'; id: string; name: string };
        }>;
      };
      assetDetails: { __typename?: 'AssetInvestmentDetails'; unvested: Decimal; vested: Decimal };
    };
  };
};

export type IAssetMetricsQueryVariables = Exact<{
  assetId: Scalars['UUID']['input'];
  filters?: InputMaybe<IAssetMetricHistoryFilters>;
}>;

export type IAssetMetricsQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'Assets';
    metricHistory: Array<{
      __typename?: 'AssetMetricHistory';
      rows: Array<{ __typename?: 'AssetMetricHistoryRow'; date: UtcDate; metrics: Record<string, string> }>;
    }>;
  };
};

export type IAssetPerformanceQueryVariables = Exact<{
  assetId: Scalars['UUID']['input'];
  fromDate: Scalars['Date']['input'];
}>;

export type IAssetPerformanceQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'Assets';
    pricePerformance: Array<{
      __typename?: 'AssetPrice';
      rows: Array<{ __typename?: 'AssetPriceRow'; price: Decimal; date: UtcDate }>;
    }>;
    metricPerformance: Array<{
      __typename?: 'AssetMetricHistory';
      rows: Array<{ __typename?: 'AssetMetricHistoryRow'; metrics: Record<string, string>; date: UtcDate }>;
    }>;
  };
};

export type IAllDerivativesQueryVariables = Exact<{
  filters?: InputMaybe<IListAssetFilters>;
}>;

export type IAllDerivativesQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'Assets';
    list: Array<{
      __typename?: 'Asset';
      type: IAssetType;
      symbol: string;
      label?: Label | null;
      name?: string | null;
      icon?: string | null;
      id: string;
      exchangeDetails?: Label | null;
      derivativeDetails?: {
        __typename?: 'DerivativeDetails';
        exchange: Label;
        derivativeType: IDerivativeType;
        expirationDate?: string | null;
        optionType?: IOptionType | null;
        strikePrice?: Decimal | null;
        baseAsset: {
          __typename?: 'BaseAsset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
        };
        quoteAsset: {
          __typename?: 'BaseAsset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
        };
        settlementAsset: {
          __typename?: 'BaseAsset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
        };
      } | null;
    }>;
  };
};

export type IDerivativeDetailsQueryVariables = Exact<{
  filters: IAssetFilters;
}>;

export type IDerivativeDetailsQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'Assets';
    details: Array<{
      __typename?: 'AssetDetails';
      metrics: Record<string, string>;
      asset: string;
      price?: Decimal | null;
      minPrice?: Decimal | null;
      maxPrice?: Decimal | null;
    }>;
    changes: Array<{
      __typename?: 'AssetChange';
      metrics: Record<string, string>;
      asset: string;
      price?: Decimal | null;
    }>;
  };
};

export type INewsBulletPointsQueryVariables = Exact<{
  paging: IPageLimitOffsetInput;
  filters?: InputMaybe<IBulletPointFilters>;
}>;

export type INewsBulletPointsQuery = {
  __typename?: 'Query';
  news: {
    __typename?: 'NewsType';
    bulletPoints: {
      __typename?: 'AssetBulletPoints';
      bulletPoints: {
        __typename?: 'BulletPointOffsetLimitPage';
        pageInfo: { __typename?: 'PageOffsetInfo'; totalResults: number };
        data: Array<{
          __typename?: 'BulletPoint';
          id: string;
          publishedAt: string;
          url: string;
          summary: string;
          isTrending: boolean;
          sentiment?: ISentiment | null;
          reaction?: IReactionType | null;
          title?: string | null;
          asset: {
            __typename?: 'Asset';
            type: IAssetType;
            symbol: string;
            label?: Label | null;
            name?: string | null;
            icon?: string | null;
            id: string;
            exchangeDetails?: Label | null;
            derivativeDetails?: {
              __typename?: 'DerivativeDetails';
              exchange: Label;
              derivativeType: IDerivativeType;
              expirationDate?: string | null;
              optionType?: IOptionType | null;
              strikePrice?: Decimal | null;
              baseAsset: {
                __typename?: 'BaseAsset';
                type: IAssetType;
                symbol: string;
                label?: Label | null;
                name?: string | null;
                icon?: string | null;
                id: string;
                exchangeDetails?: Label | null;
              };
              quoteAsset: {
                __typename?: 'BaseAsset';
                type: IAssetType;
                symbol: string;
                label?: Label | null;
                name?: string | null;
                icon?: string | null;
                id: string;
                exchangeDetails?: Label | null;
              };
              settlementAsset: {
                __typename?: 'BaseAsset';
                type: IAssetType;
                symbol: string;
                label?: Label | null;
                name?: string | null;
                icon?: string | null;
                id: string;
                exchangeDetails?: Label | null;
              };
            } | null;
          };
          newsFeed?: { __typename?: 'NewsFeed'; id: number; humanName: string; url: string } | null;
        }>;
      };
    };
  };
};

export type IExchangesQueryVariables = Exact<{ [key: string]: never }>;

export type IExchangesQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'Assets';
    options: { __typename?: 'Options'; exchanges: Array<{ __typename?: 'DataSource'; label: Label }> };
  };
};

export type IOptionsQueryVariables = Exact<{
  exchanges?: InputMaybe<Array<Scalars['LabelType']['input']> | Scalars['LabelType']['input']>;
  expirationSince?: InputMaybe<Scalars['ArrowType']['input']>;
  baseAssetId: Scalars['UUID']['input'];
}>;

export type IOptionsQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'Assets';
    options: {
      __typename?: 'Options';
      pricedOptions: Array<{
        __typename?: 'PricedOption';
        underlyingPrice: Decimal;
        time: string;
        openInterest?: Decimal | null;
        option: {
          __typename?: 'Asset';
          id: string;
          symbol: string;
          derivativeDetails?: {
            __typename?: 'DerivativeDetails';
            derivativeType: IDerivativeType;
            expirationDate?: string | null;
            strikePrice?: Decimal | null;
            optionType?: IOptionType | null;
            exchange: Label;
            settlementAsset: { __typename?: 'BaseAsset'; symbol: string };
            baseAsset: { __typename?: 'BaseAsset'; symbol: string };
            quoteAsset: { __typename?: 'BaseAsset'; symbol: string };
          } | null;
        };
        askFactors?: {
          __typename?: 'PricedOptionFactors';
          price: Decimal;
          iv?: Decimal | null;
          greeks: {
            __typename?: 'Greeks';
            delta: Decimal;
            gamma: Decimal;
            rho: Decimal;
            theta: Decimal;
            vega: Decimal;
          };
        } | null;
        bidFactors?: {
          __typename?: 'PricedOptionFactors';
          price: Decimal;
          iv?: Decimal | null;
          greeks: {
            __typename?: 'Greeks';
            delta: Decimal;
            gamma: Decimal;
            rho: Decimal;
            theta: Decimal;
            vega: Decimal;
          };
        } | null;
        markFactors?: {
          __typename?: 'PricedOptionFactors';
          price: Decimal;
          iv?: Decimal | null;
          greeks: {
            __typename?: 'Greeks';
            delta: Decimal;
            gamma: Decimal;
            rho: Decimal;
            theta: Decimal;
            vega: Decimal;
          };
        } | null;
      }>;
    };
  };
};

export type IPublicAssetForecastChartQueryVariables = Exact<{
  assetId: Scalars['UUID']['input'];
  asset: Scalars['LabelType']['input'];
  fromDate?: InputMaybe<Scalars['Date']['input']>;
}>;

export type IPublicAssetForecastChartQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'Assets';
    forecast: Array<{
      __typename?: 'AssetPriceForecastRow';
      date: UtcDate;
      value: Decimal;
      highConfidence: Decimal;
      lowConfidence: Decimal;
    }>;
    price: Array<{
      __typename?: 'AssetPrice';
      rows: Array<{ __typename?: 'AssetPriceRow'; date: UtcDate; price: Decimal }>;
    }>;
  };
};

export type IPublicAssetHoldingsQueryVariables = Exact<{
  assetId: Scalars['UUID']['input'];
  fromTime?: InputMaybe<Scalars['Date']['input']>;
}>;

export type IPublicAssetHoldingsQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    snapshot: Array<{
      __typename?: 'SubAccountSnapshot';
      date: UtcDate;
      positions: Array<{
        __typename?: 'SubAccountPositionSnapshot';
        spot?: {
          __typename?: 'SubAccountPositionSpotSnapshot';
          price: Decimal;
          amount: Decimal;
          balance: Decimal;
        } | null;
        asset: { __typename?: 'Asset'; id: string; symbol: string };
        subAccount: { __typename?: 'SubAccount'; name: string; account: { __typename?: 'Account'; name: string } };
      }>;
    }>;
  };
};

export type IPublicAssetSpotQueryVariables = Exact<{
  assetId: Scalars['UUID']['input'];
}>;

export type IPublicAssetSpotQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'Assets';
    list: Array<{
      __typename?: 'Asset';
      type: IAssetType;
      symbol: string;
      label?: Label | null;
      name?: string | null;
      icon?: string | null;
      id: string;
      exchangeDetails?: Label | null;
      derivativeDetails?: {
        __typename?: 'DerivativeDetails';
        exchange: Label;
        derivativeType: IDerivativeType;
        expirationDate?: string | null;
        optionType?: IOptionType | null;
        strikePrice?: Decimal | null;
        baseAsset: {
          __typename?: 'BaseAsset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
        };
        quoteAsset: {
          __typename?: 'BaseAsset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
        };
        settlementAsset: {
          __typename?: 'BaseAsset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
        };
      } | null;
    }>;
    availableMetrics: Array<{ __typename?: 'Metric'; label: Label }>;
    feature: Array<{
      __typename?: 'Asset';
      type: IAssetType;
      symbol: string;
      label?: Label | null;
      name?: string | null;
      icon?: string | null;
      id: string;
      exchangeDetails?: Label | null;
      derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
    }>;
  };
};

export type ISinglePublicAssetQueryVariables = Exact<{
  asset: Scalars['UUID']['input'];
}>;

export type ISinglePublicAssetQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'Assets';
    details: Array<{
      __typename?: 'AssetDetails';
      asset: string;
      metrics: Record<string, string>;
      price?: Decimal | null;
      maxPrice?: Decimal | null;
      minPrice?: Decimal | null;
    }>;
    changes: Array<{
      __typename?: 'AssetChange';
      asset: string;
      price?: Decimal | null;
      metrics: Record<string, string>;
    }>;
    assetGroups: {
      __typename?: 'AssetGroups';
      genieGroups: Array<{
        __typename?: 'AssetGroup';
        clusterName: string;
        groupName: string;
        assets: Array<{
          __typename?: 'Asset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
          derivativeDetails?: {
            __typename?: 'DerivativeDetails';
            exchange: Label;
            derivativeType: IDerivativeType;
            expirationDate?: string | null;
            optionType?: IOptionType | null;
            strikePrice?: Decimal | null;
            baseAsset: {
              __typename?: 'BaseAsset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
            };
            quoteAsset: {
              __typename?: 'BaseAsset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
            };
            settlementAsset: {
              __typename?: 'BaseAsset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
            };
          } | null;
        }>;
      }>;
    };
  };
};

export type IUpdateBulletPointReactionMutationVariables = Exact<{
  bulletPointReactionInput: IBulletPointReactionInput;
}>;

export type IUpdateBulletPointReactionMutation = { __typename?: 'Mutation'; UpdateBulletPointReaction?: any | null };

export type IMarketRegimeQueryVariables = Exact<{
  modelIds: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
  since?: InputMaybe<Scalars['Date']['input']>;
  to?: InputMaybe<Scalars['Date']['input']>;
}>;

export type IMarketRegimeQuery = {
  __typename?: 'Query';
  regime: {
    __typename?: 'RegimeType';
    historicalPredictions: Array<{
      __typename?: 'ModelPrediction';
      model: { __typename?: 'MarketModel'; name: string; id: string };
      timeseries: Array<{
        __typename?: 'TimeSeriesValue';
        date: UtcDate;
        values: Array<{ __typename?: 'TimeSeriesData'; name: Label; value: number }>;
      }>;
    }>;
  };
};

export type IMarketRegimeBenchmarkPriceQueryVariables = Exact<{
  assetIds: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
  since: Scalars['Date']['input'];
  to: Scalars['Date']['input'];
}>;

export type IMarketRegimeBenchmarkPriceQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'Assets';
    price: Array<{
      __typename?: 'AssetPrice';
      asset: { __typename?: 'Asset'; id: string; symbol: string };
      rows: Array<{ __typename?: 'AssetPriceRow'; date: UtcDate; price: Decimal }>;
    }>;
  };
};

export type IMarketRegimeInputQueryVariables = Exact<{ [key: string]: never }>;

export type IMarketRegimeInputQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'Assets';
    list: Array<{
      __typename?: 'Asset';
      type: IAssetType;
      symbol: string;
      label?: Label | null;
      name?: string | null;
      icon?: string | null;
      id: string;
      exchangeDetails?: Label | null;
      derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
    }>;
  };
  portfolio: {
    __typename?: 'Portfolio';
    supportedRegimeDefinitions: Array<{
      __typename?: 'RegimeDefinitions';
      id: string;
      name: string;
      benchmark?: { __typename?: 'Asset'; id: string; name?: string | null; symbol: string } | null;
    }>;
  };
};

export type IPortfolioMetricsQueryVariables = Exact<{
  input: IPortfolioRiskMetricsInput;
}>;

export type IPortfolioMetricsQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    metrics: Array<{
      __typename?: 'CalculatorMetricSeriesOutput';
      label: Label;
      portfolioDefinition?: { __typename?: 'PortfolioDefinition'; id: string; name: string } | null;
      series: Array<{
        __typename?: 'CalculatorSeries';
        hasContributions: boolean;
        issues: Array<{ __typename?: 'IssueItem'; issueType: IIssue; asset: { __typename?: 'Asset'; symbol: string } }>;
        parameters: Array<{
          __typename?: 'ParameterOutput';
          name: string;
          intValue?: number | null;
          strValue?: string | null;
          asset?: { __typename?: 'Asset'; symbol: string; id: string } | null;
        }>;
        values: Array<{ __typename?: 'SerieValue'; value: Decimal; date: UtcDate }>;
      }>;
    }>;
  };
};

export type IAccountFieldsFragment = {
  __typename?: 'Account';
  id: string;
  name: string;
  subAccounts: Array<{ __typename?: 'SubAccount'; name: string; id: string }>;
  venue: { __typename?: 'DataSource'; label: Label };
};

export type IAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type IAccountsQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    accounts: Array<{
      __typename?: 'Account';
      id: string;
      name: string;
      createdAt: string;
      venue: { __typename?: 'DataSource'; label: Label };
      snapshotSyncStatus: {
        __typename?: 'AccountSnapshotSyncStatus';
        latestSuccessfulTime?: string | null;
        latestCompleted?: {
          __typename?: 'AccountSnapshotSyncResult';
          time: string;
          resultCode: ISyncRequestResultCode;
        } | null;
      };
      subAccounts: Array<{
        __typename?: 'SubAccount';
        name: string;
        id: string;
        active: boolean;
        externalId?: string | null;
        account: { __typename?: 'Account'; venue: { __typename?: 'DataSource'; label: Label } };
      }>;
    }>;
  };
};

export type ICanRequestPortfolioSnapshotQueryVariables = Exact<{ [key: string]: never }>;

export type ICanRequestPortfolioSnapshotQuery = {
  __typename?: 'Query';
  portfolio: { __typename?: 'Portfolio'; canRequestPortfolioSnapshot: boolean };
};

export type ICreateAccountMutationVariables = Exact<{
  input: ICreateAccountInput;
}>;

export type ICreateAccountMutation = { __typename?: 'Mutation'; CreateAccount: { __typename?: 'Account'; id: string } };

export type IDeleteAccountMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type IDeleteAccountMutation = { __typename?: 'Mutation'; DeleteAccount?: any | null };

export type IRequestPortfolioSnapshotMutationVariables = Exact<{ [key: string]: never }>;

export type IRequestPortfolioSnapshotMutation = { __typename?: 'Mutation'; RequestPortfolioSnapshot: Array<string> };

export type ISubAccountLabelFieldsFragment = {
  __typename?: 'SubAccount';
  id: string;
  name: string;
  account: { __typename?: 'Account'; id: string; name: string; venue: { __typename?: 'DataSource'; label: Label } };
};

export type IUpdateAccountMutationVariables = Exact<{
  input: IUpdateNameInput;
}>;

export type IUpdateAccountMutation = { __typename?: 'Mutation'; UpdateAccount: { __typename?: 'Account'; id: string } };

export type IUpdateAccountApiKeysMutationVariables = Exact<{
  input: IUpdateApiKeysInput;
}>;

export type IUpdateAccountApiKeysMutation = {
  __typename?: 'Mutation';
  UpdateAccountApiKeys: { __typename?: 'Account'; id: string };
};

export type IPortfolioDashboardInputQueryVariables = Exact<{
  subAccountAssetFilters: ISubAccountAssetFilterInputType;
  tilesSettings: Scalars['String']['input'];
}>;

export type IPortfolioDashboardInputQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    accounts: Array<{
      __typename?: 'Account';
      id: string;
      name: string;
      subAccounts: Array<{ __typename?: 'SubAccount'; name: string; id: string }>;
      venue: { __typename?: 'DataSource'; label: Label };
    }>;
    positions: {
      __typename?: 'SubAccountPositions';
      positions: Array<{
        __typename?: 'SubAccountPositionSnapshot';
        asset: {
          __typename?: 'Asset';
          type: IAssetType;
          id: string;
          symbol: string;
          label?: Label | null;
          icon?: string | null;
          name?: string | null;
          exchangeDetails?: Label | null;
          derivativeDetails?: {
            __typename?: 'DerivativeDetails';
            exchange: Label;
            baseAsset: { __typename?: 'BaseAsset'; id: string };
          } | null;
        };
        subAccount: {
          __typename?: 'SubAccount';
          id: string;
          name: string;
          account: {
            __typename?: 'Account';
            id: string;
            name: string;
            venue: { __typename?: 'DataSource'; label: Label };
          };
        };
        spot?: {
          __typename?: 'SubAccountPositionSpotSnapshot';
          price: Decimal;
          amount: Decimal;
          balance: Decimal;
        } | null;
        derivative?: {
          __typename?: 'SubAccountPositionDerivativeSnapshot';
          amount: Decimal;
          side: IPositionSide;
          notional?: Decimal | null;
          unrealizedPnl?: Decimal | null;
          balance?: Decimal | null;
          unitMarketPrice?: Decimal | null;
          unitEntryPrice?: Decimal | null;
        } | null;
      }>;
    };
    journal: {
      __typename?: 'Journal';
      preferences: { __typename?: 'JournalPreferences'; dimension?: string | null };
      costBasis: Array<{
        __typename?: 'AssetCostBasis';
        costBasis: Decimal;
        asset: { __typename?: 'Asset'; id: string };
      }>;
    };
    subFunds: {
      __typename?: 'SubFunds';
      list: Array<{
        __typename?: 'SubFund';
        id: number;
        name: string;
        dimension: string;
        subAccounts: Array<{ __typename?: 'SubAccount'; id: string; name: string }>;
        accounts: Array<{
          __typename?: 'Account';
          id: string;
          name: string;
          subAccounts: Array<{ __typename?: 'SubAccount'; id: string }>;
        }>;
      }>;
    };
    metricParameters: Array<{
      __typename?: 'PortfolioMetricDescription';
      label: Label;
      parameters: Array<{
        __typename?: 'ParameterDescription';
        name: string;
        type: IParameterType;
        minMax?: { __typename?: 'MinMaxValue'; max: number; min: number } | null;
        options?: Array<{ __typename?: 'OptionValue'; value: string }> | null;
        benchmarks?: Array<{ __typename?: 'Asset'; id: string; symbol: string }> | null;
      }>;
    }>;
  };
  assets: { __typename?: 'Assets'; feature: Array<{ __typename?: 'Asset'; id: string; symbol: string }> };
  management: { __typename?: 'Management'; userSettings?: any | null };
};

export type IPortfolioExposureMetricsQueryVariables = Exact<{
  input: IPortfolioRiskMetricsInput;
}>;

export type IPortfolioExposureMetricsQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    metrics: Array<{
      __typename?: 'CalculatorMetricSeriesOutput';
      label: Label;
      portfolioDefinition?: { __typename?: 'PortfolioDefinition'; id: string; name: string } | null;
      series: Array<{
        __typename?: 'CalculatorSeries';
        hasContributions: boolean;
        issues: Array<{ __typename?: 'IssueItem'; issueType: IIssue; asset: { __typename?: 'Asset'; symbol: string } }>;
        parameters: Array<{
          __typename?: 'ParameterOutput';
          name: string;
          intValue?: number | null;
          strValue?: string | null;
          asset?: { __typename?: 'Asset'; symbol: string; id: string } | null;
        }>;
        values: Array<{ __typename?: 'SerieValue'; value: Decimal; date: UtcDate }>;
      }>;
    }>;
  };
};

export type IPortfolioExposureRiskQueryVariables = Exact<{
  subAccountAssetFilters: ISubAccountAssetFilterInputType;
}>;

export type IPortfolioExposureRiskQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    positions: {
      __typename?: 'SubAccountPositions';
      positions: Array<{
        __typename?: 'SubAccountPositionSnapshot';
        asset: {
          __typename?: 'Asset';
          id: string;
          symbol: string;
          type: IAssetType;
          label?: Label | null;
          icon?: string | null;
          name?: string | null;
          exchangeDetails?: Label | null;
          derivativeDetails?: {
            __typename?: 'DerivativeDetails';
            exchange: Label;
            derivativeType: IDerivativeType;
            baseAsset: { __typename?: 'BaseAsset'; id: string };
          } | null;
        };
      }>;
      summary: {
        __typename?: 'Summary';
        balance: { __typename?: 'Allocation'; total: Decimal };
        exposure: { __typename?: 'Allocation'; net: Decimal; long: Decimal; short: Decimal };
        balanceByVenue: Array<{ __typename?: 'BalanceByVenue'; venue: Label; name: string; balance: Decimal }>;
      };
    };
  };
};

export type IPortfolioTwrQueryVariables = Exact<{
  dates?: InputMaybe<IDateRangeFilter>;
  subAccountAssetFilters: ISubAccountAssetFilterInputType;
}>;

export type IPortfolioTwrQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    portfolioTimeWeightedReturns: {
      __typename?: 'PortfolioTimeWeightedReturnsType';
      values: ITimeWeightedReturnType[];
    };
  };
};

export type ISubAccountSnapshotQueryVariables = Exact<{
  dates?: InputMaybe<IDateRangeFilter>;
  subAccountAssetFilters: ISubAccountAssetFilterInputType;
}>;

export type ISubAccountSnapshotQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    snapshot: Array<{
      __typename?: 'SubAccountSnapshot';
      date: UtcDate;
      positions: Array<{
        __typename?: 'SubAccountPositionSnapshot';
        asset: {
          __typename?: 'Asset';
          id: string;
          symbol: string;
          type: IAssetType;
          label?: Label | null;
          exchangeDetails?: Label | null;
          derivativeDetails?: {
            __typename?: 'DerivativeDetails';
            baseAsset: { __typename?: 'BaseAsset'; id: string; symbol: string };
          } | null;
        };
        spot?: {
          __typename?: 'SubAccountPositionSpotSnapshot';
          price: Decimal;
          amount: Decimal;
          balance: Decimal;
        } | null;
        derivative?: {
          __typename?: 'SubAccountPositionDerivativeSnapshot';
          notional?: Decimal | null;
          side: IPositionSide;
          unrealizedPnl?: Decimal | null;
          balance?: Decimal | null;
        } | null;
        subAccount: {
          __typename?: 'SubAccount';
          name: string;
          id: string;
          account: {
            __typename?: 'Account';
            id: string;
            name: string;
            venue: { __typename?: 'DataSource'; label: Label };
          };
        };
      }>;
    }>;
  };
  assets: {
    __typename?: 'Assets';
    assetGroups: {
      __typename?: 'AssetGroups';
      genieGroups: Array<{
        __typename?: 'AssetGroup';
        clusterName: string;
        groupName: string;
        assets: Array<{ __typename?: 'Asset'; id: string }>;
      }>;
      userGroups: Array<{
        __typename?: 'AssetGroup';
        clusterName: string;
        groupName: string;
        assets: Array<{ __typename?: 'Asset'; id: string }>;
      }>;
    };
  };
};

export type ICurrentExposureInputQueryVariables = Exact<{
  subAccountAssetFilters?: InputMaybe<ISubAccountAssetFilterInputType>;
}>;

export type ICurrentExposureInputQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    positions: {
      __typename?: 'SubAccountPositions';
      positions: Array<{
        __typename?: 'SubAccountPositionSnapshot';
        asset: {
          __typename?: 'Asset';
          id: string;
          symbol: string;
          type: IAssetType;
          label?: Label | null;
          exchangeDetails?: Label | null;
          derivativeDetails?: {
            __typename?: 'DerivativeDetails';
            baseAsset: { __typename?: 'BaseAsset'; id: string; symbol: string };
          } | null;
        };
        spot?: {
          __typename?: 'SubAccountPositionSpotSnapshot';
          price: Decimal;
          amount: Decimal;
          balance: Decimal;
        } | null;
        derivative?: {
          __typename?: 'SubAccountPositionDerivativeSnapshot';
          notional?: Decimal | null;
          side: IPositionSide;
          unrealizedPnl?: Decimal | null;
          balance?: Decimal | null;
        } | null;
        subAccount: {
          __typename?: 'SubAccount';
          name: string;
          id: string;
          account: {
            __typename?: 'Account';
            id: string;
            name: string;
            venue: { __typename?: 'DataSource'; label: Label };
          };
        };
      }>;
    };
  };
  assets: {
    __typename?: 'Assets';
    assetGroups: {
      __typename?: 'AssetGroups';
      genieGroups: Array<{
        __typename?: 'AssetGroup';
        clusterName: string;
        groupName: string;
        assets: Array<{ __typename?: 'Asset'; id: string }>;
      }>;
      userGroups: Array<{
        __typename?: 'AssetGroup';
        clusterName: string;
        groupName: string;
        assets: Array<{ __typename?: 'Asset'; id: string }>;
      }>;
    };
  };
};

export type ICurrentExposureSettingsInputQueryVariables = Exact<{ [key: string]: never }>;

export type ICurrentExposureSettingsInputQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'Assets';
    assetGroups: {
      __typename?: 'AssetGroups';
      genieGroups: Array<{
        __typename?: 'AssetGroup';
        clusterName: string;
        groupName: string;
        assets: Array<{ __typename?: 'Asset'; id: string }>;
      }>;
      userGroups: Array<{
        __typename?: 'AssetGroup';
        clusterName: string;
        groupName: string;
        assets: Array<{ __typename?: 'Asset'; id: string }>;
      }>;
    };
  };
};

export type IPortfolioReturnsTileInputQueryVariables = Exact<{
  returnsSince: Scalars['Date']['input'];
  subAccountAssetFilters: ISubAccountAssetFilterInputType;
}>;

export type IPortfolioReturnsTileInputQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    portfolioTimeWeightedReturns: {
      __typename?: 'PortfolioTimeWeightedReturnsType';
      values: ITimeWeightedReturnType[];
    };
  };
};

export type IPortfolioTilesMetricsQueryVariables = Exact<{
  metrics: Array<IMetricCalculatorInput> | IMetricCalculatorInput;
  metricsSince: Scalars['Date']['input'];
  metricsTo: Scalars['Date']['input'];
  subAccountAssetFilters: ISubAccountAssetFilterInputType;
}>;

export type IPortfolioTilesMetricsQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    metrics: Array<{
      __typename?: 'CalculatorMetricSeriesOutput';
      label: Label;
      series: Array<{
        __typename?: 'CalculatorSeries';
        values: Array<{ __typename?: 'SerieValue'; date: UtcDate; value: Decimal }>;
      }>;
    }>;
  };
};

export type IPortfolioTwrEquityTileInputQueryVariables = Exact<{
  twrSince: Scalars['Date']['input'];
  twrTo: Scalars['Date']['input'];
  subAccountAssetFilters: ISubAccountAssetFilterInputType;
}>;

export type IPortfolioTwrEquityTileInputQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    portfolioTimeWeightedReturns: {
      __typename?: 'PortfolioTimeWeightedReturnsType';
      values: ITimeWeightedReturnType[];
    };
  };
};

export type IPortfolioVolatilityTileInputQueryVariables = Exact<{
  subAccountAssetFilters?: InputMaybe<ISubAccountAssetFilterInputType>;
}>;

export type IPortfolioVolatilityTileInputQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    metricParameters: Array<{
      __typename?: 'PortfolioMetricDescription';
      label: Label;
      parameters: Array<{
        __typename?: 'ParameterDescription';
        name: string;
        type: IParameterType;
        minMax?: { __typename?: 'MinMaxValue'; max: number; min: number } | null;
        options?: Array<{ __typename?: 'OptionValue'; value: string }> | null;
        benchmarks?: Array<{ __typename?: 'Asset'; id: string; symbol: string }> | null;
      }>;
    }>;
  };
};

export type ITwrEquityWidgetInputQueryVariables = Exact<{
  tileSettings: Scalars['String']['input'];
}>;

export type ITwrEquityWidgetInputQuery = {
  __typename?: 'Query';
  management: { __typename?: 'Management'; userSettings?: any | null };
};

export type ICreateSubFundMutationVariables = Exact<{
  subFund: ICreateSubFund;
}>;

export type ICreateSubFundMutation = { __typename?: 'Mutation'; CreateSubFund: { __typename?: 'SubFund'; id: number } };

export type IDeleteDimensionSubFundsMutationVariables = Exact<{
  dimension: Scalars['String']['input'];
}>;

export type IDeleteDimensionSubFundsMutation = { __typename?: 'Mutation'; DeleteDimensionSubFunds?: any | null };

export type IDeleteSubFundMutationVariables = Exact<{
  subFund: Scalars['String']['input'];
}>;

export type IDeleteSubFundMutation = { __typename?: 'Mutation'; DeleteSubFund?: any | null };

export type IRenameDimensionMutationVariables = Exact<{
  oldDimension: Scalars['String']['input'];
  newDimension: Scalars['String']['input'];
}>;

export type IRenameDimensionMutation = { __typename?: 'Mutation'; RenameDimension?: any | null };

export type ISubFundToSubAccountFieldsFragment = {
  __typename?: 'SubFund';
  id: number;
  name: string;
  dimension: string;
  subAccounts: Array<{ __typename?: 'SubAccount'; id: string }>;
  accounts: Array<{
    __typename?: 'Account';
    id: string;
    subAccounts: Array<{ __typename?: 'SubAccount'; id: string }>;
  }>;
};

export type ISubFundsQueryVariables = Exact<{ [key: string]: never }>;

export type ISubFundsQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    subFunds: {
      __typename?: 'SubFunds';
      list: Array<{
        __typename?: 'SubFund';
        isDefault: boolean;
        id: number;
        name: string;
        dimension: string;
        createdAt: string;
        updatedAt?: string | null;
        referenceAsset: {
          __typename?: 'Asset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
          derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
        };
        accounts: Array<{
          __typename?: 'Account';
          id: string;
          name: string;
          venue: { __typename?: 'DataSource'; label: Label };
        }>;
      }>;
    };
    accounts: Array<{
      __typename?: 'Account';
      id: string;
      name: string;
      createdAt: string;
      venue: { __typename?: 'DataSource'; label: Label };
      subAccounts: Array<{ __typename?: 'SubAccount'; name: string; id: string }>;
    }>;
  };
  assets: {
    __typename?: 'Assets';
    feature: Array<{
      __typename?: 'Asset';
      type: IAssetType;
      symbol: string;
      label?: Label | null;
      name?: string | null;
      icon?: string | null;
      id: string;
      exchangeDetails?: Label | null;
      derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
    }>;
  };
};

export type IUpdateSubFundMutationVariables = Exact<{
  input: IUpdateSubFundInput;
}>;

export type IUpdateSubFundMutation = { __typename?: 'Mutation'; UpdateSubFund: { __typename?: 'SubFund'; id: number } };

export type IAllAssetGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type IAllAssetGroupsQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'Assets';
    assetGroups: {
      __typename?: 'AssetGroups';
      genieGroups: Array<{
        __typename?: 'AssetGroup';
        clusterName: string;
        groupName: string;
        assets: Array<{ __typename?: 'Asset'; id: string }>;
      }>;
      userGroups: Array<{
        __typename?: 'AssetGroup';
        clusterName: string;
        groupName: string;
        assets: Array<{ __typename?: 'Asset'; id: string }>;
      }>;
    };
  };
};

export type IOpenPositionsSummaryCostBasisFragment = {
  __typename?: 'Journal';
  preferences: { __typename?: 'JournalPreferences'; dimension?: string | null };
  costBasis: Array<{
    __typename?: 'AssetCostBasis';
    costBasis: Decimal;
    asset: { __typename?: 'Asset'; id: string };
    subFund?: { __typename?: 'SubFund'; id: number } | null;
  }>;
};

export type IOpenPositionsSummaryQueryVariables = Exact<{
  subAccountAssetFilters: ISubAccountAssetFilterInputType;
}>;

export type IOpenPositionsSummaryQuery = {
  __typename?: 'Query';
  assets: { __typename?: 'Assets'; feature: Array<{ __typename?: 'Asset'; id: string; symbol: string }> };
  portfolio: {
    __typename?: 'Portfolio';
    positions: {
      __typename?: 'SubAccountPositions';
      summary: { __typename?: 'Summary'; balance: { __typename?: 'Allocation'; total: Decimal } };
      positions: Array<{
        __typename?: 'SubAccountPositionSnapshot';
        subAccount: {
          __typename?: 'SubAccount';
          id: string;
          name: string;
          account: {
            __typename?: 'Account';
            id: string;
            name: string;
            venue: { __typename?: 'DataSource'; label: Label };
          };
        };
        asset: {
          __typename?: 'Asset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
          derivativeDetails?: {
            __typename?: 'DerivativeDetails';
            derivativeType: IDerivativeType;
            exchange: Label;
            expirationDate?: string | null;
            optionType?: IOptionType | null;
            strikePrice?: Decimal | null;
            baseAsset: {
              __typename?: 'BaseAsset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
            };
            quoteAsset: {
              __typename?: 'BaseAsset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
            };
            settlementAsset: {
              __typename?: 'BaseAsset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
            };
          } | null;
        };
        spot?: {
          __typename?: 'SubAccountPositionSpotSnapshot';
          price: Decimal;
          amount: Decimal;
          balance: Decimal;
          slot: ISlotType;
        } | null;
        derivative?: {
          __typename?: 'SubAccountPositionDerivativeSnapshot';
          amount: Decimal;
          notional?: Decimal | null;
          side: IPositionSide;
          unrealizedPnl?: Decimal | null;
          balance?: Decimal | null;
          unitEntryPrice?: Decimal | null;
          initialMargin?: Decimal | null;
          leverage: Decimal;
          maintenanceMargin?: Decimal | null;
        } | null;
      }>;
    };
    subFunds: {
      __typename?: 'SubFunds';
      list: Array<{
        __typename?: 'SubFund';
        id: number;
        name: string;
        dimension: string;
        subAccounts: Array<{ __typename?: 'SubAccount'; id: string }>;
        accounts: Array<{
          __typename?: 'Account';
          id: string;
          subAccounts: Array<{ __typename?: 'SubAccount'; id: string }>;
        }>;
      }>;
    };
    journal: {
      __typename?: 'Journal';
      preferences: { __typename?: 'JournalPreferences'; dimension?: string | null };
      costBasis: Array<{
        __typename?: 'AssetCostBasis';
        costBasis: Decimal;
        asset: { __typename?: 'Asset'; id: string };
        subFund?: { __typename?: 'SubFund'; id: number } | null;
      }>;
    };
  };
};

export type IPricesForPositionsQueryVariables = Exact<{
  assetIds: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
}>;

export type IPricesForPositionsQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'Assets';
    priceSummary: Array<{
      __typename?: 'AssetPriceSummary';
      today?: Decimal | null;
      firstDayOfMonth?: Decimal | null;
      firstDayOfQuarter?: Decimal | null;
      firstDayOfYear?: Decimal | null;
      asset: { __typename?: 'Asset'; id: string };
    }>;
    changes: Array<{ __typename?: 'AssetChange'; assetId: string; priceChange?: Decimal | null }>;
  };
};

export type IReturnsAndAttributionQueryVariables = Exact<{
  clusterType: Scalars['String']['input'];
  since?: InputMaybe<Scalars['Date']['input']>;
  to?: InputMaybe<Scalars['Date']['input']>;
  subAccountAssetFilters: ISubAccountAssetFilterInputType;
}>;

export type IReturnsAndAttributionQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    performanceClusterAttributionsDaily: {
      __typename?: 'DailyPerformanceByClusterAndSide';
      short: Array<{
        __typename?: 'DatedCategoryValues';
        date: UtcDate;
        categoryValues: Array<{ __typename?: 'CategoryValue'; category: string; value: number }>;
      }>;
      long: Array<{
        __typename?: 'DatedCategoryValues';
        date: UtcDate;
        categoryValues: Array<{ __typename?: 'CategoryValue'; category: string; value: number }>;
      }>;
      total: Array<{
        __typename?: 'DatedCategoryValues';
        date: UtcDate;
        categoryValues: Array<{ __typename?: 'CategoryValue'; category: string; value: number }>;
      }>;
    };
  };
};

export type ITimeWeightedReturnQueryVariables = Exact<{
  since?: InputMaybe<Scalars['Date']['input']>;
  to?: InputMaybe<Scalars['Date']['input']>;
  subAccountAssetFilters: ISubAccountAssetFilterInputType;
}>;

export type ITimeWeightedReturnQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    portfolioTimeWeightedReturns: {
      __typename?: 'PortfolioTimeWeightedReturnsType';
      values: ITimeWeightedReturnType[];
    };
  };
};

export type IAssetContributionsQueryVariables = Exact<{
  since?: InputMaybe<Scalars['Date']['input']>;
  to?: InputMaybe<Scalars['Date']['input']>;
  subAccountAssetFilters: ISubAccountAssetFilterInputType;
  contributionPeriodMethod: IContributionMethod;
}>;

export type IAssetContributionsQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    performanceAssetContributions: {
      __typename?: 'AssetContributionResult';
      portfolioPerformance: Array<{
        __typename?: 'DailyPortfolioPerformance';
        date: UtcDate;
        dailyTotalContributionByAsset: number;
        dailyPortfolioTwr: number;
        dailyOpportunisticTradingContribution: number;
        cumulativeTotalContributionByAsset: number;
        cumulativePortfolioTwr: number;
        cumulativeOpportunisticTradingContribution: number;
      }>;
      assetContributions: Array<{
        __typename?: 'AssetContribution';
        asset: {
          __typename?: 'Asset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
          derivativeDetails?: {
            __typename?: 'DerivativeDetails';
            exchange: Label;
            baseAsset: {
              __typename?: 'BaseAsset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
            };
          } | null;
        };
        dailySnapshotValues: Array<{
          __typename?: 'DailySnapshotValues';
          date: UtcDate;
          amount?: number | null;
          cashWeight?: number | null;
          cumulativeAssetReturn?: number | null;
          cumulativeContributionsToReturn?: number | null;
          dailyAssetReturn?: number | null;
          dailyContributionToReturn?: number | null;
          exposure?: number | null;
          closePrice?: number | null;
          openPrice?: number | null;
        }>;
      }>;
    };
  };
};

export type IBottomUpPerformanceAttributionQueryVariables = Exact<{
  input: IMultiLevelPerformanceClusterAttributionInput;
}>;

export type IBottomUpPerformanceAttributionQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    multilevelPerformanceAttributions: {
      __typename?: 'MultilevelAttributionResult';
      levelNames: Array<string>;
      columns: Array<string>;
      data: {
        __typename?: 'LevelResult';
        values: Array<number | null>;
        name?: string | null;
        asset?: {
          __typename?: 'Asset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
          derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
        } | null;
        children: Array<{
          __typename?: 'LevelResult';
          values: Array<number | null>;
          name?: string | null;
          asset?: {
            __typename?: 'Asset';
            type: IAssetType;
            symbol: string;
            label?: Label | null;
            name?: string | null;
            icon?: string | null;
            id: string;
            exchangeDetails?: Label | null;
            derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
          } | null;
          children: Array<{
            __typename?: 'LevelResult';
            name?: string | null;
            values: Array<number | null>;
            asset?: {
              __typename?: 'Asset';
              type: IAssetType;
              symbol: string;
              label?: Label | null;
              name?: string | null;
              icon?: string | null;
              id: string;
              exchangeDetails?: Label | null;
              derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
            } | null;
            children: Array<{
              __typename?: 'LevelResult';
              name?: string | null;
              values: Array<number | null>;
              asset?: {
                __typename?: 'Asset';
                type: IAssetType;
                symbol: string;
                label?: Label | null;
                name?: string | null;
                icon?: string | null;
                id: string;
                exchangeDetails?: Label | null;
                derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
              } | null;
              children: Array<{
                __typename?: 'LevelResult';
                name?: string | null;
                values: Array<number | null>;
                asset?: {
                  __typename?: 'Asset';
                  type: IAssetType;
                  symbol: string;
                  label?: Label | null;
                  name?: string | null;
                  icon?: string | null;
                  id: string;
                  exchangeDetails?: Label | null;
                  derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
                } | null;
                children: Array<{
                  __typename?: 'LevelResult';
                  name?: string | null;
                  values: Array<number | null>;
                  asset?: {
                    __typename?: 'Asset';
                    type: IAssetType;
                    symbol: string;
                    label?: Label | null;
                    name?: string | null;
                    icon?: string | null;
                    id: string;
                    exchangeDetails?: Label | null;
                    derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
                  } | null;
                }>;
              }>;
            }>;
          }>;
        }>;
      };
    };
  };
};

export type IPerformanceAttributionQueryVariables = Exact<{
  filters: IPerformanceClusterAttributionInput;
}>;

export type IPerformanceAttributionQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    performanceClusterAttributions: {
      __typename?: 'ClusterAttributionResult';
      cumulativeAllocationEffect: Array<{
        __typename?: 'DatedCategoryValues';
        date: UtcDate;
        categoryValues: Array<{ __typename?: 'CategoryValue'; value: number; category: string }>;
      }>;
      cumulativeSelectionEffect: Array<{
        __typename?: 'DatedCategoryValues';
        date: UtcDate;
        categoryValues: Array<{ __typename?: 'CategoryValue'; value: number; category: string }>;
      }>;
      cumulativeInteractionEffect: Array<{
        __typename?: 'DatedCategoryValues';
        date: UtcDate;
        categoryValues: Array<{ __typename?: 'CategoryValue'; value: number; category: string }>;
      }>;
      dailyAllocationEffect: Array<{
        __typename?: 'DatedCategoryValues';
        date: UtcDate;
        categoryValues: Array<{ __typename?: 'CategoryValue'; value: number; category: string }>;
      }>;
      dailyInteractionEffect: Array<{
        __typename?: 'DatedCategoryValues';
        date: UtcDate;
        categoryValues: Array<{ __typename?: 'CategoryValue'; value: number; category: string }>;
      }>;
      dailySelectionEffect: Array<{
        __typename?: 'DatedCategoryValues';
        date: UtcDate;
        categoryValues: Array<{ __typename?: 'CategoryValue'; value: number; category: string }>;
      }>;
      cumulativePortfolioReturns: Array<{ __typename?: 'DatedValue'; value: number; date: UtcDate }>;
      cumulativeBenchmarkReturns: Array<{ __typename?: 'DatedValue'; value: number; date: UtcDate }>;
      cumulativeOverperformance: Array<{ __typename?: 'DatedValue'; value: number; date: UtcDate }>;
      cumulativeTradingActivity: Array<{ __typename?: 'DatedValue'; value: number; date: UtcDate }>;
    };
  };
};

export type IPerformanceAttributionInputQueryVariables = Exact<{ [key: string]: never }>;

export type IPerformanceAttributionInputQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    definitions: Array<{
      __typename?: 'PortfolioDefinition';
      id: string;
      name: string;
      description: string;
      type: IPortfolioDefinitionType;
      genie: boolean;
      createdFrom?: {
        __typename?: 'PortfolioRebalancingRule';
        name: string;
        portfolioDefinition: { __typename?: 'PortfolioDefinition'; id: string; name: string };
      } | null;
      dateRange?: { __typename?: 'PortfolioDefinitionDateRange'; since?: UtcDate | null; to?: UtcDate | null } | null;
    }>;
  };
  assets: { __typename?: 'Assets'; clusterByUser: Array<string> };
  factorRegression: {
    __typename?: 'FactorRouterType';
    supportedTimeWindow: {
      __typename?: 'FactorServiceInfo';
      supportedTimeWindow?: { __typename?: 'SupportedDateTimeWindow'; since: UtcDate; until: UtcDate } | null;
    };
  };
};

export type IPerformanceAttributionSettingsWidgetInputQueryVariables = Exact<{ [key: string]: never }>;

export type IPerformanceAttributionSettingsWidgetInputQuery = {
  __typename?: 'Query';
  assets: { __typename?: 'Assets'; clusterByUser: Array<string> };
};

export type IDailyPnlQueryVariables = Exact<{
  subAccountAssetFilters: ISubAccountAssetFilterInputType;
  since?: InputMaybe<Scalars['Date']['input']>;
  to?: InputMaybe<Scalars['Date']['input']>;
}>;

export type IDailyPnlQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    subAccountsTimeWeightedReturns: {
      __typename?: 'SubAccountsTimeWeightedReturnsType';
      subAccounts: Array<{
        __typename?: 'SubAccountTimeWeightedReturnsType';
        values: ITimeWeightedReturnType[];
        subAccount: {
          __typename?: 'SubAccount';
          id: string;
          name: string;
          account: {
            __typename?: 'Account';
            id: string;
            name: string;
            venue: { __typename?: 'DataSource'; label: Label };
          };
        };
      }>;
    };
    subFunds: {
      __typename?: 'SubFunds';
      list: Array<{
        __typename?: 'SubFund';
        id: number;
        name: string;
        dimension: string;
        subAccounts: Array<{ __typename?: 'SubAccount'; id: string }>;
        accounts: Array<{
          __typename?: 'Account';
          id: string;
          subAccounts: Array<{ __typename?: 'SubAccount'; id: string }>;
        }>;
      }>;
    };
    accounts: Array<{
      __typename?: 'Account';
      id: string;
      name: string;
      subAccounts: Array<{ __typename?: 'SubAccount'; name: string; id: string }>;
      venue: { __typename?: 'DataSource'; label: Label };
    }>;
  };
};

export type IPortfolioExposureQueryVariables = Exact<{
  since?: InputMaybe<Scalars['Date']['input']>;
  to?: InputMaybe<Scalars['Date']['input']>;
  subAccountAssetFilters: ISubAccountAssetFilterInputType;
}>;

export type IPortfolioExposureQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    snapshot: Array<{
      __typename?: 'SubAccountSnapshot';
      date: UtcDate;
      summary: {
        __typename?: 'Summary';
        exposure: {
          __typename?: 'Allocation';
          total: Decimal;
          gross: Decimal;
          long: Decimal;
          cash: Decimal;
          short: Decimal;
          net: Decimal;
        };
        balance: { __typename?: 'Allocation'; total: Decimal };
      };
    }>;
  };
};

export type IProfitLossPerformanceInputQueryVariables = Exact<{ [key: string]: never }>;

export type IProfitLossPerformanceInputQuery = {
  __typename?: 'Query';
  assets: { __typename?: 'Assets'; clusterByUser: Array<string> };
  factorRegression: {
    __typename?: 'FactorRouterType';
    supportedTimeWindow: {
      __typename?: 'FactorServiceInfo';
      supportedTimeWindow?: { __typename?: 'SupportedDateTimeWindow'; since: UtcDate; until: UtcDate } | null;
    };
  };
  portfolio: {
    __typename?: 'Portfolio';
    accounts: Array<{ __typename?: 'Account'; subAccounts: Array<{ __typename?: 'SubAccount'; id: string }> }>;
  };
};

export type ISubFundPnlQueryVariables = Exact<{
  dimensions: Array<Scalars['String']['input']> | Scalars['String']['input'];
  since?: InputMaybe<Scalars['Date']['input']>;
}>;

export type ISubFundPnlQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    subFundsTimeWeightedReturns: {
      __typename?: 'SubFundPerformance';
      subFunds: Array<{
        __typename?: 'SubFundTimeWeightedReturns';
        subFund: {
          __typename?: 'SubFund';
          id: number;
          name: string;
          dimension: string;
          referenceAsset: {
            __typename?: 'Asset';
            type: IAssetType;
            symbol: string;
            label?: Label | null;
            name?: string | null;
            icon?: string | null;
            id: string;
            exchangeDetails?: Label | null;
            derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
          };
        };
        referenceAssetReturns: {
          __typename?: 'SubFundPerformanceByAsset';
          twr: ITimeWeightedReturnType[];
          asset: {
            __typename?: 'Asset';
            id: string;
            name?: string | null;
            icon?: string | null;
            symbol: string;
            exchangeDetails?: Label | null;
            type: IAssetType;
          };
          summaries: Array<{
            __typename?: 'PeriodReturnStatisticsType';
            periodType: IPeriodType;
            qsMetrics: Array<{
              __typename?: 'QSMetricType';
              description: string;
              displayName: string;
              value: number;
              valueType: IMetricFormat;
            }>;
            cumulativeReturns: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
            statistics: {
              __typename?: 'ValuesByCategory';
              all: {
                __typename?: 'ReturnMetrics';
                medianReturn: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
                meanReturn: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
                bestDay: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
                dayCount: { __typename?: 'IntRowValues'; absolute: number; percentage: number };
                worstDay: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
              };
              negative?: {
                __typename?: 'ReturnMetrics';
                medianReturn: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
                meanReturn: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
                bestDay: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
                dayCount: { __typename?: 'IntRowValues'; absolute: number; percentage: number };
                worstDay: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
              } | null;
              positive?: {
                __typename?: 'ReturnMetrics';
                medianReturn: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
                meanReturn: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
                bestDay: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
                dayCount: { __typename?: 'IntRowValues'; absolute: number; percentage: number };
                worstDay: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
              } | null;
            };
          }>;
        };
        usdReturns: {
          __typename?: 'SubFundPerformanceByAsset';
          twr: ITimeWeightedReturnType[];
          asset: {
            __typename?: 'Asset';
            id: string;
            name?: string | null;
            icon?: string | null;
            symbol: string;
            exchangeDetails?: Label | null;
            type: IAssetType;
          };
          summaries: Array<{
            __typename?: 'PeriodReturnStatisticsType';
            periodType: IPeriodType;
            qsMetrics: Array<{
              __typename?: 'QSMetricType';
              description: string;
              displayName: string;
              value: number;
              valueType: IMetricFormat;
            }>;
            cumulativeReturns: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
            statistics: {
              __typename?: 'ValuesByCategory';
              all: {
                __typename?: 'ReturnMetrics';
                medianReturn: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
                meanReturn: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
                bestDay: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
                dayCount: { __typename?: 'IntRowValues'; absolute: number; percentage: number };
                worstDay: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
              };
              negative?: {
                __typename?: 'ReturnMetrics';
                medianReturn: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
                meanReturn: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
                bestDay: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
                dayCount: { __typename?: 'IntRowValues'; absolute: number; percentage: number };
                worstDay: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
              } | null;
              positive?: {
                __typename?: 'ReturnMetrics';
                medianReturn: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
                meanReturn: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
                bestDay: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
                dayCount: { __typename?: 'IntRowValues'; absolute: number; percentage: number };
                worstDay: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
              } | null;
            };
          }>;
        };
      }>;
    };
  };
};

export type ISubFundPnlFieldsFragment = {
  __typename?: 'SubFundPerformanceByAsset';
  twr: ITimeWeightedReturnType[];
  asset: {
    __typename?: 'Asset';
    id: string;
    name?: string | null;
    icon?: string | null;
    symbol: string;
    exchangeDetails?: Label | null;
    type: IAssetType;
  };
  summaries: Array<{
    __typename?: 'PeriodReturnStatisticsType';
    periodType: IPeriodType;
    qsMetrics: Array<{
      __typename?: 'QSMetricType';
      description: string;
      displayName: string;
      value: number;
      valueType: IMetricFormat;
    }>;
    cumulativeReturns: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
    statistics: {
      __typename?: 'ValuesByCategory';
      all: {
        __typename?: 'ReturnMetrics';
        medianReturn: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
        meanReturn: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
        bestDay: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
        dayCount: { __typename?: 'IntRowValues'; absolute: number; percentage: number };
        worstDay: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
      };
      negative?: {
        __typename?: 'ReturnMetrics';
        medianReturn: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
        meanReturn: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
        bestDay: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
        dayCount: { __typename?: 'IntRowValues'; absolute: number; percentage: number };
        worstDay: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
      } | null;
      positive?: {
        __typename?: 'ReturnMetrics';
        medianReturn: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
        meanReturn: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
        bestDay: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
        dayCount: { __typename?: 'IntRowValues'; absolute: number; percentage: number };
        worstDay: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
      } | null;
    };
  }>;
};

export type ISubFundPnlReturnMetricsFieldsFragment = {
  __typename?: 'ReturnMetrics';
  medianReturn: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
  meanReturn: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
  bestDay: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
  dayCount: { __typename?: 'IntRowValues'; absolute: number; percentage: number };
  worstDay: { __typename?: 'FloatRowValues'; absolute: number; percentage: number };
};

export type ISubFundPnlInputQueryVariables = Exact<{
  field: Scalars['String']['input'];
}>;

export type ISubFundPnlInputQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    subFunds: { __typename?: 'SubFunds'; list: Array<{ __typename?: 'SubFund'; dimension: string }> };
  };
  management: { __typename?: 'Management'; userSettings?: any | null };
};

export type ISubFundChartWidgetSettingsInputQueryVariables = Exact<{ [key: string]: never }>;

export type ISubFundChartWidgetSettingsInputQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    subFunds: {
      __typename?: 'SubFunds';
      list: Array<{
        __typename?: 'SubFund';
        dimension: string;
        referenceAsset: {
          __typename?: 'Asset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
          derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
        };
      }>;
    };
  };
};

export type ISubFundGridWidgetSettingsInputQueryVariables = Exact<{ [key: string]: never }>;

export type ISubFundGridWidgetSettingsInputQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    subFunds: { __typename?: 'SubFunds'; list: Array<{ __typename?: 'SubFund'; dimension: string }> };
  };
};

export type IDeleteUnifiedPnlReportMutationVariables = Exact<{
  analysisId: Scalars['Int']['input'];
}>;

export type IDeleteUnifiedPnlReportMutation = { __typename?: 'Mutation'; deleteUnifiedPnlAnalysis?: any | null };

export type IGenerateUnifiedPnlReportMutationVariables = Exact<{
  dimension?: InputMaybe<Scalars['String']['input']>;
  assetIds?: InputMaybe<Array<Scalars['UUID']['input']> | Scalars['UUID']['input']>;
  since: Scalars['Date']['input'];
  until: Scalars['Date']['input'];
}>;

export type IGenerateUnifiedPnlReportMutation = { __typename?: 'Mutation'; SubmitUnifiedPnLAnalysis: number };

export type IUnifiedPnlDialogInputQueryVariables = Exact<{ [key: string]: never }>;

export type IUnifiedPnlDialogInputQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    subFunds: { __typename?: 'SubFunds'; list: Array<{ __typename?: 'SubFund'; dimension: string }> };
  };
};

export type IUnifiedPnlReportQueryVariables = Exact<{
  reportId: Scalars['Int']['input'];
}>;

export type IUnifiedPnlReportQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    unifiedPnl: Array<{
      __typename?: 'UnifiedPnLAnalysisResult';
      date: UtcDate;
      amount?: Decimal | null;
      avgPrice?: Decimal | null;
      price?: Decimal | null;
      rpnl?: Decimal | null;
      upnl?: Decimal | null;
      asset: {
        __typename?: 'Asset';
        type: IAssetType;
        symbol: string;
        label?: Label | null;
        name?: string | null;
        icon?: string | null;
        id: string;
        exchangeDetails?: Label | null;
        derivativeDetails?: {
          __typename?: 'DerivativeDetails';
          exchange: Label;
          baseAsset: {
            __typename?: 'BaseAsset';
            type: IAssetType;
            symbol: string;
            label?: Label | null;
            name?: string | null;
            icon?: string | null;
            id: string;
            exchangeDetails?: Label | null;
          };
        } | null;
      };
      subFund?: { __typename?: 'SubFund'; name: string } | null;
    }>;
  };
};

export type IUnifiedPnlReportListInputQueryVariables = Exact<{ [key: string]: never }>;

export type IUnifiedPnlReportListInputQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    unifiedPnlStatus: Array<{
      __typename?: 'UnifiedPnLAnalysis';
      status: IPortfolioAnalysisStatusType;
      since: UtcDate;
      until: UtcDate;
      createdAt: string;
      subFundDimension?: string | null;
      id: number;
      assets?: Array<{
        __typename?: 'Asset';
        type: IAssetType;
        symbol: string;
        label?: Label | null;
        name?: string | null;
        icon?: string | null;
        id: string;
        exchangeDetails?: Label | null;
        derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
      }> | null;
    }>;
  };
};

export type ISnapshotEditorInputQueryVariables = Exact<{ [key: string]: never }>;

export type ISnapshotEditorInputQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    accounts: Array<{
      __typename?: 'Account';
      id: string;
      name: string;
      subAccounts: Array<{ __typename?: 'SubAccount'; id: string; name: string; createdAt: string }>;
      venue: { __typename?: 'DataSource'; label: Label };
    }>;
  };
};

export type ISnapshotPositionsQueryVariables = Exact<{
  date: Scalars['Date']['input'];
  subAccountId: Scalars['UUID']['input'];
  exchange: Scalars['LabelType']['input'];
}>;

export type ISnapshotPositionsQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    snapshot: Array<{
      __typename?: 'SubAccountSnapshot';
      positions: Array<{
        __typename?: 'SubAccountPositionSnapshot';
        asset: { __typename?: 'Asset'; id: string };
        spot?: {
          __typename?: 'SubAccountPositionSpotSnapshot';
          amount: Decimal;
          balance: Decimal;
          price: Decimal;
          slot: ISlotType;
        } | null;
      }>;
    }>;
  };
  assets: {
    __typename?: 'Assets';
    list: Array<{
      __typename?: 'Asset';
      type: IAssetType;
      symbol: string;
      label?: Label | null;
      name?: string | null;
      icon?: string | null;
      id: string;
      exchangeDetails?: Label | null;
      derivativeDetails?: {
        __typename?: 'DerivativeDetails';
        exchange: Label;
        derivativeType: IDerivativeType;
        expirationDate?: string | null;
        optionType?: IOptionType | null;
        strikePrice?: Decimal | null;
        baseAsset: {
          __typename?: 'BaseAsset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
        };
        quoteAsset: {
          __typename?: 'BaseAsset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
        };
        settlementAsset: {
          __typename?: 'BaseAsset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
        };
      } | null;
    }>;
  };
};

export type IUpdateSnapshotPositionMutationVariables = Exact<{
  assetId: Scalars['UUID']['input'];
  subAccountId: Scalars['UUID']['input'];
  date: Scalars['Date']['input'];
  amount: Scalars['Decimal']['input'];
  slot: ISlotType;
}>;

export type IUpdateSnapshotPositionMutation = { __typename?: 'Mutation'; UpdateSnapshotPosition?: any | null };

export type ISearchableAssetIdsQueryVariables = Exact<{ [key: string]: never }>;

export type ISearchableAssetIdsQuery = {
  __typename?: 'Query';
  assets: { __typename?: 'Assets'; feature: Array<{ __typename?: 'Asset'; id: string }> };
};

export type ISearchableAssetsQueryVariables = Exact<{ [key: string]: never }>;

export type ISearchableAssetsQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'Assets';
    feature: Array<{
      __typename?: 'Asset';
      type: IAssetType;
      symbol: string;
      label?: Label | null;
      name?: string | null;
      icon?: string | null;
      id: string;
      exchangeDetails?: Label | null;
      derivativeDetails?: {
        __typename?: 'DerivativeDetails';
        exchange: Label;
        derivativeType: IDerivativeType;
        expirationDate?: string | null;
        optionType?: IOptionType | null;
        strikePrice?: Decimal | null;
        baseAsset: {
          __typename?: 'BaseAsset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
        };
        quoteAsset: {
          __typename?: 'BaseAsset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
        };
        settlementAsset: {
          __typename?: 'BaseAsset';
          type: IAssetType;
          symbol: string;
          label?: Label | null;
          name?: string | null;
          icon?: string | null;
          id: string;
          exchangeDetails?: Label | null;
        };
      } | null;
    }>;
  };
};

export type IOrganizationFeaturesQueryVariables = Exact<{ [key: string]: never }>;

export type IOrganizationFeaturesQuery = {
  __typename?: 'Query';
  management: {
    __typename?: 'Management';
    features: Array<{ __typename?: 'OrganizationFeature'; name: string; status: IFeatureFlagStatus }>;
  };
};

export type ISubAccountAssetAssetFiltersInputQueryVariables = Exact<{
  assetIds: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
}>;

export type ISubAccountAssetAssetFiltersInputQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'Assets';
    list: Array<{
      __typename?: 'Asset';
      type: IAssetType;
      symbol: string;
      label?: Label | null;
      name?: string | null;
      icon?: string | null;
      id: string;
      exchangeDetails?: Label | null;
      derivativeDetails?: { __typename?: 'DerivativeDetails'; exchange: Label } | null;
    }>;
  };
};

export type ISubAccountAssetFiltersInputQueryVariables = Exact<{ [key: string]: never }>;

export type ISubAccountAssetFiltersInputQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'Portfolio';
    subFunds: {
      __typename?: 'SubFunds';
      list: Array<{ __typename?: 'SubFund'; id: number; name: string; dimension: string }>;
    };
    accounts: Array<{
      __typename?: 'Account';
      id: string;
      name: string;
      subAccounts: Array<{ __typename?: 'SubAccount'; id: string; name: string }>;
      venue: { __typename?: 'DataSource'; label: Label };
    }>;
  };
};

export type ICreatePresignedUrlMutationVariables = Exact<{
  contentType: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
}>;

export type ICreatePresignedUrlMutation = {
  __typename?: 'Mutation';
  createPresignedUrl: {
    __typename?: 'PresignedUrl';
    uploadLink: string;
    remoteFile: { __typename?: 'FileUpload'; id: string };
  };
};

export const MainNonDerivativeAssetFieldsFragmentDoc = gql`
    fragment MainNonDerivativeAssetFields on Asset {
  type
  symbol
  label
  name
  icon
  id
  exchangeDetails
}
    `;
export const MainNonDerivativeBaseAssetFieldsFragmentDoc = gql`
    fragment MainNonDerivativeBaseAssetFields on BaseAsset {
  type
  symbol
  label
  name
  icon
  id
  exchangeDetails
}
    `;
export const MainAssetFieldsFragmentDoc = gql`
    fragment MainAssetFields on Asset {
  ...MainNonDerivativeAssetFields
  derivativeDetails {
    baseAsset {
      ...MainNonDerivativeBaseAssetFields
    }
    exchange
    derivativeType
    expirationDate
    optionType
    quoteAsset {
      ...MainNonDerivativeBaseAssetFields
    }
    settlementAsset {
      ...MainNonDerivativeBaseAssetFields
    }
    strikePrice
  }
}
    ${MainNonDerivativeAssetFieldsFragmentDoc}
${MainNonDerivativeBaseAssetFieldsFragmentDoc}`;
export const AllAssetValueFieldsFragmentDoc = gql`
    fragment AllAssetValueFields on AssetValue {
  unitValue
  amount
  asset {
    ...MainAssetFields
  }
}
    ${MainAssetFieldsFragmentDoc}`;
export const AssetSolutionFieldsFragmentDoc = gql`
    fragment AssetSolutionFields on PortfolioOutput {
  nonDominated
  suggested
  solutionId
  status
  statusMessage
  riskMeasures {
    measure
    value
    benchmark {
      id
      name
      symbol
      type
    }
  }
  objectiveValues {
    value
  }
  portfolioExposures {
    name
    amount
    percentage
  }
  groupExposures {
    group {
      id
      clusterName
      groupName
    }
    exposures {
      name
      amount
      percentage
    }
  }
  returnsPredictions {
    returnStatistics {
      statName
      statValue
    }
    returns
  }
  measures: assetsMeasures {
    item: asset {
      ...MainAssetFields
    }
    cashWeight
    amount
    leverage
    weights {
      benchmark {
        id
        symbol
      }
      measure
      value
    }
  }
}
    ${MainAssetFieldsFragmentDoc}`;
export const PortfolioSolutionFieldsFragmentDoc = gql`
    fragment PortfolioSolutionFields on FundOutput {
  nonDominated
  suggested
  status
  statusMessage
  solutionId
  riskMeasures {
    measure
    value
    benchmark {
      id
      name
      type
    }
  }
  objectiveValues {
    value
  }
  portfolioExposures {
    name
    amount
    percentage
  }
  returnsPredictions {
    returnStatistics {
      statName
      statValue
    }
    returns
  }
  measures: fundMeasures {
    amount
    cashWeight
    item: fund {
      createdAt
      createdFrom {
        end
        id
        maxRebalancingCost
        name
        periodUnit
        periodValue
        portfolioDefinition {
          id
          name
          type
        }
        start
        tradingFee
      }
      type
      description
      genie
      id
      name
    }
    weights {
      benchmark {
        id
        name
        type
      }
      measure
      value
    }
  }
}
    `;
export const AssetLabelFieldsFragmentDoc = gql`
    fragment AssetLabelFields on Asset {
  type
  symbol
  label
  name
  icon
  id
  exchangeDetails
  derivativeDetails {
    exchange
  }
}
    `;
export const AccountFieldsFragmentDoc = gql`
    fragment AccountFields on Account {
  id
  name
  subAccounts {
    name
    id
  }
  venue {
    label
  }
}
    `;
export const SubAccountLabelFieldsFragmentDoc = gql`
    fragment SubAccountLabelFields on SubAccount {
  id
  name
  account {
    id
    name
    venue {
      label
    }
  }
}
    `;
export const SubFundToSubAccountFieldsFragmentDoc = gql`
    fragment SubFundToSubAccountFields on SubFund {
  id
  name
  dimension
  subAccounts {
    id
  }
  accounts {
    id
    subAccounts {
      id
    }
  }
}
    `;
export const OpenPositionsSummaryCostBasisFragmentDoc = gql`
    fragment OpenPositionsSummaryCostBasis on Journal {
  preferences {
    dimension
  }
  costBasis(perOrganization: false) {
    asset {
      id
    }
    subFund {
      id
    }
    costBasis
  }
}
    `;
export const SubFundPnlReturnMetricsFieldsFragmentDoc = gql`
    fragment SubFundPnlReturnMetricsFields on ReturnMetrics {
  medianReturn {
    absolute
    percentage
  }
  meanReturn {
    absolute
    percentage
  }
  bestDay {
    absolute
    percentage
  }
  dayCount {
    absolute
    percentage
  }
  worstDay {
    absolute
    percentage
  }
}
    `;
export const SubFundPnlFieldsFragmentDoc = gql`
    fragment SubFundPnlFields on SubFundPerformanceByAsset {
  asset {
    id
    name
    icon
    symbol
    exchangeDetails
    type
  }
  twr
  summaries {
    periodType
    qsMetrics {
      description
      displayName
      value
      valueType
    }
    cumulativeReturns {
      absolute
      percentage
    }
    statistics {
      all {
        ...SubFundPnlReturnMetricsFields
      }
      negative {
        ...SubFundPnlReturnMetricsFields
      }
      positive {
        ...SubFundPnlReturnMetricsFields
      }
    }
  }
}
    ${SubFundPnlReturnMetricsFieldsFragmentDoc}`;
export const IsExternalUserDocument = gql`
    query IsExternalUser {
  management {
    isExternalUser
  }
}
    `;

/**
 * __useIsExternalUserQuery__
 *
 * To run a query within a React component, call `useIsExternalUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsExternalUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsExternalUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsExternalUserQuery(
  baseOptions?: Apollo.QueryHookOptions<IIsExternalUserQuery, IIsExternalUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IIsExternalUserQuery, IIsExternalUserQueryVariables>(IsExternalUserDocument, options);
}
export function useIsExternalUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IIsExternalUserQuery, IIsExternalUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IIsExternalUserQuery, IIsExternalUserQueryVariables>(IsExternalUserDocument, options);
}
export function useIsExternalUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IIsExternalUserQuery, IIsExternalUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IIsExternalUserQuery, IIsExternalUserQueryVariables>(IsExternalUserDocument, options);
}
export type IsExternalUserQueryHookResult = ReturnType<typeof useIsExternalUserQuery>;
export type IsExternalUserLazyQueryHookResult = ReturnType<typeof useIsExternalUserLazyQuery>;
export type IsExternalUserSuspenseQueryHookResult = ReturnType<typeof useIsExternalUserSuspenseQuery>;
export type IsExternalUserQueryResult = Apollo.QueryResult<IIsExternalUserQuery, IIsExternalUserQueryVariables>;
export const UserOrganizationDocument = gql`
    query UserOrganization {
  organization {
    id
    name
  }
}
    `;

/**
 * __useUserOrganizationQuery__
 *
 * To run a query within a React component, call `useUserOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserOrganizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserOrganizationQuery(
  baseOptions?: Apollo.QueryHookOptions<IUserOrganizationQuery, IUserOrganizationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IUserOrganizationQuery, IUserOrganizationQueryVariables>(UserOrganizationDocument, options);
}
export function useUserOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IUserOrganizationQuery, IUserOrganizationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IUserOrganizationQuery, IUserOrganizationQueryVariables>(
    UserOrganizationDocument,
    options
  );
}
export function useUserOrganizationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IUserOrganizationQuery, IUserOrganizationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IUserOrganizationQuery, IUserOrganizationQueryVariables>(
    UserOrganizationDocument,
    options
  );
}
export type UserOrganizationQueryHookResult = ReturnType<typeof useUserOrganizationQuery>;
export type UserOrganizationLazyQueryHookResult = ReturnType<typeof useUserOrganizationLazyQuery>;
export type UserOrganizationSuspenseQueryHookResult = ReturnType<typeof useUserOrganizationSuspenseQuery>;
export type UserOrganizationQueryResult = Apollo.QueryResult<IUserOrganizationQuery, IUserOrganizationQueryVariables>;
export const CreateInvestmentDocument = gql`
    mutation CreateInvestment($input: InvestmentInput!) {
  CreateInvestment(input: $input) {
    __typename
  }
}
    `;
export type ICreateInvestmentMutationFn = Apollo.MutationFunction<
  ICreateInvestmentMutation,
  ICreateInvestmentMutationVariables
>;

/**
 * __useCreateInvestmentMutation__
 *
 * To run a mutation, you first call `useCreateInvestmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvestmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvestmentMutation, { data, loading, error }] = useCreateInvestmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInvestmentMutation(
  baseOptions?: Apollo.MutationHookOptions<ICreateInvestmentMutation, ICreateInvestmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICreateInvestmentMutation, ICreateInvestmentMutationVariables>(
    CreateInvestmentDocument,
    options
  );
}
export type CreateInvestmentMutationHookResult = ReturnType<typeof useCreateInvestmentMutation>;
export type CreateInvestmentMutationResult = Apollo.MutationResult<ICreateInvestmentMutation>;
export type CreateInvestmentMutationOptions = Apollo.BaseMutationOptions<
  ICreateInvestmentMutation,
  ICreateInvestmentMutationVariables
>;
export const DeleteInvestmentDocument = gql`
    mutation DeleteInvestment($id: UUID!) {
  DeleteInvestment(id: $id)
}
    `;
export type IDeleteInvestmentMutationFn = Apollo.MutationFunction<
  IDeleteInvestmentMutation,
  IDeleteInvestmentMutationVariables
>;

/**
 * __useDeleteInvestmentMutation__
 *
 * To run a mutation, you first call `useDeleteInvestmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvestmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvestmentMutation, { data, loading, error }] = useDeleteInvestmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteInvestmentMutation(
  baseOptions?: Apollo.MutationHookOptions<IDeleteInvestmentMutation, IDeleteInvestmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IDeleteInvestmentMutation, IDeleteInvestmentMutationVariables>(
    DeleteInvestmentDocument,
    options
  );
}
export type DeleteInvestmentMutationHookResult = ReturnType<typeof useDeleteInvestmentMutation>;
export type DeleteInvestmentMutationResult = Apollo.MutationResult<IDeleteInvestmentMutation>;
export type DeleteInvestmentMutationOptions = Apollo.BaseMutationOptions<
  IDeleteInvestmentMutation,
  IDeleteInvestmentMutationVariables
>;
export const InvestmentsInputDocument = gql`
    query InvestmentsInput($filters: ListAssetFilters!) {
  portfolio {
    accounts {
      id
      name
      venue {
        label
      }
      subAccounts {
        id
        name
      }
    }
  }
  bookkeeping {
    investments {
      list {
        id
        name
        executedAt
        subAccount {
          id
          name
          account {
            id
            name
            venue {
              label
            }
          }
        }
        credit {
          ...AllAssetValueFields
        }
        debit {
          ...AllAssetValueFields
        }
        fee {
          ...AllAssetValueFields
        }
        status
        actions
        vestedAmount
      }
    }
  }
  assets {
    list(filters: $filters) {
      ...MainAssetFields
    }
  }
}
    ${AllAssetValueFieldsFragmentDoc}
${MainAssetFieldsFragmentDoc}`;

/**
 * __useInvestmentsInputQuery__
 *
 * To run a query within a React component, call `useInvestmentsInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestmentsInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestmentsInputQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useInvestmentsInputQuery(
  baseOptions: Apollo.QueryHookOptions<IInvestmentsInputQuery, IInvestmentsInputQueryVariables> &
    ({ variables: IInvestmentsInputQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IInvestmentsInputQuery, IInvestmentsInputQueryVariables>(InvestmentsInputDocument, options);
}
export function useInvestmentsInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IInvestmentsInputQuery, IInvestmentsInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IInvestmentsInputQuery, IInvestmentsInputQueryVariables>(
    InvestmentsInputDocument,
    options
  );
}
export function useInvestmentsInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IInvestmentsInputQuery, IInvestmentsInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IInvestmentsInputQuery, IInvestmentsInputQueryVariables>(
    InvestmentsInputDocument,
    options
  );
}
export type InvestmentsInputQueryHookResult = ReturnType<typeof useInvestmentsInputQuery>;
export type InvestmentsInputLazyQueryHookResult = ReturnType<typeof useInvestmentsInputLazyQuery>;
export type InvestmentsInputSuspenseQueryHookResult = ReturnType<typeof useInvestmentsInputSuspenseQuery>;
export type InvestmentsInputQueryResult = Apollo.QueryResult<IInvestmentsInputQuery, IInvestmentsInputQueryVariables>;
export const DownloadOrdersDocument = gql`
    mutation DownloadOrders {
  DownloadOrders {
    token
  }
}
    `;
export type IDownloadOrdersMutationFn = Apollo.MutationFunction<
  IDownloadOrdersMutation,
  IDownloadOrdersMutationVariables
>;

/**
 * __useDownloadOrdersMutation__
 *
 * To run a mutation, you first call `useDownloadOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadOrdersMutation, { data, loading, error }] = useDownloadOrdersMutation({
 *   variables: {
 *   },
 * });
 */
export function useDownloadOrdersMutation(
  baseOptions?: Apollo.MutationHookOptions<IDownloadOrdersMutation, IDownloadOrdersMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IDownloadOrdersMutation, IDownloadOrdersMutationVariables>(DownloadOrdersDocument, options);
}
export type DownloadOrdersMutationHookResult = ReturnType<typeof useDownloadOrdersMutation>;
export type DownloadOrdersMutationResult = Apollo.MutationResult<IDownloadOrdersMutation>;
export type DownloadOrdersMutationOptions = Apollo.BaseMutationOptions<
  IDownloadOrdersMutation,
  IDownloadOrdersMutationVariables
>;
export const OrderFilterInputDocument = gql`
    query OrderFilterInput {
  portfolio {
    accounts {
      ...AccountFields
    }
  }
}
    ${AccountFieldsFragmentDoc}`;

/**
 * __useOrderFilterInputQuery__
 *
 * To run a query within a React component, call `useOrderFilterInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderFilterInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderFilterInputQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrderFilterInputQuery(
  baseOptions?: Apollo.QueryHookOptions<IOrderFilterInputQuery, IOrderFilterInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IOrderFilterInputQuery, IOrderFilterInputQueryVariables>(OrderFilterInputDocument, options);
}
export function useOrderFilterInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IOrderFilterInputQuery, IOrderFilterInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IOrderFilterInputQuery, IOrderFilterInputQueryVariables>(
    OrderFilterInputDocument,
    options
  );
}
export function useOrderFilterInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IOrderFilterInputQuery, IOrderFilterInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IOrderFilterInputQuery, IOrderFilterInputQueryVariables>(
    OrderFilterInputDocument,
    options
  );
}
export type OrderFilterInputQueryHookResult = ReturnType<typeof useOrderFilterInputQuery>;
export type OrderFilterInputLazyQueryHookResult = ReturnType<typeof useOrderFilterInputLazyQuery>;
export type OrderFilterInputSuspenseQueryHookResult = ReturnType<typeof useOrderFilterInputSuspenseQuery>;
export type OrderFilterInputQueryResult = Apollo.QueryResult<IOrderFilterInputQuery, IOrderFilterInputQueryVariables>;
export const OrdersDocument = gql`
    query Orders($filters: OrderFilters!, $pageLimit: PageLimitOffsetInput!, $sort: SortField!) {
  bookkeeping {
    orders(filters: $filters, paging: $pageLimit, sort: $sort) {
      data {
        amount
        price
        subAccount {
          id
          name
          account {
            id
            name
            venue {
              label
            }
          }
        }
        average
        baseAsset {
          ...MainAssetFields
        }
        quoteAsset {
          ...MainAssetFields
        }
        creationTime
        filled
        externalId
        lastUpdateTime
        side
        status
        type
      }
      pageInfo {
        totalResults
      }
    }
  }
}
    ${MainAssetFieldsFragmentDoc}`;

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pageLimit: // value for 'pageLimit'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useOrdersQuery(
  baseOptions: Apollo.QueryHookOptions<IOrdersQuery, IOrdersQueryVariables> &
    ({ variables: IOrdersQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IOrdersQuery, IOrdersQueryVariables>(OrdersDocument, options);
}
export function useOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IOrdersQuery, IOrdersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IOrdersQuery, IOrdersQueryVariables>(OrdersDocument, options);
}
export function useOrdersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IOrdersQuery, IOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IOrdersQuery, IOrdersQueryVariables>(OrdersDocument, options);
}
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersSuspenseQueryHookResult = ReturnType<typeof useOrdersSuspenseQuery>;
export type OrdersQueryResult = Apollo.QueryResult<IOrdersQuery, IOrdersQueryVariables>;
export const JournalErrorsDocument = gql`
    query JournalErrors {
  portfolio {
    journal {
      oldestJournalAssetErrors {
        asset {
          ...AssetLabelFields
        }
        time
        amount
        remainingAmount
        error
        subFund {
          id
          name
          dimension
        }
      }
    }
  }
}
    ${AssetLabelFieldsFragmentDoc}`;

/**
 * __useJournalErrorsQuery__
 *
 * To run a query within a React component, call `useJournalErrorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJournalErrorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJournalErrorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useJournalErrorsQuery(
  baseOptions?: Apollo.QueryHookOptions<IJournalErrorsQuery, IJournalErrorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IJournalErrorsQuery, IJournalErrorsQueryVariables>(JournalErrorsDocument, options);
}
export function useJournalErrorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IJournalErrorsQuery, IJournalErrorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IJournalErrorsQuery, IJournalErrorsQueryVariables>(JournalErrorsDocument, options);
}
export function useJournalErrorsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IJournalErrorsQuery, IJournalErrorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IJournalErrorsQuery, IJournalErrorsQueryVariables>(JournalErrorsDocument, options);
}
export type JournalErrorsQueryHookResult = ReturnType<typeof useJournalErrorsQuery>;
export type JournalErrorsLazyQueryHookResult = ReturnType<typeof useJournalErrorsLazyQuery>;
export type JournalErrorsSuspenseQueryHookResult = ReturnType<typeof useJournalErrorsSuspenseQuery>;
export type JournalErrorsQueryResult = Apollo.QueryResult<IJournalErrorsQuery, IJournalErrorsQueryVariables>;
export const ReconciliationReportDocument = gql`
    query ReconciliationReport($minUsdDiscrepancy: Decimal, $dates: DateRangeFilter!, $subAccountAssetFilters: SubAccountAssetFilterInputType!) {
  bookkeeping {
    reconciliation {
      report(
        minUsdDiscrepancy: $minUsdDiscrepancy
        dates: $dates
        subAccountAssetFilters: $subAccountAssetFilters
      ) {
        asset {
          ...AssetLabelFields
        }
        subAccount {
          id
          name
          account {
            id
            name
            venue {
              label
            }
          }
        }
        side
        startTime
        endTime
        snapshotBalanceChangeAmount
        transactionBalanceChangeAmount
        assetClosingPriceUsd
      }
    }
  }
}
    ${AssetLabelFieldsFragmentDoc}`;

/**
 * __useReconciliationReportQuery__
 *
 * To run a query within a React component, call `useReconciliationReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReconciliationReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReconciliationReportQuery({
 *   variables: {
 *      minUsdDiscrepancy: // value for 'minUsdDiscrepancy'
 *      dates: // value for 'dates'
 *      subAccountAssetFilters: // value for 'subAccountAssetFilters'
 *   },
 * });
 */
export function useReconciliationReportQuery(
  baseOptions: Apollo.QueryHookOptions<IReconciliationReportQuery, IReconciliationReportQueryVariables> &
    ({ variables: IReconciliationReportQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IReconciliationReportQuery, IReconciliationReportQueryVariables>(
    ReconciliationReportDocument,
    options
  );
}
export function useReconciliationReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IReconciliationReportQuery, IReconciliationReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IReconciliationReportQuery, IReconciliationReportQueryVariables>(
    ReconciliationReportDocument,
    options
  );
}
export function useReconciliationReportSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IReconciliationReportQuery, IReconciliationReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IReconciliationReportQuery, IReconciliationReportQueryVariables>(
    ReconciliationReportDocument,
    options
  );
}
export type ReconciliationReportQueryHookResult = ReturnType<typeof useReconciliationReportQuery>;
export type ReconciliationReportLazyQueryHookResult = ReturnType<typeof useReconciliationReportLazyQuery>;
export type ReconciliationReportSuspenseQueryHookResult = ReturnType<typeof useReconciliationReportSuspenseQuery>;
export type ReconciliationReportQueryResult = Apollo.QueryResult<
  IReconciliationReportQuery,
  IReconciliationReportQueryVariables
>;
export const PortfolioReportDocument = gql`
    query PortfolioReport($since: Date, $to: Date, $subAccountAssetFilters: SubAccountAssetFilterInputType!) {
  portfolio {
    snapshot(
      filters: {date: {since: $since, to: $to}, subAccountAssetFilters: $subAccountAssetFilters}
    ) {
      date
      positions {
        subAccount {
          id
        }
        asset {
          ...MainAssetFields
        }
        spot {
          amount
          price
          balance
          slot
        }
        derivative {
          amount
          notional
          side
          unrealizedPnl
          balance
        }
      }
    }
    subFunds {
      list {
        ...SubFundToSubAccountFields
      }
    }
    accounts {
      id
      name
      venue {
        label
      }
      subAccounts {
        name
        id
      }
    }
  }
}
    ${MainAssetFieldsFragmentDoc}
${SubFundToSubAccountFieldsFragmentDoc}`;

/**
 * __usePortfolioReportQuery__
 *
 * To run a query within a React component, call `usePortfolioReportQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioReportQuery({
 *   variables: {
 *      since: // value for 'since'
 *      to: // value for 'to'
 *      subAccountAssetFilters: // value for 'subAccountAssetFilters'
 *   },
 * });
 */
export function usePortfolioReportQuery(
  baseOptions: Apollo.QueryHookOptions<IPortfolioReportQuery, IPortfolioReportQueryVariables> &
    ({ variables: IPortfolioReportQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPortfolioReportQuery, IPortfolioReportQueryVariables>(PortfolioReportDocument, options);
}
export function usePortfolioReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPortfolioReportQuery, IPortfolioReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPortfolioReportQuery, IPortfolioReportQueryVariables>(PortfolioReportDocument, options);
}
export function usePortfolioReportSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IPortfolioReportQuery, IPortfolioReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IPortfolioReportQuery, IPortfolioReportQueryVariables>(
    PortfolioReportDocument,
    options
  );
}
export type PortfolioReportQueryHookResult = ReturnType<typeof usePortfolioReportQuery>;
export type PortfolioReportLazyQueryHookResult = ReturnType<typeof usePortfolioReportLazyQuery>;
export type PortfolioReportSuspenseQueryHookResult = ReturnType<typeof usePortfolioReportSuspenseQuery>;
export type PortfolioReportQueryResult = Apollo.QueryResult<IPortfolioReportQuery, IPortfolioReportQueryVariables>;
export const TradingActivityReportDocument = gql`
    query TradingActivityReport($date: DateRangeFilter, $subAccountAssetFilters: SubAccountAssetFilterInputType) {
  portfolio {
    subFunds {
      list {
        ...SubFundToSubAccountFields
      }
    }
  }
  bookkeeping {
    tradingActivity(
      filters: {date: $date, subAccountAssetFilters: $subAccountAssetFilters}
    ) {
      day
      subAccount {
        ...SubAccountLabelFields
      }
      asset {
        ...AssetLabelFields
        derivativeDetails {
          exchange
          baseAsset {
            symbol
          }
        }
      }
      userType
      price
      amount
      value
      state
      legCount
      attributedToAssetId {
        symbol
      }
      side
      legType
    }
  }
}
    ${SubFundToSubAccountFieldsFragmentDoc}
${SubAccountLabelFieldsFragmentDoc}
${AssetLabelFieldsFragmentDoc}`;

/**
 * __useTradingActivityReportQuery__
 *
 * To run a query within a React component, call `useTradingActivityReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradingActivityReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradingActivityReportQuery({
 *   variables: {
 *      date: // value for 'date'
 *      subAccountAssetFilters: // value for 'subAccountAssetFilters'
 *   },
 * });
 */
export function useTradingActivityReportQuery(
  baseOptions?: Apollo.QueryHookOptions<ITradingActivityReportQuery, ITradingActivityReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ITradingActivityReportQuery, ITradingActivityReportQueryVariables>(
    TradingActivityReportDocument,
    options
  );
}
export function useTradingActivityReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ITradingActivityReportQuery, ITradingActivityReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ITradingActivityReportQuery, ITradingActivityReportQueryVariables>(
    TradingActivityReportDocument,
    options
  );
}
export function useTradingActivityReportSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ITradingActivityReportQuery, ITradingActivityReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ITradingActivityReportQuery, ITradingActivityReportQueryVariables>(
    TradingActivityReportDocument,
    options
  );
}
export type TradingActivityReportQueryHookResult = ReturnType<typeof useTradingActivityReportQuery>;
export type TradingActivityReportLazyQueryHookResult = ReturnType<typeof useTradingActivityReportLazyQuery>;
export type TradingActivityReportSuspenseQueryHookResult = ReturnType<typeof useTradingActivityReportSuspenseQuery>;
export type TradingActivityReportQueryResult = Apollo.QueryResult<
  ITradingActivityReportQuery,
  ITradingActivityReportQueryVariables
>;
export const DeleteTransactionDocument = gql`
    mutation DeleteTransaction($input: UUID!) {
  DeleteTransactionV2(id: $input)
}
    `;
export type IDeleteTransactionMutationFn = Apollo.MutationFunction<
  IDeleteTransactionMutation,
  IDeleteTransactionMutationVariables
>;

/**
 * __useDeleteTransactionMutation__
 *
 * To run a mutation, you first call `useDeleteTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTransactionMutation, { data, loading, error }] = useDeleteTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTransactionMutation(
  baseOptions?: Apollo.MutationHookOptions<IDeleteTransactionMutation, IDeleteTransactionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IDeleteTransactionMutation, IDeleteTransactionMutationVariables>(
    DeleteTransactionDocument,
    options
  );
}
export type DeleteTransactionMutationHookResult = ReturnType<typeof useDeleteTransactionMutation>;
export type DeleteTransactionMutationResult = Apollo.MutationResult<IDeleteTransactionMutation>;
export type DeleteTransactionMutationOptions = Apollo.BaseMutationOptions<
  IDeleteTransactionMutation,
  IDeleteTransactionMutationVariables
>;
export const DownloadTransactionsDocument = gql`
    mutation DownloadTransactions($filters: TransactionFilters!, $orderAscendingByTime: Boolean!) {
  DownloadTransactionsV2(
    filters: $filters
    orderAscendingByTime: $orderAscendingByTime
  ) {
    token
  }
}
    `;
export type IDownloadTransactionsMutationFn = Apollo.MutationFunction<
  IDownloadTransactionsMutation,
  IDownloadTransactionsMutationVariables
>;

/**
 * __useDownloadTransactionsMutation__
 *
 * To run a mutation, you first call `useDownloadTransactionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadTransactionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadTransactionsMutation, { data, loading, error }] = useDownloadTransactionsMutation({
 *   variables: {
 *      filters: // value for 'filters'
 *      orderAscendingByTime: // value for 'orderAscendingByTime'
 *   },
 * });
 */
export function useDownloadTransactionsMutation(
  baseOptions?: Apollo.MutationHookOptions<IDownloadTransactionsMutation, IDownloadTransactionsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IDownloadTransactionsMutation, IDownloadTransactionsMutationVariables>(
    DownloadTransactionsDocument,
    options
  );
}
export type DownloadTransactionsMutationHookResult = ReturnType<typeof useDownloadTransactionsMutation>;
export type DownloadTransactionsMutationResult = Apollo.MutationResult<IDownloadTransactionsMutation>;
export type DownloadTransactionsMutationOptions = Apollo.BaseMutationOptions<
  IDownloadTransactionsMutation,
  IDownloadTransactionsMutationVariables
>;
export const PrecisePriceDocument = gql`
    query PrecisePrice($assetId: UUID!, $time: ArrowType!) {
  assets {
    precisePrice(assetId: $assetId, time: $time) {
      price
      time
    }
  }
}
    `;

/**
 * __usePrecisePriceQuery__
 *
 * To run a query within a React component, call `usePrecisePriceQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrecisePriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrecisePriceQuery({
 *   variables: {
 *      assetId: // value for 'assetId'
 *      time: // value for 'time'
 *   },
 * });
 */
export function usePrecisePriceQuery(
  baseOptions: Apollo.QueryHookOptions<IPrecisePriceQuery, IPrecisePriceQueryVariables> &
    ({ variables: IPrecisePriceQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPrecisePriceQuery, IPrecisePriceQueryVariables>(PrecisePriceDocument, options);
}
export function usePrecisePriceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPrecisePriceQuery, IPrecisePriceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPrecisePriceQuery, IPrecisePriceQueryVariables>(PrecisePriceDocument, options);
}
export function usePrecisePriceSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IPrecisePriceQuery, IPrecisePriceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IPrecisePriceQuery, IPrecisePriceQueryVariables>(PrecisePriceDocument, options);
}
export type PrecisePriceQueryHookResult = ReturnType<typeof usePrecisePriceQuery>;
export type PrecisePriceLazyQueryHookResult = ReturnType<typeof usePrecisePriceLazyQuery>;
export type PrecisePriceSuspenseQueryHookResult = ReturnType<typeof usePrecisePriceSuspenseQuery>;
export type PrecisePriceQueryResult = Apollo.QueryResult<IPrecisePriceQuery, IPrecisePriceQueryVariables>;
export const TransactionFilterInputDocument = gql`
    query TransactionFilterInput {
  portfolio {
    accounts {
      ...AccountFields
    }
  }
  bookkeeping {
    transactionTags
  }
}
    ${AccountFieldsFragmentDoc}`;

/**
 * __useTransactionFilterInputQuery__
 *
 * To run a query within a React component, call `useTransactionFilterInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionFilterInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionFilterInputQuery({
 *   variables: {
 *   },
 * });
 */
export function useTransactionFilterInputQuery(
  baseOptions?: Apollo.QueryHookOptions<ITransactionFilterInputQuery, ITransactionFilterInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ITransactionFilterInputQuery, ITransactionFilterInputQueryVariables>(
    TransactionFilterInputDocument,
    options
  );
}
export function useTransactionFilterInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ITransactionFilterInputQuery, ITransactionFilterInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ITransactionFilterInputQuery, ITransactionFilterInputQueryVariables>(
    TransactionFilterInputDocument,
    options
  );
}
export function useTransactionFilterInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ITransactionFilterInputQuery, ITransactionFilterInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ITransactionFilterInputQuery, ITransactionFilterInputQueryVariables>(
    TransactionFilterInputDocument,
    options
  );
}
export type TransactionFilterInputQueryHookResult = ReturnType<typeof useTransactionFilterInputQuery>;
export type TransactionFilterInputLazyQueryHookResult = ReturnType<typeof useTransactionFilterInputLazyQuery>;
export type TransactionFilterInputSuspenseQueryHookResult = ReturnType<typeof useTransactionFilterInputSuspenseQuery>;
export type TransactionFilterInputQueryResult = Apollo.QueryResult<
  ITransactionFilterInputQuery,
  ITransactionFilterInputQueryVariables
>;
export const TransactionsDocument = gql`
    query Transactions($filters: TransactionFilters!, $cursor: CursorInput!, $orderAscendingByTime: Boolean!) {
  bookkeeping {
    transactionsV2(
      filters: $filters
      cursor: $cursor
      orderAscendingByTime: $orderAscendingByTime
    ) {
      data {
        attributedToAsset {
          ...AssetLabelFields
        }
        id
        deduplicationId
        tags
        externalType
        comment
        subAccount {
          id
          name
          account {
            id
            name
            venue {
              label
            }
          }
        }
        order {
          type
          externalId
          side
        }
        status
        userType
        externalId
        time
        legs {
          id
          asset {
            ...AssetLabelFields
          }
          type
          time
          unitValue
          amount
          slot
          side
          value
          journalEntry {
            error
            entryType
            costBasis
            remainingAmount
            amount
            asset {
              ...AssetLabelFields
            }
          }
        }
      }
      cursors {
        first
        last
        hasNextPage
        hasPreviousPage
      }
    }
  }
}
    ${AssetLabelFieldsFragmentDoc}`;

/**
 * __useTransactionsQuery__
 *
 * To run a query within a React component, call `useTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      cursor: // value for 'cursor'
 *      orderAscendingByTime: // value for 'orderAscendingByTime'
 *   },
 * });
 */
export function useTransactionsQuery(
  baseOptions: Apollo.QueryHookOptions<ITransactionsQuery, ITransactionsQueryVariables> &
    ({ variables: ITransactionsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ITransactionsQuery, ITransactionsQueryVariables>(TransactionsDocument, options);
}
export function useTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ITransactionsQuery, ITransactionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ITransactionsQuery, ITransactionsQueryVariables>(TransactionsDocument, options);
}
export function useTransactionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ITransactionsQuery, ITransactionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ITransactionsQuery, ITransactionsQueryVariables>(TransactionsDocument, options);
}
export type TransactionsQueryHookResult = ReturnType<typeof useTransactionsQuery>;
export type TransactionsLazyQueryHookResult = ReturnType<typeof useTransactionsLazyQuery>;
export type TransactionsSuspenseQueryHookResult = ReturnType<typeof useTransactionsSuspenseQuery>;
export type TransactionsQueryResult = Apollo.QueryResult<ITransactionsQuery, ITransactionsQueryVariables>;
export const UpdateTransactionDocument = gql`
    mutation UpdateTransaction($input: UpdateTransactionV2InputType!) {
  UpdateTransactionV2(input: $input) {
    id
  }
}
    `;
export type IUpdateTransactionMutationFn = Apollo.MutationFunction<
  IUpdateTransactionMutation,
  IUpdateTransactionMutationVariables
>;

/**
 * __useUpdateTransactionMutation__
 *
 * To run a mutation, you first call `useUpdateTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTransactionMutation, { data, loading, error }] = useUpdateTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTransactionMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdateTransactionMutation, IUpdateTransactionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IUpdateTransactionMutation, IUpdateTransactionMutationVariables>(
    UpdateTransactionDocument,
    options
  );
}
export type UpdateTransactionMutationHookResult = ReturnType<typeof useUpdateTransactionMutation>;
export type UpdateTransactionMutationResult = Apollo.MutationResult<IUpdateTransactionMutation>;
export type UpdateTransactionMutationOptions = Apollo.BaseMutationOptions<
  IUpdateTransactionMutation,
  IUpdateTransactionMutationVariables
>;
export const CreateTransactionDocument = gql`
    mutation CreateTransaction($input: CreateTransactionV2InputType!) {
  CreateTransactionV2(input: $input) {
    id
  }
}
    `;
export type ICreateTransactionMutationFn = Apollo.MutationFunction<
  ICreateTransactionMutation,
  ICreateTransactionMutationVariables
>;

/**
 * __useCreateTransactionMutation__
 *
 * To run a mutation, you first call `useCreateTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTransactionMutation, { data, loading, error }] = useCreateTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTransactionMutation(
  baseOptions?: Apollo.MutationHookOptions<ICreateTransactionMutation, ICreateTransactionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICreateTransactionMutation, ICreateTransactionMutationVariables>(
    CreateTransactionDocument,
    options
  );
}
export type CreateTransactionMutationHookResult = ReturnType<typeof useCreateTransactionMutation>;
export type CreateTransactionMutationResult = Apollo.MutationResult<ICreateTransactionMutation>;
export type CreateTransactionMutationOptions = Apollo.BaseMutationOptions<
  ICreateTransactionMutation,
  ICreateTransactionMutationVariables
>;
export const AgentListDocument = gql`
    query AgentList {
  news {
    agents {
      agents {
        id
        name
        description
        icon
      }
    }
  }
}
    `;

/**
 * __useAgentListQuery__
 *
 * To run a query within a React component, call `useAgentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgentListQuery({
 *   variables: {
 *   },
 * });
 */
export function useAgentListQuery(baseOptions?: Apollo.QueryHookOptions<IAgentListQuery, IAgentListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IAgentListQuery, IAgentListQueryVariables>(AgentListDocument, options);
}
export function useAgentListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAgentListQuery, IAgentListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IAgentListQuery, IAgentListQueryVariables>(AgentListDocument, options);
}
export function useAgentListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IAgentListQuery, IAgentListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IAgentListQuery, IAgentListQueryVariables>(AgentListDocument, options);
}
export type AgentListQueryHookResult = ReturnType<typeof useAgentListQuery>;
export type AgentListLazyQueryHookResult = ReturnType<typeof useAgentListLazyQuery>;
export type AgentListSuspenseQueryHookResult = ReturnType<typeof useAgentListSuspenseQuery>;
export type AgentListQueryResult = Apollo.QueryResult<IAgentListQuery, IAgentListQueryVariables>;
export const GetMessageUpdateDocument = gql`
    query GetMessageUpdate($messageId: UUID!) {
  news {
    agents {
      message(messageId: $messageId) {
        id
        createdAt
        content
        role
        agentId
        agentResult {
          status
          code
          type
          value
        }
        reaction {
          reaction
        }
      }
    }
  }
}
    `;

/**
 * __useGetMessageUpdateQuery__
 *
 * To run a query within a React component, call `useGetMessageUpdateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessageUpdateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessageUpdateQuery({
 *   variables: {
 *      messageId: // value for 'messageId'
 *   },
 * });
 */
export function useGetMessageUpdateQuery(
  baseOptions: Apollo.QueryHookOptions<IGetMessageUpdateQuery, IGetMessageUpdateQueryVariables> &
    ({ variables: IGetMessageUpdateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IGetMessageUpdateQuery, IGetMessageUpdateQueryVariables>(GetMessageUpdateDocument, options);
}
export function useGetMessageUpdateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetMessageUpdateQuery, IGetMessageUpdateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IGetMessageUpdateQuery, IGetMessageUpdateQueryVariables>(
    GetMessageUpdateDocument,
    options
  );
}
export function useGetMessageUpdateSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetMessageUpdateQuery, IGetMessageUpdateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IGetMessageUpdateQuery, IGetMessageUpdateQueryVariables>(
    GetMessageUpdateDocument,
    options
  );
}
export type GetMessageUpdateQueryHookResult = ReturnType<typeof useGetMessageUpdateQuery>;
export type GetMessageUpdateLazyQueryHookResult = ReturnType<typeof useGetMessageUpdateLazyQuery>;
export type GetMessageUpdateSuspenseQueryHookResult = ReturnType<typeof useGetMessageUpdateSuspenseQuery>;
export type GetMessageUpdateQueryResult = Apollo.QueryResult<IGetMessageUpdateQuery, IGetMessageUpdateQueryVariables>;
export const MessageHistoryDocument = gql`
    query MessageHistory($conversationId: UUID!, $limit: Int!, $offset: Int!) {
  news {
    agents {
      messages(conversationId: $conversationId, limit: $limit, offset: $offset) {
        id
        role
        content
        agentResult {
          value
          type
          code
          status
        }
        agentId
        createdAt
        reaction {
          reaction
        }
      }
    }
  }
}
    `;

/**
 * __useMessageHistoryQuery__
 *
 * To run a query within a React component, call `useMessageHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageHistoryQuery({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useMessageHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<IMessageHistoryQuery, IMessageHistoryQueryVariables> &
    ({ variables: IMessageHistoryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IMessageHistoryQuery, IMessageHistoryQueryVariables>(MessageHistoryDocument, options);
}
export function useMessageHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IMessageHistoryQuery, IMessageHistoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IMessageHistoryQuery, IMessageHistoryQueryVariables>(MessageHistoryDocument, options);
}
export function useMessageHistorySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IMessageHistoryQuery, IMessageHistoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IMessageHistoryQuery, IMessageHistoryQueryVariables>(MessageHistoryDocument, options);
}
export type MessageHistoryQueryHookResult = ReturnType<typeof useMessageHistoryQuery>;
export type MessageHistoryLazyQueryHookResult = ReturnType<typeof useMessageHistoryLazyQuery>;
export type MessageHistorySuspenseQueryHookResult = ReturnType<typeof useMessageHistorySuspenseQuery>;
export type MessageHistoryQueryResult = Apollo.QueryResult<IMessageHistoryQuery, IMessageHistoryQueryVariables>;
export const SendMessageDocument = gql`
    mutation SendMessage($input: MessageInput!) {
  SendMessage(messageInput: $input) {
    userMessage {
      id
      createdAt
    }
    agentMessage {
      id
      agentId
      createdAt
    }
  }
}
    `;
export type ISendMessageMutationFn = Apollo.MutationFunction<ISendMessageMutation, ISendMessageMutationVariables>;

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<ISendMessageMutation, ISendMessageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ISendMessageMutation, ISendMessageMutationVariables>(SendMessageDocument, options);
}
export type SendMessageMutationHookResult = ReturnType<typeof useSendMessageMutation>;
export type SendMessageMutationResult = Apollo.MutationResult<ISendMessageMutation>;
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<
  ISendMessageMutation,
  ISendMessageMutationVariables
>;
export const UpdateReactionDocument = gql`
    mutation UpdateReaction($input: MessageReactionInput!) {
  UpdateMessageReaction(messageReactionInput: $input)
}
    `;
export type IUpdateReactionMutationFn = Apollo.MutationFunction<
  IUpdateReactionMutation,
  IUpdateReactionMutationVariables
>;

/**
 * __useUpdateReactionMutation__
 *
 * To run a mutation, you first call `useUpdateReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReactionMutation, { data, loading, error }] = useUpdateReactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReactionMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdateReactionMutation, IUpdateReactionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IUpdateReactionMutation, IUpdateReactionMutationVariables>(UpdateReactionDocument, options);
}
export type UpdateReactionMutationHookResult = ReturnType<typeof useUpdateReactionMutation>;
export type UpdateReactionMutationResult = Apollo.MutationResult<IUpdateReactionMutation>;
export type UpdateReactionMutationOptions = Apollo.BaseMutationOptions<
  IUpdateReactionMutation,
  IUpdateReactionMutationVariables
>;
export const ConversationHistoryDocument = gql`
    query ConversationHistory($offset: Int!, $limit: Int!) {
  news {
    agents {
      conversations(offset: $offset, limit: $limit) {
        id
        title
        updatedAt
      }
    }
  }
}
    `;

/**
 * __useConversationHistoryQuery__
 *
 * To run a query within a React component, call `useConversationHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationHistoryQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useConversationHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<IConversationHistoryQuery, IConversationHistoryQueryVariables> &
    ({ variables: IConversationHistoryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IConversationHistoryQuery, IConversationHistoryQueryVariables>(
    ConversationHistoryDocument,
    options
  );
}
export function useConversationHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IConversationHistoryQuery, IConversationHistoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IConversationHistoryQuery, IConversationHistoryQueryVariables>(
    ConversationHistoryDocument,
    options
  );
}
export function useConversationHistorySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IConversationHistoryQuery, IConversationHistoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IConversationHistoryQuery, IConversationHistoryQueryVariables>(
    ConversationHistoryDocument,
    options
  );
}
export type ConversationHistoryQueryHookResult = ReturnType<typeof useConversationHistoryQuery>;
export type ConversationHistoryLazyQueryHookResult = ReturnType<typeof useConversationHistoryLazyQuery>;
export type ConversationHistorySuspenseQueryHookResult = ReturnType<typeof useConversationHistorySuspenseQuery>;
export type ConversationHistoryQueryResult = Apollo.QueryResult<
  IConversationHistoryQuery,
  IConversationHistoryQueryVariables
>;
export const DeleteConversationDocument = gql`
    mutation DeleteConversation($convId: UUID!) {
  DeleteConversation(conversationId: $convId)
}
    `;
export type IDeleteConversationMutationFn = Apollo.MutationFunction<
  IDeleteConversationMutation,
  IDeleteConversationMutationVariables
>;

/**
 * __useDeleteConversationMutation__
 *
 * To run a mutation, you first call `useDeleteConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConversationMutation, { data, loading, error }] = useDeleteConversationMutation({
 *   variables: {
 *      convId: // value for 'convId'
 *   },
 * });
 */
export function useDeleteConversationMutation(
  baseOptions?: Apollo.MutationHookOptions<IDeleteConversationMutation, IDeleteConversationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IDeleteConversationMutation, IDeleteConversationMutationVariables>(
    DeleteConversationDocument,
    options
  );
}
export type DeleteConversationMutationHookResult = ReturnType<typeof useDeleteConversationMutation>;
export type DeleteConversationMutationResult = Apollo.MutationResult<IDeleteConversationMutation>;
export type DeleteConversationMutationOptions = Apollo.BaseMutationOptions<
  IDeleteConversationMutation,
  IDeleteConversationMutationVariables
>;
export const FactorRegressionDocument = gql`
    query FactorRegression($input: RegressionServiceInputV2!) {
  factorRegressionV2 {
    portfolioFactorRegression(input: $input) {
      portfolioDefinition {
        id
        name
      }
      results {
        date
        results {
          factor {
            id
            symbol
          }
          beta
          pValue
          stdErr
          confidenceIntervals {
            name
            value
          }
        }
        fValue
        rSquared
        alphaValue
        alpha {
          factor {
            id
            symbol
          }
          beta
          pValue
          stdErr
          confidenceIntervals {
            name
            value
          }
        }
      }
      message
      code
    }
  }
}
    `;

/**
 * __useFactorRegressionQuery__
 *
 * To run a query within a React component, call `useFactorRegressionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFactorRegressionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFactorRegressionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFactorRegressionQuery(
  baseOptions: Apollo.QueryHookOptions<IFactorRegressionQuery, IFactorRegressionQueryVariables> &
    ({ variables: IFactorRegressionQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IFactorRegressionQuery, IFactorRegressionQueryVariables>(FactorRegressionDocument, options);
}
export function useFactorRegressionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IFactorRegressionQuery, IFactorRegressionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IFactorRegressionQuery, IFactorRegressionQueryVariables>(
    FactorRegressionDocument,
    options
  );
}
export function useFactorRegressionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IFactorRegressionQuery, IFactorRegressionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IFactorRegressionQuery, IFactorRegressionQueryVariables>(
    FactorRegressionDocument,
    options
  );
}
export type FactorRegressionQueryHookResult = ReturnType<typeof useFactorRegressionQuery>;
export type FactorRegressionLazyQueryHookResult = ReturnType<typeof useFactorRegressionLazyQuery>;
export type FactorRegressionSuspenseQueryHookResult = ReturnType<typeof useFactorRegressionSuspenseQuery>;
export type FactorRegressionQueryResult = Apollo.QueryResult<IFactorRegressionQuery, IFactorRegressionQueryVariables>;
export const FactorRegressionInputDocument = gql`
    query FactorRegressionInput {
  factorRegressionV2 {
    supportedFactors {
      asset {
        ...MainAssetFields
      }
      type
    }
    factorPresets {
      name
      factors {
        asset {
          ...MainAssetFields
        }
        type
      }
      description
    }
  }
}
    ${MainAssetFieldsFragmentDoc}`;

/**
 * __useFactorRegressionInputQuery__
 *
 * To run a query within a React component, call `useFactorRegressionInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useFactorRegressionInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFactorRegressionInputQuery({
 *   variables: {
 *   },
 * });
 */
export function useFactorRegressionInputQuery(
  baseOptions?: Apollo.QueryHookOptions<IFactorRegressionInputQuery, IFactorRegressionInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IFactorRegressionInputQuery, IFactorRegressionInputQueryVariables>(
    FactorRegressionInputDocument,
    options
  );
}
export function useFactorRegressionInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IFactorRegressionInputQuery, IFactorRegressionInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IFactorRegressionInputQuery, IFactorRegressionInputQueryVariables>(
    FactorRegressionInputDocument,
    options
  );
}
export function useFactorRegressionInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IFactorRegressionInputQuery, IFactorRegressionInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IFactorRegressionInputQuery, IFactorRegressionInputQueryVariables>(
    FactorRegressionInputDocument,
    options
  );
}
export type FactorRegressionInputQueryHookResult = ReturnType<typeof useFactorRegressionInputQuery>;
export type FactorRegressionInputLazyQueryHookResult = ReturnType<typeof useFactorRegressionInputLazyQuery>;
export type FactorRegressionInputSuspenseQueryHookResult = ReturnType<typeof useFactorRegressionInputSuspenseQuery>;
export type FactorRegressionInputQueryResult = Apollo.QueryResult<
  IFactorRegressionInputQuery,
  IFactorRegressionInputQueryVariables
>;
export const PortfolioBacktestingInputDocument = gql`
    query PortfolioBacktestingInput {
  portfolio {
    definitions {
      id
      name
      description
      type
      createdFrom {
        name
        portfolioDefinition {
          id
          name
        }
      }
      dateRange {
        since
        to
      }
      genie
    }
    supportedRegimeDefinitions {
      id
      name
      regimes
    }
  }
  assets {
    assetGroups {
      userGroups {
        assets {
          symbol
          id
        }
        clusterName
        groupName
      }
      genieGroups {
        assets {
          symbol
          id
        }
        clusterName
        groupName
      }
    }
  }
}
    `;

/**
 * __usePortfolioBacktestingInputQuery__
 *
 * To run a query within a React component, call `usePortfolioBacktestingInputQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioBacktestingInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioBacktestingInputQuery({
 *   variables: {
 *   },
 * });
 */
export function usePortfolioBacktestingInputQuery(
  baseOptions?: Apollo.QueryHookOptions<IPortfolioBacktestingInputQuery, IPortfolioBacktestingInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPortfolioBacktestingInputQuery, IPortfolioBacktestingInputQueryVariables>(
    PortfolioBacktestingInputDocument,
    options
  );
}
export function usePortfolioBacktestingInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPortfolioBacktestingInputQuery, IPortfolioBacktestingInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPortfolioBacktestingInputQuery, IPortfolioBacktestingInputQueryVariables>(
    PortfolioBacktestingInputDocument,
    options
  );
}
export function usePortfolioBacktestingInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IPortfolioBacktestingInputQuery,
    IPortfolioBacktestingInputQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IPortfolioBacktestingInputQuery, IPortfolioBacktestingInputQueryVariables>(
    PortfolioBacktestingInputDocument,
    options
  );
}
export type PortfolioBacktestingInputQueryHookResult = ReturnType<typeof usePortfolioBacktestingInputQuery>;
export type PortfolioBacktestingInputLazyQueryHookResult = ReturnType<typeof usePortfolioBacktestingInputLazyQuery>;
export type PortfolioBacktestingInputSuspenseQueryHookResult = ReturnType<
  typeof usePortfolioBacktestingInputSuspenseQuery
>;
export type PortfolioBacktestingInputQueryResult = Apollo.QueryResult<
  IPortfolioBacktestingInputQuery,
  IPortfolioBacktestingInputQueryVariables
>;
export const PortfolioCorrelationsDocument = gql`
    query PortfolioCorrelations($input: PortfolioCorrelationInputType!) {
  portfolio {
    portfolioCorrelations(input: $input) {
      correlations {
        date
        value
      }
      portfolioPair {
        portfolioA {
          id
          name
        }
        portfolioB {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __usePortfolioCorrelationsQuery__
 *
 * To run a query within a React component, call `usePortfolioCorrelationsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioCorrelationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioCorrelationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePortfolioCorrelationsQuery(
  baseOptions: Apollo.QueryHookOptions<IPortfolioCorrelationsQuery, IPortfolioCorrelationsQueryVariables> &
    ({ variables: IPortfolioCorrelationsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPortfolioCorrelationsQuery, IPortfolioCorrelationsQueryVariables>(
    PortfolioCorrelationsDocument,
    options
  );
}
export function usePortfolioCorrelationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPortfolioCorrelationsQuery, IPortfolioCorrelationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPortfolioCorrelationsQuery, IPortfolioCorrelationsQueryVariables>(
    PortfolioCorrelationsDocument,
    options
  );
}
export function usePortfolioCorrelationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IPortfolioCorrelationsQuery, IPortfolioCorrelationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IPortfolioCorrelationsQuery, IPortfolioCorrelationsQueryVariables>(
    PortfolioCorrelationsDocument,
    options
  );
}
export type PortfolioCorrelationsQueryHookResult = ReturnType<typeof usePortfolioCorrelationsQuery>;
export type PortfolioCorrelationsLazyQueryHookResult = ReturnType<typeof usePortfolioCorrelationsLazyQuery>;
export type PortfolioCorrelationsSuspenseQueryHookResult = ReturnType<typeof usePortfolioCorrelationsSuspenseQuery>;
export type PortfolioCorrelationsQueryResult = Apollo.QueryResult<
  IPortfolioCorrelationsQuery,
  IPortfolioCorrelationsQueryVariables
>;
export const PortfolioDetailsDocument = gql`
    query PortfolioDetails($portfolioIds: [UUID!]!, $since: Date!, $to: Date) {
  portfolio {
    analysis(portfolioIds: $portfolioIds, since: $since, to: $to) {
      allocations {
        assetAllocation {
          asset {
            symbol
            id
          }
          value
        }
        date
      }
      returns {
        date
        portfolioWeightedReturn
      }
      cashAssetIds
      portfolioDefinition {
        id
        name
      }
    }
  }
}
    `;

/**
 * __usePortfolioDetailsQuery__
 *
 * To run a query within a React component, call `usePortfolioDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioDetailsQuery({
 *   variables: {
 *      portfolioIds: // value for 'portfolioIds'
 *      since: // value for 'since'
 *      to: // value for 'to'
 *   },
 * });
 */
export function usePortfolioDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<IPortfolioDetailsQuery, IPortfolioDetailsQueryVariables> &
    ({ variables: IPortfolioDetailsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPortfolioDetailsQuery, IPortfolioDetailsQueryVariables>(PortfolioDetailsDocument, options);
}
export function usePortfolioDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPortfolioDetailsQuery, IPortfolioDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPortfolioDetailsQuery, IPortfolioDetailsQueryVariables>(
    PortfolioDetailsDocument,
    options
  );
}
export function usePortfolioDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IPortfolioDetailsQuery, IPortfolioDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IPortfolioDetailsQuery, IPortfolioDetailsQueryVariables>(
    PortfolioDetailsDocument,
    options
  );
}
export type PortfolioDetailsQueryHookResult = ReturnType<typeof usePortfolioDetailsQuery>;
export type PortfolioDetailsLazyQueryHookResult = ReturnType<typeof usePortfolioDetailsLazyQuery>;
export type PortfolioDetailsSuspenseQueryHookResult = ReturnType<typeof usePortfolioDetailsSuspenseQuery>;
export type PortfolioDetailsQueryResult = Apollo.QueryResult<IPortfolioDetailsQuery, IPortfolioDetailsQueryVariables>;
export const PortfolioDetailsCommentaryDocument = gql`
    query PortfolioDetailsCommentary($input: SummaryInput!) {
  portfolio {
    portfolioSummary(input: $input) {
      content
      index
      title
      portfolio {
        id
      }
    }
  }
}
    `;

/**
 * __usePortfolioDetailsCommentaryQuery__
 *
 * To run a query within a React component, call `usePortfolioDetailsCommentaryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioDetailsCommentaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioDetailsCommentaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePortfolioDetailsCommentaryQuery(
  baseOptions: Apollo.QueryHookOptions<IPortfolioDetailsCommentaryQuery, IPortfolioDetailsCommentaryQueryVariables> &
    ({ variables: IPortfolioDetailsCommentaryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPortfolioDetailsCommentaryQuery, IPortfolioDetailsCommentaryQueryVariables>(
    PortfolioDetailsCommentaryDocument,
    options
  );
}
export function usePortfolioDetailsCommentaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPortfolioDetailsCommentaryQuery, IPortfolioDetailsCommentaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPortfolioDetailsCommentaryQuery, IPortfolioDetailsCommentaryQueryVariables>(
    PortfolioDetailsCommentaryDocument,
    options
  );
}
export function usePortfolioDetailsCommentarySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IPortfolioDetailsCommentaryQuery,
    IPortfolioDetailsCommentaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IPortfolioDetailsCommentaryQuery, IPortfolioDetailsCommentaryQueryVariables>(
    PortfolioDetailsCommentaryDocument,
    options
  );
}
export type PortfolioDetailsCommentaryQueryHookResult = ReturnType<typeof usePortfolioDetailsCommentaryQuery>;
export type PortfolioDetailsCommentaryLazyQueryHookResult = ReturnType<typeof usePortfolioDetailsCommentaryLazyQuery>;
export type PortfolioDetailsCommentarySuspenseQueryHookResult = ReturnType<
  typeof usePortfolioDetailsCommentarySuspenseQuery
>;
export type PortfolioDetailsCommentaryQueryResult = Apollo.QueryResult<
  IPortfolioDetailsCommentaryQuery,
  IPortfolioDetailsCommentaryQueryVariables
>;
export const PortfolioRegimeDocument = gql`
    query PortfolioRegime {
  portfolio {
    supportedRegimeDefinitions {
      id
      name
      regimes
    }
  }
}
    `;

/**
 * __usePortfolioRegimeQuery__
 *
 * To run a query within a React component, call `usePortfolioRegimeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioRegimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioRegimeQuery({
 *   variables: {
 *   },
 * });
 */
export function usePortfolioRegimeQuery(
  baseOptions?: Apollo.QueryHookOptions<IPortfolioRegimeQuery, IPortfolioRegimeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPortfolioRegimeQuery, IPortfolioRegimeQueryVariables>(PortfolioRegimeDocument, options);
}
export function usePortfolioRegimeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPortfolioRegimeQuery, IPortfolioRegimeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPortfolioRegimeQuery, IPortfolioRegimeQueryVariables>(PortfolioRegimeDocument, options);
}
export function usePortfolioRegimeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IPortfolioRegimeQuery, IPortfolioRegimeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IPortfolioRegimeQuery, IPortfolioRegimeQueryVariables>(
    PortfolioRegimeDocument,
    options
  );
}
export type PortfolioRegimeQueryHookResult = ReturnType<typeof usePortfolioRegimeQuery>;
export type PortfolioRegimeLazyQueryHookResult = ReturnType<typeof usePortfolioRegimeLazyQuery>;
export type PortfolioRegimeSuspenseQueryHookResult = ReturnType<typeof usePortfolioRegimeSuspenseQuery>;
export type PortfolioRegimeQueryResult = Apollo.QueryResult<IPortfolioRegimeQuery, IPortfolioRegimeQueryVariables>;
export const PortfolioRegimeResultDocument = gql`
    query PortfolioRegimeResult($input: RegimeRiskInput!) {
  portfolio {
    regimeRisk(input: $input) {
      portfolio {
        name
        id
      }
      data {
        message
        metrics {
          benchmark {
            symbol
            id
          }
          format
          metricName
          metricValue
        }
        regimeName
        returns
      }
    }
  }
}
    `;

/**
 * __usePortfolioRegimeResultQuery__
 *
 * To run a query within a React component, call `usePortfolioRegimeResultQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioRegimeResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioRegimeResultQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePortfolioRegimeResultQuery(
  baseOptions: Apollo.QueryHookOptions<IPortfolioRegimeResultQuery, IPortfolioRegimeResultQueryVariables> &
    ({ variables: IPortfolioRegimeResultQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPortfolioRegimeResultQuery, IPortfolioRegimeResultQueryVariables>(
    PortfolioRegimeResultDocument,
    options
  );
}
export function usePortfolioRegimeResultLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPortfolioRegimeResultQuery, IPortfolioRegimeResultQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPortfolioRegimeResultQuery, IPortfolioRegimeResultQueryVariables>(
    PortfolioRegimeResultDocument,
    options
  );
}
export function usePortfolioRegimeResultSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IPortfolioRegimeResultQuery, IPortfolioRegimeResultQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IPortfolioRegimeResultQuery, IPortfolioRegimeResultQueryVariables>(
    PortfolioRegimeResultDocument,
    options
  );
}
export type PortfolioRegimeResultQueryHookResult = ReturnType<typeof usePortfolioRegimeResultQuery>;
export type PortfolioRegimeResultLazyQueryHookResult = ReturnType<typeof usePortfolioRegimeResultLazyQuery>;
export type PortfolioRegimeResultSuspenseQueryHookResult = ReturnType<typeof usePortfolioRegimeResultSuspenseQuery>;
export type PortfolioRegimeResultQueryResult = Apollo.QueryResult<
  IPortfolioRegimeResultQuery,
  IPortfolioRegimeResultQueryVariables
>;
export const PortfolioRiskInputDocument = gql`
    query PortfolioRiskInput {
  portfolio {
    metricParameters {
      label
      parameters {
        name
        type
        minMax {
          default
          max
          min
        }
        options {
          id
          label
          value
        }
        visibleIf {
          parameter
          selectedOption
        }
        benchmarks {
          ...AssetLabelFields
        }
      }
    }
  }
}
    ${AssetLabelFieldsFragmentDoc}`;

/**
 * __usePortfolioRiskInputQuery__
 *
 * To run a query within a React component, call `usePortfolioRiskInputQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioRiskInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioRiskInputQuery({
 *   variables: {
 *   },
 * });
 */
export function usePortfolioRiskInputQuery(
  baseOptions?: Apollo.QueryHookOptions<IPortfolioRiskInputQuery, IPortfolioRiskInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPortfolioRiskInputQuery, IPortfolioRiskInputQueryVariables>(
    PortfolioRiskInputDocument,
    options
  );
}
export function usePortfolioRiskInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPortfolioRiskInputQuery, IPortfolioRiskInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPortfolioRiskInputQuery, IPortfolioRiskInputQueryVariables>(
    PortfolioRiskInputDocument,
    options
  );
}
export function usePortfolioRiskInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IPortfolioRiskInputQuery, IPortfolioRiskInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IPortfolioRiskInputQuery, IPortfolioRiskInputQueryVariables>(
    PortfolioRiskInputDocument,
    options
  );
}
export type PortfolioRiskInputQueryHookResult = ReturnType<typeof usePortfolioRiskInputQuery>;
export type PortfolioRiskInputLazyQueryHookResult = ReturnType<typeof usePortfolioRiskInputLazyQuery>;
export type PortfolioRiskInputSuspenseQueryHookResult = ReturnType<typeof usePortfolioRiskInputSuspenseQuery>;
export type PortfolioRiskInputQueryResult = Apollo.QueryResult<
  IPortfolioRiskInputQuery,
  IPortfolioRiskInputQueryVariables
>;
export const DeletePortfolioDefinitionDocument = gql`
    mutation DeletePortfolioDefinition($input: UUID!) {
  portfolio {
    deleteDefinition(input: $input)
  }
}
    `;
export type IDeletePortfolioDefinitionMutationFn = Apollo.MutationFunction<
  IDeletePortfolioDefinitionMutation,
  IDeletePortfolioDefinitionMutationVariables
>;

/**
 * __useDeletePortfolioDefinitionMutation__
 *
 * To run a mutation, you first call `useDeletePortfolioDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePortfolioDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePortfolioDefinitionMutation, { data, loading, error }] = useDeletePortfolioDefinitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePortfolioDefinitionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeletePortfolioDefinitionMutation,
    IDeletePortfolioDefinitionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IDeletePortfolioDefinitionMutation, IDeletePortfolioDefinitionMutationVariables>(
    DeletePortfolioDefinitionDocument,
    options
  );
}
export type DeletePortfolioDefinitionMutationHookResult = ReturnType<typeof useDeletePortfolioDefinitionMutation>;
export type DeletePortfolioDefinitionMutationResult = Apollo.MutationResult<IDeletePortfolioDefinitionMutation>;
export type DeletePortfolioDefinitionMutationOptions = Apollo.BaseMutationOptions<
  IDeletePortfolioDefinitionMutation,
  IDeletePortfolioDefinitionMutationVariables
>;
export const PortfolioDefListInputDocument = gql`
    query PortfolioDefListInput {
  portfolio {
    definitions {
      id
      name
      description
      type
      subType
      createdAt
      transactionTag
      realDefAccounts {
        ...AccountFields
      }
      realDefSubFunds {
        id
        name
        dimension
      }
      genie
      assets {
        ...AssetLabelFields
      }
    }
    accounts {
      ...AccountFields
    }
    subFunds {
      list {
        id
        name
        dimension
      }
    }
  }
}
    ${AccountFieldsFragmentDoc}
${AssetLabelFieldsFragmentDoc}`;

/**
 * __usePortfolioDefListInputQuery__
 *
 * To run a query within a React component, call `usePortfolioDefListInputQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioDefListInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioDefListInputQuery({
 *   variables: {
 *   },
 * });
 */
export function usePortfolioDefListInputQuery(
  baseOptions?: Apollo.QueryHookOptions<IPortfolioDefListInputQuery, IPortfolioDefListInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPortfolioDefListInputQuery, IPortfolioDefListInputQueryVariables>(
    PortfolioDefListInputDocument,
    options
  );
}
export function usePortfolioDefListInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPortfolioDefListInputQuery, IPortfolioDefListInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPortfolioDefListInputQuery, IPortfolioDefListInputQueryVariables>(
    PortfolioDefListInputDocument,
    options
  );
}
export function usePortfolioDefListInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IPortfolioDefListInputQuery, IPortfolioDefListInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IPortfolioDefListInputQuery, IPortfolioDefListInputQueryVariables>(
    PortfolioDefListInputDocument,
    options
  );
}
export type PortfolioDefListInputQueryHookResult = ReturnType<typeof usePortfolioDefListInputQuery>;
export type PortfolioDefListInputLazyQueryHookResult = ReturnType<typeof usePortfolioDefListInputLazyQuery>;
export type PortfolioDefListInputSuspenseQueryHookResult = ReturnType<typeof usePortfolioDefListInputSuspenseQuery>;
export type PortfolioDefListInputQueryResult = Apollo.QueryResult<
  IPortfolioDefListInputQuery,
  IPortfolioDefListInputQueryVariables
>;
export const PortfolioDefNameDocument = gql`
    query PortfolioDefName($id: UUID!) {
  portfolio {
    definition(id: $id) {
      id
      name
    }
  }
}
    `;

/**
 * __usePortfolioDefNameQuery__
 *
 * To run a query within a React component, call `usePortfolioDefNameQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioDefNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioDefNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePortfolioDefNameQuery(
  baseOptions: Apollo.QueryHookOptions<IPortfolioDefNameQuery, IPortfolioDefNameQueryVariables> &
    ({ variables: IPortfolioDefNameQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPortfolioDefNameQuery, IPortfolioDefNameQueryVariables>(PortfolioDefNameDocument, options);
}
export function usePortfolioDefNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPortfolioDefNameQuery, IPortfolioDefNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPortfolioDefNameQuery, IPortfolioDefNameQueryVariables>(
    PortfolioDefNameDocument,
    options
  );
}
export function usePortfolioDefNameSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IPortfolioDefNameQuery, IPortfolioDefNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IPortfolioDefNameQuery, IPortfolioDefNameQueryVariables>(
    PortfolioDefNameDocument,
    options
  );
}
export type PortfolioDefNameQueryHookResult = ReturnType<typeof usePortfolioDefNameQuery>;
export type PortfolioDefNameLazyQueryHookResult = ReturnType<typeof usePortfolioDefNameLazyQuery>;
export type PortfolioDefNameSuspenseQueryHookResult = ReturnType<typeof usePortfolioDefNameSuspenseQuery>;
export type PortfolioDefNameQueryResult = Apollo.QueryResult<IPortfolioDefNameQuery, IPortfolioDefNameQueryVariables>;
export const CreatePortfolioDefinitionDocument = gql`
    mutation CreatePortfolioDefinition($input: CreatePortfolioDefInput!) {
  portfolio {
    createDefinition(input: $input)
  }
}
    `;
export type ICreatePortfolioDefinitionMutationFn = Apollo.MutationFunction<
  ICreatePortfolioDefinitionMutation,
  ICreatePortfolioDefinitionMutationVariables
>;

/**
 * __useCreatePortfolioDefinitionMutation__
 *
 * To run a mutation, you first call `useCreatePortfolioDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePortfolioDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPortfolioDefinitionMutation, { data, loading, error }] = useCreatePortfolioDefinitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePortfolioDefinitionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ICreatePortfolioDefinitionMutation,
    ICreatePortfolioDefinitionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICreatePortfolioDefinitionMutation, ICreatePortfolioDefinitionMutationVariables>(
    CreatePortfolioDefinitionDocument,
    options
  );
}
export type CreatePortfolioDefinitionMutationHookResult = ReturnType<typeof useCreatePortfolioDefinitionMutation>;
export type CreatePortfolioDefinitionMutationResult = Apollo.MutationResult<ICreatePortfolioDefinitionMutation>;
export type CreatePortfolioDefinitionMutationOptions = Apollo.BaseMutationOptions<
  ICreatePortfolioDefinitionMutation,
  ICreatePortfolioDefinitionMutationVariables
>;
export const CreateTransactionBasedPortfolioDialogInputDocument = gql`
    query CreateTransactionBasedPortfolioDialogInput {
  bookkeeping {
    transactionTags
  }
}
    `;

/**
 * __useCreateTransactionBasedPortfolioDialogInputQuery__
 *
 * To run a query within a React component, call `useCreateTransactionBasedPortfolioDialogInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateTransactionBasedPortfolioDialogInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateTransactionBasedPortfolioDialogInputQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreateTransactionBasedPortfolioDialogInputQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ICreateTransactionBasedPortfolioDialogInputQuery,
    ICreateTransactionBasedPortfolioDialogInputQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ICreateTransactionBasedPortfolioDialogInputQuery,
    ICreateTransactionBasedPortfolioDialogInputQueryVariables
  >(CreateTransactionBasedPortfolioDialogInputDocument, options);
}
export function useCreateTransactionBasedPortfolioDialogInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ICreateTransactionBasedPortfolioDialogInputQuery,
    ICreateTransactionBasedPortfolioDialogInputQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ICreateTransactionBasedPortfolioDialogInputQuery,
    ICreateTransactionBasedPortfolioDialogInputQueryVariables
  >(CreateTransactionBasedPortfolioDialogInputDocument, options);
}
export function useCreateTransactionBasedPortfolioDialogInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ICreateTransactionBasedPortfolioDialogInputQuery,
    ICreateTransactionBasedPortfolioDialogInputQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ICreateTransactionBasedPortfolioDialogInputQuery,
    ICreateTransactionBasedPortfolioDialogInputQueryVariables
  >(CreateTransactionBasedPortfolioDialogInputDocument, options);
}
export type CreateTransactionBasedPortfolioDialogInputQueryHookResult = ReturnType<
  typeof useCreateTransactionBasedPortfolioDialogInputQuery
>;
export type CreateTransactionBasedPortfolioDialogInputLazyQueryHookResult = ReturnType<
  typeof useCreateTransactionBasedPortfolioDialogInputLazyQuery
>;
export type CreateTransactionBasedPortfolioDialogInputSuspenseQueryHookResult = ReturnType<
  typeof useCreateTransactionBasedPortfolioDialogInputSuspenseQuery
>;
export type CreateTransactionBasedPortfolioDialogInputQueryResult = Apollo.QueryResult<
  ICreateTransactionBasedPortfolioDialogInputQuery,
  ICreateTransactionBasedPortfolioDialogInputQueryVariables
>;
export const RecomputePortfolioDocument = gql`
    mutation RecomputePortfolio($id: UUID!) {
  portfolio {
    recomputePortfolio(id: $id)
  }
}
    `;
export type IRecomputePortfolioMutationFn = Apollo.MutationFunction<
  IRecomputePortfolioMutation,
  IRecomputePortfolioMutationVariables
>;

/**
 * __useRecomputePortfolioMutation__
 *
 * To run a mutation, you first call `useRecomputePortfolioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecomputePortfolioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recomputePortfolioMutation, { data, loading, error }] = useRecomputePortfolioMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRecomputePortfolioMutation(
  baseOptions?: Apollo.MutationHookOptions<IRecomputePortfolioMutation, IRecomputePortfolioMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IRecomputePortfolioMutation, IRecomputePortfolioMutationVariables>(
    RecomputePortfolioDocument,
    options
  );
}
export type RecomputePortfolioMutationHookResult = ReturnType<typeof useRecomputePortfolioMutation>;
export type RecomputePortfolioMutationResult = Apollo.MutationResult<IRecomputePortfolioMutation>;
export type RecomputePortfolioMutationOptions = Apollo.BaseMutationOptions<
  IRecomputePortfolioMutation,
  IRecomputePortfolioMutationVariables
>;
export const UploadRebalancePortfolioReturnsDocument = gql`
    mutation UploadRebalancePortfolioReturns($input: [CreateReturnsBasedPortfolioInput!]!) {
  portfolio {
    createReturnsBasedPortfolio(rows: $input)
  }
}
    `;
export type IUploadRebalancePortfolioReturnsMutationFn = Apollo.MutationFunction<
  IUploadRebalancePortfolioReturnsMutation,
  IUploadRebalancePortfolioReturnsMutationVariables
>;

/**
 * __useUploadRebalancePortfolioReturnsMutation__
 *
 * To run a mutation, you first call `useUploadRebalancePortfolioReturnsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadRebalancePortfolioReturnsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadRebalancePortfolioReturnsMutation, { data, loading, error }] = useUploadRebalancePortfolioReturnsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadRebalancePortfolioReturnsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUploadRebalancePortfolioReturnsMutation,
    IUploadRebalancePortfolioReturnsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IUploadRebalancePortfolioReturnsMutation,
    IUploadRebalancePortfolioReturnsMutationVariables
  >(UploadRebalancePortfolioReturnsDocument, options);
}
export type UploadRebalancePortfolioReturnsMutationHookResult = ReturnType<
  typeof useUploadRebalancePortfolioReturnsMutation
>;
export type UploadRebalancePortfolioReturnsMutationResult =
  Apollo.MutationResult<IUploadRebalancePortfolioReturnsMutation>;
export type UploadRebalancePortfolioReturnsMutationOptions = Apollo.BaseMutationOptions<
  IUploadRebalancePortfolioReturnsMutation,
  IUploadRebalancePortfolioReturnsMutationVariables
>;
export const ImportPositionsDocument = gql`
    mutation ImportPositions($remoteFileId: UUID!, $rebalancedPortfolioId: UUID!) {
  portfolio {
    importPortfolioFromFile(
      remoteFileId: $remoteFileId
      rebalancedPortfolioId: $rebalancedPortfolioId
    )
  }
}
    `;
export type IImportPositionsMutationFn = Apollo.MutationFunction<
  IImportPositionsMutation,
  IImportPositionsMutationVariables
>;

/**
 * __useImportPositionsMutation__
 *
 * To run a mutation, you first call `useImportPositionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportPositionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importPositionsMutation, { data, loading, error }] = useImportPositionsMutation({
 *   variables: {
 *      remoteFileId: // value for 'remoteFileId'
 *      rebalancedPortfolioId: // value for 'rebalancedPortfolioId'
 *   },
 * });
 */
export function useImportPositionsMutation(
  baseOptions?: Apollo.MutationHookOptions<IImportPositionsMutation, IImportPositionsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IImportPositionsMutation, IImportPositionsMutationVariables>(
    ImportPositionsDocument,
    options
  );
}
export type ImportPositionsMutationHookResult = ReturnType<typeof useImportPositionsMutation>;
export type ImportPositionsMutationResult = Apollo.MutationResult<IImportPositionsMutation>;
export type ImportPositionsMutationOptions = Apollo.BaseMutationOptions<
  IImportPositionsMutation,
  IImportPositionsMutationVariables
>;
export const ImportPositionsHistoryDocument = gql`
    query ImportPositionsHistory($portfolioDefinitionId: UUID!) {
  portfolio {
    imports(portfolioDefinitionIds: [$portfolioDefinitionId]) {
      status
      remoteFile {
        uploadTime
      }
      result {
        processedLines
        totalErrors
        errors {
          message
          lineNo
        }
      }
    }
  }
}
    `;

/**
 * __useImportPositionsHistoryQuery__
 *
 * To run a query within a React component, call `useImportPositionsHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useImportPositionsHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImportPositionsHistoryQuery({
 *   variables: {
 *      portfolioDefinitionId: // value for 'portfolioDefinitionId'
 *   },
 * });
 */
export function useImportPositionsHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<IImportPositionsHistoryQuery, IImportPositionsHistoryQueryVariables> &
    ({ variables: IImportPositionsHistoryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IImportPositionsHistoryQuery, IImportPositionsHistoryQueryVariables>(
    ImportPositionsHistoryDocument,
    options
  );
}
export function useImportPositionsHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IImportPositionsHistoryQuery, IImportPositionsHistoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IImportPositionsHistoryQuery, IImportPositionsHistoryQueryVariables>(
    ImportPositionsHistoryDocument,
    options
  );
}
export function useImportPositionsHistorySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IImportPositionsHistoryQuery, IImportPositionsHistoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IImportPositionsHistoryQuery, IImportPositionsHistoryQueryVariables>(
    ImportPositionsHistoryDocument,
    options
  );
}
export type ImportPositionsHistoryQueryHookResult = ReturnType<typeof useImportPositionsHistoryQuery>;
export type ImportPositionsHistoryLazyQueryHookResult = ReturnType<typeof useImportPositionsHistoryLazyQuery>;
export type ImportPositionsHistorySuspenseQueryHookResult = ReturnType<typeof useImportPositionsHistorySuspenseQuery>;
export type ImportPositionsHistoryQueryResult = Apollo.QueryResult<
  IImportPositionsHistoryQuery,
  IImportPositionsHistoryQueryVariables
>;
export const CreateRebalancingRuleDocument = gql`
    mutation CreateRebalancingRule($input: CreateRebalancingRuleInput!) {
  portfolio {
    createRebalancingRule(input: $input)
  }
}
    `;
export type ICreateRebalancingRuleMutationFn = Apollo.MutationFunction<
  ICreateRebalancingRuleMutation,
  ICreateRebalancingRuleMutationVariables
>;

/**
 * __useCreateRebalancingRuleMutation__
 *
 * To run a mutation, you first call `useCreateRebalancingRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRebalancingRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRebalancingRuleMutation, { data, loading, error }] = useCreateRebalancingRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRebalancingRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<ICreateRebalancingRuleMutation, ICreateRebalancingRuleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICreateRebalancingRuleMutation, ICreateRebalancingRuleMutationVariables>(
    CreateRebalancingRuleDocument,
    options
  );
}
export type CreateRebalancingRuleMutationHookResult = ReturnType<typeof useCreateRebalancingRuleMutation>;
export type CreateRebalancingRuleMutationResult = Apollo.MutationResult<ICreateRebalancingRuleMutation>;
export type CreateRebalancingRuleMutationOptions = Apollo.BaseMutationOptions<
  ICreateRebalancingRuleMutation,
  ICreateRebalancingRuleMutationVariables
>;
export const CreateRebalancingRuleInputDocument = gql`
    query CreateRebalancingRuleInput($portfolioDefinitionId: UUID!) {
  portfolio {
    definition(id: $portfolioDefinitionId) {
      id
      sinceForRebalancedPortfolio
    }
  }
}
    `;

/**
 * __useCreateRebalancingRuleInputQuery__
 *
 * To run a query within a React component, call `useCreateRebalancingRuleInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateRebalancingRuleInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateRebalancingRuleInputQuery({
 *   variables: {
 *      portfolioDefinitionId: // value for 'portfolioDefinitionId'
 *   },
 * });
 */
export function useCreateRebalancingRuleInputQuery(
  baseOptions: Apollo.QueryHookOptions<ICreateRebalancingRuleInputQuery, ICreateRebalancingRuleInputQueryVariables> &
    ({ variables: ICreateRebalancingRuleInputQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ICreateRebalancingRuleInputQuery, ICreateRebalancingRuleInputQueryVariables>(
    CreateRebalancingRuleInputDocument,
    options
  );
}
export function useCreateRebalancingRuleInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ICreateRebalancingRuleInputQuery, ICreateRebalancingRuleInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ICreateRebalancingRuleInputQuery, ICreateRebalancingRuleInputQueryVariables>(
    CreateRebalancingRuleInputDocument,
    options
  );
}
export function useCreateRebalancingRuleInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ICreateRebalancingRuleInputQuery,
    ICreateRebalancingRuleInputQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ICreateRebalancingRuleInputQuery, ICreateRebalancingRuleInputQueryVariables>(
    CreateRebalancingRuleInputDocument,
    options
  );
}
export type CreateRebalancingRuleInputQueryHookResult = ReturnType<typeof useCreateRebalancingRuleInputQuery>;
export type CreateRebalancingRuleInputLazyQueryHookResult = ReturnType<typeof useCreateRebalancingRuleInputLazyQuery>;
export type CreateRebalancingRuleInputSuspenseQueryHookResult = ReturnType<
  typeof useCreateRebalancingRuleInputSuspenseQuery
>;
export type CreateRebalancingRuleInputQueryResult = Apollo.QueryResult<
  ICreateRebalancingRuleInputQuery,
  ICreateRebalancingRuleInputQueryVariables
>;
export const DeleteRebalancingRuleDocument = gql`
    mutation DeleteRebalancingRule($id: UUID!) {
  portfolio {
    deleteRebalancingRule(id: $id)
  }
}
    `;
export type IDeleteRebalancingRuleMutationFn = Apollo.MutationFunction<
  IDeleteRebalancingRuleMutation,
  IDeleteRebalancingRuleMutationVariables
>;

/**
 * __useDeleteRebalancingRuleMutation__
 *
 * To run a mutation, you first call `useDeleteRebalancingRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRebalancingRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRebalancingRuleMutation, { data, loading, error }] = useDeleteRebalancingRuleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRebalancingRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<IDeleteRebalancingRuleMutation, IDeleteRebalancingRuleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IDeleteRebalancingRuleMutation, IDeleteRebalancingRuleMutationVariables>(
    DeleteRebalancingRuleDocument,
    options
  );
}
export type DeleteRebalancingRuleMutationHookResult = ReturnType<typeof useDeleteRebalancingRuleMutation>;
export type DeleteRebalancingRuleMutationResult = Apollo.MutationResult<IDeleteRebalancingRuleMutation>;
export type DeleteRebalancingRuleMutationOptions = Apollo.BaseMutationOptions<
  IDeleteRebalancingRuleMutation,
  IDeleteRebalancingRuleMutationVariables
>;
export const RebalancingRulesDocument = gql`
    query RebalancingRules($id: UUID!) {
  portfolio {
    definition(id: $id) {
      genie
      type
      rebalancingRules {
        id
        name
        start
        end
        periodUnit
        periodValue
        tradingFee
        maxRebalancingCost
      }
    }
  }
}
    `;

/**
 * __useRebalancingRulesQuery__
 *
 * To run a query within a React component, call `useRebalancingRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRebalancingRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRebalancingRulesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRebalancingRulesQuery(
  baseOptions: Apollo.QueryHookOptions<IRebalancingRulesQuery, IRebalancingRulesQueryVariables> &
    ({ variables: IRebalancingRulesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IRebalancingRulesQuery, IRebalancingRulesQueryVariables>(RebalancingRulesDocument, options);
}
export function useRebalancingRulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IRebalancingRulesQuery, IRebalancingRulesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IRebalancingRulesQuery, IRebalancingRulesQueryVariables>(
    RebalancingRulesDocument,
    options
  );
}
export function useRebalancingRulesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IRebalancingRulesQuery, IRebalancingRulesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IRebalancingRulesQuery, IRebalancingRulesQueryVariables>(
    RebalancingRulesDocument,
    options
  );
}
export type RebalancingRulesQueryHookResult = ReturnType<typeof useRebalancingRulesQuery>;
export type RebalancingRulesLazyQueryHookResult = ReturnType<typeof useRebalancingRulesLazyQuery>;
export type RebalancingRulesSuspenseQueryHookResult = ReturnType<typeof useRebalancingRulesSuspenseQuery>;
export type RebalancingRulesQueryResult = Apollo.QueryResult<IRebalancingRulesQuery, IRebalancingRulesQueryVariables>;
export const UpdatePortfolioDefinitionDocument = gql`
    mutation UpdatePortfolioDefinition($input: UpdatePortfolioDefInput!) {
  portfolio {
    updateDefinition(input: $input)
  }
}
    `;
export type IUpdatePortfolioDefinitionMutationFn = Apollo.MutationFunction<
  IUpdatePortfolioDefinitionMutation,
  IUpdatePortfolioDefinitionMutationVariables
>;

/**
 * __useUpdatePortfolioDefinitionMutation__
 *
 * To run a mutation, you first call `useUpdatePortfolioDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePortfolioDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePortfolioDefinitionMutation, { data, loading, error }] = useUpdatePortfolioDefinitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePortfolioDefinitionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdatePortfolioDefinitionMutation,
    IUpdatePortfolioDefinitionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IUpdatePortfolioDefinitionMutation, IUpdatePortfolioDefinitionMutationVariables>(
    UpdatePortfolioDefinitionDocument,
    options
  );
}
export type UpdatePortfolioDefinitionMutationHookResult = ReturnType<typeof useUpdatePortfolioDefinitionMutation>;
export type UpdatePortfolioDefinitionMutationResult = Apollo.MutationResult<IUpdatePortfolioDefinitionMutation>;
export type UpdatePortfolioDefinitionMutationOptions = Apollo.BaseMutationOptions<
  IUpdatePortfolioDefinitionMutation,
  IUpdatePortfolioDefinitionMutationVariables
>;
export const ConfirmNotificationDeliveryDocument = gql`
    mutation ConfirmNotificationDelivery($acknowledgedPlacedAt: ArrowType!) {
  ConfirmNotificationDelivery(acknowledgedPlacedAt: $acknowledgedPlacedAt)
}
    `;
export type IConfirmNotificationDeliveryMutationFn = Apollo.MutationFunction<
  IConfirmNotificationDeliveryMutation,
  IConfirmNotificationDeliveryMutationVariables
>;

/**
 * __useConfirmNotificationDeliveryMutation__
 *
 * To run a mutation, you first call `useConfirmNotificationDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmNotificationDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmNotificationDeliveryMutation, { data, loading, error }] = useConfirmNotificationDeliveryMutation({
 *   variables: {
 *      acknowledgedPlacedAt: // value for 'acknowledgedPlacedAt'
 *   },
 * });
 */
export function useConfirmNotificationDeliveryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IConfirmNotificationDeliveryMutation,
    IConfirmNotificationDeliveryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IConfirmNotificationDeliveryMutation, IConfirmNotificationDeliveryMutationVariables>(
    ConfirmNotificationDeliveryDocument,
    options
  );
}
export type ConfirmNotificationDeliveryMutationHookResult = ReturnType<typeof useConfirmNotificationDeliveryMutation>;
export type ConfirmNotificationDeliveryMutationResult = Apollo.MutationResult<IConfirmNotificationDeliveryMutation>;
export type ConfirmNotificationDeliveryMutationOptions = Apollo.BaseMutationOptions<
  IConfirmNotificationDeliveryMutation,
  IConfirmNotificationDeliveryMutationVariables
>;
export const MarkNotificationsAsReadDocument = gql`
    mutation MarkNotificationsAsRead($acknowledgedPlacedAt: ArrowType!) {
  MarkNotificationsAsRead(acknowledgedPlacedAt: $acknowledgedPlacedAt)
}
    `;
export type IMarkNotificationsAsReadMutationFn = Apollo.MutationFunction<
  IMarkNotificationsAsReadMutation,
  IMarkNotificationsAsReadMutationVariables
>;

/**
 * __useMarkNotificationsAsReadMutation__
 *
 * To run a mutation, you first call `useMarkNotificationsAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationsAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationsAsReadMutation, { data, loading, error }] = useMarkNotificationsAsReadMutation({
 *   variables: {
 *      acknowledgedPlacedAt: // value for 'acknowledgedPlacedAt'
 *   },
 * });
 */
export function useMarkNotificationsAsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<IMarkNotificationsAsReadMutation, IMarkNotificationsAsReadMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IMarkNotificationsAsReadMutation, IMarkNotificationsAsReadMutationVariables>(
    MarkNotificationsAsReadDocument,
    options
  );
}
export type MarkNotificationsAsReadMutationHookResult = ReturnType<typeof useMarkNotificationsAsReadMutation>;
export type MarkNotificationsAsReadMutationResult = Apollo.MutationResult<IMarkNotificationsAsReadMutation>;
export type MarkNotificationsAsReadMutationOptions = Apollo.BaseMutationOptions<
  IMarkNotificationsAsReadMutation,
  IMarkNotificationsAsReadMutationVariables
>;
export const NotificationSubscriptionsDocument = gql`
    query NotificationSubscriptions {
  notification {
    subscription {
      category
    }
  }
}
    `;

/**
 * __useNotificationSubscriptionsQuery__
 *
 * To run a query within a React component, call `useNotificationSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationSubscriptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<INotificationSubscriptionsQuery, INotificationSubscriptionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<INotificationSubscriptionsQuery, INotificationSubscriptionsQueryVariables>(
    NotificationSubscriptionsDocument,
    options
  );
}
export function useNotificationSubscriptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<INotificationSubscriptionsQuery, INotificationSubscriptionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<INotificationSubscriptionsQuery, INotificationSubscriptionsQueryVariables>(
    NotificationSubscriptionsDocument,
    options
  );
}
export function useNotificationSubscriptionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    INotificationSubscriptionsQuery,
    INotificationSubscriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<INotificationSubscriptionsQuery, INotificationSubscriptionsQueryVariables>(
    NotificationSubscriptionsDocument,
    options
  );
}
export type NotificationSubscriptionsQueryHookResult = ReturnType<typeof useNotificationSubscriptionsQuery>;
export type NotificationSubscriptionsLazyQueryHookResult = ReturnType<typeof useNotificationSubscriptionsLazyQuery>;
export type NotificationSubscriptionsSuspenseQueryHookResult = ReturnType<
  typeof useNotificationSubscriptionsSuspenseQuery
>;
export type NotificationSubscriptionsQueryResult = Apollo.QueryResult<
  INotificationSubscriptionsQuery,
  INotificationSubscriptionsQueryVariables
>;
export const NotificationsDocument = gql`
    query Notifications($pageLimit: PageLimitOffsetInput!, $status: NotificationStatus) {
  notification {
    list(paging: $pageLimit, status: $status) {
      data {
        title
        body
        status
        type
        placedAt
        requestId
      }
      pageInfo {
        totalResults
      }
    }
  }
}
    `;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      pageLimit: // value for 'pageLimit'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useNotificationsQuery(
  baseOptions: Apollo.QueryHookOptions<INotificationsQuery, INotificationsQueryVariables> &
    ({ variables: INotificationsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<INotificationsQuery, INotificationsQueryVariables>(NotificationsDocument, options);
}
export function useNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<INotificationsQuery, INotificationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<INotificationsQuery, INotificationsQueryVariables>(NotificationsDocument, options);
}
export function useNotificationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<INotificationsQuery, INotificationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<INotificationsQuery, INotificationsQueryVariables>(NotificationsDocument, options);
}
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsSuspenseQueryHookResult = ReturnType<typeof useNotificationsSuspenseQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<INotificationsQuery, INotificationsQueryVariables>;
export const UpdateNotificationStatusDocument = gql`
    mutation UpdateNotificationStatus($requestId: UUID!, $status: NotificationStatus!) {
  UpdateNotificationStatus(requestId: $requestId, targetStatus: $status)
}
    `;
export type IUpdateNotificationStatusMutationFn = Apollo.MutationFunction<
  IUpdateNotificationStatusMutation,
  IUpdateNotificationStatusMutationVariables
>;

/**
 * __useUpdateNotificationStatusMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationStatusMutation, { data, loading, error }] = useUpdateNotificationStatusMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateNotificationStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdateNotificationStatusMutation,
    IUpdateNotificationStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IUpdateNotificationStatusMutation, IUpdateNotificationStatusMutationVariables>(
    UpdateNotificationStatusDocument,
    options
  );
}
export type UpdateNotificationStatusMutationHookResult = ReturnType<typeof useUpdateNotificationStatusMutation>;
export type UpdateNotificationStatusMutationResult = Apollo.MutationResult<IUpdateNotificationStatusMutation>;
export type UpdateNotificationStatusMutationOptions = Apollo.BaseMutationOptions<
  IUpdateNotificationStatusMutation,
  IUpdateNotificationStatusMutationVariables
>;
export const UpdateNotificationSubscriptionsDocument = gql`
    mutation UpdateNotificationSubscriptions($categories: [String!]!) {
  UpdateSubscriptions(categories: $categories)
}
    `;
export type IUpdateNotificationSubscriptionsMutationFn = Apollo.MutationFunction<
  IUpdateNotificationSubscriptionsMutation,
  IUpdateNotificationSubscriptionsMutationVariables
>;

/**
 * __useUpdateNotificationSubscriptionsMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationSubscriptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationSubscriptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationSubscriptionsMutation, { data, loading, error }] = useUpdateNotificationSubscriptionsMutation({
 *   variables: {
 *      categories: // value for 'categories'
 *   },
 * });
 */
export function useUpdateNotificationSubscriptionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdateNotificationSubscriptionsMutation,
    IUpdateNotificationSubscriptionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IUpdateNotificationSubscriptionsMutation,
    IUpdateNotificationSubscriptionsMutationVariables
  >(UpdateNotificationSubscriptionsDocument, options);
}
export type UpdateNotificationSubscriptionsMutationHookResult = ReturnType<
  typeof useUpdateNotificationSubscriptionsMutation
>;
export type UpdateNotificationSubscriptionsMutationResult =
  Apollo.MutationResult<IUpdateNotificationSubscriptionsMutation>;
export type UpdateNotificationSubscriptionsMutationOptions = Apollo.BaseMutationOptions<
  IUpdateNotificationSubscriptionsMutation,
  IUpdateNotificationSubscriptionsMutationVariables
>;
export const EvaluateOptionsPortfolioDocument = gql`
    query EvaluateOptionsPortfolio($input: ScenarioAnalysisInputType!) {
  optionsAnalysis {
    scenarioAnalysis(input: $input) {
      referenceValue {
        greeks {
          delta
          gamma
          rho
          theta
          vega
        }
        marketPnl
        npvPnl
      }
      simulatedResults {
        parameterChange {
          price
          riskFree
          volatility
        }
        portfolioValue {
          npvPnl
          marketPnl
          greeks {
            vega
            theta
            rho
            gamma
            delta
          }
        }
      }
    }
  }
}
    `;

/**
 * __useEvaluateOptionsPortfolioQuery__
 *
 * To run a query within a React component, call `useEvaluateOptionsPortfolioQuery` and pass it any options that fit your needs.
 * When your component renders, `useEvaluateOptionsPortfolioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEvaluateOptionsPortfolioQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEvaluateOptionsPortfolioQuery(
  baseOptions: Apollo.QueryHookOptions<IEvaluateOptionsPortfolioQuery, IEvaluateOptionsPortfolioQueryVariables> &
    ({ variables: IEvaluateOptionsPortfolioQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IEvaluateOptionsPortfolioQuery, IEvaluateOptionsPortfolioQueryVariables>(
    EvaluateOptionsPortfolioDocument,
    options
  );
}
export function useEvaluateOptionsPortfolioLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IEvaluateOptionsPortfolioQuery, IEvaluateOptionsPortfolioQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IEvaluateOptionsPortfolioQuery, IEvaluateOptionsPortfolioQueryVariables>(
    EvaluateOptionsPortfolioDocument,
    options
  );
}
export function useEvaluateOptionsPortfolioSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IEvaluateOptionsPortfolioQuery, IEvaluateOptionsPortfolioQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IEvaluateOptionsPortfolioQuery, IEvaluateOptionsPortfolioQueryVariables>(
    EvaluateOptionsPortfolioDocument,
    options
  );
}
export type EvaluateOptionsPortfolioQueryHookResult = ReturnType<typeof useEvaluateOptionsPortfolioQuery>;
export type EvaluateOptionsPortfolioLazyQueryHookResult = ReturnType<typeof useEvaluateOptionsPortfolioLazyQuery>;
export type EvaluateOptionsPortfolioSuspenseQueryHookResult = ReturnType<
  typeof useEvaluateOptionsPortfolioSuspenseQuery
>;
export type EvaluateOptionsPortfolioQueryResult = Apollo.QueryResult<
  IEvaluateOptionsPortfolioQuery,
  IEvaluateOptionsPortfolioQueryVariables
>;
export const OptionsStressTestInputDocument = gql`
    query OptionsStressTestInput($expirationSince: ArrowType!, $baseAssetId: UUID!) {
  assets {
    list(
      filters: {baseAssetIds: [$baseAssetId], expirationSince: $expirationSince, derivativeTypes: [OPTION], assetTypes: DERIVATIVE}
    ) {
      symbol
      id
      derivativeDetails {
        exchange
      }
    }
  }
}
    `;

/**
 * __useOptionsStressTestInputQuery__
 *
 * To run a query within a React component, call `useOptionsStressTestInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptionsStressTestInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptionsStressTestInputQuery({
 *   variables: {
 *      expirationSince: // value for 'expirationSince'
 *      baseAssetId: // value for 'baseAssetId'
 *   },
 * });
 */
export function useOptionsStressTestInputQuery(
  baseOptions: Apollo.QueryHookOptions<IOptionsStressTestInputQuery, IOptionsStressTestInputQueryVariables> &
    ({ variables: IOptionsStressTestInputQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IOptionsStressTestInputQuery, IOptionsStressTestInputQueryVariables>(
    OptionsStressTestInputDocument,
    options
  );
}
export function useOptionsStressTestInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IOptionsStressTestInputQuery, IOptionsStressTestInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IOptionsStressTestInputQuery, IOptionsStressTestInputQueryVariables>(
    OptionsStressTestInputDocument,
    options
  );
}
export function useOptionsStressTestInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IOptionsStressTestInputQuery, IOptionsStressTestInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IOptionsStressTestInputQuery, IOptionsStressTestInputQueryVariables>(
    OptionsStressTestInputDocument,
    options
  );
}
export type OptionsStressTestInputQueryHookResult = ReturnType<typeof useOptionsStressTestInputQuery>;
export type OptionsStressTestInputLazyQueryHookResult = ReturnType<typeof useOptionsStressTestInputLazyQuery>;
export type OptionsStressTestInputSuspenseQueryHookResult = ReturnType<typeof useOptionsStressTestInputSuspenseQuery>;
export type OptionsStressTestInputQueryResult = Apollo.QueryResult<
  IOptionsStressTestInputQuery,
  IOptionsStressTestInputQueryVariables
>;
export const EvaluatePortfolioDocument = gql`
    query EvaluatePortfolio($input: FineTunerInput!) {
  portfolioEvaluation {
    portfolioEvaluation(input: $input) {
      riskMetrics {
        measure
        value
        benchmark {
          id
          icon
          label
          name
          symbol
          type
          exchangeDetails
        }
      }
    }
  }
}
    `;

/**
 * __useEvaluatePortfolioQuery__
 *
 * To run a query within a React component, call `useEvaluatePortfolioQuery` and pass it any options that fit your needs.
 * When your component renders, `useEvaluatePortfolioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEvaluatePortfolioQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEvaluatePortfolioQuery(
  baseOptions: Apollo.QueryHookOptions<IEvaluatePortfolioQuery, IEvaluatePortfolioQueryVariables> &
    ({ variables: IEvaluatePortfolioQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IEvaluatePortfolioQuery, IEvaluatePortfolioQueryVariables>(EvaluatePortfolioDocument, options);
}
export function useEvaluatePortfolioLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IEvaluatePortfolioQuery, IEvaluatePortfolioQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IEvaluatePortfolioQuery, IEvaluatePortfolioQueryVariables>(
    EvaluatePortfolioDocument,
    options
  );
}
export function useEvaluatePortfolioSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IEvaluatePortfolioQuery, IEvaluatePortfolioQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IEvaluatePortfolioQuery, IEvaluatePortfolioQueryVariables>(
    EvaluatePortfolioDocument,
    options
  );
}
export type EvaluatePortfolioQueryHookResult = ReturnType<typeof useEvaluatePortfolioQuery>;
export type EvaluatePortfolioLazyQueryHookResult = ReturnType<typeof useEvaluatePortfolioLazyQuery>;
export type EvaluatePortfolioSuspenseQueryHookResult = ReturnType<typeof useEvaluatePortfolioSuspenseQuery>;
export type EvaluatePortfolioQueryResult = Apollo.QueryResult<
  IEvaluatePortfolioQuery,
  IEvaluatePortfolioQueryVariables
>;
export const PortfolioBuilderInputDocument = gql`
    query PortfolioBuilderInput {
  assets {
    feature(feature: FINE_TUNER) {
      ...MainAssetFields
    }
    assetGroups {
      genieGroups {
        clusterName
        groupName
        assets {
          id
        }
      }
      userGroups {
        clusterName
        groupName
        assets {
          id
        }
      }
    }
  }
  portfolio {
    positions {
      positions {
        asset {
          ...AssetLabelFields
        }
        spot {
          price
          amount
          balance
        }
      }
    }
  }
}
    ${MainAssetFieldsFragmentDoc}
${AssetLabelFieldsFragmentDoc}`;

/**
 * __usePortfolioBuilderInputQuery__
 *
 * To run a query within a React component, call `usePortfolioBuilderInputQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioBuilderInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioBuilderInputQuery({
 *   variables: {
 *   },
 * });
 */
export function usePortfolioBuilderInputQuery(
  baseOptions?: Apollo.QueryHookOptions<IPortfolioBuilderInputQuery, IPortfolioBuilderInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPortfolioBuilderInputQuery, IPortfolioBuilderInputQueryVariables>(
    PortfolioBuilderInputDocument,
    options
  );
}
export function usePortfolioBuilderInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPortfolioBuilderInputQuery, IPortfolioBuilderInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPortfolioBuilderInputQuery, IPortfolioBuilderInputQueryVariables>(
    PortfolioBuilderInputDocument,
    options
  );
}
export function usePortfolioBuilderInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IPortfolioBuilderInputQuery, IPortfolioBuilderInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IPortfolioBuilderInputQuery, IPortfolioBuilderInputQueryVariables>(
    PortfolioBuilderInputDocument,
    options
  );
}
export type PortfolioBuilderInputQueryHookResult = ReturnType<typeof usePortfolioBuilderInputQuery>;
export type PortfolioBuilderInputLazyQueryHookResult = ReturnType<typeof usePortfolioBuilderInputLazyQuery>;
export type PortfolioBuilderInputSuspenseQueryHookResult = ReturnType<typeof usePortfolioBuilderInputSuspenseQuery>;
export type PortfolioBuilderInputQueryResult = Apollo.QueryResult<
  IPortfolioBuilderInputQuery,
  IPortfolioBuilderInputQueryVariables
>;
export const AssetOptimizationNameDocument = gql`
    query AssetOptimizationName($optimizationId: Int!) {
  portfolioOptimization {
    getOptimization(optimizationId: $optimizationId) {
      name
    }
  }
}
    `;

/**
 * __useAssetOptimizationNameQuery__
 *
 * To run a query within a React component, call `useAssetOptimizationNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetOptimizationNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetOptimizationNameQuery({
 *   variables: {
 *      optimizationId: // value for 'optimizationId'
 *   },
 * });
 */
export function useAssetOptimizationNameQuery(
  baseOptions: Apollo.QueryHookOptions<IAssetOptimizationNameQuery, IAssetOptimizationNameQueryVariables> &
    ({ variables: IAssetOptimizationNameQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IAssetOptimizationNameQuery, IAssetOptimizationNameQueryVariables>(
    AssetOptimizationNameDocument,
    options
  );
}
export function useAssetOptimizationNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAssetOptimizationNameQuery, IAssetOptimizationNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IAssetOptimizationNameQuery, IAssetOptimizationNameQueryVariables>(
    AssetOptimizationNameDocument,
    options
  );
}
export function useAssetOptimizationNameSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IAssetOptimizationNameQuery, IAssetOptimizationNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IAssetOptimizationNameQuery, IAssetOptimizationNameQueryVariables>(
    AssetOptimizationNameDocument,
    options
  );
}
export type AssetOptimizationNameQueryHookResult = ReturnType<typeof useAssetOptimizationNameQuery>;
export type AssetOptimizationNameLazyQueryHookResult = ReturnType<typeof useAssetOptimizationNameLazyQuery>;
export type AssetOptimizationNameSuspenseQueryHookResult = ReturnType<typeof useAssetOptimizationNameSuspenseQuery>;
export type AssetOptimizationNameQueryResult = Apollo.QueryResult<
  IAssetOptimizationNameQuery,
  IAssetOptimizationNameQueryVariables
>;
export const PortfolioOptimizationListDocument = gql`
    query PortfolioOptimizationList {
  portfolioOptimization {
    listOptimizations {
      optimizationId
      name
      description
      status
      createdAt
      updatedAt
    }
  }
  yieldOptimization {
    listOptimizations: listMultiassetOptimizations {
      optimizationId
      name
      description
      status
      createdAt
      updatedAt
    }
  }
  fundOptimization {
    listOptimizations {
      optimizationId
      name
      description
      status
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __usePortfolioOptimizationListQuery__
 *
 * To run a query within a React component, call `usePortfolioOptimizationListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioOptimizationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioOptimizationListQuery({
 *   variables: {
 *   },
 * });
 */
export function usePortfolioOptimizationListQuery(
  baseOptions?: Apollo.QueryHookOptions<IPortfolioOptimizationListQuery, IPortfolioOptimizationListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPortfolioOptimizationListQuery, IPortfolioOptimizationListQueryVariables>(
    PortfolioOptimizationListDocument,
    options
  );
}
export function usePortfolioOptimizationListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPortfolioOptimizationListQuery, IPortfolioOptimizationListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPortfolioOptimizationListQuery, IPortfolioOptimizationListQueryVariables>(
    PortfolioOptimizationListDocument,
    options
  );
}
export function usePortfolioOptimizationListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IPortfolioOptimizationListQuery,
    IPortfolioOptimizationListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IPortfolioOptimizationListQuery, IPortfolioOptimizationListQueryVariables>(
    PortfolioOptimizationListDocument,
    options
  );
}
export type PortfolioOptimizationListQueryHookResult = ReturnType<typeof usePortfolioOptimizationListQuery>;
export type PortfolioOptimizationListLazyQueryHookResult = ReturnType<typeof usePortfolioOptimizationListLazyQuery>;
export type PortfolioOptimizationListSuspenseQueryHookResult = ReturnType<
  typeof usePortfolioOptimizationListSuspenseQuery
>;
export type PortfolioOptimizationListQueryResult = Apollo.QueryResult<
  IPortfolioOptimizationListQuery,
  IPortfolioOptimizationListQueryVariables
>;
export const PortfolioOptimizationNameDocument = gql`
    query PortfolioOptimizationName($optimizationId: Int!) {
  fundOptimization {
    getOptimization(optimizationId: $optimizationId) {
      name
    }
  }
}
    `;

/**
 * __usePortfolioOptimizationNameQuery__
 *
 * To run a query within a React component, call `usePortfolioOptimizationNameQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioOptimizationNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioOptimizationNameQuery({
 *   variables: {
 *      optimizationId: // value for 'optimizationId'
 *   },
 * });
 */
export function usePortfolioOptimizationNameQuery(
  baseOptions: Apollo.QueryHookOptions<IPortfolioOptimizationNameQuery, IPortfolioOptimizationNameQueryVariables> &
    ({ variables: IPortfolioOptimizationNameQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPortfolioOptimizationNameQuery, IPortfolioOptimizationNameQueryVariables>(
    PortfolioOptimizationNameDocument,
    options
  );
}
export function usePortfolioOptimizationNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPortfolioOptimizationNameQuery, IPortfolioOptimizationNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPortfolioOptimizationNameQuery, IPortfolioOptimizationNameQueryVariables>(
    PortfolioOptimizationNameDocument,
    options
  );
}
export function usePortfolioOptimizationNameSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IPortfolioOptimizationNameQuery,
    IPortfolioOptimizationNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IPortfolioOptimizationNameQuery, IPortfolioOptimizationNameQueryVariables>(
    PortfolioOptimizationNameDocument,
    options
  );
}
export type PortfolioOptimizationNameQueryHookResult = ReturnType<typeof usePortfolioOptimizationNameQuery>;
export type PortfolioOptimizationNameLazyQueryHookResult = ReturnType<typeof usePortfolioOptimizationNameLazyQuery>;
export type PortfolioOptimizationNameSuspenseQueryHookResult = ReturnType<
  typeof usePortfolioOptimizationNameSuspenseQuery
>;
export type PortfolioOptimizationNameQueryResult = Apollo.QueryResult<
  IPortfolioOptimizationNameQuery,
  IPortfolioOptimizationNameQueryVariables
>;
export const YieldOptimizationNameDocument = gql`
    query YieldOptimizationName($optimizationId: Int!) {
  yieldOptimization {
    getMultiAssetOptimization(optimizationId: $optimizationId) {
      name
    }
  }
}
    `;

/**
 * __useYieldOptimizationNameQuery__
 *
 * To run a query within a React component, call `useYieldOptimizationNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useYieldOptimizationNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useYieldOptimizationNameQuery({
 *   variables: {
 *      optimizationId: // value for 'optimizationId'
 *   },
 * });
 */
export function useYieldOptimizationNameQuery(
  baseOptions: Apollo.QueryHookOptions<IYieldOptimizationNameQuery, IYieldOptimizationNameQueryVariables> &
    ({ variables: IYieldOptimizationNameQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IYieldOptimizationNameQuery, IYieldOptimizationNameQueryVariables>(
    YieldOptimizationNameDocument,
    options
  );
}
export function useYieldOptimizationNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IYieldOptimizationNameQuery, IYieldOptimizationNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IYieldOptimizationNameQuery, IYieldOptimizationNameQueryVariables>(
    YieldOptimizationNameDocument,
    options
  );
}
export function useYieldOptimizationNameSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IYieldOptimizationNameQuery, IYieldOptimizationNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IYieldOptimizationNameQuery, IYieldOptimizationNameQueryVariables>(
    YieldOptimizationNameDocument,
    options
  );
}
export type YieldOptimizationNameQueryHookResult = ReturnType<typeof useYieldOptimizationNameQuery>;
export type YieldOptimizationNameLazyQueryHookResult = ReturnType<typeof useYieldOptimizationNameLazyQuery>;
export type YieldOptimizationNameSuspenseQueryHookResult = ReturnType<typeof useYieldOptimizationNameSuspenseQuery>;
export type YieldOptimizationNameQueryResult = Apollo.QueryResult<
  IYieldOptimizationNameQuery,
  IYieldOptimizationNameQueryVariables
>;
export const AssetOptimizerResultDocument = gql`
    query AssetOptimizerResult($optimizationId: Int!) {
  optimization: portfolioOptimization {
    getOptimizationInput(optimizationId: $optimizationId) {
      constraints: assetConstraints {
        asset {
          ...AssetLabelFields
        }
        userExpectedReturn
        riskBudget
        maxLeverage
        expectedYield
        constraint {
          upperBound
          lowerBound
        }
      }
      portfolioAmount
      leverageType
      constraintFormulation
      returnMeasureName
      portfolioConstraints {
        primary {
          constrainedQuantity
          constraint {
            lowerBound
            upperBound
          }
          riskMetric {
            benchmark {
              id
              symbol
            }
            metricLabel
          }
        }
        secondary {
          constrainedQuantity
          constraint {
            lowerBound
            upperBound
          }
          riskMetric {
            benchmark {
              ...AssetLabelFields
            }
            metricLabel
          }
        }
      }
      givenPortfolio {
        asset {
          ...AssetLabelFields
        }
        cashWeight
      }
      groupConstraints {
        group {
          groupName
          clusterName
        }
        allocationConstraints {
          constrainedQuantity
          constraint {
            lowerBound
            upperBound
          }
        }
      }
    }
    getOptimization(optimizationId: $optimizationId) {
      optimizationId
      status
      name
      description
      objectives {
        objectiveType
        risk
        benchmark {
          ...AssetLabelFields
        }
      }
      givenPortfolioOutput {
        ...AssetSolutionFields
      }
      output: portfolioOutput {
        ...AssetSolutionFields
      }
    }
  }
}
    ${AssetLabelFieldsFragmentDoc}
${AssetSolutionFieldsFragmentDoc}`;

/**
 * __useAssetOptimizerResultQuery__
 *
 * To run a query within a React component, call `useAssetOptimizerResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetOptimizerResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetOptimizerResultQuery({
 *   variables: {
 *      optimizationId: // value for 'optimizationId'
 *   },
 * });
 */
export function useAssetOptimizerResultQuery(
  baseOptions: Apollo.QueryHookOptions<IAssetOptimizerResultQuery, IAssetOptimizerResultQueryVariables> &
    ({ variables: IAssetOptimizerResultQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IAssetOptimizerResultQuery, IAssetOptimizerResultQueryVariables>(
    AssetOptimizerResultDocument,
    options
  );
}
export function useAssetOptimizerResultLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAssetOptimizerResultQuery, IAssetOptimizerResultQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IAssetOptimizerResultQuery, IAssetOptimizerResultQueryVariables>(
    AssetOptimizerResultDocument,
    options
  );
}
export function useAssetOptimizerResultSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IAssetOptimizerResultQuery, IAssetOptimizerResultQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IAssetOptimizerResultQuery, IAssetOptimizerResultQueryVariables>(
    AssetOptimizerResultDocument,
    options
  );
}
export type AssetOptimizerResultQueryHookResult = ReturnType<typeof useAssetOptimizerResultQuery>;
export type AssetOptimizerResultLazyQueryHookResult = ReturnType<typeof useAssetOptimizerResultLazyQuery>;
export type AssetOptimizerResultSuspenseQueryHookResult = ReturnType<typeof useAssetOptimizerResultSuspenseQuery>;
export type AssetOptimizerResultQueryResult = Apollo.QueryResult<
  IAssetOptimizerResultQuery,
  IAssetOptimizerResultQueryVariables
>;
export const PortfolioOptimizerResultDocument = gql`
    query PortfolioOptimizerResult($optimizationId: Int!) {
  optimization: fundOptimization {
    getOptimizationInput(optimizationId: $optimizationId) {
      constraints: fundConstraints {
        fund {
          id
          name
          description
          type
          createdAt
          createdFrom {
            name
            periodUnit
            periodValue
            portfolioDefinition {
              name
            }
          }
        }
        userExpectedReturn
        riskBudget
        constraint {
          upperBound
          lowerBound
        }
      }
      portfolioAmount
      constraintFormulation
      returnMeasureName
      portfolioConstraints {
        primary {
          constrainedQuantity
          constraint {
            lowerBound
            upperBound
          }
          riskMetric {
            benchmark {
              symbol
            }
            metricLabel
          }
        }
        secondary {
          constrainedQuantity
          constraint {
            lowerBound
            upperBound
          }
          riskMetric {
            benchmark {
              ...AssetLabelFields
            }
            metricLabel
          }
        }
      }
      givenPortfolio {
        cashWeight
        fund {
          id
          name
          createdFrom {
            name
            periodValue
            periodUnit
            portfolioDefinition {
              name
            }
          }
        }
        cashWeight
      }
    }
    getOptimization(optimizationId: $optimizationId) {
      optimizationId
      status
      name
      description
      objectives {
        objectiveType
        risk
        benchmark {
          ...MainAssetFields
        }
      }
      givenPortfolioOutput {
        ...PortfolioSolutionFields
      }
      output: fundOutput {
        ...PortfolioSolutionFields
      }
    }
  }
}
    ${AssetLabelFieldsFragmentDoc}
${MainAssetFieldsFragmentDoc}
${PortfolioSolutionFieldsFragmentDoc}`;

/**
 * __usePortfolioOptimizerResultQuery__
 *
 * To run a query within a React component, call `usePortfolioOptimizerResultQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioOptimizerResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioOptimizerResultQuery({
 *   variables: {
 *      optimizationId: // value for 'optimizationId'
 *   },
 * });
 */
export function usePortfolioOptimizerResultQuery(
  baseOptions: Apollo.QueryHookOptions<IPortfolioOptimizerResultQuery, IPortfolioOptimizerResultQueryVariables> &
    ({ variables: IPortfolioOptimizerResultQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPortfolioOptimizerResultQuery, IPortfolioOptimizerResultQueryVariables>(
    PortfolioOptimizerResultDocument,
    options
  );
}
export function usePortfolioOptimizerResultLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPortfolioOptimizerResultQuery, IPortfolioOptimizerResultQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPortfolioOptimizerResultQuery, IPortfolioOptimizerResultQueryVariables>(
    PortfolioOptimizerResultDocument,
    options
  );
}
export function usePortfolioOptimizerResultSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IPortfolioOptimizerResultQuery, IPortfolioOptimizerResultQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IPortfolioOptimizerResultQuery, IPortfolioOptimizerResultQueryVariables>(
    PortfolioOptimizerResultDocument,
    options
  );
}
export type PortfolioOptimizerResultQueryHookResult = ReturnType<typeof usePortfolioOptimizerResultQuery>;
export type PortfolioOptimizerResultLazyQueryHookResult = ReturnType<typeof usePortfolioOptimizerResultLazyQuery>;
export type PortfolioOptimizerResultSuspenseQueryHookResult = ReturnType<
  typeof usePortfolioOptimizerResultSuspenseQuery
>;
export type PortfolioOptimizerResultQueryResult = Apollo.QueryResult<
  IPortfolioOptimizerResultQuery,
  IPortfolioOptimizerResultQueryVariables
>;
export const YieldOptimizerResultDocument = gql`
    query YieldOptimizerResult($optimizationId: Int!) {
  optimization: yieldOptimization {
    getOptimizationInput: getMultiassetOptimizationInput(
      optimizationId: $optimizationId
    ) {
      pools {
        constraint {
          lowerBound
          upperBound
        }
        poolInfo {
          poolName
          chain
          externalLink
          protocol {
            id
            name
            icon
          }
          category
          collateralAsset {
            ...AssetLabelFields
          }
          underlyingAsset {
            ...AssetLabelFields
          }
        }
      }
      name
      description
      amounts {
        amount
        asset {
          ...AssetLabelFields
        }
      }
    }
    getOptimization: getMultiAssetOptimization(optimizationId: $optimizationId) {
      optimizationId
      status
      name
      description
      optimizationMode
      output {
        groupPoolsByUnderlying
        globalApy
        yieldInUsd
        naiveAllocationApys {
          impactedApy
          yieldUsd
        }
        allocations {
          dollarValueOfAllocation
          percentageAllocationInUsd
          percentageAllocationInCollateral
          absoluteAllocationInCollateral
          yieldInUsd
          yieldInCollateral
          baseApy
          impactedApy
          pool {
            poolName
            chain
            externalLink
            protocol {
              id
              name
              icon
            }
            category
            collateralAsset {
              ...AssetLabelFields
            }
            underlyingAsset {
              ...AssetLabelFields
            }
            metrics {
              tvlUsd
              apyBase
              apy
              apyReward
            }
          }
        }
      }
    }
  }
}
    ${AssetLabelFieldsFragmentDoc}`;

/**
 * __useYieldOptimizerResultQuery__
 *
 * To run a query within a React component, call `useYieldOptimizerResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useYieldOptimizerResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useYieldOptimizerResultQuery({
 *   variables: {
 *      optimizationId: // value for 'optimizationId'
 *   },
 * });
 */
export function useYieldOptimizerResultQuery(
  baseOptions: Apollo.QueryHookOptions<IYieldOptimizerResultQuery, IYieldOptimizerResultQueryVariables> &
    ({ variables: IYieldOptimizerResultQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IYieldOptimizerResultQuery, IYieldOptimizerResultQueryVariables>(
    YieldOptimizerResultDocument,
    options
  );
}
export function useYieldOptimizerResultLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IYieldOptimizerResultQuery, IYieldOptimizerResultQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IYieldOptimizerResultQuery, IYieldOptimizerResultQueryVariables>(
    YieldOptimizerResultDocument,
    options
  );
}
export function useYieldOptimizerResultSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IYieldOptimizerResultQuery, IYieldOptimizerResultQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IYieldOptimizerResultQuery, IYieldOptimizerResultQueryVariables>(
    YieldOptimizerResultDocument,
    options
  );
}
export type YieldOptimizerResultQueryHookResult = ReturnType<typeof useYieldOptimizerResultQuery>;
export type YieldOptimizerResultLazyQueryHookResult = ReturnType<typeof useYieldOptimizerResultLazyQuery>;
export type YieldOptimizerResultSuspenseQueryHookResult = ReturnType<typeof useYieldOptimizerResultSuspenseQuery>;
export type YieldOptimizerResultQueryResult = Apollo.QueryResult<
  IYieldOptimizerResultQuery,
  IYieldOptimizerResultQueryVariables
>;
export const AssetOptimizerForecastDocument = gql`
    query AssetOptimizerForecast($assets: [UUID!]!) {
  portfolioOptimization {
    forecast(input: $assets) {
      asset {
        id
      }
      expectedReturn
      targetRiskBudget
    }
  }
}
    `;

/**
 * __useAssetOptimizerForecastQuery__
 *
 * To run a query within a React component, call `useAssetOptimizerForecastQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetOptimizerForecastQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetOptimizerForecastQuery({
 *   variables: {
 *      assets: // value for 'assets'
 *   },
 * });
 */
export function useAssetOptimizerForecastQuery(
  baseOptions: Apollo.QueryHookOptions<IAssetOptimizerForecastQuery, IAssetOptimizerForecastQueryVariables> &
    ({ variables: IAssetOptimizerForecastQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IAssetOptimizerForecastQuery, IAssetOptimizerForecastQueryVariables>(
    AssetOptimizerForecastDocument,
    options
  );
}
export function useAssetOptimizerForecastLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAssetOptimizerForecastQuery, IAssetOptimizerForecastQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IAssetOptimizerForecastQuery, IAssetOptimizerForecastQueryVariables>(
    AssetOptimizerForecastDocument,
    options
  );
}
export function useAssetOptimizerForecastSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IAssetOptimizerForecastQuery, IAssetOptimizerForecastQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IAssetOptimizerForecastQuery, IAssetOptimizerForecastQueryVariables>(
    AssetOptimizerForecastDocument,
    options
  );
}
export type AssetOptimizerForecastQueryHookResult = ReturnType<typeof useAssetOptimizerForecastQuery>;
export type AssetOptimizerForecastLazyQueryHookResult = ReturnType<typeof useAssetOptimizerForecastLazyQuery>;
export type AssetOptimizerForecastSuspenseQueryHookResult = ReturnType<typeof useAssetOptimizerForecastSuspenseQuery>;
export type AssetOptimizerForecastQueryResult = Apollo.QueryResult<
  IAssetOptimizerForecastQuery,
  IAssetOptimizerForecastQueryVariables
>;
export const AssetOptimizerMultifactorForecastDocument = gql`
    query AssetOptimizerMultifactorForecast($input: BiasFromMultiFactorInput!) {
  multifactor {
    computeBiasFromMultifactor(input: $input) {
      assetBias {
        score
        asset {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useAssetOptimizerMultifactorForecastQuery__
 *
 * To run a query within a React component, call `useAssetOptimizerMultifactorForecastQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetOptimizerMultifactorForecastQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetOptimizerMultifactorForecastQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssetOptimizerMultifactorForecastQuery(
  baseOptions: Apollo.QueryHookOptions<
    IAssetOptimizerMultifactorForecastQuery,
    IAssetOptimizerMultifactorForecastQueryVariables
  > &
    ({ variables: IAssetOptimizerMultifactorForecastQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IAssetOptimizerMultifactorForecastQuery, IAssetOptimizerMultifactorForecastQueryVariables>(
    AssetOptimizerMultifactorForecastDocument,
    options
  );
}
export function useAssetOptimizerMultifactorForecastLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IAssetOptimizerMultifactorForecastQuery,
    IAssetOptimizerMultifactorForecastQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IAssetOptimizerMultifactorForecastQuery, IAssetOptimizerMultifactorForecastQueryVariables>(
    AssetOptimizerMultifactorForecastDocument,
    options
  );
}
export function useAssetOptimizerMultifactorForecastSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IAssetOptimizerMultifactorForecastQuery,
    IAssetOptimizerMultifactorForecastQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    IAssetOptimizerMultifactorForecastQuery,
    IAssetOptimizerMultifactorForecastQueryVariables
  >(AssetOptimizerMultifactorForecastDocument, options);
}
export type AssetOptimizerMultifactorForecastQueryHookResult = ReturnType<
  typeof useAssetOptimizerMultifactorForecastQuery
>;
export type AssetOptimizerMultifactorForecastLazyQueryHookResult = ReturnType<
  typeof useAssetOptimizerMultifactorForecastLazyQuery
>;
export type AssetOptimizerMultifactorForecastSuspenseQueryHookResult = ReturnType<
  typeof useAssetOptimizerMultifactorForecastSuspenseQuery
>;
export type AssetOptimizerMultifactorForecastQueryResult = Apollo.QueryResult<
  IAssetOptimizerMultifactorForecastQuery,
  IAssetOptimizerMultifactorForecastQueryVariables
>;
export const AssetOptimizerWizardInputDocument = gql`
    query AssetOptimizerWizardInput {
  multifactor {
    getAllMultifactorsForUser {
      multifactor {
        id
        name
      }
      maxFactors
    }
  }
  assets {
    benchmark: feature(feature: BENCHMARK) {
      ...AssetLabelFields
    }
    feature(feature: PORTFOLIO_SIMULATOR) {
      ...AssetLabelFields
      derivativeDetails {
        exchange
        baseAsset {
          id
        }
      }
    }
    assetGroups {
      genieGroups {
        id
        assets {
          id
        }
        clusterName
        groupName
      }
      userGroups {
        id
        assets {
          id
        }
        clusterName
        groupName
      }
    }
  }
  portfolio {
    positions {
      positions {
        asset {
          ...MainAssetFields
        }
        spot {
          balance
        }
        derivative {
          notional
        }
      }
    }
  }
}
    ${AssetLabelFieldsFragmentDoc}
${MainAssetFieldsFragmentDoc}`;

/**
 * __useAssetOptimizerWizardInputQuery__
 *
 * To run a query within a React component, call `useAssetOptimizerWizardInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetOptimizerWizardInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetOptimizerWizardInputQuery({
 *   variables: {
 *   },
 * });
 */
export function useAssetOptimizerWizardInputQuery(
  baseOptions?: Apollo.QueryHookOptions<IAssetOptimizerWizardInputQuery, IAssetOptimizerWizardInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IAssetOptimizerWizardInputQuery, IAssetOptimizerWizardInputQueryVariables>(
    AssetOptimizerWizardInputDocument,
    options
  );
}
export function useAssetOptimizerWizardInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAssetOptimizerWizardInputQuery, IAssetOptimizerWizardInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IAssetOptimizerWizardInputQuery, IAssetOptimizerWizardInputQueryVariables>(
    AssetOptimizerWizardInputDocument,
    options
  );
}
export function useAssetOptimizerWizardInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IAssetOptimizerWizardInputQuery,
    IAssetOptimizerWizardInputQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IAssetOptimizerWizardInputQuery, IAssetOptimizerWizardInputQueryVariables>(
    AssetOptimizerWizardInputDocument,
    options
  );
}
export type AssetOptimizerWizardInputQueryHookResult = ReturnType<typeof useAssetOptimizerWizardInputQuery>;
export type AssetOptimizerWizardInputLazyQueryHookResult = ReturnType<typeof useAssetOptimizerWizardInputLazyQuery>;
export type AssetOptimizerWizardInputSuspenseQueryHookResult = ReturnType<
  typeof useAssetOptimizerWizardInputSuspenseQuery
>;
export type AssetOptimizerWizardInputQueryResult = Apollo.QueryResult<
  IAssetOptimizerWizardInputQuery,
  IAssetOptimizerWizardInputQueryVariables
>;
export const SubmitAssetOptimizationDocument = gql`
    mutation SubmitAssetOptimization($input: PortfolioOptimizationInput!) {
  optimization: SubmitOptimization(input: $input) {
    optimizationId
  }
}
    `;
export type ISubmitAssetOptimizationMutationFn = Apollo.MutationFunction<
  ISubmitAssetOptimizationMutation,
  ISubmitAssetOptimizationMutationVariables
>;

/**
 * __useSubmitAssetOptimizationMutation__
 *
 * To run a mutation, you first call `useSubmitAssetOptimizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitAssetOptimizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitAssetOptimizationMutation, { data, loading, error }] = useSubmitAssetOptimizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitAssetOptimizationMutation(
  baseOptions?: Apollo.MutationHookOptions<ISubmitAssetOptimizationMutation, ISubmitAssetOptimizationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ISubmitAssetOptimizationMutation, ISubmitAssetOptimizationMutationVariables>(
    SubmitAssetOptimizationDocument,
    options
  );
}
export type SubmitAssetOptimizationMutationHookResult = ReturnType<typeof useSubmitAssetOptimizationMutation>;
export type SubmitAssetOptimizationMutationResult = Apollo.MutationResult<ISubmitAssetOptimizationMutation>;
export type SubmitAssetOptimizationMutationOptions = Apollo.BaseMutationOptions<
  ISubmitAssetOptimizationMutation,
  ISubmitAssetOptimizationMutationVariables
>;
export const PortfolioOptimizerForecastDocument = gql`
    query PortfolioOptimizerForecast($portfolioIds: [UUID!]!) {
  fundOptimization {
    getForecastst(fundIds: $portfolioIds) {
      fundId {
        id
      }
      expectedReturn
      riskBudget
    }
  }
}
    `;

/**
 * __usePortfolioOptimizerForecastQuery__
 *
 * To run a query within a React component, call `usePortfolioOptimizerForecastQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioOptimizerForecastQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioOptimizerForecastQuery({
 *   variables: {
 *      portfolioIds: // value for 'portfolioIds'
 *   },
 * });
 */
export function usePortfolioOptimizerForecastQuery(
  baseOptions: Apollo.QueryHookOptions<IPortfolioOptimizerForecastQuery, IPortfolioOptimizerForecastQueryVariables> &
    ({ variables: IPortfolioOptimizerForecastQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPortfolioOptimizerForecastQuery, IPortfolioOptimizerForecastQueryVariables>(
    PortfolioOptimizerForecastDocument,
    options
  );
}
export function usePortfolioOptimizerForecastLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPortfolioOptimizerForecastQuery, IPortfolioOptimizerForecastQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPortfolioOptimizerForecastQuery, IPortfolioOptimizerForecastQueryVariables>(
    PortfolioOptimizerForecastDocument,
    options
  );
}
export function usePortfolioOptimizerForecastSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IPortfolioOptimizerForecastQuery,
    IPortfolioOptimizerForecastQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IPortfolioOptimizerForecastQuery, IPortfolioOptimizerForecastQueryVariables>(
    PortfolioOptimizerForecastDocument,
    options
  );
}
export type PortfolioOptimizerForecastQueryHookResult = ReturnType<typeof usePortfolioOptimizerForecastQuery>;
export type PortfolioOptimizerForecastLazyQueryHookResult = ReturnType<typeof usePortfolioOptimizerForecastLazyQuery>;
export type PortfolioOptimizerForecastSuspenseQueryHookResult = ReturnType<
  typeof usePortfolioOptimizerForecastSuspenseQuery
>;
export type PortfolioOptimizerForecastQueryResult = Apollo.QueryResult<
  IPortfolioOptimizerForecastQuery,
  IPortfolioOptimizerForecastQueryVariables
>;
export const PortfolioOptimizerWizardInputDocument = gql`
    query PortfolioOptimizerWizardInput {
  fundOptimization {
    getOptimizableFundsForUser {
      name
      createdFrom {
        id
        name
        start
        tradingFee
        periodUnit
        periodValue
        portfolioDefinition {
          id
          type
          name
        }
      }
      type
      id
      createdAt
      description
      genie
    }
  }
  assets {
    benchmark: feature(feature: BENCHMARK) {
      ...AssetLabelFields
    }
  }
}
    ${AssetLabelFieldsFragmentDoc}`;

/**
 * __usePortfolioOptimizerWizardInputQuery__
 *
 * To run a query within a React component, call `usePortfolioOptimizerWizardInputQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioOptimizerWizardInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioOptimizerWizardInputQuery({
 *   variables: {
 *   },
 * });
 */
export function usePortfolioOptimizerWizardInputQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IPortfolioOptimizerWizardInputQuery,
    IPortfolioOptimizerWizardInputQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPortfolioOptimizerWizardInputQuery, IPortfolioOptimizerWizardInputQueryVariables>(
    PortfolioOptimizerWizardInputDocument,
    options
  );
}
export function usePortfolioOptimizerWizardInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IPortfolioOptimizerWizardInputQuery,
    IPortfolioOptimizerWizardInputQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPortfolioOptimizerWizardInputQuery, IPortfolioOptimizerWizardInputQueryVariables>(
    PortfolioOptimizerWizardInputDocument,
    options
  );
}
export function usePortfolioOptimizerWizardInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IPortfolioOptimizerWizardInputQuery,
    IPortfolioOptimizerWizardInputQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IPortfolioOptimizerWizardInputQuery, IPortfolioOptimizerWizardInputQueryVariables>(
    PortfolioOptimizerWizardInputDocument,
    options
  );
}
export type PortfolioOptimizerWizardInputQueryHookResult = ReturnType<typeof usePortfolioOptimizerWizardInputQuery>;
export type PortfolioOptimizerWizardInputLazyQueryHookResult = ReturnType<
  typeof usePortfolioOptimizerWizardInputLazyQuery
>;
export type PortfolioOptimizerWizardInputSuspenseQueryHookResult = ReturnType<
  typeof usePortfolioOptimizerWizardInputSuspenseQuery
>;
export type PortfolioOptimizerWizardInputQueryResult = Apollo.QueryResult<
  IPortfolioOptimizerWizardInputQuery,
  IPortfolioOptimizerWizardInputQueryVariables
>;
export const SubmitPortfolioOptimizationDocument = gql`
    mutation SubmitPortfolioOptimization($input: FundOptimizationInput!) {
  optimization: submitFundOptimization(input: $input) {
    optimizationId
  }
}
    `;
export type ISubmitPortfolioOptimizationMutationFn = Apollo.MutationFunction<
  ISubmitPortfolioOptimizationMutation,
  ISubmitPortfolioOptimizationMutationVariables
>;

/**
 * __useSubmitPortfolioOptimizationMutation__
 *
 * To run a mutation, you first call `useSubmitPortfolioOptimizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitPortfolioOptimizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitPortfolioOptimizationMutation, { data, loading, error }] = useSubmitPortfolioOptimizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitPortfolioOptimizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISubmitPortfolioOptimizationMutation,
    ISubmitPortfolioOptimizationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ISubmitPortfolioOptimizationMutation, ISubmitPortfolioOptimizationMutationVariables>(
    SubmitPortfolioOptimizationDocument,
    options
  );
}
export type SubmitPortfolioOptimizationMutationHookResult = ReturnType<typeof useSubmitPortfolioOptimizationMutation>;
export type SubmitPortfolioOptimizationMutationResult = Apollo.MutationResult<ISubmitPortfolioOptimizationMutation>;
export type SubmitPortfolioOptimizationMutationOptions = Apollo.BaseMutationOptions<
  ISubmitPortfolioOptimizationMutation,
  ISubmitPortfolioOptimizationMutationVariables
>;
export const YieldOptimizerWizardInputDocument = gql`
    query YieldOptimizerWizardInput {
  yieldOptimization {
    listPools(input: {}) {
      id
      category
      chain
      collateralAsset {
        ...AssetLabelFields
      }
      underlyingAsset {
        ...AssetLabelFields
      }
      externalLink
      poolName
      protocol {
        id
        name
        icon
      }
      chain
      metrics {
        apy
        apyBase
        tvlUsd
      }
    }
  }
  portfolio {
    positions {
      positions {
        asset {
          id
        }
        spot {
          amount
        }
      }
    }
  }
}
    ${AssetLabelFieldsFragmentDoc}`;

/**
 * __useYieldOptimizerWizardInputQuery__
 *
 * To run a query within a React component, call `useYieldOptimizerWizardInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useYieldOptimizerWizardInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useYieldOptimizerWizardInputQuery({
 *   variables: {
 *   },
 * });
 */
export function useYieldOptimizerWizardInputQuery(
  baseOptions?: Apollo.QueryHookOptions<IYieldOptimizerWizardInputQuery, IYieldOptimizerWizardInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IYieldOptimizerWizardInputQuery, IYieldOptimizerWizardInputQueryVariables>(
    YieldOptimizerWizardInputDocument,
    options
  );
}
export function useYieldOptimizerWizardInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IYieldOptimizerWizardInputQuery, IYieldOptimizerWizardInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IYieldOptimizerWizardInputQuery, IYieldOptimizerWizardInputQueryVariables>(
    YieldOptimizerWizardInputDocument,
    options
  );
}
export function useYieldOptimizerWizardInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IYieldOptimizerWizardInputQuery,
    IYieldOptimizerWizardInputQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IYieldOptimizerWizardInputQuery, IYieldOptimizerWizardInputQueryVariables>(
    YieldOptimizerWizardInputDocument,
    options
  );
}
export type YieldOptimizerWizardInputQueryHookResult = ReturnType<typeof useYieldOptimizerWizardInputQuery>;
export type YieldOptimizerWizardInputLazyQueryHookResult = ReturnType<typeof useYieldOptimizerWizardInputLazyQuery>;
export type YieldOptimizerWizardInputSuspenseQueryHookResult = ReturnType<
  typeof useYieldOptimizerWizardInputSuspenseQuery
>;
export type YieldOptimizerWizardInputQueryResult = Apollo.QueryResult<
  IYieldOptimizerWizardInputQuery,
  IYieldOptimizerWizardInputQueryVariables
>;
export const SubmitYieldOptimizationDocument = gql`
    mutation SubmitYieldOptimization($input: MultiAssetYieldOptimizationInput!) {
  optimization: submitMultiAssetYieldOptimization(input: $input) {
    optimizationId
  }
}
    `;
export type ISubmitYieldOptimizationMutationFn = Apollo.MutationFunction<
  ISubmitYieldOptimizationMutation,
  ISubmitYieldOptimizationMutationVariables
>;

/**
 * __useSubmitYieldOptimizationMutation__
 *
 * To run a mutation, you first call `useSubmitYieldOptimizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitYieldOptimizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitYieldOptimizationMutation, { data, loading, error }] = useSubmitYieldOptimizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitYieldOptimizationMutation(
  baseOptions?: Apollo.MutationHookOptions<ISubmitYieldOptimizationMutation, ISubmitYieldOptimizationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ISubmitYieldOptimizationMutation, ISubmitYieldOptimizationMutationVariables>(
    SubmitYieldOptimizationDocument,
    options
  );
}
export type SubmitYieldOptimizationMutationHookResult = ReturnType<typeof useSubmitYieldOptimizationMutation>;
export type SubmitYieldOptimizationMutationResult = Apollo.MutationResult<ISubmitYieldOptimizationMutation>;
export type SubmitYieldOptimizationMutationOptions = Apollo.BaseMutationOptions<
  ISubmitYieldOptimizationMutation,
  ISubmitYieldOptimizationMutationVariables
>;
export const CounterpartyRiskReportDocument = gql`
    query CounterpartyRiskReport($subAccountAssetFilters: SubAccountAssetFilterInputType!) {
  portfolio {
    positions(filters: {subAccountAssetFilters: $subAccountAssetFilters}) {
      positions {
        subAccount {
          id
          name
          account {
            id
            name
            venue {
              label
            }
          }
        }
        asset {
          ...MainAssetFields
        }
        spot {
          price
          amount
          balance
        }
        derivative {
          amount
          notional
          side
          unrealizedPnl
          balance
        }
      }
    }
    subFunds {
      list {
        ...SubFundToSubAccountFields
      }
    }
  }
}
    ${MainAssetFieldsFragmentDoc}
${SubFundToSubAccountFieldsFragmentDoc}`;

/**
 * __useCounterpartyRiskReportQuery__
 *
 * To run a query within a React component, call `useCounterpartyRiskReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useCounterpartyRiskReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCounterpartyRiskReportQuery({
 *   variables: {
 *      subAccountAssetFilters: // value for 'subAccountAssetFilters'
 *   },
 * });
 */
export function useCounterpartyRiskReportQuery(
  baseOptions: Apollo.QueryHookOptions<ICounterpartyRiskReportQuery, ICounterpartyRiskReportQueryVariables> &
    ({ variables: ICounterpartyRiskReportQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ICounterpartyRiskReportQuery, ICounterpartyRiskReportQueryVariables>(
    CounterpartyRiskReportDocument,
    options
  );
}
export function useCounterpartyRiskReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ICounterpartyRiskReportQuery, ICounterpartyRiskReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ICounterpartyRiskReportQuery, ICounterpartyRiskReportQueryVariables>(
    CounterpartyRiskReportDocument,
    options
  );
}
export function useCounterpartyRiskReportSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ICounterpartyRiskReportQuery, ICounterpartyRiskReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ICounterpartyRiskReportQuery, ICounterpartyRiskReportQueryVariables>(
    CounterpartyRiskReportDocument,
    options
  );
}
export type CounterpartyRiskReportQueryHookResult = ReturnType<typeof useCounterpartyRiskReportQuery>;
export type CounterpartyRiskReportLazyQueryHookResult = ReturnType<typeof useCounterpartyRiskReportLazyQuery>;
export type CounterpartyRiskReportSuspenseQueryHookResult = ReturnType<typeof useCounterpartyRiskReportSuspenseQuery>;
export type CounterpartyRiskReportQueryResult = Apollo.QueryResult<
  ICounterpartyRiskReportQuery,
  ICounterpartyRiskReportQueryVariables
>;
export const PortfolioRiskMetricsContributionDocument = gql`
    query PortfolioRiskMetricsContribution($input: PortfolioRiskMetricsContributionInput!) {
  portfolio {
    metricContributions(input: $input) {
      portfolioDefinition {
        id
        name
      }
      label
      series {
        total
        values {
          value
          asset {
            id
            symbol
            type
            derivativeDetails {
              baseAsset {
                id
              }
            }
          }
        }
        parameters {
          strValue
          intValue
          name
          asset {
            symbol
            id
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePortfolioRiskMetricsContributionQuery__
 *
 * To run a query within a React component, call `usePortfolioRiskMetricsContributionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioRiskMetricsContributionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioRiskMetricsContributionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePortfolioRiskMetricsContributionQuery(
  baseOptions: Apollo.QueryHookOptions<
    IPortfolioRiskMetricsContributionQuery,
    IPortfolioRiskMetricsContributionQueryVariables
  > &
    ({ variables: IPortfolioRiskMetricsContributionQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPortfolioRiskMetricsContributionQuery, IPortfolioRiskMetricsContributionQueryVariables>(
    PortfolioRiskMetricsContributionDocument,
    options
  );
}
export function usePortfolioRiskMetricsContributionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IPortfolioRiskMetricsContributionQuery,
    IPortfolioRiskMetricsContributionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPortfolioRiskMetricsContributionQuery, IPortfolioRiskMetricsContributionQueryVariables>(
    PortfolioRiskMetricsContributionDocument,
    options
  );
}
export function usePortfolioRiskMetricsContributionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IPortfolioRiskMetricsContributionQuery,
    IPortfolioRiskMetricsContributionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    IPortfolioRiskMetricsContributionQuery,
    IPortfolioRiskMetricsContributionQueryVariables
  >(PortfolioRiskMetricsContributionDocument, options);
}
export type PortfolioRiskMetricsContributionQueryHookResult = ReturnType<
  typeof usePortfolioRiskMetricsContributionQuery
>;
export type PortfolioRiskMetricsContributionLazyQueryHookResult = ReturnType<
  typeof usePortfolioRiskMetricsContributionLazyQuery
>;
export type PortfolioRiskMetricsContributionSuspenseQueryHookResult = ReturnType<
  typeof usePortfolioRiskMetricsContributionSuspenseQuery
>;
export type PortfolioRiskMetricsContributionQueryResult = Apollo.QueryResult<
  IPortfolioRiskMetricsContributionQuery,
  IPortfolioRiskMetricsContributionQueryVariables
>;
export const RiskDashboardInputDocument = gql`
    query RiskDashboardInput($subAccountAssetFilters: SubAccountAssetFilterInputType!) {
  portfolio {
    accounts {
      name
      id
      venue {
        label
      }
      subAccounts {
        id
        name
      }
    }
    metricParameters(subAccountAssetFilter: $subAccountAssetFilters) {
      label
      parameters {
        name
        type
        minMax {
          default
          max
          min
        }
        options {
          id
          label
          value
        }
        visibleIf {
          parameter
          selectedOption
        }
        benchmarks {
          ...AssetLabelFields
        }
      }
    }
  }
  assets {
    assetGroups {
      genieGroups {
        clusterName
        groupName
        assets {
          id
        }
      }
    }
  }
}
    ${AssetLabelFieldsFragmentDoc}`;

/**
 * __useRiskDashboardInputQuery__
 *
 * To run a query within a React component, call `useRiskDashboardInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useRiskDashboardInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRiskDashboardInputQuery({
 *   variables: {
 *      subAccountAssetFilters: // value for 'subAccountAssetFilters'
 *   },
 * });
 */
export function useRiskDashboardInputQuery(
  baseOptions: Apollo.QueryHookOptions<IRiskDashboardInputQuery, IRiskDashboardInputQueryVariables> &
    ({ variables: IRiskDashboardInputQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IRiskDashboardInputQuery, IRiskDashboardInputQueryVariables>(
    RiskDashboardInputDocument,
    options
  );
}
export function useRiskDashboardInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IRiskDashboardInputQuery, IRiskDashboardInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IRiskDashboardInputQuery, IRiskDashboardInputQueryVariables>(
    RiskDashboardInputDocument,
    options
  );
}
export function useRiskDashboardInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IRiskDashboardInputQuery, IRiskDashboardInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IRiskDashboardInputQuery, IRiskDashboardInputQueryVariables>(
    RiskDashboardInputDocument,
    options
  );
}
export type RiskDashboardInputQueryHookResult = ReturnType<typeof useRiskDashboardInputQuery>;
export type RiskDashboardInputLazyQueryHookResult = ReturnType<typeof useRiskDashboardInputLazyQuery>;
export type RiskDashboardInputSuspenseQueryHookResult = ReturnType<typeof useRiskDashboardInputSuspenseQuery>;
export type RiskDashboardInputQueryResult = Apollo.QueryResult<
  IRiskDashboardInputQuery,
  IRiskDashboardInputQueryVariables
>;
export const AssetRiskMetricsDocument = gql`
    query AssetRiskMetrics($assetIds: [UUID!]!) {
  assets {
    details(filters: {assets: $assetIds}) {
      asset
      metrics
    }
  }
}
    `;

/**
 * __useAssetRiskMetricsQuery__
 *
 * To run a query within a React component, call `useAssetRiskMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetRiskMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetRiskMetricsQuery({
 *   variables: {
 *      assetIds: // value for 'assetIds'
 *   },
 * });
 */
export function useAssetRiskMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<IAssetRiskMetricsQuery, IAssetRiskMetricsQueryVariables> &
    ({ variables: IAssetRiskMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IAssetRiskMetricsQuery, IAssetRiskMetricsQueryVariables>(AssetRiskMetricsDocument, options);
}
export function useAssetRiskMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAssetRiskMetricsQuery, IAssetRiskMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IAssetRiskMetricsQuery, IAssetRiskMetricsQueryVariables>(
    AssetRiskMetricsDocument,
    options
  );
}
export function useAssetRiskMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IAssetRiskMetricsQuery, IAssetRiskMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IAssetRiskMetricsQuery, IAssetRiskMetricsQueryVariables>(
    AssetRiskMetricsDocument,
    options
  );
}
export type AssetRiskMetricsQueryHookResult = ReturnType<typeof useAssetRiskMetricsQuery>;
export type AssetRiskMetricsLazyQueryHookResult = ReturnType<typeof useAssetRiskMetricsLazyQuery>;
export type AssetRiskMetricsSuspenseQueryHookResult = ReturnType<typeof useAssetRiskMetricsSuspenseQuery>;
export type AssetRiskMetricsQueryResult = Apollo.QueryResult<IAssetRiskMetricsQuery, IAssetRiskMetricsQueryVariables>;
export const AssetRiskMetricsReportAssetsAndParametersDocument = gql`
    query AssetRiskMetricsReportAssetsAndParameters($subAccountAssetFilters: SubAccountAssetFilterInputType!) {
  portfolio {
    positions(filters: {subAccountAssetFilters: $subAccountAssetFilters}) {
      positions {
        asset {
          ...AssetLabelFields
        }
        spot {
          balance
        }
        derivative {
          balance
        }
      }
    }
    metricParameters(subAccountAssetFilter: $subAccountAssetFilters) {
      label
      parameters {
        name
        type
        minMax {
          max
          min
        }
        options {
          value
        }
        benchmarks {
          id
          symbol
        }
      }
    }
  }
  assets {
    feature(feature: KNOWN_ASSETS) {
      id
      symbol
    }
  }
}
    ${AssetLabelFieldsFragmentDoc}`;

/**
 * __useAssetRiskMetricsReportAssetsAndParametersQuery__
 *
 * To run a query within a React component, call `useAssetRiskMetricsReportAssetsAndParametersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetRiskMetricsReportAssetsAndParametersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetRiskMetricsReportAssetsAndParametersQuery({
 *   variables: {
 *      subAccountAssetFilters: // value for 'subAccountAssetFilters'
 *   },
 * });
 */
export function useAssetRiskMetricsReportAssetsAndParametersQuery(
  baseOptions: Apollo.QueryHookOptions<
    IAssetRiskMetricsReportAssetsAndParametersQuery,
    IAssetRiskMetricsReportAssetsAndParametersQueryVariables
  > &
    ({ variables: IAssetRiskMetricsReportAssetsAndParametersQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IAssetRiskMetricsReportAssetsAndParametersQuery,
    IAssetRiskMetricsReportAssetsAndParametersQueryVariables
  >(AssetRiskMetricsReportAssetsAndParametersDocument, options);
}
export function useAssetRiskMetricsReportAssetsAndParametersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IAssetRiskMetricsReportAssetsAndParametersQuery,
    IAssetRiskMetricsReportAssetsAndParametersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IAssetRiskMetricsReportAssetsAndParametersQuery,
    IAssetRiskMetricsReportAssetsAndParametersQueryVariables
  >(AssetRiskMetricsReportAssetsAndParametersDocument, options);
}
export function useAssetRiskMetricsReportAssetsAndParametersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IAssetRiskMetricsReportAssetsAndParametersQuery,
    IAssetRiskMetricsReportAssetsAndParametersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    IAssetRiskMetricsReportAssetsAndParametersQuery,
    IAssetRiskMetricsReportAssetsAndParametersQueryVariables
  >(AssetRiskMetricsReportAssetsAndParametersDocument, options);
}
export type AssetRiskMetricsReportAssetsAndParametersQueryHookResult = ReturnType<
  typeof useAssetRiskMetricsReportAssetsAndParametersQuery
>;
export type AssetRiskMetricsReportAssetsAndParametersLazyQueryHookResult = ReturnType<
  typeof useAssetRiskMetricsReportAssetsAndParametersLazyQuery
>;
export type AssetRiskMetricsReportAssetsAndParametersSuspenseQueryHookResult = ReturnType<
  typeof useAssetRiskMetricsReportAssetsAndParametersSuspenseQuery
>;
export type AssetRiskMetricsReportAssetsAndParametersQueryResult = Apollo.QueryResult<
  IAssetRiskMetricsReportAssetsAndParametersQuery,
  IAssetRiskMetricsReportAssetsAndParametersQueryVariables
>;
export const StrategyDocument = gql`
    query Strategy($label: LabelType!) {
  strategy {
    strategy(label: $label) {
      label
      name
      description
    }
  }
}
    `;

/**
 * __useStrategyQuery__
 *
 * To run a query within a React component, call `useStrategyQuery` and pass it any options that fit your needs.
 * When your component renders, `useStrategyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStrategyQuery({
 *   variables: {
 *      label: // value for 'label'
 *   },
 * });
 */
export function useStrategyQuery(
  baseOptions: Apollo.QueryHookOptions<IStrategyQuery, IStrategyQueryVariables> &
    ({ variables: IStrategyQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IStrategyQuery, IStrategyQueryVariables>(StrategyDocument, options);
}
export function useStrategyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IStrategyQuery, IStrategyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IStrategyQuery, IStrategyQueryVariables>(StrategyDocument, options);
}
export function useStrategySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IStrategyQuery, IStrategyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IStrategyQuery, IStrategyQueryVariables>(StrategyDocument, options);
}
export type StrategyQueryHookResult = ReturnType<typeof useStrategyQuery>;
export type StrategyLazyQueryHookResult = ReturnType<typeof useStrategyLazyQuery>;
export type StrategySuspenseQueryHookResult = ReturnType<typeof useStrategySuspenseQuery>;
export type StrategyQueryResult = Apollo.QueryResult<IStrategyQuery, IStrategyQueryVariables>;
export const StrategyAllocationDocument = gql`
    query StrategyAllocation($label: LabelType!) {
  strategy {
    assetAllocations(label: $label) {
      time
      assetAllocations {
        asset {
          id
          name
          symbol
        }
        value
      }
    }
  }
}
    `;

/**
 * __useStrategyAllocationQuery__
 *
 * To run a query within a React component, call `useStrategyAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useStrategyAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStrategyAllocationQuery({
 *   variables: {
 *      label: // value for 'label'
 *   },
 * });
 */
export function useStrategyAllocationQuery(
  baseOptions: Apollo.QueryHookOptions<IStrategyAllocationQuery, IStrategyAllocationQueryVariables> &
    ({ variables: IStrategyAllocationQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IStrategyAllocationQuery, IStrategyAllocationQueryVariables>(
    StrategyAllocationDocument,
    options
  );
}
export function useStrategyAllocationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IStrategyAllocationQuery, IStrategyAllocationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IStrategyAllocationQuery, IStrategyAllocationQueryVariables>(
    StrategyAllocationDocument,
    options
  );
}
export function useStrategyAllocationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IStrategyAllocationQuery, IStrategyAllocationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IStrategyAllocationQuery, IStrategyAllocationQueryVariables>(
    StrategyAllocationDocument,
    options
  );
}
export type StrategyAllocationQueryHookResult = ReturnType<typeof useStrategyAllocationQuery>;
export type StrategyAllocationLazyQueryHookResult = ReturnType<typeof useStrategyAllocationLazyQuery>;
export type StrategyAllocationSuspenseQueryHookResult = ReturnType<typeof useStrategyAllocationSuspenseQuery>;
export type StrategyAllocationQueryResult = Apollo.QueryResult<
  IStrategyAllocationQuery,
  IStrategyAllocationQueryVariables
>;
export const StrategyListDocument = gql`
    query StrategyList {
  strategy {
    list {
      label
      name
      description
    }
  }
}
    `;

/**
 * __useStrategyListQuery__
 *
 * To run a query within a React component, call `useStrategyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStrategyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStrategyListQuery({
 *   variables: {
 *   },
 * });
 */
export function useStrategyListQuery(
  baseOptions?: Apollo.QueryHookOptions<IStrategyListQuery, IStrategyListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IStrategyListQuery, IStrategyListQueryVariables>(StrategyListDocument, options);
}
export function useStrategyListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IStrategyListQuery, IStrategyListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IStrategyListQuery, IStrategyListQueryVariables>(StrategyListDocument, options);
}
export function useStrategyListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IStrategyListQuery, IStrategyListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IStrategyListQuery, IStrategyListQueryVariables>(StrategyListDocument, options);
}
export type StrategyListQueryHookResult = ReturnType<typeof useStrategyListQuery>;
export type StrategyListLazyQueryHookResult = ReturnType<typeof useStrategyListLazyQuery>;
export type StrategyListSuspenseQueryHookResult = ReturnType<typeof useStrategyListSuspenseQuery>;
export type StrategyListQueryResult = Apollo.QueryResult<IStrategyListQuery, IStrategyListQueryVariables>;
export const StrategyMonthlyReturnsDocument = gql`
    query StrategyMonthlyReturns($label: LabelType!) {
  strategy {
    monthlyReturns(label: $label) {
      time
      value
    }
  }
}
    `;

/**
 * __useStrategyMonthlyReturnsQuery__
 *
 * To run a query within a React component, call `useStrategyMonthlyReturnsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStrategyMonthlyReturnsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStrategyMonthlyReturnsQuery({
 *   variables: {
 *      label: // value for 'label'
 *   },
 * });
 */
export function useStrategyMonthlyReturnsQuery(
  baseOptions: Apollo.QueryHookOptions<IStrategyMonthlyReturnsQuery, IStrategyMonthlyReturnsQueryVariables> &
    ({ variables: IStrategyMonthlyReturnsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IStrategyMonthlyReturnsQuery, IStrategyMonthlyReturnsQueryVariables>(
    StrategyMonthlyReturnsDocument,
    options
  );
}
export function useStrategyMonthlyReturnsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IStrategyMonthlyReturnsQuery, IStrategyMonthlyReturnsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IStrategyMonthlyReturnsQuery, IStrategyMonthlyReturnsQueryVariables>(
    StrategyMonthlyReturnsDocument,
    options
  );
}
export function useStrategyMonthlyReturnsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IStrategyMonthlyReturnsQuery, IStrategyMonthlyReturnsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IStrategyMonthlyReturnsQuery, IStrategyMonthlyReturnsQueryVariables>(
    StrategyMonthlyReturnsDocument,
    options
  );
}
export type StrategyMonthlyReturnsQueryHookResult = ReturnType<typeof useStrategyMonthlyReturnsQuery>;
export type StrategyMonthlyReturnsLazyQueryHookResult = ReturnType<typeof useStrategyMonthlyReturnsLazyQuery>;
export type StrategyMonthlyReturnsSuspenseQueryHookResult = ReturnType<typeof useStrategyMonthlyReturnsSuspenseQuery>;
export type StrategyMonthlyReturnsQueryResult = Apollo.QueryResult<
  IStrategyMonthlyReturnsQuery,
  IStrategyMonthlyReturnsQueryVariables
>;
export const StrategyPerformanceDocument = gql`
    query StrategyPerformance($label: LabelType!) {
  strategy {
    strategyPerformanceMetrics(label: $label) {
      name
      value
    }
    cumulativeReturns(label: $label) {
      backtest {
        time
        value
      }
      live {
        time
        value
      }
    }
  }
}
    `;

/**
 * __useStrategyPerformanceQuery__
 *
 * To run a query within a React component, call `useStrategyPerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useStrategyPerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStrategyPerformanceQuery({
 *   variables: {
 *      label: // value for 'label'
 *   },
 * });
 */
export function useStrategyPerformanceQuery(
  baseOptions: Apollo.QueryHookOptions<IStrategyPerformanceQuery, IStrategyPerformanceQueryVariables> &
    ({ variables: IStrategyPerformanceQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IStrategyPerformanceQuery, IStrategyPerformanceQueryVariables>(
    StrategyPerformanceDocument,
    options
  );
}
export function useStrategyPerformanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IStrategyPerformanceQuery, IStrategyPerformanceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IStrategyPerformanceQuery, IStrategyPerformanceQueryVariables>(
    StrategyPerformanceDocument,
    options
  );
}
export function useStrategyPerformanceSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IStrategyPerformanceQuery, IStrategyPerformanceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IStrategyPerformanceQuery, IStrategyPerformanceQueryVariables>(
    StrategyPerformanceDocument,
    options
  );
}
export type StrategyPerformanceQueryHookResult = ReturnType<typeof useStrategyPerformanceQuery>;
export type StrategyPerformanceLazyQueryHookResult = ReturnType<typeof useStrategyPerformanceLazyQuery>;
export type StrategyPerformanceSuspenseQueryHookResult = ReturnType<typeof useStrategyPerformanceSuspenseQuery>;
export type StrategyPerformanceQueryResult = Apollo.QueryResult<
  IStrategyPerformanceQuery,
  IStrategyPerformanceQueryVariables
>;
export const StrategyPositionStatisticsDocument = gql`
    query StrategyPositionStatistics($label: LabelType!) {
  strategy {
    positionStatistics(label: $label) {
      name
      value
    }
  }
}
    `;

/**
 * __useStrategyPositionStatisticsQuery__
 *
 * To run a query within a React component, call `useStrategyPositionStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStrategyPositionStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStrategyPositionStatisticsQuery({
 *   variables: {
 *      label: // value for 'label'
 *   },
 * });
 */
export function useStrategyPositionStatisticsQuery(
  baseOptions: Apollo.QueryHookOptions<IStrategyPositionStatisticsQuery, IStrategyPositionStatisticsQueryVariables> &
    ({ variables: IStrategyPositionStatisticsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IStrategyPositionStatisticsQuery, IStrategyPositionStatisticsQueryVariables>(
    StrategyPositionStatisticsDocument,
    options
  );
}
export function useStrategyPositionStatisticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IStrategyPositionStatisticsQuery, IStrategyPositionStatisticsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IStrategyPositionStatisticsQuery, IStrategyPositionStatisticsQueryVariables>(
    StrategyPositionStatisticsDocument,
    options
  );
}
export function useStrategyPositionStatisticsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IStrategyPositionStatisticsQuery,
    IStrategyPositionStatisticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IStrategyPositionStatisticsQuery, IStrategyPositionStatisticsQueryVariables>(
    StrategyPositionStatisticsDocument,
    options
  );
}
export type StrategyPositionStatisticsQueryHookResult = ReturnType<typeof useStrategyPositionStatisticsQuery>;
export type StrategyPositionStatisticsLazyQueryHookResult = ReturnType<typeof useStrategyPositionStatisticsLazyQuery>;
export type StrategyPositionStatisticsSuspenseQueryHookResult = ReturnType<
  typeof useStrategyPositionStatisticsSuspenseQuery
>;
export type StrategyPositionStatisticsQueryResult = Apollo.QueryResult<
  IStrategyPositionStatisticsQuery,
  IStrategyPositionStatisticsQueryVariables
>;
export const StrategyPositionsDocument = gql`
    query StrategyPositions($label: LabelType!) {
  strategy {
    positionList(label: $label) {
      exchange
      openAt
      closedAt
      side
      status
      base {
        ...MainAssetFields
      }
      quote {
        ...MainAssetFields
      }
      amount
      openPrice
      closePrice
      fee {
        asset {
          ...MainAssetFields
        }
        amount
      }
      netReturn
      netQuoteProfit
    }
  }
}
    ${MainAssetFieldsFragmentDoc}`;

/**
 * __useStrategyPositionsQuery__
 *
 * To run a query within a React component, call `useStrategyPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStrategyPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStrategyPositionsQuery({
 *   variables: {
 *      label: // value for 'label'
 *   },
 * });
 */
export function useStrategyPositionsQuery(
  baseOptions: Apollo.QueryHookOptions<IStrategyPositionsQuery, IStrategyPositionsQueryVariables> &
    ({ variables: IStrategyPositionsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IStrategyPositionsQuery, IStrategyPositionsQueryVariables>(StrategyPositionsDocument, options);
}
export function useStrategyPositionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IStrategyPositionsQuery, IStrategyPositionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IStrategyPositionsQuery, IStrategyPositionsQueryVariables>(
    StrategyPositionsDocument,
    options
  );
}
export function useStrategyPositionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IStrategyPositionsQuery, IStrategyPositionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IStrategyPositionsQuery, IStrategyPositionsQueryVariables>(
    StrategyPositionsDocument,
    options
  );
}
export type StrategyPositionsQueryHookResult = ReturnType<typeof useStrategyPositionsQuery>;
export type StrategyPositionsLazyQueryHookResult = ReturnType<typeof useStrategyPositionsLazyQuery>;
export type StrategyPositionsSuspenseQueryHookResult = ReturnType<typeof useStrategyPositionsSuspenseQuery>;
export type StrategyPositionsQueryResult = Apollo.QueryResult<
  IStrategyPositionsQuery,
  IStrategyPositionsQueryVariables
>;
export const StrategyRiskMetricsDocument = gql`
    query StrategyRiskMetrics($label: LabelType!) {
  strategy {
    strategyRiskMetrics(label: $label) {
      name
      value
    }
  }
}
    `;

/**
 * __useStrategyRiskMetricsQuery__
 *
 * To run a query within a React component, call `useStrategyRiskMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStrategyRiskMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStrategyRiskMetricsQuery({
 *   variables: {
 *      label: // value for 'label'
 *   },
 * });
 */
export function useStrategyRiskMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<IStrategyRiskMetricsQuery, IStrategyRiskMetricsQueryVariables> &
    ({ variables: IStrategyRiskMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IStrategyRiskMetricsQuery, IStrategyRiskMetricsQueryVariables>(
    StrategyRiskMetricsDocument,
    options
  );
}
export function useStrategyRiskMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IStrategyRiskMetricsQuery, IStrategyRiskMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IStrategyRiskMetricsQuery, IStrategyRiskMetricsQueryVariables>(
    StrategyRiskMetricsDocument,
    options
  );
}
export function useStrategyRiskMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IStrategyRiskMetricsQuery, IStrategyRiskMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IStrategyRiskMetricsQuery, IStrategyRiskMetricsQueryVariables>(
    StrategyRiskMetricsDocument,
    options
  );
}
export type StrategyRiskMetricsQueryHookResult = ReturnType<typeof useStrategyRiskMetricsQuery>;
export type StrategyRiskMetricsLazyQueryHookResult = ReturnType<typeof useStrategyRiskMetricsLazyQuery>;
export type StrategyRiskMetricsSuspenseQueryHookResult = ReturnType<typeof useStrategyRiskMetricsSuspenseQuery>;
export type StrategyRiskMetricsQueryResult = Apollo.QueryResult<
  IStrategyRiskMetricsQuery,
  IStrategyRiskMetricsQueryVariables
>;
export const StrategyRollingMetricsDocument = gql`
    query StrategyRollingMetrics($label: LabelType!) {
  strategy {
    strategyRiskMetricsRolling(label: $label) {
      label
      timeSeries {
        time
        value
      }
      mean
    }
  }
}
    `;

/**
 * __useStrategyRollingMetricsQuery__
 *
 * To run a query within a React component, call `useStrategyRollingMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStrategyRollingMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStrategyRollingMetricsQuery({
 *   variables: {
 *      label: // value for 'label'
 *   },
 * });
 */
export function useStrategyRollingMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<IStrategyRollingMetricsQuery, IStrategyRollingMetricsQueryVariables> &
    ({ variables: IStrategyRollingMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IStrategyRollingMetricsQuery, IStrategyRollingMetricsQueryVariables>(
    StrategyRollingMetricsDocument,
    options
  );
}
export function useStrategyRollingMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IStrategyRollingMetricsQuery, IStrategyRollingMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IStrategyRollingMetricsQuery, IStrategyRollingMetricsQueryVariables>(
    StrategyRollingMetricsDocument,
    options
  );
}
export function useStrategyRollingMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IStrategyRollingMetricsQuery, IStrategyRollingMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IStrategyRollingMetricsQuery, IStrategyRollingMetricsQueryVariables>(
    StrategyRollingMetricsDocument,
    options
  );
}
export type StrategyRollingMetricsQueryHookResult = ReturnType<typeof useStrategyRollingMetricsQuery>;
export type StrategyRollingMetricsLazyQueryHookResult = ReturnType<typeof useStrategyRollingMetricsLazyQuery>;
export type StrategyRollingMetricsSuspenseQueryHookResult = ReturnType<typeof useStrategyRollingMetricsSuspenseQuery>;
export type StrategyRollingMetricsQueryResult = Apollo.QueryResult<
  IStrategyRollingMetricsQuery,
  IStrategyRollingMetricsQueryVariables
>;
export const PerformStressTestDocument = gql`
    query PerformStressTest($input: StressTestInputType!) {
  stressTest {
    stressTest(input: $input) {
      keyMeasures {
        assetCount
        measures {
          asset {
            symbol
          }
          name
          value
          netChange
        }
      }
      logicResult {
        assetResults {
          asset {
            ...AssetLabelFields
          }
          unitUsdValue
          meanEquityChange
          meanReturn
        }
        portfolioReturns
        portfolioStatistics {
          value
          name
        }
      }
    }
  }
}
    ${AssetLabelFieldsFragmentDoc}`;

/**
 * __usePerformStressTestQuery__
 *
 * To run a query within a React component, call `usePerformStressTestQuery` and pass it any options that fit your needs.
 * When your component renders, `usePerformStressTestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePerformStressTestQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePerformStressTestQuery(
  baseOptions: Apollo.QueryHookOptions<IPerformStressTestQuery, IPerformStressTestQueryVariables> &
    ({ variables: IPerformStressTestQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPerformStressTestQuery, IPerformStressTestQueryVariables>(PerformStressTestDocument, options);
}
export function usePerformStressTestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPerformStressTestQuery, IPerformStressTestQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPerformStressTestQuery, IPerformStressTestQueryVariables>(
    PerformStressTestDocument,
    options
  );
}
export function usePerformStressTestSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IPerformStressTestQuery, IPerformStressTestQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IPerformStressTestQuery, IPerformStressTestQueryVariables>(
    PerformStressTestDocument,
    options
  );
}
export type PerformStressTestQueryHookResult = ReturnType<typeof usePerformStressTestQuery>;
export type PerformStressTestLazyQueryHookResult = ReturnType<typeof usePerformStressTestLazyQuery>;
export type PerformStressTestSuspenseQueryHookResult = ReturnType<typeof usePerformStressTestSuspenseQuery>;
export type PerformStressTestQueryResult = Apollo.QueryResult<
  IPerformStressTestQuery,
  IPerformStressTestQueryVariables
>;
export const StressTestSimulatorInputDocument = gql`
    query StressTestSimulatorInput {
  assets {
    feature(feature: STRESS_TEST) {
      ...MainAssetFields
    }
    assetGroups {
      genieGroups {
        groupName
        clusterName
        assets {
          ...MainAssetFields
        }
      }
    }
  }
  portfolio {
    positions {
      positions {
        asset {
          ...AssetLabelFields
        }
        spot {
          balance
        }
      }
    }
  }
  stressTest {
    scenarioLibrary {
      name
      description
      events {
        asset {
          ...AssetLabelFields
        }
        netReturn
      }
    }
  }
}
    ${MainAssetFieldsFragmentDoc}
${AssetLabelFieldsFragmentDoc}`;

/**
 * __useStressTestSimulatorInputQuery__
 *
 * To run a query within a React component, call `useStressTestSimulatorInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useStressTestSimulatorInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStressTestSimulatorInputQuery({
 *   variables: {
 *   },
 * });
 */
export function useStressTestSimulatorInputQuery(
  baseOptions?: Apollo.QueryHookOptions<IStressTestSimulatorInputQuery, IStressTestSimulatorInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IStressTestSimulatorInputQuery, IStressTestSimulatorInputQueryVariables>(
    StressTestSimulatorInputDocument,
    options
  );
}
export function useStressTestSimulatorInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IStressTestSimulatorInputQuery, IStressTestSimulatorInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IStressTestSimulatorInputQuery, IStressTestSimulatorInputQueryVariables>(
    StressTestSimulatorInputDocument,
    options
  );
}
export function useStressTestSimulatorInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IStressTestSimulatorInputQuery, IStressTestSimulatorInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IStressTestSimulatorInputQuery, IStressTestSimulatorInputQueryVariables>(
    StressTestSimulatorInputDocument,
    options
  );
}
export type StressTestSimulatorInputQueryHookResult = ReturnType<typeof useStressTestSimulatorInputQuery>;
export type StressTestSimulatorInputLazyQueryHookResult = ReturnType<typeof useStressTestSimulatorInputLazyQuery>;
export type StressTestSimulatorInputSuspenseQueryHookResult = ReturnType<
  typeof useStressTestSimulatorInputSuspenseQuery
>;
export type StressTestSimulatorInputQueryResult = Apollo.QueryResult<
  IStressTestSimulatorInputQuery,
  IStressTestSimulatorInputQueryVariables
>;
export const UnifiedStressTestDocument = gql`
    query UnifiedStressTest($input: ScenAnalysisInputType!) {
  scenarioAnalysisV2 {
    scenarioAnalysisV2(input: $input) {
      externalId
      referenceEvaluation {
        greeks {
          vega
          theta
          rho
          gamma
          delta
        }
        fairValue
        marketValue
      }
      simulatedResults {
        scenario {
          benchmark {
            price {
              mode
              reference
              shock
            }
            volatility {
              shock
              reference
              mode
            }
          }
          riskFree {
            mode
            reference
            shock
          }
        }
        portfolioEvaluationV2 {
          marketValue
          fairValue
          greeks {
            delta
            gamma
            rho
            theta
            vega
          }
        }
      }
    }
  }
}
    `;

/**
 * __useUnifiedStressTestQuery__
 *
 * To run a query within a React component, call `useUnifiedStressTestQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnifiedStressTestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnifiedStressTestQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnifiedStressTestQuery(
  baseOptions: Apollo.QueryHookOptions<IUnifiedStressTestQuery, IUnifiedStressTestQueryVariables> &
    ({ variables: IUnifiedStressTestQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IUnifiedStressTestQuery, IUnifiedStressTestQueryVariables>(UnifiedStressTestDocument, options);
}
export function useUnifiedStressTestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IUnifiedStressTestQuery, IUnifiedStressTestQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IUnifiedStressTestQuery, IUnifiedStressTestQueryVariables>(
    UnifiedStressTestDocument,
    options
  );
}
export function useUnifiedStressTestSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IUnifiedStressTestQuery, IUnifiedStressTestQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IUnifiedStressTestQuery, IUnifiedStressTestQueryVariables>(
    UnifiedStressTestDocument,
    options
  );
}
export type UnifiedStressTestQueryHookResult = ReturnType<typeof useUnifiedStressTestQuery>;
export type UnifiedStressTestLazyQueryHookResult = ReturnType<typeof useUnifiedStressTestLazyQuery>;
export type UnifiedStressTestSuspenseQueryHookResult = ReturnType<typeof useUnifiedStressTestSuspenseQuery>;
export type UnifiedStressTestQueryResult = Apollo.QueryResult<
  IUnifiedStressTestQuery,
  IUnifiedStressTestQueryVariables
>;
export const UnifiedStressTestInputDocument = gql`
    query UnifiedStressTestInput {
  assets {
    feature(feature: BENCHMARK) {
      ...AssetLabelFields
    }
  }
  portfolio {
    definitions {
      genie
      type
      name
      latestAssetComposition {
        asset {
          ...AssetLabelFields
          derivativeDetails {
            derivativeType
            exchange
          }
        }
        amount
        weight
        entryPrice
      }
    }
  }
}
    ${AssetLabelFieldsFragmentDoc}`;

/**
 * __useUnifiedStressTestInputQuery__
 *
 * To run a query within a React component, call `useUnifiedStressTestInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnifiedStressTestInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnifiedStressTestInputQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnifiedStressTestInputQuery(
  baseOptions?: Apollo.QueryHookOptions<IUnifiedStressTestInputQuery, IUnifiedStressTestInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IUnifiedStressTestInputQuery, IUnifiedStressTestInputQueryVariables>(
    UnifiedStressTestInputDocument,
    options
  );
}
export function useUnifiedStressTestInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IUnifiedStressTestInputQuery, IUnifiedStressTestInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IUnifiedStressTestInputQuery, IUnifiedStressTestInputQueryVariables>(
    UnifiedStressTestInputDocument,
    options
  );
}
export function useUnifiedStressTestInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IUnifiedStressTestInputQuery, IUnifiedStressTestInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IUnifiedStressTestInputQuery, IUnifiedStressTestInputQueryVariables>(
    UnifiedStressTestInputDocument,
    options
  );
}
export type UnifiedStressTestInputQueryHookResult = ReturnType<typeof useUnifiedStressTestInputQuery>;
export type UnifiedStressTestInputLazyQueryHookResult = ReturnType<typeof useUnifiedStressTestInputLazyQuery>;
export type UnifiedStressTestInputSuspenseQueryHookResult = ReturnType<typeof useUnifiedStressTestInputSuspenseQuery>;
export type UnifiedStressTestInputQueryResult = Apollo.QueryResult<
  IUnifiedStressTestInputQuery,
  IUnifiedStressTestInputQueryVariables
>;
export const ManagementDashboardInputDocument = gql`
    query ManagementDashboardInput {
  portfolio {
    subFunds {
      list {
        dimension
      }
    }
    journal {
      preferences {
        dimension
      }
    }
  }
}
    `;

/**
 * __useManagementDashboardInputQuery__
 *
 * To run a query within a React component, call `useManagementDashboardInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagementDashboardInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagementDashboardInputQuery({
 *   variables: {
 *   },
 * });
 */
export function useManagementDashboardInputQuery(
  baseOptions?: Apollo.QueryHookOptions<IManagementDashboardInputQuery, IManagementDashboardInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IManagementDashboardInputQuery, IManagementDashboardInputQueryVariables>(
    ManagementDashboardInputDocument,
    options
  );
}
export function useManagementDashboardInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IManagementDashboardInputQuery, IManagementDashboardInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IManagementDashboardInputQuery, IManagementDashboardInputQueryVariables>(
    ManagementDashboardInputDocument,
    options
  );
}
export function useManagementDashboardInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IManagementDashboardInputQuery, IManagementDashboardInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IManagementDashboardInputQuery, IManagementDashboardInputQueryVariables>(
    ManagementDashboardInputDocument,
    options
  );
}
export type ManagementDashboardInputQueryHookResult = ReturnType<typeof useManagementDashboardInputQuery>;
export type ManagementDashboardInputLazyQueryHookResult = ReturnType<typeof useManagementDashboardInputLazyQuery>;
export type ManagementDashboardInputSuspenseQueryHookResult = ReturnType<
  typeof useManagementDashboardInputSuspenseQuery
>;
export type ManagementDashboardInputQueryResult = Apollo.QueryResult<
  IManagementDashboardInputQuery,
  IManagementDashboardInputQueryVariables
>;
export const UpdateJournalDimensionDocument = gql`
    mutation UpdateJournalDimension($dimension: String) {
  updateJournalPreferences(dimension: $dimension)
}
    `;
export type IUpdateJournalDimensionMutationFn = Apollo.MutationFunction<
  IUpdateJournalDimensionMutation,
  IUpdateJournalDimensionMutationVariables
>;

/**
 * __useUpdateJournalDimensionMutation__
 *
 * To run a mutation, you first call `useUpdateJournalDimensionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJournalDimensionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJournalDimensionMutation, { data, loading, error }] = useUpdateJournalDimensionMutation({
 *   variables: {
 *      dimension: // value for 'dimension'
 *   },
 * });
 */
export function useUpdateJournalDimensionMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdateJournalDimensionMutation, IUpdateJournalDimensionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IUpdateJournalDimensionMutation, IUpdateJournalDimensionMutationVariables>(
    UpdateJournalDimensionDocument,
    options
  );
}
export type UpdateJournalDimensionMutationHookResult = ReturnType<typeof useUpdateJournalDimensionMutation>;
export type UpdateJournalDimensionMutationResult = Apollo.MutationResult<IUpdateJournalDimensionMutation>;
export type UpdateJournalDimensionMutationOptions = Apollo.BaseMutationOptions<
  IUpdateJournalDimensionMutation,
  IUpdateJournalDimensionMutationVariables
>;
export const UpdateUserSettingsDocument = gql`
    mutation UpdateUserSettings($settings: JSON!) {
  UpdateUserSettings(settings: $settings)
}
    `;
export type IUpdateUserSettingsMutationFn = Apollo.MutationFunction<
  IUpdateUserSettingsMutation,
  IUpdateUserSettingsMutationVariables
>;

/**
 * __useUpdateUserSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSettingsMutation, { data, loading, error }] = useUpdateUserSettingsMutation({
 *   variables: {
 *      settings: // value for 'settings'
 *   },
 * });
 */
export function useUpdateUserSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdateUserSettingsMutation, IUpdateUserSettingsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IUpdateUserSettingsMutation, IUpdateUserSettingsMutationVariables>(
    UpdateUserSettingsDocument,
    options
  );
}
export type UpdateUserSettingsMutationHookResult = ReturnType<typeof useUpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationResult = Apollo.MutationResult<IUpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationOptions = Apollo.BaseMutationOptions<
  IUpdateUserSettingsMutation,
  IUpdateUserSettingsMutationVariables
>;
export const UserSettingsDocument = gql`
    query UserSettings($field: String!) {
  management {
    userSettings(field: $field)
  }
}
    `;

/**
 * __useUserSettingsQuery__
 *
 * To run a query within a React component, call `useUserSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSettingsQuery({
 *   variables: {
 *      field: // value for 'field'
 *   },
 * });
 */
export function useUserSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<IUserSettingsQuery, IUserSettingsQueryVariables> &
    ({ variables: IUserSettingsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IUserSettingsQuery, IUserSettingsQueryVariables>(UserSettingsDocument, options);
}
export function useUserSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IUserSettingsQuery, IUserSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IUserSettingsQuery, IUserSettingsQueryVariables>(UserSettingsDocument, options);
}
export function useUserSettingsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IUserSettingsQuery, IUserSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IUserSettingsQuery, IUserSettingsQueryVariables>(UserSettingsDocument, options);
}
export type UserSettingsQueryHookResult = ReturnType<typeof useUserSettingsQuery>;
export type UserSettingsLazyQueryHookResult = ReturnType<typeof useUserSettingsLazyQuery>;
export type UserSettingsSuspenseQueryHookResult = ReturnType<typeof useUserSettingsSuspenseQuery>;
export type UserSettingsQueryResult = Apollo.QueryResult<IUserSettingsQuery, IUserSettingsQueryVariables>;
export const CreateWidgetDocument = gql`
    mutation CreateWidget($input: CreateWidgetInput!) {
  createWidget(input: $input)
}
    `;
export type ICreateWidgetMutationFn = Apollo.MutationFunction<ICreateWidgetMutation, ICreateWidgetMutationVariables>;

/**
 * __useCreateWidgetMutation__
 *
 * To run a mutation, you first call `useCreateWidgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWidgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWidgetMutation, { data, loading, error }] = useCreateWidgetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWidgetMutation(
  baseOptions?: Apollo.MutationHookOptions<ICreateWidgetMutation, ICreateWidgetMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICreateWidgetMutation, ICreateWidgetMutationVariables>(CreateWidgetDocument, options);
}
export type CreateWidgetMutationHookResult = ReturnType<typeof useCreateWidgetMutation>;
export type CreateWidgetMutationResult = Apollo.MutationResult<ICreateWidgetMutation>;
export type CreateWidgetMutationOptions = Apollo.BaseMutationOptions<
  ICreateWidgetMutation,
  ICreateWidgetMutationVariables
>;
export const DeleteWidgetDocument = gql`
    mutation DeleteWidget($page: String!, $id: String!) {
  deleteWidget(page: $page, widgetId: $id)
}
    `;
export type IDeleteWidgetMutationFn = Apollo.MutationFunction<IDeleteWidgetMutation, IDeleteWidgetMutationVariables>;

/**
 * __useDeleteWidgetMutation__
 *
 * To run a mutation, you first call `useDeleteWidgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWidgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWidgetMutation, { data, loading, error }] = useDeleteWidgetMutation({
 *   variables: {
 *      page: // value for 'page'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWidgetMutation(
  baseOptions?: Apollo.MutationHookOptions<IDeleteWidgetMutation, IDeleteWidgetMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IDeleteWidgetMutation, IDeleteWidgetMutationVariables>(DeleteWidgetDocument, options);
}
export type DeleteWidgetMutationHookResult = ReturnType<typeof useDeleteWidgetMutation>;
export type DeleteWidgetMutationResult = Apollo.MutationResult<IDeleteWidgetMutation>;
export type DeleteWidgetMutationOptions = Apollo.BaseMutationOptions<
  IDeleteWidgetMutation,
  IDeleteWidgetMutationVariables
>;
export const GetWidgetsDocument = gql`
    query GetWidgets($pageId: String!) {
  management {
    widgets(page: $pageId) {
      id
      title
      type
      data
      layout {
        x
        y
        w
        h
      }
    }
  }
}
    `;

/**
 * __useGetWidgetsQuery__
 *
 * To run a query within a React component, call `useGetWidgetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWidgetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWidgetsQuery({
 *   variables: {
 *      pageId: // value for 'pageId'
 *   },
 * });
 */
export function useGetWidgetsQuery(
  baseOptions: Apollo.QueryHookOptions<IGetWidgetsQuery, IGetWidgetsQueryVariables> &
    ({ variables: IGetWidgetsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IGetWidgetsQuery, IGetWidgetsQueryVariables>(GetWidgetsDocument, options);
}
export function useGetWidgetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetWidgetsQuery, IGetWidgetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IGetWidgetsQuery, IGetWidgetsQueryVariables>(GetWidgetsDocument, options);
}
export function useGetWidgetsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetWidgetsQuery, IGetWidgetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IGetWidgetsQuery, IGetWidgetsQueryVariables>(GetWidgetsDocument, options);
}
export type GetWidgetsQueryHookResult = ReturnType<typeof useGetWidgetsQuery>;
export type GetWidgetsLazyQueryHookResult = ReturnType<typeof useGetWidgetsLazyQuery>;
export type GetWidgetsSuspenseQueryHookResult = ReturnType<typeof useGetWidgetsSuspenseQuery>;
export type GetWidgetsQueryResult = Apollo.QueryResult<IGetWidgetsQuery, IGetWidgetsQueryVariables>;
export const UpdateWidgetStateDocument = gql`
    mutation UpdateWidgetState($input: UpdateWidgetInput!) {
  updateWidget(input: $input)
}
    `;
export type IUpdateWidgetStateMutationFn = Apollo.MutationFunction<
  IUpdateWidgetStateMutation,
  IUpdateWidgetStateMutationVariables
>;

/**
 * __useUpdateWidgetStateMutation__
 *
 * To run a mutation, you first call `useUpdateWidgetStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWidgetStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWidgetStateMutation, { data, loading, error }] = useUpdateWidgetStateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWidgetStateMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdateWidgetStateMutation, IUpdateWidgetStateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IUpdateWidgetStateMutation, IUpdateWidgetStateMutationVariables>(
    UpdateWidgetStateDocument,
    options
  );
}
export type UpdateWidgetStateMutationHookResult = ReturnType<typeof useUpdateWidgetStateMutation>;
export type UpdateWidgetStateMutationResult = Apollo.MutationResult<IUpdateWidgetStateMutation>;
export type UpdateWidgetStateMutationOptions = Apollo.BaseMutationOptions<
  IUpdateWidgetStateMutation,
  IUpdateWidgetStateMutationVariables
>;
export const UpdateWidgetsLayoutDocument = gql`
    mutation UpdateWidgetsLayout($input: [UpdateLayoutInput!]!) {
  updateLayout(input: $input)
}
    `;
export type IUpdateWidgetsLayoutMutationFn = Apollo.MutationFunction<
  IUpdateWidgetsLayoutMutation,
  IUpdateWidgetsLayoutMutationVariables
>;

/**
 * __useUpdateWidgetsLayoutMutation__
 *
 * To run a mutation, you first call `useUpdateWidgetsLayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWidgetsLayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWidgetsLayoutMutation, { data, loading, error }] = useUpdateWidgetsLayoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWidgetsLayoutMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdateWidgetsLayoutMutation, IUpdateWidgetsLayoutMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IUpdateWidgetsLayoutMutation, IUpdateWidgetsLayoutMutationVariables>(
    UpdateWidgetsLayoutDocument,
    options
  );
}
export type UpdateWidgetsLayoutMutationHookResult = ReturnType<typeof useUpdateWidgetsLayoutMutation>;
export type UpdateWidgetsLayoutMutationResult = Apollo.MutationResult<IUpdateWidgetsLayoutMutation>;
export type UpdateWidgetsLayoutMutationOptions = Apollo.BaseMutationOptions<
  IUpdateWidgetsLayoutMutation,
  IUpdateWidgetsLayoutMutationVariables
>;
export const UsersDocument = gql`
    query Users {
  management {
    users {
      name
      id
      email
      groups
      enabled
      cognitoUsername
      organization {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<IUsersQuery, IUsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IUsersQuery, IUsersQueryVariables>(UsersDocument, options);
}
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IUsersQuery, IUsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IUsersQuery, IUsersQueryVariables>(UsersDocument, options);
}
export function useUsersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IUsersQuery, IUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IUsersQuery, IUsersQueryVariables>(UsersDocument, options);
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersSuspenseQueryHookResult = ReturnType<typeof useUsersSuspenseQuery>;
export type UsersQueryResult = Apollo.QueryResult<IUsersQuery, IUsersQueryVariables>;
export const AssetDocument = gql`
    query Asset($assetId: UUID!) {
  assets {
    list(filters: {assetIds: [$assetId]}) {
      ...MainAssetFields
    }
  }
}
    ${MainAssetFieldsFragmentDoc}`;

/**
 * __useAssetQuery__
 *
 * To run a query within a React component, call `useAssetQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetQuery({
 *   variables: {
 *      assetId: // value for 'assetId'
 *   },
 * });
 */
export function useAssetQuery(
  baseOptions: Apollo.QueryHookOptions<IAssetQuery, IAssetQueryVariables> &
    ({ variables: IAssetQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IAssetQuery, IAssetQueryVariables>(AssetDocument, options);
}
export function useAssetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IAssetQuery, IAssetQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IAssetQuery, IAssetQueryVariables>(AssetDocument, options);
}
export function useAssetSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IAssetQuery, IAssetQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IAssetQuery, IAssetQueryVariables>(AssetDocument, options);
}
export type AssetQueryHookResult = ReturnType<typeof useAssetQuery>;
export type AssetLazyQueryHookResult = ReturnType<typeof useAssetLazyQuery>;
export type AssetSuspenseQueryHookResult = ReturnType<typeof useAssetSuspenseQuery>;
export type AssetQueryResult = Apollo.QueryResult<IAssetQuery, IAssetQueryVariables>;
export const AssetNameDocument = gql`
    query AssetName($filters: ListAssetFilters) {
  assets {
    list(filters: $filters) {
      name
      id
    }
  }
}
    `;

/**
 * __useAssetNameQuery__
 *
 * To run a query within a React component, call `useAssetNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetNameQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAssetNameQuery(baseOptions?: Apollo.QueryHookOptions<IAssetNameQuery, IAssetNameQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IAssetNameQuery, IAssetNameQueryVariables>(AssetNameDocument, options);
}
export function useAssetNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAssetNameQuery, IAssetNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IAssetNameQuery, IAssetNameQueryVariables>(AssetNameDocument, options);
}
export function useAssetNameSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IAssetNameQuery, IAssetNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IAssetNameQuery, IAssetNameQueryVariables>(AssetNameDocument, options);
}
export type AssetNameQueryHookResult = ReturnType<typeof useAssetNameQuery>;
export type AssetNameLazyQueryHookResult = ReturnType<typeof useAssetNameLazyQuery>;
export type AssetNameSuspenseQueryHookResult = ReturnType<typeof useAssetNameSuspenseQuery>;
export type AssetNameQueryResult = Apollo.QueryResult<IAssetNameQuery, IAssetNameQueryVariables>;
export const AssetPaginatedDocument = gql`
    query AssetPaginated($cursors: CursorsV1, $filters: AssetByNameFilters) {
  assets {
    paginated(cursors: $cursors, filters: $filters) {
      data {
        ...AssetLabelFields
        derivativeDetails {
          derivativeType
          exchange
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
}
    ${AssetLabelFieldsFragmentDoc}`;

/**
 * __useAssetPaginatedQuery__
 *
 * To run a query within a React component, call `useAssetPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetPaginatedQuery({
 *   variables: {
 *      cursors: // value for 'cursors'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAssetPaginatedQuery(
  baseOptions?: Apollo.QueryHookOptions<IAssetPaginatedQuery, IAssetPaginatedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IAssetPaginatedQuery, IAssetPaginatedQueryVariables>(AssetPaginatedDocument, options);
}
export function useAssetPaginatedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAssetPaginatedQuery, IAssetPaginatedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IAssetPaginatedQuery, IAssetPaginatedQueryVariables>(AssetPaginatedDocument, options);
}
export function useAssetPaginatedSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IAssetPaginatedQuery, IAssetPaginatedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IAssetPaginatedQuery, IAssetPaginatedQueryVariables>(AssetPaginatedDocument, options);
}
export type AssetPaginatedQueryHookResult = ReturnType<typeof useAssetPaginatedQuery>;
export type AssetPaginatedLazyQueryHookResult = ReturnType<typeof useAssetPaginatedLazyQuery>;
export type AssetPaginatedSuspenseQueryHookResult = ReturnType<typeof useAssetPaginatedSuspenseQuery>;
export type AssetPaginatedQueryResult = Apollo.QueryResult<IAssetPaginatedQuery, IAssetPaginatedQueryVariables>;
export const PublicAssetsDocument = gql`
    query PublicAssets {
  assets {
    list(filters: {assetTypes: [PUBLIC]}) {
      id
      symbol
    }
  }
}
    `;

/**
 * __usePublicAssetsQuery__
 *
 * To run a query within a React component, call `usePublicAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicAssetsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePublicAssetsQuery(
  baseOptions?: Apollo.QueryHookOptions<IPublicAssetsQuery, IPublicAssetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPublicAssetsQuery, IPublicAssetsQueryVariables>(PublicAssetsDocument, options);
}
export function usePublicAssetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPublicAssetsQuery, IPublicAssetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPublicAssetsQuery, IPublicAssetsQueryVariables>(PublicAssetsDocument, options);
}
export function usePublicAssetsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IPublicAssetsQuery, IPublicAssetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IPublicAssetsQuery, IPublicAssetsQueryVariables>(PublicAssetsDocument, options);
}
export type PublicAssetsQueryHookResult = ReturnType<typeof usePublicAssetsQuery>;
export type PublicAssetsLazyQueryHookResult = ReturnType<typeof usePublicAssetsLazyQuery>;
export type PublicAssetsSuspenseQueryHookResult = ReturnType<typeof usePublicAssetsSuspenseQuery>;
export type PublicAssetsQueryResult = Apollo.QueryResult<IPublicAssetsQuery, IPublicAssetsQueryVariables>;
export const AssetsDocument = gql`
    query Assets {
  assets {
    feature(feature: DISCOVERY_DASHBOARD) {
      type
      symbol
      label
      name
      icon
      id
    }
  }
}
    `;

/**
 * __useAssetsQuery__
 *
 * To run a query within a React component, call `useAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAssetsQuery(baseOptions?: Apollo.QueryHookOptions<IAssetsQuery, IAssetsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IAssetsQuery, IAssetsQueryVariables>(AssetsDocument, options);
}
export function useAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IAssetsQuery, IAssetsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IAssetsQuery, IAssetsQueryVariables>(AssetsDocument, options);
}
export function useAssetsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IAssetsQuery, IAssetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IAssetsQuery, IAssetsQueryVariables>(AssetsDocument, options);
}
export type AssetsQueryHookResult = ReturnType<typeof useAssetsQuery>;
export type AssetsLazyQueryHookResult = ReturnType<typeof useAssetsLazyQuery>;
export type AssetsSuspenseQueryHookResult = ReturnType<typeof useAssetsSuspenseQuery>;
export type AssetsQueryResult = Apollo.QueryResult<IAssetsQuery, IAssetsQueryVariables>;
export const ComputeMultifactorScoresDocument = gql`
    query ComputeMultifactorScores($input: AssetScreenerInput!) {
  multifactor {
    computeMultifactorScores(input: $input) {
      scores {
        assetScores {
          asset {
            ...MainAssetFields
          }
          score
        }
        multifactor {
          factorWeights {
            betterIfLower
            metricId
            weight
          }
          maxFactors
          multifactor {
            description
            id
            name
            createdAt
            updatedAt
          }
        }
      }
    }
  }
}
    ${MainAssetFieldsFragmentDoc}`;

/**
 * __useComputeMultifactorScoresQuery__
 *
 * To run a query within a React component, call `useComputeMultifactorScoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useComputeMultifactorScoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComputeMultifactorScoresQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useComputeMultifactorScoresQuery(
  baseOptions: Apollo.QueryHookOptions<IComputeMultifactorScoresQuery, IComputeMultifactorScoresQueryVariables> &
    ({ variables: IComputeMultifactorScoresQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IComputeMultifactorScoresQuery, IComputeMultifactorScoresQueryVariables>(
    ComputeMultifactorScoresDocument,
    options
  );
}
export function useComputeMultifactorScoresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IComputeMultifactorScoresQuery, IComputeMultifactorScoresQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IComputeMultifactorScoresQuery, IComputeMultifactorScoresQueryVariables>(
    ComputeMultifactorScoresDocument,
    options
  );
}
export function useComputeMultifactorScoresSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IComputeMultifactorScoresQuery, IComputeMultifactorScoresQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IComputeMultifactorScoresQuery, IComputeMultifactorScoresQueryVariables>(
    ComputeMultifactorScoresDocument,
    options
  );
}
export type ComputeMultifactorScoresQueryHookResult = ReturnType<typeof useComputeMultifactorScoresQuery>;
export type ComputeMultifactorScoresLazyQueryHookResult = ReturnType<typeof useComputeMultifactorScoresLazyQuery>;
export type ComputeMultifactorScoresSuspenseQueryHookResult = ReturnType<
  typeof useComputeMultifactorScoresSuspenseQuery
>;
export type ComputeMultifactorScoresQueryResult = Apollo.QueryResult<
  IComputeMultifactorScoresQuery,
  IComputeMultifactorScoresQueryVariables
>;
export const FactorDocument = gql`
    query Factor {
  multifactor {
    getAllSupportedFactors {
      id
      name
      category
    }
  }
}
    `;

/**
 * __useFactorQuery__
 *
 * To run a query within a React component, call `useFactorQuery` and pass it any options that fit your needs.
 * When your component renders, `useFactorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFactorQuery({
 *   variables: {
 *   },
 * });
 */
export function useFactorQuery(baseOptions?: Apollo.QueryHookOptions<IFactorQuery, IFactorQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IFactorQuery, IFactorQueryVariables>(FactorDocument, options);
}
export function useFactorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IFactorQuery, IFactorQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IFactorQuery, IFactorQueryVariables>(FactorDocument, options);
}
export function useFactorSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IFactorQuery, IFactorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IFactorQuery, IFactorQueryVariables>(FactorDocument, options);
}
export type FactorQueryHookResult = ReturnType<typeof useFactorQuery>;
export type FactorLazyQueryHookResult = ReturnType<typeof useFactorLazyQuery>;
export type FactorSuspenseQueryHookResult = ReturnType<typeof useFactorSuspenseQuery>;
export type FactorQueryResult = Apollo.QueryResult<IFactorQuery, IFactorQueryVariables>;
export const MultifactorDocument = gql`
    query Multifactor {
  multifactor {
    getAllMultifactorsForUser {
      factorWeights {
        betterIfLower
        metricId
        weight
      }
      maxFactors
      multifactor {
        createdAt
        description
        name
        updatedAt
        id
      }
    }
  }
}
    `;

/**
 * __useMultifactorQuery__
 *
 * To run a query within a React component, call `useMultifactorQuery` and pass it any options that fit your needs.
 * When your component renders, `useMultifactorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMultifactorQuery({
 *   variables: {
 *   },
 * });
 */
export function useMultifactorQuery(
  baseOptions?: Apollo.QueryHookOptions<IMultifactorQuery, IMultifactorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IMultifactorQuery, IMultifactorQueryVariables>(MultifactorDocument, options);
}
export function useMultifactorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IMultifactorQuery, IMultifactorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IMultifactorQuery, IMultifactorQueryVariables>(MultifactorDocument, options);
}
export function useMultifactorSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IMultifactorQuery, IMultifactorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IMultifactorQuery, IMultifactorQueryVariables>(MultifactorDocument, options);
}
export type MultifactorQueryHookResult = ReturnType<typeof useMultifactorQuery>;
export type MultifactorLazyQueryHookResult = ReturnType<typeof useMultifactorLazyQuery>;
export type MultifactorSuspenseQueryHookResult = ReturnType<typeof useMultifactorSuspenseQuery>;
export type MultifactorQueryResult = Apollo.QueryResult<IMultifactorQuery, IMultifactorQueryVariables>;
export const MultifactorDetailsDocument = gql`
    query MultifactorDetails($multifactorId: Int!) {
  multifactor {
    getMultifactorDetails(multifactorId: $multifactorId) {
      factorWeights {
        betterIfLower
        metricId
        weight
      }
      maxFactors
      multifactor {
        createdAt
        description
        name
        updatedAt
        id
      }
    }
  }
}
    `;

/**
 * __useMultifactorDetailsQuery__
 *
 * To run a query within a React component, call `useMultifactorDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMultifactorDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMultifactorDetailsQuery({
 *   variables: {
 *      multifactorId: // value for 'multifactorId'
 *   },
 * });
 */
export function useMultifactorDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<IMultifactorDetailsQuery, IMultifactorDetailsQueryVariables> &
    ({ variables: IMultifactorDetailsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IMultifactorDetailsQuery, IMultifactorDetailsQueryVariables>(
    MultifactorDetailsDocument,
    options
  );
}
export function useMultifactorDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IMultifactorDetailsQuery, IMultifactorDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IMultifactorDetailsQuery, IMultifactorDetailsQueryVariables>(
    MultifactorDetailsDocument,
    options
  );
}
export function useMultifactorDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IMultifactorDetailsQuery, IMultifactorDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IMultifactorDetailsQuery, IMultifactorDetailsQueryVariables>(
    MultifactorDetailsDocument,
    options
  );
}
export type MultifactorDetailsQueryHookResult = ReturnType<typeof useMultifactorDetailsQuery>;
export type MultifactorDetailsLazyQueryHookResult = ReturnType<typeof useMultifactorDetailsLazyQuery>;
export type MultifactorDetailsSuspenseQueryHookResult = ReturnType<typeof useMultifactorDetailsSuspenseQuery>;
export type MultifactorDetailsQueryResult = Apollo.QueryResult<
  IMultifactorDetailsQuery,
  IMultifactorDetailsQueryVariables
>;
export const SaveEditedMultifactorDocument = gql`
    mutation SaveEditedMultifactor($input: PartiallyDefinedMultiFactorInput!, $multifactorId: Int!) {
  saveEditedMultifactor(input: $input, multifactorId: $multifactorId) {
    factorWeights {
      betterIfLower
      metricId
      weight
    }
    multifactor {
      createdAt
      description
      name
      updatedAt
      id
    }
  }
}
    `;
export type ISaveEditedMultifactorMutationFn = Apollo.MutationFunction<
  ISaveEditedMultifactorMutation,
  ISaveEditedMultifactorMutationVariables
>;

/**
 * __useSaveEditedMultifactorMutation__
 *
 * To run a mutation, you first call `useSaveEditedMultifactorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveEditedMultifactorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveEditedMultifactorMutation, { data, loading, error }] = useSaveEditedMultifactorMutation({
 *   variables: {
 *      input: // value for 'input'
 *      multifactorId: // value for 'multifactorId'
 *   },
 * });
 */
export function useSaveEditedMultifactorMutation(
  baseOptions?: Apollo.MutationHookOptions<ISaveEditedMultifactorMutation, ISaveEditedMultifactorMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ISaveEditedMultifactorMutation, ISaveEditedMultifactorMutationVariables>(
    SaveEditedMultifactorDocument,
    options
  );
}
export type SaveEditedMultifactorMutationHookResult = ReturnType<typeof useSaveEditedMultifactorMutation>;
export type SaveEditedMultifactorMutationResult = Apollo.MutationResult<ISaveEditedMultifactorMutation>;
export type SaveEditedMultifactorMutationOptions = Apollo.BaseMutationOptions<
  ISaveEditedMultifactorMutation,
  ISaveEditedMultifactorMutationVariables
>;
export const SaveNewMultifactorDocument = gql`
    mutation SaveNewMultifactor($input: PartiallyDefinedMultiFactorInput!) {
  saveNewMultifactor(input: $input) {
    factorWeights {
      betterIfLower
      metricId
      weight
    }
    multifactor {
      createdAt
      description
      name
      updatedAt
      id
    }
  }
}
    `;
export type ISaveNewMultifactorMutationFn = Apollo.MutationFunction<
  ISaveNewMultifactorMutation,
  ISaveNewMultifactorMutationVariables
>;

/**
 * __useSaveNewMultifactorMutation__
 *
 * To run a mutation, you first call `useSaveNewMultifactorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveNewMultifactorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveNewMultifactorMutation, { data, loading, error }] = useSaveNewMultifactorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveNewMultifactorMutation(
  baseOptions?: Apollo.MutationHookOptions<ISaveNewMultifactorMutation, ISaveNewMultifactorMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ISaveNewMultifactorMutation, ISaveNewMultifactorMutationVariables>(
    SaveNewMultifactorDocument,
    options
  );
}
export type SaveNewMultifactorMutationHookResult = ReturnType<typeof useSaveNewMultifactorMutation>;
export type SaveNewMultifactorMutationResult = Apollo.MutationResult<ISaveNewMultifactorMutation>;
export type SaveNewMultifactorMutationOptions = Apollo.BaseMutationOptions<
  ISaveNewMultifactorMutation,
  ISaveNewMultifactorMutationVariables
>;
export const DeleteMultifactorDocument = gql`
    mutation DeleteMultifactor($multifactorId: Int!) {
  deleteMultifactor(multifactorId: $multifactorId)
}
    `;
export type IDeleteMultifactorMutationFn = Apollo.MutationFunction<
  IDeleteMultifactorMutation,
  IDeleteMultifactorMutationVariables
>;

/**
 * __useDeleteMultifactorMutation__
 *
 * To run a mutation, you first call `useDeleteMultifactorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMultifactorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMultifactorMutation, { data, loading, error }] = useDeleteMultifactorMutation({
 *   variables: {
 *      multifactorId: // value for 'multifactorId'
 *   },
 * });
 */
export function useDeleteMultifactorMutation(
  baseOptions?: Apollo.MutationHookOptions<IDeleteMultifactorMutation, IDeleteMultifactorMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IDeleteMultifactorMutation, IDeleteMultifactorMutationVariables>(
    DeleteMultifactorDocument,
    options
  );
}
export type DeleteMultifactorMutationHookResult = ReturnType<typeof useDeleteMultifactorMutation>;
export type DeleteMultifactorMutationResult = Apollo.MutationResult<IDeleteMultifactorMutation>;
export type DeleteMultifactorMutationOptions = Apollo.BaseMutationOptions<
  IDeleteMultifactorMutation,
  IDeleteMultifactorMutationVariables
>;
export const CorrelationInputDocument = gql`
    query CorrelationInput {
  portfolio {
    positions {
      positions {
        asset {
          ...AssetLabelFields
        }
        spot {
          balance
        }
      }
    }
  }
  assets {
    feature(feature: CORRELATION_DASHBOARD) {
      ...AssetLabelFields
    }
  }
}
    ${AssetLabelFieldsFragmentDoc}`;

/**
 * __useCorrelationInputQuery__
 *
 * To run a query within a React component, call `useCorrelationInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useCorrelationInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCorrelationInputQuery({
 *   variables: {
 *   },
 * });
 */
export function useCorrelationInputQuery(
  baseOptions?: Apollo.QueryHookOptions<ICorrelationInputQuery, ICorrelationInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ICorrelationInputQuery, ICorrelationInputQueryVariables>(CorrelationInputDocument, options);
}
export function useCorrelationInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ICorrelationInputQuery, ICorrelationInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ICorrelationInputQuery, ICorrelationInputQueryVariables>(
    CorrelationInputDocument,
    options
  );
}
export function useCorrelationInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ICorrelationInputQuery, ICorrelationInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ICorrelationInputQuery, ICorrelationInputQueryVariables>(
    CorrelationInputDocument,
    options
  );
}
export type CorrelationInputQueryHookResult = ReturnType<typeof useCorrelationInputQuery>;
export type CorrelationInputLazyQueryHookResult = ReturnType<typeof useCorrelationInputLazyQuery>;
export type CorrelationInputSuspenseQueryHookResult = ReturnType<typeof useCorrelationInputSuspenseQuery>;
export type CorrelationInputQueryResult = Apollo.QueryResult<ICorrelationInputQuery, ICorrelationInputQueryVariables>;
export const CorrelationsDocument = gql`
    query Correlations($input: TwoAssetMetricRequestInput!, $filters: TwoAssetMetricFilters) {
  assets {
    twoAssetMetrics(input: $input, filters: $filters) {
      date
      value
      primaryAsset {
        id
      }
      secondaryAsset {
        id
      }
    }
  }
}
    `;

/**
 * __useCorrelationsQuery__
 *
 * To run a query within a React component, call `useCorrelationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCorrelationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCorrelationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCorrelationsQuery(
  baseOptions: Apollo.QueryHookOptions<ICorrelationsQuery, ICorrelationsQueryVariables> &
    ({ variables: ICorrelationsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ICorrelationsQuery, ICorrelationsQueryVariables>(CorrelationsDocument, options);
}
export function useCorrelationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ICorrelationsQuery, ICorrelationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ICorrelationsQuery, ICorrelationsQueryVariables>(CorrelationsDocument, options);
}
export function useCorrelationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ICorrelationsQuery, ICorrelationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ICorrelationsQuery, ICorrelationsQueryVariables>(CorrelationsDocument, options);
}
export type CorrelationsQueryHookResult = ReturnType<typeof useCorrelationsQuery>;
export type CorrelationsLazyQueryHookResult = ReturnType<typeof useCorrelationsLazyQuery>;
export type CorrelationsSuspenseQueryHookResult = ReturnType<typeof useCorrelationsSuspenseQuery>;
export type CorrelationsQueryResult = Apollo.QueryResult<ICorrelationsQuery, ICorrelationsQueryVariables>;
export const LatestCorrelationsDocument = gql`
    query LatestCorrelations($input: TwoAssetMetricRequestInput!) {
  assets {
    twoAssetLatestMetrics(input: $input) {
      date
      value
      primaryAsset {
        ...MainAssetFields
      }
      secondaryAsset {
        ...MainAssetFields
      }
    }
  }
}
    ${MainAssetFieldsFragmentDoc}`;

/**
 * __useLatestCorrelationsQuery__
 *
 * To run a query within a React component, call `useLatestCorrelationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestCorrelationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestCorrelationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLatestCorrelationsQuery(
  baseOptions: Apollo.QueryHookOptions<ILatestCorrelationsQuery, ILatestCorrelationsQueryVariables> &
    ({ variables: ILatestCorrelationsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ILatestCorrelationsQuery, ILatestCorrelationsQueryVariables>(
    LatestCorrelationsDocument,
    options
  );
}
export function useLatestCorrelationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ILatestCorrelationsQuery, ILatestCorrelationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ILatestCorrelationsQuery, ILatestCorrelationsQueryVariables>(
    LatestCorrelationsDocument,
    options
  );
}
export function useLatestCorrelationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ILatestCorrelationsQuery, ILatestCorrelationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ILatestCorrelationsQuery, ILatestCorrelationsQueryVariables>(
    LatestCorrelationsDocument,
    options
  );
}
export type LatestCorrelationsQueryHookResult = ReturnType<typeof useLatestCorrelationsQuery>;
export type LatestCorrelationsLazyQueryHookResult = ReturnType<typeof useLatestCorrelationsLazyQuery>;
export type LatestCorrelationsSuspenseQueryHookResult = ReturnType<typeof useLatestCorrelationsSuspenseQuery>;
export type LatestCorrelationsQueryResult = Apollo.QueryResult<
  ILatestCorrelationsQuery,
  ILatestCorrelationsQueryVariables
>;
export const AssetDashboardDocument = gql`
    query AssetDashboard($filters: AssetFilters!) {
  assets {
    feature(feature: DISCOVERY_DASHBOARD) {
      type
      symbol
      label
      name
      icon
      id
    }
    changes(filters: $filters) {
      asset
      price
      metrics
    }
    details(filters: $filters) {
      asset
      metrics
      price
    }
    assetGroups {
      genieGroups {
        id
        clusterName
        groupName
        assets {
          type
          symbol
          label
          name
          icon
          id
        }
      }
      userGroups {
        id
        clusterName
        groupName
        assets {
          type
          symbol
          label
          name
          icon
          id
        }
      }
    }
  }
}
    `;

/**
 * __useAssetDashboardQuery__
 *
 * To run a query within a React component, call `useAssetDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetDashboardQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAssetDashboardQuery(
  baseOptions: Apollo.QueryHookOptions<IAssetDashboardQuery, IAssetDashboardQueryVariables> &
    ({ variables: IAssetDashboardQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IAssetDashboardQuery, IAssetDashboardQueryVariables>(AssetDashboardDocument, options);
}
export function useAssetDashboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAssetDashboardQuery, IAssetDashboardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IAssetDashboardQuery, IAssetDashboardQueryVariables>(AssetDashboardDocument, options);
}
export function useAssetDashboardSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IAssetDashboardQuery, IAssetDashboardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IAssetDashboardQuery, IAssetDashboardQueryVariables>(AssetDashboardDocument, options);
}
export type AssetDashboardQueryHookResult = ReturnType<typeof useAssetDashboardQuery>;
export type AssetDashboardLazyQueryHookResult = ReturnType<typeof useAssetDashboardLazyQuery>;
export type AssetDashboardSuspenseQueryHookResult = ReturnType<typeof useAssetDashboardSuspenseQuery>;
export type AssetDashboardQueryResult = Apollo.QueryResult<IAssetDashboardQuery, IAssetDashboardQueryVariables>;
export const AssetClusterGroupPriceDocument = gql`
    query AssetClusterGroupPrice($assetIds: [UUID!]!, $fromDate: Date) {
  assets {
    price(assetIds: $assetIds, fromDate: $fromDate, resolutionDays: 3) {
      asset {
        id
      }
      rows {
        date
        price
      }
    }
  }
}
    `;

/**
 * __useAssetClusterGroupPriceQuery__
 *
 * To run a query within a React component, call `useAssetClusterGroupPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetClusterGroupPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetClusterGroupPriceQuery({
 *   variables: {
 *      assetIds: // value for 'assetIds'
 *      fromDate: // value for 'fromDate'
 *   },
 * });
 */
export function useAssetClusterGroupPriceQuery(
  baseOptions: Apollo.QueryHookOptions<IAssetClusterGroupPriceQuery, IAssetClusterGroupPriceQueryVariables> &
    ({ variables: IAssetClusterGroupPriceQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IAssetClusterGroupPriceQuery, IAssetClusterGroupPriceQueryVariables>(
    AssetClusterGroupPriceDocument,
    options
  );
}
export function useAssetClusterGroupPriceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAssetClusterGroupPriceQuery, IAssetClusterGroupPriceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IAssetClusterGroupPriceQuery, IAssetClusterGroupPriceQueryVariables>(
    AssetClusterGroupPriceDocument,
    options
  );
}
export function useAssetClusterGroupPriceSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IAssetClusterGroupPriceQuery, IAssetClusterGroupPriceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IAssetClusterGroupPriceQuery, IAssetClusterGroupPriceQueryVariables>(
    AssetClusterGroupPriceDocument,
    options
  );
}
export type AssetClusterGroupPriceQueryHookResult = ReturnType<typeof useAssetClusterGroupPriceQuery>;
export type AssetClusterGroupPriceLazyQueryHookResult = ReturnType<typeof useAssetClusterGroupPriceLazyQuery>;
export type AssetClusterGroupPriceSuspenseQueryHookResult = ReturnType<typeof useAssetClusterGroupPriceSuspenseQuery>;
export type AssetClusterGroupPriceQueryResult = Apollo.QueryResult<
  IAssetClusterGroupPriceQuery,
  IAssetClusterGroupPriceQueryVariables
>;
export const FilterOptionsDocument = gql`
    query FilterOptions {
  assets {
    metricStats {
      liquidity {
        min
        max
      }
      marketCap {
        max
        min
      }
      totalValueLocked {
        max
        min
      }
    }
  }
}
    `;

/**
 * __useFilterOptionsQuery__
 *
 * To run a query within a React component, call `useFilterOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFilterOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<IFilterOptionsQuery, IFilterOptionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IFilterOptionsQuery, IFilterOptionsQueryVariables>(FilterOptionsDocument, options);
}
export function useFilterOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IFilterOptionsQuery, IFilterOptionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IFilterOptionsQuery, IFilterOptionsQueryVariables>(FilterOptionsDocument, options);
}
export function useFilterOptionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IFilterOptionsQuery, IFilterOptionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IFilterOptionsQuery, IFilterOptionsQueryVariables>(FilterOptionsDocument, options);
}
export type FilterOptionsQueryHookResult = ReturnType<typeof useFilterOptionsQuery>;
export type FilterOptionsLazyQueryHookResult = ReturnType<typeof useFilterOptionsLazyQuery>;
export type FilterOptionsSuspenseQueryHookResult = ReturnType<typeof useFilterOptionsSuspenseQuery>;
export type FilterOptionsQueryResult = Apollo.QueryResult<IFilterOptionsQuery, IFilterOptionsQueryVariables>;
export const AssetGroupsDocument = gql`
    query AssetGroups {
  assets {
    assetGroups {
      userGroups {
        id
        clusterName
        groupName
        createdAt
        updatedAt
        assets {
          id
          icon
          label
          name
          symbol
          type
          exchangeDetails
        }
      }
    }
    feature(feature: ASSET_GROUP) {
      id
      icon
      label
      name
      symbol
      type
      exchangeDetails
    }
  }
}
    `;

/**
 * __useAssetGroupsQuery__
 *
 * To run a query within a React component, call `useAssetGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAssetGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<IAssetGroupsQuery, IAssetGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IAssetGroupsQuery, IAssetGroupsQueryVariables>(AssetGroupsDocument, options);
}
export function useAssetGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAssetGroupsQuery, IAssetGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IAssetGroupsQuery, IAssetGroupsQueryVariables>(AssetGroupsDocument, options);
}
export function useAssetGroupsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IAssetGroupsQuery, IAssetGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IAssetGroupsQuery, IAssetGroupsQueryVariables>(AssetGroupsDocument, options);
}
export type AssetGroupsQueryHookResult = ReturnType<typeof useAssetGroupsQuery>;
export type AssetGroupsLazyQueryHookResult = ReturnType<typeof useAssetGroupsLazyQuery>;
export type AssetGroupsSuspenseQueryHookResult = ReturnType<typeof useAssetGroupsSuspenseQuery>;
export type AssetGroupsQueryResult = Apollo.QueryResult<IAssetGroupsQuery, IAssetGroupsQueryVariables>;
export const CreateAssetGroupDocument = gql`
    mutation CreateAssetGroup($input: CreateAssetGroupInput!) {
  CreateAssetGroup(input: $input) {
    groupName
  }
}
    `;
export type ICreateAssetGroupMutationFn = Apollo.MutationFunction<
  ICreateAssetGroupMutation,
  ICreateAssetGroupMutationVariables
>;

/**
 * __useCreateAssetGroupMutation__
 *
 * To run a mutation, you first call `useCreateAssetGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetGroupMutation, { data, loading, error }] = useCreateAssetGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssetGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<ICreateAssetGroupMutation, ICreateAssetGroupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICreateAssetGroupMutation, ICreateAssetGroupMutationVariables>(
    CreateAssetGroupDocument,
    options
  );
}
export type CreateAssetGroupMutationHookResult = ReturnType<typeof useCreateAssetGroupMutation>;
export type CreateAssetGroupMutationResult = Apollo.MutationResult<ICreateAssetGroupMutation>;
export type CreateAssetGroupMutationOptions = Apollo.BaseMutationOptions<
  ICreateAssetGroupMutation,
  ICreateAssetGroupMutationVariables
>;
export const DeleteAssetGroupDocument = gql`
    mutation DeleteAssetGroup($input: DeleteAssetGroupInput!) {
  DeleteAssetGroup(input: $input)
}
    `;
export type IDeleteAssetGroupMutationFn = Apollo.MutationFunction<
  IDeleteAssetGroupMutation,
  IDeleteAssetGroupMutationVariables
>;

/**
 * __useDeleteAssetGroupMutation__
 *
 * To run a mutation, you first call `useDeleteAssetGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssetGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssetGroupMutation, { data, loading, error }] = useDeleteAssetGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAssetGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<IDeleteAssetGroupMutation, IDeleteAssetGroupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IDeleteAssetGroupMutation, IDeleteAssetGroupMutationVariables>(
    DeleteAssetGroupDocument,
    options
  );
}
export type DeleteAssetGroupMutationHookResult = ReturnType<typeof useDeleteAssetGroupMutation>;
export type DeleteAssetGroupMutationResult = Apollo.MutationResult<IDeleteAssetGroupMutation>;
export type DeleteAssetGroupMutationOptions = Apollo.BaseMutationOptions<
  IDeleteAssetGroupMutation,
  IDeleteAssetGroupMutationVariables
>;
export const DeleteClusterAssetGroupsDocument = gql`
    mutation DeleteClusterAssetGroups($clusterName: String!) {
  DeleteClusterAssetGroups(clusterName: $clusterName)
}
    `;
export type IDeleteClusterAssetGroupsMutationFn = Apollo.MutationFunction<
  IDeleteClusterAssetGroupsMutation,
  IDeleteClusterAssetGroupsMutationVariables
>;

/**
 * __useDeleteClusterAssetGroupsMutation__
 *
 * To run a mutation, you first call `useDeleteClusterAssetGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClusterAssetGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClusterAssetGroupsMutation, { data, loading, error }] = useDeleteClusterAssetGroupsMutation({
 *   variables: {
 *      clusterName: // value for 'clusterName'
 *   },
 * });
 */
export function useDeleteClusterAssetGroupsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteClusterAssetGroupsMutation,
    IDeleteClusterAssetGroupsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IDeleteClusterAssetGroupsMutation, IDeleteClusterAssetGroupsMutationVariables>(
    DeleteClusterAssetGroupsDocument,
    options
  );
}
export type DeleteClusterAssetGroupsMutationHookResult = ReturnType<typeof useDeleteClusterAssetGroupsMutation>;
export type DeleteClusterAssetGroupsMutationResult = Apollo.MutationResult<IDeleteClusterAssetGroupsMutation>;
export type DeleteClusterAssetGroupsMutationOptions = Apollo.BaseMutationOptions<
  IDeleteClusterAssetGroupsMutation,
  IDeleteClusterAssetGroupsMutationVariables
>;
export const RenameClusterDocument = gql`
    mutation RenameCluster($oldClusterName: String!, $newClusterName: String!) {
  RenameCluster(
    input: {oldClusterName: $oldClusterName, newClusterName: $newClusterName}
  )
}
    `;
export type IRenameClusterMutationFn = Apollo.MutationFunction<IRenameClusterMutation, IRenameClusterMutationVariables>;

/**
 * __useRenameClusterMutation__
 *
 * To run a mutation, you first call `useRenameClusterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameClusterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameClusterMutation, { data, loading, error }] = useRenameClusterMutation({
 *   variables: {
 *      oldClusterName: // value for 'oldClusterName'
 *      newClusterName: // value for 'newClusterName'
 *   },
 * });
 */
export function useRenameClusterMutation(
  baseOptions?: Apollo.MutationHookOptions<IRenameClusterMutation, IRenameClusterMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IRenameClusterMutation, IRenameClusterMutationVariables>(RenameClusterDocument, options);
}
export type RenameClusterMutationHookResult = ReturnType<typeof useRenameClusterMutation>;
export type RenameClusterMutationResult = Apollo.MutationResult<IRenameClusterMutation>;
export type RenameClusterMutationOptions = Apollo.BaseMutationOptions<
  IRenameClusterMutation,
  IRenameClusterMutationVariables
>;
export const UpdateAssetGroupDocument = gql`
    mutation UpdateAssetGroup($input: UpdateAssetGroupInput!) {
  UpdateAssetGroup(input: $input) {
    id
    groupName
    clusterName
    updatedAt
    createdAt
    assets {
      id
      icon
      label
      name
      symbol
      type
      exchangeDetails
    }
  }
}
    `;
export type IUpdateAssetGroupMutationFn = Apollo.MutationFunction<
  IUpdateAssetGroupMutation,
  IUpdateAssetGroupMutationVariables
>;

/**
 * __useUpdateAssetGroupMutation__
 *
 * To run a mutation, you first call `useUpdateAssetGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetGroupMutation, { data, loading, error }] = useUpdateAssetGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssetGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdateAssetGroupMutation, IUpdateAssetGroupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IUpdateAssetGroupMutation, IUpdateAssetGroupMutationVariables>(
    UpdateAssetGroupDocument,
    options
  );
}
export type UpdateAssetGroupMutationHookResult = ReturnType<typeof useUpdateAssetGroupMutation>;
export type UpdateAssetGroupMutationResult = Apollo.MutationResult<IUpdateAssetGroupMutation>;
export type UpdateAssetGroupMutationOptions = Apollo.BaseMutationOptions<
  IUpdateAssetGroupMutation,
  IUpdateAssetGroupMutationVariables
>;
export const BlacklistAssetDocument = gql`
    mutation BlacklistAsset($id: UUID!) {
  BlacklistAsset(id: $id)
}
    `;
export type IBlacklistAssetMutationFn = Apollo.MutationFunction<
  IBlacklistAssetMutation,
  IBlacklistAssetMutationVariables
>;

/**
 * __useBlacklistAssetMutation__
 *
 * To run a mutation, you first call `useBlacklistAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlacklistAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blacklistAssetMutation, { data, loading, error }] = useBlacklistAssetMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBlacklistAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<IBlacklistAssetMutation, IBlacklistAssetMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IBlacklistAssetMutation, IBlacklistAssetMutationVariables>(BlacklistAssetDocument, options);
}
export type BlacklistAssetMutationHookResult = ReturnType<typeof useBlacklistAssetMutation>;
export type BlacklistAssetMutationResult = Apollo.MutationResult<IBlacklistAssetMutation>;
export type BlacklistAssetMutationOptions = Apollo.BaseMutationOptions<
  IBlacklistAssetMutation,
  IBlacklistAssetMutationVariables
>;
export const BlacklistedAssetInputDocument = gql`
    query BlacklistedAssetInput {
  assets {
    blacklistedAssets {
      ...AssetLabelFields
    }
  }
}
    ${AssetLabelFieldsFragmentDoc}`;

/**
 * __useBlacklistedAssetInputQuery__
 *
 * To run a query within a React component, call `useBlacklistedAssetInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlacklistedAssetInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlacklistedAssetInputQuery({
 *   variables: {
 *   },
 * });
 */
export function useBlacklistedAssetInputQuery(
  baseOptions?: Apollo.QueryHookOptions<IBlacklistedAssetInputQuery, IBlacklistedAssetInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IBlacklistedAssetInputQuery, IBlacklistedAssetInputQueryVariables>(
    BlacklistedAssetInputDocument,
    options
  );
}
export function useBlacklistedAssetInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IBlacklistedAssetInputQuery, IBlacklistedAssetInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IBlacklistedAssetInputQuery, IBlacklistedAssetInputQueryVariables>(
    BlacklistedAssetInputDocument,
    options
  );
}
export function useBlacklistedAssetInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IBlacklistedAssetInputQuery, IBlacklistedAssetInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IBlacklistedAssetInputQuery, IBlacklistedAssetInputQueryVariables>(
    BlacklistedAssetInputDocument,
    options
  );
}
export type BlacklistedAssetInputQueryHookResult = ReturnType<typeof useBlacklistedAssetInputQuery>;
export type BlacklistedAssetInputLazyQueryHookResult = ReturnType<typeof useBlacklistedAssetInputLazyQuery>;
export type BlacklistedAssetInputSuspenseQueryHookResult = ReturnType<typeof useBlacklistedAssetInputSuspenseQuery>;
export type BlacklistedAssetInputQueryResult = Apollo.QueryResult<
  IBlacklistedAssetInputQuery,
  IBlacklistedAssetInputQueryVariables
>;
export const UnBlacklistAssetDocument = gql`
    mutation UnBlacklistAsset($id: UUID!) {
  UnblacklistAsset(id: $id)
}
    `;
export type IUnBlacklistAssetMutationFn = Apollo.MutationFunction<
  IUnBlacklistAssetMutation,
  IUnBlacklistAssetMutationVariables
>;

/**
 * __useUnBlacklistAssetMutation__
 *
 * To run a mutation, you first call `useUnBlacklistAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnBlacklistAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unBlacklistAssetMutation, { data, loading, error }] = useUnBlacklistAssetMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnBlacklistAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<IUnBlacklistAssetMutation, IUnBlacklistAssetMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IUnBlacklistAssetMutation, IUnBlacklistAssetMutationVariables>(
    UnBlacklistAssetDocument,
    options
  );
}
export type UnBlacklistAssetMutationHookResult = ReturnType<typeof useUnBlacklistAssetMutation>;
export type UnBlacklistAssetMutationResult = Apollo.MutationResult<IUnBlacklistAssetMutation>;
export type UnBlacklistAssetMutationOptions = Apollo.BaseMutationOptions<
  IUnBlacklistAssetMutation,
  IUnBlacklistAssetMutationVariables
>;
export const CreatePrivateAssetDocument = gql`
    mutation CreatePrivateAsset($input: CreatePrivateAssetInput!) {
  CreatePrivateAsset(input: $input) {
    symbol
    id
    name
  }
}
    `;
export type ICreatePrivateAssetMutationFn = Apollo.MutationFunction<
  ICreatePrivateAssetMutation,
  ICreatePrivateAssetMutationVariables
>;

/**
 * __useCreatePrivateAssetMutation__
 *
 * To run a mutation, you first call `useCreatePrivateAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePrivateAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPrivateAssetMutation, { data, loading, error }] = useCreatePrivateAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePrivateAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<ICreatePrivateAssetMutation, ICreatePrivateAssetMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICreatePrivateAssetMutation, ICreatePrivateAssetMutationVariables>(
    CreatePrivateAssetDocument,
    options
  );
}
export type CreatePrivateAssetMutationHookResult = ReturnType<typeof useCreatePrivateAssetMutation>;
export type CreatePrivateAssetMutationResult = Apollo.MutationResult<ICreatePrivateAssetMutation>;
export type CreatePrivateAssetMutationOptions = Apollo.BaseMutationOptions<
  ICreatePrivateAssetMutation,
  ICreatePrivateAssetMutationVariables
>;
export const DeletePrivateAssetDocument = gql`
    mutation DeletePrivateAsset($id: UUID!) {
  DeletePrivateAsset(id: $id)
}
    `;
export type IDeletePrivateAssetMutationFn = Apollo.MutationFunction<
  IDeletePrivateAssetMutation,
  IDeletePrivateAssetMutationVariables
>;

/**
 * __useDeletePrivateAssetMutation__
 *
 * To run a mutation, you first call `useDeletePrivateAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePrivateAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePrivateAssetMutation, { data, loading, error }] = useDeletePrivateAssetMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePrivateAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<IDeletePrivateAssetMutation, IDeletePrivateAssetMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IDeletePrivateAssetMutation, IDeletePrivateAssetMutationVariables>(
    DeletePrivateAssetDocument,
    options
  );
}
export type DeletePrivateAssetMutationHookResult = ReturnType<typeof useDeletePrivateAssetMutation>;
export type DeletePrivateAssetMutationResult = Apollo.MutationResult<IDeletePrivateAssetMutation>;
export type DeletePrivateAssetMutationOptions = Apollo.BaseMutationOptions<
  IDeletePrivateAssetMutation,
  IDeletePrivateAssetMutationVariables
>;
export const PrivateAssetsDocument = gql`
    query PrivateAssets {
  assets {
    list(filters: {assetTypes: [PRIVATE]}) {
      type
      name
      id
      symbol
    }
  }
}
    `;

/**
 * __usePrivateAssetsQuery__
 *
 * To run a query within a React component, call `usePrivateAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrivateAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrivateAssetsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePrivateAssetsQuery(
  baseOptions?: Apollo.QueryHookOptions<IPrivateAssetsQuery, IPrivateAssetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPrivateAssetsQuery, IPrivateAssetsQueryVariables>(PrivateAssetsDocument, options);
}
export function usePrivateAssetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPrivateAssetsQuery, IPrivateAssetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPrivateAssetsQuery, IPrivateAssetsQueryVariables>(PrivateAssetsDocument, options);
}
export function usePrivateAssetsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IPrivateAssetsQuery, IPrivateAssetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IPrivateAssetsQuery, IPrivateAssetsQueryVariables>(PrivateAssetsDocument, options);
}
export type PrivateAssetsQueryHookResult = ReturnType<typeof usePrivateAssetsQuery>;
export type PrivateAssetsLazyQueryHookResult = ReturnType<typeof usePrivateAssetsLazyQuery>;
export type PrivateAssetsSuspenseQueryHookResult = ReturnType<typeof usePrivateAssetsSuspenseQuery>;
export type PrivateAssetsQueryResult = Apollo.QueryResult<IPrivateAssetsQuery, IPrivateAssetsQueryVariables>;
export const UpdatePrivateAssetDocument = gql`
    mutation UpdatePrivateAsset($input: UpdatePrivateAssetInput!) {
  UpdatePrivateAsset(input: $input) {
    name
    id
    symbol
  }
}
    `;
export type IUpdatePrivateAssetMutationFn = Apollo.MutationFunction<
  IUpdatePrivateAssetMutation,
  IUpdatePrivateAssetMutationVariables
>;

/**
 * __useUpdatePrivateAssetMutation__
 *
 * To run a mutation, you first call `useUpdatePrivateAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrivateAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrivateAssetMutation, { data, loading, error }] = useUpdatePrivateAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePrivateAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdatePrivateAssetMutation, IUpdatePrivateAssetMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IUpdatePrivateAssetMutation, IUpdatePrivateAssetMutationVariables>(
    UpdatePrivateAssetDocument,
    options
  );
}
export type UpdatePrivateAssetMutationHookResult = ReturnType<typeof useUpdatePrivateAssetMutation>;
export type UpdatePrivateAssetMutationResult = Apollo.MutationResult<IUpdatePrivateAssetMutation>;
export type UpdatePrivateAssetMutationOptions = Apollo.BaseMutationOptions<
  IUpdatePrivateAssetMutation,
  IUpdatePrivateAssetMutationVariables
>;
export const CreatePublicAssetRequestDocument = gql`
    mutation CreatePublicAssetRequest($input: AddAssetFromCoingeckoIdRequestInputType!) {
  AddAssetFromCoingeckoId(input: $input)
}
    `;
export type ICreatePublicAssetRequestMutationFn = Apollo.MutationFunction<
  ICreatePublicAssetRequestMutation,
  ICreatePublicAssetRequestMutationVariables
>;

/**
 * __useCreatePublicAssetRequestMutation__
 *
 * To run a mutation, you first call `useCreatePublicAssetRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePublicAssetRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPublicAssetRequestMutation, { data, loading, error }] = useCreatePublicAssetRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePublicAssetRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ICreatePublicAssetRequestMutation,
    ICreatePublicAssetRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICreatePublicAssetRequestMutation, ICreatePublicAssetRequestMutationVariables>(
    CreatePublicAssetRequestDocument,
    options
  );
}
export type CreatePublicAssetRequestMutationHookResult = ReturnType<typeof useCreatePublicAssetRequestMutation>;
export type CreatePublicAssetRequestMutationResult = Apollo.MutationResult<ICreatePublicAssetRequestMutation>;
export type CreatePublicAssetRequestMutationOptions = Apollo.BaseMutationOptions<
  ICreatePublicAssetRequestMutation,
  ICreatePublicAssetRequestMutationVariables
>;
export const PublicAssetRequestsDocument = gql`
    query PublicAssetRequests {
  assets {
    publicAssetRequests {
      endTime
      startTime
      status
      symbol
    }
  }
}
    `;

/**
 * __usePublicAssetRequestsQuery__
 *
 * To run a query within a React component, call `usePublicAssetRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicAssetRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicAssetRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePublicAssetRequestsQuery(
  baseOptions?: Apollo.QueryHookOptions<IPublicAssetRequestsQuery, IPublicAssetRequestsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPublicAssetRequestsQuery, IPublicAssetRequestsQueryVariables>(
    PublicAssetRequestsDocument,
    options
  );
}
export function usePublicAssetRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPublicAssetRequestsQuery, IPublicAssetRequestsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPublicAssetRequestsQuery, IPublicAssetRequestsQueryVariables>(
    PublicAssetRequestsDocument,
    options
  );
}
export function usePublicAssetRequestsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IPublicAssetRequestsQuery, IPublicAssetRequestsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IPublicAssetRequestsQuery, IPublicAssetRequestsQueryVariables>(
    PublicAssetRequestsDocument,
    options
  );
}
export type PublicAssetRequestsQueryHookResult = ReturnType<typeof usePublicAssetRequestsQuery>;
export type PublicAssetRequestsLazyQueryHookResult = ReturnType<typeof usePublicAssetRequestsLazyQuery>;
export type PublicAssetRequestsSuspenseQueryHookResult = ReturnType<typeof usePublicAssetRequestsSuspenseQuery>;
export type PublicAssetRequestsQueryResult = Apollo.QueryResult<
  IPublicAssetRequestsQuery,
  IPublicAssetRequestsQueryVariables
>;
export const CalendarEventsDocument = gql`
    query CalendarEvents($assetId: UUID!, $assetTypes: [AssetType!]!) {
  calendar {
    calendarEvents(filters: {assets: {id: $assetId}}) {
      id
      time
      description
      title
    }
  }
  assets {
    list(filters: {assetTypes: $assetTypes}) {
      ...MainAssetFields
    }
  }
}
    ${MainAssetFieldsFragmentDoc}`;

/**
 * __useCalendarEventsQuery__
 *
 * To run a query within a React component, call `useCalendarEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarEventsQuery({
 *   variables: {
 *      assetId: // value for 'assetId'
 *      assetTypes: // value for 'assetTypes'
 *   },
 * });
 */
export function useCalendarEventsQuery(
  baseOptions: Apollo.QueryHookOptions<ICalendarEventsQuery, ICalendarEventsQueryVariables> &
    ({ variables: ICalendarEventsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ICalendarEventsQuery, ICalendarEventsQueryVariables>(CalendarEventsDocument, options);
}
export function useCalendarEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ICalendarEventsQuery, ICalendarEventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ICalendarEventsQuery, ICalendarEventsQueryVariables>(CalendarEventsDocument, options);
}
export function useCalendarEventsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ICalendarEventsQuery, ICalendarEventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ICalendarEventsQuery, ICalendarEventsQueryVariables>(CalendarEventsDocument, options);
}
export type CalendarEventsQueryHookResult = ReturnType<typeof useCalendarEventsQuery>;
export type CalendarEventsLazyQueryHookResult = ReturnType<typeof useCalendarEventsLazyQuery>;
export type CalendarEventsSuspenseQueryHookResult = ReturnType<typeof useCalendarEventsSuspenseQuery>;
export type CalendarEventsQueryResult = Apollo.QueryResult<ICalendarEventsQuery, ICalendarEventsQueryVariables>;
export const CreateCalendarEventDocument = gql`
    mutation CreateCalendarEvent($input: CalendarEventInput!) {
  CreateCalendarEvent(input: $input) {
    id
  }
}
    `;
export type ICreateCalendarEventMutationFn = Apollo.MutationFunction<
  ICreateCalendarEventMutation,
  ICreateCalendarEventMutationVariables
>;

/**
 * __useCreateCalendarEventMutation__
 *
 * To run a mutation, you first call `useCreateCalendarEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCalendarEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCalendarEventMutation, { data, loading, error }] = useCreateCalendarEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCalendarEventMutation(
  baseOptions?: Apollo.MutationHookOptions<ICreateCalendarEventMutation, ICreateCalendarEventMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICreateCalendarEventMutation, ICreateCalendarEventMutationVariables>(
    CreateCalendarEventDocument,
    options
  );
}
export type CreateCalendarEventMutationHookResult = ReturnType<typeof useCreateCalendarEventMutation>;
export type CreateCalendarEventMutationResult = Apollo.MutationResult<ICreateCalendarEventMutation>;
export type CreateCalendarEventMutationOptions = Apollo.BaseMutationOptions<
  ICreateCalendarEventMutation,
  ICreateCalendarEventMutationVariables
>;
export const DeleteCalendarEventDocument = gql`
    mutation DeleteCalendarEvent($id: UUID!) {
  DeleteCalendarEvent(id: $id)
}
    `;
export type IDeleteCalendarEventMutationFn = Apollo.MutationFunction<
  IDeleteCalendarEventMutation,
  IDeleteCalendarEventMutationVariables
>;

/**
 * __useDeleteCalendarEventMutation__
 *
 * To run a mutation, you first call `useDeleteCalendarEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCalendarEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCalendarEventMutation, { data, loading, error }] = useDeleteCalendarEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCalendarEventMutation(
  baseOptions?: Apollo.MutationHookOptions<IDeleteCalendarEventMutation, IDeleteCalendarEventMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IDeleteCalendarEventMutation, IDeleteCalendarEventMutationVariables>(
    DeleteCalendarEventDocument,
    options
  );
}
export type DeleteCalendarEventMutationHookResult = ReturnType<typeof useDeleteCalendarEventMutation>;
export type DeleteCalendarEventMutationResult = Apollo.MutationResult<IDeleteCalendarEventMutation>;
export type DeleteCalendarEventMutationOptions = Apollo.BaseMutationOptions<
  IDeleteCalendarEventMutation,
  IDeleteCalendarEventMutationVariables
>;
export const PrivateAssetPriceDocument = gql`
    query PrivateAssetPrice($filters: ListAssetFilters) {
  assets {
    feature(feature: KNOWN_ASSETS) {
      id
      label
    }
    list(filters: $filters) {
      ...MainAssetFields
      priceAsset {
        id
        symbol
      }
    }
  }
}
    ${MainAssetFieldsFragmentDoc}`;

/**
 * __usePrivateAssetPriceQuery__
 *
 * To run a query within a React component, call `usePrivateAssetPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrivateAssetPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrivateAssetPriceQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePrivateAssetPriceQuery(
  baseOptions?: Apollo.QueryHookOptions<IPrivateAssetPriceQuery, IPrivateAssetPriceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPrivateAssetPriceQuery, IPrivateAssetPriceQueryVariables>(PrivateAssetPriceDocument, options);
}
export function usePrivateAssetPriceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPrivateAssetPriceQuery, IPrivateAssetPriceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPrivateAssetPriceQuery, IPrivateAssetPriceQueryVariables>(
    PrivateAssetPriceDocument,
    options
  );
}
export function usePrivateAssetPriceSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IPrivateAssetPriceQuery, IPrivateAssetPriceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IPrivateAssetPriceQuery, IPrivateAssetPriceQueryVariables>(
    PrivateAssetPriceDocument,
    options
  );
}
export type PrivateAssetPriceQueryHookResult = ReturnType<typeof usePrivateAssetPriceQuery>;
export type PrivateAssetPriceLazyQueryHookResult = ReturnType<typeof usePrivateAssetPriceLazyQuery>;
export type PrivateAssetPriceSuspenseQueryHookResult = ReturnType<typeof usePrivateAssetPriceSuspenseQuery>;
export type PrivateAssetPriceQueryResult = Apollo.QueryResult<
  IPrivateAssetPriceQuery,
  IPrivateAssetPriceQueryVariables
>;
export const SinglePrivateAssetDocument = gql`
    query SinglePrivateAsset($assetId: UUID!) {
  assets {
    pricePerformance: price(assetIds: [$assetId]) {
      rows {
        price
        date
      }
    }
  }
}
    `;

/**
 * __useSinglePrivateAssetQuery__
 *
 * To run a query within a React component, call `useSinglePrivateAssetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSinglePrivateAssetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSinglePrivateAssetQuery({
 *   variables: {
 *      assetId: // value for 'assetId'
 *   },
 * });
 */
export function useSinglePrivateAssetQuery(
  baseOptions: Apollo.QueryHookOptions<ISinglePrivateAssetQuery, ISinglePrivateAssetQueryVariables> &
    ({ variables: ISinglePrivateAssetQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ISinglePrivateAssetQuery, ISinglePrivateAssetQueryVariables>(
    SinglePrivateAssetDocument,
    options
  );
}
export function useSinglePrivateAssetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ISinglePrivateAssetQuery, ISinglePrivateAssetQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ISinglePrivateAssetQuery, ISinglePrivateAssetQueryVariables>(
    SinglePrivateAssetDocument,
    options
  );
}
export function useSinglePrivateAssetSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ISinglePrivateAssetQuery, ISinglePrivateAssetQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ISinglePrivateAssetQuery, ISinglePrivateAssetQueryVariables>(
    SinglePrivateAssetDocument,
    options
  );
}
export type SinglePrivateAssetQueryHookResult = ReturnType<typeof useSinglePrivateAssetQuery>;
export type SinglePrivateAssetLazyQueryHookResult = ReturnType<typeof useSinglePrivateAssetLazyQuery>;
export type SinglePrivateAssetSuspenseQueryHookResult = ReturnType<typeof useSinglePrivateAssetSuspenseQuery>;
export type SinglePrivateAssetQueryResult = Apollo.QueryResult<
  ISinglePrivateAssetQuery,
  ISinglePrivateAssetQueryVariables
>;
export const AssetPriceChartDocument = gql`
    query AssetPriceChart($assetIds: [UUID!]!) {
  assets {
    price(assetIds: $assetIds, resolutionDays: 1) {
      asset {
        id
        name
        symbol
      }
      rows {
        date
        price
      }
    }
  }
}
    `;

/**
 * __useAssetPriceChartQuery__
 *
 * To run a query within a React component, call `useAssetPriceChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetPriceChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetPriceChartQuery({
 *   variables: {
 *      assetIds: // value for 'assetIds'
 *   },
 * });
 */
export function useAssetPriceChartQuery(
  baseOptions: Apollo.QueryHookOptions<IAssetPriceChartQuery, IAssetPriceChartQueryVariables> &
    ({ variables: IAssetPriceChartQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IAssetPriceChartQuery, IAssetPriceChartQueryVariables>(AssetPriceChartDocument, options);
}
export function useAssetPriceChartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAssetPriceChartQuery, IAssetPriceChartQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IAssetPriceChartQuery, IAssetPriceChartQueryVariables>(AssetPriceChartDocument, options);
}
export function useAssetPriceChartSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IAssetPriceChartQuery, IAssetPriceChartQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IAssetPriceChartQuery, IAssetPriceChartQueryVariables>(
    AssetPriceChartDocument,
    options
  );
}
export type AssetPriceChartQueryHookResult = ReturnType<typeof useAssetPriceChartQuery>;
export type AssetPriceChartLazyQueryHookResult = ReturnType<typeof useAssetPriceChartLazyQuery>;
export type AssetPriceChartSuspenseQueryHookResult = ReturnType<typeof useAssetPriceChartSuspenseQuery>;
export type AssetPriceChartQueryResult = Apollo.QueryResult<IAssetPriceChartQuery, IAssetPriceChartQueryVariables>;
export const CreateUpdateAssetPriceDocument = gql`
    mutation CreateUpdateAssetPrice($input: AssetPriceInput!) {
  UpdateAssetPrice(input: $input)
}
    `;
export type ICreateUpdateAssetPriceMutationFn = Apollo.MutationFunction<
  ICreateUpdateAssetPriceMutation,
  ICreateUpdateAssetPriceMutationVariables
>;

/**
 * __useCreateUpdateAssetPriceMutation__
 *
 * To run a mutation, you first call `useCreateUpdateAssetPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateAssetPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateAssetPriceMutation, { data, loading, error }] = useCreateUpdateAssetPriceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdateAssetPriceMutation(
  baseOptions?: Apollo.MutationHookOptions<ICreateUpdateAssetPriceMutation, ICreateUpdateAssetPriceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICreateUpdateAssetPriceMutation, ICreateUpdateAssetPriceMutationVariables>(
    CreateUpdateAssetPriceDocument,
    options
  );
}
export type CreateUpdateAssetPriceMutationHookResult = ReturnType<typeof useCreateUpdateAssetPriceMutation>;
export type CreateUpdateAssetPriceMutationResult = Apollo.MutationResult<ICreateUpdateAssetPriceMutation>;
export type CreateUpdateAssetPriceMutationOptions = Apollo.BaseMutationOptions<
  ICreateUpdateAssetPriceMutation,
  ICreateUpdateAssetPriceMutationVariables
>;
export const CreateUpdateAssetPricesDocument = gql`
    mutation CreateUpdateAssetPrices($input: AssetPriceBulkInput!) {
  UpdateAssetPriceBulk(input: $input)
}
    `;
export type ICreateUpdateAssetPricesMutationFn = Apollo.MutationFunction<
  ICreateUpdateAssetPricesMutation,
  ICreateUpdateAssetPricesMutationVariables
>;

/**
 * __useCreateUpdateAssetPricesMutation__
 *
 * To run a mutation, you first call `useCreateUpdateAssetPricesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateAssetPricesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateAssetPricesMutation, { data, loading, error }] = useCreateUpdateAssetPricesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdateAssetPricesMutation(
  baseOptions?: Apollo.MutationHookOptions<ICreateUpdateAssetPricesMutation, ICreateUpdateAssetPricesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICreateUpdateAssetPricesMutation, ICreateUpdateAssetPricesMutationVariables>(
    CreateUpdateAssetPricesDocument,
    options
  );
}
export type CreateUpdateAssetPricesMutationHookResult = ReturnType<typeof useCreateUpdateAssetPricesMutation>;
export type CreateUpdateAssetPricesMutationResult = Apollo.MutationResult<ICreateUpdateAssetPricesMutation>;
export type CreateUpdateAssetPricesMutationOptions = Apollo.BaseMutationOptions<
  ICreateUpdateAssetPricesMutation,
  ICreateUpdateAssetPricesMutationVariables
>;
export const DeleteAssetPriceDocument = gql`
    mutation DeleteAssetPrice($input: AssetPriceIdInput!) {
  DeleteAssetPrice(input: $input)
}
    `;
export type IDeleteAssetPriceMutationFn = Apollo.MutationFunction<
  IDeleteAssetPriceMutation,
  IDeleteAssetPriceMutationVariables
>;

/**
 * __useDeleteAssetPriceMutation__
 *
 * To run a mutation, you first call `useDeleteAssetPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssetPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssetPriceMutation, { data, loading, error }] = useDeleteAssetPriceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAssetPriceMutation(
  baseOptions?: Apollo.MutationHookOptions<IDeleteAssetPriceMutation, IDeleteAssetPriceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IDeleteAssetPriceMutation, IDeleteAssetPriceMutationVariables>(
    DeleteAssetPriceDocument,
    options
  );
}
export type DeleteAssetPriceMutationHookResult = ReturnType<typeof useDeleteAssetPriceMutation>;
export type DeleteAssetPriceMutationResult = Apollo.MutationResult<IDeleteAssetPriceMutation>;
export type DeleteAssetPriceMutationOptions = Apollo.BaseMutationOptions<
  IDeleteAssetPriceMutation,
  IDeleteAssetPriceMutationVariables
>;
export const AssetVestingDocument = gql`
    query AssetVesting($assetId: UUID!) {
  bookkeeping {
    investments {
      assetBalance(assetId: $assetId) {
        investments {
          balance {
            time
            amount
          }
          investment {
            id
            name
          }
        }
      }
      assetDetails(assetId: $assetId) {
        unvested
        vested
      }
    }
  }
}
    `;

/**
 * __useAssetVestingQuery__
 *
 * To run a query within a React component, call `useAssetVestingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetVestingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetVestingQuery({
 *   variables: {
 *      assetId: // value for 'assetId'
 *   },
 * });
 */
export function useAssetVestingQuery(
  baseOptions: Apollo.QueryHookOptions<IAssetVestingQuery, IAssetVestingQueryVariables> &
    ({ variables: IAssetVestingQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IAssetVestingQuery, IAssetVestingQueryVariables>(AssetVestingDocument, options);
}
export function useAssetVestingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAssetVestingQuery, IAssetVestingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IAssetVestingQuery, IAssetVestingQueryVariables>(AssetVestingDocument, options);
}
export function useAssetVestingSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IAssetVestingQuery, IAssetVestingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IAssetVestingQuery, IAssetVestingQueryVariables>(AssetVestingDocument, options);
}
export type AssetVestingQueryHookResult = ReturnType<typeof useAssetVestingQuery>;
export type AssetVestingLazyQueryHookResult = ReturnType<typeof useAssetVestingLazyQuery>;
export type AssetVestingSuspenseQueryHookResult = ReturnType<typeof useAssetVestingSuspenseQuery>;
export type AssetVestingQueryResult = Apollo.QueryResult<IAssetVestingQuery, IAssetVestingQueryVariables>;
export const AssetMetricsDocument = gql`
    query AssetMetrics($assetId: UUID!, $filters: AssetMetricHistoryFilters) {
  assets {
    metricHistory(assetIds: [$assetId], filters: $filters) {
      rows {
        date
        metrics
      }
    }
  }
}
    `;

/**
 * __useAssetMetricsQuery__
 *
 * To run a query within a React component, call `useAssetMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetMetricsQuery({
 *   variables: {
 *      assetId: // value for 'assetId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAssetMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<IAssetMetricsQuery, IAssetMetricsQueryVariables> &
    ({ variables: IAssetMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IAssetMetricsQuery, IAssetMetricsQueryVariables>(AssetMetricsDocument, options);
}
export function useAssetMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAssetMetricsQuery, IAssetMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IAssetMetricsQuery, IAssetMetricsQueryVariables>(AssetMetricsDocument, options);
}
export function useAssetMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IAssetMetricsQuery, IAssetMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IAssetMetricsQuery, IAssetMetricsQueryVariables>(AssetMetricsDocument, options);
}
export type AssetMetricsQueryHookResult = ReturnType<typeof useAssetMetricsQuery>;
export type AssetMetricsLazyQueryHookResult = ReturnType<typeof useAssetMetricsLazyQuery>;
export type AssetMetricsSuspenseQueryHookResult = ReturnType<typeof useAssetMetricsSuspenseQuery>;
export type AssetMetricsQueryResult = Apollo.QueryResult<IAssetMetricsQuery, IAssetMetricsQueryVariables>;
export const AssetPerformanceDocument = gql`
    query AssetPerformance($assetId: UUID!, $fromDate: Date!) {
  assets {
    pricePerformance: price(
      assetIds: [$assetId]
      fromDate: $fromDate
      resolutionDays: 7
    ) {
      rows {
        price
        date
      }
    }
    metricPerformance: metricHistory(
      assetIds: [$assetId]
      filters: {date: {since: $fromDate}, resolutionDays: 7, metricLabels: ["met:market_cap", "met:total_val_locked", "met:volume-24h", "met:liquidity"]}
    ) {
      rows {
        metrics
        date
      }
    }
  }
}
    `;

/**
 * __useAssetPerformanceQuery__
 *
 * To run a query within a React component, call `useAssetPerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetPerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetPerformanceQuery({
 *   variables: {
 *      assetId: // value for 'assetId'
 *      fromDate: // value for 'fromDate'
 *   },
 * });
 */
export function useAssetPerformanceQuery(
  baseOptions: Apollo.QueryHookOptions<IAssetPerformanceQuery, IAssetPerformanceQueryVariables> &
    ({ variables: IAssetPerformanceQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IAssetPerformanceQuery, IAssetPerformanceQueryVariables>(AssetPerformanceDocument, options);
}
export function useAssetPerformanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAssetPerformanceQuery, IAssetPerformanceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IAssetPerformanceQuery, IAssetPerformanceQueryVariables>(
    AssetPerformanceDocument,
    options
  );
}
export function useAssetPerformanceSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IAssetPerformanceQuery, IAssetPerformanceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IAssetPerformanceQuery, IAssetPerformanceQueryVariables>(
    AssetPerformanceDocument,
    options
  );
}
export type AssetPerformanceQueryHookResult = ReturnType<typeof useAssetPerformanceQuery>;
export type AssetPerformanceLazyQueryHookResult = ReturnType<typeof useAssetPerformanceLazyQuery>;
export type AssetPerformanceSuspenseQueryHookResult = ReturnType<typeof useAssetPerformanceSuspenseQuery>;
export type AssetPerformanceQueryResult = Apollo.QueryResult<IAssetPerformanceQuery, IAssetPerformanceQueryVariables>;
export const AllDerivativesDocument = gql`
    query AllDerivatives($filters: ListAssetFilters) {
  assets {
    list(filters: $filters) {
      ...MainAssetFields
    }
  }
}
    ${MainAssetFieldsFragmentDoc}`;

/**
 * __useAllDerivativesQuery__
 *
 * To run a query within a React component, call `useAllDerivativesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllDerivativesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllDerivativesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAllDerivativesQuery(
  baseOptions?: Apollo.QueryHookOptions<IAllDerivativesQuery, IAllDerivativesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IAllDerivativesQuery, IAllDerivativesQueryVariables>(AllDerivativesDocument, options);
}
export function useAllDerivativesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAllDerivativesQuery, IAllDerivativesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IAllDerivativesQuery, IAllDerivativesQueryVariables>(AllDerivativesDocument, options);
}
export function useAllDerivativesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IAllDerivativesQuery, IAllDerivativesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IAllDerivativesQuery, IAllDerivativesQueryVariables>(AllDerivativesDocument, options);
}
export type AllDerivativesQueryHookResult = ReturnType<typeof useAllDerivativesQuery>;
export type AllDerivativesLazyQueryHookResult = ReturnType<typeof useAllDerivativesLazyQuery>;
export type AllDerivativesSuspenseQueryHookResult = ReturnType<typeof useAllDerivativesSuspenseQuery>;
export type AllDerivativesQueryResult = Apollo.QueryResult<IAllDerivativesQuery, IAllDerivativesQueryVariables>;
export const DerivativeDetailsDocument = gql`
    query DerivativeDetails($filters: AssetFilters!) {
  assets {
    details(filters: $filters) {
      metrics
      asset
      price
      minPrice
      maxPrice
    }
    changes(filters: $filters) {
      metrics
      asset
      price
    }
  }
}
    `;

/**
 * __useDerivativeDetailsQuery__
 *
 * To run a query within a React component, call `useDerivativeDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDerivativeDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDerivativeDetailsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useDerivativeDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<IDerivativeDetailsQuery, IDerivativeDetailsQueryVariables> &
    ({ variables: IDerivativeDetailsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IDerivativeDetailsQuery, IDerivativeDetailsQueryVariables>(DerivativeDetailsDocument, options);
}
export function useDerivativeDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IDerivativeDetailsQuery, IDerivativeDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IDerivativeDetailsQuery, IDerivativeDetailsQueryVariables>(
    DerivativeDetailsDocument,
    options
  );
}
export function useDerivativeDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IDerivativeDetailsQuery, IDerivativeDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IDerivativeDetailsQuery, IDerivativeDetailsQueryVariables>(
    DerivativeDetailsDocument,
    options
  );
}
export type DerivativeDetailsQueryHookResult = ReturnType<typeof useDerivativeDetailsQuery>;
export type DerivativeDetailsLazyQueryHookResult = ReturnType<typeof useDerivativeDetailsLazyQuery>;
export type DerivativeDetailsSuspenseQueryHookResult = ReturnType<typeof useDerivativeDetailsSuspenseQuery>;
export type DerivativeDetailsQueryResult = Apollo.QueryResult<
  IDerivativeDetailsQuery,
  IDerivativeDetailsQueryVariables
>;
export const NewsBulletPointsDocument = gql`
    query NewsBulletPoints($paging: PageLimitOffsetInput!, $filters: BulletPointFilters) {
  news {
    bulletPoints {
      bulletPoints(paging: $paging, filters: $filters) {
        pageInfo {
          totalResults
        }
        data {
          asset {
            ...MainAssetFields
          }
          id
          publishedAt
          url
          summary
          isTrending
          sentiment
          reaction
          newsFeed {
            id
            humanName
            url
          }
          title
        }
      }
    }
  }
}
    ${MainAssetFieldsFragmentDoc}`;

/**
 * __useNewsBulletPointsQuery__
 *
 * To run a query within a React component, call `useNewsBulletPointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsBulletPointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsBulletPointsQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useNewsBulletPointsQuery(
  baseOptions: Apollo.QueryHookOptions<INewsBulletPointsQuery, INewsBulletPointsQueryVariables> &
    ({ variables: INewsBulletPointsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<INewsBulletPointsQuery, INewsBulletPointsQueryVariables>(NewsBulletPointsDocument, options);
}
export function useNewsBulletPointsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<INewsBulletPointsQuery, INewsBulletPointsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<INewsBulletPointsQuery, INewsBulletPointsQueryVariables>(
    NewsBulletPointsDocument,
    options
  );
}
export function useNewsBulletPointsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<INewsBulletPointsQuery, INewsBulletPointsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<INewsBulletPointsQuery, INewsBulletPointsQueryVariables>(
    NewsBulletPointsDocument,
    options
  );
}
export type NewsBulletPointsQueryHookResult = ReturnType<typeof useNewsBulletPointsQuery>;
export type NewsBulletPointsLazyQueryHookResult = ReturnType<typeof useNewsBulletPointsLazyQuery>;
export type NewsBulletPointsSuspenseQueryHookResult = ReturnType<typeof useNewsBulletPointsSuspenseQuery>;
export type NewsBulletPointsQueryResult = Apollo.QueryResult<INewsBulletPointsQuery, INewsBulletPointsQueryVariables>;
export const ExchangesDocument = gql`
    query Exchanges {
  assets {
    options {
      exchanges {
        label
      }
    }
  }
}
    `;

/**
 * __useExchangesQuery__
 *
 * To run a query within a React component, call `useExchangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExchangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExchangesQuery({
 *   variables: {
 *   },
 * });
 */
export function useExchangesQuery(baseOptions?: Apollo.QueryHookOptions<IExchangesQuery, IExchangesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IExchangesQuery, IExchangesQueryVariables>(ExchangesDocument, options);
}
export function useExchangesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IExchangesQuery, IExchangesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IExchangesQuery, IExchangesQueryVariables>(ExchangesDocument, options);
}
export function useExchangesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IExchangesQuery, IExchangesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IExchangesQuery, IExchangesQueryVariables>(ExchangesDocument, options);
}
export type ExchangesQueryHookResult = ReturnType<typeof useExchangesQuery>;
export type ExchangesLazyQueryHookResult = ReturnType<typeof useExchangesLazyQuery>;
export type ExchangesSuspenseQueryHookResult = ReturnType<typeof useExchangesSuspenseQuery>;
export type ExchangesQueryResult = Apollo.QueryResult<IExchangesQuery, IExchangesQueryVariables>;
export const OptionsDocument = gql`
    query Options($exchanges: [LabelType!], $expirationSince: ArrowType, $baseAssetId: UUID!) {
  assets {
    options {
      pricedOptions(
        exchanges: $exchanges
        expirationSince: $expirationSince
        baseAssetId: $baseAssetId
      ) {
        option {
          id
          symbol
          derivativeDetails {
            derivativeType
            expirationDate
            strikePrice
            optionType
            exchange
            settlementAsset {
              symbol
            }
            baseAsset {
              symbol
            }
            quoteAsset {
              symbol
            }
          }
        }
        askFactors {
          price
          iv
          greeks {
            delta
            gamma
            rho
            theta
            vega
          }
        }
        bidFactors {
          price
          iv
          greeks {
            delta
            gamma
            rho
            theta
            vega
          }
        }
        markFactors {
          price
          iv
          greeks {
            delta
            gamma
            rho
            theta
            vega
          }
        }
        underlyingPrice
        time
        openInterest
      }
    }
  }
}
    `;

/**
 * __useOptionsQuery__
 *
 * To run a query within a React component, call `useOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptionsQuery({
 *   variables: {
 *      exchanges: // value for 'exchanges'
 *      expirationSince: // value for 'expirationSince'
 *      baseAssetId: // value for 'baseAssetId'
 *   },
 * });
 */
export function useOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<IOptionsQuery, IOptionsQueryVariables> &
    ({ variables: IOptionsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IOptionsQuery, IOptionsQueryVariables>(OptionsDocument, options);
}
export function useOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IOptionsQuery, IOptionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IOptionsQuery, IOptionsQueryVariables>(OptionsDocument, options);
}
export function useOptionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IOptionsQuery, IOptionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IOptionsQuery, IOptionsQueryVariables>(OptionsDocument, options);
}
export type OptionsQueryHookResult = ReturnType<typeof useOptionsQuery>;
export type OptionsLazyQueryHookResult = ReturnType<typeof useOptionsLazyQuery>;
export type OptionsSuspenseQueryHookResult = ReturnType<typeof useOptionsSuspenseQuery>;
export type OptionsQueryResult = Apollo.QueryResult<IOptionsQuery, IOptionsQueryVariables>;
export const PublicAssetForecastChartDocument = gql`
    query PublicAssetForecastChart($assetId: UUID!, $asset: LabelType!, $fromDate: Date) {
  assets {
    forecast(asset: $asset) {
      date
      value
      highConfidence
      lowConfidence
    }
    price(assetIds: [$assetId], fromDate: $fromDate, resolutionDays: 1) {
      rows {
        date
        price
      }
    }
  }
}
    `;

/**
 * __usePublicAssetForecastChartQuery__
 *
 * To run a query within a React component, call `usePublicAssetForecastChartQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicAssetForecastChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicAssetForecastChartQuery({
 *   variables: {
 *      assetId: // value for 'assetId'
 *      asset: // value for 'asset'
 *      fromDate: // value for 'fromDate'
 *   },
 * });
 */
export function usePublicAssetForecastChartQuery(
  baseOptions: Apollo.QueryHookOptions<IPublicAssetForecastChartQuery, IPublicAssetForecastChartQueryVariables> &
    ({ variables: IPublicAssetForecastChartQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPublicAssetForecastChartQuery, IPublicAssetForecastChartQueryVariables>(
    PublicAssetForecastChartDocument,
    options
  );
}
export function usePublicAssetForecastChartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPublicAssetForecastChartQuery, IPublicAssetForecastChartQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPublicAssetForecastChartQuery, IPublicAssetForecastChartQueryVariables>(
    PublicAssetForecastChartDocument,
    options
  );
}
export function usePublicAssetForecastChartSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IPublicAssetForecastChartQuery, IPublicAssetForecastChartQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IPublicAssetForecastChartQuery, IPublicAssetForecastChartQueryVariables>(
    PublicAssetForecastChartDocument,
    options
  );
}
export type PublicAssetForecastChartQueryHookResult = ReturnType<typeof usePublicAssetForecastChartQuery>;
export type PublicAssetForecastChartLazyQueryHookResult = ReturnType<typeof usePublicAssetForecastChartLazyQuery>;
export type PublicAssetForecastChartSuspenseQueryHookResult = ReturnType<
  typeof usePublicAssetForecastChartSuspenseQuery
>;
export type PublicAssetForecastChartQueryResult = Apollo.QueryResult<
  IPublicAssetForecastChartQuery,
  IPublicAssetForecastChartQueryVariables
>;
export const PublicAssetHoldingsDocument = gql`
    query PublicAssetHoldings($assetId: UUID!, $fromTime: Date) {
  portfolio {
    snapshot(
      filters: {date: {since: $fromTime}, subAccountAssetFilters: {assetIds: [$assetId]}}
    ) {
      date
      positions {
        spot {
          price
          amount
          balance
        }
        asset {
          id
          symbol
        }
        subAccount {
          name
          account {
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePublicAssetHoldingsQuery__
 *
 * To run a query within a React component, call `usePublicAssetHoldingsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicAssetHoldingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicAssetHoldingsQuery({
 *   variables: {
 *      assetId: // value for 'assetId'
 *      fromTime: // value for 'fromTime'
 *   },
 * });
 */
export function usePublicAssetHoldingsQuery(
  baseOptions: Apollo.QueryHookOptions<IPublicAssetHoldingsQuery, IPublicAssetHoldingsQueryVariables> &
    ({ variables: IPublicAssetHoldingsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPublicAssetHoldingsQuery, IPublicAssetHoldingsQueryVariables>(
    PublicAssetHoldingsDocument,
    options
  );
}
export function usePublicAssetHoldingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPublicAssetHoldingsQuery, IPublicAssetHoldingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPublicAssetHoldingsQuery, IPublicAssetHoldingsQueryVariables>(
    PublicAssetHoldingsDocument,
    options
  );
}
export function usePublicAssetHoldingsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IPublicAssetHoldingsQuery, IPublicAssetHoldingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IPublicAssetHoldingsQuery, IPublicAssetHoldingsQueryVariables>(
    PublicAssetHoldingsDocument,
    options
  );
}
export type PublicAssetHoldingsQueryHookResult = ReturnType<typeof usePublicAssetHoldingsQuery>;
export type PublicAssetHoldingsLazyQueryHookResult = ReturnType<typeof usePublicAssetHoldingsLazyQuery>;
export type PublicAssetHoldingsSuspenseQueryHookResult = ReturnType<typeof usePublicAssetHoldingsSuspenseQuery>;
export type PublicAssetHoldingsQueryResult = Apollo.QueryResult<
  IPublicAssetHoldingsQuery,
  IPublicAssetHoldingsQueryVariables
>;
export const PublicAssetSpotDocument = gql`
    query PublicAssetSpot($assetId: UUID!) {
  assets {
    list(filters: {assetIds: [$assetId]}) {
      ...MainAssetFields
    }
    availableMetrics(assetId: $assetId) {
      label
    }
    feature(feature: ASSETS_CORRELATION) {
      ...AssetLabelFields
    }
  }
}
    ${MainAssetFieldsFragmentDoc}
${AssetLabelFieldsFragmentDoc}`;

/**
 * __usePublicAssetSpotQuery__
 *
 * To run a query within a React component, call `usePublicAssetSpotQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicAssetSpotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicAssetSpotQuery({
 *   variables: {
 *      assetId: // value for 'assetId'
 *   },
 * });
 */
export function usePublicAssetSpotQuery(
  baseOptions: Apollo.QueryHookOptions<IPublicAssetSpotQuery, IPublicAssetSpotQueryVariables> &
    ({ variables: IPublicAssetSpotQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPublicAssetSpotQuery, IPublicAssetSpotQueryVariables>(PublicAssetSpotDocument, options);
}
export function usePublicAssetSpotLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPublicAssetSpotQuery, IPublicAssetSpotQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPublicAssetSpotQuery, IPublicAssetSpotQueryVariables>(PublicAssetSpotDocument, options);
}
export function usePublicAssetSpotSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IPublicAssetSpotQuery, IPublicAssetSpotQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IPublicAssetSpotQuery, IPublicAssetSpotQueryVariables>(
    PublicAssetSpotDocument,
    options
  );
}
export type PublicAssetSpotQueryHookResult = ReturnType<typeof usePublicAssetSpotQuery>;
export type PublicAssetSpotLazyQueryHookResult = ReturnType<typeof usePublicAssetSpotLazyQuery>;
export type PublicAssetSpotSuspenseQueryHookResult = ReturnType<typeof usePublicAssetSpotSuspenseQuery>;
export type PublicAssetSpotQueryResult = Apollo.QueryResult<IPublicAssetSpotQuery, IPublicAssetSpotQueryVariables>;
export const SinglePublicAssetDocument = gql`
    query SinglePublicAsset($asset: UUID!) {
  assets {
    details(filters: {assets: [$asset]}) {
      asset
      metrics
      price
      maxPrice
      minPrice
    }
    changes(filters: {assets: [$asset]}) {
      asset
      price
      metrics
    }
    assetGroups {
      genieGroups {
        clusterName
        groupName
        assets {
          ...MainAssetFields
        }
      }
    }
  }
}
    ${MainAssetFieldsFragmentDoc}`;

/**
 * __useSinglePublicAssetQuery__
 *
 * To run a query within a React component, call `useSinglePublicAssetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSinglePublicAssetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSinglePublicAssetQuery({
 *   variables: {
 *      asset: // value for 'asset'
 *   },
 * });
 */
export function useSinglePublicAssetQuery(
  baseOptions: Apollo.QueryHookOptions<ISinglePublicAssetQuery, ISinglePublicAssetQueryVariables> &
    ({ variables: ISinglePublicAssetQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ISinglePublicAssetQuery, ISinglePublicAssetQueryVariables>(SinglePublicAssetDocument, options);
}
export function useSinglePublicAssetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ISinglePublicAssetQuery, ISinglePublicAssetQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ISinglePublicAssetQuery, ISinglePublicAssetQueryVariables>(
    SinglePublicAssetDocument,
    options
  );
}
export function useSinglePublicAssetSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ISinglePublicAssetQuery, ISinglePublicAssetQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ISinglePublicAssetQuery, ISinglePublicAssetQueryVariables>(
    SinglePublicAssetDocument,
    options
  );
}
export type SinglePublicAssetQueryHookResult = ReturnType<typeof useSinglePublicAssetQuery>;
export type SinglePublicAssetLazyQueryHookResult = ReturnType<typeof useSinglePublicAssetLazyQuery>;
export type SinglePublicAssetSuspenseQueryHookResult = ReturnType<typeof useSinglePublicAssetSuspenseQuery>;
export type SinglePublicAssetQueryResult = Apollo.QueryResult<
  ISinglePublicAssetQuery,
  ISinglePublicAssetQueryVariables
>;
export const UpdateBulletPointReactionDocument = gql`
    mutation UpdateBulletPointReaction($bulletPointReactionInput: BulletPointReactionInput!) {
  UpdateBulletPointReaction(bulletPointReactionInput: $bulletPointReactionInput)
}
    `;
export type IUpdateBulletPointReactionMutationFn = Apollo.MutationFunction<
  IUpdateBulletPointReactionMutation,
  IUpdateBulletPointReactionMutationVariables
>;

/**
 * __useUpdateBulletPointReactionMutation__
 *
 * To run a mutation, you first call `useUpdateBulletPointReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBulletPointReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBulletPointReactionMutation, { data, loading, error }] = useUpdateBulletPointReactionMutation({
 *   variables: {
 *      bulletPointReactionInput: // value for 'bulletPointReactionInput'
 *   },
 * });
 */
export function useUpdateBulletPointReactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdateBulletPointReactionMutation,
    IUpdateBulletPointReactionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IUpdateBulletPointReactionMutation, IUpdateBulletPointReactionMutationVariables>(
    UpdateBulletPointReactionDocument,
    options
  );
}
export type UpdateBulletPointReactionMutationHookResult = ReturnType<typeof useUpdateBulletPointReactionMutation>;
export type UpdateBulletPointReactionMutationResult = Apollo.MutationResult<IUpdateBulletPointReactionMutation>;
export type UpdateBulletPointReactionMutationOptions = Apollo.BaseMutationOptions<
  IUpdateBulletPointReactionMutation,
  IUpdateBulletPointReactionMutationVariables
>;
export const MarketRegimeDocument = gql`
    query MarketRegime($modelIds: [UUID!]!, $since: Date, $to: Date) {
  regime {
    historicalPredictions(modelIds: $modelIds, since: $since, until: $to) {
      model {
        name
        id
      }
      timeseries {
        date
        values {
          name
          value
        }
      }
    }
  }
}
    `;

/**
 * __useMarketRegimeQuery__
 *
 * To run a query within a React component, call `useMarketRegimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketRegimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketRegimeQuery({
 *   variables: {
 *      modelIds: // value for 'modelIds'
 *      since: // value for 'since'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useMarketRegimeQuery(
  baseOptions: Apollo.QueryHookOptions<IMarketRegimeQuery, IMarketRegimeQueryVariables> &
    ({ variables: IMarketRegimeQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IMarketRegimeQuery, IMarketRegimeQueryVariables>(MarketRegimeDocument, options);
}
export function useMarketRegimeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IMarketRegimeQuery, IMarketRegimeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IMarketRegimeQuery, IMarketRegimeQueryVariables>(MarketRegimeDocument, options);
}
export function useMarketRegimeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IMarketRegimeQuery, IMarketRegimeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IMarketRegimeQuery, IMarketRegimeQueryVariables>(MarketRegimeDocument, options);
}
export type MarketRegimeQueryHookResult = ReturnType<typeof useMarketRegimeQuery>;
export type MarketRegimeLazyQueryHookResult = ReturnType<typeof useMarketRegimeLazyQuery>;
export type MarketRegimeSuspenseQueryHookResult = ReturnType<typeof useMarketRegimeSuspenseQuery>;
export type MarketRegimeQueryResult = Apollo.QueryResult<IMarketRegimeQuery, IMarketRegimeQueryVariables>;
export const MarketRegimeBenchmarkPriceDocument = gql`
    query MarketRegimeBenchmarkPrice($assetIds: [UUID!]!, $since: Date!, $to: Date!) {
  assets {
    price(assetIds: $assetIds, resolutionDays: 1, fromDate: $since, toDate: $to) {
      asset {
        id
        symbol
      }
      rows {
        date
        price
      }
    }
  }
}
    `;

/**
 * __useMarketRegimeBenchmarkPriceQuery__
 *
 * To run a query within a React component, call `useMarketRegimeBenchmarkPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketRegimeBenchmarkPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketRegimeBenchmarkPriceQuery({
 *   variables: {
 *      assetIds: // value for 'assetIds'
 *      since: // value for 'since'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useMarketRegimeBenchmarkPriceQuery(
  baseOptions: Apollo.QueryHookOptions<IMarketRegimeBenchmarkPriceQuery, IMarketRegimeBenchmarkPriceQueryVariables> &
    ({ variables: IMarketRegimeBenchmarkPriceQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IMarketRegimeBenchmarkPriceQuery, IMarketRegimeBenchmarkPriceQueryVariables>(
    MarketRegimeBenchmarkPriceDocument,
    options
  );
}
export function useMarketRegimeBenchmarkPriceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IMarketRegimeBenchmarkPriceQuery, IMarketRegimeBenchmarkPriceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IMarketRegimeBenchmarkPriceQuery, IMarketRegimeBenchmarkPriceQueryVariables>(
    MarketRegimeBenchmarkPriceDocument,
    options
  );
}
export function useMarketRegimeBenchmarkPriceSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IMarketRegimeBenchmarkPriceQuery,
    IMarketRegimeBenchmarkPriceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IMarketRegimeBenchmarkPriceQuery, IMarketRegimeBenchmarkPriceQueryVariables>(
    MarketRegimeBenchmarkPriceDocument,
    options
  );
}
export type MarketRegimeBenchmarkPriceQueryHookResult = ReturnType<typeof useMarketRegimeBenchmarkPriceQuery>;
export type MarketRegimeBenchmarkPriceLazyQueryHookResult = ReturnType<typeof useMarketRegimeBenchmarkPriceLazyQuery>;
export type MarketRegimeBenchmarkPriceSuspenseQueryHookResult = ReturnType<
  typeof useMarketRegimeBenchmarkPriceSuspenseQuery
>;
export type MarketRegimeBenchmarkPriceQueryResult = Apollo.QueryResult<
  IMarketRegimeBenchmarkPriceQuery,
  IMarketRegimeBenchmarkPriceQueryVariables
>;
export const MarketRegimeInputDocument = gql`
    query MarketRegimeInput {
  assets {
    list(filters: {assetTypes: [PUBLIC]}) {
      ...AssetLabelFields
    }
  }
  portfolio {
    supportedRegimeDefinitions {
      id
      name
      benchmark {
        id
        name
        symbol
      }
    }
  }
}
    ${AssetLabelFieldsFragmentDoc}`;

/**
 * __useMarketRegimeInputQuery__
 *
 * To run a query within a React component, call `useMarketRegimeInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketRegimeInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketRegimeInputQuery({
 *   variables: {
 *   },
 * });
 */
export function useMarketRegimeInputQuery(
  baseOptions?: Apollo.QueryHookOptions<IMarketRegimeInputQuery, IMarketRegimeInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IMarketRegimeInputQuery, IMarketRegimeInputQueryVariables>(MarketRegimeInputDocument, options);
}
export function useMarketRegimeInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IMarketRegimeInputQuery, IMarketRegimeInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IMarketRegimeInputQuery, IMarketRegimeInputQueryVariables>(
    MarketRegimeInputDocument,
    options
  );
}
export function useMarketRegimeInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IMarketRegimeInputQuery, IMarketRegimeInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IMarketRegimeInputQuery, IMarketRegimeInputQueryVariables>(
    MarketRegimeInputDocument,
    options
  );
}
export type MarketRegimeInputQueryHookResult = ReturnType<typeof useMarketRegimeInputQuery>;
export type MarketRegimeInputLazyQueryHookResult = ReturnType<typeof useMarketRegimeInputLazyQuery>;
export type MarketRegimeInputSuspenseQueryHookResult = ReturnType<typeof useMarketRegimeInputSuspenseQuery>;
export type MarketRegimeInputQueryResult = Apollo.QueryResult<
  IMarketRegimeInputQuery,
  IMarketRegimeInputQueryVariables
>;
export const PortfolioMetricsDocument = gql`
    query PortfolioMetrics($input: PortfolioRiskMetricsInput!) {
  portfolio {
    metrics(input: $input) {
      label
      portfolioDefinition {
        id
        name
      }
      series {
        hasContributions
        issues {
          asset {
            symbol
          }
          issueType
        }
        parameters {
          name
          intValue
          strValue
          asset {
            symbol
            id
          }
        }
        values {
          value
          date
        }
      }
    }
  }
}
    `;

/**
 * __usePortfolioMetricsQuery__
 *
 * To run a query within a React component, call `usePortfolioMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioMetricsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePortfolioMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<IPortfolioMetricsQuery, IPortfolioMetricsQueryVariables> &
    ({ variables: IPortfolioMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPortfolioMetricsQuery, IPortfolioMetricsQueryVariables>(PortfolioMetricsDocument, options);
}
export function usePortfolioMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPortfolioMetricsQuery, IPortfolioMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPortfolioMetricsQuery, IPortfolioMetricsQueryVariables>(
    PortfolioMetricsDocument,
    options
  );
}
export function usePortfolioMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IPortfolioMetricsQuery, IPortfolioMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IPortfolioMetricsQuery, IPortfolioMetricsQueryVariables>(
    PortfolioMetricsDocument,
    options
  );
}
export type PortfolioMetricsQueryHookResult = ReturnType<typeof usePortfolioMetricsQuery>;
export type PortfolioMetricsLazyQueryHookResult = ReturnType<typeof usePortfolioMetricsLazyQuery>;
export type PortfolioMetricsSuspenseQueryHookResult = ReturnType<typeof usePortfolioMetricsSuspenseQuery>;
export type PortfolioMetricsQueryResult = Apollo.QueryResult<IPortfolioMetricsQuery, IPortfolioMetricsQueryVariables>;
export const AccountsDocument = gql`
    query Accounts {
  portfolio {
    accounts {
      id
      name
      createdAt
      venue {
        label
      }
      snapshotSyncStatus {
        latestSuccessfulTime
        latestCompleted {
          time
          resultCode
        }
      }
      subAccounts {
        name
        id
        active
        externalId
        account {
          venue {
            label
          }
        }
      }
    }
  }
}
    `;

/**
 * __useAccountsQuery__
 *
 * To run a query within a React component, call `useAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountsQuery(baseOptions?: Apollo.QueryHookOptions<IAccountsQuery, IAccountsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IAccountsQuery, IAccountsQueryVariables>(AccountsDocument, options);
}
export function useAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAccountsQuery, IAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IAccountsQuery, IAccountsQueryVariables>(AccountsDocument, options);
}
export function useAccountsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IAccountsQuery, IAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IAccountsQuery, IAccountsQueryVariables>(AccountsDocument, options);
}
export type AccountsQueryHookResult = ReturnType<typeof useAccountsQuery>;
export type AccountsLazyQueryHookResult = ReturnType<typeof useAccountsLazyQuery>;
export type AccountsSuspenseQueryHookResult = ReturnType<typeof useAccountsSuspenseQuery>;
export type AccountsQueryResult = Apollo.QueryResult<IAccountsQuery, IAccountsQueryVariables>;
export const CanRequestPortfolioSnapshotDocument = gql`
    query CanRequestPortfolioSnapshot {
  portfolio {
    canRequestPortfolioSnapshot
  }
}
    `;

/**
 * __useCanRequestPortfolioSnapshotQuery__
 *
 * To run a query within a React component, call `useCanRequestPortfolioSnapshotQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanRequestPortfolioSnapshotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanRequestPortfolioSnapshotQuery({
 *   variables: {
 *   },
 * });
 */
export function useCanRequestPortfolioSnapshotQuery(
  baseOptions?: Apollo.QueryHookOptions<ICanRequestPortfolioSnapshotQuery, ICanRequestPortfolioSnapshotQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ICanRequestPortfolioSnapshotQuery, ICanRequestPortfolioSnapshotQueryVariables>(
    CanRequestPortfolioSnapshotDocument,
    options
  );
}
export function useCanRequestPortfolioSnapshotLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ICanRequestPortfolioSnapshotQuery,
    ICanRequestPortfolioSnapshotQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ICanRequestPortfolioSnapshotQuery, ICanRequestPortfolioSnapshotQueryVariables>(
    CanRequestPortfolioSnapshotDocument,
    options
  );
}
export function useCanRequestPortfolioSnapshotSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ICanRequestPortfolioSnapshotQuery,
    ICanRequestPortfolioSnapshotQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ICanRequestPortfolioSnapshotQuery, ICanRequestPortfolioSnapshotQueryVariables>(
    CanRequestPortfolioSnapshotDocument,
    options
  );
}
export type CanRequestPortfolioSnapshotQueryHookResult = ReturnType<typeof useCanRequestPortfolioSnapshotQuery>;
export type CanRequestPortfolioSnapshotLazyQueryHookResult = ReturnType<typeof useCanRequestPortfolioSnapshotLazyQuery>;
export type CanRequestPortfolioSnapshotSuspenseQueryHookResult = ReturnType<
  typeof useCanRequestPortfolioSnapshotSuspenseQuery
>;
export type CanRequestPortfolioSnapshotQueryResult = Apollo.QueryResult<
  ICanRequestPortfolioSnapshotQuery,
  ICanRequestPortfolioSnapshotQueryVariables
>;
export const CreateAccountDocument = gql`
    mutation CreateAccount($input: CreateAccountInput!) {
  CreateAccount(input: $input) {
    id
  }
}
    `;
export type ICreateAccountMutationFn = Apollo.MutationFunction<ICreateAccountMutation, ICreateAccountMutationVariables>;

/**
 * __useCreateAccountMutation__
 *
 * To run a mutation, you first call `useCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<ICreateAccountMutation, ICreateAccountMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICreateAccountMutation, ICreateAccountMutationVariables>(CreateAccountDocument, options);
}
export type CreateAccountMutationHookResult = ReturnType<typeof useCreateAccountMutation>;
export type CreateAccountMutationResult = Apollo.MutationResult<ICreateAccountMutation>;
export type CreateAccountMutationOptions = Apollo.BaseMutationOptions<
  ICreateAccountMutation,
  ICreateAccountMutationVariables
>;
export const DeleteAccountDocument = gql`
    mutation DeleteAccount($id: UUID!) {
  DeleteAccount(id: $id)
}
    `;
export type IDeleteAccountMutationFn = Apollo.MutationFunction<IDeleteAccountMutation, IDeleteAccountMutationVariables>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<IDeleteAccountMutation, IDeleteAccountMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IDeleteAccountMutation, IDeleteAccountMutationVariables>(DeleteAccountDocument, options);
}
export type DeleteAccountMutationHookResult = ReturnType<typeof useDeleteAccountMutation>;
export type DeleteAccountMutationResult = Apollo.MutationResult<IDeleteAccountMutation>;
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<
  IDeleteAccountMutation,
  IDeleteAccountMutationVariables
>;
export const RequestPortfolioSnapshotDocument = gql`
    mutation RequestPortfolioSnapshot {
  RequestPortfolioSnapshot
}
    `;
export type IRequestPortfolioSnapshotMutationFn = Apollo.MutationFunction<
  IRequestPortfolioSnapshotMutation,
  IRequestPortfolioSnapshotMutationVariables
>;

/**
 * __useRequestPortfolioSnapshotMutation__
 *
 * To run a mutation, you first call `useRequestPortfolioSnapshotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPortfolioSnapshotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPortfolioSnapshotMutation, { data, loading, error }] = useRequestPortfolioSnapshotMutation({
 *   variables: {
 *   },
 * });
 */
export function useRequestPortfolioSnapshotMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IRequestPortfolioSnapshotMutation,
    IRequestPortfolioSnapshotMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IRequestPortfolioSnapshotMutation, IRequestPortfolioSnapshotMutationVariables>(
    RequestPortfolioSnapshotDocument,
    options
  );
}
export type RequestPortfolioSnapshotMutationHookResult = ReturnType<typeof useRequestPortfolioSnapshotMutation>;
export type RequestPortfolioSnapshotMutationResult = Apollo.MutationResult<IRequestPortfolioSnapshotMutation>;
export type RequestPortfolioSnapshotMutationOptions = Apollo.BaseMutationOptions<
  IRequestPortfolioSnapshotMutation,
  IRequestPortfolioSnapshotMutationVariables
>;
export const UpdateAccountDocument = gql`
    mutation UpdateAccount($input: UpdateNameInput!) {
  UpdateAccount(input: $input) {
    id
  }
}
    `;
export type IUpdateAccountMutationFn = Apollo.MutationFunction<IUpdateAccountMutation, IUpdateAccountMutationVariables>;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdateAccountMutation, IUpdateAccountMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IUpdateAccountMutation, IUpdateAccountMutationVariables>(UpdateAccountDocument, options);
}
export type UpdateAccountMutationHookResult = ReturnType<typeof useUpdateAccountMutation>;
export type UpdateAccountMutationResult = Apollo.MutationResult<IUpdateAccountMutation>;
export type UpdateAccountMutationOptions = Apollo.BaseMutationOptions<
  IUpdateAccountMutation,
  IUpdateAccountMutationVariables
>;
export const UpdateAccountApiKeysDocument = gql`
    mutation UpdateAccountApiKeys($input: UpdateApiKeysInput!) {
  UpdateAccountApiKeys(input: $input) {
    id
  }
}
    `;
export type IUpdateAccountApiKeysMutationFn = Apollo.MutationFunction<
  IUpdateAccountApiKeysMutation,
  IUpdateAccountApiKeysMutationVariables
>;

/**
 * __useUpdateAccountApiKeysMutation__
 *
 * To run a mutation, you first call `useUpdateAccountApiKeysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountApiKeysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountApiKeysMutation, { data, loading, error }] = useUpdateAccountApiKeysMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountApiKeysMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdateAccountApiKeysMutation, IUpdateAccountApiKeysMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IUpdateAccountApiKeysMutation, IUpdateAccountApiKeysMutationVariables>(
    UpdateAccountApiKeysDocument,
    options
  );
}
export type UpdateAccountApiKeysMutationHookResult = ReturnType<typeof useUpdateAccountApiKeysMutation>;
export type UpdateAccountApiKeysMutationResult = Apollo.MutationResult<IUpdateAccountApiKeysMutation>;
export type UpdateAccountApiKeysMutationOptions = Apollo.BaseMutationOptions<
  IUpdateAccountApiKeysMutation,
  IUpdateAccountApiKeysMutationVariables
>;
export const PortfolioDashboardInputDocument = gql`
    query PortfolioDashboardInput($subAccountAssetFilters: SubAccountAssetFilterInputType!, $tilesSettings: String!) {
  portfolio {
    accounts {
      ...AccountFields
    }
    positions(filters: {subAccountAssetFilters: $subAccountAssetFilters}) {
      positions {
        asset {
          type
          id
          symbol
          type
          label
          icon
          name
          exchangeDetails
          derivativeDetails {
            exchange
            baseAsset {
              id
            }
          }
        }
        subAccount {
          id
          name
          account {
            id
            name
            venue {
              label
            }
          }
        }
        spot {
          price
          amount
          balance
        }
        derivative {
          amount
          side
          notional
          unrealizedPnl
          balance
          unitMarketPrice
          unitEntryPrice
        }
      }
    }
    journal {
      preferences {
        dimension
      }
      costBasis(perOrganization: true) {
        asset {
          id
        }
        costBasis
      }
    }
    subFunds {
      list {
        id
        name
        dimension
        subAccounts {
          id
          name
        }
        accounts {
          id
          name
          subAccounts {
            id
          }
        }
      }
    }
    metricParameters(subAccountAssetFilter: $subAccountAssetFilters) {
      label
      parameters {
        name
        type
        minMax {
          max
          min
        }
        options {
          value
        }
        benchmarks {
          id
          symbol
        }
      }
    }
  }
  assets {
    feature(feature: KNOWN_ASSETS) {
      id
      symbol
    }
  }
  management {
    userSettings(field: $tilesSettings)
  }
}
    ${AccountFieldsFragmentDoc}`;

/**
 * __usePortfolioDashboardInputQuery__
 *
 * To run a query within a React component, call `usePortfolioDashboardInputQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioDashboardInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioDashboardInputQuery({
 *   variables: {
 *      subAccountAssetFilters: // value for 'subAccountAssetFilters'
 *      tilesSettings: // value for 'tilesSettings'
 *   },
 * });
 */
export function usePortfolioDashboardInputQuery(
  baseOptions: Apollo.QueryHookOptions<IPortfolioDashboardInputQuery, IPortfolioDashboardInputQueryVariables> &
    ({ variables: IPortfolioDashboardInputQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPortfolioDashboardInputQuery, IPortfolioDashboardInputQueryVariables>(
    PortfolioDashboardInputDocument,
    options
  );
}
export function usePortfolioDashboardInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPortfolioDashboardInputQuery, IPortfolioDashboardInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPortfolioDashboardInputQuery, IPortfolioDashboardInputQueryVariables>(
    PortfolioDashboardInputDocument,
    options
  );
}
export function usePortfolioDashboardInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IPortfolioDashboardInputQuery, IPortfolioDashboardInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IPortfolioDashboardInputQuery, IPortfolioDashboardInputQueryVariables>(
    PortfolioDashboardInputDocument,
    options
  );
}
export type PortfolioDashboardInputQueryHookResult = ReturnType<typeof usePortfolioDashboardInputQuery>;
export type PortfolioDashboardInputLazyQueryHookResult = ReturnType<typeof usePortfolioDashboardInputLazyQuery>;
export type PortfolioDashboardInputSuspenseQueryHookResult = ReturnType<typeof usePortfolioDashboardInputSuspenseQuery>;
export type PortfolioDashboardInputQueryResult = Apollo.QueryResult<
  IPortfolioDashboardInputQuery,
  IPortfolioDashboardInputQueryVariables
>;
export const PortfolioExposureMetricsDocument = gql`
    query PortfolioExposureMetrics($input: PortfolioRiskMetricsInput!) {
  portfolio {
    metrics(input: $input) {
      label
      portfolioDefinition {
        id
        name
      }
      series {
        hasContributions
        issues {
          asset {
            symbol
          }
          issueType
        }
        parameters {
          name
          intValue
          strValue
          asset {
            symbol
            id
          }
        }
        values {
          value
          date
        }
      }
    }
  }
}
    `;

/**
 * __usePortfolioExposureMetricsQuery__
 *
 * To run a query within a React component, call `usePortfolioExposureMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioExposureMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioExposureMetricsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePortfolioExposureMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<IPortfolioExposureMetricsQuery, IPortfolioExposureMetricsQueryVariables> &
    ({ variables: IPortfolioExposureMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPortfolioExposureMetricsQuery, IPortfolioExposureMetricsQueryVariables>(
    PortfolioExposureMetricsDocument,
    options
  );
}
export function usePortfolioExposureMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPortfolioExposureMetricsQuery, IPortfolioExposureMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPortfolioExposureMetricsQuery, IPortfolioExposureMetricsQueryVariables>(
    PortfolioExposureMetricsDocument,
    options
  );
}
export function usePortfolioExposureMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IPortfolioExposureMetricsQuery, IPortfolioExposureMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IPortfolioExposureMetricsQuery, IPortfolioExposureMetricsQueryVariables>(
    PortfolioExposureMetricsDocument,
    options
  );
}
export type PortfolioExposureMetricsQueryHookResult = ReturnType<typeof usePortfolioExposureMetricsQuery>;
export type PortfolioExposureMetricsLazyQueryHookResult = ReturnType<typeof usePortfolioExposureMetricsLazyQuery>;
export type PortfolioExposureMetricsSuspenseQueryHookResult = ReturnType<
  typeof usePortfolioExposureMetricsSuspenseQuery
>;
export type PortfolioExposureMetricsQueryResult = Apollo.QueryResult<
  IPortfolioExposureMetricsQuery,
  IPortfolioExposureMetricsQueryVariables
>;
export const PortfolioExposureRiskDocument = gql`
    query PortfolioExposureRisk($subAccountAssetFilters: SubAccountAssetFilterInputType!) {
  portfolio {
    positions(filters: {subAccountAssetFilters: $subAccountAssetFilters}) {
      positions {
        asset {
          id
          symbol
          type
          label
          icon
          name
          exchangeDetails
          derivativeDetails {
            exchange
            derivativeType
            baseAsset {
              id
            }
          }
        }
      }
      summary {
        balance {
          total
        }
        exposure {
          net
          long
          short
        }
        balanceByVenue {
          venue
          name
          balance
        }
      }
    }
  }
}
    `;

/**
 * __usePortfolioExposureRiskQuery__
 *
 * To run a query within a React component, call `usePortfolioExposureRiskQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioExposureRiskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioExposureRiskQuery({
 *   variables: {
 *      subAccountAssetFilters: // value for 'subAccountAssetFilters'
 *   },
 * });
 */
export function usePortfolioExposureRiskQuery(
  baseOptions: Apollo.QueryHookOptions<IPortfolioExposureRiskQuery, IPortfolioExposureRiskQueryVariables> &
    ({ variables: IPortfolioExposureRiskQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPortfolioExposureRiskQuery, IPortfolioExposureRiskQueryVariables>(
    PortfolioExposureRiskDocument,
    options
  );
}
export function usePortfolioExposureRiskLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPortfolioExposureRiskQuery, IPortfolioExposureRiskQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPortfolioExposureRiskQuery, IPortfolioExposureRiskQueryVariables>(
    PortfolioExposureRiskDocument,
    options
  );
}
export function usePortfolioExposureRiskSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IPortfolioExposureRiskQuery, IPortfolioExposureRiskQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IPortfolioExposureRiskQuery, IPortfolioExposureRiskQueryVariables>(
    PortfolioExposureRiskDocument,
    options
  );
}
export type PortfolioExposureRiskQueryHookResult = ReturnType<typeof usePortfolioExposureRiskQuery>;
export type PortfolioExposureRiskLazyQueryHookResult = ReturnType<typeof usePortfolioExposureRiskLazyQuery>;
export type PortfolioExposureRiskSuspenseQueryHookResult = ReturnType<typeof usePortfolioExposureRiskSuspenseQuery>;
export type PortfolioExposureRiskQueryResult = Apollo.QueryResult<
  IPortfolioExposureRiskQuery,
  IPortfolioExposureRiskQueryVariables
>;
export const PortfolioTwrDocument = gql`
    query PortfolioTwr($dates: DateRangeFilter, $subAccountAssetFilters: SubAccountAssetFilterInputType!) {
  portfolio {
    portfolioTimeWeightedReturns(
      filters: {date: $dates, subAccountAssetFilters: $subAccountAssetFilters}
    ) {
      values
    }
  }
}
    `;

/**
 * __usePortfolioTwrQuery__
 *
 * To run a query within a React component, call `usePortfolioTwrQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioTwrQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioTwrQuery({
 *   variables: {
 *      dates: // value for 'dates'
 *      subAccountAssetFilters: // value for 'subAccountAssetFilters'
 *   },
 * });
 */
export function usePortfolioTwrQuery(
  baseOptions: Apollo.QueryHookOptions<IPortfolioTwrQuery, IPortfolioTwrQueryVariables> &
    ({ variables: IPortfolioTwrQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPortfolioTwrQuery, IPortfolioTwrQueryVariables>(PortfolioTwrDocument, options);
}
export function usePortfolioTwrLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPortfolioTwrQuery, IPortfolioTwrQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPortfolioTwrQuery, IPortfolioTwrQueryVariables>(PortfolioTwrDocument, options);
}
export function usePortfolioTwrSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IPortfolioTwrQuery, IPortfolioTwrQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IPortfolioTwrQuery, IPortfolioTwrQueryVariables>(PortfolioTwrDocument, options);
}
export type PortfolioTwrQueryHookResult = ReturnType<typeof usePortfolioTwrQuery>;
export type PortfolioTwrLazyQueryHookResult = ReturnType<typeof usePortfolioTwrLazyQuery>;
export type PortfolioTwrSuspenseQueryHookResult = ReturnType<typeof usePortfolioTwrSuspenseQuery>;
export type PortfolioTwrQueryResult = Apollo.QueryResult<IPortfolioTwrQuery, IPortfolioTwrQueryVariables>;
export const SubAccountSnapshotDocument = gql`
    query SubAccountSnapshot($dates: DateRangeFilter, $subAccountAssetFilters: SubAccountAssetFilterInputType!) {
  portfolio {
    snapshot(
      filters: {date: $dates, subAccountAssetFilters: $subAccountAssetFilters}
    ) {
      date
      positions {
        asset {
          id
          symbol
          type
          label
          exchangeDetails
          derivativeDetails {
            baseAsset {
              id
              symbol
            }
          }
        }
        spot {
          price
          amount
          balance
        }
        derivative {
          notional
          side
          unrealizedPnl
          balance
        }
        subAccount {
          name
          id
          account {
            id
            name
            venue {
              label
            }
          }
        }
      }
    }
  }
  assets {
    assetGroups {
      genieGroups {
        clusterName
        groupName
        assets {
          id
        }
      }
      userGroups {
        clusterName
        groupName
        assets {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useSubAccountSnapshotQuery__
 *
 * To run a query within a React component, call `useSubAccountSnapshotQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubAccountSnapshotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubAccountSnapshotQuery({
 *   variables: {
 *      dates: // value for 'dates'
 *      subAccountAssetFilters: // value for 'subAccountAssetFilters'
 *   },
 * });
 */
export function useSubAccountSnapshotQuery(
  baseOptions: Apollo.QueryHookOptions<ISubAccountSnapshotQuery, ISubAccountSnapshotQueryVariables> &
    ({ variables: ISubAccountSnapshotQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ISubAccountSnapshotQuery, ISubAccountSnapshotQueryVariables>(
    SubAccountSnapshotDocument,
    options
  );
}
export function useSubAccountSnapshotLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ISubAccountSnapshotQuery, ISubAccountSnapshotQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ISubAccountSnapshotQuery, ISubAccountSnapshotQueryVariables>(
    SubAccountSnapshotDocument,
    options
  );
}
export function useSubAccountSnapshotSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ISubAccountSnapshotQuery, ISubAccountSnapshotQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ISubAccountSnapshotQuery, ISubAccountSnapshotQueryVariables>(
    SubAccountSnapshotDocument,
    options
  );
}
export type SubAccountSnapshotQueryHookResult = ReturnType<typeof useSubAccountSnapshotQuery>;
export type SubAccountSnapshotLazyQueryHookResult = ReturnType<typeof useSubAccountSnapshotLazyQuery>;
export type SubAccountSnapshotSuspenseQueryHookResult = ReturnType<typeof useSubAccountSnapshotSuspenseQuery>;
export type SubAccountSnapshotQueryResult = Apollo.QueryResult<
  ISubAccountSnapshotQuery,
  ISubAccountSnapshotQueryVariables
>;
export const CurrentExposureInputDocument = gql`
    query CurrentExposureInput($subAccountAssetFilters: SubAccountAssetFilterInputType) {
  portfolio {
    positions(filters: {subAccountAssetFilters: $subAccountAssetFilters}) {
      positions {
        asset {
          id
          symbol
          type
          label
          exchangeDetails
          derivativeDetails {
            baseAsset {
              id
              symbol
            }
          }
        }
        spot {
          price
          amount
          balance
        }
        derivative {
          notional
          side
          unrealizedPnl
          balance
        }
        subAccount {
          name
          id
          account {
            id
            name
            venue {
              label
            }
          }
        }
      }
    }
  }
  assets {
    assetGroups {
      genieGroups {
        clusterName
        groupName
        assets {
          id
        }
      }
      userGroups {
        clusterName
        groupName
        assets {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useCurrentExposureInputQuery__
 *
 * To run a query within a React component, call `useCurrentExposureInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentExposureInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentExposureInputQuery({
 *   variables: {
 *      subAccountAssetFilters: // value for 'subAccountAssetFilters'
 *   },
 * });
 */
export function useCurrentExposureInputQuery(
  baseOptions?: Apollo.QueryHookOptions<ICurrentExposureInputQuery, ICurrentExposureInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ICurrentExposureInputQuery, ICurrentExposureInputQueryVariables>(
    CurrentExposureInputDocument,
    options
  );
}
export function useCurrentExposureInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ICurrentExposureInputQuery, ICurrentExposureInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ICurrentExposureInputQuery, ICurrentExposureInputQueryVariables>(
    CurrentExposureInputDocument,
    options
  );
}
export function useCurrentExposureInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ICurrentExposureInputQuery, ICurrentExposureInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ICurrentExposureInputQuery, ICurrentExposureInputQueryVariables>(
    CurrentExposureInputDocument,
    options
  );
}
export type CurrentExposureInputQueryHookResult = ReturnType<typeof useCurrentExposureInputQuery>;
export type CurrentExposureInputLazyQueryHookResult = ReturnType<typeof useCurrentExposureInputLazyQuery>;
export type CurrentExposureInputSuspenseQueryHookResult = ReturnType<typeof useCurrentExposureInputSuspenseQuery>;
export type CurrentExposureInputQueryResult = Apollo.QueryResult<
  ICurrentExposureInputQuery,
  ICurrentExposureInputQueryVariables
>;
export const CurrentExposureSettingsInputDocument = gql`
    query CurrentExposureSettingsInput {
  assets {
    assetGroups {
      genieGroups {
        clusterName
        groupName
        assets {
          id
        }
      }
      userGroups {
        clusterName
        groupName
        assets {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useCurrentExposureSettingsInputQuery__
 *
 * To run a query within a React component, call `useCurrentExposureSettingsInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentExposureSettingsInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentExposureSettingsInputQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentExposureSettingsInputQuery(
  baseOptions?: Apollo.QueryHookOptions<ICurrentExposureSettingsInputQuery, ICurrentExposureSettingsInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ICurrentExposureSettingsInputQuery, ICurrentExposureSettingsInputQueryVariables>(
    CurrentExposureSettingsInputDocument,
    options
  );
}
export function useCurrentExposureSettingsInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ICurrentExposureSettingsInputQuery,
    ICurrentExposureSettingsInputQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ICurrentExposureSettingsInputQuery, ICurrentExposureSettingsInputQueryVariables>(
    CurrentExposureSettingsInputDocument,
    options
  );
}
export function useCurrentExposureSettingsInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ICurrentExposureSettingsInputQuery,
    ICurrentExposureSettingsInputQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ICurrentExposureSettingsInputQuery, ICurrentExposureSettingsInputQueryVariables>(
    CurrentExposureSettingsInputDocument,
    options
  );
}
export type CurrentExposureSettingsInputQueryHookResult = ReturnType<typeof useCurrentExposureSettingsInputQuery>;
export type CurrentExposureSettingsInputLazyQueryHookResult = ReturnType<
  typeof useCurrentExposureSettingsInputLazyQuery
>;
export type CurrentExposureSettingsInputSuspenseQueryHookResult = ReturnType<
  typeof useCurrentExposureSettingsInputSuspenseQuery
>;
export type CurrentExposureSettingsInputQueryResult = Apollo.QueryResult<
  ICurrentExposureSettingsInputQuery,
  ICurrentExposureSettingsInputQueryVariables
>;
export const PortfolioReturnsTileInputDocument = gql`
    query PortfolioReturnsTileInput($returnsSince: Date!, $subAccountAssetFilters: SubAccountAssetFilterInputType!) {
  portfolio {
    portfolioTimeWeightedReturns(
      filters: {subAccountAssetFilters: $subAccountAssetFilters, date: {since: $returnsSince}}
    ) {
      values
    }
  }
}
    `;

/**
 * __usePortfolioReturnsTileInputQuery__
 *
 * To run a query within a React component, call `usePortfolioReturnsTileInputQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioReturnsTileInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioReturnsTileInputQuery({
 *   variables: {
 *      returnsSince: // value for 'returnsSince'
 *      subAccountAssetFilters: // value for 'subAccountAssetFilters'
 *   },
 * });
 */
export function usePortfolioReturnsTileInputQuery(
  baseOptions: Apollo.QueryHookOptions<IPortfolioReturnsTileInputQuery, IPortfolioReturnsTileInputQueryVariables> &
    ({ variables: IPortfolioReturnsTileInputQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPortfolioReturnsTileInputQuery, IPortfolioReturnsTileInputQueryVariables>(
    PortfolioReturnsTileInputDocument,
    options
  );
}
export function usePortfolioReturnsTileInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPortfolioReturnsTileInputQuery, IPortfolioReturnsTileInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPortfolioReturnsTileInputQuery, IPortfolioReturnsTileInputQueryVariables>(
    PortfolioReturnsTileInputDocument,
    options
  );
}
export function usePortfolioReturnsTileInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IPortfolioReturnsTileInputQuery,
    IPortfolioReturnsTileInputQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IPortfolioReturnsTileInputQuery, IPortfolioReturnsTileInputQueryVariables>(
    PortfolioReturnsTileInputDocument,
    options
  );
}
export type PortfolioReturnsTileInputQueryHookResult = ReturnType<typeof usePortfolioReturnsTileInputQuery>;
export type PortfolioReturnsTileInputLazyQueryHookResult = ReturnType<typeof usePortfolioReturnsTileInputLazyQuery>;
export type PortfolioReturnsTileInputSuspenseQueryHookResult = ReturnType<
  typeof usePortfolioReturnsTileInputSuspenseQuery
>;
export type PortfolioReturnsTileInputQueryResult = Apollo.QueryResult<
  IPortfolioReturnsTileInputQuery,
  IPortfolioReturnsTileInputQueryVariables
>;
export const PortfolioTilesMetricsDocument = gql`
    query PortfolioTilesMetrics($metrics: [MetricCalculatorInput!]!, $metricsSince: Date!, $metricsTo: Date!, $subAccountAssetFilters: SubAccountAssetFilterInputType!) {
  portfolio {
    metrics(
      input: {metrics: $metrics, since: $metricsSince, to: $metricsTo, subAccountAssetFilter: $subAccountAssetFilters}
    ) {
      label
      series {
        values {
          date
          value
        }
      }
    }
  }
}
    `;

/**
 * __usePortfolioTilesMetricsQuery__
 *
 * To run a query within a React component, call `usePortfolioTilesMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioTilesMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioTilesMetricsQuery({
 *   variables: {
 *      metrics: // value for 'metrics'
 *      metricsSince: // value for 'metricsSince'
 *      metricsTo: // value for 'metricsTo'
 *      subAccountAssetFilters: // value for 'subAccountAssetFilters'
 *   },
 * });
 */
export function usePortfolioTilesMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<IPortfolioTilesMetricsQuery, IPortfolioTilesMetricsQueryVariables> &
    ({ variables: IPortfolioTilesMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPortfolioTilesMetricsQuery, IPortfolioTilesMetricsQueryVariables>(
    PortfolioTilesMetricsDocument,
    options
  );
}
export function usePortfolioTilesMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPortfolioTilesMetricsQuery, IPortfolioTilesMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPortfolioTilesMetricsQuery, IPortfolioTilesMetricsQueryVariables>(
    PortfolioTilesMetricsDocument,
    options
  );
}
export function usePortfolioTilesMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IPortfolioTilesMetricsQuery, IPortfolioTilesMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IPortfolioTilesMetricsQuery, IPortfolioTilesMetricsQueryVariables>(
    PortfolioTilesMetricsDocument,
    options
  );
}
export type PortfolioTilesMetricsQueryHookResult = ReturnType<typeof usePortfolioTilesMetricsQuery>;
export type PortfolioTilesMetricsLazyQueryHookResult = ReturnType<typeof usePortfolioTilesMetricsLazyQuery>;
export type PortfolioTilesMetricsSuspenseQueryHookResult = ReturnType<typeof usePortfolioTilesMetricsSuspenseQuery>;
export type PortfolioTilesMetricsQueryResult = Apollo.QueryResult<
  IPortfolioTilesMetricsQuery,
  IPortfolioTilesMetricsQueryVariables
>;
export const PortfolioTwrEquityTileInputDocument = gql`
    query PortfolioTwrEquityTileInput($twrSince: Date!, $twrTo: Date!, $subAccountAssetFilters: SubAccountAssetFilterInputType!) {
  portfolio {
    portfolioTimeWeightedReturns(
      filters: {date: {since: $twrSince, to: $twrTo}, subAccountAssetFilters: $subAccountAssetFilters}
    ) {
      values
    }
  }
}
    `;

/**
 * __usePortfolioTwrEquityTileInputQuery__
 *
 * To run a query within a React component, call `usePortfolioTwrEquityTileInputQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioTwrEquityTileInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioTwrEquityTileInputQuery({
 *   variables: {
 *      twrSince: // value for 'twrSince'
 *      twrTo: // value for 'twrTo'
 *      subAccountAssetFilters: // value for 'subAccountAssetFilters'
 *   },
 * });
 */
export function usePortfolioTwrEquityTileInputQuery(
  baseOptions: Apollo.QueryHookOptions<IPortfolioTwrEquityTileInputQuery, IPortfolioTwrEquityTileInputQueryVariables> &
    ({ variables: IPortfolioTwrEquityTileInputQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPortfolioTwrEquityTileInputQuery, IPortfolioTwrEquityTileInputQueryVariables>(
    PortfolioTwrEquityTileInputDocument,
    options
  );
}
export function usePortfolioTwrEquityTileInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IPortfolioTwrEquityTileInputQuery,
    IPortfolioTwrEquityTileInputQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPortfolioTwrEquityTileInputQuery, IPortfolioTwrEquityTileInputQueryVariables>(
    PortfolioTwrEquityTileInputDocument,
    options
  );
}
export function usePortfolioTwrEquityTileInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IPortfolioTwrEquityTileInputQuery,
    IPortfolioTwrEquityTileInputQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IPortfolioTwrEquityTileInputQuery, IPortfolioTwrEquityTileInputQueryVariables>(
    PortfolioTwrEquityTileInputDocument,
    options
  );
}
export type PortfolioTwrEquityTileInputQueryHookResult = ReturnType<typeof usePortfolioTwrEquityTileInputQuery>;
export type PortfolioTwrEquityTileInputLazyQueryHookResult = ReturnType<typeof usePortfolioTwrEquityTileInputLazyQuery>;
export type PortfolioTwrEquityTileInputSuspenseQueryHookResult = ReturnType<
  typeof usePortfolioTwrEquityTileInputSuspenseQuery
>;
export type PortfolioTwrEquityTileInputQueryResult = Apollo.QueryResult<
  IPortfolioTwrEquityTileInputQuery,
  IPortfolioTwrEquityTileInputQueryVariables
>;
export const PortfolioVolatilityTileInputDocument = gql`
    query PortfolioVolatilityTileInput($subAccountAssetFilters: SubAccountAssetFilterInputType) {
  portfolio {
    metricParameters(subAccountAssetFilter: $subAccountAssetFilters) {
      label
      parameters {
        name
        type
        minMax {
          max
          min
        }
        options {
          value
        }
        benchmarks {
          id
          symbol
        }
      }
    }
  }
}
    `;

/**
 * __usePortfolioVolatilityTileInputQuery__
 *
 * To run a query within a React component, call `usePortfolioVolatilityTileInputQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioVolatilityTileInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioVolatilityTileInputQuery({
 *   variables: {
 *      subAccountAssetFilters: // value for 'subAccountAssetFilters'
 *   },
 * });
 */
export function usePortfolioVolatilityTileInputQuery(
  baseOptions?: Apollo.QueryHookOptions<IPortfolioVolatilityTileInputQuery, IPortfolioVolatilityTileInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPortfolioVolatilityTileInputQuery, IPortfolioVolatilityTileInputQueryVariables>(
    PortfolioVolatilityTileInputDocument,
    options
  );
}
export function usePortfolioVolatilityTileInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IPortfolioVolatilityTileInputQuery,
    IPortfolioVolatilityTileInputQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPortfolioVolatilityTileInputQuery, IPortfolioVolatilityTileInputQueryVariables>(
    PortfolioVolatilityTileInputDocument,
    options
  );
}
export function usePortfolioVolatilityTileInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IPortfolioVolatilityTileInputQuery,
    IPortfolioVolatilityTileInputQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IPortfolioVolatilityTileInputQuery, IPortfolioVolatilityTileInputQueryVariables>(
    PortfolioVolatilityTileInputDocument,
    options
  );
}
export type PortfolioVolatilityTileInputQueryHookResult = ReturnType<typeof usePortfolioVolatilityTileInputQuery>;
export type PortfolioVolatilityTileInputLazyQueryHookResult = ReturnType<
  typeof usePortfolioVolatilityTileInputLazyQuery
>;
export type PortfolioVolatilityTileInputSuspenseQueryHookResult = ReturnType<
  typeof usePortfolioVolatilityTileInputSuspenseQuery
>;
export type PortfolioVolatilityTileInputQueryResult = Apollo.QueryResult<
  IPortfolioVolatilityTileInputQuery,
  IPortfolioVolatilityTileInputQueryVariables
>;
export const TwrEquityWidgetInputDocument = gql`
    query TwrEquityWidgetInput($tileSettings: String!) {
  management {
    userSettings(field: $tileSettings)
  }
}
    `;

/**
 * __useTwrEquityWidgetInputQuery__
 *
 * To run a query within a React component, call `useTwrEquityWidgetInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useTwrEquityWidgetInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTwrEquityWidgetInputQuery({
 *   variables: {
 *      tileSettings: // value for 'tileSettings'
 *   },
 * });
 */
export function useTwrEquityWidgetInputQuery(
  baseOptions: Apollo.QueryHookOptions<ITwrEquityWidgetInputQuery, ITwrEquityWidgetInputQueryVariables> &
    ({ variables: ITwrEquityWidgetInputQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ITwrEquityWidgetInputQuery, ITwrEquityWidgetInputQueryVariables>(
    TwrEquityWidgetInputDocument,
    options
  );
}
export function useTwrEquityWidgetInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ITwrEquityWidgetInputQuery, ITwrEquityWidgetInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ITwrEquityWidgetInputQuery, ITwrEquityWidgetInputQueryVariables>(
    TwrEquityWidgetInputDocument,
    options
  );
}
export function useTwrEquityWidgetInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ITwrEquityWidgetInputQuery, ITwrEquityWidgetInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ITwrEquityWidgetInputQuery, ITwrEquityWidgetInputQueryVariables>(
    TwrEquityWidgetInputDocument,
    options
  );
}
export type TwrEquityWidgetInputQueryHookResult = ReturnType<typeof useTwrEquityWidgetInputQuery>;
export type TwrEquityWidgetInputLazyQueryHookResult = ReturnType<typeof useTwrEquityWidgetInputLazyQuery>;
export type TwrEquityWidgetInputSuspenseQueryHookResult = ReturnType<typeof useTwrEquityWidgetInputSuspenseQuery>;
export type TwrEquityWidgetInputQueryResult = Apollo.QueryResult<
  ITwrEquityWidgetInputQuery,
  ITwrEquityWidgetInputQueryVariables
>;
export const CreateSubFundDocument = gql`
    mutation CreateSubFund($subFund: CreateSubFund!) {
  CreateSubFund(input: $subFund) {
    id
  }
}
    `;
export type ICreateSubFundMutationFn = Apollo.MutationFunction<ICreateSubFundMutation, ICreateSubFundMutationVariables>;

/**
 * __useCreateSubFundMutation__
 *
 * To run a mutation, you first call `useCreateSubFundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubFundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubFundMutation, { data, loading, error }] = useCreateSubFundMutation({
 *   variables: {
 *      subFund: // value for 'subFund'
 *   },
 * });
 */
export function useCreateSubFundMutation(
  baseOptions?: Apollo.MutationHookOptions<ICreateSubFundMutation, ICreateSubFundMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICreateSubFundMutation, ICreateSubFundMutationVariables>(CreateSubFundDocument, options);
}
export type CreateSubFundMutationHookResult = ReturnType<typeof useCreateSubFundMutation>;
export type CreateSubFundMutationResult = Apollo.MutationResult<ICreateSubFundMutation>;
export type CreateSubFundMutationOptions = Apollo.BaseMutationOptions<
  ICreateSubFundMutation,
  ICreateSubFundMutationVariables
>;
export const DeleteDimensionSubFundsDocument = gql`
    mutation DeleteDimensionSubFunds($dimension: String!) {
  DeleteDimensionSubFunds(dimension: $dimension)
}
    `;
export type IDeleteDimensionSubFundsMutationFn = Apollo.MutationFunction<
  IDeleteDimensionSubFundsMutation,
  IDeleteDimensionSubFundsMutationVariables
>;

/**
 * __useDeleteDimensionSubFundsMutation__
 *
 * To run a mutation, you first call `useDeleteDimensionSubFundsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDimensionSubFundsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDimensionSubFundsMutation, { data, loading, error }] = useDeleteDimensionSubFundsMutation({
 *   variables: {
 *      dimension: // value for 'dimension'
 *   },
 * });
 */
export function useDeleteDimensionSubFundsMutation(
  baseOptions?: Apollo.MutationHookOptions<IDeleteDimensionSubFundsMutation, IDeleteDimensionSubFundsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IDeleteDimensionSubFundsMutation, IDeleteDimensionSubFundsMutationVariables>(
    DeleteDimensionSubFundsDocument,
    options
  );
}
export type DeleteDimensionSubFundsMutationHookResult = ReturnType<typeof useDeleteDimensionSubFundsMutation>;
export type DeleteDimensionSubFundsMutationResult = Apollo.MutationResult<IDeleteDimensionSubFundsMutation>;
export type DeleteDimensionSubFundsMutationOptions = Apollo.BaseMutationOptions<
  IDeleteDimensionSubFundsMutation,
  IDeleteDimensionSubFundsMutationVariables
>;
export const DeleteSubFundDocument = gql`
    mutation DeleteSubFund($subFund: String!) {
  DeleteSubFund(input: {name: $subFund})
}
    `;
export type IDeleteSubFundMutationFn = Apollo.MutationFunction<IDeleteSubFundMutation, IDeleteSubFundMutationVariables>;

/**
 * __useDeleteSubFundMutation__
 *
 * To run a mutation, you first call `useDeleteSubFundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubFundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubFundMutation, { data, loading, error }] = useDeleteSubFundMutation({
 *   variables: {
 *      subFund: // value for 'subFund'
 *   },
 * });
 */
export function useDeleteSubFundMutation(
  baseOptions?: Apollo.MutationHookOptions<IDeleteSubFundMutation, IDeleteSubFundMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IDeleteSubFundMutation, IDeleteSubFundMutationVariables>(DeleteSubFundDocument, options);
}
export type DeleteSubFundMutationHookResult = ReturnType<typeof useDeleteSubFundMutation>;
export type DeleteSubFundMutationResult = Apollo.MutationResult<IDeleteSubFundMutation>;
export type DeleteSubFundMutationOptions = Apollo.BaseMutationOptions<
  IDeleteSubFundMutation,
  IDeleteSubFundMutationVariables
>;
export const RenameDimensionDocument = gql`
    mutation RenameDimension($oldDimension: String!, $newDimension: String!) {
  RenameDimension(
    input: {oldDimension: $oldDimension, newDimension: $newDimension}
  )
}
    `;
export type IRenameDimensionMutationFn = Apollo.MutationFunction<
  IRenameDimensionMutation,
  IRenameDimensionMutationVariables
>;

/**
 * __useRenameDimensionMutation__
 *
 * To run a mutation, you first call `useRenameDimensionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameDimensionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameDimensionMutation, { data, loading, error }] = useRenameDimensionMutation({
 *   variables: {
 *      oldDimension: // value for 'oldDimension'
 *      newDimension: // value for 'newDimension'
 *   },
 * });
 */
export function useRenameDimensionMutation(
  baseOptions?: Apollo.MutationHookOptions<IRenameDimensionMutation, IRenameDimensionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IRenameDimensionMutation, IRenameDimensionMutationVariables>(
    RenameDimensionDocument,
    options
  );
}
export type RenameDimensionMutationHookResult = ReturnType<typeof useRenameDimensionMutation>;
export type RenameDimensionMutationResult = Apollo.MutationResult<IRenameDimensionMutation>;
export type RenameDimensionMutationOptions = Apollo.BaseMutationOptions<
  IRenameDimensionMutation,
  IRenameDimensionMutationVariables
>;
export const SubFundsDocument = gql`
    query SubFunds {
  portfolio {
    subFunds {
      list {
        isDefault
        id
        name
        dimension
        createdAt
        updatedAt
        referenceAsset {
          ...AssetLabelFields
        }
        accounts {
          id
          name
          venue {
            label
          }
        }
      }
    }
    accounts {
      id
      name
      createdAt
      venue {
        label
      }
      subAccounts {
        name
        id
      }
    }
  }
  assets {
    feature(feature: CORRELATION_DASHBOARD) {
      ...AssetLabelFields
    }
  }
}
    ${AssetLabelFieldsFragmentDoc}`;

/**
 * __useSubFundsQuery__
 *
 * To run a query within a React component, call `useSubFundsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubFundsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubFundsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubFundsQuery(baseOptions?: Apollo.QueryHookOptions<ISubFundsQuery, ISubFundsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ISubFundsQuery, ISubFundsQueryVariables>(SubFundsDocument, options);
}
export function useSubFundsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ISubFundsQuery, ISubFundsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ISubFundsQuery, ISubFundsQueryVariables>(SubFundsDocument, options);
}
export function useSubFundsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ISubFundsQuery, ISubFundsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ISubFundsQuery, ISubFundsQueryVariables>(SubFundsDocument, options);
}
export type SubFundsQueryHookResult = ReturnType<typeof useSubFundsQuery>;
export type SubFundsLazyQueryHookResult = ReturnType<typeof useSubFundsLazyQuery>;
export type SubFundsSuspenseQueryHookResult = ReturnType<typeof useSubFundsSuspenseQuery>;
export type SubFundsQueryResult = Apollo.QueryResult<ISubFundsQuery, ISubFundsQueryVariables>;
export const UpdateSubFundDocument = gql`
    mutation UpdateSubFund($input: UpdateSubFundInput!) {
  UpdateSubFund(input: $input) {
    id
  }
}
    `;
export type IUpdateSubFundMutationFn = Apollo.MutationFunction<IUpdateSubFundMutation, IUpdateSubFundMutationVariables>;

/**
 * __useUpdateSubFundMutation__
 *
 * To run a mutation, you first call `useUpdateSubFundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubFundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubFundMutation, { data, loading, error }] = useUpdateSubFundMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubFundMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdateSubFundMutation, IUpdateSubFundMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IUpdateSubFundMutation, IUpdateSubFundMutationVariables>(UpdateSubFundDocument, options);
}
export type UpdateSubFundMutationHookResult = ReturnType<typeof useUpdateSubFundMutation>;
export type UpdateSubFundMutationResult = Apollo.MutationResult<IUpdateSubFundMutation>;
export type UpdateSubFundMutationOptions = Apollo.BaseMutationOptions<
  IUpdateSubFundMutation,
  IUpdateSubFundMutationVariables
>;
export const AllAssetGroupsDocument = gql`
    query AllAssetGroups {
  assets {
    assetGroups {
      genieGroups {
        assets {
          id
        }
        clusterName
        groupName
      }
      userGroups {
        assets {
          id
        }
        clusterName
        groupName
      }
    }
  }
}
    `;

/**
 * __useAllAssetGroupsQuery__
 *
 * To run a query within a React component, call `useAllAssetGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAssetGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAssetGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllAssetGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<IAllAssetGroupsQuery, IAllAssetGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IAllAssetGroupsQuery, IAllAssetGroupsQueryVariables>(AllAssetGroupsDocument, options);
}
export function useAllAssetGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAllAssetGroupsQuery, IAllAssetGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IAllAssetGroupsQuery, IAllAssetGroupsQueryVariables>(AllAssetGroupsDocument, options);
}
export function useAllAssetGroupsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IAllAssetGroupsQuery, IAllAssetGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IAllAssetGroupsQuery, IAllAssetGroupsQueryVariables>(AllAssetGroupsDocument, options);
}
export type AllAssetGroupsQueryHookResult = ReturnType<typeof useAllAssetGroupsQuery>;
export type AllAssetGroupsLazyQueryHookResult = ReturnType<typeof useAllAssetGroupsLazyQuery>;
export type AllAssetGroupsSuspenseQueryHookResult = ReturnType<typeof useAllAssetGroupsSuspenseQuery>;
export type AllAssetGroupsQueryResult = Apollo.QueryResult<IAllAssetGroupsQuery, IAllAssetGroupsQueryVariables>;
export const OpenPositionsSummaryDocument = gql`
    query OpenPositionsSummary($subAccountAssetFilters: SubAccountAssetFilterInputType!) {
  assets {
    feature(feature: BENCHMARK) {
      id
      symbol
    }
  }
  portfolio {
    positions(filters: {subAccountAssetFilters: $subAccountAssetFilters}) {
      summary {
        balance {
          total
        }
      }
      positions {
        subAccount {
          id
          name
          account {
            id
            name
            venue {
              label
            }
          }
        }
        asset {
          ...MainAssetFields
          derivativeDetails {
            derivativeType
          }
        }
        spot {
          price
          amount
          balance
          slot
        }
        derivative {
          amount
          notional
          side
          unrealizedPnl
          balance
          unitEntryPrice
          initialMargin
          leverage
          maintenanceMargin
        }
      }
    }
    subFunds {
      list {
        ...SubFundToSubAccountFields
      }
    }
    journal {
      ...OpenPositionsSummaryCostBasis
    }
  }
}
    ${MainAssetFieldsFragmentDoc}
${SubFundToSubAccountFieldsFragmentDoc}
${OpenPositionsSummaryCostBasisFragmentDoc}`;

/**
 * __useOpenPositionsSummaryQuery__
 *
 * To run a query within a React component, call `useOpenPositionsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpenPositionsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpenPositionsSummaryQuery({
 *   variables: {
 *      subAccountAssetFilters: // value for 'subAccountAssetFilters'
 *   },
 * });
 */
export function useOpenPositionsSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<IOpenPositionsSummaryQuery, IOpenPositionsSummaryQueryVariables> &
    ({ variables: IOpenPositionsSummaryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IOpenPositionsSummaryQuery, IOpenPositionsSummaryQueryVariables>(
    OpenPositionsSummaryDocument,
    options
  );
}
export function useOpenPositionsSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IOpenPositionsSummaryQuery, IOpenPositionsSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IOpenPositionsSummaryQuery, IOpenPositionsSummaryQueryVariables>(
    OpenPositionsSummaryDocument,
    options
  );
}
export function useOpenPositionsSummarySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IOpenPositionsSummaryQuery, IOpenPositionsSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IOpenPositionsSummaryQuery, IOpenPositionsSummaryQueryVariables>(
    OpenPositionsSummaryDocument,
    options
  );
}
export type OpenPositionsSummaryQueryHookResult = ReturnType<typeof useOpenPositionsSummaryQuery>;
export type OpenPositionsSummaryLazyQueryHookResult = ReturnType<typeof useOpenPositionsSummaryLazyQuery>;
export type OpenPositionsSummarySuspenseQueryHookResult = ReturnType<typeof useOpenPositionsSummarySuspenseQuery>;
export type OpenPositionsSummaryQueryResult = Apollo.QueryResult<
  IOpenPositionsSummaryQuery,
  IOpenPositionsSummaryQueryVariables
>;
export const PricesForPositionsDocument = gql`
    query PricesForPositions($assetIds: [UUID!]!) {
  assets {
    priceSummary(assetIds: $assetIds) {
      asset {
        id
      }
      today
      firstDayOfMonth
      firstDayOfQuarter
      firstDayOfYear
    }
    changes(filters: {assets: $assetIds}) {
      assetId: asset
      priceChange: price
    }
  }
}
    `;

/**
 * __usePricesForPositionsQuery__
 *
 * To run a query within a React component, call `usePricesForPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePricesForPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePricesForPositionsQuery({
 *   variables: {
 *      assetIds: // value for 'assetIds'
 *   },
 * });
 */
export function usePricesForPositionsQuery(
  baseOptions: Apollo.QueryHookOptions<IPricesForPositionsQuery, IPricesForPositionsQueryVariables> &
    ({ variables: IPricesForPositionsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPricesForPositionsQuery, IPricesForPositionsQueryVariables>(
    PricesForPositionsDocument,
    options
  );
}
export function usePricesForPositionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPricesForPositionsQuery, IPricesForPositionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPricesForPositionsQuery, IPricesForPositionsQueryVariables>(
    PricesForPositionsDocument,
    options
  );
}
export function usePricesForPositionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IPricesForPositionsQuery, IPricesForPositionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IPricesForPositionsQuery, IPricesForPositionsQueryVariables>(
    PricesForPositionsDocument,
    options
  );
}
export type PricesForPositionsQueryHookResult = ReturnType<typeof usePricesForPositionsQuery>;
export type PricesForPositionsLazyQueryHookResult = ReturnType<typeof usePricesForPositionsLazyQuery>;
export type PricesForPositionsSuspenseQueryHookResult = ReturnType<typeof usePricesForPositionsSuspenseQuery>;
export type PricesForPositionsQueryResult = Apollo.QueryResult<
  IPricesForPositionsQuery,
  IPricesForPositionsQueryVariables
>;
export const ReturnsAndAttributionDocument = gql`
    query ReturnsAndAttribution($clusterType: String!, $since: Date, $to: Date, $subAccountAssetFilters: SubAccountAssetFilterInputType!) {
  portfolio {
    performanceClusterAttributionsDaily(
      input: {clusterType: $clusterType, dateRange: {since: $since, to: $to}, subAccountAssetFilter: $subAccountAssetFilters}
    ) {
      short {
        date
        categoryValues {
          category
          value
        }
      }
      long {
        date
        categoryValues {
          category
          value
        }
      }
      total {
        date
        categoryValues {
          category
          value
        }
      }
    }
  }
}
    `;

/**
 * __useReturnsAndAttributionQuery__
 *
 * To run a query within a React component, call `useReturnsAndAttributionQuery` and pass it any options that fit your needs.
 * When your component renders, `useReturnsAndAttributionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReturnsAndAttributionQuery({
 *   variables: {
 *      clusterType: // value for 'clusterType'
 *      since: // value for 'since'
 *      to: // value for 'to'
 *      subAccountAssetFilters: // value for 'subAccountAssetFilters'
 *   },
 * });
 */
export function useReturnsAndAttributionQuery(
  baseOptions: Apollo.QueryHookOptions<IReturnsAndAttributionQuery, IReturnsAndAttributionQueryVariables> &
    ({ variables: IReturnsAndAttributionQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IReturnsAndAttributionQuery, IReturnsAndAttributionQueryVariables>(
    ReturnsAndAttributionDocument,
    options
  );
}
export function useReturnsAndAttributionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IReturnsAndAttributionQuery, IReturnsAndAttributionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IReturnsAndAttributionQuery, IReturnsAndAttributionQueryVariables>(
    ReturnsAndAttributionDocument,
    options
  );
}
export function useReturnsAndAttributionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IReturnsAndAttributionQuery, IReturnsAndAttributionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IReturnsAndAttributionQuery, IReturnsAndAttributionQueryVariables>(
    ReturnsAndAttributionDocument,
    options
  );
}
export type ReturnsAndAttributionQueryHookResult = ReturnType<typeof useReturnsAndAttributionQuery>;
export type ReturnsAndAttributionLazyQueryHookResult = ReturnType<typeof useReturnsAndAttributionLazyQuery>;
export type ReturnsAndAttributionSuspenseQueryHookResult = ReturnType<typeof useReturnsAndAttributionSuspenseQuery>;
export type ReturnsAndAttributionQueryResult = Apollo.QueryResult<
  IReturnsAndAttributionQuery,
  IReturnsAndAttributionQueryVariables
>;
export const TimeWeightedReturnDocument = gql`
    query TimeWeightedReturn($since: Date, $to: Date, $subAccountAssetFilters: SubAccountAssetFilterInputType!) {
  portfolio {
    portfolioTimeWeightedReturns(
      filters: {date: {since: $since, to: $to}, subAccountAssetFilters: $subAccountAssetFilters}
    ) {
      values
    }
  }
}
    `;

/**
 * __useTimeWeightedReturnQuery__
 *
 * To run a query within a React component, call `useTimeWeightedReturnQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeWeightedReturnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeWeightedReturnQuery({
 *   variables: {
 *      since: // value for 'since'
 *      to: // value for 'to'
 *      subAccountAssetFilters: // value for 'subAccountAssetFilters'
 *   },
 * });
 */
export function useTimeWeightedReturnQuery(
  baseOptions: Apollo.QueryHookOptions<ITimeWeightedReturnQuery, ITimeWeightedReturnQueryVariables> &
    ({ variables: ITimeWeightedReturnQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ITimeWeightedReturnQuery, ITimeWeightedReturnQueryVariables>(
    TimeWeightedReturnDocument,
    options
  );
}
export function useTimeWeightedReturnLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ITimeWeightedReturnQuery, ITimeWeightedReturnQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ITimeWeightedReturnQuery, ITimeWeightedReturnQueryVariables>(
    TimeWeightedReturnDocument,
    options
  );
}
export function useTimeWeightedReturnSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ITimeWeightedReturnQuery, ITimeWeightedReturnQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ITimeWeightedReturnQuery, ITimeWeightedReturnQueryVariables>(
    TimeWeightedReturnDocument,
    options
  );
}
export type TimeWeightedReturnQueryHookResult = ReturnType<typeof useTimeWeightedReturnQuery>;
export type TimeWeightedReturnLazyQueryHookResult = ReturnType<typeof useTimeWeightedReturnLazyQuery>;
export type TimeWeightedReturnSuspenseQueryHookResult = ReturnType<typeof useTimeWeightedReturnSuspenseQuery>;
export type TimeWeightedReturnQueryResult = Apollo.QueryResult<
  ITimeWeightedReturnQuery,
  ITimeWeightedReturnQueryVariables
>;
export const AssetContributionsDocument = gql`
    query AssetContributions($since: Date, $to: Date, $subAccountAssetFilters: SubAccountAssetFilterInputType!, $contributionPeriodMethod: ContributionMethod!) {
  portfolio {
    performanceAssetContributions(
      input: {dateRange: {since: $since, to: $to}, method: $contributionPeriodMethod, subAccountAssetFilter: $subAccountAssetFilters}
    ) {
      portfolioPerformance {
        date
        dailyTotalContributionByAsset
        dailyPortfolioTwr
        dailyOpportunisticTradingContribution
        cumulativeTotalContributionByAsset
        cumulativePortfolioTwr
        cumulativeOpportunisticTradingContribution
      }
      assetContributions {
        asset {
          ...AssetLabelFields
          derivativeDetails {
            baseAsset {
              ...MainNonDerivativeBaseAssetFields
            }
          }
        }
        dailySnapshotValues {
          date
          amount
          cashWeight
          cumulativeAssetReturn
          cumulativeContributionsToReturn
          dailyAssetReturn
          dailyContributionToReturn
          exposure
          closePrice
          openPrice
        }
      }
    }
  }
}
    ${AssetLabelFieldsFragmentDoc}
${MainNonDerivativeBaseAssetFieldsFragmentDoc}`;

/**
 * __useAssetContributionsQuery__
 *
 * To run a query within a React component, call `useAssetContributionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetContributionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetContributionsQuery({
 *   variables: {
 *      since: // value for 'since'
 *      to: // value for 'to'
 *      subAccountAssetFilters: // value for 'subAccountAssetFilters'
 *      contributionPeriodMethod: // value for 'contributionPeriodMethod'
 *   },
 * });
 */
export function useAssetContributionsQuery(
  baseOptions: Apollo.QueryHookOptions<IAssetContributionsQuery, IAssetContributionsQueryVariables> &
    ({ variables: IAssetContributionsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IAssetContributionsQuery, IAssetContributionsQueryVariables>(
    AssetContributionsDocument,
    options
  );
}
export function useAssetContributionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAssetContributionsQuery, IAssetContributionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IAssetContributionsQuery, IAssetContributionsQueryVariables>(
    AssetContributionsDocument,
    options
  );
}
export function useAssetContributionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IAssetContributionsQuery, IAssetContributionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IAssetContributionsQuery, IAssetContributionsQueryVariables>(
    AssetContributionsDocument,
    options
  );
}
export type AssetContributionsQueryHookResult = ReturnType<typeof useAssetContributionsQuery>;
export type AssetContributionsLazyQueryHookResult = ReturnType<typeof useAssetContributionsLazyQuery>;
export type AssetContributionsSuspenseQueryHookResult = ReturnType<typeof useAssetContributionsSuspenseQuery>;
export type AssetContributionsQueryResult = Apollo.QueryResult<
  IAssetContributionsQuery,
  IAssetContributionsQueryVariables
>;
export const BottomUpPerformanceAttributionDocument = gql`
    query BottomUpPerformanceAttribution($input: MultiLevelPerformanceClusterAttributionInput!) {
  portfolio {
    multilevelPerformanceAttributions(input: $input) {
      levelNames
      data {
        values
        name
        asset {
          ...AssetLabelFields
        }
        children {
          values
          name
          asset {
            ...AssetLabelFields
          }
          children {
            name
            values
            asset {
              ...AssetLabelFields
            }
            children {
              name
              values
              asset {
                ...AssetLabelFields
              }
              children {
                name
                values
                asset {
                  ...AssetLabelFields
                }
                children {
                  name
                  values
                  asset {
                    ...AssetLabelFields
                  }
                }
              }
            }
          }
        }
      }
      columns
    }
  }
}
    ${AssetLabelFieldsFragmentDoc}`;

/**
 * __useBottomUpPerformanceAttributionQuery__
 *
 * To run a query within a React component, call `useBottomUpPerformanceAttributionQuery` and pass it any options that fit your needs.
 * When your component renders, `useBottomUpPerformanceAttributionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBottomUpPerformanceAttributionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBottomUpPerformanceAttributionQuery(
  baseOptions: Apollo.QueryHookOptions<
    IBottomUpPerformanceAttributionQuery,
    IBottomUpPerformanceAttributionQueryVariables
  > &
    ({ variables: IBottomUpPerformanceAttributionQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IBottomUpPerformanceAttributionQuery, IBottomUpPerformanceAttributionQueryVariables>(
    BottomUpPerformanceAttributionDocument,
    options
  );
}
export function useBottomUpPerformanceAttributionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IBottomUpPerformanceAttributionQuery,
    IBottomUpPerformanceAttributionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IBottomUpPerformanceAttributionQuery, IBottomUpPerformanceAttributionQueryVariables>(
    BottomUpPerformanceAttributionDocument,
    options
  );
}
export function useBottomUpPerformanceAttributionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IBottomUpPerformanceAttributionQuery,
    IBottomUpPerformanceAttributionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IBottomUpPerformanceAttributionQuery, IBottomUpPerformanceAttributionQueryVariables>(
    BottomUpPerformanceAttributionDocument,
    options
  );
}
export type BottomUpPerformanceAttributionQueryHookResult = ReturnType<typeof useBottomUpPerformanceAttributionQuery>;
export type BottomUpPerformanceAttributionLazyQueryHookResult = ReturnType<
  typeof useBottomUpPerformanceAttributionLazyQuery
>;
export type BottomUpPerformanceAttributionSuspenseQueryHookResult = ReturnType<
  typeof useBottomUpPerformanceAttributionSuspenseQuery
>;
export type BottomUpPerformanceAttributionQueryResult = Apollo.QueryResult<
  IBottomUpPerformanceAttributionQuery,
  IBottomUpPerformanceAttributionQueryVariables
>;
export const PerformanceAttributionDocument = gql`
    query PerformanceAttribution($filters: PerformanceClusterAttributionInput!) {
  portfolio {
    performanceClusterAttributions(input: $filters) {
      cumulativeAllocationEffect {
        categoryValues {
          value
          category
        }
        date
      }
      cumulativeSelectionEffect {
        date
        categoryValues {
          value
          category
        }
      }
      cumulativeInteractionEffect {
        date
        categoryValues {
          value
          category
        }
      }
      dailyAllocationEffect {
        date
        categoryValues {
          value
          category
        }
      }
      dailyInteractionEffect {
        date
        categoryValues {
          value
          category
        }
      }
      dailySelectionEffect {
        date
        categoryValues {
          value
          category
        }
      }
      cumulativePortfolioReturns {
        value
        date
      }
      cumulativeBenchmarkReturns {
        value
        date
      }
      cumulativeOverperformance {
        value
        date
      }
      cumulativeTradingActivity {
        value
        date
      }
    }
  }
}
    `;

/**
 * __usePerformanceAttributionQuery__
 *
 * To run a query within a React component, call `usePerformanceAttributionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePerformanceAttributionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePerformanceAttributionQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePerformanceAttributionQuery(
  baseOptions: Apollo.QueryHookOptions<IPerformanceAttributionQuery, IPerformanceAttributionQueryVariables> &
    ({ variables: IPerformanceAttributionQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPerformanceAttributionQuery, IPerformanceAttributionQueryVariables>(
    PerformanceAttributionDocument,
    options
  );
}
export function usePerformanceAttributionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPerformanceAttributionQuery, IPerformanceAttributionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPerformanceAttributionQuery, IPerformanceAttributionQueryVariables>(
    PerformanceAttributionDocument,
    options
  );
}
export function usePerformanceAttributionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IPerformanceAttributionQuery, IPerformanceAttributionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IPerformanceAttributionQuery, IPerformanceAttributionQueryVariables>(
    PerformanceAttributionDocument,
    options
  );
}
export type PerformanceAttributionQueryHookResult = ReturnType<typeof usePerformanceAttributionQuery>;
export type PerformanceAttributionLazyQueryHookResult = ReturnType<typeof usePerformanceAttributionLazyQuery>;
export type PerformanceAttributionSuspenseQueryHookResult = ReturnType<typeof usePerformanceAttributionSuspenseQuery>;
export type PerformanceAttributionQueryResult = Apollo.QueryResult<
  IPerformanceAttributionQuery,
  IPerformanceAttributionQueryVariables
>;
export const PerformanceAttributionInputDocument = gql`
    query PerformanceAttributionInput {
  portfolio {
    definitions {
      id
      name
      description
      type
      createdFrom {
        name
        portfolioDefinition {
          id
          name
        }
      }
      dateRange {
        since
        to
      }
      genie
    }
  }
  assets {
    clusterByUser
  }
  factorRegression {
    supportedTimeWindow {
      supportedTimeWindow {
        since
        until
      }
    }
  }
}
    `;

/**
 * __usePerformanceAttributionInputQuery__
 *
 * To run a query within a React component, call `usePerformanceAttributionInputQuery` and pass it any options that fit your needs.
 * When your component renders, `usePerformanceAttributionInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePerformanceAttributionInputQuery({
 *   variables: {
 *   },
 * });
 */
export function usePerformanceAttributionInputQuery(
  baseOptions?: Apollo.QueryHookOptions<IPerformanceAttributionInputQuery, IPerformanceAttributionInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPerformanceAttributionInputQuery, IPerformanceAttributionInputQueryVariables>(
    PerformanceAttributionInputDocument,
    options
  );
}
export function usePerformanceAttributionInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IPerformanceAttributionInputQuery,
    IPerformanceAttributionInputQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPerformanceAttributionInputQuery, IPerformanceAttributionInputQueryVariables>(
    PerformanceAttributionInputDocument,
    options
  );
}
export function usePerformanceAttributionInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IPerformanceAttributionInputQuery,
    IPerformanceAttributionInputQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IPerformanceAttributionInputQuery, IPerformanceAttributionInputQueryVariables>(
    PerformanceAttributionInputDocument,
    options
  );
}
export type PerformanceAttributionInputQueryHookResult = ReturnType<typeof usePerformanceAttributionInputQuery>;
export type PerformanceAttributionInputLazyQueryHookResult = ReturnType<typeof usePerformanceAttributionInputLazyQuery>;
export type PerformanceAttributionInputSuspenseQueryHookResult = ReturnType<
  typeof usePerformanceAttributionInputSuspenseQuery
>;
export type PerformanceAttributionInputQueryResult = Apollo.QueryResult<
  IPerformanceAttributionInputQuery,
  IPerformanceAttributionInputQueryVariables
>;
export const PerformanceAttributionSettingsWidgetInputDocument = gql`
    query PerformanceAttributionSettingsWidgetInput {
  assets {
    clusterByUser
  }
}
    `;

/**
 * __usePerformanceAttributionSettingsWidgetInputQuery__
 *
 * To run a query within a React component, call `usePerformanceAttributionSettingsWidgetInputQuery` and pass it any options that fit your needs.
 * When your component renders, `usePerformanceAttributionSettingsWidgetInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePerformanceAttributionSettingsWidgetInputQuery({
 *   variables: {
 *   },
 * });
 */
export function usePerformanceAttributionSettingsWidgetInputQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IPerformanceAttributionSettingsWidgetInputQuery,
    IPerformanceAttributionSettingsWidgetInputQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IPerformanceAttributionSettingsWidgetInputQuery,
    IPerformanceAttributionSettingsWidgetInputQueryVariables
  >(PerformanceAttributionSettingsWidgetInputDocument, options);
}
export function usePerformanceAttributionSettingsWidgetInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IPerformanceAttributionSettingsWidgetInputQuery,
    IPerformanceAttributionSettingsWidgetInputQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IPerformanceAttributionSettingsWidgetInputQuery,
    IPerformanceAttributionSettingsWidgetInputQueryVariables
  >(PerformanceAttributionSettingsWidgetInputDocument, options);
}
export function usePerformanceAttributionSettingsWidgetInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IPerformanceAttributionSettingsWidgetInputQuery,
    IPerformanceAttributionSettingsWidgetInputQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    IPerformanceAttributionSettingsWidgetInputQuery,
    IPerformanceAttributionSettingsWidgetInputQueryVariables
  >(PerformanceAttributionSettingsWidgetInputDocument, options);
}
export type PerformanceAttributionSettingsWidgetInputQueryHookResult = ReturnType<
  typeof usePerformanceAttributionSettingsWidgetInputQuery
>;
export type PerformanceAttributionSettingsWidgetInputLazyQueryHookResult = ReturnType<
  typeof usePerformanceAttributionSettingsWidgetInputLazyQuery
>;
export type PerformanceAttributionSettingsWidgetInputSuspenseQueryHookResult = ReturnType<
  typeof usePerformanceAttributionSettingsWidgetInputSuspenseQuery
>;
export type PerformanceAttributionSettingsWidgetInputQueryResult = Apollo.QueryResult<
  IPerformanceAttributionSettingsWidgetInputQuery,
  IPerformanceAttributionSettingsWidgetInputQueryVariables
>;
export const DailyPnlDocument = gql`
    query DailyPnl($subAccountAssetFilters: SubAccountAssetFilterInputType!, $since: Date, $to: Date) {
  portfolio {
    subAccountsTimeWeightedReturns(
      filters: {subAccountAssetFilters: $subAccountAssetFilters, date: {since: $since, to: $to}}
    ) {
      subAccounts {
        subAccount {
          ...SubAccountLabelFields
        }
        values
      }
    }
    subFunds {
      list {
        ...SubFundToSubAccountFields
      }
    }
    accounts {
      ...AccountFields
    }
  }
}
    ${SubAccountLabelFieldsFragmentDoc}
${SubFundToSubAccountFieldsFragmentDoc}
${AccountFieldsFragmentDoc}`;

/**
 * __useDailyPnlQuery__
 *
 * To run a query within a React component, call `useDailyPnlQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyPnlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyPnlQuery({
 *   variables: {
 *      subAccountAssetFilters: // value for 'subAccountAssetFilters'
 *      since: // value for 'since'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useDailyPnlQuery(
  baseOptions: Apollo.QueryHookOptions<IDailyPnlQuery, IDailyPnlQueryVariables> &
    ({ variables: IDailyPnlQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IDailyPnlQuery, IDailyPnlQueryVariables>(DailyPnlDocument, options);
}
export function useDailyPnlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IDailyPnlQuery, IDailyPnlQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IDailyPnlQuery, IDailyPnlQueryVariables>(DailyPnlDocument, options);
}
export function useDailyPnlSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IDailyPnlQuery, IDailyPnlQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IDailyPnlQuery, IDailyPnlQueryVariables>(DailyPnlDocument, options);
}
export type DailyPnlQueryHookResult = ReturnType<typeof useDailyPnlQuery>;
export type DailyPnlLazyQueryHookResult = ReturnType<typeof useDailyPnlLazyQuery>;
export type DailyPnlSuspenseQueryHookResult = ReturnType<typeof useDailyPnlSuspenseQuery>;
export type DailyPnlQueryResult = Apollo.QueryResult<IDailyPnlQuery, IDailyPnlQueryVariables>;
export const PortfolioExposureDocument = gql`
    query PortfolioExposure($since: Date, $to: Date, $subAccountAssetFilters: SubAccountAssetFilterInputType!) {
  portfolio {
    snapshot(
      filters: {date: {since: $since, to: $to}, subAccountAssetFilters: $subAccountAssetFilters}
    ) {
      date
      summary {
        exposure {
          total
          gross
          long
          cash
          short
          net
        }
        balance {
          total
        }
      }
    }
  }
}
    `;

/**
 * __usePortfolioExposureQuery__
 *
 * To run a query within a React component, call `usePortfolioExposureQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioExposureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioExposureQuery({
 *   variables: {
 *      since: // value for 'since'
 *      to: // value for 'to'
 *      subAccountAssetFilters: // value for 'subAccountAssetFilters'
 *   },
 * });
 */
export function usePortfolioExposureQuery(
  baseOptions: Apollo.QueryHookOptions<IPortfolioExposureQuery, IPortfolioExposureQueryVariables> &
    ({ variables: IPortfolioExposureQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPortfolioExposureQuery, IPortfolioExposureQueryVariables>(PortfolioExposureDocument, options);
}
export function usePortfolioExposureLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPortfolioExposureQuery, IPortfolioExposureQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPortfolioExposureQuery, IPortfolioExposureQueryVariables>(
    PortfolioExposureDocument,
    options
  );
}
export function usePortfolioExposureSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IPortfolioExposureQuery, IPortfolioExposureQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IPortfolioExposureQuery, IPortfolioExposureQueryVariables>(
    PortfolioExposureDocument,
    options
  );
}
export type PortfolioExposureQueryHookResult = ReturnType<typeof usePortfolioExposureQuery>;
export type PortfolioExposureLazyQueryHookResult = ReturnType<typeof usePortfolioExposureLazyQuery>;
export type PortfolioExposureSuspenseQueryHookResult = ReturnType<typeof usePortfolioExposureSuspenseQuery>;
export type PortfolioExposureQueryResult = Apollo.QueryResult<
  IPortfolioExposureQuery,
  IPortfolioExposureQueryVariables
>;
export const ProfitLossPerformanceInputDocument = gql`
    query ProfitLossPerformanceInput {
  assets {
    clusterByUser
  }
  factorRegression {
    supportedTimeWindow {
      supportedTimeWindow {
        since
        until
      }
    }
  }
  portfolio {
    accounts {
      subAccounts {
        id
      }
    }
  }
}
    `;

/**
 * __useProfitLossPerformanceInputQuery__
 *
 * To run a query within a React component, call `useProfitLossPerformanceInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfitLossPerformanceInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfitLossPerformanceInputQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfitLossPerformanceInputQuery(
  baseOptions?: Apollo.QueryHookOptions<IProfitLossPerformanceInputQuery, IProfitLossPerformanceInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IProfitLossPerformanceInputQuery, IProfitLossPerformanceInputQueryVariables>(
    ProfitLossPerformanceInputDocument,
    options
  );
}
export function useProfitLossPerformanceInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IProfitLossPerformanceInputQuery, IProfitLossPerformanceInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IProfitLossPerformanceInputQuery, IProfitLossPerformanceInputQueryVariables>(
    ProfitLossPerformanceInputDocument,
    options
  );
}
export function useProfitLossPerformanceInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IProfitLossPerformanceInputQuery,
    IProfitLossPerformanceInputQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IProfitLossPerformanceInputQuery, IProfitLossPerformanceInputQueryVariables>(
    ProfitLossPerformanceInputDocument,
    options
  );
}
export type ProfitLossPerformanceInputQueryHookResult = ReturnType<typeof useProfitLossPerformanceInputQuery>;
export type ProfitLossPerformanceInputLazyQueryHookResult = ReturnType<typeof useProfitLossPerformanceInputLazyQuery>;
export type ProfitLossPerformanceInputSuspenseQueryHookResult = ReturnType<
  typeof useProfitLossPerformanceInputSuspenseQuery
>;
export type ProfitLossPerformanceInputQueryResult = Apollo.QueryResult<
  IProfitLossPerformanceInputQuery,
  IProfitLossPerformanceInputQueryVariables
>;
export const SubFundPnlDocument = gql`
    query SubFundPnl($dimensions: [String!]!, $since: Date) {
  portfolio {
    subFundsTimeWeightedReturns(
      filters: {dimensions: {include: $dimensions}, since: $since}
    ) {
      subFunds {
        subFund {
          id
          name
          dimension
          referenceAsset {
            ...AssetLabelFields
          }
        }
        referenceAssetReturns {
          ...SubFundPnlFields
        }
        usdReturns {
          ...SubFundPnlFields
        }
      }
    }
  }
}
    ${AssetLabelFieldsFragmentDoc}
${SubFundPnlFieldsFragmentDoc}`;

/**
 * __useSubFundPnlQuery__
 *
 * To run a query within a React component, call `useSubFundPnlQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubFundPnlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubFundPnlQuery({
 *   variables: {
 *      dimensions: // value for 'dimensions'
 *      since: // value for 'since'
 *   },
 * });
 */
export function useSubFundPnlQuery(
  baseOptions: Apollo.QueryHookOptions<ISubFundPnlQuery, ISubFundPnlQueryVariables> &
    ({ variables: ISubFundPnlQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ISubFundPnlQuery, ISubFundPnlQueryVariables>(SubFundPnlDocument, options);
}
export function useSubFundPnlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ISubFundPnlQuery, ISubFundPnlQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ISubFundPnlQuery, ISubFundPnlQueryVariables>(SubFundPnlDocument, options);
}
export function useSubFundPnlSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ISubFundPnlQuery, ISubFundPnlQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ISubFundPnlQuery, ISubFundPnlQueryVariables>(SubFundPnlDocument, options);
}
export type SubFundPnlQueryHookResult = ReturnType<typeof useSubFundPnlQuery>;
export type SubFundPnlLazyQueryHookResult = ReturnType<typeof useSubFundPnlLazyQuery>;
export type SubFundPnlSuspenseQueryHookResult = ReturnType<typeof useSubFundPnlSuspenseQuery>;
export type SubFundPnlQueryResult = Apollo.QueryResult<ISubFundPnlQuery, ISubFundPnlQueryVariables>;
export const SubFundPnlInputDocument = gql`
    query SubFundPnlInput($field: String!) {
  portfolio {
    subFunds {
      list {
        dimension
      }
    }
  }
  management {
    userSettings(field: $field)
  }
}
    `;

/**
 * __useSubFundPnlInputQuery__
 *
 * To run a query within a React component, call `useSubFundPnlInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubFundPnlInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubFundPnlInputQuery({
 *   variables: {
 *      field: // value for 'field'
 *   },
 * });
 */
export function useSubFundPnlInputQuery(
  baseOptions: Apollo.QueryHookOptions<ISubFundPnlInputQuery, ISubFundPnlInputQueryVariables> &
    ({ variables: ISubFundPnlInputQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ISubFundPnlInputQuery, ISubFundPnlInputQueryVariables>(SubFundPnlInputDocument, options);
}
export function useSubFundPnlInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ISubFundPnlInputQuery, ISubFundPnlInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ISubFundPnlInputQuery, ISubFundPnlInputQueryVariables>(SubFundPnlInputDocument, options);
}
export function useSubFundPnlInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ISubFundPnlInputQuery, ISubFundPnlInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ISubFundPnlInputQuery, ISubFundPnlInputQueryVariables>(
    SubFundPnlInputDocument,
    options
  );
}
export type SubFundPnlInputQueryHookResult = ReturnType<typeof useSubFundPnlInputQuery>;
export type SubFundPnlInputLazyQueryHookResult = ReturnType<typeof useSubFundPnlInputLazyQuery>;
export type SubFundPnlInputSuspenseQueryHookResult = ReturnType<typeof useSubFundPnlInputSuspenseQuery>;
export type SubFundPnlInputQueryResult = Apollo.QueryResult<ISubFundPnlInputQuery, ISubFundPnlInputQueryVariables>;
export const SubFundChartWidgetSettingsInputDocument = gql`
    query SubFundChartWidgetSettingsInput {
  portfolio {
    subFunds {
      list {
        dimension
        referenceAsset {
          ...AssetLabelFields
        }
      }
    }
  }
}
    ${AssetLabelFieldsFragmentDoc}`;

/**
 * __useSubFundChartWidgetSettingsInputQuery__
 *
 * To run a query within a React component, call `useSubFundChartWidgetSettingsInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubFundChartWidgetSettingsInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubFundChartWidgetSettingsInputQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubFundChartWidgetSettingsInputQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ISubFundChartWidgetSettingsInputQuery,
    ISubFundChartWidgetSettingsInputQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ISubFundChartWidgetSettingsInputQuery, ISubFundChartWidgetSettingsInputQueryVariables>(
    SubFundChartWidgetSettingsInputDocument,
    options
  );
}
export function useSubFundChartWidgetSettingsInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ISubFundChartWidgetSettingsInputQuery,
    ISubFundChartWidgetSettingsInputQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ISubFundChartWidgetSettingsInputQuery, ISubFundChartWidgetSettingsInputQueryVariables>(
    SubFundChartWidgetSettingsInputDocument,
    options
  );
}
export function useSubFundChartWidgetSettingsInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ISubFundChartWidgetSettingsInputQuery,
    ISubFundChartWidgetSettingsInputQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ISubFundChartWidgetSettingsInputQuery, ISubFundChartWidgetSettingsInputQueryVariables>(
    SubFundChartWidgetSettingsInputDocument,
    options
  );
}
export type SubFundChartWidgetSettingsInputQueryHookResult = ReturnType<typeof useSubFundChartWidgetSettingsInputQuery>;
export type SubFundChartWidgetSettingsInputLazyQueryHookResult = ReturnType<
  typeof useSubFundChartWidgetSettingsInputLazyQuery
>;
export type SubFundChartWidgetSettingsInputSuspenseQueryHookResult = ReturnType<
  typeof useSubFundChartWidgetSettingsInputSuspenseQuery
>;
export type SubFundChartWidgetSettingsInputQueryResult = Apollo.QueryResult<
  ISubFundChartWidgetSettingsInputQuery,
  ISubFundChartWidgetSettingsInputQueryVariables
>;
export const SubFundGridWidgetSettingsInputDocument = gql`
    query SubFundGridWidgetSettingsInput {
  portfolio {
    subFunds {
      list {
        dimension
      }
    }
  }
}
    `;

/**
 * __useSubFundGridWidgetSettingsInputQuery__
 *
 * To run a query within a React component, call `useSubFundGridWidgetSettingsInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubFundGridWidgetSettingsInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubFundGridWidgetSettingsInputQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubFundGridWidgetSettingsInputQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ISubFundGridWidgetSettingsInputQuery,
    ISubFundGridWidgetSettingsInputQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ISubFundGridWidgetSettingsInputQuery, ISubFundGridWidgetSettingsInputQueryVariables>(
    SubFundGridWidgetSettingsInputDocument,
    options
  );
}
export function useSubFundGridWidgetSettingsInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ISubFundGridWidgetSettingsInputQuery,
    ISubFundGridWidgetSettingsInputQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ISubFundGridWidgetSettingsInputQuery, ISubFundGridWidgetSettingsInputQueryVariables>(
    SubFundGridWidgetSettingsInputDocument,
    options
  );
}
export function useSubFundGridWidgetSettingsInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ISubFundGridWidgetSettingsInputQuery,
    ISubFundGridWidgetSettingsInputQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ISubFundGridWidgetSettingsInputQuery, ISubFundGridWidgetSettingsInputQueryVariables>(
    SubFundGridWidgetSettingsInputDocument,
    options
  );
}
export type SubFundGridWidgetSettingsInputQueryHookResult = ReturnType<typeof useSubFundGridWidgetSettingsInputQuery>;
export type SubFundGridWidgetSettingsInputLazyQueryHookResult = ReturnType<
  typeof useSubFundGridWidgetSettingsInputLazyQuery
>;
export type SubFundGridWidgetSettingsInputSuspenseQueryHookResult = ReturnType<
  typeof useSubFundGridWidgetSettingsInputSuspenseQuery
>;
export type SubFundGridWidgetSettingsInputQueryResult = Apollo.QueryResult<
  ISubFundGridWidgetSettingsInputQuery,
  ISubFundGridWidgetSettingsInputQueryVariables
>;
export const DeleteUnifiedPnlReportDocument = gql`
    mutation DeleteUnifiedPnlReport($analysisId: Int!) {
  deleteUnifiedPnlAnalysis(analysisId: $analysisId)
}
    `;
export type IDeleteUnifiedPnlReportMutationFn = Apollo.MutationFunction<
  IDeleteUnifiedPnlReportMutation,
  IDeleteUnifiedPnlReportMutationVariables
>;

/**
 * __useDeleteUnifiedPnlReportMutation__
 *
 * To run a mutation, you first call `useDeleteUnifiedPnlReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUnifiedPnlReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUnifiedPnlReportMutation, { data, loading, error }] = useDeleteUnifiedPnlReportMutation({
 *   variables: {
 *      analysisId: // value for 'analysisId'
 *   },
 * });
 */
export function useDeleteUnifiedPnlReportMutation(
  baseOptions?: Apollo.MutationHookOptions<IDeleteUnifiedPnlReportMutation, IDeleteUnifiedPnlReportMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IDeleteUnifiedPnlReportMutation, IDeleteUnifiedPnlReportMutationVariables>(
    DeleteUnifiedPnlReportDocument,
    options
  );
}
export type DeleteUnifiedPnlReportMutationHookResult = ReturnType<typeof useDeleteUnifiedPnlReportMutation>;
export type DeleteUnifiedPnlReportMutationResult = Apollo.MutationResult<IDeleteUnifiedPnlReportMutation>;
export type DeleteUnifiedPnlReportMutationOptions = Apollo.BaseMutationOptions<
  IDeleteUnifiedPnlReportMutation,
  IDeleteUnifiedPnlReportMutationVariables
>;
export const GenerateUnifiedPnlReportDocument = gql`
    mutation GenerateUnifiedPnlReport($dimension: String, $assetIds: [UUID!], $since: Date!, $until: Date!) {
  SubmitUnifiedPnLAnalysis(
    subFundDimension: $dimension
    since: $since
    until: $until
    assetIds: $assetIds
  )
}
    `;
export type IGenerateUnifiedPnlReportMutationFn = Apollo.MutationFunction<
  IGenerateUnifiedPnlReportMutation,
  IGenerateUnifiedPnlReportMutationVariables
>;

/**
 * __useGenerateUnifiedPnlReportMutation__
 *
 * To run a mutation, you first call `useGenerateUnifiedPnlReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateUnifiedPnlReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateUnifiedPnlReportMutation, { data, loading, error }] = useGenerateUnifiedPnlReportMutation({
 *   variables: {
 *      dimension: // value for 'dimension'
 *      assetIds: // value for 'assetIds'
 *      since: // value for 'since'
 *      until: // value for 'until'
 *   },
 * });
 */
export function useGenerateUnifiedPnlReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IGenerateUnifiedPnlReportMutation,
    IGenerateUnifiedPnlReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IGenerateUnifiedPnlReportMutation, IGenerateUnifiedPnlReportMutationVariables>(
    GenerateUnifiedPnlReportDocument,
    options
  );
}
export type GenerateUnifiedPnlReportMutationHookResult = ReturnType<typeof useGenerateUnifiedPnlReportMutation>;
export type GenerateUnifiedPnlReportMutationResult = Apollo.MutationResult<IGenerateUnifiedPnlReportMutation>;
export type GenerateUnifiedPnlReportMutationOptions = Apollo.BaseMutationOptions<
  IGenerateUnifiedPnlReportMutation,
  IGenerateUnifiedPnlReportMutationVariables
>;
export const UnifiedPnlDialogInputDocument = gql`
    query UnifiedPnlDialogInput {
  portfolio {
    subFunds {
      list {
        dimension
      }
    }
  }
}
    `;

/**
 * __useUnifiedPnlDialogInputQuery__
 *
 * To run a query within a React component, call `useUnifiedPnlDialogInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnifiedPnlDialogInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnifiedPnlDialogInputQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnifiedPnlDialogInputQuery(
  baseOptions?: Apollo.QueryHookOptions<IUnifiedPnlDialogInputQuery, IUnifiedPnlDialogInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IUnifiedPnlDialogInputQuery, IUnifiedPnlDialogInputQueryVariables>(
    UnifiedPnlDialogInputDocument,
    options
  );
}
export function useUnifiedPnlDialogInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IUnifiedPnlDialogInputQuery, IUnifiedPnlDialogInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IUnifiedPnlDialogInputQuery, IUnifiedPnlDialogInputQueryVariables>(
    UnifiedPnlDialogInputDocument,
    options
  );
}
export function useUnifiedPnlDialogInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IUnifiedPnlDialogInputQuery, IUnifiedPnlDialogInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IUnifiedPnlDialogInputQuery, IUnifiedPnlDialogInputQueryVariables>(
    UnifiedPnlDialogInputDocument,
    options
  );
}
export type UnifiedPnlDialogInputQueryHookResult = ReturnType<typeof useUnifiedPnlDialogInputQuery>;
export type UnifiedPnlDialogInputLazyQueryHookResult = ReturnType<typeof useUnifiedPnlDialogInputLazyQuery>;
export type UnifiedPnlDialogInputSuspenseQueryHookResult = ReturnType<typeof useUnifiedPnlDialogInputSuspenseQuery>;
export type UnifiedPnlDialogInputQueryResult = Apollo.QueryResult<
  IUnifiedPnlDialogInputQuery,
  IUnifiedPnlDialogInputQueryVariables
>;
export const UnifiedPnlReportDocument = gql`
    query UnifiedPnlReport($reportId: Int!) {
  portfolio {
    unifiedPnl(analysisId: $reportId) {
      date
      asset {
        ...AssetLabelFields
        derivativeDetails {
          baseAsset {
            ...MainNonDerivativeBaseAssetFields
          }
        }
      }
      subFund {
        name
      }
      amount
      avgPrice
      price
      rpnl
      upnl
    }
  }
}
    ${AssetLabelFieldsFragmentDoc}
${MainNonDerivativeBaseAssetFieldsFragmentDoc}`;

/**
 * __useUnifiedPnlReportQuery__
 *
 * To run a query within a React component, call `useUnifiedPnlReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnifiedPnlReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnifiedPnlReportQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useUnifiedPnlReportQuery(
  baseOptions: Apollo.QueryHookOptions<IUnifiedPnlReportQuery, IUnifiedPnlReportQueryVariables> &
    ({ variables: IUnifiedPnlReportQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IUnifiedPnlReportQuery, IUnifiedPnlReportQueryVariables>(UnifiedPnlReportDocument, options);
}
export function useUnifiedPnlReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IUnifiedPnlReportQuery, IUnifiedPnlReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IUnifiedPnlReportQuery, IUnifiedPnlReportQueryVariables>(
    UnifiedPnlReportDocument,
    options
  );
}
export function useUnifiedPnlReportSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IUnifiedPnlReportQuery, IUnifiedPnlReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IUnifiedPnlReportQuery, IUnifiedPnlReportQueryVariables>(
    UnifiedPnlReportDocument,
    options
  );
}
export type UnifiedPnlReportQueryHookResult = ReturnType<typeof useUnifiedPnlReportQuery>;
export type UnifiedPnlReportLazyQueryHookResult = ReturnType<typeof useUnifiedPnlReportLazyQuery>;
export type UnifiedPnlReportSuspenseQueryHookResult = ReturnType<typeof useUnifiedPnlReportSuspenseQuery>;
export type UnifiedPnlReportQueryResult = Apollo.QueryResult<IUnifiedPnlReportQuery, IUnifiedPnlReportQueryVariables>;
export const UnifiedPnlReportListInputDocument = gql`
    query UnifiedPnlReportListInput {
  portfolio {
    unifiedPnlStatus {
      status
      since
      until
      createdAt
      subFundDimension
      id
      assets {
        ...AssetLabelFields
      }
    }
  }
}
    ${AssetLabelFieldsFragmentDoc}`;

/**
 * __useUnifiedPnlReportListInputQuery__
 *
 * To run a query within a React component, call `useUnifiedPnlReportListInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnifiedPnlReportListInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnifiedPnlReportListInputQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnifiedPnlReportListInputQuery(
  baseOptions?: Apollo.QueryHookOptions<IUnifiedPnlReportListInputQuery, IUnifiedPnlReportListInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IUnifiedPnlReportListInputQuery, IUnifiedPnlReportListInputQueryVariables>(
    UnifiedPnlReportListInputDocument,
    options
  );
}
export function useUnifiedPnlReportListInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IUnifiedPnlReportListInputQuery, IUnifiedPnlReportListInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IUnifiedPnlReportListInputQuery, IUnifiedPnlReportListInputQueryVariables>(
    UnifiedPnlReportListInputDocument,
    options
  );
}
export function useUnifiedPnlReportListInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IUnifiedPnlReportListInputQuery,
    IUnifiedPnlReportListInputQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IUnifiedPnlReportListInputQuery, IUnifiedPnlReportListInputQueryVariables>(
    UnifiedPnlReportListInputDocument,
    options
  );
}
export type UnifiedPnlReportListInputQueryHookResult = ReturnType<typeof useUnifiedPnlReportListInputQuery>;
export type UnifiedPnlReportListInputLazyQueryHookResult = ReturnType<typeof useUnifiedPnlReportListInputLazyQuery>;
export type UnifiedPnlReportListInputSuspenseQueryHookResult = ReturnType<
  typeof useUnifiedPnlReportListInputSuspenseQuery
>;
export type UnifiedPnlReportListInputQueryResult = Apollo.QueryResult<
  IUnifiedPnlReportListInputQuery,
  IUnifiedPnlReportListInputQueryVariables
>;
export const SnapshotEditorInputDocument = gql`
    query SnapshotEditorInput {
  portfolio {
    accounts {
      id
      name
      subAccounts {
        id
        name
        createdAt
      }
      venue {
        label
      }
    }
  }
}
    `;

/**
 * __useSnapshotEditorInputQuery__
 *
 * To run a query within a React component, call `useSnapshotEditorInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useSnapshotEditorInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSnapshotEditorInputQuery({
 *   variables: {
 *   },
 * });
 */
export function useSnapshotEditorInputQuery(
  baseOptions?: Apollo.QueryHookOptions<ISnapshotEditorInputQuery, ISnapshotEditorInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ISnapshotEditorInputQuery, ISnapshotEditorInputQueryVariables>(
    SnapshotEditorInputDocument,
    options
  );
}
export function useSnapshotEditorInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ISnapshotEditorInputQuery, ISnapshotEditorInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ISnapshotEditorInputQuery, ISnapshotEditorInputQueryVariables>(
    SnapshotEditorInputDocument,
    options
  );
}
export function useSnapshotEditorInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ISnapshotEditorInputQuery, ISnapshotEditorInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ISnapshotEditorInputQuery, ISnapshotEditorInputQueryVariables>(
    SnapshotEditorInputDocument,
    options
  );
}
export type SnapshotEditorInputQueryHookResult = ReturnType<typeof useSnapshotEditorInputQuery>;
export type SnapshotEditorInputLazyQueryHookResult = ReturnType<typeof useSnapshotEditorInputLazyQuery>;
export type SnapshotEditorInputSuspenseQueryHookResult = ReturnType<typeof useSnapshotEditorInputSuspenseQuery>;
export type SnapshotEditorInputQueryResult = Apollo.QueryResult<
  ISnapshotEditorInputQuery,
  ISnapshotEditorInputQueryVariables
>;
export const SnapshotPositionsDocument = gql`
    query SnapshotPositions($date: Date!, $subAccountId: UUID!, $exchange: LabelType!) {
  portfolio {
    snapshot(
      filters: {date: {since: $date, to: $date}, subAccountAssetFilters: {subAccountIds: [$subAccountId]}}
    ) {
      positions {
        asset {
          id
        }
        spot {
          amount
          balance
          price
          slot
        }
      }
    }
  }
  assets {
    list(filters: {exchanges: [$exchange], assetTypes: [PUBLIC, EXCHANGE]}) {
      ...MainAssetFields
    }
  }
}
    ${MainAssetFieldsFragmentDoc}`;

/**
 * __useSnapshotPositionsQuery__
 *
 * To run a query within a React component, call `useSnapshotPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSnapshotPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSnapshotPositionsQuery({
 *   variables: {
 *      date: // value for 'date'
 *      subAccountId: // value for 'subAccountId'
 *      exchange: // value for 'exchange'
 *   },
 * });
 */
export function useSnapshotPositionsQuery(
  baseOptions: Apollo.QueryHookOptions<ISnapshotPositionsQuery, ISnapshotPositionsQueryVariables> &
    ({ variables: ISnapshotPositionsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ISnapshotPositionsQuery, ISnapshotPositionsQueryVariables>(SnapshotPositionsDocument, options);
}
export function useSnapshotPositionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ISnapshotPositionsQuery, ISnapshotPositionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ISnapshotPositionsQuery, ISnapshotPositionsQueryVariables>(
    SnapshotPositionsDocument,
    options
  );
}
export function useSnapshotPositionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ISnapshotPositionsQuery, ISnapshotPositionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ISnapshotPositionsQuery, ISnapshotPositionsQueryVariables>(
    SnapshotPositionsDocument,
    options
  );
}
export type SnapshotPositionsQueryHookResult = ReturnType<typeof useSnapshotPositionsQuery>;
export type SnapshotPositionsLazyQueryHookResult = ReturnType<typeof useSnapshotPositionsLazyQuery>;
export type SnapshotPositionsSuspenseQueryHookResult = ReturnType<typeof useSnapshotPositionsSuspenseQuery>;
export type SnapshotPositionsQueryResult = Apollo.QueryResult<
  ISnapshotPositionsQuery,
  ISnapshotPositionsQueryVariables
>;
export const UpdateSnapshotPositionDocument = gql`
    mutation UpdateSnapshotPosition($assetId: UUID!, $subAccountId: UUID!, $date: Date!, $amount: Decimal!, $slot: SlotType!) {
  UpdateSnapshotPosition(
    updateInput: {assetId: $assetId, date: $date, amount: $amount, subAccountId: $subAccountId, slot: $slot}
  )
}
    `;
export type IUpdateSnapshotPositionMutationFn = Apollo.MutationFunction<
  IUpdateSnapshotPositionMutation,
  IUpdateSnapshotPositionMutationVariables
>;

/**
 * __useUpdateSnapshotPositionMutation__
 *
 * To run a mutation, you first call `useUpdateSnapshotPositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSnapshotPositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSnapshotPositionMutation, { data, loading, error }] = useUpdateSnapshotPositionMutation({
 *   variables: {
 *      assetId: // value for 'assetId'
 *      subAccountId: // value for 'subAccountId'
 *      date: // value for 'date'
 *      amount: // value for 'amount'
 *      slot: // value for 'slot'
 *   },
 * });
 */
export function useUpdateSnapshotPositionMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdateSnapshotPositionMutation, IUpdateSnapshotPositionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IUpdateSnapshotPositionMutation, IUpdateSnapshotPositionMutationVariables>(
    UpdateSnapshotPositionDocument,
    options
  );
}
export type UpdateSnapshotPositionMutationHookResult = ReturnType<typeof useUpdateSnapshotPositionMutation>;
export type UpdateSnapshotPositionMutationResult = Apollo.MutationResult<IUpdateSnapshotPositionMutation>;
export type UpdateSnapshotPositionMutationOptions = Apollo.BaseMutationOptions<
  IUpdateSnapshotPositionMutation,
  IUpdateSnapshotPositionMutationVariables
>;
export const SearchableAssetIdsDocument = gql`
    query SearchableAssetIds {
  assets {
    feature(feature: SEARCH) {
      id
    }
  }
}
    `;

/**
 * __useSearchableAssetIdsQuery__
 *
 * To run a query within a React component, call `useSearchableAssetIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchableAssetIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchableAssetIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchableAssetIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<ISearchableAssetIdsQuery, ISearchableAssetIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ISearchableAssetIdsQuery, ISearchableAssetIdsQueryVariables>(
    SearchableAssetIdsDocument,
    options
  );
}
export function useSearchableAssetIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ISearchableAssetIdsQuery, ISearchableAssetIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ISearchableAssetIdsQuery, ISearchableAssetIdsQueryVariables>(
    SearchableAssetIdsDocument,
    options
  );
}
export function useSearchableAssetIdsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ISearchableAssetIdsQuery, ISearchableAssetIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ISearchableAssetIdsQuery, ISearchableAssetIdsQueryVariables>(
    SearchableAssetIdsDocument,
    options
  );
}
export type SearchableAssetIdsQueryHookResult = ReturnType<typeof useSearchableAssetIdsQuery>;
export type SearchableAssetIdsLazyQueryHookResult = ReturnType<typeof useSearchableAssetIdsLazyQuery>;
export type SearchableAssetIdsSuspenseQueryHookResult = ReturnType<typeof useSearchableAssetIdsSuspenseQuery>;
export type SearchableAssetIdsQueryResult = Apollo.QueryResult<
  ISearchableAssetIdsQuery,
  ISearchableAssetIdsQueryVariables
>;
export const SearchableAssetsDocument = gql`
    query SearchableAssets {
  assets {
    feature(feature: SEARCH) {
      ...MainAssetFields
    }
  }
}
    ${MainAssetFieldsFragmentDoc}`;

/**
 * __useSearchableAssetsQuery__
 *
 * To run a query within a React component, call `useSearchableAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchableAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchableAssetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchableAssetsQuery(
  baseOptions?: Apollo.QueryHookOptions<ISearchableAssetsQuery, ISearchableAssetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ISearchableAssetsQuery, ISearchableAssetsQueryVariables>(SearchableAssetsDocument, options);
}
export function useSearchableAssetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ISearchableAssetsQuery, ISearchableAssetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ISearchableAssetsQuery, ISearchableAssetsQueryVariables>(
    SearchableAssetsDocument,
    options
  );
}
export function useSearchableAssetsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ISearchableAssetsQuery, ISearchableAssetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ISearchableAssetsQuery, ISearchableAssetsQueryVariables>(
    SearchableAssetsDocument,
    options
  );
}
export type SearchableAssetsQueryHookResult = ReturnType<typeof useSearchableAssetsQuery>;
export type SearchableAssetsLazyQueryHookResult = ReturnType<typeof useSearchableAssetsLazyQuery>;
export type SearchableAssetsSuspenseQueryHookResult = ReturnType<typeof useSearchableAssetsSuspenseQuery>;
export type SearchableAssetsQueryResult = Apollo.QueryResult<ISearchableAssetsQuery, ISearchableAssetsQueryVariables>;
export const OrganizationFeaturesDocument = gql`
    query OrganizationFeatures {
  management {
    features {
      name
      status
    }
  }
}
    `;

/**
 * __useOrganizationFeaturesQuery__
 *
 * To run a query within a React component, call `useOrganizationFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationFeaturesQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationFeaturesQuery(
  baseOptions?: Apollo.QueryHookOptions<IOrganizationFeaturesQuery, IOrganizationFeaturesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IOrganizationFeaturesQuery, IOrganizationFeaturesQueryVariables>(
    OrganizationFeaturesDocument,
    options
  );
}
export function useOrganizationFeaturesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IOrganizationFeaturesQuery, IOrganizationFeaturesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IOrganizationFeaturesQuery, IOrganizationFeaturesQueryVariables>(
    OrganizationFeaturesDocument,
    options
  );
}
export function useOrganizationFeaturesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IOrganizationFeaturesQuery, IOrganizationFeaturesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IOrganizationFeaturesQuery, IOrganizationFeaturesQueryVariables>(
    OrganizationFeaturesDocument,
    options
  );
}
export type OrganizationFeaturesQueryHookResult = ReturnType<typeof useOrganizationFeaturesQuery>;
export type OrganizationFeaturesLazyQueryHookResult = ReturnType<typeof useOrganizationFeaturesLazyQuery>;
export type OrganizationFeaturesSuspenseQueryHookResult = ReturnType<typeof useOrganizationFeaturesSuspenseQuery>;
export type OrganizationFeaturesQueryResult = Apollo.QueryResult<
  IOrganizationFeaturesQuery,
  IOrganizationFeaturesQueryVariables
>;
export const SubAccountAssetAssetFiltersInputDocument = gql`
    query SubAccountAssetAssetFiltersInput($assetIds: [UUID!]!) {
  assets {
    list(filters: {assetIds: $assetIds}) {
      ...AssetLabelFields
    }
  }
}
    ${AssetLabelFieldsFragmentDoc}`;

/**
 * __useSubAccountAssetAssetFiltersInputQuery__
 *
 * To run a query within a React component, call `useSubAccountAssetAssetFiltersInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubAccountAssetAssetFiltersInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubAccountAssetAssetFiltersInputQuery({
 *   variables: {
 *      assetIds: // value for 'assetIds'
 *   },
 * });
 */
export function useSubAccountAssetAssetFiltersInputQuery(
  baseOptions: Apollo.QueryHookOptions<
    ISubAccountAssetAssetFiltersInputQuery,
    ISubAccountAssetAssetFiltersInputQueryVariables
  > &
    ({ variables: ISubAccountAssetAssetFiltersInputQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ISubAccountAssetAssetFiltersInputQuery, ISubAccountAssetAssetFiltersInputQueryVariables>(
    SubAccountAssetAssetFiltersInputDocument,
    options
  );
}
export function useSubAccountAssetAssetFiltersInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ISubAccountAssetAssetFiltersInputQuery,
    ISubAccountAssetAssetFiltersInputQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ISubAccountAssetAssetFiltersInputQuery, ISubAccountAssetAssetFiltersInputQueryVariables>(
    SubAccountAssetAssetFiltersInputDocument,
    options
  );
}
export function useSubAccountAssetAssetFiltersInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ISubAccountAssetAssetFiltersInputQuery,
    ISubAccountAssetAssetFiltersInputQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ISubAccountAssetAssetFiltersInputQuery,
    ISubAccountAssetAssetFiltersInputQueryVariables
  >(SubAccountAssetAssetFiltersInputDocument, options);
}
export type SubAccountAssetAssetFiltersInputQueryHookResult = ReturnType<
  typeof useSubAccountAssetAssetFiltersInputQuery
>;
export type SubAccountAssetAssetFiltersInputLazyQueryHookResult = ReturnType<
  typeof useSubAccountAssetAssetFiltersInputLazyQuery
>;
export type SubAccountAssetAssetFiltersInputSuspenseQueryHookResult = ReturnType<
  typeof useSubAccountAssetAssetFiltersInputSuspenseQuery
>;
export type SubAccountAssetAssetFiltersInputQueryResult = Apollo.QueryResult<
  ISubAccountAssetAssetFiltersInputQuery,
  ISubAccountAssetAssetFiltersInputQueryVariables
>;
export const SubAccountAssetFiltersInputDocument = gql`
    query SubAccountAssetFiltersInput {
  portfolio {
    subFunds {
      list {
        id
        name
        dimension
      }
    }
    accounts {
      id
      name
      subAccounts {
        id
        name
      }
      venue {
        label
      }
    }
  }
}
    `;

/**
 * __useSubAccountAssetFiltersInputQuery__
 *
 * To run a query within a React component, call `useSubAccountAssetFiltersInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubAccountAssetFiltersInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubAccountAssetFiltersInputQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubAccountAssetFiltersInputQuery(
  baseOptions?: Apollo.QueryHookOptions<ISubAccountAssetFiltersInputQuery, ISubAccountAssetFiltersInputQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ISubAccountAssetFiltersInputQuery, ISubAccountAssetFiltersInputQueryVariables>(
    SubAccountAssetFiltersInputDocument,
    options
  );
}
export function useSubAccountAssetFiltersInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ISubAccountAssetFiltersInputQuery,
    ISubAccountAssetFiltersInputQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ISubAccountAssetFiltersInputQuery, ISubAccountAssetFiltersInputQueryVariables>(
    SubAccountAssetFiltersInputDocument,
    options
  );
}
export function useSubAccountAssetFiltersInputSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ISubAccountAssetFiltersInputQuery,
    ISubAccountAssetFiltersInputQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ISubAccountAssetFiltersInputQuery, ISubAccountAssetFiltersInputQueryVariables>(
    SubAccountAssetFiltersInputDocument,
    options
  );
}
export type SubAccountAssetFiltersInputQueryHookResult = ReturnType<typeof useSubAccountAssetFiltersInputQuery>;
export type SubAccountAssetFiltersInputLazyQueryHookResult = ReturnType<typeof useSubAccountAssetFiltersInputLazyQuery>;
export type SubAccountAssetFiltersInputSuspenseQueryHookResult = ReturnType<
  typeof useSubAccountAssetFiltersInputSuspenseQuery
>;
export type SubAccountAssetFiltersInputQueryResult = Apollo.QueryResult<
  ISubAccountAssetFiltersInputQuery,
  ISubAccountAssetFiltersInputQueryVariables
>;
export const CreatePresignedUrlDocument = gql`
    mutation CreatePresignedUrl($contentType: String!, $fileName: String!) {
  createPresignedUrl(contentType: $contentType, fileName: $fileName) {
    remoteFile {
      id
    }
    uploadLink
  }
}
    `;
export type ICreatePresignedUrlMutationFn = Apollo.MutationFunction<
  ICreatePresignedUrlMutation,
  ICreatePresignedUrlMutationVariables
>;

/**
 * __useCreatePresignedUrlMutation__
 *
 * To run a mutation, you first call `useCreatePresignedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePresignedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPresignedUrlMutation, { data, loading, error }] = useCreatePresignedUrlMutation({
 *   variables: {
 *      contentType: // value for 'contentType'
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useCreatePresignedUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<ICreatePresignedUrlMutation, ICreatePresignedUrlMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICreatePresignedUrlMutation, ICreatePresignedUrlMutationVariables>(
    CreatePresignedUrlDocument,
    options
  );
}
export type CreatePresignedUrlMutationHookResult = ReturnType<typeof useCreatePresignedUrlMutation>;
export type CreatePresignedUrlMutationResult = Apollo.MutationResult<ICreatePresignedUrlMutation>;
export type CreatePresignedUrlMutationOptions = Apollo.BaseMutationOptions<
  ICreatePresignedUrlMutation,
  ICreatePresignedUrlMutationVariables
>;

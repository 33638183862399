import GButton from '../../technical/inputs/GButton/GButton.tsx';
import { Close } from '@mui/icons-material';
import { Gear } from '../../technical/icons';

const iconStyle = {
  color: 'inherit',
  fontSize: 'xl',
} as const;

const iconWidth = '1.5rem';
export const iconPadding = '2rem';

export const CustomWidgetCloseAction = ({
  editingWidget,
  setEditingWidget,
  forceStatic,
}: { editingWidget: boolean; setEditingWidget: (val: boolean) => void; forceStatic: (val: boolean) => void }) => (
  <GButton
    data-tour="widget-card-close-button"
    onMouseDown={(e) => {
      e.preventDefault();
      e.stopPropagation();
      setEditingWidget(!editingWidget);
      forceStatic(!editingWidget);
    }}
    variant={'plain'}
    color={'neutral'}
    type="button"
    removeDecoratorMargin
    sx={{
      width: iconWidth,
      // default button has min height larger than the icon size
      minHeight: 0,
    }}
    startDecorator={editingWidget ? <Close {...iconStyle} /> : <Gear {...iconStyle} />}
  />
);

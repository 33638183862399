import dayjs, { type Dayjs } from 'dayjs';
import isNil from 'lodash/fp/isNil';
import { formatISODate } from './formatter.utils';

/** utc timezone code */
export const UTC = 'UTC';
// there is no available list of timezones, only option dynamically check via isValidTimezone
export type Timezone = string;

export const isValidDayjsDate = (date: unknown): date is Dayjs => {
  if (!dayjs.isDayjs(date)) {
    return true;
  }

  return date.isValid();
};

export const isValidDayjsDateRange = (dateRange: unknown): dateRange is null | [Dayjs, Dayjs] => {
  if (isNil(dateRange)) {
    return true;
  }

  if (!Array.isArray(dateRange)) {
    return false;
  }

  if (dateRange.length !== 2) {
    return false;
  }

  if (dateRange.some((date) => !dayjs.isDayjs(date))) {
    return false;
  }

  if (!dateRange.every((date) => date.isValid())) {
    return false;
  }

  return !dateRange[0].isAfter(dateRange[1]);
};

export const isDayjsDateNotInTheFuture = (date: unknown): boolean => {
  if (!dayjs.isDayjs(date)) {
    return true;
  }

  return !isDayjsAfter(date, dayjs());
};

export const isDayjsAfter = (date: unknown, otherDate: Dayjs): boolean => {
  if (!dayjs.isDayjs(date)) {
    return true;
  }

  return date.isAfter(otherDate);
};

export const isDayjsAfterEqual = (date: unknown, otherDate: Dayjs): boolean => {
  if (!dayjs.isDayjs(date)) {
    return true;
  }

  return !date.isBefore(otherDate);
};

export const parseUtcDate = (isoDateTime: UtcDate): Dayjs => {
  return dayjs.utc(isoDateTime.toString());
};

// move timezone to utc and take date
export const convertDateInUtcToUTCISODate = (date: Dayjs): UtcDate => {
  return formatISODate(date.utc());
};

export const isValidTime = (time: Dayjs): boolean => {
  return time.isValid();
};

export const getSecondsFromTime = (time: Dayjs): number => {
  return time.diff(time.startOf('day'), 's');
};

export function convertDateInUtcToLocalWithoutOffset(date: Dayjs): Dayjs;
export function convertDateInUtcToLocalWithoutOffset(date: Dayjs | undefined | null): Dayjs | null;
export function convertDateInUtcToLocalWithoutOffset(date: Dayjs | undefined | null): Dayjs | null {
  if (!date) {
    return null;
  }
  return dayjs()
    .year(date.year())
    .month(date.month())
    .date(date.date())
    .hour(date.hour())
    .minute(date.minute())
    .second(date.second())
    .millisecond(date.millisecond());
}

/**
 * drops local timezone - 14:30:00+02:00 -> 14:30:00+00:00
 * react-datepicker only works with local time, but as we use utc dates in genie we need to treat local dates as utc
 */
export function convertLocalDateToUTCWithoutOffset(localDate: Dayjs | Date): Dayjs;
export function convertLocalDateToUTCWithoutOffset(localDate: Dayjs | Date | null): Dayjs | null;
export function convertLocalDateToUTCWithoutOffset(localDate: Dayjs | Date | null): Dayjs | null {
  if (!localDate) {
    return null;
  }

  return dayjs(localDate).utc(true);
}

export function convertLocalDateToTimezoneWithoutOffset(localDate: Date | Dayjs | string, timezone: Timezone): Dayjs;
export function convertLocalDateToTimezoneWithoutOffset(localDate: null, timezone: Timezone): null;

export function convertLocalDateToTimezoneWithoutOffset(
  localDate: Date | Dayjs | string | null,
  timezone: Timezone
): Dayjs | null {
  if (!localDate) {
    return null;
  }
  const dayjsDate = dayjs.isDayjs(localDate) ? localDate : dayjs(localDate);

  return setTimezone(dayjsDate, timezone, true);
}

export function isValidTimezone(timezoneToCheck: string): boolean {
  try {
    dayjs().tz(timezoneToCheck);
    return true;
  } catch {
    return false;
  }
}

export const setTimezone = (date: Dayjs, timezone: Timezone, keepLocalTime = false): Dayjs =>
  isUtcEquivalent(timezone) ? date.utc(keepLocalTime) : date.tz(timezone, keepLocalTime);

const isUtcEquivalentCache = new Map<Timezone, boolean>();
/**
 * most of dayjs functions like startOf/endOf are broken for all utc equivalent timezones like utc, europe/london etc e.g. https://github.com/iamkun/dayjs/issues/2507
 * workaround is to check and use dayjs.utc() instead of date.tz('UTC')
 */
export const isUtcEquivalent = (timezone: Timezone): boolean => {
  if (isUtcEquivalentCache.has(timezone)) {
    return isUtcEquivalentCache.get(timezone)!;
  }
  const isUtc = dayjs().tz(timezone).isUTC();
  isUtcEquivalentCache.set(timezone, isUtc);
  return isUtc;
};

export const now = (timezone: Timezone): Dayjs => (isUtcEquivalent(timezone) ? dayjs.utc() : dayjs().tz(timezone));

export const validateRequiredDate = (
  dateText: string
):
  | {
      error: null;
      value: Dayjs;
    }
  | {
      error: string;
      value: null;
    } => {
  if (dateText === '') {
    return {
      error: 'Empty date',
      value: null,
    };
  }

  const date = dateText.match(/^\d{4}-\d{2}-\d{2}$/);
  if (!date) {
    return {
      error: 'Date has invalid format',
      value: null,
    };
  }

  const parsedDate = dayjs.utc(dateText, 'YYYY-MM-DD', true);
  if (!parsedDate.isValid()) {
    return {
      error: 'Date is invalid',
      value: null,
    };
  }

  return {
    error: null,
    value: parsedDate,
  };
};

import { ApolloProvider } from '@apollo/client';
import { CssBaseline } from '@mui/joy';
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import { StyledEngineProvider } from '@mui/material';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { AuthnProvider } from './Authn.tsx';
import apolloClient from './createApollo';
import MaterialTheme from './MaterialTheme';
import Root from './Root';
import '@fontsource/inter';
import './App.css';
import { joyTheme } from './theme/joyTheme';
import { AuthzProvider } from './Authz.tsx';
import VersionChecker from './VersionChecker.tsx';

import '@sjmc11/tourguidejs/dist/css/tour.min.css';

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <StyledEngineProvider>
      <JoyCssVarsProvider theme={joyTheme}>
        <CssBaseline />
        <MaterialTheme>
          <ApolloProvider client={apolloClient}>
            <AuthnProvider>
              <AuthzProvider>
                <Root />
                <VersionChecker />
              </AuthzProvider>
            </AuthnProvider>
          </ApolloProvider>
        </MaterialTheme>
      </JoyCssVarsProvider>
    </StyledEngineProvider>
  </StrictMode>
);

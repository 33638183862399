import { type Components, createGetCssVar, extendTheme, type Theme } from '@mui/joy';
import type { CSSInterpolation, Components as MuiComponents } from '@mui/material';

const getCssVar = createGetCssVar('joy');
const componentsOverride = {
  // overriding options in material ui theme no longer works for components and we need to put them in joyui theme
  // the reason is that they share the same context and the last one wins
  MuiPagination: {
    defaultProps: {
      size: 'small',
      showFirstButton: true,
      showLastButton: true,
    },
  },
  JoyFormControl: {
    defaultProps: {
      size: 'sm',
    },
  },
  JoyListItemButton: {
    styleOverrides: {
      root: ({ theme }): CSSInterpolation => {
        return {
          '.MuiDrawer-root &.Mui-selected': { backgroundColor: theme.palette.neutral.plainHoverBg },
        };
      },
    },
  },
  JoyAutocompleteListbox: {
    defaultProps: {
      size: 'sm',
    },
  },
  JoyButton: {
    defaultProps: {
      size: 'sm',
    },
  },
  JoyIconButton: {
    defaultProps: {
      size: 'sm',
    },
  },
  JoyButtonGroup: {
    defaultProps: {
      size: 'sm',
    },
  },
  JoyAvatar: {
    defaultProps: {
      size: 'sm',
    },
  },
  JoyBadge: {
    defaultProps: {
      size: 'sm',
    },
  },
  JoyRadio: {
    defaultProps: {
      size: 'sm',
    },
  },
  JoyMenu: {
    defaultProps: {
      size: 'sm',
      color: 'neutral',
    },
  },
  JoyMenuList: {
    defaultProps: {
      size: 'sm',
    },
  },
  JoyList: {
    defaultProps: {
      size: 'sm',
    },
  },
  JoyStack: {
    defaultProps: {
      useFlexGap: true,
    },
  },
  JoyInput: {
    styleOverrides: {
      input: {
        width: '100%',
      },
    },
  },
  JoySwitch: {
    defaultProps: {
      size: 'sm',
    },
  },
  JoyAutocomplete: {
    styleOverrides: {
      input: ({ ownerState }): CSSInterpolation => {
        return {
          ...(!ownerState.focused && {
            minWidth: '5px', // lower minWidth to allow for prevent autocomplete from taking two lines when element is collapsed
          }),
        };
      },
    },
    defaultProps: {
      size: 'sm',
    },
  },
  JoyGrid: {
    styleOverrides: {
      root: (): CSSInterpolation => {
        return {
          '.MuiDivider-vertical': { marginRight: '-1px' },
        };
      },
    },
    defaultProps: {
      spacing: 1.5,
    },
  },
  JoyTabs: {
    defaultProps: {
      size: 'sm',
    },
    styleOverrides: {
      root: {
        background: 'inherit',
      },
    },
  },
  JoyTabList: {
    styleOverrides: {
      root: ({ theme }): CSSInterpolation => {
        return {
          backgroundColor: 'transparent',
          '& .MuiTab-root': {
            color: theme.palette.text.primary,
          },
          '& .MuiTab-root[aria-selected="true"]': {
            backgroundColor: 'transparent',
            color: theme.palette.primary.plainColor,
          },
          '& .MuiTab-root[aria-selected="true"]::after': {
            backgroundColor: theme.palette.primary[500],
          },
        };
      },
    },
  },
  JoyModalDialog: {
    defaultProps: {
      size: 'md',
    },
    styleOverrides: {
      root: ({ ownerState, theme }): CSSInterpolation => {
        const { size } = ownerState;
        const extraStyles: CSSInterpolation = { paddingTop: '1.5rem', gap: 0 };
        if (size === 'lg') {
          extraStyles['--Card-radius'] = theme.radius.md;
        } else if (size === 'md') {
          extraStyles.paddingTop = '2rem';
        }

        return {
          backgroundColor: theme.palette.background.body,
          ...extraStyles,
        };
      },
    },
  },
  JoyDialogTitle: {
    styleOverrides: {
      root: ({ ownerState, theme }): CSSInterpolation => {
        // reverse mapping from DialogTitle.js joyui component
        const dialogSizeToModalSize: Record<string, 'sm' | 'md' | 'lg'> = {
          'title-md': 'sm',
          'title-lg': 'md',
          h4: 'lg',
        };

        let extraStyles: CSSInterpolation = {
          paddingRight: '2.5rem', // make space for closing dialog button
          paddingBottom: '0',
        };

        const modalSize = dialogSizeToModalSize[ownerState.level ?? ''] as unknown as 'sm' | 'md' | 'lg' | undefined;
        if (modalSize === 'lg') {
          extraStyles = {
            ...extraStyles,
            ...theme.typography.h3,
            paddingBottom: '1rem',
          };
        } else if (modalSize === 'md') {
          extraStyles = {
            ...extraStyles,
            ...theme.typography.h4,
            paddingBottom: '1.5rem',
          };
        }

        return extraStyles;
      },
    },
  },
  JoyModalClose: {
    styleOverrides: {
      root: ({ ownerState }): CSSInterpolation => {
        const { size } = ownerState;
        const extraStyles: CSSInterpolation = {
          top: '1.5rem',
          right: '1.5rem',
        };

        if (size === 'md') {
          extraStyles.top = '1.75rem';
        }

        return {
          ...extraStyles,
        };
      },
    },
  },
  JoyTooltip: {
    defaultProps: {
      size: 'sm',
    },
    styleOverrides: {
      root: {
        maxWidth: '400px',
      },
    },
  },
  JoyCheckbox: {
    defaultProps: {
      size: 'sm',
    },
  },
  JoyCard: {
    defaultProps: {
      variant: 'outlined',
      size: 'md',
    },
    styleOverrides: {
      root: ({ ownerState, theme }): CSSInterpolation => ({
        borderRadius: theme.radius.md,
        boxShadow: ownerState.variant === 'outlined' ? theme.shadow.sm : '',
        border: ownerState.variant === 'outlined' ? `1px solid ${theme.palette.secondary['50']}` : '',
      }),
    },
  },
} satisfies Components<Theme> & MuiComponents<unknown>;

export const joyTheme: Theme = extendTheme({
  components: componentsOverride,
  fontSize: {
    xs2: '0.625rem',
  },
  typography: {
    'title-md-emphasis': {
      fontFamily: getCssVar('fontFamily-body'),
      fontWeight: getCssVar('fontWeight-md'),
      fontSize: getCssVar('fontSize-md'),
      lineHeight: getCssVar('lineHeight-md'),
      color: getCssVar('palette-text-tertiary'),
    },
    'title-sm-emphasis': {
      fontFamily: getCssVar('fontFamily-body'),
      fontWeight: getCssVar('fontWeight-md'),
      fontSize: getCssVar('fontSize-sm'),
      lineHeight: getCssVar('lineHeight-sm'),
      color: getCssVar('palette-text-tertiary'),
    },
    'body-xs': {
      fontFamily: getCssVar('fontFamily-body'),
      fontWeight: getCssVar('fontWeight-md'),
      fontSize: getCssVar('fontSize-xs'),
      lineHeight: getCssVar('lineHeight-md'),
      color: getCssVar('palette-text-tertiary'),
    },
    'body-xs2': {
      fontFamily: getCssVar('fontFamily-body'),
      fontWeight: getCssVar('fontWeight-md'),
      fontSize: getCssVar('fontSize-xs2'),
      lineHeight: getCssVar('lineHeight-xs'),
      color: getCssVar('palette-text-tertiary'),
    },
    'title-xs': {
      fontFamily: getCssVar('fontFamily-body'),
      fontWeight: getCssVar('fontWeight-md'),
      fontSize: getCssVar('fontSize-xs'),
      lineHeight: getCssVar('lineHeight-sm'),
      color: getCssVar('palette-text-primary'),
    },
  },
  shadow: {
    sm: 'var(--joy-shadowRing, 0 0 #000), 0px 1px 4px 0px rgba(var(--joy-shadowChannel, 21 21 21) / var(--joy-shadowOpacity, 0.08))',
  },
  colorSchemes: {
    light: {
      shadowOpacity: '0.14',
      palette: {
        secondary: {
          50: '#fff',
          100: '#fff',
          200: '#687588',
          solidBg: 'var(--joy-palette-secondary-100)',
        },
      },
    },
    dark: {
      palette: {
        secondary: {
          50: '#232B3C',
          100: '#191D26',
          200: '#4684D4',
          solidBg: 'var(--joy-palette-secondary-100)',
        },
      },
    },
  },
  radius: {
    xl2: '24px',
  },
});

import { type ReactElement, useContext } from 'react';
import GridCard from './GridCard.tsx';
import { DropdownActionTypes, DropdownContext } from '@mui/base';
import { ADD_NEW_CARD_STYLE } from './consts.ts';

export const GCardMenuButton = (props: {
  height: string;
  gridWidth: { md: number; sm: number; lg: number };
}): ReactElement => {
  const menuContext = useContext(DropdownContext);
  return (
    <GridCard
      ref={menuContext?.registerTrigger}
      height={props.height}
      gridWidth={props.gridWidth}
      addNewText="Add new"
      cardSx={ADD_NEW_CARD_STYLE}
      onClick={(event) => {
        menuContext?.dispatch({
          type: DropdownActionTypes.toggle,
          event,
        });
      }}
    />
  );
};

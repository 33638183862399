import { type ReactElement, useState } from 'react';
import AssetLabel from '../../AssetLabel.tsx';
import { IconVariant } from '../../cryptocurrencies/CryptocurrenciesData.tsx';
import { Box, Stack } from '@mui/joy';
import SelectedChipsBoard from '../../../../technical/inputs/Autocomplete/SelectedChipsBoard.tsx';
import {
  createAssetAutocompleteProps,
  type NotVerifiedAssetWithId,
  useAccountAssetPaginatedOptions,
} from '../../AssetService.tsx';
import GSingleAutocomplete from 'components/technical/inputs/Autocomplete/GSingleAutocomplete.tsx';
import { useFeedback } from '../../../../technical/Feedback/UseFeedback.tsx';
import {
  useBlacklistAssetMutation,
  useBlacklistedAssetInputSuspenseQuery,
  useUnBlacklistAssetMutation,
} from 'generated/graphql.tsx';

const BlacklistedAsset = ({ initialAssets }: { initialAssets: NotVerifiedAssetWithId[] }): ReactElement => {
  const [autocompleteClearKey, setAutocompleteClearKey] = useState(0);
  const [blacklistedAssets, setBlacklistedAssets] = useState<NotVerifiedAssetWithId[]>(initialAssets);
  const { showGraphqlError } = useFeedback();
  const [blacklistAsset] = useBlacklistAssetMutation();
  const [unblacklistAsset] = useUnBlacklistAssetMutation();

  const assetOptions = useAccountAssetPaginatedOptions();
  return (
    <Stack direction="column" gap={3.5} width="100%">
      <GSingleAutocomplete<NotVerifiedAssetWithId>
        value={null}
        key={autocompleteClearKey} // to clear input after asset selected, value=null works only on blur too late
        label="Select the asset you want to blacklist"
        placeholder=""
        width="xl3"
        showLabelAboveInput
        {...createAssetAutocompleteProps()}
        {...assetOptions}
        showChipTooltips
        menuWidth="xl3"
        onChange={async (assetToAdd) => {
          if (!assetToAdd) {
            return;
          }

          setBlacklistedAssets([...blacklistedAssets, assetToAdd]);
          setAutocompleteClearKey((prevAutocompleteClearKey) => prevAutocompleteClearKey + 1);

          try {
            await blacklistAsset({
              variables: {
                id: assetToAdd.id,
              },
            });
          } catch (e) {
            setBlacklistedAssets((assets) => assets.filter((asset) => asset.id !== assetToAdd.id));
            showGraphqlError(e);
          }
        }}
      />
      <Box width={800} maxWidth={'100%'}>
        <SelectedChipsBoard
          label={'Blacklisted assets'}
          value={blacklistedAssets}
          height={300}
          width="fullWidth"
          getItemKey={(asset) => asset.id}
          onDelete={async (itemToDelete) => {
            const assetToRemove = blacklistedAssets.find((asset) => asset.id === itemToDelete.id);
            if (!assetToRemove) {
              return;
            }

            setBlacklistedAssets((assets) => assets.filter((asset) => asset.id !== itemToDelete.id));
            try {
              await unblacklistAsset({
                variables: {
                  id: assetToRemove.id,
                },
              });
            } catch (e) {
              setBlacklistedAssets((assets) => [...assets, assetToRemove]);
              showGraphqlError(e);
            }
          }}
          renderItem={(asset) => <AssetLabel asset={asset} link={false} size={IconVariant.MEDIUM} wrap={false} />}
        />
      </Box>
    </Stack>
  );
};

const BlacklistedAssetContainer = (): ReactElement => {
  const { data } = useBlacklistedAssetInputSuspenseQuery();

  return <BlacklistedAsset initialAssets={data.assets.blacklistedAssets} />;
};

export default BlacklistedAssetContainer;

import { Autocomplete as JoyAutocomplete, FormControl, Box } from '@mui/joy';
import type { AutocompleteRenderOptionState } from '@mui/joy/Autocomplete';
import isNil from 'lodash/fp/isNil';
import {
  type Context,
  type HTMLAttributes,
  type ReactElement,
  type ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { calculateContainerClasses, createJoyAutocompleteProps, type GOption } from './GAutocomplete';
import type { GMultiAutocompleteProps } from './GMultiAutocomplete.props';
import { VirtualizedListbox } from './VirtualizedListBox/VirtualizedListbox';
import {
  VirtualizedListContext,
  type VirtualizedListContextType,
} from './VirtualizedListBox/VirtualizedListContext.tsx';
import { virtualizedRenderOptions } from './VirtualizedListBox/VirtualizedRenderOptions.tsx';
import { useLoading } from '../../UseLoading.ts';
import InputError from '../InputError';
import InputLabel from '../InputLabel';
import { renderOption } from '../RenderOption/RenderOption.tsx';
import { useDebounce } from '../../../UseDebounce.ts';
import { shouldRenderInputLabel } from '../LabelService.ts';
import { queryOptions } from './GAutocomplete.utils.tsx';
import { EmptyClearIcon } from './EmptyClearIcon.tsx';
import { useOpen } from 'components/technical/UseOpen.tsx';

function GMultiAutocomplete<TValue>(props: GMultiAutocompleteProps<TValue>): ReactElement {
  const { value, onChange, getOptions, isOptionEqualToValue, renderOption: renderOptionProp } = props;

  /* jscpd:ignore-start */
  const [input, setInput] = useState('');
  const [options, setOptions] = useState<GOption<TValue>[]>([]);
  const { load } = useLoading();
  // keep track of open state to avoid fetching data when autocomplete is closed
  const { open, setOpen } = useOpen();
  // avoid flashing of loading indicator
  const debouncedInput = useDebounce(input, 100);
  /* jscpd:ignore-end */

  useEffect(() => {
    return load(async (signal) => {
      if (!open) {
        return;
      }

      const selectedOptions = value ?? [];
      await queryOptions({
        value: selectedOptions,
        debouncedInput,
        setOptions,
        signal,
        isOptionEqualToValue,
        getOptions,
      });
    });
  }, [getOptions, debouncedInput, isOptionEqualToValue, load, value, open]);

  const finalValue = useMemo(
    () =>
      isNil(value)
        ? []
        : value.map(
            (val): GOption<TValue> => ({
              type: 'regular',
              value: val,
            })
          ),
    [value]
  );

  const VirtualizedBoxContext = VirtualizedListContext as Context<VirtualizedListContextType<TValue>>;
  return (
    <Box sx={calculateContainerClasses(props)}>
      <FormControl error={!!props.error} disabled={props.disabled} color={props.color}>
        {shouldRenderInputLabel(props) ? <InputLabel {...props} /> : <></>}
        <VirtualizedBoxContext.Provider
          value={{
            optionHeight: props.optionHeight,
            getOptionKey: props.getOptionKey,
            hasGroups: !!props.groupBy,
            renderOption: (
              props: Omit<HTMLAttributes<HTMLLIElement>, 'color'>,
              option: TValue,
              state: AutocompleteRenderOptionState
            ): ReactNode => {
              return renderOption(renderOptionProp(props, option, state));
            },
            menuWidth: props.menuWidth ?? props.width,
          }}
        >
          <JoyAutocomplete<GOption<TValue>, true>
            {...createJoyAutocompleteProps(props)}
            // @ts-expect-error: seems like an error in joy types - no ref for multiple=true/false, but actually ref is required
            ref={props.ref}
            value={finalValue}
            open={open}
            onOpen={(): void => {
              setOpen(true);
            }}
            onChange={(_event, value: GOption<TValue>[] | null): void => {
              if (value === null) {
                // typing is incorrect - empty selection onChange gives empty list
                onChange?.([]);
                return;
              }

              onChange?.(value.map((el) => el.value as TValue));
              if (!props.retainInputAfterSelection) {
                setInput('');
              }
            }}
            options={options}
            onClose={(): void => {
              setOpen(false);
              setInput('');
            }}
            multiple
            renderOption={virtualizedRenderOptions}
            onInputChange={(_event, value, reason): void => {
              if (reason === 'reset') {
                return;
              }

              setInput(value);
            }}
            inputValue={input}
            disableCloseOnSelect
            getOptionDisabled={(option): boolean => option.type !== 'regular'}
            slots={
              props.showClearable
                ? { listbox: VirtualizedListbox }
                : {
                    clearIndicator: EmptyClearIcon,
                    listbox: VirtualizedListbox,
                  }
            }
          />
        </VirtualizedBoxContext.Provider>
        <InputError error={props.error} />
      </FormControl>
    </Box>
  );
}

export default GMultiAutocomplete;

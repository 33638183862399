import { Stack, Typography } from '@mui/joy';
import type { ReactElement, ReactNode } from 'react';

export const PortfolioDefTile = ({
  name,
  children,
  dropdown,
}: {
  name: string;
  children?: ReactNode;
  dropdown?: ReactNode;
}): ReactElement => {
  return (
    <Stack direction="column" justifyContent="space-between" height="100%">
      <Stack justifyContent="space-between" direction="row">
        <Typography level="title-lg" component="h3">
          {name}
        </Typography>
        {dropdown}
      </Stack>
      {children}
    </Stack>
  );
};

import { useForm } from 'react-hook-form';
import gYupResolver from '../../../../technical/form/gYupResolver.ts';
import * as yup from 'yup';
import { Stack } from '@mui/joy';
import { FormPredefinedDateRangeInput } from '../../../../technical/form/FormPredefinedDateRangeInput.tsx';
import type { PredefinedRangeLabel } from '../../../../predefinedDateRanges.ts';
import { defaultRowSpacing } from '../../../../StackSpacing.ts';
import { updateWidgetState, useWidgetState } from 'components/management/customDashboard/CustomWidgetContext.tsx';
import { calculateDefaultPresetRange } from 'components/management/TilesDateRangeSelector.tsx';
import WidgetSettings from 'components/management/customDashboard/WidgetSettings.tsx';
import { createDimensionAutocompleteOptions } from '../../../fund/SubFundService.tsx';
import FormSelect from 'components/technical/form/FormSelect.tsx';
import { useSubFundChartWidgetSettingsInputSuspenseQuery } from 'generated/graphql.tsx';
import { noDimensionsMessage } from './../SubFundPnlDashboard.tsx';
import { createAssetSelectOptions, type NotVerifiedAssetWithId } from '../../../../market/asset/AssetService.tsx';
import type { ReactElement } from 'react';
import type { DefaultValues } from 'react-hook-form';
import type { StaticAutocompleteOption } from '../../../../technical/inputs/Autocomplete/StaticSingleAutocomplete.props.ts';
import { uniqBy, upperFirst } from 'lodash/fp';
import FormStaticSingleAutocomplete from 'components/technical/form/FormStaticSingleAutocomplete.tsx';
import type { SupportedTwrFields } from './SubFundPerformanceLineChart.tsx';

export type ChartDataRange = 'historical' | 'latest';

const chartDataRangeOptions: StaticAutocompleteOption<ChartDataRange>[] = [
  'historical' as const,
  'latest' as const,
].map((value: ChartDataRange) => ({
  label: upperFirst(value),
  value: value,
  key: value,
  searchText: value,
}));

const chartValueTypeOptions: StaticAutocompleteOption<SupportedTwrFields>[] = [
  {
    label: 'Performance',
    value: 'weight',
    key: 'performance',
    searchText: 'performance',
  },
  {
    label: 'P&L',
    value: 'value',
    key: 'pnl',
    searchText: 'pnl p&l',
  },
  {
    label: 'Balance',
    value: 'balance',
    key: 'balance',
    searchText: 'balance',
  },
];

const schema = yup.object({
  title: yup.string().required(),
  dimension: yup.string().required(),
  chartDataRange: yup.string().oneOf(['historical', 'latest']).required(),
  predefinedDateRange: yup.object().required(),
  twrField: yup.string().required(),
  asset: yup.object().nullable().optional(),
});

interface FormState {
  title: string;
  dimension: string;
  chartDataRange: ChartDataRange;
  predefinedDateRange: {
    label: PredefinedRangeLabel;
  };
  twrField: SupportedTwrFields;
  asset: NotVerifiedAssetWithId | null | undefined;
}

export interface SubFundChartWidgetState {
  title: string;
  predefinedDateRange?: PredefinedRangeLabel;
  dimension?: string;
  chartDataRange: ChartDataRange;
  twrField: SupportedTwrFields;
  assetId?: string | null | undefined;
}

const SubFundChartWidgetSettings = ({
  id,
  onFinishedEditing,
  onRemovedWidget,
  defaultValues,
  dimensions,
  assets,
}: {
  id: string;
  onFinishedEditing: () => void;
  onRemovedWidget: () => Promise<void>;
  defaultValues: DefaultValues<FormState>;
  dimensions: string[];
  assets: NotVerifiedAssetWithId[];
}) => {
  const updater = updateWidgetState<SubFundChartWidgetState>();
  const dimensionAutocompleteOptions = createDimensionAutocompleteOptions(dimensions);

  const methods = useForm<FormState>({
    resolver: gYupResolver(schema),
    defaultValues: defaultValues,
  });

  const handleFormSubmit = async (input: FormState): Promise<void> => {
    await updater({
      id,
      state: {
        title: input.title,
        chartDataRange: input.chartDataRange,
        twrField: input.twrField,
        predefinedDateRange: input.predefinedDateRange.label,
        dimension: input.dimension,
        assetId: input.asset?.id,
      },
      updateBackend: true,
    });

    onFinishedEditing();
  };

  const assetOptions = createAssetSelectOptions(assets);

  return (
    <WidgetSettings methods={methods} handleFormSubmit={handleFormSubmit} onRemovedWidget={onRemovedWidget}>
      {dimensionAutocompleteOptions.options.length === 0 ? (
        noDimensionsMessage
      ) : (
        <Stack width={'100%'} rowGap={defaultRowSpacing}>
          <Stack direction={'row'} flexWrap={'wrap'} gap={defaultRowSpacing}>
            <FormSelect
              {...dimensionAutocompleteOptions}
              width={'normal'}
              name={'dimension'}
              menuWidth={'normal'}
              label={'Dimension'}
              showLabelAboveInput
            />
            <FormSelect
              options={chartDataRangeOptions}
              width={'normal'}
              name={'chartDataRange'}
              menuWidth={'normal'}
              label={'Data range'}
              showLabelAboveInput
            />
            <FormSelect
              options={chartValueTypeOptions}
              width={'normal'}
              name={'twrField'}
              menuWidth={'normal'}
              label={'Chart type'}
              showLabelAboveInput
            />
            <FormPredefinedDateRangeInput name={'predefinedDateRange'} label={'Date range'} />
          </Stack>
          <FormStaticSingleAutocomplete
            {...assetOptions}
            name={'asset'}
            label={'Asset'}
            width={'xl3'}
            showClearable
            showLabelAboveInput
            placeholder={'Leave blank for all sub-funds performance'}
          />
        </Stack>
      )}
    </WidgetSettings>
  );
};

export const SubFundChartWidgetSettingsContainer = ({
  id,
  onFinishedEditing,
  onRemovedWidget,
}: {
  id: string;
  onFinishedEditing: () => void;
  onRemovedWidget: () => Promise<void>;
}): ReactElement => {
  const initialState = useWidgetState<SubFundChartWidgetState>(id);
  const { data } = useSubFundChartWidgetSettingsInputSuspenseQuery();

  const subFunds = data.portfolio.subFunds.list;
  const assets = uniqBy(
    (asset) => asset.id,
    subFunds.map((item) => item.referenceAsset)
  );
  return (
    <SubFundChartWidgetSettings
      id={id}
      onFinishedEditing={onFinishedEditing}
      onRemovedWidget={onRemovedWidget}
      dimensions={subFunds.map((item) => item.dimension)}
      assets={assets}
      defaultValues={{
        ...initialState,
        dimension: initialState.dimension ?? subFunds[0]?.dimension,
        predefinedDateRange: calculateDefaultPresetRange(initialState.predefinedDateRange),
        asset: assets.find((asset) => asset.id === initialState.assetId),
      }}
    />
  );
};

import { intersection, isEmpty } from 'lodash/fp';
import isNil from 'lodash/fp/isNil';
import { useEffect, useSyncExternalStore } from 'react';
import * as yup from 'yup';
import type { SubAccountType } from 'components/portfolio/account/Account.types';
import type { ISubAccountAssetFiltersInputQuery } from 'generated/graphql';
import isEqual from 'lodash/fp/isEqual';
import { emptyToUndefined } from 'components/filter.utils';

type Listener = () => void;
const listeners = new Set<Listener>();

export const SUB_ACCOUNT_ASSET_FILTERS_KEY = 'subAccountAssetFilters';
export type SubFund = ISubAccountAssetFiltersInputQuery['portfolio']['subFunds']['list'][number];
export type SubAccount = ISubAccountAssetFiltersInputQuery['portfolio']['accounts'][number]['subAccounts'][number];

export type SubAccountAssetFilters = {
  subFundIds: number[] | undefined;
  accountIds: string[] | undefined;
  subAccountIds: string[] | undefined;
  assetIds: string[] | undefined;
};

const filtersSchema = yup.object().shape({
  subFundIds: yup.array().of(yup.number()),
  accountIds: yup.array().of(yup.string()),
  subAccountIds: yup.array().of(yup.string()),
  assetIds: yup.array().of(yup.string()),
});

type UseSubAccountAssetFilters = {
  subAccountAssetFilters: SubAccountAssetFilters;
  isFiltered: boolean;
  setSubAccountAssetFilters: (newFilterValues: SubAccountAssetFilters) => void;
};

function subscribe(listener: Listener): () => void {
  listeners.add(listener);
  return () => listeners.delete(listener);
}

function setSubAccountAssetFilterToExternalStorage(newFilterValues: SubAccountAssetFilters): void {
  sessionStorage.setItem(
    SUB_ACCOUNT_ASSET_FILTERS_KEY,
    JSON.stringify({
      subFundIds: emptyToUndefined(newFilterValues.subFundIds),
      subAccountIds: emptyToUndefined(newFilterValues.subAccountIds),
      accountIds: emptyToUndefined(newFilterValues.accountIds),
      assetIds: emptyToUndefined(newFilterValues.assetIds),
    })
  );

  for (const listener of listeners) {
    listener();
  }
}

const previousState: {
  storageText: string;
  value: SubAccountAssetFilters;
} = {
  storageText: '{}',
  value: { subFundIds: undefined, subAccountIds: undefined, assetIds: undefined, accountIds: undefined },
};

function getSubAccountAssetFilterValues(): SubAccountAssetFilters {
  const filterValueJson = sessionStorage.getItem(SUB_ACCOUNT_ASSET_FILTERS_KEY) ?? '{}';
  if (previousState.storageText === filterValueJson) {
    return previousState.value;
  }

  let filterValue: SubAccountAssetFilters | undefined;
  try {
    filterValue = JSON.parse(filterValueJson);
    filtersSchema.validateSync(filterValue);
  } catch (e) {
    console.warn(
      `filter value in session storage ${SUB_ACCOUNT_ASSET_FILTERS_KEY} should be {subFundIds: number[];subAccountIds: string[], assetIds: string[]}, but found ${filterValueJson}`,
      e
    );
    filterValue = undefined;
  }

  filterValue ??= { subFundIds: undefined, subAccountIds: undefined, assetIds: undefined, accountIds: undefined };
  previousState.storageText = filterValueJson;
  previousState.value = filterValue;
  return filterValue;
}

/** Store filter value in session storage to keep it between pages refreshes */
export function useSubAccountAssetFilters(): UseSubAccountAssetFilters {
  const filters = useSyncExternalStore(subscribe, () => getSubAccountAssetFilterValues());

  return {
    subAccountAssetFilters: filters,
    isFiltered:
      !isNil(filters.subAccountIds) ||
      !isNil(filters.subFundIds) ||
      !isNil(filters.assetIds) ||
      !isNil(filters.accountIds),
    setSubAccountAssetFilters: setSubAccountAssetFilterToExternalStorage,
  };
}

/** We need update filter value in sessionStorage in case some saved in filter subFundIds were removed */
export function useSyncSubAccountAssetFilterWithCurrentData(queryData: {
  portfolio: {
    subFunds: {
      list: {
        id: number;
      }[];
    };
    accounts: {
      id: string;
      subAccounts: {
        id: string;
      }[];
    }[];
  };
  assets?: {
    list: {
      id: string;
    }[];
  };
}): void {
  const { setSubAccountAssetFilters, subAccountAssetFilters } = useSubAccountAssetFilters();
  useEffect(() => {
    const subFundIds = intersection(
      subAccountAssetFilters.subFundIds,
      queryData.portfolio.subFunds.list.map((subFund) => subFund.id)
    );

    const subAccountIds = intersection(
      queryData.portfolio.accounts.flatMap((account) => account.subAccounts).map((subAccount) => subAccount.id),
      subAccountAssetFilters.subAccountIds
    );

    const accountIds = intersection(
      queryData.portfolio.accounts.map((account) => account.id),
      subAccountAssetFilters.accountIds
    );

    const assetIds = intersection(
      (queryData.assets?.list ?? []).map((asset) => asset.id),
      subAccountAssetFilters.assetIds
    );

    const newFilter = {
      subFundIds,
      subAccountIds,
      accountIds,
      assetIds,
    };

    // empty lists are the same as undefined in filters, so we need to compare values manually
    if (
      !isEqual(
        {
          subFundIds: subAccountAssetFilters.subFundIds ?? [],
          subAccountIds: subAccountAssetFilters.subAccountIds ?? [],
          accountIds: subAccountAssetFilters.accountIds ?? [],
          assetIds: subAccountAssetFilters.assetIds ?? [],
        },
        newFilter
      )
    ) {
      setSubAccountAssetFilters(newFilter);
    }
  }, [subAccountAssetFilters, setSubAccountAssetFilters, queryData]);
}

export function useSyncSubAccountAssetFilterWithCurrentSubFunds(subFunds: SubFund[] | undefined): void {
  const { setSubAccountAssetFilters, subAccountAssetFilters } = useSubAccountAssetFilters();
  useEffect(() => {
    if (isNil(subFunds) || isEmpty(subAccountAssetFilters.subFundIds)) {
      return;
    }
    const subFundIds = intersection(
      subAccountAssetFilters.subFundIds,
      subFunds.map((subFund) => subFund.id)
    );

    setSubAccountAssetFilters({
      ...subAccountAssetFilters,
      subFundIds,
    });
  }, [subAccountAssetFilters, setSubAccountAssetFilters, subFunds]);
}

export function useSyncSubAccountAssetFilterWithCurrentSubAccounts(subAccounts: SubAccountType[] | undefined): void {
  const { setSubAccountAssetFilters, subAccountAssetFilters } = useSubAccountAssetFilters();
  useEffect(() => {
    if (isNil(subAccounts) || isEmpty(subAccountAssetFilters.subAccountIds)) {
      return;
    }
    const subAccountIds = intersection(
      subAccountAssetFilters.subAccountIds,
      subAccounts.map((subAccount) => subAccount.id)
    );

    setSubAccountAssetFilters({
      ...subAccountAssetFilters,
      subAccountIds,
    });
  }, [subAccountAssetFilters, setSubAccountAssetFilters, subAccounts]);
}

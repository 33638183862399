import { Box, Stack } from '@mui/joy';
import { iconPadding } from './CustomWidgetCloseAction.tsx';
import { GraphQLApiFormErrorMessage } from '../../technical/form/GraphQLApiErrorMessage.tsx';
import SubmitButton from '../../technical/form/SubmitButton.tsx';
import DeleteDialogButton from '../../technical/inputs/GButton/DeleteDialogButton.tsx';
import GFormProvider from '../../technical/form/GFormProvider.tsx';
import { useWatch, type UseFormReturn } from 'react-hook-form';
import type { ReactNode } from 'react';
import { defaultRowSpacing } from '../../StackSpacing.ts';
import FormInput from '../../technical/form/FormInput.tsx';

const WidgetSettings = <FormState extends { title: string }>({
  methods,
  handleFormSubmit,
  children,
  onRemovedWidget,
}: {
  methods: UseFormReturn<FormState>;
  handleFormSubmit: (fields: FormState) => Promise<void>;
  children?: ReactNode;
  onRemovedWidget: () => Promise<void>;
}) => {
  const title = useWatch({
    control: methods.control,
    // @ts-expect-error - react form hook path type cannot infer property from generic class
    name: 'title',
  });
  return (
    <GFormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(handleFormSubmit)}
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box pr={iconPadding} width={'100%'} pb={defaultRowSpacing}>
          <FormInput name={'title'} width={'fullWidth'} type={'text'} />
        </Box>
        <Stack direction={'column'} spacing={2} alignItems={'flex-start'} minHeight={0}>
          <Stack
            direction={'column'}
            gap={defaultRowSpacing}
            sx={{
              overflow: 'auto',
              minHeight: 0,
              width: '100%',
            }}
          >
            {children}
          </Stack>
          <Stack direction={'column'} spacing={1}>
            <GraphQLApiFormErrorMessage />
            <Stack direction={'row'} spacing={1} data-tour="widget-card-settings-actions">
              <SubmitButton>Save</SubmitButton>
              <DeleteDialogButton
                dataTour="widget-card-delete-button"
                deletedMessage={'Widget removed'}
                confirmationMessage={`Are you sure you want to remove "${title}" widget?`}
                deleteItem={onRemovedWidget}
              />
            </Stack>
          </Stack>
        </Stack>
      </form>
    </GFormProvider>
  );
};

export default WidgetSettings;

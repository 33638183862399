import type { ColDef, ColGroupDef, IAggFuncParams, IRowNode, ValueGetterParams } from 'ag-grid-community';
import { dateReadableValueGetter, DEFAULT_AGG_FUNCTIONS } from 'components/technical/grids/agGrid.utils.tsx';
import { type IUnifiedPnlReportQuery, useUnifiedPnlReportSuspenseQuery } from 'generated/graphql';
import { nameColumn, symbolColumn, underlyingAssetColumn } from 'components/technical/grids/SharedReportColumns';
import { bignumber } from 'mathjs';
import type { ReactElement } from 'react';
import GAgGridPresets from '../../../technical/grids/GAgGridPresets.tsx';
import { UserSettings } from '../../../management/UserSettings.types.ts';
import { presets } from './unifiedPnlGridPresets.ts';
import isNil from 'lodash/fp/isNil';
import dayjs, { type Dayjs } from 'dayjs';
import { parseUtcDate } from '../../../date.utils.ts';
import bigNumMath from '../../../../bigNumMath.ts';

type RowData = IUnifiedPnlReportQuery['portfolio']['unifiedPnl'][number];

type UnrealizedPnlAggOutput = { value: number | null; date: Dayjs };
const mapRowNodesToUpnl = (rowNodes: IRowNode<RowData>[]): UnrealizedPnlAggOutput[] => {
  return rowNodes
    .map((row) => row.data)
    .filter((data) => !isNil(data))
    .map((data) => ({
      date: parseUtcDate(data.date),
      value: isNil(data.upnl) ? null : bignumber(data.upnl).toNumber(),
    }));
};

export const unrealizedPnl = ({
  values,
  rowNode,
}: IAggFuncParams<RowData, UnrealizedPnlAggOutput | number | null>): UnrealizedPnlAggOutput => {
  // when at leaf, input value contains only unified pnl without date, so we refer to childrenAfterFilter property to get actual child data
  const items = rowNode.leafGroup
    ? mapRowNodesToUpnl(rowNode.childrenAfterFilter ?? [])
    : (values as UnrealizedPnlAggOutput[]);
  const maxDate = dayjs.max(items.map((item) => item.date))!;
  const upnlValues = items.filter((item) => item.date.isSame(maxDate)).map((item) => item.value);
  const value = upnlValues.every((val) => isNil(val))
    ? null
    : bigNumMath.sum(upnlValues.map((val) => (isNil(val) ? 0 : val)));

  return {
    value,
    date: maxDate,
  };
};

const unrealizedPnlFunctionName = 'unrealizedpnl';
const customAgg = {
  [unrealizedPnlFunctionName]: unrealizedPnl,
};

const UnifiedPnlGrid = ({ reportId }: { reportId: number }): ReactElement => {
  const report = useUnifiedPnlReportSuspenseQuery({
    variables: {
      reportId,
    },
  });

  const columns: (ColDef<RowData> | ColGroupDef<RowData>)[] = [
    {
      headerName: 'Date',
      field: 'date',
      type: 'dateColumn',
      initialSort: 'desc',
      valueGetter: (params: ValueGetterParams<RowData>): string | undefined =>
        params.data ? dateReadableValueGetter(params.data.date) : undefined,
    },
    {
      headerName: 'Asset Details',
      colId: 'asset-details',
      marryChildren: true,
      children: [
        nameColumn({ initialHide: false, allowedAggFuncs: DEFAULT_AGG_FUNCTIONS }),
        symbolColumn({ allowedAggFuncs: DEFAULT_AGG_FUNCTIONS }),
        underlyingAssetColumn({ allowedAggFuncs: DEFAULT_AGG_FUNCTIONS }),
      ],
    },
    {
      headerName: 'Sub-fund',
      colId: 'sub-fund',
      type: 'textColumn',
      valueGetter: (params: ValueGetterParams<RowData>): string | undefined => {
        if (!params.data) {
          return undefined;
        }

        return params.data.subFund?.name ?? 'Organization';
      },
    },
    {
      headerName: 'Amount',
      type: ['numericColumn', 'extendedNumericColumn'],
      valueGetter: (params: ValueGetterParams<RowData>): number | undefined => {
        if (!params.data?.amount) {
          return undefined;
        }
        return bignumber(params.data?.amount).toNumber();
      },
    },
    {
      headerName: 'Price',
      type: ['numericColumn', 'cashColumn'],
      valueGetter: (params: ValueGetterParams<RowData>): number | undefined => {
        if (!params.data?.price) {
          return undefined;
        }
        return bignumber(params.data?.price).toNumber();
      },
    },
    {
      headerName: 'AvgPrice',
      type: ['numericColumn', 'cashColumn'],
      valueGetter: (params: ValueGetterParams<RowData>): number | undefined => {
        if (!params.data?.avgPrice) {
          return undefined;
        }
        return bignumber(params.data?.avgPrice).toNumber();
      },
    },
    {
      headerName: 'Realized P&L',
      type: ['numericColumn', 'cashColumn'],
      valueGetter: (params: ValueGetterParams<RowData>): number | undefined => {
        if (!params.data?.rpnl) {
          return undefined;
        }
        return bignumber(params.data?.rpnl).toNumber();
      },
    },
    {
      headerName: 'Unrealized P&L',
      type: ['numericColumn', 'cashColumn'],
      valueGetter: (params: ValueGetterParams<RowData>): number | undefined => {
        const upnl = params.data?.upnl;
        if (!upnl) {
          return undefined;
        }
        return bignumber(upnl).toNumber();
      },
      allowedAggFuncs: [...DEFAULT_AGG_FUNCTIONS, unrealizedPnlFunctionName],
      defaultAggFunc: unrealizedPnlFunctionName,
      initialAggFunc: unrealizedPnlFunctionName,
    },
  ];

  return (
    <GAgGridPresets
      rowData={report.data.portfolio.unifiedPnl}
      presetSettingsKey={UserSettings.UnifiedPnlReportPresets}
      defaultPresets={presets}
      aggFuncs={customAgg}
      sideBar={{
        toolPanels: ['columns', 'filters'],
      }}
      enableCharts
      cellSelection
      autoSizeStrategy={{ type: 'fitCellContents' }}
      columnDefs={columns.map((col) => {
        if ((col as ColDef<RowData>).allowedAggFuncs) {
          return col;
        }

        return {
          ...col,
          allowedAggFuncs: DEFAULT_AGG_FUNCTIONS,
        };
      })}
    />
  );
};

export default UnifiedPnlGrid;

import dayjs, { type Dayjs } from 'dayjs';
import { uniq } from 'lodash/fp';
import isNil from 'lodash/fp/isNil';
import { formatPercentage } from 'components/formatter.utils';
import {
  dateTimeAxisFormat,
  dateTimeExportFormat,
  type HighchartSeries,
  tooltipFormat,
} from 'components/technical/charts/HighChartsWrapper/Highchart.utils';
import HighChartsContainer from 'components/technical/charts/HighChartsWrapper/HighChartsWrapper';
import { useSubAccountAssetFilters } from 'components/technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters.tsx';
import { type IReturnsAndAttributionQuery, useReturnsAndAttributionQuery } from 'generated/graphql';
import { convertDateRangeToSinceToDate } from 'components/technical/inputs/date/dateRange.utils.ts';
import type Highcharts from 'highcharts';
import type { StaticAutocompleteOption } from '../../../technical/inputs/Autocomplete/StaticSingleAutocomplete.props.ts';

export type AttributionReportSide = 'long' | 'short' | 'total';
export const attributionSideOptions: StaticAutocompleteOption<AttributionReportSide>[] = [
  {
    label: 'Total',
    searchText: 'Total',
    value: 'total',
    key: 'Total',
  },
  {
    label: 'Short',
    searchText: 'Short',
    value: 'short',
    key: 'Short',
  },
  {
    label: 'Long',
    searchText: 'Long',
    value: 'long',
    key: 'Long',
  },
];

const calculateChartData =
  (side: AttributionReportSide) =>
  (data: IReturnsAndAttributionQuery): HighchartSeries[] => {
    const performanceClusterAttributionsDaily = data.portfolio.performanceClusterAttributionsDaily[side];

    const categories = uniq(
      performanceClusterAttributionsDaily.flatMap((row) => row.categoryValues.map((cat) => cat.category))
    );

    const chartData: HighchartSeries[] = [
      {
        name: 'Returns',
        data: performanceClusterAttributionsDaily.map((row) => {
          const value = row.categoryValues.reduce((acc, cur) => acc + cur.value, 0);

          return {
            x: dayjs.utc(row.date.toString()).valueOf(),
            y: value * 100,
            textValue: formatPercentage(value),
          };
        }),
        type: 'line',
        zIndex: 1,
      },
      ...categories.map((category) => ({
        data: performanceClusterAttributionsDaily.map((row) => {
          const categoryValues = row.categoryValues.find((cat) => cat.category === category);
          return {
            x: dayjs.utc(row.date.toString()).valueOf(),
            y: !isNil(categoryValues) ? categoryValues.value * 100 : 0,
            textValue: formatPercentage(categoryValues?.value ?? 0),
          };
        }),
        name: category,
        type: 'column' as const,
      })),
    ];

    return chartData;
  };

const calculateOptions = (side: AttributionReportSide) => (): Highcharts.Options => {
  return {
    ...dateTimeAxisFormat,
    ...dateTimeExportFormat('performance-attribution'),
    ...tooltipFormat,
    plotOptions: {
      column: {
        stacking: 'normal',
      },
    },
    yAxis: {
      labels: {
        format: '{value:.2f}%',
      },
      title: {
        text: `Daily returns - ${side}`,
      },
    },
  };
};

const PerformanceAttributionChart = ({
  side,
  classification,
  dateRange,
  fullHeight,
}: {
  side: AttributionReportSide;
  classification: string;
  dateRange: [Dayjs, Dayjs] | null;
  fullHeight?: boolean;
}) => {
  const { subAccountAssetFilters } = useSubAccountAssetFilters();

  const queryOutput = useReturnsAndAttributionQuery({
    variables: {
      ...convertDateRangeToSinceToDate(dateRange),
      clusterType: classification,
      subAccountAssetFilters,
    },
  });

  return (
    <HighChartsContainer<IReturnsAndAttributionQuery>
      {...queryOutput}
      height={fullHeight ? 'fullHeight' : undefined}
      key={`${classification}-${side}`}
      calculateOptions={calculateOptions(side)}
      calculateChartData={calculateChartData(side)}
    />
  );
};

export default PerformanceAttributionChart;

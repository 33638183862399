import { Add } from '@mui/icons-material';
import { Link, Modal, ModalDialog, Stack, Typography as JoyTypography } from '@mui/joy';
import { type ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';
import type { FormInputType } from 'components/technical/form/Form.types';
import FormStaticSingleAutocomplete from 'components/technical/form/FormStaticSingleAutocomplete';
import GFormProvider from 'components/technical/form/GFormProvider';
import { GraphQLApiFormErrorMessage } from 'components/technical/form/GraphQLApiErrorMessage';
import gYupResolver from 'components/technical/form/gYupResolver';
import SubmitButton from 'components/technical/form/SubmitButton';
import { useGraphQLApiError } from 'components/technical/form/UseGraphQLApiError.tsx';
import GDialogHeader from 'components/technical/GDialog/GDialogHeader.tsx';
import { AssetPriceChartDocument, useCreateUpdateAssetPricesMutation } from '../../../../../../generated/graphql';
import type { Asset } from '../../../Asset.types';
import { createAssetSelectOptions } from '../../../AssetService';
import { defaultRowSpacing } from '../../../../../StackSpacing.ts';
import { downloadLocalCsvFile, FileErrorSummary, generateErrorSummary } from '../../../../../file.utils.tsx';
import FormFileUploadInput from '../../../../../technical/form/FormFileUploadInput.tsx';
import { parseFile, sampleFile } from './UploadAssetPricesDialog.util.ts';
import { convertDateInUtcToUTCISODate } from '../../../../../date.utils.ts';

interface FormOutputFields {
  asset: { id: string };
  file: File;
}

type FormInputFields = FormInputType<FormOutputFields>;

const formSchema = yup.object({
  asset: yup.mixed().required(),
  file: yup.mixed().required(),
});

export const UploadAssetPricesDialog = (props: {
  onClose: () => void;
  onAdded: () => void;
  availableAssets: Asset[];
  quoteAsset: { id: string };
}): ReactElement => {
  const [fileValidationErrors, setFileValidationErrors] = useState<string[]>([]);
  const assetOptions = createAssetSelectOptions(props.availableAssets);

  const methods = useForm<FormInputFields>({
    resolver: gYupResolver(formSchema),
    defaultValues: {
      asset: props.availableAssets.at(0),
      file: null,
    },
  });

  const { onErrorAndThrow } = useGraphQLApiError(methods);
  const { showSuccessMessage } = useFeedback();

  const [createAssetPrices] = useCreateUpdateAssetPricesMutation({
    refetchQueries: [AssetPriceChartDocument],
  });

  const handleFormSubmit = async (input: FormInputFields): Promise<void> => {
    const formOutput = input as unknown as FormOutputFields;
    setFileValidationErrors([]);
    const fileText = (await formOutput.file.text()) ?? '';
    const { generalErrors, lineErrors, data } = parseFile(fileText);
    const errorText = generateErrorSummary({ generalErrors, lineErrors });

    if (errorText.length > 0) {
      setFileValidationErrors(errorText);
      console.error('File validation errors', errorText);
      throw new Error('Invalid form');
    }

    try {
      await createAssetPrices({
        variables: {
          input: {
            prices: data.map((row) => ({
              id: {
                baseAssetId: formOutput.asset.id,
                quoteAssetId: props.quoteAsset.id,
                date: convertDateInUtcToUTCISODate(row.date),
              },
              unitValue: row.price,
            })),
          },
        },
      });

      showSuccessMessage('New asset prices successfully added');

      props.onAdded();
    } catch (e) {
      onErrorAndThrow(e);
    }
  };

  const inputWidth = 'normal';
  return (
    <Modal open={true} onClose={props.onClose}>
      <ModalDialog>
        <GFormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
            <GDialogHeader>Upload prices</GDialogHeader>
            <Stack spacing={2}>
              <Stack direction={'row'} spacing={2} alignItems={'flex-end'}>
                <FormStaticSingleAutocomplete<FormInputFields>
                  showLabelAboveInput
                  {...assetOptions}
                  name="asset"
                  label="Asset"
                  width={inputWidth}
                />
                <FormFileUploadInput name={'file'} width={inputWidth} onChange={() => setFileValidationErrors([])} />
              </Stack>

              <div>
                <JoyTypography color="neutral" level="body-sm" maxWidth={'100%'}>
                  Click on the link to download a{' '}
                  <Link
                    onClick={() => {
                      downloadLocalCsvFile(sampleFile, 'prices.txt');
                    }}
                  >
                    sample file
                  </Link>
                  . The file contains two columns: date and price. Date uses format YYYY-MM-DD and cannot be in the the
                  future.
                  <br /> <br />
                  Please note that opening CSV file in Microsoft Excel may automatically alter the format of columns
                  containing dates and numerical values, depending on your system settings. To prevent this, you can
                  adjust Excel's data conversion settings by following{' '}
                  <Link
                    href="https://techcommunity.microsoft.com/blog/microsoft365insiderblog/control-data-conversions-in-excel-for-windows-and-mac/4215336"
                    target="_blank"
                  >
                    Control data conversion in Excel
                  </Link>{' '}
                  or import the data as text using{' '}
                  <Link
                    href="https://support.microsoft.com/en-us/office/import-or-export-text-txt-or-csv-files-5250ac4c-663c-47ce-937b-339e391393ba"
                    target="_blank"
                  >
                    Import CSV as text
                  </Link>
                  .
                </JoyTypography>
              </div>

              <GraphQLApiFormErrorMessage />
              <FileErrorSummary fileValidationErrors={fileValidationErrors} />
              <Stack direction={'row'} spacing={defaultRowSpacing} justifyContent={'center'}>
                <SubmitButton width="small" startDecorator={<Add />}>
                  Upload prices
                </SubmitButton>
              </Stack>
            </Stack>
          </form>
        </GFormProvider>
      </ModalDialog>
    </Modal>
  );
};

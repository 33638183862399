import type { CustomWidgetDescription } from '../../../management/customDashboard/CustomWidgetDescription.types.ts';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { TitleSettings } from '../../../management/customDashboard/TitleSettings.tsx';
import { VolatilityTileContentContainer } from './VolatilityTile.tsx';
import { DailyReturnsTileContent } from './DailyReturnsTile.tsx';
import { useTwrEquityWidgetInputSuspenseQuery } from '../../../../generated/graphql.tsx';
import { UserSettings } from '../../../management/UserSettings.types.ts';
import { getSavedDaysRange, useTwrEquityData } from './PortfolioTilesService.ts';
import { TwrTileContent } from './TwrTile.tsx';
import { EquityTileContent } from './EquityTile.tsx';

export const portfolioTileWidgets: CustomWidgetDescription[] = [
  {
    name: 'Equity value',
    type: 'portfolioEquityTile',
    description: 'See your portfolio equity',
    icon: () => <CreditCardIcon />,
    viewComponent: () => {
      const { data } = useTwrEquityWidgetInputSuspenseQuery({
        variables: { tileSettings: UserSettings.TilesDateRange },
      });

      const { savedTimeFrameInDays, savedDateRange } = getSavedDaysRange(data);
      const { twr, loading } = useTwrEquityData({ savedTimeFrameInDays });

      return <EquityTileContent hideTitle loading={loading} twr={twr} since={savedDateRange.value()![0]} />;
    },
    settingsComponent: TitleSettings,
    initialH: 4,
    initialW: 3,
  },
  {
    name: 'TWR',
    type: 'portfolioTwrTile',
    description: 'See your portfolio twr',
    icon: () => <CreditCardIcon />,
    viewComponent: () => {
      const { data } = useTwrEquityWidgetInputSuspenseQuery({
        variables: { tileSettings: UserSettings.TilesDateRange },
      });

      const { savedTimeFrameInDays, savedDateRange } = getSavedDaysRange(data);
      const { twr, twrSince, loading } = useTwrEquityData({ savedTimeFrameInDays });

      return (
        <TwrTileContent hideTitle dateRangeLabel={savedDateRange.label} loading={loading} twr={twr} since={twrSince} />
      );
    },
    settingsComponent: TitleSettings,
    initialH: 4,
    initialW: 3,
  },
  {
    name: 'Last 30-days returns',
    type: 'portfolioDailyReturnTile',
    description: 'See your daily returns',
    icon: () => <CreditCardIcon />,
    viewComponent: () => {
      return <DailyReturnsTileContent hideTitle />;
    },
    settingsComponent: TitleSettings,
    initialH: 4,
    initialW: 3,
  },
  {
    name: 'Volatility',
    type: 'portfolioVolatilityTile',
    description: 'See your 30 days volatility',
    icon: () => <CreditCardIcon />,
    viewComponent: () => {
      return <VolatilityTileContentContainer hideTitle />;
    },
    settingsComponent: TitleSettings,
    initialState: { title: 'Volatility' },
    initialH: 4,
    initialW: 3,
  },
];

import type { ReactElement } from 'react';
import type Highcharts from 'highcharts';
import {
  areaChartOpacity,
  dateTimeAxisFormat,
  dateTimeExportFormat,
  defaultTooltipDateHeader,
  defaultTooltipPointColor,
  getHighchartColor,
  getHighchartColorWithOpacity,
  type HighchartSeries,
  numberFormatter,
} from '../../../technical/charts/HighChartsWrapper/Highchart.utils.ts';
import HighChartsWrapper from 'components/technical/charts/HighChartsWrapper/HighChartsWrapper.tsx';
import { DateTimeFormat, formatDate, formatNumber } from '../../../formatter.utils.ts';
import dayjs from 'dayjs';
import { bignumber } from 'mathjs';
import { parseUtcDate } from '../../../date.utils.ts';
import groupBy from 'lodash/fp/groupBy';
import type { PortfolioResult } from './PortfolioResult.types.ts';
import { useFinalColorScheme } from '../../../../useFinalColorScheme.ts';

interface SerieUserData {
  customSerieType: 'beta' | 'confidenceInterval';
  assetId: string;
  assetSymbol: string;
}

const calculateOptions = (): Highcharts.Options => {
  return {
    ...dateTimeExportFormat('portfolio-factors'),
    ...dateTimeAxisFormat,
    tooltip: {
      formatter: function ({ chart }): string {
        const timestamp = this.x;
        const text: string[] = [];
        const points = (
          this as unknown as {
            points: {
              color: string;
              point: {
                textValue: string;
                index: number;
              };
              series: {
                userOptions: SerieUserData;
              };
            }[];
          }
        ).points;

        const perAssetSeries = groupBy((p) => p.series.userOptions.assetId, points);

        const series = chart.options.series as unknown as ({
          data: { textValue: string }[];
        } & SerieUserData)[];

        for (const [assetId, assetPoints] of Object.entries(perAssetSeries)) {
          const point = assetPoints[0];
          const confidenceIntervalPoint = assetPoints.find(
            (point) => point.series.userOptions.customSerieType === 'confidenceInterval'
          );

          const parts = [defaultTooltipPointColor(point.color), `${point.series.userOptions.assetSymbol}`];

          const pointIndex = point.point.index;
          const betaSerie = series.find((serie) => serie.assetId === assetId && serie.customSerieType === 'beta');

          if (betaSerie) {
            parts.push(`Beta: <b>${betaSerie.data[pointIndex].textValue}</b>`);
          }

          if (confidenceIntervalPoint) {
            parts.push(`Confidence: <b>${confidenceIntervalPoint.point.textValue}</b>`);
          }
          text.push(parts.join(' '));
        }

        const header = defaultTooltipDateHeader(formatDate(dayjs.utc(timestamp), DateTimeFormat.LongDate));
        return [header, text.join('<br/>')].join('<br/>');
      },
    },
    yAxis: {
      labels: {
        formatter: numberFormatter,
      },
      title: {
        text: undefined,
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        states: {
          select: {
            // Don't change appearance for selected bar
            color: undefined,
            borderColor: undefined,
          },
        },
      },
      arearange: {
        fillOpacity: areaChartOpacity,
        marker: {
          symbol: 'circle',
        },
      },
      line: {
        marker: {
          symbol: 'circle',
        },
      },
    },
  };
};

const calculateChartData = (colorSchema: 'dark' | 'light', data: PortfolioResult): HighchartSeries[] => {
  const factorToBeta: Record<string, Extract<HighchartSeries, { type: 'column' }> & SerieUserData> = {};
  const factorToConfidence: Record<string, Extract<HighchartSeries, { type: 'arearange' }> & SerieUserData> = {};
  const factorToColorIndex: Map<string, number> = new Map();
  for (const { results, date } of data.results) {
    for (const { beta, confidenceIntervals, factor } of results) {
      if (!factor) {
        console.warn('Factor details are missing');
        continue;
      }

      let colorIndex = factorToColorIndex.get(factor.id!);
      if (colorIndex === undefined) {
        colorIndex = factorToColorIndex.size;
        factorToColorIndex.set(factor.id, colorIndex);
      }

      factorToBeta[factor.id] ??= {
        data: [],
        name: `Beta-${factor.symbol}`,
        type: 'column',
        color: getHighchartColor(colorSchema, colorIndex),
        assetId: factor.id,
        assetSymbol: factor.symbol,
        customSerieType: 'beta',
      };

      const formattedDate = parseUtcDate(date).valueOf();
      const betaDayValue = bignumber(beta).toNumber();
      factorToBeta[factor.id].data.push({
        x: formattedDate,
        y: betaDayValue,
        textValue: formatNumber(betaDayValue),
      });

      factorToConfidence[factor.id] ??= {
        data: [],
        name: `Confidence interval-${factor.symbol}`,
        type: 'arearange',
        color: getHighchartColorWithOpacity(colorSchema, colorIndex, 50),
        assetId: factor.id,
        assetSymbol: factor.symbol,
        customSerieType: 'confidenceInterval',
        visible: false,
      };

      const low = bignumber(confidenceIntervals[0].value).toNumber();
      const high = bignumber(confidenceIntervals[1].value).toNumber();
      factorToConfidence[factor.id].data.push({
        x: formattedDate,
        low: low,
        high: high,
        textValue: `${formatNumber(low)} - ${formatNumber(high)}`,
      });
    }
  }

  return [...Object.values(factorToBeta), ...Object.values(factorToConfidence)];
};

const FactorBetaChart = ({ result }: { result: PortfolioResult }): ReactElement => {
  const colorScheme = useFinalColorScheme();
  return (
    <HighChartsWrapper<PortfolioResult>
      data={result}
      loading={false}
      calculateOptions={calculateOptions}
      calculateChartData={(data) => calculateChartData(colorScheme, data)}
    />
  );
};

export default FactorBetaChart;

import type { Schema } from 'yup';
import * as yup from 'yup';
import type { FormInputType } from 'components/technical/form/Form.types.ts';
import type { SelectOption } from 'components/technical/inputs/Select/Select.props.ts';
import { translate, yupLocale } from '../../../../../../setup/yupLocale.ts';
import { yupWhen } from '../../../../../../validation.ts';
import { ConstraintType, constraintTypeValues } from '../../ConstraintTypeValues.validation.ts';
import { greaterThanMin, smallerThanMax } from '../../MinMax.validation.ts';
import type { UniversePool } from '../poolUniverse/PoolUniverseStep.tsx';

export const allocationConstraintTypeValues: SelectOption<ConstraintType>[] = constraintTypeValues.filter((option) =>
  [ConstraintType.Equal, ConstraintType.Between].includes(option.value)
);

export interface AllocationConstraintOutput {
  pool: UniversePool;
  constraintType: ConstraintType;
  constraintValue: { value: number | null; min: number | null; max: number | null };
}

export interface AllocationConstraintsOutput {
  constraints: AllocationConstraintOutput[];
}

export type AllocationConstraintsInput = FormInputType<Omit<AllocationConstraintsOutput, 'constraints'>> & {
  constraints: {
    pool: UniversePool | null;
    constraintType: ConstraintType;
    constraintValue: { value: string | null; min: string | null; max: string | null };
  }[];
};

const baseRequiredHiddenLabelNumberSchema = yup.number().required(translate(yupLocale.number.required));

export const schema: Schema<unknown> = yup.object({
  constraints: yup
    .array(
      yup.object({
        pool: yup.object().required(),
        constraintType: yup.string().oneOf(Object.values(ConstraintType)).required(),
        constraintValue: yupWhen(['constraintType'], ([constraintType]: [ConstraintType | null]) => {
          const fallbackSchema = yup.number().nullable().optional();
          const minValueSchema = yup.number().min(0, translate(yupLocale.number.min));
          const maxValueSchema = yup.number();

          return yup
            .object({
              value: constraintType === ConstraintType.Equal ? minValueSchema.required() : fallbackSchema,
              min:
                constraintType === ConstraintType.Between
                  ? baseRequiredHiddenLabelNumberSchema
                      .test('minValue', 'Must be smaller or equal to max', smallerThanMax)
                      .concat(minValueSchema)
                      .concat(maxValueSchema)
                      .required() // necessary to display correct error message
                  : fallbackSchema,
              max:
                constraintType === ConstraintType.Between
                  ? baseRequiredHiddenLabelNumberSchema
                      .test('maxValue', 'Must be greater or equal to min', greaterThanMin)
                      .concat(minValueSchema)
                      .concat(maxValueSchema)
                      .required() // necessary to display correct error message
                  : fallbackSchema,
            })
            .required();
        }),
      })
    )
    .required(),
});

import isNil from 'lodash/fp/isNil';
import * as yup from 'yup';
import { isValidNumber } from './components/number.utils.ts';

/*
  By default, yup takes schema type based on argument before .when, which causes problems with type inference. For example, you may have code:
  yup.object(['constrainedQuantity'], ([constrainedQuantity]) => {
    return yup.object({
      name: constrainedQuantity === IPrimaryConstrainedQuantity.TargetRisk ? yup.string().required() : yup.string(0
    })
  });

  When you type check the code, the expression has type Schema<object>, fields are lost.
  The code below, workarounds this problem, by taking type of schema based on a function provided
 */
//
// biome-ignore lint/suspicious/noExplicitAny:
export const yupWhen = <V = any[], T extends yup.Schema<unknown> = yup.Schema<unknown>>(
  keys: string[],
  cb: (vals: V) => T
): T => {
  // @ts-ignore
  return yup.mixed().when(
    keys,
    // @ts-ignore
    cb
  );
};

export const isBlankOrNan = (value: unknown): value is undefined | null | number => {
  return !isValidNumber(value);
};

export const arrayLengthSchemaFactory = (arrayField: string, message: string): yup.Schema =>
  yup
    .mixed()
    .nullable()
    .optional()
    .when(arrayField, ([field], schema) =>
      schema.test('atLeastOneItem', message, () => {
        return isNil(field) || field.length >= 1;
      })
    );

import type { Table } from '@tanstack/table-core';
import type { ReactNode } from 'react';

import type { GTableProps } from './GTable.props';
import { isCursorDynamic, isPageDynamic } from './GTable.utils';
import { GTableCursorPagination, GTablePagePagination } from './GTablePagination.tsx';
import { DEFAULT_PAGE_SIZES } from './UseTablePaginator.tsx';
import { Stack } from '@mui/system';

const canFillMoreThanOneMinPage = (results: number | undefined): boolean => {
  return (results ?? 0) > Math.min(...DEFAULT_PAGE_SIZES);
};

export const showPagination = <ROW,>(props: GTableProps<ROW>): boolean => {
  if (props.disablePagination) {
    return false;
  }

  if (isCursorDynamic(props)) {
    if (props.loading) {
      return false;
    }

    return true;
  }

  if (isPageDynamic(props) && (props.loading || props.error)) {
    return false;
  }

  const maxResults = isPageDynamic(props) ? props.totalResults : (props.data?.length ?? 0);
  return canFillMoreThanOneMinPage(maxResults);
};

export const createPagination = <ROW,>(
  table: Table<ROW>,
  props: GTableProps<ROW>,
  hideRowsPerPage: boolean
): ReactNode => {
  const pagination = showPagination(props);

  if (!props.topBar && !pagination) {
    return null;
  }

  const maxResults = isPageDynamic(props) ? props.totalResults : (props.data?.length ?? 0);
  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      columnGap={1.5}
      rowGap={1}
      alignItems="flex-end"
      justifyContent={'flex-start'}
      width={'100%'}
    >
      {props.topBar}
      {pagination &&
        (isCursorDynamic(props) ? (
          <GTableCursorPagination table={table} hideRowsPerPage={hideRowsPerPage} />
        ) : (
          <GTablePagePagination table={table} totalResults={maxResults} hideRowsPerPage={hideRowsPerPage} />
        ))}
    </Stack>
  );
};

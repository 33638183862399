import { type Layout, Responsive, WidthProvider } from 'react-grid-layout';
import { Box } from '@mui/joy';
import { useCallback, useEffect, useState, type ReactElement } from 'react';

const ResponsiveGridLayout = WidthProvider(Responsive);

const breakpointColumns = {
  lg: 12,
  md: 6,
  sm: 3,
};

const breakpointWidths: Record<keyof typeof breakpointColumns, number> = {
  lg: 1200,
  md: 800,
  sm: 500,
};

export interface Widget {
  id: string;
  type: string;
  component: (props: {
    id: string;
    editing: boolean;
    forceStatic: (id: string, value: boolean) => void;
  }) => ReactElement;
  layout: Omit<Layout, 'i'>;
}

const WidgetContainer = ({
  editing,
  widgets,
  setWidgets,
}: {
  editing: boolean;
  widgets: Widget[];
  setWidgets: (arg: (prev: Widget[]) => Widget[]) => void;
}): ReactElement => {
  const [forceStatic, setForceStatic] = useState<string[]>([]);

  useEffect(() => {
    if (!editing) {
      setForceStatic([]);
    }
  }, [editing]);

  const widgetForceStatic = useCallback((id: string, val: boolean) => {
    if (val) {
      setForceStatic((prev) => [...prev, id]);
      return;
    }

    setForceStatic((prev) => prev.filter((widgetId) => widgetId !== id));
  }, []);

  return (
    <Box data-tour="widget-container">
      <ResponsiveGridLayout
        layouts={{
          lg: widgets.map((item) => ({
            ...item.layout,
            i: item.id,
            static: !editing || forceStatic.includes(item.id),
          })),
        }}
        breakpoints={breakpointWidths}
        cols={breakpointColumns}
        onLayoutChange={(layout) => {
          if (!editing) {
            return;
          }

          const idToLayout = Object.fromEntries(layout.map((lay) => [lay.i, lay]));
          setWidgets((prev: Widget[]): Widget[] =>
            prev.map((widget) => ({
              ...widget,
              layout: idToLayout[widget.id],
            }))
          );
        }}
        rowHeight={50}
      >
        {widgets.map((widget) => {
          return (
            <Box key={widget.id} data-tour="widget-card-item">
              <widget.component id={widget.id} editing={editing} forceStatic={widgetForceStatic} />
            </Box>
          );
        })}
      </ResponsiveGridLayout>
    </Box>
  );
};

export default WidgetContainer;

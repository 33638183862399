import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClearIcon from '@mui/icons-material/Clear';
import { type AutocompleteProps, createFilterOptions } from '@mui/joy';
import isNil from 'lodash/fp/isNil';
import type { CSSProperties, ReactNode } from 'react';
import { createRenderTagsFunction } from './GAutocomplete.utils';
import type { GAutocompleteProps } from './GSingleAutocomplete.props';
import { VirtualizedListbox } from './VirtualizedListBox/VirtualizedListbox.tsx';
import { calculatePlaceholder } from '../LabelService.ts';
import { EmptyClearIcon } from './EmptyClearIcon.tsx';
import widthSx from '../../../width.styles.ts';

export const calculateContainerClasses = <T,>(props: GAutocompleteProps<T>): CSSProperties => {
  const allWidthSx = {
    fullWidth: {
      width: '100%',
    },
    ...widthSx,
  };

  return {
    position: 'relative',
    height: 'fit-content',
    minWidth: '0',
    ...allWidthSx[props.width ?? 'fullWidth'],
  };
};

export type GOptionType = 'regular' | 'group' | 'hasMoreResults' | 'hasNoResults' | 'loading' | 'typeMore';

export interface GOption<T> {
  type: GOptionType;
  value?: T;
  name?: string; // for type===group
  key?: string; // for type===group
}

type JoyAutocompleteProps<T> = Exclude<keyof AutocompleteProps<GOption<T>, undefined, undefined, undefined>, 'options'>;

type RequiredAutoCompleteProps = 'getOptionLabel';
export const createJoyAutocompleteProps = <T,>(
  props: GAutocompleteProps<T>
): Pick<
  AutocompleteProps<GOption<T>, undefined, boolean | undefined, undefined>,
  Exclude<JoyAutocompleteProps<T>, RequiredAutoCompleteProps>
> &
  Required<
    Pick<AutocompleteProps<GOption<T>, undefined, boolean | undefined, undefined>, RequiredAutoCompleteProps>
  > => {
  const isOptionEqualToValue = props.isOptionEqualToValue;
  const groupBy = props.groupBy;
  const getOptionLabel = (option: GOption<T>): string => {
    if (option.type !== 'regular') {
      return '';
    }

    return props.getOptionLabel(option.value as T);
  };

  const optionsSearch = props.getOptionSearchText;
  return {
    variant: props.variant,
    onBlur: props.onBlur,
    getOptionLabel,
    isOptionEqualToValue: isOptionEqualToValue
      ? (opt1, opt2): boolean => {
          return opt1.type === opt2.type && isOptionEqualToValue(opt1.value!, opt2.value!);
        }
      : undefined,
    noOptionsText: <>No Options</>,
    popupIcon: <ArrowDropDownIcon />,
    clearIcon: <ClearIcon />,
    disabled: props.disabled,
    limitTags: props.limitTags,
    placeholder: calculatePlaceholder(props),
    groupBy: groupBy
      ? (opt): string => {
          if (opt.type !== 'regular') {
            return '';
          }

          return groupBy(opt.value as T);
        }
      : undefined,
    renderGroup: (params) => params as unknown as ReactNode,
    filterOptions: optionsSearch
      ? createFilterOptions({
          ignoreCase: true,
          ignoreAccents: true,
          matchFrom: 'any',
          stringify: (option: GOption<T>): string => {
            if (option.type !== 'regular') {
              return '';
            }

            const val = option.value;
            if (isNil(val)) {
              return '';
            }

            return optionsSearch(val);
          },
        })
      : (options: GOption<T>[]): GOption<T>[] => options, // pass all filter
    filterSelectedOptions: props.filterSelectedOptions ?? true,
    renderTags: createRenderTagsFunction({
      showChipTooltips: Boolean(props.showChipTooltips),
      getOptionLabel,
    }),
    color: props.color,
    slots: props.showClearable
      ? { listbox: VirtualizedListbox }
      : {
          clearIndicator: EmptyClearIcon,
          listbox: VirtualizedListbox,
        },
  };
};

import type {
  IAssetOptimizerResultQuery,
  IMultiAssetYieldOptimizationOutputType,
  IPortfolioOptimizerResultQuery,
  IYieldOptimizerResultQuery,
} from 'generated/graphql';
import { OptimizationType } from '../optimization.utils.ts';

export type PortfolioOptimization = IPortfolioOptimizerResultQuery['optimization']['getOptimization'];
export type AssetOptimization = IAssetOptimizerResultQuery['optimization']['getOptimization'];
export type AssetSolution = AssetOptimization['output'][number] & {
  name: string;
};

export type PortfolioSolution = PortfolioOptimization['output'][number] & {
  name: string;
};
export type Solution = (PortfolioOptimization | AssetOptimization)['output'][number] & {
  name: string;
};

const givenPortfolioId = -1;

export function isGivenPortfolio(solution: { id: number }): boolean {
  return solution.id === givenPortfolioId;
}

export const getSolutionName = (solutionId: number): string =>
  solutionId === givenPortfolioId ? 'Initial portfolio' : `Solution ${solutionId}`;

export const isAssetResult = (
  value: IAssetOptimizerResultQuery | IPortfolioOptimizerResultQuery | IMultiAssetYieldOptimizationOutputType,
  optimizationType: OptimizationType
): value is IAssetOptimizerResultQuery => optimizationType === OptimizationType.asset;

export const isPortfolioResult = (
  value: IAssetOptimizerResultQuery | IPortfolioOptimizerResultQuery | IMultiAssetYieldOptimizationOutputType,
  optimizationType: OptimizationType
): value is IPortfolioOptimizerResultQuery => optimizationType === OptimizationType.portfolio;

export const isYieldResult = (
  value: IAssetOptimizerResultQuery | IPortfolioOptimizerResultQuery | IYieldOptimizerResultQuery,
  optimizationType: OptimizationType
): value is IYieldOptimizerResultQuery => optimizationType === OptimizationType.yield;

import { Box, CircularProgress } from '@mui/joy';
import { isNil } from 'lodash/fp';
import type { Ref } from 'react';

import type { LoaderProps } from './Loader.props';
import widthSx from '../../width.styles.ts';

const Loader = ({
  variant = 'medium',
  positionVariant = 'centered',
  fullHeight,
  width,
  ref,
}: LoaderProps & { ref?: Ref<HTMLDivElement> }) => {
  return (
    <Box
      ref={ref}
      sx={{
        aspectRatio: '1',
        maxHeight: '100%',
        ...(fullHeight && { height: '100%', flexGrow: 1, minHeight: 0 }),
        ...(!isNil(width) && width !== 'fullWidth' && widthSx[width]),
        ...(positionVariant === 'centered' && {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }),
        ...(variant === 'small' && !fullHeight && { height: '1.5rem' }),
        ...(variant === 'medium' && !fullHeight && { height: '8rem' }),
      }}
    >
      <CircularProgress size={variant === 'small' ? 'sm' : 'md'} />
    </Box>
  );
};

export default Loader;

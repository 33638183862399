import { Box } from '@mui/joy';
import { type FunctionComponent, useRef, useState } from 'react';
import { useNavigate } from 'react-router';

import type { SunburstChartData, SunburstChartProps } from './SunburstChart.props';
import HighChartsWrapper from '../HighChartsWrapper/HighChartsWrapper.tsx';
import type { HighchartSeries } from '../HighChartsWrapper/Highchart.utils.ts';
import type Highcharts from 'highcharts';
import type { Point } from 'highcharts';
import { formatPercentage } from '../../../formatter.utils.ts';
import isNil from 'lodash/fp/isNil';

const shouldHaveCursor = (point: Point & { node: { children: unknown[] } }): boolean => {
  return (!!point.options.parent && point.node.children.length > 0) || !!point.options.custom?.link;
};

const calculateLabels = (
  point: {
    name: string;
    options: { parent?: string | undefined; custom?: Record<string, string> };
    value?: number | null | undefined;
  },
  textinfo: 'label' | 'percent root+label+text' | 'label+text',
  rootValue: number
): string[] => {
  if (textinfo === 'label') {
    return [point.name];
  }

  const parts = [point.name];
  const isRoot = !point.options.parent;
  if (!isRoot) {
    const percentage = (point.value ?? 0) / rootValue;
    parts.push(formatPercentage(percentage));
  }

  const customText = point.options.custom?.text;
  if (textinfo === 'percent root+label+text' && customText) {
    parts.push(customText);
  }

  return parts.filter((val) => !isNil(val));
};

const formatLabels = (parts: string[]): string => {
  return `<span>${parts.map((text) => `<tspan>${text}</tspan>`).join('<br/>')}</span>`;
};

const fontSize = 12;
const fontLineHeight = 1.2;
const calculateLayout = (data: SunburstChartData, clazz: string): Highcharts.Options => {
  const topLevelRootIndex = data.parents.findIndex((val) => !val);
  const topLevelRootValue = data.values[topLevelRootIndex];
  return {
    tooltip: {
      shared: false,
      formatter(this): string {
        const text: string[] = [];
        const point = (this as unknown as { point: Point }).point;
        text.push(point.name);
        if (data.hoverinfo === 'percent root+label+text') {
          text.push(`${formatPercentage((point.options.value ?? 0) / topLevelRootValue)}`);
        }

        text.push(point.options.custom?.text);
        return formatLabels(text);
      },
    },
    plotOptions: {
      sunburst: {
        allowTraversingTree: true,
        dataLabels: {
          enabled: true,
          padding: 0,
          rotationMode: 'auto',
          allowOverlap: true,
          verticalAlign: 'middle',
          className: clazz,
          style: {
            fontSize: `${fontSize}px`,
            fontFamily: 'Inter',
            fontWeight: '400',
            textOverflow: 'clip',
            letterSpacing: '0.9',
          },
          textPath: {
            attributes: {
              // by default highcharts doesnt take length of label text into account,
              // so with 3 lines of text, the text overlays lower level slices
              dy: data.textinfo === 'percent root+label+text' ? -10 : 0,
            },
            enabled: true,
          },
          formatter(this): string {
            const point = (this as unknown as { point: Point }).point;
            const parts: string[] = calculateLabels(point, data.textinfo, topLevelRootValue);
            const longestText = Math.max(...parts.map((part) => part.length));
            // adjust based on font properties
            const widthOfXForTheFontPx = 0.4 * fontSize;

            // @ts-expect-error highcharts don't expose all objects via typings
            const shape = this.point.node.shapeArgs;
            const angle = shape.end - shape.start;
            const innerArcPixels = angle * shape.innerR;
            // handle separately root and other nodes
            if (shape.innerR === 0) {
              if (widthOfXForTheFontPx * longestText > shape.r) {
                return '';
              }
            } else {
              if (widthOfXForTheFontPx * longestText > innerArcPixels) {
                return '';
              }

              // assume we need to fit 3 lines of text for each label
              const textHeight = fontSize * fontLineHeight * 3;
              if (shape.r - shape.innerR < textHeight) {
                return '';
              }
            }

            return formatLabels(parts);
          },
        },
      },
    },
  };
};

// it's not possible to center middle text using builting highcharts options,
// so we create a class name per chart on the fly and manually calculate
// the transformation offset for the text
let classNameIndex = 0;

const calculateRootText = (
  data: SunburstChartData,
  rootId: string
): { rootTextWidthPx: number; topLevelRootLines: number; rootTextLines: number } => {
  const topLevelRootIndex = data.parents.findIndex((val) => !val);
  const topLevelRootValue = data.values[topLevelRootIndex];
  const rootIndex = rootId ? data.ids.findIndex((val) => val === rootId) : topLevelRootIndex;
  const rootLabel = data.labels[rootIndex];
  const rootText = data.text[rootIndex];
  const rootTextInfo = calculateLabels(
    {
      name: rootLabel,
      value: topLevelRootValue,
      options: {
        custom: {
          text: rootText,
        },
      },
    },
    data.textinfo,
    topLevelRootValue
  );

  const rootTextWidthPx = rootTextInfo.length > 0 ? Math.max(...rootTextInfo.map((line) => line.length)) : 0;
  const topLevelRootLines = calculateLabels(
    {
      name: data.labels[topLevelRootIndex],
      value: data.values[topLevelRootIndex],
      options: {
        custom: {
          text: data.text[topLevelRootIndex],
        },
      },
    },
    data.textinfo,
    topLevelRootValue
  ).length;

  const rootTextLines = rootTextInfo.length;
  return { rootTextWidthPx, topLevelRootLines, rootTextLines };
};

const SunburstChart: FunctionComponent<SunburstChartProps> = ({ data, height }) => {
  const navigate = useNavigate();
  const [rootId, setRootId] = useState<string>('');
  const uniqueClass = useRef(`genie-sunburst-highcharts-${classNameIndex++}`);
  const layout = calculateLayout(data, uniqueClass.current);
  const { topLevelRootLines, rootTextLines } = calculateRootText(data, rootId);

  return (
    <>
      <Box
        sx={{
          '.highcharts-breadcrumbs-group': {
            display: 'none',
          },
          width: '100%',
          height: height === 'fullHeight' ? '100%' : height,
        }}
      >
        {
          <style>
            {`
            .${uniqueClass.current}:last-child text {
              text-anchor: middle;
              transform: ${rootId ? `translateY(${(rootTextLines - topLevelRootLines) * -0.5}em)` : ''};
            }
          `}
          </style>
        }
        <HighChartsWrapper
          loading={false}
          data={data}
          height={height}
          calculateOptions={() => layout}
          calculateChartData={(data): HighchartSeries[] => {
            const items: Highcharts.PointOptionsObject[] = [];
            for (let i = 0; i < data.ids.length; i++) {
              items.push({
                id: data.ids[i],
                name: data.labels[i],
                parent: data.parents[i],
                value: data.values[i],
                color: data.marker.colors[i],
                // color: undefined,
                custom: {
                  link: data.links[i],
                  text: data.text[i],
                },
              });
            }

            return [
              {
                data: items,
                type: 'sunburst',
                breadcrumbs: {
                  floating: true,
                },
                allowPointSelect: true,
                point: {
                  events: {
                    click(this: Point): void {
                      // @ts-ignore
                      setRootId(this.series.rootNode);
                    },
                    select(this: Point): void {
                      if (!this) {
                        return;
                      }

                      const link = this.options.custom?.link;
                      if (link) {
                        navigate(link);
                      }
                    },
                    mouseOver(this: Point): void {
                      // biome-ignore lint/suspicious/noExplicitAny: point field depend on a type of point
                      const anyThis = this as any;
                      const cursor = shouldHaveCursor(anyThis);

                      if (cursor) {
                        const element = anyThis.graphic.element as SVGElement;
                        element.style.cursor = 'pointer';
                      }
                    },
                  },
                },
              },
            ];
          }}
        />
      </Box>
    </>
  );
};

export default SunburstChart;

import { useGraphQLApiError } from '../../../technical/form/UseGraphQLApiError.tsx';
import { type FunctionComponent, Suspense } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import gYupResolver from '../../../technical/form/gYupResolver.ts';
import { Modal, ModalDialog, Stack, Typography } from '@mui/joy';
import GFormProvider from '../../../technical/form/GFormProvider.tsx';
import { GraphQLApiFormErrorMessage } from '../../../technical/form/GraphQLApiErrorMessage.tsx';
import SubmitButton from '../../../technical/form/SubmitButton.tsx';
import type { Dayjs } from 'dayjs';
import { isValidDayjsDateRange } from '../../../date.utils.ts';
import { FormDateRangeInput } from '../../../technical/form/FormDateRangeInput.tsx';
import ModalClose from '@mui/joy/ModalClose';
import UnifiedPnlReportList from './UnifiedPnlReportList.tsx';
import FormSelect from '../../../technical/form/FormSelect.tsx';
import { UnifiedPnlReportListInputDocument, useGenerateUnifiedPnlReportMutation } from 'generated/graphql.tsx';
import { formatISODate } from '../../../formatter.utils.ts';
import Loader from '../../../technical/Loader/Loader.tsx';
import { defaultRowSpacing } from '../../../StackSpacing.ts';
import type { NotVerifiedAsset } from '../../../market/asset/AssetLabelService.ts';
import FormMultiAutocomplete from 'components/technical/form/FormMultiAutocomplete.tsx';
import { createAssetAutocompleteProps, useAssetPaginatedOptions } from '../../../market/asset/AssetService.tsx';

interface FormOutputFields {
  dimension: string | null;
  dateRange: [Dayjs, Dayjs];
  assets: (NotVerifiedAsset & { id: string })[];
}

interface FormInputFields {
  dimension: string | null;
  dateRange: [Dayjs, Dayjs] | null;
  assets: (NotVerifiedAsset & { id: string })[];
}

const schema = yup.object({
  dimension: yup.string().nullable(),
  dateRange: yup.mixed().required().test('validDateRange', 'Date range is invalid', isValidDayjsDateRange),
  assets: yup.array(yup.object()).required(),
});

interface GenerateUnifiedPnlDialogProps {
  onClose: () => void;
  onReportSelected: (id: number) => void;
  onReportDeleted: (id: number) => void;
  existingDimensions: string[];
}

const GenerateUnifiedPnlDialog: FunctionComponent<GenerateUnifiedPnlDialogProps> = (props) => {
  const methods = useForm<FormInputFields>({
    resolver: gYupResolver(schema),
    defaultValues: {
      dimension: '',
      dateRange: null,
      assets: [],
    },
  });

  const [generateUnifiedPnlReport] = useGenerateUnifiedPnlReportMutation({
    refetchQueries: [UnifiedPnlReportListInputDocument],
  });

  const { getOptions } = useAssetPaginatedOptions();

  const { onErrorAndThrow } = useGraphQLApiError(methods);
  const submitReport = async (input: FormInputFields) => {
    const data = input as FormOutputFields;
    try {
      await generateUnifiedPnlReport({
        variables: {
          dimension: data.dimension,
          assetIds: data.assets.length > 0 ? data.assets.map((asset) => asset.id) : null,
          since: formatISODate(data.dateRange[0]),
          until: formatISODate(data.dateRange[1]),
        },
      });
    } catch (error) {
      onErrorAndThrow(error);
    }
  };

  return (
    <Modal open onClose={props.onClose}>
      <ModalDialog minWidth={'900px'}>
        <Stack rowGap={3}>
          <Typography level={'h4'}>Generate unified P&L report</Typography>
          <ModalClose />
          <GFormProvider {...methods}>
            <form onSubmit={methods.handleSubmit((data) => submitReport(data))}>
              <Stack spacing={3} alignItems="flex-start">
                <Stack spacing={defaultRowSpacing} direction={'row'} alignItems={'flex-start'}>
                  <FormDateRangeInput width="xl" name="dateRange" label="Date range" />
                  <FormSelect
                    label="Dimension"
                    name="dimension"
                    width="xl"
                    options={props.existingDimensions.map((dim) => ({
                      key: dim,
                      value: dim,
                      label: dim,
                    }))}
                  />
                  <FormMultiAutocomplete
                    name={'assets'}
                    label={'Assets'}
                    {...createAssetAutocompleteProps()}
                    getOptions={getOptions}
                    width={'xl2'}
                  />
                  <GraphQLApiFormErrorMessage />
                  <SubmitButton width="xl">Generate</SubmitButton>
                </Stack>
              </Stack>
            </form>
          </GFormProvider>
          <Typography level={'h4'}>Past reports</Typography>
          <Suspense fallback={<Loader />}>
            <UnifiedPnlReportList onReportSelected={props.onReportSelected} onReportDeleted={props.onReportDeleted} />
          </Suspense>
        </Stack>
      </ModalDialog>
    </Modal>
  );
};

export default GenerateUnifiedPnlDialog;

import { validateRequiredDate } from 'components/date.utils.ts';
import { validateRequiredNumber } from 'components/number.utils';
import { CsvError, parse } from 'csv-parse/browser/esm/sync';
import dayjs, { type Dayjs } from 'dayjs';

export const sampleFile = `
date,portfolio 1,portfolio 2
2024-01-01,0.02,
2024-01-03,0.01,-0.07
2024-01-05,0.04,0.05
2024-01-06,,0.02`.trim();

interface PortfolioRow {
  date: Dayjs;
  portfolioName: string;
  value: string;
}

const processLines = (
  lines: { date: string; [portfolio: string]: string }[]
): {
  lineErrors: { line: number; error: string }[];
  generalErrors: string[];
  data: PortfolioRow[];
} => {
  if (lines.length === 0) {
    return {
      generalErrors: ['File is empty'],
      lineErrors: [],
      data: [],
    };
  }

  const data: PortfolioRow[] = [];
  const allLineErrors: { line: number; error: string }[] = [];
  for (let i = 0; i < lines.length; i++) {
    const { date: dateText, ...rest } = lines[i];
    const dateErrors: string[] = [];
    const dateValidationResult = validateRequiredDate(dateText);
    if (dateValidationResult.error) {
      dateErrors.push(dateValidationResult.error);
    } else if (dateValidationResult.value!.isAfter(dayjs.utc())) {
      dateErrors.push('Date is in the future');
    }

    const portfolioLineErrors = [];
    for (const [portfolio, returns] of Object.entries(rest)) {
      if (returns.trim() === '') {
        continue;
      }

      const priceValidationResult = validateRequiredNumber({
        columnName: `${portfolio} returns`,
        numberText: returns,
      });

      if (priceValidationResult.error) {
        portfolioLineErrors.push(priceValidationResult.error);
      } else if (dateErrors.length === 0) {
        data.push({
          date: dateValidationResult.value!,
          portfolioName: portfolio,
          value: priceValidationResult.value!,
        });
      }
    }

    const lineErrors = [...dateErrors, ...portfolioLineErrors];
    if (lineErrors.length > 0) {
      allLineErrors.push({ line: i + 1, error: lineErrors.join('. ') });
    }
  }

  return {
    generalErrors: [],
    lineErrors: allLineErrors,
    data,
  };
};

export const parseFile = (
  file: string
): {
  lineErrors: { line: number; error: string }[];
  generalErrors: string[];
  data: PortfolioRow[];
} => {
  try {
    const lines: { date: string; [portfolio: string]: string }[] = parse(file.trim(), {
      skip_empty_lines: true,
      columns: true,
    });

    return processLines(lines);
  } catch (e) {
    if (e instanceof CsvError) {
      return {
        lineErrors: [
          {
            line: e.lines,
            error: e.code === 'CSV_RECORD_INCONSISTENT_COLUMNS' ? 'Invalid number of columns' : e.message,
          },
        ],
        generalErrors: [],
        data: [],
      };
    }

    throw e;
  }
};

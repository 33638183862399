import type { FunctionComponent, ReactElement, ReactNode } from 'react';
import { Navigate, useMatch, useParams } from 'react-router';
import type { IAsset, IAssetType } from 'generated/graphql';

import SingleAsset from './SingleAsset.tsx';
import PageShell from '../../../technical/PageShell/PageShell.tsx';
import { isPublicAsset } from '../Asset.types';
import { useAsset } from '../UseAsset';
import { useOptionsBaseAssets } from '../useOptionsBaseAssetId';

const DefaultAssetIdPageRouter: FunctionComponent<{ assetId: string }> = ({ assetId }) => {
  const { loaded, data: asset, Fallback } = useAsset(assetId);

  if (!loaded) {
    return <Fallback />;
  }

  return <DefaultAssetPageRouter asset={asset} />;
};

const DefaultAssetPageRouter: FunctionComponent<{ asset: Omit<IAsset, 'priceAsset'> }> = ({ asset }) => {
  if (isPublicAsset(asset)) {
    return <Navigate to={`/app/market/assets/${asset.id}/spot`} replace />;
  }

  return <Navigate to={`/app/market/assets/${asset.id}/price`} replace />;
};

export const OptionsGuardRoute: FunctionComponent<{
  children: ReactNode;
}> = ({ children }) => {
  const { assetId } = useParams();
  const { loaded, data, Fallback } = useOptionsBaseAssets();

  if (!loaded) {
    return <Fallback />;
  }

  if (data.some((asset) => asset.id === assetId)) {
    return <>{children}</>;
  }

  return <DefaultAssetIdPageRouter assetId={assetId!} />;
};

export const AssetGuardRoute: FunctionComponent<{ assetType: IAssetType; children: ReactNode }> = ({
  assetType,
  children,
}) => {
  const { assetId } = useParams();

  const { loaded, data: asset, Fallback } = useAsset(assetId!);
  if (!loaded) {
    return <Fallback />;
  }
  if (asset.type === assetType) {
    return <>{children}</>;
  }
  return <DefaultAssetPageRouter asset={asset} />;
};

export const SingleAssetPageContainer = (): ReactElement => {
  const { assetId } = useParams();
  const match = useMatch('/app/market/assets/:assetId');

  const { loaded, data: asset, Fallback } = useAsset(assetId!);
  if (!loaded) {
    return (
      <PageShell>
        <Fallback />
      </PageShell>
    );
  }

  if (match) {
    return <DefaultAssetPageRouter asset={asset} />;
  }

  return (
    <PageShell>
      <SingleAsset />
    </PageShell>
  );
};

import { Grid, Typography } from '@mui/joy';
import type { ReactElement } from 'react';
import { formatCash, formatPercentage } from 'components/formatter.utils';
import { KeyValueItem } from 'components/technical/KeyValueItem';
import Tile, { TileContent } from 'components/technical/Tile/Tile';
import { IPortfolioExposureStatName } from 'generated/graphql';
import type { Solution } from './Results.types.ts';

const portfolioExposureTiles = [
  {
    title: 'Exposures',
    values: [IPortfolioExposureStatName.Gross, IPortfolioExposureStatName.Net],
  },
  {
    title: 'Direction',
    values: [IPortfolioExposureStatName.Long, IPortfolioExposureStatName.Short],
  },
  {
    title: 'Cash position',
    values: [IPortfolioExposureStatName.Cash, IPortfolioExposureStatName.CashShortFuture],
  },
];

const portfolioExposureTranslation: Record<IPortfolioExposureStatName, string> = {
  [IPortfolioExposureStatName.Gross]: 'Gross exposure',
  [IPortfolioExposureStatName.Net]: 'Net exposure',
  [IPortfolioExposureStatName.Long]: 'Long exposure',
  [IPortfolioExposureStatName.Short]: 'Short exposure',
  [IPortfolioExposureStatName.Cash]: 'If shorting spot',
  [IPortfolioExposureStatName.CashShortFuture]: 'If shorting futures',
};

export const OptimizerSolutionPortfolioExposure = ({ solution }: { solution: Solution }): ReactElement => {
  const portfolioExposureTypeToValue = Object.fromEntries(solution.portfolioExposures.map((exp) => [exp.name, exp]));

  return (
    <>
      <Typography level="h4">Exposures</Typography>
      <Grid container>
        {portfolioExposureTiles.map((tile) => (
          <Grid md={4} xs={12} key={tile.title}>
            <Tile>
              <TileContent title={tile.title}>
                <Typography level="title-xs" component="h3">
                  {tile.title}
                </Typography>
                {tile.values
                  .filter((val) => !!portfolioExposureTypeToValue[val])
                  .map((val) => (
                    <KeyValueItem
                      key={val}
                      label={portfolioExposureTranslation[val]}
                      value={`${formatCash(portfolioExposureTypeToValue[val].amount)} (${formatPercentage(
                        portfolioExposureTypeToValue[val].percentage
                      )})`}
                    />
                  ))}
              </TileContent>
            </Tile>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

import { Box, Stack } from '@mui/joy';
import { defaultRowSpacing } from 'components/StackSpacing';
import GAgGrid from 'components/technical/grids/GAgGrid';
import GButton from 'components/technical/inputs/GButton/GButton';
import type { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import type { ColDef, ValueGetterParams } from 'ag-grid-community';
import { gridWithInputStyles } from 'components/technical/grids/gridStyles';
import { bignumber } from 'mathjs';
import type { PortfolioOptimizerInputFields } from '../PortfolioOptimizer.validation.ts';
import type { Portfolio } from '../PortfolioOptimizerWizard.tsx';
import { defaultCol } from '../../initialPortfolio/InitialPortfolioStep.utils.tsx';
import { syncOutlookWithGivenUniverseFields } from '../../assumptionsAndOutlook/AssumptionsAndOutlook.utils.ts';
import { portfolioDefinitionSharedColumns } from '../PortfolioDefinitionSharedColumns.ts';
import { InitialPortfolioInputCellRenderer } from '../../initialPortfolio/InitialPortfolioInputCellRenderer.tsx';

export type PortfolioRow = Portfolio;

const InitialPortfolioStep = ({
  optimizableFunds,
  goToNextStep,
}: {
  optimizableFunds: PortfolioRow[];
  goToNextStep: () => void;
}): ReactElement => {
  const { getValues, setValue, clearErrors } = useFormContext<PortfolioOptimizerInputFields>();

  const columns: ColDef<PortfolioRow>[] = [
    ...portfolioDefinitionSharedColumns,
    {
      colId: 'givenPortfolio',
      headerName: 'Cash weight',
      cellRenderer: InitialPortfolioInputCellRenderer,
      cellStyle: gridWithInputStyles.inputCellStyle,
      filter: 'agNumberColumnFilter',
      valueGetter: (params: ValueGetterParams<PortfolioRow, number>): number | undefined => {
        if (!params.data) {
          return undefined;
        }
        const assetId = params.data.id;
        const value = getValues(`givenPortfolio.${assetId}`);

        return value ? bignumber(value).toNumber() : undefined;
      },
    },
  ];

  return (
    <Stack spacing={defaultRowSpacing}>
      <Stack spacing={defaultRowSpacing} direction="row-reverse">
        <GButton
          variant="plain"
          onClick={(): void => {
            const emptyGivenPortfolio = Object.fromEntries(
              optimizableFunds.map((optimizableFund) => [optimizableFund.id, null])
            );
            setValue('givenPortfolio', emptyGivenPortfolio);
            syncOutlookWithGivenUniverseFields(getValues, setValue, clearErrors);
          }}
        >
          Clear all
        </GButton>
      </Stack>
      <Box height="500px">
        <GAgGrid
          rowData={optimizableFunds}
          columnDefs={columns}
          defaultColDef={defaultCol}
          autoSizeStrategy={{ type: 'fitGridWidth' }}
          rowHeight={gridWithInputStyles.rowHeight}
        />
      </Box>
      <GButton onClick={goToNextStep} sx={{ marginLeft: 'auto' }}>
        Next
      </GButton>
    </Stack>
  );
};

export default InitialPortfolioStep;

import { Card, Stack, Typography } from '@mui/joy';
import { isNil, uniq } from 'lodash/fp';
import type { FunctionComponent } from 'react';
import { IAssetType, type IPortfolioExposureRiskQuery } from '../../../generated/graphql';
import NewsSectionContainer from '../../market/asset/NewsSectionContainer';

const AssetNewsTitle = 'News';
// const ASSET_NEWS_HEIGHT = 475;

type SubAccountPositions = IPortfolioExposureRiskQuery['portfolio']['positions']['positions'];

const AssetNews: FunctionComponent<{
  positions: SubAccountPositions;
}> = ({ positions }) => {
  const publicAssetIds = positions
    .map((pos) => (pos.asset.type === IAssetType.Public ? pos.asset.id : undefined))
    .filter((id): id is string => !isNil(id));
  const derivativeBaseAssetIds = positions
    .map((pos) => pos.asset.derivativeDetails?.baseAsset.id)
    .filter((id): id is string => !isNil(id));

  const uniqAssetIds = uniq([...publicAssetIds, ...derivativeBaseAssetIds]);
  return (
    <Stack gap={1.5} height="100%">
      <Card>
        <Typography level="h3">{AssetNewsTitle}</Typography>
        <NewsSectionContainer assetIds={uniqAssetIds} />
      </Card>
    </Stack>
  );
};

export default AssetNews;

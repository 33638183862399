import { bignumber } from 'mathjs';
import * as yup from 'yup';
import type { BuilderPortfolioPosition } from './PortfolioBuilder';

export type PortfolioBuilderInputFields = {
  portfolioEquity: string | null;
  positions: Record<string, BuilderPortfolioPosition>;
};

export const positionSchema: yup.Schema = yup.object({
  weight: yup.number().required('Weight is required').notOneOf([0], 'Weight must be a non-zero number'),
  value: yup.number().required('Value is required').notOneOf([0], 'Value must be a non-zero number'),
  asset: yup.object(),
});

export const schema = yup.object({
  portfolioEquity: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? undefined : value))
    .positive('Portfolio equity must be a positive number')
    .notRequired(),
  positions: yup.lazy((value) =>
    yup
      .object()
      .shape(
        value && typeof value === 'object'
          ? Object.fromEntries(Object.keys(value).map((key) => [key, positionSchema]))
          : {}
      )
  ),
});

export function calculateValue(equity: number, weight: number): string {
  return bignumber(weight).div(100).mul(equity).toFixed(2);
}

export function calculateWeight(equity: number, value: number): string {
  return bignumber(value).div(equity).mul(100).toFixed(2);
}

import { Stack, Typography as JoyTypography } from '@mui/joy';
import * as yup from 'yup';
import EthereumLogo from 'cryptocurrency-icons/svg/color/eth.svg?react';
import mapValues from 'lodash/fp/mapValues';
import type { ReactElement } from 'react';
import type { SvgIcon } from '@mui/material';

import BitcoinLogo from './bitcoin.svg?react';
import AploLogo from './aplo.svg?react';
import BinanceLogo from './binance.svg?react';
import BitgetLogo from './bitget.svg?react';
import BitgoLogo from './bitgo.svg?react';
import BybiLogo from './bybit.svg?react';
import CoinBaseLogo from './coinbase.svg?react';
import CoinBaseBlueLetterCLogo from './coinBaseBlueLetterC.svg?react';
import CoinBaseAdvancedLogo from './coinBaseAdvanced.svg?react';
import CopperLogo from './copper.svg?react';
import { DefaultIPWhitelistingSection } from './DefaultIPWhitelistingSection.tsx';
import DeribitLogo from './deribit.svg?react';
import FalconXLogo from './falconx.svg?react';
import FireblocksLogo from './fireblocks.svg?react';
import FtxLogo from './ftx.svg?react';
import GateioLogo from './gateio.svg?react';
import HuobiLogo from './huobi.svg?react';
import HyperliquidLogo from './hyperliquid.svg?react';
import KrakenLogo from './kraken.svg?react';
import KucoinLogo from './kucoin.svg?react';
import OKXLogo from './okx.svg?react';
import PhemexLogo from './phemex.svg?react';
import { SelectableIPAddresses } from './SelectableIPAddresses.tsx';
import SolanaLogo from './solana.svg?react';
import DyDxLogo from './dydx.svg?react';
import sFoxLogo from './sFox.svg?react';
import UnknownVenueLogo from './unknownExchange.svg?react';
import { commonBlack, commonWhite, neutralPlainDisabledColor } from '../../theme/colors';
import { formatLabelToName, normalizeParts } from '../formatter.utils';
import { yupWhen } from '../../validation.ts';
import OkxV2MasterCredentials from '../portfolio/account/CreateAccountDialog/OkxV2MasterCredentials.tsx';
import BinanceV2MasterCredentials from '../portfolio/account/CreateAccountDialog/BinanceV2MasterCredentials.tsx';

export interface VenueField {
  label: string;
  name: string;
  type?: string;
  optional?: boolean;
}

export interface VenueEntry {
  name: string;
  blockchain?: boolean;
  tooltip?: string;
  symbol: string;
  cannotConnect?: boolean;
  icon: (() => ReactElement) | typeof SvgIcon;
  iconBackground: string;
  baseFields?: VenueField[];
  getFieldsSchema: () => yup.ObjectSchema<object>;
  disclaimer: () => ReactElement;
  adminOnly?: boolean;
  extraFields?: () => ReactElement;
  uiOnlyFields: string[];
  onboarding?: {
    assets?: boolean;
    recalculateHistoricalBalance?: boolean;
  };
}

export const unknownVenueLabel = 'ex:unknown';
export const GENIE_VENUE = 'dpr:genie';

const unknownVenue: VenueEntry = {
  name: 'Unknown',
  symbol: 'N/A',
  cannotConnect: true,
  icon: UnknownVenueLogo,
  iconBackground: neutralPlainDisabledColor,
  disclaimer: DefaultIPWhitelistingSection,
  getFieldsSchema: () => yup.object(),
  uiOnlyFields: [],
};

const formatLabelToSymbol = (name: Label): string => {
  if (!name) {
    return '';
  }

  const parts = normalizeParts(name);
  const lastPart = parts.at(-1);
  if (!lastPart) {
    throw new Error('Empty path');
  }

  return lastPart.toUpperCase();
};

export const createUnsupportedVenueEntry = (label: Label): VenueEntry => {
  return {
    ...unknownVenue,
    name: formatLabelToName(label),
    symbol: formatLabelToSymbol(label),
  };
};

const emptyDisclaimer = (): ReactElement => <></>;
export const rawVenues: Record<
  string,
  Omit<VenueEntry, 'disclaimer' | 'getFieldsSchema' | 'uiOnlyFields'> & {
    disclaimer?: VenueEntry['disclaimer'];
    getFieldsSchema?: () => yup.ObjectSchema<object>;
    uiOnlyFields?: string[];
  }
> = {
  'cex:aplo': {
    name: 'APLO',
    symbol: 'APLO',
    icon: AploLogo,
    iconBackground: commonBlack,
    baseFields: [
      { label: 'API key', name: 'api_key' },
      { label: 'Account id', name: 'account_id' },
    ],
  },
  'cex:aplo-v2': {
    name: 'APLO',
    symbol: 'APLO',
    icon: AploLogo,
    iconBackground: commonBlack,
    adminOnly: true,
    baseFields: [
      { label: 'API key', name: 'api_key' },
      { label: 'Account id', name: 'account_id' },
    ],
  },
  'cex:binance': {
    name: 'Binance',
    symbol: 'BIN',
    icon: BinanceLogo,
    iconBackground: commonBlack,
    baseFields: [
      { label: 'API key', name: 'api_key' },
      { label: 'Secret key', name: 'secret', type: 'password' },
    ],
    // onboarding: {
    //   assets: true,
    //   recalculateHistoricalBalance: true,
    // },
    disclaimer: (): ReactElement => {
      return (
        <Stack spacing={1.5}>
          <JoyTypography color="neutral" level="body-md" maxWidth={500}>
            IP whitelisting is required to receive derivative information from the exchange. Without it we will be only
            able to track spots. Please whitelist the following IP addresses:
          </JoyTypography>
          <SelectableIPAddresses />
        </Stack>
      );
    },
  },
  'cex:binance-v2': {
    name: 'Binance',
    symbol: 'BIN',
    icon: BinanceLogo,
    iconBackground: commonBlack,
    adminOnly: true,
    baseFields: [
      { label: 'API key', name: 'api_key' },
      { label: 'Secret key', name: 'secret', type: 'password' },
      { label: 'Email', name: 'email', optional: true },
    ],
    extraFields: BinanceV2MasterCredentials,
    getFieldsSchema: () => {
      const trimmedString = yup.string().trim().required();
      return yup.object({
        api_key: trimmedString,
        secret: trimmedString,
        use_master_credentials: yup.boolean().default(false), // snake case for consistency, not send to the backend
        master_key: yupWhen(['use_master_credentials'], ([useMasterCredentials]): yup.Schema => {
          if (useMasterCredentials) {
            return yup.object({
              api_key: trimmedString,
              secret: trimmedString,
            });
          }

          return yup.mixed().notRequired();
        }),
      });
    },
    disclaimer: (): ReactElement => {
      return (
        <Stack spacing={1.5}>
          <JoyTypography color="neutral" level="body-md" maxWidth={500}>
            IP whitelisting is required to receive derivative information from the exchange. Without it we will be only
            able to track spots. Please whitelist the following IP addresses:
          </JoyTypography>
          <SelectableIPAddresses />
        </Stack>
      );
    },
  },
  'cex:binanceus': {
    name: 'Binance US',
    symbol: 'BIN US',
    icon: BinanceLogo,
    iconBackground: commonBlack,
    baseFields: [
      { label: 'API key', name: 'api_key' },
      { label: 'Secret key', name: 'secret', type: 'password' },
    ],
    adminOnly: true,
  },
  'cex:bitget': {
    name: 'Bitget',
    symbol: 'Bitget',
    icon: BitgetLogo,
    iconBackground: commonBlack,
    baseFields: [
      { label: 'API key', name: 'api_key' },
      { label: 'Secret key', name: 'secret', type: 'password' },
      { label: 'Passphrase', name: 'password', type: 'password' },
    ],
  },
  'cex:bitget-v2': {
    name: 'Bitget',
    symbol: 'Bitget',
    icon: BitgetLogo,
    iconBackground: commonBlack,
    adminOnly: true,
    baseFields: [
      { label: 'API key', name: 'api_key' },
      { label: 'Secret key', name: 'secret', type: 'password' },
      { label: 'Passphrase', name: 'password', type: 'password' },
    ],
  },
  'cex:bybit': {
    name: 'Bybit',
    symbol: 'Bybit',
    icon: BybiLogo,
    iconBackground: commonBlack,
    baseFields: [
      { label: 'API key', name: 'api_key' },
      { label: 'Secret key', name: 'secret', type: 'password' },
    ],
  },
  'cex:bybit-v2': {
    name: 'Bybit',
    symbol: 'Bybit',
    icon: BybiLogo,
    iconBackground: commonBlack,
    adminOnly: true,
    baseFields: [
      { label: 'API key', name: 'api_key' },
      { label: 'Secret key', name: 'secret', type: 'password' },
    ],
  },
  'cex:coinbaseprime': {
    name: 'Coinbase Prime',
    symbol: 'Coinbase Prime',
    icon: CoinBaseLogo,
    iconBackground: '#0052FF',
    baseFields: [
      { label: 'Access key', name: 'api_key' },
      { label: 'Signing key', name: 'secret', type: 'password' },
      { label: 'Passphrase', name: 'password', type: 'password' },
      { label: 'Service account id', name: 'account_id' },
    ],
  },
  'cex:coinbaseprime-v2': {
    name: 'Coinbase Prime',
    symbol: 'Coinbase Prime',
    icon: CoinBaseLogo,
    iconBackground: '#0052FF',
    adminOnly: true,
    baseFields: [
      { label: 'Access key', name: 'api_key' },
      { label: 'Signing key', name: 'secret', type: 'password' },
      { label: 'Passphrase', name: 'password', type: 'password' },
      { label: 'Service account id', name: 'account_id' },
    ],
  },
  'cex:copper': {
    name: 'Copper',
    symbol: 'Copper',
    icon: CopperLogo,
    iconBackground: commonBlack,
    baseFields: [
      { label: 'API key', name: 'api_key' },
      { label: 'Secret key', name: 'secret', type: 'password' },
    ],
  },
  'cex:coinbasepro': {
    name: 'Coinbase Pro',
    symbol: 'Coinbase Pro',
    icon: CoinBaseLogo,
    iconBackground: commonBlack,
    baseFields: [
      { label: 'API key', name: 'api_key' },
      { label: 'Secret key', name: 'secret', type: 'password' },
      { label: 'Password', name: 'password', type: 'password' },
    ],
  },
  'cex:coinbase': {
    name: 'Coinbase',
    symbol: 'Coinbase',
    icon: CoinBaseBlueLetterCLogo,
    iconBackground: commonBlack,
    baseFields: [
      { label: 'API key', name: 'api_key' },
      { label: 'Secret key', name: 'secret', type: 'password' },
    ],
  },
  'cex:coinbaseadvanced': {
    name: 'Coinbase Advanced',
    symbol: 'Coinbase Advanced',
    icon: CoinBaseAdvancedLogo,
    iconBackground: commonWhite,
    baseFields: [
      { label: 'API key', name: 'api_key' },
      { label: 'Secret key', name: 'secret', type: 'password' },
    ],
    adminOnly: true,
  },
  'cex:debank': {
    name: 'EVM',
    blockchain: true,
    symbol: 'ETH',
    icon: EthereumLogo,
    iconBackground: '#687fed',
    baseFields: [{ label: 'Public address', name: 'public_id' }],
    // empty disclaimer. By default, if the property isn't defined, we show
    // information about ip whitelisting, but it's not needed here
    disclaimer: (): ReactElement => <></>,
  },
  'cex:debank-v2': {
    name: 'EVM',
    blockchain: true,
    symbol: 'EVM',
    icon: EthereumLogo,
    iconBackground: '#687fed',
    adminOnly: true,
    baseFields: [{ label: 'Public address', name: 'public_id' }],
    // empty disclaimer. By default, if the property isn't defined, we show
    // information about ip whitelisting, but it's not needed here
    disclaimer: (): ReactElement => <></>,
  },
  'cex:deribit': {
    name: 'Deribit',
    symbol: 'Deribit',
    icon: DeribitLogo,
    iconBackground: commonBlack,
    baseFields: [
      { label: 'API key', name: 'api_key' },
      { label: 'Secret key', name: 'secret', type: 'password' },
    ],
  },
  'cex:deribit-v2': {
    name: 'Deribit',
    symbol: 'Deribit',
    icon: DeribitLogo,
    iconBackground: commonBlack,
    adminOnly: true,
    baseFields: [
      { label: 'API key', name: 'api_key' },
      { label: 'Secret key', name: 'secret', type: 'password' },
    ],
  },
  'cex:dydx': {
    name: 'DyDx',
    blockchain: true,
    symbol: 'DyDx',
    icon: DyDxLogo,
    iconBackground: commonBlack,
    baseFields: [
      { label: 'Passphrase', name: 'password', type: 'password' },
      { label: 'API key', name: 'api_key' },
      { label: 'Secret key', name: 'secret', type: 'password' },
    ],
    // empty disclaimer. By default, if the property isn't defined, we show
    // information about ip whitelisting, but it's not needed here
    disclaimer: (): ReactElement => <></>,
  },
  'cex:falconx': {
    name: 'Falcon X',
    symbol: 'FalconX',
    icon: FalconXLogo,
    iconBackground: commonBlack,
    baseFields: [
      { label: 'API key', name: 'api_key' },
      { label: 'Secret key', name: 'secret', type: 'password' },
      { label: 'Password', name: 'password', type: 'password' },
    ],
  },
  'cex:falconx-v2': {
    name: 'Falcon X',
    symbol: 'FalconX',
    icon: FalconXLogo,
    iconBackground: commonBlack,
    adminOnly: true,
    baseFields: [
      { label: 'API key', name: 'api_key' },
      { label: 'Secret key', name: 'secret', type: 'password' },
      { label: 'Password', name: 'password', type: 'password' },
    ],
  },
  'cex:fireblocks': {
    name: 'Fireblocks',
    symbol: 'Fireblocks',
    icon: FireblocksLogo,
    iconBackground: commonBlack,
    baseFields: [
      { label: 'API key', name: 'api_key' },
      { label: 'Private key', name: 'secret', type: 'password' },
    ],
    disclaimer: () => (
      <>
        <DefaultIPWhitelistingSection />
        Please be aware that transaction information provided by Fireblocks might be incomplete.
      </>
    ),
  },
  'cex:ftx': {
    name: 'FTX',
    symbol: 'FTX',
    icon: FtxLogo,
    iconBackground: commonWhite,
    cannotConnect: true,
  },
  'cex:ftxus': {
    name: 'FTX US',
    symbol: 'FTX US',
    icon: FtxLogo,
    iconBackground: commonWhite,
    cannotConnect: true,
  },
  'cex:huobi': {
    name: 'Huobi',
    symbol: 'Huobi',
    icon: HuobiLogo,
    iconBackground: commonBlack,
    baseFields: [
      { label: 'API key', name: 'api_key' },
      { label: 'Secret key', name: 'secret', type: 'password' },
    ],
    adminOnly: true,
  },
  'cex:hyperliquid': {
    name: 'Hyperliquid',
    blockchain: true,
    symbol: 'Hyperliquid',
    icon: HyperliquidLogo,
    iconBackground: '#072722',
    adminOnly: true,
    baseFields: [{ label: 'Public address', name: 'public_id' }],
    // empty disclaimer. By default, if the property isn't defined, we show
    // information about ip whitelisting, but it's not needed here
    disclaimer: emptyDisclaimer,
  },
  'cex:gateio': {
    name: 'Gate.io',
    symbol: 'GateIO',
    icon: GateioLogo,
    iconBackground: commonWhite,
    baseFields: [
      { label: 'API key', name: 'api_key' },
      { label: 'Secret key', name: 'secret', type: 'password' },
    ],
  },
  'cex:kraken': {
    name: 'Kraken',
    symbol: 'Kraken',
    icon: KrakenLogo,
    iconBackground: '#5741D9',
    cannotConnect: true,
    baseFields: [
      { label: 'API key', name: 'api_key' },
      { label: 'Secret key', name: 'secret', type: 'password' },
    ],
  },
  'cex:kraken-v2': {
    name: 'Kraken',
    symbol: 'Kraken',
    icon: KrakenLogo,
    iconBackground: '#5741D9',
    adminOnly: true,
    baseFields: [
      { label: 'API key', name: 'api_key' },
      { label: 'Secret key', name: 'secret', type: 'password' },
    ],
  },
  'cex:bitquery_btc': {
    name: 'Bitcoin',
    blockchain: true,
    symbol: 'Bitcoin',
    icon: BitcoinLogo,
    iconBackground: commonBlack,
    cannotConnect: true,
    baseFields: [{ label: 'Public address', name: 'publicId' }],
    // empty disclaimer. By default, if the property isn't defined, we show
    // information about ip whitelisting, but it's not needed here
    disclaimer: (): ReactElement => <></>,
  },
  'cex:kucoinfutures': {
    name: 'Kucoin Futures',
    symbol: 'Kucoin',
    icon: KucoinLogo,
    iconBackground: commonBlack,
    baseFields: [
      { label: 'API key', name: 'api_key' },
      { label: 'Secret key', name: 'secret', type: 'password' },
      { label: 'Password', name: 'password', type: 'password' },
    ],
    adminOnly: true,
  },
  'cex:okx': {
    name: 'OKX',
    symbol: 'OKX',
    icon: OKXLogo,
    iconBackground: commonBlack,
    baseFields: [
      { label: 'Password', name: 'password', type: 'password' },
      { label: 'API key', name: 'api_key' },
      { label: 'Secret key', name: 'secret', type: 'password' },
    ],
  },
  'cex:okx-v2': {
    name: 'OKX',
    symbol: 'OKX',
    icon: OKXLogo,
    iconBackground: commonBlack,
    adminOnly: true,
    baseFields: [
      { label: 'Password', name: 'password', type: 'password' },
      { label: 'API key', name: 'api_key' },
      { label: 'Secret key', name: 'secret', type: 'password' },
    ],
    extraFields: OkxV2MasterCredentials,
    getFieldsSchema: () => {
      const trimmedString = yup.string().trim().required();
      return yup.object({
        password: trimmedString,
        api_key: trimmedString,
        secret: trimmedString,
        use_master_credentials: yup.boolean().default(false), // snake case for consistency, not send to the backend
        master_key: yupWhen(['use_master_credentials'], ([useMasterCredentials]): yup.Schema => {
          if (useMasterCredentials) {
            return yup.object({
              password: trimmedString,
              api_key: trimmedString,
              secret: trimmedString,
            });
          }

          return yup.mixed().notRequired();
        }),
      });
    },
    uiOnlyFields: ['use_master_credentials'],
  },
  'cex:phemex': {
    name: 'Phemex',
    symbol: 'Phemex',
    icon: PhemexLogo,
    iconBackground: commonBlack,
    baseFields: [
      { label: 'ID', name: 'api_key' },
      { label: 'API Secret', name: 'secret', type: 'password' },
    ],
  },
  'cex:bitgo': {
    name: 'BitGo',
    symbol: 'BitGo',
    icon: BitgoLogo,
    iconBackground: commonWhite,
    adminOnly: true,
    baseFields: [{ label: 'Access Token', name: 'access_token' }],
    // empty disclaimer. By default, if the property isn't defined, we show
    // information about ip whitelisting, but it's not needed here
    disclaimer: emptyDisclaimer,
  },
  'cex:sfox': {
    name: 'sFox',
    symbol: 'sFox',
    adminOnly: true,
    icon: sFoxLogo,
    iconBackground: commonBlack,
    baseFields: [{ label: 'ID', name: 'api_key' }],
    // empty disclaimer. By default, if the property isn't defined, we show
    // information about ip whitelisting, but it's not needed here
    disclaimer: emptyDisclaimer,
  },
  'cex:solana': {
    name: 'Solana',
    blockchain: true,
    symbol: 'Solana',
    icon: SolanaLogo,
    iconBackground: commonBlack,
    baseFields: [{ label: 'Public address', name: 'public_id' }],
    // empty disclaimer. By default, if the property isn't defined, we show
    // information about ip whitelisting, but it's not needed here
    disclaimer: emptyDisclaimer,
  },
  'cex:solana-v2': {
    name: 'Solana',
    blockchain: true,
    symbol: 'Solana',
    icon: SolanaLogo,
    iconBackground: commonBlack,
    adminOnly: true,
    baseFields: [{ label: 'Public address', name: 'public_id' }],
    // empty disclaimer. By default, if the property isn't defined, we show
    // information about ip whitelisting, but it's not needed here
    disclaimer: emptyDisclaimer,
  },
  'cex:solscan': {
    name: 'SolScan',
    blockchain: true,
    symbol: 'Solana',
    icon: SolanaLogo,
    iconBackground: commonBlack,
    adminOnly: true,
    baseFields: [{ label: 'Public address', name: 'public_id' }],
    // empty disclaimer. By default, if the property isn't defined, we show
    // information about ip whitelisting, but it's not needed here
    disclaimer: emptyDisclaimer,
  },
  [GENIE_VENUE]: {
    name: 'Virtual',
    symbol: 'Virtual',
    cannotConnect: true,
    icon: unknownVenue.icon,
    iconBackground: unknownVenue.iconBackground,
  },
  [unknownVenueLabel]: unknownVenue,
};

export const venues: Record<string, VenueEntry> = mapValues(
  (obj): VenueEntry => ({
    disclaimer: DefaultIPWhitelistingSection,
    getFieldsSchema: () => {
      const venueExtraFields = obj.baseFields ?? [];

      const fieldsSchema = Object.fromEntries(
        venueExtraFields.filter((field) => !field.optional).map((field) => [field.name, yup.string().trim().required()])
      );

      return yup.object(fieldsSchema);
    },
    uiOnlyFields: [],
    ...obj,
  }),
  rawVenues
);

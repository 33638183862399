import Close from '@mui/icons-material/Close';
import { type AutocompleteRenderGetTagProps, Chip, Tooltip } from '@mui/joy';
import { type ReactNode, useCallback } from 'react';
import type { GOption } from './GAutocomplete.tsx';
import type { GAutocompleteProps } from './GSingleAutocomplete.props.ts';

type RenderTagsFunction<T> = (
  value: Array<T>,
  getTagProps: AutocompleteRenderGetTagProps,
  ownerState: object
) => ReactNode;

export function createRenderTagsFunction<T>({
  showChipTooltips,
  getOptionLabel,
}: {
  showChipTooltips: boolean;
  getOptionLabel: (value: T) => string;
}): RenderTagsFunction<T> {
  return useCallback(
    function renderTags(tags: Array<T>, getTagProps: AutocompleteRenderGetTagProps): ReactNode {
      return tags.map((item, index) => {
        const label = getOptionLabel(item);
        const { key, ...tagProps } = getTagProps({ index });

        const chip = (
          <Chip
            key={key}
            endDecorator={<Close fontSize="small" />}
            sx={{
              maxWidth: '100%',
            }}
            {...tagProps}
          >
            {label}
          </Chip>
        );

        if (showChipTooltips) {
          return (
            <Tooltip title={label} key={label} placement="left">
              {chip}
            </Tooltip>
          );
        }

        return chip;
      });
    },
    [showChipTooltips, getOptionLabel]
  );
}

export const queryOptions = async <TValue,>({
  value,
  debouncedInput,
  setOptions,
  signal,
  isOptionEqualToValue,
  getOptions,
}: {
  value: TValue[];
  debouncedInput: string;
  setOptions: (options: GOption<TValue>[]) => void;
  signal: AbortSignal;
  isOptionEqualToValue?: (a: TValue, b: TValue) => boolean;
  getOptions: GAutocompleteProps<TValue>['getOptions'];
}) => {
  const selectedGOptions = value.map(
    (opt): GOption<TValue> => ({
      type: 'regular',
      value: opt,
    })
  );

  setOptions([
    {
      type: 'loading',
    },
    ...selectedGOptions,
  ]);

  const result = await getOptions({ input: debouncedInput, signal, paginationState: null });
  const fetchedOptions = result.data;

  const finalIsOptionEqualToValue =
    isOptionEqualToValue ?? ((option1: TValue, option2: TValue): boolean => option1 === option2);
  const newOptions = fetchedOptions.filter((opt) => !value.some((selOpt) => finalIsOptionEqualToValue(selOpt, opt)));

  const extraOptions: GOption<TValue>[] = [];
  if (result.typeMore) {
    extraOptions.push({
      type: 'typeMore',
    });
  }

  if (result.hasMoreResults) {
    extraOptions.push({
      type: 'hasMoreResults',
    });
  }

  if (!result.typeMore && !result.hasMoreResults && newOptions.length === 0) {
    extraOptions.push({
      type: 'hasNoResults',
    });
  }

  const regularOptions = [
    ...selectedGOptions,
    ...newOptions.map(
      (opt): GOption<TValue> => ({
        type: 'regular',
        value: opt,
      })
    ),
  ];

  setOptions([...extraOptions, ...regularOptions]);
};

import type { ReactElement } from 'react';
import { memo } from 'react';
import { createAutocompleteProps } from './CreateAutocompleteProps.tsx';
import GMultiAutocomplete from './GMultiAutocomplete';
import type { StaticMultiAutocompleteProps } from './StaticMultiAutocomplete.props';
import type { StaticAutocompleteOption } from './StaticSingleAutocomplete.props';
import { SEE_MORE_HEIGHT } from './VirtualizedListBox/renderRow.utils.ts';

function StaticMultiAutocomplete<T>(props: StaticMultiAutocompleteProps<T>): ReactElement {
  if (!props.options) {
    console.warn('Missing options for static multi select', props.name);
  }

  const matchingValues = props.options
    .filter((opt) => {
      return (props.value ?? []).some((val) => {
        if (props.isValueEqual) {
          return props.isValueEqual(opt.value, val);
        }

        return opt.value === val;
      });
    })
    .map((val) => {
      // mui autocomplete json stringifies values which are not present in options, which fails when option contains markup
      return {
        ...val,
        toJSON() {
          return JSON.stringify({
            ...val,
            label: 'skippedInJsonStringify',
            icon: 'skippedInJsonStringify',
          });
        },
      };
    });

  const restProps = createAutocompleteProps(props);
  return (
    <GMultiAutocomplete<StaticAutocompleteOption<T>>
      optionHeight={SEE_MORE_HEIGHT}
      {...props}
      {...restProps}
      value={matchingValues}
      onChange={(option: StaticAutocompleteOption<T> | StaticAutocompleteOption<T>[] | null): void => {
        const finalOpt = option as StaticAutocompleteOption<T>[] | null;
        props.onChange?.((finalOpt ?? []).map((opt) => opt.value));
      }}
      ref={props.ref}
    />
  );
}

const StaticMultiAutocompleteMemoized = memo(StaticMultiAutocomplete) as typeof StaticMultiAutocomplete;
export default StaticMultiAutocompleteMemoized;

import { type FunctionComponent, Suspense, useState } from 'react';
import Loader from 'components/technical/Loader/Loader.tsx';
import DailyPnlGrid from './DailyPnlGrid.tsx';
import type { Dayjs } from 'dayjs';
import { range7Days } from 'components/predefinedDateRanges';
import { Stack } from '@mui/joy';
import PredefinedDateRangeButtonsWithCalendar from 'components/technical/inputs/date/PredefinedDateRangeButtonsWithCalendar.tsx';
import { defaultRowSpacing } from '../../../StackSpacing.ts';
import { UserSettings } from 'components/management/UserSettings.types.ts';

const DailyPnl: FunctionComponent = () => {
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs] | null>(range7Days.value);

  return (
    <Stack spacing={defaultRowSpacing} height="100%">
      <PredefinedDateRangeButtonsWithCalendar
        alignRight={false}
        defaultValue={range7Days}
        onChange={(val): void => setDateRange(val)}
      />
      <Suspense fallback={<Loader />}>
        <DailyPnlGrid dateRange={dateRange} presetSettingsKey={UserSettings.DailyPnlPresets} />
      </Suspense>
    </Stack>
  );
};

export default DailyPnl;

import {
  type IPortfolioDefinition,
  IPortfolioDefinitionSubType,
  useRecomputePortfolioMutation,
} from '../../../../../generated/graphql.tsx';
import type { NotVerifiedAsset } from '../../../../market/asset/AssetLabelService.ts';
import { memo, type ReactElement } from 'react';
import { useNavigate } from 'react-router';
import GridCard from '../../../../technical/GCard/GridCard.tsx';
import { calculateItemProps } from '../PortfolioDefList.utils.ts';
import { PortfolioDefTile } from '../PortfolioDefTile.tsx';
import UpdatePortfolioDialogItem from '../update/UpdatePortfolioDialogItem.tsx';
import { MenuItem, Typography } from '@mui/joy';
import { AssetIconList } from '../../../../market/asset/AssetIconList.tsx';
import { PortfolioDescriptionText } from '../PortfolioDescriptionText.tsx';
import PortfolioCreatedAtText from '../PortfolioCreatedAt.tsx';
import { useFeedback } from '../../../../technical/Feedback/UseFeedback.tsx';
import SeeMoreDropdown from 'components/technical/SeeMoreDropDown/SeeMoreDropdown.tsx';
import { DeletePortfolioMenuItem } from '../DeletePortfolioMenuItem.tsx';

export const gridWidth = { sm: 12, md: 6, lg: 3 };

const RebalancedPortfolioCard = memo(
  (props: {
    height: string;
    selected: boolean;
    def: Pick<
      IPortfolioDefinition,
      'createdAt' | 'description' | 'id' | 'name' | 'type' | 'genie' | 'subType' | 'transactionTag'
    > & {
      assets: NotVerifiedAsset[];
    };
  }): ReactElement => {
    const navigate = useNavigate();
    const [recomputePortfolio] = useRecomputePortfolioMutation();
    const { showGraphqlError, showSuccessMessage } = useFeedback();
    return (
      <GridCard height={props.height} gridWidth={gridWidth} {...calculateItemProps(props.selected, props.def.id)}>
        <PortfolioDefTile
          name={props.def.name}
          dropdown={
            props.def.genie ? undefined : (
              <SeeMoreDropdown>
                <UpdatePortfolioDialogItem def={{ ...props.def, realDefAccounts: [], realDefSubFunds: [] }} />
                {(props.def.subType === IPortfolioDefinitionSubType.Default ||
                  props.def.subType === IPortfolioDefinitionSubType.AssetRuleBased) && (
                  <MenuItem
                    onClick={() => {
                      navigate(`/app/copilot/lab/portfolio/${props.def.id}/rebalancing-rules`);
                    }}
                  >
                    Rebalancing rules
                  </MenuItem>
                )}
                {props.def.subType === IPortfolioDefinitionSubType.PositionBased && (
                  <MenuItem
                    onClick={() => {
                      navigate(`/app/copilot/lab/portfolio/${props.def.id}/import-positions`);
                    }}
                  >
                    Import positions
                  </MenuItem>
                )}
                {props.def.subType === IPortfolioDefinitionSubType.TransactionBased && (
                  <MenuItem
                    onClick={async () => {
                      try {
                        await recomputePortfolio({ variables: { id: props.def.id } });
                        showSuccessMessage('Portfolio scheduled for recomputing');
                      } catch (e) {
                        showGraphqlError(e);
                      }
                    }}
                  >
                    Recompute
                  </MenuItem>
                )}
                <DeletePortfolioMenuItem name={props.def.name} id={props.def.id} />
              </SeeMoreDropdown>
            )
          }
        >
          <AssetIconList assets={props.def.assets ?? []} maxItems={6} />
          <PortfolioDescriptionText definition={props.def} />
          {props.def.subType === 'TRANSACTION_BASED' && (
            <Typography level="body-sm">Transaction tag: {props.def.transactionTag}</Typography>
          )}
          {!props.def.genie && <PortfolioCreatedAtText createdAt={props.def.createdAt} />}
        </PortfolioDefTile>
      </GridCard>
    );
  }
);

export default RebalancedPortfolioCard;

import { formatNumber } from '../../../../formatter.utils.ts';
import type { ITimeWeightedReturnType } from '../../../../../scalars.ts';
import { bignumber } from 'mathjs';
import Stack from '@mui/joy/Stack';
import SunburstChart from '../../../../technical/charts/SunburstChart/SunburstChart.tsx';
import Message from '../../../../technical/Message.tsx';
import {
  calculateChartInput,
  type ElementChild,
} from '../../../../technical/charts/SunburstChart/SunburstChart.utils.ts';
import bigNumMath from '../../../../../bigNumMath.ts';
import { useFinalColorScheme } from '../../../../../useFinalColorScheme.ts';
import { getRegularChartColor } from '../../../../../theme/colors.ts';
import { Typography } from '@mui/joy';

interface Row {
  subFund: {
    id: number;
    name: string;
  };
  twr: ITimeWeightedReturnType;
}

const SubFundBalanceChart = ({
  data,
  title,
  subFundIdToColorIndex,
  fullHeight,
}: { data: Row[]; title?: string; subFundIdToColorIndex: Map<number, number>; fullHeight?: boolean }) => {
  const colorScheme = useFinalColorScheme();
  if (data.length === 0) {
    return <Message>No data</Message>;
  }

  const sunburstData = calculateChartInput<{ value: number } & ElementChild>({
    root: {
      label: 'Total',
      color: 'transparent',
      elements: data.map(({ subFund, twr }) => ({
        label: subFund.name,
        color: getRegularChartColor(colorScheme, subFundIdToColorIndex.get(subFund.id) ?? -1),
        value: twr.return.balance,
        elements: [],
      })),
    },
    valueProvider: (val) => bignumber(val.value),
    textProvider: (val) => formatNumber(val),
    rootValue: bigNumMath.sum(data.map(({ twr }) => bignumber(twr.return.balance))),
  });

  return (
    <Stack gap={2} height={fullHeight ? '100%' : undefined}>
      {title && <Typography level="body-xs">{title}</Typography>}
      <SunburstChart
        height={fullHeight ? 'fullHeight' : undefined}
        data={{ ...sunburstData, hoverinfo: 'label+text', textinfo: 'label+text' }}
      />
    </Stack>
  );
};

export default SubFundBalanceChart;

import type { GridApi, CellClassParams, ICellRendererParams, ColDef, ColGroupDef } from 'ag-grid-community';
import { get, isDate, isNil, isObject } from 'lodash/fp';
import { isBigNumber } from 'mathjs';
import type { IAsset } from 'generated/graphql';
import type { ReactElement } from 'react';
import AssetLabel from '../../market/asset/AssetLabel';
import { IconVariant } from '../../market/asset/cryptocurrencies/CryptocurrenciesData';
import { getAssetName } from '../../market/asset/AssetService';
import { DateTimeFormat, formatDate } from 'components/formatter.utils';
import dayjs from 'dayjs';
import { UTC } from 'components/date.utils';
import type { AssetLabelInput, NotVerifiedAsset } from '../../market/asset/AssetLabelService.ts';
import { type AggFuncRule, aggFuncRules } from './SharedReportColumns.tsx';
import { Avatar, Stack, Typography } from '@mui/joy';
import UnknownIcon from '../UnknownIcon/UnknownIcon.tsx';

export const DEFAULT_AGG_FUNCTIONS = ['sum', 'avg', 'count', 'min', 'max', 'first', 'last'];

export const isColGroupDef = (col: ColDef | ColGroupDef): col is ColGroupDef => {
  return 'children' in col;
};

/**
 * When column added to row group, we don't have access to whole row data, only to column value ("group by" value)
 * and to render components asset/sub-account/venue we need more fields, we retrieve data from the first leaf node of the group
 * data is grouped by current column so all leaf nodes should have the same value
 */
export function getRowDataForRowGroupColumn<T>(params: ICellRendererParams<T> | CellClassParams<T>): T | undefined {
  if (params.data) {
    // normal row case
    return params.data;
  }

  // use value property to figure out if row would be rendered normally by aggrid
  if (!isNil(params.value)) {
    return params.node.allLeafChildren?.[0]?.data;
  }

  return undefined;
}

export const sideAwareHeaderName = (headerName: string, sideAware: boolean): string =>
  sideAware ? headerName : `${headerName} ABS`;

export function getValueFormatterCellValue<TValue>(value: TValue | null | undefined): TValue | null | undefined {
  if (isNil(value)) {
    return value;
  }

  if (isBigNumber(value)) {
    return value;
  }

  // for grouped rows value is object {count, value}
  if (isObject(value) && !isDate(value)) {
    if (!('value' in value)) {
      return value.toString() as TValue;
    }

    return getValueFormatterCellValue(value.value) as TValue | null | undefined;
  }

  return value;
}

export function caseInsensitiveComparator(valueA: string | undefined, valueB: string | undefined): number {
  return (valueA ?? '').toLowerCase().localeCompare((valueB ?? '').toLowerCase());
}

export type DerivativeAssetNameRow = {
  asset: AssetLabelInput | NotVerifiedAsset;
};

export type AssetNameRow = {
  asset: Pick<IAsset, 'type' | 'symbol' | 'name'>;
};

export function createAssetCellRenderer<T extends string>(
  field: T,
  withSymbol = false
): (params: ICellRendererParams<unknown>) => ReactElement | undefined {
  return (params: ICellRendererParams<unknown>): ReactElement | undefined => {
    const rowData = getRowDataForRowGroupColumn(params);
    const val = get(field, rowData);
    if (isNil(val)) {
      return undefined;
    }

    return (
      <Stack direction="row" alignItems="center" spacing="1">
        <AssetLabel asset={val} wrap={false} size={IconVariant.MEDIUM} withSymbol={withSymbol} plain link />
      </Stack>
    );
  };
}

export const protocolCellElement = (protocol: { name: string; icon: string | null }) => {
  const { name, icon } = protocol;
  return (
    <Stack direction="row" alignItems="center" display="flex" maxWidth="100%">
      {icon ? (
        <Avatar
          src={icon ?? '?'}
          alt={name}
          size="sm"
          sx={{
            // Protocol icon are way too big even on sm size
            '& .MuiAvatar-img': {
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              objectFit: 'fill',
            },
          }}
          variant="plain"
        />
      ) : (
        <UnknownIcon size={IconVariant.SMALL} text={name} />
      )}
      <Typography
        sx={{
          mr: 1,
        }}
        level={'title-sm'}
      >
        {name}
      </Typography>
    </Stack>
  );
};

export function createProtocolCellRenderer<T extends string>(
  field: T
): (params: ICellRendererParams<unknown>) => ReactElement | undefined {
  return (params: ICellRendererParams<unknown>): ReactElement | undefined => {
    const rowData = getRowDataForRowGroupColumn(params);
    const val = get(field, rowData);
    if (isNil(val)) {
      return undefined;
    }

    return protocolCellElement({ name: val.name, icon: val.icon });
  };
}

export const assetCellRenderer = (assetName = 'asset', withSymbol = false) =>
  createAssetCellRenderer(assetName, withSymbol);

export function underlyingAssetValueGetter(params: { data?: unknown }): string | undefined {
  if (!params.data) {
    return undefined;
  }

  let asset = get('asset', params.data);

  if (!asset) {
    asset = get('derivativeDetails.baseAsset', params.data);
  }

  return getAssetName(asset as Pick<IAsset, 'type' | 'symbol' | 'name'>);
}

export function assetSymbolGetter(params: { data?: { asset: { symbol: string } } }): string | undefined {
  if (!params.data) {
    return undefined;
  }

  return params.data.asset.symbol;
}

export function createAssetNameGetter<T extends string>(field: T): (params: { data?: unknown }) => string | undefined {
  return (params: { data?: unknown }): string | undefined => {
    const val = get(field, params.data);
    if (!val) {
      return undefined;
    }

    return getAssetName(val);
  };
}

export const assetNameGetter = createAssetNameGetter('asset');

export function dateReadableValueGetter(
  date: UtcDate | string,
  format: DateTimeFormat = DateTimeFormat.Date
): string | undefined {
  const localMidnightDate = dayjs.utc(date.toString());
  // the only found way to export correct format to charts is to store value in readable date format
  // but filtering only works with native Date object, so we need to implement it manually bellow
  return formatDate(localMidnightDate, format, UTC);
}

export const createColumnToolDef = (panelParams: {
  suppressRowGroups?: boolean;
  suppressValues?: boolean;
  suppressPivots?: boolean;
  suppressPivotMode?: boolean;
}) => ({
  id: 'columns',
  labelDefault: 'Columns',
  labelKey: 'columns',
  iconKey: 'columns',
  toolPanel: 'agColumnsToolPanel',
  toolPanelParams: panelParams,
});

// Extract column identifier safely
function getColId<T>(col: ColDef<T> | ColGroupDef<T>): string | undefined {
  if ('colId' in col && col.colId) return col.colId;
  if ('groupId' in col && col.groupId) return col.groupId;
  return undefined;
}

// Update columns aggFunc recursively
export function updateColumnsAggFuncs<T>(
  columns: (ColDef<T> | ColGroupDef<T>)[],
  visibleColumnsIds: string[],
  rules: AggFuncRule[],
  shouldApply: boolean
): (ColDef<T> | ColGroupDef<T>)[] {
  return columns.map((col) => {
    if (isColGroupDef(col)) {
      // Recursively update only children (no aggFunc on ColGroupDef itself)
      return {
        ...col,
        children: updateColumnsAggFuncs(col.children || [], visibleColumnsIds, rules, shouldApply),
      };
    }

    // col is ColDef<T>
    const colObj = col as ColDef<T>;
    if (colObj.colId && !visibleColumnsIds.includes(colObj.colId)) {
      return colObj;
    }
    const matchedRule = rules.find((rule) => {
      const colId = getColId(colObj);
      return colId ? (typeof rule.target === 'string' ? rule.target === colId : rule.target.test(colId)) : false;
    });

    return {
      ...colObj,
      aggFunc: matchedRule ? (shouldApply ? matchedRule.aggFunc : undefined) : colObj.aggFunc,
    };
  });
}

// TODO: this is a temporary function to handle row group changed event for PIVOT mode only
// It doesn't handle other modes or column value changed event

export function handleRowGroupChanged<T>(
  gridApi: GridApi | undefined | null,
  columnDefs: (ColDef<T> | ColGroupDef<T>)[],
  rowGroupColumnIdsToCheck: string[],
  targetColumns?: (typeof aggFuncRules)[number]['target'][]
): void {
  if (!gridApi) return;

  const activeGroupedColumns = gridApi.getRowGroupColumns().map((col: { getColId: () => string }) => col.getColId());
  const shouldApplyAggFuncs = activeGroupedColumns.some((colId: string) => rowGroupColumnIdsToCheck.includes(colId));
  const visibleColumnsIds = gridApi
    .getColumnState()
    .filter((col) => col.aggFunc)
    .map((col: { colId: string }) => col.colId);

  const aggFuncRulesToApply = targetColumns
    ? aggFuncRules.filter((rule) => targetColumns.includes(rule.target))
    : aggFuncRules;
  const updatedColumnDefs = updateColumnsAggFuncs(
    columnDefs,
    visibleColumnsIds,
    aggFuncRulesToApply,
    shouldApplyAggFuncs
  );

  gridApi.setGridOption('columnDefs', updatedColumnDefs);
}

import { StackedLineChart } from '@mui/icons-material';
import {
  DateRangePickerSettings,
  type DateRangePickerWidgetState,
} from 'components/management/customDashboard/DateRangePickerSettings.tsx';
import { useWidgetState } from 'components/management/customDashboard/CustomWidgetContext';
import type { CustomWidgetDescription } from 'components/management/customDashboard/CustomWidgetDescription.types.ts';
import { calculateDefaultPresetRange } from 'components/management/TilesDateRangeSelector';
import ProfitLossChart from './ProfitLossChart.tsx';

export const profitLossWidget: CustomWidgetDescription = {
  name: 'Total P&L',
  type: 'profitLossChart',
  description: 'Time weighted rate of return',
  icon: () => <StackedLineChart />,
  viewComponent: ({ id }) => {
    const initialState = useWidgetState<DateRangePickerWidgetState>(id);
    const range = calculateDefaultPresetRange(initialState.predefinedDateRange);
    return <ProfitLossChart dateRange={range.value()} fullHeight />;
  },
  settingsComponent: DateRangePickerSettings,
  initialH: 4,
  initialW: 3,
};

import { useForm } from 'react-hook-form';
import gYupResolver from '../../technical/form/gYupResolver.ts';
import * as yup from 'yup';
import { updateWidgetState, useWidgetState } from './CustomWidgetContext.tsx';
import { Stack } from '@mui/joy';
import { iconPadding } from './CustomWidgetCloseAction.tsx';
import { FormPredefinedDateRangeInput } from '../../technical/form/FormPredefinedDateRangeInput.tsx';
import type { PredefinedRangeLabel } from '../../predefinedDateRanges.ts';
import { calculateDefaultPresetRange } from '../TilesDateRangeSelector.tsx';
import { defaultRowSpacing } from '../../StackSpacing.ts';
import WidgetSettings from './WidgetSettings.tsx';

const schema = yup.object({
  title: yup.string().required(),
  predefinedDateRange: yup.object().required(),
});

interface FormState {
  title: string;
  predefinedDateRange: {
    label: PredefinedRangeLabel;
  };
}

export interface DateRangePickerWidgetState {
  title: string;
  predefinedDateRange?: PredefinedRangeLabel;
}

export const DateRangePickerSettings = ({
  id,
  onFinishedEditing,
  onRemovedWidget,
}: {
  id: string;
  onFinishedEditing: () => void;
  onRemovedWidget: () => Promise<void>;
}) => {
  const initialState = useWidgetState<DateRangePickerWidgetState>(id);
  const updater = updateWidgetState<DateRangePickerWidgetState>();

  const methods = useForm<FormState>({
    resolver: gYupResolver(schema),
    defaultValues: {
      ...initialState,
      predefinedDateRange: calculateDefaultPresetRange(initialState.predefinedDateRange),
    },
  });

  const handleFormSubmit = async (input: FormState): Promise<void> => {
    await updater({
      id,
      state: {
        title: input.title,
        predefinedDateRange: input.predefinedDateRange.label,
      },
      updateBackend: true,
    });

    onFinishedEditing();
  };

  return (
    <WidgetSettings methods={methods} handleFormSubmit={handleFormSubmit} onRemovedWidget={onRemovedWidget}>
      <Stack pr={iconPadding} width={'100%'} rowGap={defaultRowSpacing}>
        <FormPredefinedDateRangeInput name={'predefinedDateRange'} label={'Date range'} />
      </Stack>
    </WidgetSettings>
  );
};

import { Stack, useTheme } from '@mui/joy';
import { bignumber } from 'mathjs';
import GAgGridPresets from 'components/technical/grids/GAgGridPresets';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';
import {
  type IOpenPositionsSummaryQuery,
  useAssetRiskMetricsQuery,
  useOpenPositionsSummaryQuery,
} from 'generated/graphql';

import { positionsSummaryDefaultPresets } from './defaultPresets';
import {
  type AssetMetricValues,
  type MetricColumnConfig,
  accountColumn,
  amountColumn,
  assetMetricColumn,
  assetTypeColumn,
  balanceToMetricColumn,
  clusterColumn,
  costBasisColumn,
  SPARKLINE_Y_MIN_OFFSET,
  exposureColumn,
  fdvColumn,
  initialMarginColumn,
  leverageColumn,
  maintenanceMarginColumn,
  NAV_PERC_MIN_DECIMAL_THRESHOLD,
  navPercentageColumn,
  priceChangeAgainstAssetGroupColumns,
  priceChangesGroupColumns,
  sideWithBadgeColumn,
  subAccountColumn,
  subFundGroupColumn,
  underlyingAssetColumn,
  unitCostBasisColumn,
  unrealizedPnlWithColorsColumn,
  venueColumn,
  assetWithSymbolColumn,
  balanceColumn,
  COLUMN_IDS_WITH_ALTERNATIVE_AGG_FUNC,
  slotColumn,
} from '../../technical/grids/SharedReportColumns';
import { usePriceChanges } from './UsePriceChanges';
import { useReportAssetGroup } from '../../UseReportAssetGroups';
import { groupCostBasisByAssetAndSubFund } from '../account/SubAccountPositionsService';
import { mapSubAccountAndDimensionToSubFund } from 'components/bookkeeping/report/Report.utils';
import { calculateSubAccountToSubFundsMapper } from './PositionsSummary.util.ts';
import { uniq } from 'lodash/fp';
import { useSubAccountAssetFilters } from 'components/technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters.tsx';
import { MAX_DRAWDOWN } from 'components/metrics/NonPortfolioMetricsData.tsx';
import { MAX_DRAWDOWN_1Y } from 'components/metrics/NonPortfolioMetricsData.tsx';
import type { ColDef, GridApi, ColGroupDef } from 'ag-grid-community';
import { useMemo, useRef } from 'react';
import Loader from '../../technical/Loader/Loader.tsx';

const GROUP_COLUMN_MIN_WIDTH = 220;
type RowData = IOpenPositionsSummaryQuery['portfolio']['positions']['positions'][number];

const PositionsSummary = ({ presetSettingsKey }: { presetSettingsKey: string }) => {
  const theme = useTheme();
  const gridApi = useRef<GridApi | null>(null);

  const { subAccountAssetFilters } = useSubAccountAssetFilters();
  const reportAssetGroup = useReportAssetGroup();

  const metricColumnsConfiguration: Record<string, MetricColumnConfig[]> = useMemo(() => {
    return {
      Fundamentals: [
        { metricLabel: 'met:market_cap', initialHide: false },
        { metricLabel: 'met:total_val_locked', initialHide: false },
        { metricLabel: 'met:volume-24h', initialHide: false },
        { metricLabel: 'met:liquidity', initialHide: false },
        { metricLabel: MAX_DRAWDOWN, initialHide: false },
        { metricLabel: MAX_DRAWDOWN_1Y, initialHide: false },
      ],
      '30 Days': [
        {
          metricLabel: 'met:rolling_volatility-30',
          initialHide: false,
        },
        { metricLabel: 'met:rolling_beta_btc-30', initialHide: false },
        { metricLabel: 'met:rolling_beta_eth-30', initialHide: false },
        { metricLabel: 'met:rolling_sharpe-30', initialHide: false },
        { metricLabel: 'met:rolling_sortino-30', initialHide: false },
        { metricLabel: 'met:rolling_var95-30', initialHide: false },
        { metricLabel: 'met:rolling_var99-30', initialHide: false },
        { metricLabel: 'met:rolling_skewness-30', initialHide: true },
        { metricLabel: 'met:rolling_kurtosis-30', initialHide: true },
      ],

      '60 Days': [
        { metricLabel: 'met:rolling_volatility-60', initialHide: true },
        { metricLabel: 'met:rolling_beta_btc-60', initialHide: true },
        { metricLabel: 'met:rolling_beta_eth-60', initialHide: true },
        { metricLabel: 'met:rolling_sharpe-60', initialHide: true },
        { metricLabel: 'met:rolling_sortino-60', initialHide: true },
        { metricLabel: 'met:rolling_var95-60', initialHide: true },
        { metricLabel: 'met:rolling_var99-60', initialHide: true },
        { metricLabel: 'met:rolling_skewness-60', initialHide: true },
        { metricLabel: 'met:rolling_kurtosis-60', initialHide: true },
      ],
      '90 Days': [
        { metricLabel: 'met:rolling_volatility-90', initialHide: true },
        { metricLabel: 'met:rolling_beta_btc-90', initialHide: true },
        { metricLabel: 'met:rolling_beta_eth-90', initialHide: true },
        { metricLabel: 'met:rolling_sharpe-90', initialHide: true },
        { metricLabel: 'met:rolling_sortino-90', initialHide: true },
        { metricLabel: 'met:rolling_var95-90', initialHide: true },
        { metricLabel: 'met:rolling_var99-90', initialHide: true },
        { metricLabel: 'met:rolling_skewness-90', initialHide: true },
        { metricLabel: 'met:rolling_kurtosis-90', initialHide: true },
      ],
    };
  }, []);

  const openPositionsSummaryQueryResult = useDefaultErrorHandling(
    useOpenPositionsSummaryQuery({ variables: { subAccountAssetFilters } })
  );

  const positions = openPositionsSummaryQueryResult.data?.portfolio.positions.positions ?? [];
  const assetIds = positions.map((position) => position.asset.id);

  const assetRiskMetricsQueryResult = useDefaultErrorHandling(
    useAssetRiskMetricsQuery({
      skip: !positions.length,
      variables: { assetIds: uniq(assetIds) },
    })
  );

  const btcAsset = openPositionsSummaryQueryResult.data?.assets.feature.find((asset) => asset.symbol === 'BTC');

  const pricesChangesResult = usePriceChanges(btcAsset ? [...assetIds, btcAsset.id] : assetIds);

  const metricsByAsset = new Map<string, AssetMetricValues>(
    assetRiskMetricsQueryResult.data?.assets.details.map((assetMetrics) => [
      assetMetrics.asset,
      assetMetrics.metrics,
    ]) ?? []
  );

  const totalBalance = bignumber(openPositionsSummaryQueryResult.data?.portfolio.positions.summary.balance.total ?? 0);
  const navValues = positions
    .map((pos) => {
      const value = pos.spot
        ? bignumber(pos.spot.balance).div(totalBalance).toNumber()
        : pos.derivative
          ? bignumber(pos.derivative.balance || 0)
              .div(totalBalance)
              .toNumber()
          : 0;
      return Math.abs(value) < NAV_PERC_MIN_DECIMAL_THRESHOLD ? 0 : value;
    })
    .filter((val) => !Number.isNaN(val));

  const minNavValue = navValues.some((val) => val < 0) ? SPARKLINE_Y_MIN_OFFSET : undefined;

  const columnDefs = useMemo<(ColDef<RowData> | ColGroupDef<RowData>)[]>(() => {
    // Early return if data isn't ready
    if (
      !openPositionsSummaryQueryResult.loaded ||
      !assetRiskMetricsQueryResult.loaded ||
      !reportAssetGroup.loaded ||
      !btcAsset ||
      !pricesChangesResult.loaded ||
      !openPositionsSummaryQueryResult.data
    ) {
      return [];
    }

    const portfolio = openPositionsSummaryQueryResult.data.portfolio;

    const subFunds = portfolio.subFunds.list;
    const costBasisDimension = portfolio.journal.preferences.dimension ?? null;
    const costBasisPerAssetAndSubFund = groupCostBasisByAssetAndSubFund(portfolio.journal.costBasis);
    const subAccountToCostBasisSubFundsMapper = calculateSubAccountToSubFundsMapper(costBasisDimension, subFunds);
    const { subAccountAndDimensionToSubFund, subFundDimensions } = mapSubAccountAndDimensionToSubFund(subFunds);

    return [
      {
        headerName: 'Position',
        colId: 'position',
        marryChildren: true,
        children: [
          assetWithSymbolColumn(),
          amountColumn({ sideAware: true, initialHide: false }),
          sideWithBadgeColumn({ initialHide: false }),
          assetTypeColumn({ initialHide: false }),
          exposureColumn({ headerName: 'Notional', sideAware: true, initialHide: true }),
          exposureColumn({ headerName: 'Notional', sideAware: false, initialHide: true }),
          balanceColumn({
            initialHide: false,
          }),
          slotColumn(),
          underlyingAssetColumn(),
          navPercentageColumn({
            showBar: true,
            totalNAV: totalBalance.toNumber(),
            theme,
            minNavValue,
          }),
          unrealizedPnlWithColorsColumn(costBasisPerAssetAndSubFund, subAccountToCostBasisSubFundsMapper),
          unitCostBasisColumn(costBasisPerAssetAndSubFund, subAccountToCostBasisSubFundsMapper),
          costBasisColumn(costBasisPerAssetAndSubFund, subAccountToCostBasisSubFundsMapper),
        ],
      },
      {
        headerName: 'Groups',
        colId: COLUMN_IDS_WITH_ALTERNATIVE_AGG_FUNC.groups,
        initialHide: false,
        marryChildren: true,
        children: reportAssetGroup.clusters.map((cluster) =>
          clusterColumn<RowData>(cluster, reportAssetGroup.assetAndGroupClusterMapToGroup)
        ),
      },
      {
        headerName: 'Account Details',
        colId: 'account-details',
        marryChildren: true,
        children: [accountColumn(), subAccountColumn(), venueColumn()],
      },
      {
        headerName: 'Sub-funds',
        colId: 'sub-funds',
        marryChildren: true,
        children: subFundDimensions.map((subFundDimension) =>
          subFundGroupColumn(subFundDimension, subAccountAndDimensionToSubFund)
        ),
      },
      {
        headerName: 'Derivatives',
        colId: 'derivatives',
        marryChildren: true,
        children: [initialMarginColumn(), maintenanceMarginColumn(), leverageColumn()],
      },
      ...priceChangeAgainstAssetGroupColumns<RowData>(
        pricesChangesResult.priceSummary,
        pricesChangesResult.priceChanges24hByAssetId,
        {
          id: btcAsset.id,
          name: btcAsset.symbol,
        }
      ),
      ...priceChangesGroupColumns<RowData>(
        pricesChangesResult.priceSummary,
        pricesChangesResult.priceChanges24hByAssetId
      ),
      ...Object.entries(metricColumnsConfiguration).map(([groupHeader, groupColumns]) => {
        const children = [
          ...groupColumns.map(
            ({ metricLabel, initialHide }): ColDef<{ asset: { id: string } }> =>
              assetMetricColumn(metricsByAsset, metricLabel, initialHide)
          ),
          ...(groupHeader === 'Fundamentals'
            ? [
                fdvColumn({ metricsByAsset }),
                {
                  headerName: 'Extra',
                  colId: COLUMN_IDS_WITH_ALTERNATIVE_AGG_FUNC.extra,
                  marryChildren: true,
                  children: [
                    balanceToMetricColumn({
                      metricsByAsset,
                      metricLabel: 'met:volume-24h',
                      headerName: 'Balance / Volume',
                    }),
                    balanceToMetricColumn({
                      metricsByAsset,
                      metricLabel: 'met:liquidity',
                      headerName: 'Balance / Liquidity',
                    }),
                  ],
                },
              ]
            : []),
        ].filter(Boolean);
        return {
          headerName: groupHeader,
          marryChildren: true,
          children,
        };
      }),
    ];
  }, [
    openPositionsSummaryQueryResult.loaded,
    openPositionsSummaryQueryResult.data,
    assetRiskMetricsQueryResult.loaded,
    reportAssetGroup.loaded,
    btcAsset,
    totalBalance,
    pricesChangesResult,
    reportAssetGroup,
    metricsByAsset,
    theme,
    minNavValue,
    metricColumnsConfiguration,
  ]);

  // Loading checks after all hooks
  if (!openPositionsSummaryQueryResult.loaded) {
    return <openPositionsSummaryQueryResult.Fallback />;
  }

  if (!assetRiskMetricsQueryResult.loaded) {
    return <assetRiskMetricsQueryResult.Fallback />;
  }

  if (!reportAssetGroup.loaded) {
    return <reportAssetGroup.Fallback />;
  }
  if (!pricesChangesResult.loaded) {
    return <pricesChangesResult.Fallback />;
  }

  // Check if we have both data and column definitions
  if (columnDefs.length === 0 || !openPositionsSummaryQueryResult.data?.portfolio.positions.positions) {
    return <Loader />;
  }

  return (
    <Stack spacing={1.5} height="100%">
      <GAgGridPresets<RowData>
        defaultPresets={positionsSummaryDefaultPresets}
        presetSettingsKey={presetSettingsKey}
        rowData={openPositionsSummaryQueryResult.data?.portfolio.positions.positions ?? []}
        groupDefaultExpanded={-1}
        sideBar={{
          toolPanels: ['columns', 'filters'],
        }}
        enableCharts
        cellSelection
        autoGroupColumnDef={{
          minWidth: GROUP_COLUMN_MIN_WIDTH,
        }}
        onGridReady={(params: { api: GridApi }) => {
          gridApi.current = params.api;
        }}
        onGridPreDestroyed={() => {
          gridApi.current = null;
        }}
        autoSizeStrategy={{ type: 'fitCellContents' }}
        defaultColDef={{
          resizable: true,
          sortable: true,
          filter: true,
        }}
        columnDefs={columnDefs}
      />
    </Stack>
  );
};

export default PositionsSummary;

import { Stack } from '@mui/joy';
import type { ReactElement } from 'react';

import FormTextArea from 'components/technical/form/FormTextArea.tsx';
import { FormInput } from 'components/technical/inputs';
import GButton from 'components/technical/inputs/GButton/GButton.tsx';
import type { DescriptionStepInput } from './DescriptionStep.validation.ts';
import FormCheckbox from 'components/technical/form/FormCheckbox.tsx';
import { defaultRowSpacing } from 'components/StackSpacing.ts';

export const defaultName = 'Optimization 1';
const DescriptionStep = ({ goToNextStep }: { goToNextStep: () => void }): ReactElement => {
  return (
    <Stack spacing={defaultRowSpacing}>
      <FormInput<DescriptionStepInput> label="Name" name="name" width="normal" placeholder={defaultName} />
      <FormTextArea<DescriptionStepInput>
        label="Description"
        name="description"
        width="xl4"
        maxRows={3}
        minRows={3}
        placeholder="Add a short text to explain the objective of the optimization"
      />
      <FormCheckbox<DescriptionStepInput> label="Use underlying" name="useUnderlying" />
      <GButton
        onClick={goToNextStep}
        sx={{
          marginLeft: 'auto',
        }}
      >
        Next
      </GButton>
    </Stack>
  );
};

export default DescriptionStep;

import { createContext, type ReactNode, useContext, useMemo, useState } from 'react';
import { useUpdateWidgetStateMutation } from '../../../generated/graphql.tsx';
import omit from 'lodash/fp/omit';

const customWidgetContext = createContext<{
  state: Map<
    string,
    {
      title: string;
    }
  >;
  updateWidget: (options: {
    id: string;
    widgetState: { title: string };
  }) => void;
  page: string;
}>({
  state: new Map(),
  updateWidget: () => {
    console.warn('context not initialized');
  },
  page: '',
});

export const CustomWidgetContextProvider = ({
  children,
  page,
  initialState,
}: {
  children: ReactNode;
  page: string;
  initialState: Map<string, { title: string }>;
}) => {
  const [widgetState, setWidgetState] = useState<Map<string, { title: string }>>(initialState);
  const val = useMemo(() => {
    return {
      state: widgetState,
      page,
      updateWidget: (options: {
        id: string;
        widgetState: { title: string };
      }) => {
        setWidgetState((prev) => {
          const newState = new Map(prev);
          newState.set(options.id, {
            ...options.widgetState,
          });

          return newState;
        });
      },
    };
  }, [widgetState, page]);
  return <customWidgetContext.Provider value={val}>{children}</customWidgetContext.Provider>;
};

export const useWidgetState = <T extends { title: string }>(id: string): T => {
  const ctx = useContext(customWidgetContext);
  return ctx.state.get(id) as unknown as T;
};

export const updateWidgetState = <T extends { title: string }>(): ((options: {
  id: string;
  state: T;
  updateBackend: boolean;
}) => Promise<void>) => {
  const ctx = useContext(customWidgetContext);
  const [updateWidget] = useUpdateWidgetStateMutation();
  return async ({ id, state, updateBackend }) => {
    ctx.updateWidget({ id, widgetState: state });
    if (!updateBackend) {
      return;
    }

    await updateWidget({
      variables: {
        input: {
          id,
          page: ctx.page,
          title: state.title,
          data: omit(['title'], state),
        },
      },
    });
  };
};

import dayjs, { type Dayjs } from 'dayjs';
import { validateRequiredNumber } from '../../../../../number.utils.ts';
import { parse, CsvError } from 'csv-parse/browser/esm/sync';
import { validateRequiredDate } from 'components/date.utils.ts';

export const sampleFile = `
date,price
2024-01-01,96608.77
2024-01-03,96608.77
2024-01-04,96608.87`.trim();

const processLines = (
  lines: { date: string; price: string }[]
): {
  lineErrors: { line: number; error: string }[];
  generalErrors: string[];
  data: { date: Dayjs; price: string }[];
} => {
  if (lines.length === 0) {
    return {
      generalErrors: ['File is empty'],
      lineErrors: [],
      data: [],
    };
  }

  const generalErrors = [];
  const firstList = lines[0];
  if (firstList.date !== 'date' || firstList.price !== 'price') {
    generalErrors.push('File contains invalid header');
  }

  const data: {
    date: Dayjs;
    price: string;
  }[] = [];
  const allLineErrors: { line: number; error: string }[] = [];
  for (let i = 1; i < lines.length; i++) {
    const { date: dateText, price: priceText } = lines[i];
    const lineErrors: string[] = [];
    const dateValidationResult = validateRequiredDate(dateText);
    if (dateValidationResult.error) {
      lineErrors.push(dateValidationResult.error);
    } else if (dateValidationResult.value!.isAfter(dayjs.utc())) {
      lineErrors.push('Date is in the future');
    }

    const priceValidationResult = validateRequiredNumber({
      columnName: 'price',
      numberText: priceText,
    });
    if (priceValidationResult.error) {
      lineErrors.push(priceValidationResult.error);
    } else if (Number.parseFloat(priceValidationResult.value!) < 0) {
      lineErrors.push('Price is negative');
    }

    if (lineErrors.length > 0) {
      allLineErrors.push({ line: i + 1, error: lineErrors.join('. ') });
    }

    if (lineErrors.length === 0) {
      data.push({
        date: dateValidationResult.value!,
        price: priceValidationResult.value!,
      });
    }
  }

  return {
    generalErrors,
    lineErrors: allLineErrors,
    data,
  };
};

export const parseFile = (
  file: string
): {
  lineErrors: { line: number; error: string }[];
  generalErrors: string[];
  data: { date: Dayjs; price: string }[];
} => {
  try {
    const lines: { date: string; price: string }[] = parse(file.trim(), {
      columns: ['date', 'price'],
      skip_empty_lines: true,
    });

    return processLines(lines);
  } catch (e) {
    if (e instanceof CsvError) {
      return {
        lineErrors: [
          {
            line: e.lines,
            error: e.code === 'CSV_RECORD_INCONSISTENT_COLUMNS' ? 'Invalid number of columns' : e.message,
          },
        ],
        generalErrors: [],
        data: [],
      };
    }

    throw e;
  }
};

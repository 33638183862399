import isEqual from 'lodash/fp/isEqual';
import { useEffect, useRef, useState } from 'react';

import type { GTableCursorPaginator, GTablePagePaginator } from './GTable.props.ts';
import { ICursorType } from 'generated/graphql.tsx';
import type { PaginationState } from '@tanstack/table-core';

export const DEFAULT_PAGE_SIZES = [10, 25, 50];

export const useTablePagePaginator = ({
  filters,
  defaultPageSize,
}: {
  filters?: unknown;
  defaultPageSize?: number;
}): {
  tablePaginator: GTablePagePaginator;
  page: {
    limit: number;
    offset: number;
  };
} => {
  const previousFilters = useRef<unknown>(null);
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: defaultPageSize ?? DEFAULT_PAGE_SIZES[0],
  });

  useEffect(() => {
    if (!isEqual(filters, previousFilters.current)) {
      previousFilters.current = filters;
      setPagination({
        pageIndex: 0,
        pageSize,
      });
    }
  }, [filters, pageSize]);

  return {
    tablePaginator: {
      state: {
        pageIndex,
        pageSize,
      },
      onChange(updater) {
        if (typeof updater === 'function') {
          setPagination((prev) => {
            const newState = updater(prev);
            return {
              ...newState,
              pageIndex: newState.pageIndex ?? 0,
            };
          });
        } else {
          setPagination({
            ...updater,
            pageIndex: updater.pageIndex ?? 0,
          });
        }
      },
    },
    page: {
      offset: pageIndex * pageSize,
      limit: pageSize,
    },
  };
};

type CursorPaginationState = Omit<PaginationState, 'pageIndex' | 'cursorType'> & { cursorType: ICursorType };

export const calculateCursorTypeOnSortingChange = (opts: {
  cursorType: ICursorType;
  firstValue: string | null;
  lastValue: string | null;
}): { cursorType: ICursorType; cursorValue: string | null } => {
  if (opts.cursorType === ICursorType.First) {
    return { cursorType: ICursorType.Last, cursorValue: null };
  }

  if (opts.cursorType === ICursorType.Last) {
    return { cursorType: ICursorType.First, cursorValue: null };
  }

  return { cursorType: ICursorType.AfterEqual, cursorValue: opts.lastValue };
};

export const useTableCursorPaginator = ({
  filters,
  defaultPageSize,
  sortAscending,
}: {
  filters?: unknown;
  defaultPageSize?: number;
  sortAscending: boolean;
}): {
  tablePaginator: GTableCursorPaginator;
  cursor: {
    limit: number;
    cursorType: ICursorType;
    cursorValue: string | null;
  };
  onCursorUpdated: (cursors: {
    first?: string | null;
    last?: string | null;
    hasNextPage?: boolean;
    hasPreviousPage?: boolean;
  }) => void;
} => {
  const previousFilters = useRef<unknown>(filters);
  const previousSortAscending = useRef<unknown>(sortAscending);
  const [state, setState] = useState<CursorPaginationState>({
    pageSize: defaultPageSize ?? DEFAULT_PAGE_SIZES[0],
    cursorType: ICursorType.First,
    cursorValue: null,
    hasPreviousPage: false,
    hasNextPage: false,
    first: null,
    last: null,
  });

  useEffect(() => {
    if (!isEqual(filters, previousFilters.current)) {
      previousFilters.current = filters;
      setState({
        pageSize: state.pageSize,
        cursorType: ICursorType.First,
        cursorValue: null,
        hasPreviousPage: false,
        hasNextPage: false,
        first: null,
        last: null,
      });
    }
  }, [filters, state]);

  useEffect(() => {
    // whenever user changes sorting, we need to go to the first page
    if (!isEqual(sortAscending, previousSortAscending.current)) {
      previousSortAscending.current = sortAscending;
      setState({
        pageSize: state.pageSize,
        hasNextPage: false,
        hasPreviousPage: false,
        cursorType: ICursorType.First,
        cursorValue: null,
        first: state.last,
        last: state.first,
      });
    }
  }, [sortAscending, state]);

  return {
    tablePaginator: {
      state: state,
      onChange: (updater) => {
        if (typeof updater === 'function') {
          setState((prev) => {
            const newState = updater({
              ...prev,
            });

            return {
              ...prev,
              ...newState,
            };
          });
        } else {
          setState({
            ...state,
            ...updater,
          });
        }
      },
    },
    cursor: {
      cursorType: state.cursorType ?? ICursorType.First,
      cursorValue: state.cursorValue ?? null,
      limit: state.pageSize,
    },
    onCursorUpdated: (cursors) => {
      setState((prev) => {
        return {
          ...prev,
          first: cursors.first ?? null,
          last: cursors.last ?? null,
          hasPreviousPage: cursors.hasPreviousPage ?? false,
          hasNextPage: cursors.hasNextPage ?? false,
        };
      });
    },
  };
};

export const pageOptions = DEFAULT_PAGE_SIZES.map((value) => ({
  value: value.toString(),
  label: <>Per page {value.toString()}</>,
  key: value.toString(),
}));

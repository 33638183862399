import { useWidgetState } from 'components/management/customDashboard/CustomWidgetContext';
import { calculateDefaultPresetRange } from 'components/management/TilesDateRangeSelector';
import {
  useSubFundChartWidgetSettingsInputSuspenseQuery,
  useSubFundPnlSuspenseQuery,
} from '../../../../../generated/graphql.tsx';
import uniq from 'lodash/fp/uniq';
import { noDimensionsMessage } from './../SubFundPnlDashboard.tsx';
import Message from '../../../../technical/Message.tsx';
import { isNil, sortBy } from 'lodash/fp';
import { formatISODate } from '../../../../formatter.utils.ts';
import type { ReactElement } from 'react';
import type { Dayjs } from 'dayjs';
import type { ChartDataRange, SubFundChartWidgetState } from './SubFundChartWidgetSettings.tsx';
import dayjs from 'dayjs';
import { SubFundLastDayBarChart } from './SubFundLastDayBarChart.tsx';
import { filterTwrRowsByAsset, findTwrForDate, getTwrDatesByIndex } from '../SubFundPnlResult.tsx';
import SubFundPerformanceLineChart, { type SupportedTwrFields } from './SubFundPerformanceLineChart.tsx';
import SubFundBalanceChart from './SubFundBalanceChart.tsx';

const SubFundChartWidgetContainer = ({
  dimension,
  startDate,
  assetId,
  chartDataRange,
  twrField,
}: {
  dimension: string;
  startDate: Dayjs | null;
  assetId: string | null | undefined;
  twrField: SupportedTwrFields;
  chartDataRange: ChartDataRange;
}): ReactElement => {
  const { data } = useSubFundPnlSuspenseQuery({
    variables: {
      dimensions: isNil(dimension) ? [] : [dimension],
      since: startDate === null ? null : formatISODate(startDate),
    },
  });

  const subFundTwr = data.portfolio.subFundsTimeWeightedReturns.subFunds;
  const twrRows = filterTwrRowsByAsset({
    rows: subFundTwr,
    asset: assetId ? { id: assetId } : null,
    defaultTwrIfNoAsset: twrField === 'weight' ? 'reference' : 'usd',
  });
  const twrLastDate = getTwrDatesByIndex(twrRows, -1);

  if (chartDataRange === 'latest') {
    if (twrLastDate.length === 0) {
      return <Message>No twr data</Message>;
    }

    const maxTwrDate = dayjs.max(twrLastDate);
    const lastDayTwr = findTwrForDate(twrRows, maxTwrDate!);
    if (twrField !== 'balance') {
      return <SubFundLastDayBarChart data={lastDayTwr} twrField={twrField} hideTitle fullHeight />;
    }

    const sortedSubFunds = sortBy((row) => row.subFund.name, subFundTwr);
    const subFundToColorIndex = new Map(sortedSubFunds.map((subFund, index) => [subFund.subFund.id, index]));
    return <SubFundBalanceChart data={lastDayTwr} subFundIdToColorIndex={subFundToColorIndex} fullHeight />;
  }

  return <SubFundPerformanceLineChart data={twrRows} twrField={twrField} fullHeight />;
};

export const SubFundPnlChartWidget = ({ id }: { id: string }): ReactElement => {
  const { data } = useSubFundChartWidgetSettingsInputSuspenseQuery();
  const state = useWidgetState<SubFundChartWidgetState>(id);
  const dimensions = uniq(data.portfolio.subFunds.list.map((item) => item.dimension));
  if (dimensions.length === 0) {
    return noDimensionsMessage;
  }

  const dimension = state.dimension ?? dimensions[0];
  if (!dimensions.includes(dimension)) {
    return <Message>Selected dimension doesn't exist</Message>;
  }

  const range = calculateDefaultPresetRange(state.predefinedDateRange);
  const rangeValue = range.value();
  return (
    <SubFundChartWidgetContainer
      dimension={dimension}
      startDate={isNil(rangeValue) ? null : rangeValue[0]}
      assetId={state.assetId}
      twrField={state.twrField}
      chartDataRange={state.chartDataRange}
    />
  );
};

import { type Dispatch, type ReactElement, type SetStateAction, useCallback } from 'react';
import type { DragEndEvent, DraggableAttributes } from '@dnd-kit/core';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { Box, Checkbox, Stack } from '@mui/joy';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import ListItemContent from '@mui/joy/ListItemContent';
import { swap } from '../../../arrayUtils.ts';
import DraggableList from '../../technical/DraggableList/DraggableList.tsx';

export interface AggItem {
  id: string;
  label: string;
  category: string;
  checked: boolean;
}

export const AggregationList = ({
  aggs,
  setAggs,
}: {
  aggs: AggItem[];
  setAggs: Dispatch<SetStateAction<AggItem[]>>;
}) => {
  const renderItem = useCallback(
    (item: AggItem, draggableAttrs: DraggableAttributes, listeners: SyntheticListenerMap | undefined): ReactElement => {
      return (
        <Stack direction="row" alignItems="center" spacing={1.5}>
          <Checkbox
            onChange={(e): void => {
              const newValue = e.target.checked;
              setAggs((prev) =>
                prev.map((prevItem) => {
                  if (prevItem.id === item.id) {
                    return {
                      ...prevItem,
                      checked: newValue,
                    };
                  }
                  return prevItem;
                })
              );
            }}
            checked={item.checked}
          />
          <Box {...draggableAttrs} {...listeners} sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }}>
            <DragHandleIcon />
          </Box>
          <ListItemContent>{item.label}</ListItemContent>
        </Stack>
      );
    },
    [setAggs]
  );

  const onDragEnd = (e: DragEndEvent): void => {
    const { active, over } = e;
    if (!over) {
      return;
    }

    if (over.id === active.id) {
      return;
    }

    const ids = aggs.map((item) => item.id);
    const oldIndex = ids.indexOf(active.id as string);
    const newIndex = ids.indexOf(over.id as string);
    const newItems = swap(aggs, oldIndex, newIndex);
    setAggs(newItems);
  };

  return <DraggableList<AggItem> dragHandle items={aggs} renderItem={renderItem} onDragEnd={onDragEnd} />;
};

import { useForm } from 'react-hook-form';
import gYupResolver from '../../technical/form/gYupResolver.ts';
import * as yup from 'yup';
import { updateWidgetState, useWidgetState } from './CustomWidgetContext.tsx';
import WidgetSettings from './WidgetSettings.tsx';

const schema = yup.object({
  title: yup.string().required(),
});

interface FormState {
  title: string;
}

export interface TitleWidgetState {
  title: string;
}

export const TitleSettings = ({
  id,
  onFinishedEditing,
  onRemovedWidget,
}: {
  id: string;
  onFinishedEditing: () => void;
  onRemovedWidget: () => Promise<void>;
}) => {
  const initialState = useWidgetState<TitleWidgetState>(id);
  const updater = updateWidgetState<TitleWidgetState>();

  const methods = useForm<FormState>({
    resolver: gYupResolver(schema),
    defaultValues: {
      ...initialState,
    },
  });

  const handleFormSubmit = async (input: FormState): Promise<void> => {
    await updater({
      id,
      state: {
        title: input.title,
      },
      updateBackend: true,
    });

    onFinishedEditing();
  };

  return <WidgetSettings methods={methods} handleFormSubmit={handleFormSubmit} onRemovedWidget={onRemovedWidget} />;
};

import { gridWithInputStyles } from '../../../../technical/grids/gridStyles.ts';
import ErrorMessage from '../../../../technical/ErrorMessage.tsx';
import { Controller, useFormContext } from 'react-hook-form';
import type { ReactElement } from 'react';
import { defaultCol } from '../initialPortfolio/InitialPortfolioStep.utils.tsx';
import GAgGrid from '../../../../technical/grids/GAgGrid.tsx';
import { Box } from '@mui/joy';
import type { ColDef, ColGroupDef } from 'ag-grid-enterprise';
import type { ValueGetterParams } from 'ag-grid-community';
import isNil from 'lodash/fp/isNil';
import type { UseFormReturn } from 'react-hook-form';
import type { RecursivePartial } from '../../../../type.utils.ts';

export const createInInitialPortfolioColumn = <T extends { id: string }>(
  givenPortfolio: Record<string, string | null>
): ColDef<T> => {
  return {
    colId: 'initialPortfolio',
    headerName: 'Initial portfolio',
    valueGetter: (params: ValueGetterParams<T, string>): string | undefined => {
      if (!params.data) {
        return undefined;
      }

      const assetId = params.data.id;
      const givenPortfolioValue = givenPortfolio[assetId];
      const inGivenPortfolio = !isNil(givenPortfolioValue) && Number.parseFloat(givenPortfolioValue) !== 0;
      return inGivenPortfolio ? 'Yes' : 'No';
    },
  };
};

const Universe = <ROW, FIELDS extends { universe: RecursivePartial<ROW>[] }>({
  rowData,
  columnDefs,
  onChange: onChangeProp,
  idGetter,
  syncSelection,
  groupDefaultExpanded = 0,
}: {
  rowData: ROW[];
  columnDefs: (ColDef<ROW> | ColGroupDef<ROW>)[];
  onChange?: (rows: ROW[]) => void;
  idGetter: (row: ROW | null | undefined) => string;
  syncSelection: (context: UseFormReturn<FIELDS>) => void;
  groupDefaultExpanded?: number;
}): ReactElement => {
  const methods = useFormContext<FIELDS>();
  const { isSubmitting } = methods.formState;

  return (
    <Controller<FIELDS>
      // @ts-expect-error path doesnt point to collection but to collection index and as a result, cannot be typed properly
      name={'universe'}
      control={methods.control}
      /* jscpd:ignore-end */
      render={({ field: { value, onChange }, fieldState }): ReactElement => {
        return (
          <>
            <Box height="700px">
              <GAgGrid<ROW>
                autoSizeStrategy={{
                  type: 'fitGridWidth',
                  defaultMinWidth: 250,
                  defaultMaxWidth: 250,
                }}
                onRowSelected={(e): void => {
                  const items: ROW[] = e.api.getSelectedRows();
                  onChange(items);
                  onChangeProp?.(items);
                  syncSelection(methods);
                }}
                rowSelection={{
                  mode: 'multiRow',
                  groupSelects: 'filteredDescendants',
                  enableClickSelection: false,
                  checkboxLocation: 'autoGroupColumn',
                  selectAll: 'filtered',
                  checkboxes: !isSubmitting,
                }}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultCol}
                groupDefaultExpanded={groupDefaultExpanded}
                getRowId={(row) => {
                  return idGetter(row.data);
                }}
                onGridReady={(e): void => {
                  const selectedRowIds = new Set<string>(
                    Array.isArray(value) ? (value as FIELDS['universe']).map((item) => idGetter(item as ROW)) : []
                  );
                  let scrolledToFirstSelected = false;
                  e.api.forEachNodeAfterFilter((node) => {
                    if (selectedRowIds.has(idGetter(node.data) ?? '')) {
                      node.setSelected(true);
                      if (!scrolledToFirstSelected) {
                        // scroll for first selected
                        e.api.ensureNodeVisible(node, 'middle');
                        scrolledToFirstSelected = true;
                      }
                    }
                  });
                }}
                rowHeight={gridWithInputStyles.rowHeight}
              />
            </Box>
            {fieldState.error && <ErrorMessage>{fieldState.error.message}</ErrorMessage>}
          </>
        );
      }}
    />
  );
};

export default Universe;

import type { FunctionComponent } from 'react';
import {
  assetAggregation,
  getGenieGroupAggregations,
  getUserGroupAggregations,
  type GroupWithAssetId,
  longShortCategory,
} from 'components/portfolio/dashboard/PositionAggregationsService.ts';
import PortfolioSunburstWithAggregations from 'components/portfolio/dashboard/sunburst/PortfolioSunburstWithAggregations.tsx';
import { DEFAULT_PORTFOLIO_EQUITY, type BuilderPortfolioPosition } from './PortfolioBuilder.tsx';
import { bignumber } from 'mathjs';
import { UserSettings } from 'components/management/UserSettings.types.ts';
import { calculateValue, type PortfolioBuilderInputFields } from './PortfolioBuilder.utils.ts';

import { useAggregations } from 'UseUserAggregations.tsx';
import type { Aggregation, AggregationValue } from 'components/portfolio/dashboard/PositionAggregationsService.ts';
import { isValidNumber } from 'components/number.utils.ts';
import { useWatch } from 'react-hook-form';
import { Typography } from '@mui/joy';
import bigNumMath from 'bigNumMath.ts';
import SunburstChartSkeleton from 'components/technical/charts/SunburstChart/SunburstChartSkeleton.tsx';

type PositionsSunburstProps = {
  assetGroups: {
    genieGroups: GroupWithAssetId[];
    userGroups: GroupWithAssetId[];
  };
  portfolioEquity: string | null;
};

const PositionsSunburst: FunctionComponent<PositionsSunburstProps> = ({ assetGroups, portfolioEquity }) => {
  const allAvailableAggregations: Aggregation<BuilderPortfolioPosition>[] = [
    assetAggregation,
    {
      label: 'Long/short',
      category: longShortCategory,
      calculateValue: (pos: BuilderPortfolioPosition): AggregationValue => {
        const value = Number.parseFloat(pos.value ?? '0') > 0 ? 'Long' : 'Short';
        return {
          id: value,
          label: value,
        };
      },
    },
    ...getGenieGroupAggregations<BuilderPortfolioPosition>(assetGroups.genieGroups),
    ...getUserGroupAggregations<BuilderPortfolioPosition>(assetGroups.userGroups),
  ];

  const { aggregations, handleSetAggregationConfig, activeAggregationsOrdered, aggregationsByCategory } =
    useAggregations<BuilderPortfolioPosition>({
      allAvailableAggregations,
      defaultAggregations: [assetAggregation],
      userSettingsKey: UserSettings.PortfolioBuilderAggregations,
    });

  const positions = useWatch<PortfolioBuilderInputFields, 'positions'>({
    name: 'positions',
  });

  const validPositions = Object.values(positions).filter((pos: BuilderPortfolioPosition) => isValidNumber(pos.weight));

  const calculateBalance = (pos: BuilderPortfolioPosition) => {
    return bignumber(
      calculateValue(
        Number.parseFloat(portfolioEquity ?? DEFAULT_PORTFOLIO_EQUITY),
        Number.parseFloat(pos.weight ?? '0')
      )
    );
  };

  const leverageValue = () => {
    const totalValue = bigNumMath.sum(validPositions.map((pos) => calculateBalance(pos)));
    if (totalValue.isZero() || Number.parseFloat(portfolioEquity ?? DEFAULT_PORTFOLIO_EQUITY) === 0) {
      return '0';
    }

    return totalValue
      .toDP(2)
      .div(Number.parseFloat(portfolioEquity ?? DEFAULT_PORTFOLIO_EQUITY))
      .toNumber()
      .toFixed(2);
  };

  if (validPositions.length === 0) {
    return <SunburstChartSkeleton />;
  }

  return (
    <PortfolioSunburstWithAggregations<BuilderPortfolioPosition>
      positions={validPositions}
      aggregations={aggregations}
      onSetAggregationConfig={handleSetAggregationConfig}
      calculateBalance={calculateBalance}
      activeAggregationsOrdered={activeAggregationsOrdered}
      aggregationsByCategory={aggregationsByCategory}
    >
      {/* mt used to align with the gear icon */}
      <Typography level="body-md" mt={1}>
        Leverage {leverageValue()}x
      </Typography>
    </PortfolioSunburstWithAggregations>
  );
};

export default PositionsSunburst;

import { Stack, Tooltip } from '@mui/joy';
import type { Dayjs } from 'dayjs';
import { type ReactElement, useState } from 'react';
import { getDefaultRange } from 'components/predefinedDateRanges';
import PredefinedDateRangeButtonsWithCalendar from 'components/technical/inputs/date/PredefinedDateRangeButtonsWithCalendar';
import ActionsChartWithTitle from '../../../ActionsChartWithTitle.tsx';
import ProfitLossChart from './ProfitLossChart.tsx';

export const CHART_TITLE = 'Return metrics';

const ProfitLossChartWrapper = (): ReactElement => {
  const defaultDateRange = getDefaultRange();
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs] | null>(defaultDateRange.value);

  return (
    <ActionsChartWithTitle title={CHART_TITLE} paper>
      <Stack spacing={1}>
        <Stack direction="row-reverse" justifyContent="space-between" flexWrap="wrap" width="100%" spacing={1.5}>
          <Tooltip title="The dates shown in the intervals are at end of day UTC">
            <div>
              <PredefinedDateRangeButtonsWithCalendar
                defaultValue={defaultDateRange}
                onChange={(val): void => setDateRange(val)}
              />
            </div>
          </Tooltip>
        </Stack>
        <ProfitLossChart dateRange={dateRange} fullHeight={false} />
      </Stack>
    </ActionsChartWithTitle>
  );
};

export default ProfitLossChartWrapper;

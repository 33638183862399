import { Add } from '@mui/icons-material';
import { Modal, ModalDialog, Stack } from '@mui/joy';

import type { Dayjs } from 'dayjs';
import { useRef, type ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { formatISODate } from 'components/formatter.utils';
import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';
import type { FormInputType } from 'components/technical/form/Form.types';
import { FormDateInput } from 'components/technical/form/FormDateInput';
import FormStaticSingleAutocomplete from 'components/technical/form/FormStaticSingleAutocomplete';
import GFormProvider from 'components/technical/form/GFormProvider';
import { GraphQLApiFormErrorMessage } from 'components/technical/form/GraphQLApiErrorMessage';
import gYupResolver from 'components/technical/form/gYupResolver';
import SubmitButton from 'components/technical/form/SubmitButton';
import { useGraphQLApiError } from 'components/technical/form/UseGraphQLApiError.tsx';
import GDialogHeader from 'components/technical/GDialog/GDialogHeader.tsx';
import { FormInput } from 'components/technical/inputs';
import {
  AssetPriceChartDocument,
  type IAsset,
  useCreateUpdateAssetPriceMutation,
} from '../../../../../../generated/graphql';
import { isDayjsDateNotInTheFuture, isValidDayjsDate } from '../../../../../date.utils';
import type { Asset } from '../../../Asset.types';
import { createAssetSelectOptions } from '../../../AssetService';
import { defaultRowSpacing } from '../../../../../StackSpacing.ts';
import dayjs from 'dayjs';

interface FormOutputFields {
  asset: Omit<IAsset, 'derivativeDetails' | 'unvestedAsset' | 'priceAsset'>;
  date: Dayjs;
  price: number;
}

type FormInputFields = FormInputType<FormOutputFields>;

const formSchema = yup.object({
  asset: yup.mixed().required(),
  date: yup
    .mixed()
    .required()
    .test('validDate', 'Date is invalid', isValidDayjsDate)
    .test('notInTheFuture', 'Date cannot be in the future', isDayjsDateNotInTheFuture),
  price: yup.number().required().positive(),
});

export const CreateAssetPriceDialog = (props: {
  onClose: () => void;
  onAdded: () => void;
  availableAssets: Asset[];
  quoteAsset: { id: string };
}): ReactElement => {
  const maxDate = useRef(dayjs.utc());
  const assetOptions = createAssetSelectOptions(props.availableAssets);

  const methods = useForm<FormInputFields>({
    resolver: gYupResolver(formSchema),
    defaultValues: {
      asset: props.availableAssets.at(0),
      date: null,
      price: '',
    },
  });

  const { onErrorAndThrow } = useGraphQLApiError(methods);
  const { showSuccessMessage } = useFeedback();

  const [createAssetPrice] = useCreateUpdateAssetPriceMutation({
    refetchQueries: [AssetPriceChartDocument],
  });

  const handleFormSubmit = async (input: FormInputFields): Promise<void> => {
    const formOutput = input as unknown as FormOutputFields;
    try {
      await createAssetPrice({
        variables: {
          input: {
            id: {
              date: formatISODate(formOutput.date),
              baseAssetId: formOutput.asset.id,
              quoteAssetId: props.quoteAsset.id,
            },
            unitValue: formOutput.price.toString(),
          },
        },
      });

      showSuccessMessage('New asset price successfully added');

      props.onAdded();
    } catch (e) {
      onErrorAndThrow(e);
    }
  };

  const inputWidth = 'xl2';
  return (
    <Modal open={true} onClose={props.onClose}>
      <ModalDialog>
        <GFormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
            <GDialogHeader>Add price</GDialogHeader>
            <Stack alignItems="center" spacing={defaultRowSpacing}>
              <FormStaticSingleAutocomplete<FormInputFields>
                {...assetOptions}
                name="asset"
                label="Asset"
                width={inputWidth}
              />
              <FormDateInput<FormInputFields> label="Date" name="date" width={inputWidth} maxDate={maxDate.current} />
              <FormInput label="Price" name="price" width={inputWidth} />

              <GraphQLApiFormErrorMessage />
              <SubmitButton width="normal" startDecorator={<Add />}>
                Add new price
              </SubmitButton>
            </Stack>
          </form>
        </GFormProvider>
      </ModalDialog>
    </Modal>
  );
};

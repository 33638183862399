import type { IMetricCalculatorParameterInput } from 'generated/graphql';
import type { MetricData, MetricParams } from './MetricsData.types';

export const PORTFOLIO_SORTINO_METRIC = 'pmet:sortino' as const;
export const PORTFOLIO_SHARPE_METRIC = 'pmet:sharpe' as const;
export const PORTFOLIO_VOLATILITY_METRIC = 'pmet:volatility' as const;
export const PORTFOLIO_ALPHA_METRIC = 'pmet:alpha' as const;
export const PORTFOLIO_BETA_METRIC = 'pmet:beta' as const;
export const PORTFOLIO_CORRELATION_METRIC = 'pmet:correlation' as const;
export const PORTFOLIO_VAR_METRIC = 'pmet:var' as const;
export const PORTFOLIO_CVAR_METRIC = 'pmet:cvar' as const;
export const PORTFOLIO_DOWNSIDE_RISK_METRIC = 'pmet:downside_risk' as const;
export const PORTFOLIO_DRAWDOWN_METRIC = 'pmet:drawdown' as const;
export const PORTFOLIO_KURTOSIS_METRIC = 'pmet:kurtosis' as const;
export const PORTFOLIO_SKEWNESS_METRIC = 'pmet:skewness' as const;
export const PORTFOLIO_CAPTURE_METRIC = 'pmet:capture' as const;
export const PORTFOLIO_CALMAR_RATIO_METRIC = 'pmet:calmar_ratio' as const;
export const PORTFOLIO_MAR_RATIO_METRIC = 'pmet:mar_ratio' as const;
export const PORTFOLIO_TRACKING_ERROR_METRIC = 'pmet:tracking_error' as const;
export const PORTFOLIO_TURNOVER_METRIC = 'pmet:turnover' as const;

const portfolioMetrics = [
  PORTFOLIO_SORTINO_METRIC,
  PORTFOLIO_SHARPE_METRIC,
  PORTFOLIO_VOLATILITY_METRIC,
  PORTFOLIO_ALPHA_METRIC,
  PORTFOLIO_BETA_METRIC,
  PORTFOLIO_CORRELATION_METRIC,
  PORTFOLIO_VAR_METRIC,
  PORTFOLIO_CVAR_METRIC,
  PORTFOLIO_DOWNSIDE_RISK_METRIC,
  PORTFOLIO_DRAWDOWN_METRIC,
  PORTFOLIO_KURTOSIS_METRIC,
  PORTFOLIO_SKEWNESS_METRIC,
  PORTFOLIO_CAPTURE_METRIC,
  PORTFOLIO_CALMAR_RATIO_METRIC,
  PORTFOLIO_MAR_RATIO_METRIC,
  PORTFOLIO_TRACKING_ERROR_METRIC,
  PORTFOLIO_TURNOVER_METRIC,
];

type PortfolioMetrics = (typeof portfolioMetrics)[number];

function parametrizedName(name: string): (params?: MetricParams) => string {
  return (params?: MetricParams): string => {
    if (!params) {
      return name;
    }

    const parts = [name];
    if (params.annualized) {
      parts.push('(annualized)');
    }

    if (params.distribution) {
      parts.push(params.distribution);
    }

    if (params.assetSymbol) {
      parts.push(params.assetSymbol);
    }

    return parts.join(' ');
  };
}

export const metricsData: Record<PortfolioMetrics, MetricData> = {
  [PORTFOLIO_SORTINO_METRIC]: {
    name: parametrizedName('Sortino'),
    format: 'number',
    tooltip: (params?: MetricParams) => {
      if (params?.annualized && params?.windowType === 'expanding') {
        return 'The ratio of the average returns of your portfolio vs its downside risk';
      }
      if (params?.annualized && params?.windowType === 'rolling') {
        return 'The annualized ratio of the last days average returns of your portfolio vs its volatility';
      }
      if (params?.metricType === 'realized' && params?.windowType === 'rolling') {
        return 'The ratio of the last n days average returns of your portfolio vs its volatility';
      }
      return 'The ratio of the last days average returns of your portfolio vs its downside risk';
    },
  },
  [PORTFOLIO_KURTOSIS_METRIC]: {
    name: parametrizedName('Kurtosis'),
    format: 'number',
    tooltip:
      'A measure of how thick the tails of the returns of your portfolio are. A valuable of 3 corresponds to gaussian tails.',
  },
  [PORTFOLIO_SKEWNESS_METRIC]: {
    name: parametrizedName('Skewness'),
    format: 'number',
    tooltip:
      'A measure of the asymmetry of the returns of your portfolio. Positive skewness means the longer tail is the one with large positive returns.',
  },
  [PORTFOLIO_CAPTURE_METRIC]: {
    name: (params) => {
      if (params?.captureType === 'up') {
        return 'Upside capture';
      }
      if (params?.captureType === 'down') {
        return 'Downside capture';
      }
      return 'Capture ratio';
    },

    format: 'percentage',
    tooltip: (params?: MetricParams) => {
      if (params?.captureType === 'up') {
        return "The upside capture ratio measures the portfolio's performance in up markets relative to the benchmark. A value over 100% indicates that the portfolio has outperformed the benchmark during periods of positive returns for the benchmark.";
      }
      if (params?.captureType === 'down') {
        return "The downside capture ratio measures the portfolio's performance in down markets relative to the benchmark. A value of less than 100% indicates that an investment has lost less than its benchmark during periods of negative returns for the benchmark.";
      }

      return 'The capture ratio measures the performance of the portfolio during upward and downward market trends with respect to the benchmark specified in the filed "benchmark"';
    },
  },
  [PORTFOLIO_SHARPE_METRIC]: {
    name: parametrizedName('Sharpe'),
    format: 'number',
    tooltip: (params) => {
      if (params?.annualized && params?.windowType === 'rolling') {
        return 'The annualized ratio of the last days average returns of your portfolio vs its volatilit';
      }
      if (params?.annualized && params?.windowType === 'expanding') {
        return 'The ratio of the average returns of your portfolio vs its volatility';
      }
      return 'The ratio of the last 30 days average returns of your portfolio vs its volatility';
    },
  },
  [PORTFOLIO_VOLATILITY_METRIC]: {
    name: parametrizedName('Volatility'),
    format: 'percentage',
    tooltip: (params) => {
      if (params?.windowType === 'rolling' && params?.metricType) {
        return `Is the ${params.metricType} standard deviation of daily returns in the last 30 days`;
      }
      if (params?.windowType === 'rolling') {
        return 'The standard deviation of the last 30 days returns of your portfolio';
      }
      return 'Is the standard deviation of daily returns in the last 30 days';
    },
  },
  [PORTFOLIO_BETA_METRIC]: {
    name: parametrizedName('Beta'),
    format: 'number',
    tooltip: (params) => {
      let symbol = params?.assetSymbol;
      if (!symbol) {
        // there are places in ui where we want to generate a list of metrics first and then allow selecting an asset
        symbol = 'selected asset';
      }
      return `Is how much your portfolio value is expected to change when the value of ${symbol} increases by 1%`;
    },
  },
  [PORTFOLIO_CORRELATION_METRIC]: {
    name: parametrizedName('Correlation'),
    format: 'number',
  },
  [PORTFOLIO_CVAR_METRIC]: {
    name: parametrizedName('CVar'),
    format: 'percentage',
  },
  [PORTFOLIO_VAR_METRIC]: {
    name: parametrizedName('Var'),
    format: 'percentage',
  },
  [PORTFOLIO_DOWNSIDE_RISK_METRIC]: {
    name: parametrizedName('Downside risk'),
    format: 'percentage',
    tooltip: (params) => {
      if (params?.metricType && params?.windowType === 'rolling') {
        return `The ${params.metricType} standard deviation of negative daily returns in a period of 30days. Positive returns are counted with value of 0`;
      }
      if (params?.windowType === 'rolling') {
        return 'The standard deviation of negative daily returns in a period of 30days. Positive returns are counted with value of 0';
      }
      return 'The standard deviation of negative daily return. Positive returns are counted with value of 0';
    },
  },
  [PORTFOLIO_DRAWDOWN_METRIC]: {
    name: parametrizedName('Drawdown'),
    format: 'percentage',
    tooltip: "How much in % your portfolio has lost with respect to it's all times high",
  },
  [PORTFOLIO_ALPHA_METRIC]: {
    name: 'Jensen alpha',
    format: 'percentage',
    tooltip:
      "The Jensen's alpha, is a risk-adjusted performance measure that represents the average return on a portfolio, above or below that predicted by the capital asset pricing model, given the portfolio's beta and the average returns for the benchmark",
  },
  [PORTFOLIO_CALMAR_RATIO_METRIC]: {
    name: 'Calmar ratio',
    format: 'number',
    tooltip: 'The CALMAR ratio compares the average annual compounded rate of return and the maximum drawdown risk.',
  },
  [PORTFOLIO_MAR_RATIO_METRIC]: {
    name: 'Mar ratio',
    format: 'number',
    tooltip:
      'The MAR ratio is calculated by dividing the compound annual growth rate (CAGR) of a fund or strategy since its inception by its most significant drawdown. The higher the ratio, the better the risk-adjusted returns.',
  },
  [PORTFOLIO_TRACKING_ERROR_METRIC]: {
    name: 'Tracking error',
    format: 'percentage',
    tooltip: "Shows the consistency of keeping the fund's returns in line with its benchmark",
  },
  [PORTFOLIO_TURNOVER_METRIC]: {
    name: 'Turnover',
    format: 'cash',
    tooltip: 'Total value of trades necessary to transition from initial portfolio to optimized portfolio',
  },
};

export const expanding: IMetricCalculatorParameterInput = {
  name: 'Window type',
  strValues: ['expanding'],
};

export const rolling: IMetricCalculatorParameterInput = {
  name: 'Window type',
  strValues: ['rolling'],
};

export const realized: IMetricCalculatorParameterInput = {
  name: 'Metric type',
  strValues: ['realized'],
};

export const annualized: IMetricCalculatorParameterInput = {
  name: 'Annualized',
  strValues: ['annualized'],
};

export const notAnnualized: IMetricCalculatorParameterInput = {
  name: 'Annualized',
  strValues: ['not_annualized'],
};

export const benchmarkParameter = (assetId: string): IMetricCalculatorParameterInput => ({
  name: 'Benchmark',
  strValues: [assetId],
});

export const windowParameter = (window: number): IMetricCalculatorParameterInput => ({
  name: 'Window',
  intValues: [window],
});

export const expected: IMetricCalculatorParameterInput = {
  name: 'Metric type',
  strValues: ['expected'],
};

import type { FunctionComponent } from 'react';
import { useSubAccountAssetFilters } from 'components/technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters.tsx';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';

import PortfolioExposureRisk from './PortfolioExposureRisk.tsx';
import PortfolioSnapshotContainer from './PortfolioSnapshotContainer';
import { FIRST_CHART_TITLE, SND_CHART_TITLE } from './PortfolioSnapshotContainerService.tsx';
import PortfolioTiles from './tiles/PortfolioTiles';
import { usePortfolioDashboardInputQuery } from '../../../generated/graphql';
import ChartsSkeleton from '../../ChartsSkeleton.tsx';
import CardWithFooter from './CardWithFooter.tsx';
import PositionSummaryLight from '../openPositions/PositionSummaryLight.tsx';
import { Stack, Typography } from '@mui/joy';
import { useNavigate } from 'react-router-dom';
import { UserSettings } from '../../management/UserSettings.types.ts';
import { getSavedDaysRange } from './tiles/PortfolioTilesService.ts';

const PortfolioDashboard: FunctionComponent = () => {
  const navigate = useNavigate();
  const positionsDetailsUrl = '/app/portfolio/positions';

  const { subAccountAssetFilters } = useSubAccountAssetFilters();
  const { loaded, Fallback, data, refetch } = useDefaultErrorHandling(
    usePortfolioDashboardInputQuery({
      variables: { subAccountAssetFilters, tilesSettings: UserSettings.TilesDateRange },
    })
  );

  if (!loaded) {
    return <Fallback />;
  }

  const { savedDateRange, savedTimeFrameInDays } = getSavedDaysRange(data);
  return data.portfolio.accounts.length === 0 ? (
    <ChartsSkeleton
      titleLeft={FIRST_CHART_TITLE}
      titleRight={SND_CHART_TITLE}
      onSubAccountConnected={(): void => {
        refetch();
      }}
    />
  ) : (
    <>
      <PortfolioTiles savedDateRange={savedDateRange} savedTimeFrameInDays={savedTimeFrameInDays} />
      <PortfolioSnapshotContainer />
      <CardWithFooter noMinHeight seeMoreAction={(): void => navigate(positionsDetailsUrl)}>
        <Stack gap={2} height="100%">
          <Typography level="h3">Open positions</Typography>
          <PositionSummaryLight portfolio={data.portfolio} />
        </Stack>
      </CardWithFooter>
      <PortfolioExposureRisk assets={data.assets.feature} />
    </>
  );
};

export default PortfolioDashboard;

import { Card, CardContent, CardOverflow, Divider, Grid, Skeleton, Stack, Typography } from '@mui/joy';
import { isNil } from 'lodash/fp';
import { bignumber } from 'mathjs';
import type { FunctionComponent } from 'react';
import { useNavigate } from 'react-router';

import { GraphQLErrorMessage } from 'components/technical/form/GraphQLApiErrorMessage.tsx';
import HeaderBar from 'components/technical/HeaderBar/HeaderBar';
import KeyValueListSkeleton from 'components/technical/KeyValueListSkeleton';
import { portfolioRiskLink } from 'components/technical/Sidebar/Menu';
import { useSubAccountAssetFilters } from 'components/technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters.tsx';
import CardWithFooter from './CardWithFooter';
import { DASHBOARD_CARD_HEIGHT } from './PortfolioDashboard.types.ts';
import PortfolioRealizedMetrics from './PortfolioRealizedMetrics';
import SubAccountBalanceList from './VenueBalanceList.tsx';
import {
  type IAsset,
  type IMetricCalculatorInput,
  usePortfolioExposureMetricsQuery,
  usePortfolioExposureRiskQuery,
} from '../../../generated/graphql';
import { formatCash } from '../../formatter.utils';
import {
  PORTFOLIO_BETA_METRIC,
  PORTFOLIO_SHARPE_METRIC,
  PORTFOLIO_SORTINO_METRIC,
  PORTFOLIO_VOLATILITY_METRIC,
  annualized,
  benchmarkParameter,
  expanding,
  notAnnualized,
  realized,
} from '../../metrics/PortfolioMetricsData';
import AssetNews from './AssetNews.tsx';

const SubtitleWithHeading: FunctionComponent<{ subtitle: string; heading: string }> = ({ subtitle, heading }) => {
  return (
    <Stack>
      <Typography level="title-xs" component="h3">
        {subtitle}
      </Typography>
      <Typography level="h2" component="span">
        {heading}
      </Typography>
    </Stack>
  );
};

const ExposureRiskSkeleton: FunctionComponent<{ animation: boolean }> = ({ animation }) => {
  const animationProp = animation ? 'pulse' : false;
  return (
    <Card sx={{ height: DASHBOARD_CARD_HEIGHT, justifyContent: 'space-between' }}>
      <Stack height="100%" gap={3}>
        <Stack>
          <Skeleton level="h2" variant="text" width="50%" animation={animationProp} />
          <Skeleton level="h2" variant="text" width="30%" animation={animationProp} />
          <Skeleton level="title-sm" variant="text" width="60%" animation={animationProp} />
        </Stack>
        <Stack gap={2}>
          <Skeleton level="body-xs2" variant="text" width="25%" animation={animationProp} />
          <KeyValueListSkeleton animation={animation} />
        </Stack>
      </Stack>
      <CardOverflow variant="soft">
        <Divider inset="context" />
        <CardContent orientation="horizontal">
          <Skeleton level="body-xs2" sx={{ marginLeft: 'auto' }} variant="text" width="25%" animation={animationProp} />
        </CardContent>
      </CardOverflow>
    </Card>
  );
};

const metrics = (ethId: string, btcId: string): Array<IMetricCalculatorInput> => [
  {
    label: PORTFOLIO_SORTINO_METRIC,
    parameters: [realized, expanding, annualized],
  },
  {
    label: PORTFOLIO_SHARPE_METRIC,
    parameters: [realized, expanding, annualized],
  },
  {
    label: PORTFOLIO_VOLATILITY_METRIC,
    parameters: [realized, expanding, notAnnualized],
  },
  {
    label: PORTFOLIO_BETA_METRIC,
    parameters: [realized, expanding, benchmarkParameter(ethId)],
  },
  {
    label: PORTFOLIO_BETA_METRIC,
    parameters: [realized, expanding, benchmarkParameter(btcId)],
  },
];

const ExposureRiskTitle = 'Exposures and risk';
const CounterpartyReportUrl = '/app/portfolio/risk/counterparty-report';

const PortfolioExposureRisk: FunctionComponent<{ assets: Pick<IAsset, 'id' | 'symbol'>[] }> = ({ assets }) => {
  const ethId = assets.find((asset) => asset.symbol === 'ETH')!.id;
  const btcId = assets.find((asset) => asset.symbol === 'BTC')!.id;

  const navigate = useNavigate();
  const { subAccountAssetFilters } = useSubAccountAssetFilters();

  const { loading, data, error } = usePortfolioExposureRiskQuery({
    variables: {
      subAccountAssetFilters,
    },
  });

  const {
    loading: loadingRiskMetrics,
    data: riskMetricsData,
    error: riskMetricsError,
  } = usePortfolioExposureMetricsQuery({
    variables: {
      input: {
        metrics: metrics(ethId, btcId),
        subAccountAssetFilter: subAccountAssetFilters,
      },
    },
  });

  if (loading || !isNil(error) || !data) {
    return (
      <Stack gap={1.5}>
        <HeaderBar title={ExposureRiskTitle} />
        {error && <GraphQLErrorMessage error={error} />}
        <Grid container alignItems="stretch" spacing={1.5}>
          <Grid xs={12} md={3}>
            <ExposureRiskSkeleton animation={loading} />
          </Grid>
          <Grid xs={12} md={6}>
            <ExposureRiskSkeleton animation={loading} />
          </Grid>
          <Grid xs={12} md={3}>
            <ExposureRiskSkeleton animation={loading} />
          </Grid>
        </Grid>
      </Stack>
    );
  }

  const portfolio = data.portfolio;
  const openPositionsSummary = portfolio.positions.summary;

  return (
    <Stack gap={1.5} mb={4}>
      <Stack direction="row" alignItems="center" gap={4}>
        <HeaderBar title={ExposureRiskTitle} />
      </Stack>
      <Grid container alignItems="stretch" spacing={3}>
        <Grid xs={12} md={12} lg={4}>
          <Stack direction="column" gap={2}>
            <CardWithFooter noMinHeight seeMoreAction={(): void => navigate(portfolioRiskLink)}>
              <Stack gap={2} direction="column">
                {openPositionsSummary && (
                  <Stack direction="row" gap={2} justifyContent="space-between">
                    <SubtitleWithHeading
                      subtitle="Gross exposure"
                      heading={formatCash(
                        bignumber(openPositionsSummary.exposure.long).plus(openPositionsSummary.exposure.short)
                      )}
                    />
                    <SubtitleWithHeading
                      subtitle="Net exposure"
                      heading={formatCash(openPositionsSummary.exposure.net)}
                    />
                  </Stack>
                )}
                <Stack>
                  <Typography>Portfolio realized risk metrics</Typography>
                  {loadingRiskMetrics && <KeyValueListSkeleton animation />}
                  {riskMetricsError && <GraphQLErrorMessage error={riskMetricsError} />}

                  {riskMetricsData && (
                    <PortfolioRealizedMetrics
                      metrics={riskMetricsData.portfolio.metrics}
                      betaAssetIds={{ btc: btcId, eth: ethId }}
                    />
                  )}
                </Stack>
              </Stack>
            </CardWithFooter>
            <CardWithFooter noMinHeight seeMoreAction={(): void => navigate(CounterpartyReportUrl)}>
              <Stack gap={2}>
                <Typography level="h3" component="span">
                  Counterparty risk
                </Typography>
                <Stack height="255px">
                  <SubAccountBalanceList balanceByVenue={openPositionsSummary.balanceByVenue} />
                </Stack>
              </Stack>
            </CardWithFooter>
          </Stack>
        </Grid>
        <Grid xs={12} md={12} lg={8}>
          <AssetNews positions={data.portfolio.positions.positions} />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default PortfolioExposureRisk;

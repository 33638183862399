import { calculateTooltipText, getTileValues } from './PortfolioTilesService.ts';
import TitleValueTile, { TitleValueContent } from '../../../technical/Tile/TitleValueTile.tsx';
import { formatPercentage } from '../../../formatter.utils.ts';
import { Tooltip, Typography } from '@mui/joy';
import { tooltipEnterDelay } from '../../../../theme/consts.ts';
import { TileProgressFooter } from './TileProgressFooter.tsx';
import type { IPortfolioTwrEquityTileInputQuery } from 'generated/graphql.tsx';
import type { Dayjs } from 'dayjs';

export const TwrTileContent = ({
  loading,
  dateRangeLabel,
  twr,
  since,
  hideTitle,
}: {
  loading: boolean;
  twr?: IPortfolioTwrEquityTileInputQuery['portfolio']['portfolioTimeWeightedReturns']['values'];
  dateRangeLabel: string;
  since: Dayjs;
  hideTitle?: boolean;
}) => {
  const twrRangeTooltip = calculateTooltipText(since);

  const twrFilteredBySavedRangeRows = twr?.map((twr) => ({
    value: twr.return.weight,
  }));
  const twrTile = getTileValues(twrFilteredBySavedRangeRows?.slice(1));
  return (
    <TitleValueContent
      loading={loading}
      title={!hideTitle && <Typography lineHeight={2}>TWR ({dateRangeLabel.toUpperCase()})</Typography>}
      value={formatPercentage(twrTile?.latestValue)}
      footer={
        <Tooltip title={twrRangeTooltip} enterDelay={tooltipEnterDelay.Slow}>
          <span>
            <TileProgressFooter
              minValue={formatPercentage(twrTile?.minValue)}
              maxValue={formatPercentage(twrTile?.maxValue)}
              progressValue={twrTile?.progressValue}
            />
          </span>
        </Tooltip>
      }
    />
  );
};

const TwrTile = ({
  loading,
  dateRangeLabel,
  twr,
  since,
}: {
  loading: boolean;
  twr?: IPortfolioTwrEquityTileInputQuery['portfolio']['portfolioTimeWeightedReturns']['values'];
  dateRangeLabel: string;
  since: Dayjs;
}) => {
  return (
    <TitleValueTile>
      <TwrTileContent loading={loading} dateRangeLabel={dateRangeLabel} twr={twr} since={since} />
    </TitleValueTile>
  );
};
export default TwrTile;
